import React, { useContext } from 'react';
import _ from 'lodash';
import { Description } from './PrimaryPanel';
import { renderTextWithAnchor } from 'jbc-front/components/Form';

const Context = React.createContext([]);

export const QuestionDescriptionsProvider = ({ descriptions, children }) => (
  <Context.Provider value={descriptions}>{children}</Context.Provider>
);

export const useQuestionDescriptions = () => useContext(Context);

export const CustomDescription = ({ field, as = Description }) => {
  const descriptions = useQuestionDescriptions();
  const { description } = _.find(descriptions, { field });
  const Component = as;
  if (description) {
    return (
      <Component>
        <div style={{ whiteSpace: 'pre-line' }}>{renderTextWithAnchor(description)}</div>
      </Component>
    );
  } else {
    return null;
  }
};
