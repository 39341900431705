import { useYearMasters } from 'hooks/useYearMasters';
import { eraNameFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { ReportName } from '../types';

type UseGenerateReportNameMap = (
  year: number
) => {
  reportMap: { [key in ReportName]: string };
};

export const useGenerateReportNameMap: UseGenerateReportNameMap = year => {
  const { yearMasters, loading } = useYearMasters();
  const reportMap = {
    insuranceDeduction: '',
    bsiDeduction: '',
    dependentDeductionThisYear: '',
    dependentDeductionNextYear: '',
    withholdingBook: '',
    withholdingSlip: '',
    spouseDeduction: '',
    withholdingSlipEmployee: '',
    fileMount: ''
  };

  if (loading || !yearMasters) {
    return { reportMap };
  }

  const thisYearMaster = yearMasters.find(yearMaster => yearMaster.year === year);
  const nextYearMaster = yearMasters.find(yearMaster => yearMaster.year === year + 1);

  if (!thisYearMaster || !nextYearMaster) {
    return { reportMap };
  }

  const thisYearEra = eraNameFormat(thisYearMaster.eraName, thisYearMaster.eraNumber);
  const formattedThisYearEra = eraNameFormat(thisYearMaster.eraName, thisYearMaster.eraNumber, true, true);
  const formattedNextYearEra = eraNameFormat(nextYearMaster.eraName, nextYearMaster.eraNumber, true, true);

  reportMap['insuranceDeduction'] = `${thisYearEra}年分 給与所得者の保険料控除申告書`;
  reportMap['bsiDeduction'] = `${thisYearEra}年分 給与所得者の基礎控除申告書 兼 給与所得者の配偶者控除等申告書 兼 所得金額調整控除申告書`;
  reportMap['dependentDeductionThisYear'] = `${formattedThisYearEra}年分 給与所得者の扶養控除等（異動）申告書`;
  reportMap['dependentDeductionNextYear'] = `${formattedNextYearEra}年分 給与所得者の扶養控除等（異動）申告書`;
  reportMap['withholdingBook'] = '源泉徴収簿';
  reportMap['withholdingSlip'] = '源泉徴収票';
  reportMap['spouseDeduction'] = `${thisYearEra}年分 給与所得者の配偶者控除等申告書`;
  reportMap['withholdingSlipEmployee'] = '源泉徴収票';
  reportMap['fileMount'] = '添付台紙';

  return { reportMap };
};
