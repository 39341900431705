import { yup } from '@jbc-year-end-adj/common/yup';
import { FileType } from '@jbc-year-end-adj/types';

export type Schema = {
  attachments: Attachment[];
};

type Attachment = {
  id?: number;
  file?: FileType | File;
};

const AttachmentSchema = yup.object({
  id: yup.string(),
  file: yup
    .mixed<File>()
    .required()
    .label('添付ファイル')
});

export const schema = yup.object({
  attachments: yup.array().of(AttachmentSchema)
});

export const generateDefaultValues = (attachments?: Attachment[]) => {
  if (!attachments || attachments.length === 0) {
    return { attachments: [] };
  }

  return { attachments: attachments.map(attachment => generateAttachment(attachment)) };
};

export const generateAttachment = (attachment?: Attachment) => {
  return {
    id: attachment?.id,
    file: attachment?.file
  };
};
