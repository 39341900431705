import React from 'react';
import { change } from 'redux-form';
import { useDispatch } from 'react-redux';
import { LabelMapper } from 'jbc-front/components/FormErrors';
import { Section } from 'jbc-front/components/Form';
import Name from '../components/Name';
import Address from '../components/Address';
import { gql } from '@apollo/client';
import { toFormValues } from '../utils/form';
import _ from 'lodash';
import { genderOptions } from '../questions/BasicInfos';
import { nationalTypes, isDisasterSuffererOptions } from '../questions/BasicInfos';
import { toBooleanProps } from '../utils/form';
import { FormSection, TextField, DateField, RadioField, SelectField, CheckboxField } from '../components/FieldWithDiff';
import { relationships } from '../questions/Householder';
import { amount, maxLength } from '../validators';
import { renderCheckBoxField } from 'jbc-front/components/Form';
import AutoField from '../components/AutoField';
import { EMPLOYEE_ADDRESS_CLASSIFICATION } from './ResidentTax';
import { useYear, useQuery, useAutofill, useFormValue, useFormName } from '../components';
import { Line } from '../components/PrimaryPanel';

export { BASIC_INFORMATION_FRAGMENT } from '../questions/BasicInfos';
export { HOUSEHOLDER_FRAGMENT } from '../questions/Householder';
export { ADJ_TARGET_FIELDS_FRAGMENT } from '../employee_pages/Finished';

export const EMPLOYEE_DETAIL_FRAGMENT = gql`
  fragment EmployeeDetail on Employee {
    email
    staffCode
    joinedOn
    resignedOn
    resignType
    status
    hasNoPayment
    isNonResident
    employmentType
    position
  }
`;

export const EMPLOYEE_TYPES_POSITIONS = gql`
  query employeeTypesPositions($year: Int!) {
    client {
      id
      clientYearly(year: $year) {
        id
        employmentTypes
        positions
      }
    }
  }
`;

const withNull = lst => [...(lst || [])];

const resignTypes = [
  { value: 'others', label: '一般退職' },
  { value: 'disability', label: '障害退職' },
  { value: 'passed_away', label: '死亡退職' }
];

const isNonResidentOptions = [
  { value: 'false', label: '対象外' },
  { value: 'true', label: '非居住者' }
];

const BasicInformation = ({ employee }) => {
  const year = useYear();
  const { data, loading } = useQuery(EMPLOYEE_ADDRESS_CLASSIFICATION, { variables: { year } });
  const resignedOn = useFormValue('basicInformation.resignedOn');
  const relationship = useFormValue('basicInformation.relationship');
  const salaryMoreThanCurrent = useFormValue('basicInformation.salaryMoreThanCurrent');
  const workForMultiCompanies = useFormValue('basicInformation.workForMultiCompanies');
  const isDisasterSufferer = useFormValue('basicInformation.isDisasterSufferer');
  const isNonResident = useFormValue('basicInformation.isNonResident');
  const other = useFormValue('basicInformation.other');
  const autofill = useAutofill();
  const dispatch = useDispatch();
  const { formName } = useFormName();
  const type = useQuery(EMPLOYEE_TYPES_POSITIONS, { variables: { year } });
  if (loading || type.loading) {
    return null;
  }
  const {
    client: {
      clientYearly: {
        clientSetting: { employeeAddressClassification }
      }
    }
  } = data;
  const employmentTypes = withNull(_.compact(type.data.client.clientYearly.employmentTypes).map(type => ({ value: type, label: type })));
  const positions = withNull(_.compact(type.data.client.clientYearly.positions).map(type => ({ value: type, label: type })));
  const employmentTypesDisabled = employmentTypes.length == 0;
  const positionsDisabled = positions.length == 0;
  return (
    <Section title="基本情報">
      <FormSection name="basicInformation">
        <LabelMapper name="basicInformation" label="基本情報の" />
        <DateField name="joinedOn" label="入社日" example="例）1981/01/23、S56.1.23" required />
        <DateField name="resignedOn" label="退職日" example="例）1981/01/23、S56.1.23" />
        {resignedOn && <RadioField name="resignType" label="退職区分" options={resignTypes} />}
        <Name required />
        <DateField name="birthday" label="生年月日" viewMode="years" example="例）1981/01/23、S56.1.23" required />
        <RadioField name="gender" label="性別" options={genderOptions} required />
        <TextField name="email" label="メールアドレス" disabled />
        <TextField name="staffCode" label="スタッフコード" disabled />
        <SelectField
          disabled={employmentTypesDisabled}
          placeholder={employmentTypesDisabled && '–'}
          options={employmentTypes}
          name="employmentType"
          label="雇用形態"
        />
        <SelectField disabled={positionsDisabled} placeholder={positionsDisabled && '–'} options={positions} name="position" label="役職" />
        <div className="u-mb20">
          ※こちらで変更した「雇用形態」「役職」は労務HR、給与計算の従業員情報には自動で反映されませんのでそれぞれのサービスでも変更が必要です。
        </div>
        <RadioField
          label="非居住者区分"
          name="isNonResident"
          {...toBooleanProps}
          options={isNonResidentOptions}
          note={
            <div>
              一般的に国内に住所を有する場合は対象外（居住者）、国外に住所を有する場合は非居住者に区分します。 詳細は
              <a href="https://www.nta.go.jp/taxes/shiraberu/taxanswer/gensen/2875.htm" rel="noreferrer" target="_blank">
                国税庁のページ
              </a>
              をご確認ください。
            </div>
          }
        />
        {!isNonResident && (
          <Address
            withKana={false}
            required
            autofillCityCode={({ name, code }) => {
              if (employeeAddressClassification === 'profile') {
                autofill('residentTax.name', name);
                autofill('residentTax.code', code);
              }
            }}
            year={year}
          />
        )}
        <SelectField
          name="relationship"
          label="世帯主の続柄"
          options={relationships}
          creatable
          note="リストにない続柄は直接入力して追加できます"
        />
        {relationship && relationship !== '本人' && <TextField name="name" label="世帯主の氏名" />}
        <RadioField name="nationalType" label="外国人区分" options={nationalTypes} required />
        <RadioField
          name="isDisasterSufferer"
          label="（源泉徴収票）災害者"
          {...toBooleanProps}
          options={isDisasterSuffererOptions}
          required
        />
        {isDisasterSufferer && <TextField name="taxPostponementAmount" label="徴収猶予税額" required validate={amount} />}
        <div className="u-mb10">年末調整対象外の場合の対象外理由</div>
        <CheckboxField
          label="年内退職予定（12月給与支給前に退職予定）"
          name="resignBeforeYearEnd"
          onChange={(e, value) => {
            if (value) {
              dispatch(change(formName, 'basicInformation.isNotCoveredByNextYear', true));
            }
          }}
        />
        <CheckboxField
          label="今年、他社の給与のほうが多いため（税額表区分で「乙欄」に該当）"
          name="salaryMoreThanCurrent"
          onChange={(e, value) => {
            if (value) {
              dispatch(change(formName, 'basicInformation.workForMultiCompanies', true));
            } else {
              dispatch(change(formName, 'basicInformation.workForMultiCompanies', false));
              dispatch(change(formName, 'basicInformation.salaryLessThanOthersNextYear', false));
            }
          }}
        />
        <div className="u-ml30">
          <CheckboxField
            label="2社以上勤務"
            name="workForMultiCompanies"
            onChange={(e, value) => {
              if (value === false) {
                dispatch(change(formName, 'basicInformation.salaryMoreThanCurrent', false));
                dispatch(change(formName, 'basicInformation.salaryLessThanOthersNextYear', false));
              }
            }}
          />
          <CheckboxField
            label="今年、他社の給与のほうが多い"
            name="salaryMoreThanCurrent"
            disabled={!workForMultiCompanies}
            onChange={(e, value) => {
              if (value === false) {
                dispatch(change(formName, 'basicInformation.salaryLessThanOthersNextYear', false));
              }
            }}
          />
          <CheckboxField
            label="来年、他社の給与のほうが多い（翌年の年末調整も対象外）"
            name="salaryLessThanOthersNextYear"
            disabled={!salaryMoreThanCurrent || !workForMultiCompanies}
          />
        </div>
        <CheckboxField label="今年支払った金額がない" name="hasNoPayment" />
        <AutoField InputField={renderCheckBoxField} label="給与が2000万円を超える" value={employee.salaryGreaterThan20Million} />
        <CheckboxField label="その他" name="other" />
        {other && (
          <div className="u-ml30">
            <TextField name="otherReason" label="" validate={maxLength(31)} />
          </div>
        )}
        <Line />
        <CheckboxField label="来年度年末調整対象外" name="isNotCoveredByNextYear" />
      </FormSection>
    </Section>
  );
};

export const formatValues = values => {
  const { workForMultiCompanies, salaryMoreThanCurrent, ...rest } = values.basicInformation;
  return {
    ...values,
    basicInformation: {
      ...rest,
      workForMultiCompanies: workForMultiCompanies,
      salaryMoreThanOthers: workForMultiCompanies && !salaryMoreThanCurrent
    }
  };
};

export const makeInitialValues = (employee, profile) => ({
  ..._.omit(toFormValues(employee), ['status', 'id', 'salaryGreaterThan20Million', 'taxSchedule', 'residentsTaxCollectionMethod']),
  ..._.omit(toFormValues(profile, true), [
    'maritalStatus',
    'hasSpouseDeduction',
    'needAdjThisYear',
    'nextYearOnly',
    'hasSpouseDeductionThisYear'
  ]),
  ...toFormValues(profile.householder, true),
  salaryMoreThanCurrent: profile.workForMultiCompanies && !profile.salaryMoreThanOthers
});

export default BasicInformation;
