import React from 'react'
import Icon from 'react-icon-base'

const Check = props => (
  <Icon width="10" height="9" viewBox="0 0 10 9" {...props}>
    <path fill='currentColor' d='M3.315 9.027a1.079 1.079 0 0 1-.905-.498L.162 4.964a1.054 1.054 0 0 1 .34-1.46c.5-.31 1.159-.158 1.471.337l1.452 2.304L8.101.395a1.074 1.074 0 0 1 1.502-.16c.459.367.53 1.033.16 1.489L4.147 8.632a1.07 1.07 0 0 1-.831.395z' />
  </Icon>
)

Check.defaultProps = {
  size: 10
}

export default Check
