import React from 'react'
import Icon from 'react-icon-base'

const Strage = props => (
  <Icon viewBox="0 0 15 16" {...props}>
    <g fill="none" transform="translate(1 1)">
      <path d="M4.7 14H.997a.927.927 0 0 1-.388-.084A1.075 1.075 0 0 1 0 12.929V1.071C0 .48.446 0 .996 0h9.793L13 2.32v10.61c0 .59-.446 1.07-.996 1.07H4.701z" fill="none" stroke="currentColor" strokeLinecap="round"/>
      <path d="M2.286 5.167h8.025V1.834H2.286z" stroke="currentColor"/>
      <rect height="5.417" width="8.609" rx="1" stroke="currentColor" x="2.196" y="6.917"/>
      <rect height="1" width="5.087" fill="currentColor" rx=".5" x="3.957" y="10.5"/>
      <rect height="1" width="5.087" fill="currentColor" rx=".5" x="3.957" y="9.333"/>
      <rect height="1" width="5.087" fill="currentColor" rx=".5" x="3.957" y="8.167"/>
      <path d="M8.115 2.333h1.696v2.333H8.115z" fill="currentColor"/>
    </g>
  </Icon>
)
Strage.defaultProps = {
  size: 16
}

export default Strage
