import { FC } from 'react';
import { HousingLoan as HousingLoanTemplate } from 'features/questions/templates/HousingLoan';
import { useWizard } from '../WizardProvider';
import { Radio } from 'components/form/Radio';
import { InputWithText } from 'components/form/InputWithText';
import { FileField } from 'components/form/FileField';
import { SelectField } from 'components/form/SelectField';
import { DateField } from 'components/form/DateField';
import moment from 'moment';
import { FilePreview } from 'components/feature/FilePreview';
import { amountFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { useYear } from 'hooks/useYear';
import { SpecialDeduction } from '../../../query';

const deductionClassifications = [
  { value: 'normal', label: '住 [一般の住宅借入金等特別控除（増改築等を含む）]' },
  {
    value: 'special_case_house_normal',
    label: '住(特家) [一般の住宅借入金等特別控除の場合（増改築等を含む。）で住宅が特例居住用家屋に該当するとき]'
  },
  { value: 'ceritified', label: '認 [認定住宅の新築等に係る住宅借入金等特別控除]' },
  {
    value: 'special_case_house_certified',
    label: '認（特家）[認定住宅等の新築等に係る住宅借入金等特別控除の場合で住宅が特例認定住宅等に該当するとき]'
  },
  { value: 'extension', label: '増 [特定増改築等住宅借入金等特別控除]' },
  {
    value: 'earthquake',
    label: '震 [震災特例法第13条の2第１項「住宅の再取得等に係る住宅借入金等特別控除」の規定の適用を選択した場合]'
  },
  {
    value: 'special_case_house_earthquake',
    label: '震（特家）[震災再取得等の適用を選択した場合で住宅が特例居住用家屋に該当するとき]'
  }
];

const specialDeductionOptions = [
  { value: 'none', label: '対象外' },
  { value: 'applied', label: '特定取得' },
  { value: 'special_applied', label: '特別特定取得' },
  { value: 'special_exception_applied', label: '特例特別特例取得' }
];

interface SpecialDeductionFieldProps {
  label: string;
  note: string;
  value: SpecialDeduction | undefined;
}

const SpecialDeductionField: FC<SpecialDeductionFieldProps> = ({ label, note, value }) => {
  const year = useYear();
  if (year <= 2023) {
    return (
      <Radio.Container label={label} note={note} required>
        <div>
          <Radio checked={value === 'none'} disabled>
            対象外
          </Radio>
          <Radio checked={value === 'applied'} disabled>
            特定取得
          </Radio>
          <Radio checked={value === 'special_applied'} disabled>
            特別特定取得
          </Radio>
          <Radio checked={value === 'special_exception_applied'} disabled>
            特例特別特例取得
          </Radio>
        </div>
      </Radio.Container>
    );
  } else {
    return (
      <SelectField
        label={label}
        note={note}
        required
        options={specialDeductionOptions}
        value={specialDeductionOptions.find(opt => opt.value === value)}
        disabled
      />
    );
  }
};

export const HousingLoan: FC = () => {
  const { question, request } = useWizard();
  const housingLoan = request.profile?.housingLoan;

  if (!housingLoan) {
    return null;
  }
  const applicableCount = Number(housingLoan.housingLoanDeductionApplicableCount);
  const firstHousingLoanDetail = housingLoan.housingLoanDetails.find(detail => detail.no === 1);
  const secondHousingLoandDetail = housingLoan.housingLoanDetails.find(detail => detail.no === 2);

  const file = housingLoan.image;
  const certificateFile = housingLoan.certificateImage;

  return (
    <HousingLoanTemplate question={question}>
      <Radio.Container label="住宅借入金等 特別控除適用数" note="適用数3以上の場合管理者にお問い合わせください。" required>
        <Radio checked={applicableCount === 1} disabled>
          1
        </Radio>
        <Radio checked={applicableCount === 2} disabled>
          2
        </Radio>
      </Radio.Container>

      <InputWithText
        label="住宅借入金特別控除額"
        text="円"
        value={amountFormat(housingLoan.housingLoanDeductionApplicableAmount)}
        required
        disabled
      />

      {applicableCount >= 1 && (
        <>
          <DateField
            label="居住開始年月日（1回目）"
            value={moment(firstHousingLoanDetail?.residenceStartOn).format('YYYY/MM/DD')}
            note="住宅借入金等特別控除申告書の㋑または㋠を転記してください。"
            required
            disabled
          />

          <SelectField
            label="住宅借入金等特別控除区分（1回目）"
            required
            options={deductionClassifications}
            value={deductionClassifications.find(
              classification => classification.value === firstHousingLoanDetail?.deductionClassification
            )}
            disabled
          />

          <SpecialDeductionField
            label="住宅借入金等特別控除区分（1回目）　特定"
            note="住宅借入金等特別控除申告書の㋑または㋠に（特定）等の表記がある場合に選択してください。"
            value={firstHousingLoanDetail?.specialDeduction}
          />

          <InputWithText
            label="住宅借入金等 年末残高（1回目）"
            text="円"
            required
            value={amountFormat(firstHousingLoanDetail?.yearEndBalance)}
            disabled
          />
        </>
      )}

      {applicableCount >= 2 && (
        <>
          <DateField
            label="居住開始年月日（2回目）"
            value={moment(secondHousingLoandDetail?.residenceStartOn).format('YYYY/MM/DD')}
            note="住宅借入金等特別控除申告書の㋠を転記してください。"
            required
            disabled
          />

          <SelectField
            label="住宅借入金等特別控除区分（2回目）"
            options={deductionClassifications}
            value={deductionClassifications.find(
              classification => classification.value === secondHousingLoandDetail?.deductionClassification
            )}
            required
            disabled
          />

          <SpecialDeductionField
            label="住宅借入金等特別控除区分（2回目）　特定"
            note="住宅借入金等特別控除申告書の㋠に（特定）等の表記がある場合に選択してください。"
            value={secondHousingLoandDetail?.specialDeduction}
          />

          <InputWithText
            label="住宅借入金等 年末残高（2回目）"
            text="円"
            value={amountFormat(secondHousingLoandDetail?.yearEndBalance)}
            disabled
            required
          />
        </>
      )}

      <FileField label="住宅借入金等特別控除申告書の画像" note="対応可能形式：jpg, jpeg, gif, png, pdf" disabled>
        {file && (
          <FileField.FileContainer>
            <FileField.File file={file} />
            <FilePreview file={file} />
          </FileField.FileContainer>
        )}
      </FileField>

      <FileField label="住宅ローンの年末残高証明書の画像" note="対応可能形式：jpg, jpeg, gif, png, pdf" disabled>
        {certificateFile && (
          <FileField.FileContainer>
            <FileField.File file={certificateFile} />
            <FilePreview file={certificateFile} />
          </FileField.FileContainer>
        )}
      </FileField>
    </HousingLoanTemplate>
  );
};
