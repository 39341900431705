import { FC, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Modal } from 'jbc-front/components/presenters/ui/Modal';
import Button from 'jbc-front/components/Button';
import { Spacer } from 'jbc-front/components/presenters/Spacer';
import { Note } from 'jbc-front/components/presenters/ui/Note';
import { Checkbox } from 'jbc-front/components/presenters/form/Checkbox';
import { FormSection } from 'components/form/FormSection';
import { Input } from 'components/form/Input';
import { Separator } from 'components/ui/Separator';
import { MOVE_NOT_NEED_ADJ, convertFormDataToQueryVariables } from './mutation';
import { Employee, FETCH_EMPLOYEE } from '../query';
import { SelectField } from 'components/react-hook-form/SelectField';
import { schema, Schema, generateDefaultValues } from './schema';
import styles from './MoveNotNeedAdjModal.scss';

// @ts-ignore
import { useNotify } from 'actions';
// @ts-ignore
import { useMutation } from 'components/Graphql';

type MoveNotNeedAdjModalProps = {
  employee: Employee;
  isOpen: boolean;
  onClose: () => void;
};

const options = [
  { label: '退職済または12月給与支給前に退職予定', value: 'resign_before_year_end' },
  { label: '今年、他社給与の方が多いため（税額表区分で乙欄に該当）', value: 'salary_more_than_others' },
  { label: '今年支払った給与がない', value: 'has_no_payment' },
  { label: 'その他', value: 'other' }
];

export const MoveNotNeedAdjModal: FC<MoveNotNeedAdjModalProps> = ({ employee, isOpen, onClose }) => {
  const [moveNotNeedAdj, { loading }] = useMutation(MOVE_NOT_NEED_ADJ);
  const notify = useNotify();

  const methods = useForm<Schema>({
    defaultValues: generateDefaultValues(employee),
    resolver: yupResolver(schema)
  });

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors }
  } = methods;

  const onSubmit = async (data: Schema) => {
    try {
      const variables = convertFormDataToQueryVariables(String(employee.id), data);
      await moveNotNeedAdj({ variables, refetchQueries: [FETCH_EMPLOYEE] });
      notify('保存しました', 'success');
      onClose();
    } catch {
      notify('年末調整対象外にできませんでした', 'error');
    }
  };

  const reason = watch('reason');
  const isNotNeedAdj = watch('isNotNeedAdj');
  const salaryMoreThanOthersNextYear = watch('salaryMoreThanOthersNextYear');

  useEffect(() => {
    if (!isNotNeedAdj) {
      setValue('reason', undefined);
      setValue('otherReason', undefined);
      setValue('salaryMoreThanOthersNextYear', false);
    }
  }, [isNotNeedAdj]);

  useEffect(() => {
    if (salaryMoreThanOthersNextYear) {
      setValue('isNotCoveredByNextYear', true);
    }
  }, [salaryMoreThanOthersNextYear]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header onClose={onClose}>年末調整対象外にする</Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <FormSection>
          <FormProvider {...methods}>
            <Checkbox defaultChecked={isNotNeedAdj} {...register('isNotNeedAdj')}>
              今年の年末調整対象外
            </Checkbox>

            {isNotNeedAdj && (
              <>
                <SelectField
                  label="対象外理由"
                  options={options}
                  name="reason"
                  error={errors.reason?.message}
                  value={options.find(option => option.value === reason)}
                />
                {reason === 'other' && (
                  <Input
                    label="その他（理由）"
                    error={errors.otherReason?.message}
                    isError={!!errors.otherReason?.message}
                    {...register('otherReason')}
                  />
                )}
                {reason === 'salary_more_than_others' && (
                  <Checkbox {...register('salaryMoreThanOthersNextYear')}>来年も他社給与の方が多い（来年も乙欄に該当）</Checkbox>
                )}
                <Separator />
              </>
            )}

            <Checkbox {...register('isNotCoveredByNextYear')}>来年の年末調整対象外</Checkbox>
            <Note>チェックすると来年度の給与所得者の扶養控除等（異動）申告書が出力されなくなります。</Note>
          </FormProvider>
        </FormSection>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} disabled={loading}>
          キャンセル
        </Button>
        <Spacer direction="x" size={20} />
        <Button primary onClick={handleSubmit(onSubmit)} disabled={loading}>
          保存する
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
