import React from 'react'
import Icon from 'react-icon-base'

const Custom18 = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="icon/employees/Custom18" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group" transform="translate(8.000000, 2.000000)" fill={props.color} fillRule="nonzero">
        <path d="M22,0 C23.1045695,0 24,0.8954305 24,2 L24,34 C24,35.1045695 23.1045695,36 22,36 L2,36 C0.8954305,36 0,35.1045695 0,34 L0,2 C0,0.8954305 0.8954305,0 2,0 L22,0 Z M22,2 L2,2 L2,34 L22,34 L22,2 Z" id="Rectangle"></path>
        <path d="M20,3 C20.5522847,3 21,3.44771525 21,4 L21,27 C21,27.5522847 20.5522847,28 20,28 L4,28 C3.44771525,28 3,27.5522847 3,27 L3,4 C3,3.44771525 3.44771525,3 4,3 L20,3 Z M19.5,4.5 L4.5,4.5 L4.5,26.5 L19.5,26.5 L19.5,4.5 Z" id="Rectangle-Copy"></path>
        <path d="M12,29 C13.1045695,29 14,29.8954305 14,31 C14,32.1045695 13.1045695,33 12,33 C10.8954305,33 10,32.1045695 10,31 C10,29.8954305 10.8954305,29 12,29 Z M12,30 C11.4477153,30 11,30.4477153 11,31 C11,31.5522847 11.4477153,32 12,32 C12.5522847,32 13,31.5522847 13,31 C13,30.4477153 12.5522847,30 12,30 Z" id="Oval"></path>
      </g>
    </g>
  </Icon>
)
Custom18.defaultProps = {
  size: 30,
  color: "#3498DB"
}

export default Custom18