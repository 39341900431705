import React, { useState } from 'react';
import _ from 'lodash';
import styles from './FileImport.scss';
import classnames from 'classnames';

const MAX_LIST_ITEM = 4;

export const renderList = ({ title = '下記より一つ選択してください', texts = [], notice }) => {
  return (
    <>
      <p>下記より一つ選択してください</p>
      <ul>
        {texts.map((text, i) => (
          <li key={i}>{`・${text}`}</li>
        ))}
      </ul>
      {notice && <p>{notice}</p>}
    </>
  );
};

export const renderToggleList = ({ title = '下記より一つ選択してください', texts = [], notice }) => () => {
  const [isShowMore, setIsShowMore] = useState(false);
  const _texts = isShowMore ? texts : texts.slice(0, MAX_LIST_ITEM);
  return (
    <>
      <p>下記より一つ選択してください</p>
      <ul>
        {_texts.map((text, i) => (
          <li key={i}>{`・${text}`}</li>
        ))}
      </ul>
      <button className={styles.more} onClick={() => setIsShowMore(!isShowMore)}>
        {isShowMore ? '隠す' : 'もっと見る'}
      </button>
      {notice && <p>{notice}</p>}
    </>
  );
};

export const FileImportDescription = ({ data }) =>
  data ? (
    <div className="l-wrap-xl">
      <div className="l-overflow-scroll">
        <table className={classnames('m-table-description', styles.spTableWidth)}>
          <thead>
            <tr>
              <th className="m-table-description-item-name">データ項目名</th>
              <th>説明文</th>
              <th className="m-table-description-example">例</th>
            </tr>
          </thead>
          <tbody>
            {data.map(({ name, description = '-', example = '-', notice, required }, i) => (
              <tr key={i}>
                <th>
                  {name}
                  {required && <span className={styles.required}>必須</span>}
                </th>
                <td>
                  {_.isFunction(description) ? (
                    <>
                      {description()}
                      {notice && <p>{notice}</p>}
                    </>
                  ) : (
                    <>
                      {description}
                      {notice && <p>{notice}</p>}
                    </>
                  )}{' '}
                </td>
                <td>{example}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  ) : null;

export default FileImportDescription;
