import React from 'react';
import Button from 'jbc-front/components/Button';
import { useMutation, useQuery } from '../components/Graphql';
import { gql } from '@apollo/client';
import { TextField, SelectField, CheckboxField, DateField, FileField, RadioField, AmountField } from '../components/FieldWithDiff';
import { toFormValues } from '../utils/form';
import _ from 'lodash';
import { amount } from '../validators';
import fp from 'lodash/fp';
import { Panel, Headding, Item, Lead, Description, Buttons } from '../components/PrimaryPanel';
import LoadingPage from '../components/LoadingPage';
import useReactRouter from 'use-react-router';
import { useFormValue, reduxForm } from '../components/FormName';
import { CustomDescription } from '../components/QuestionDescription';
import FilePreview from '../components/FilePreview';
import { ToggleDivImage } from '../components/ToggleDivImage';
import image from '../../images/img-Premium_Deduction_Mortgage_Loan_Insurance.png';
import { amountFormat } from '@jbc-year-end-adj/common/utils/formatter';
import styles from './HousingLoan.scss';

const deductionClassificationsFor2023 = [
  { value: 'normal', label: '住 [一般の住宅借入金等特別控除（増改築等を含む）]' },
  {
    value: 'special_case_house_normal',
    label: '住(特家) [一般の住宅借入金等特別控除の場合（増改築等を含む。）で住宅が特例居住用家屋に該当するとき]'
  },
  { value: 'ceritified', label: '認 [認定住宅の新築等に係る住宅借入金等特別控除]' },
  {
    value: 'special_case_house_certified',
    label: '認（特家）[認定住宅等の新築等に係る住宅借入金等特別控除の場合で住宅が特例認定住宅等に該当するとき]'
  },
  { value: 'extension', label: '増 [特定増改築等住宅借入金等特別控除]' },
  {
    value: 'earthquake',
    label: '震 [震災特例法第13条の2第１項「住宅の再取得等に係る住宅借入金等特別控除」の規定の適用を選択した場合]'
  },
  {
    value: 'special_case_house_earthquake',
    label: '震（特家）[震災再取得等の適用を選択した場合で住宅が特例居住用家屋に該当するとき]'
  }
];

const baseDeductionClassifications = [
  { value: 'normal', label: '住 [一般の住宅借入金等特別控除（増改築等を含む）]' },
  { value: 'ceritified', label: '認 [認定住宅の新築等に係る住宅借入金等特別控除]' },
  { value: 'extension', label: '増 [特定増改築等住宅借入金等特別控除]' },
  {
    value: 'earthquake',
    label: '震 [震災特例法第13条の2第１項「住宅の再取得等に係る住宅借入金等特別控除」の規定の適用を選択した場合]'
  }
];
const deductionClassifications = year => {
  if (year >= 2023) {
    return deductionClassificationsFor2023;
  }
  return baseDeductionClassifications;
};

const specialDeductionClassifications = year => [
  { value: 'none', label: '対象外' },
  { value: 'applied', label: '特定取得' },
  ...(year >= 2020 ? [{ value: 'special_applied', label: '特別特定取得' }] : []),
  ...(year >= 2022 ? [{ value: 'special_exception_applied', label: '特例特別特例取得' }] : [])
];

export const HOUSING_LOAN_FRAGMENT = gql`
  fragment HousingLoan on Profile {
    housingLoan {
      id
      housingLoanDeductionApplicableCount
      housingLoanDeductionApplicableAmount
      image {
        url
        filename
      }
      certificateImage {
        url
        filename
      }
      housingLoanDetails {
        id
        deductionClassification
        residenceStartOn
        yearEndBalance
        specialDeduction
      }
    }
  }
`;

const HOUSING_LOAN = gql`
  query housingLoan {
    request {
      id
      employee {
        id
        year
      }
      profile {
        id
        ...HousingLoan
      }
    }
    clientSetting {
      id
      requestImageUploadSettings {
        housingLoan
      }
    }
  }
  ${HOUSING_LOAN_FRAGMENT}
`;

const detailFields = ['deductionClassification', 'yearEndBalance', 'residenceStartOn', 'specialDeduction'];
const detailName = fp.curry((idx, field) => `housingLoanDetails${idx}${field}`);

const isEmpty = (value = {}, prefix) => !detailFields.some(name => value |> fp.get(`${prefix}${name}`));

const requiredFields = {};
const requiredField = prefix => {
  if (!requiredFields[prefix]) {
    requiredFields[prefix] = (value, values = {}) => (!value && !isEmpty(values, prefix) ? 'を入力してください' : undefined);
  }
  return requiredFields[prefix];
};

const ANSWER_HOUSING_LOAN = gql`
  mutation answerHousingLoan($input: AnswerHousingLoanInput!) {
    answerHousingLoan(input: $input) {
      questions {
        id
        status
      }
      profile {
        id
        ...HousingLoan
      }
    }
  }
  ${HOUSING_LOAN_FRAGMENT}
`;

const housingLoanDeductionApplicableCounts = ['1', '2'].map(value => ({ value, label: value }));

export const Loans = ({ field, requestImageUpload, year }) => {
  const fieldName = field ? `${field}.` : '';
  const housingLoanDeductionApplicableCount = useFormValue(`${fieldName}housingLoanDeductionApplicableCount`);
  return (
    <>
      <RadioField
        name="housingLoanDeductionApplicableCount"
        label="住宅借入金等 特別控除適用数"
        required
        description="適用数3以上の場合管理者にお問い合わせください。"
        options={housingLoanDeductionApplicableCounts}
      />
      <AmountField
        name="housingLoanDeductionApplicableAmount"
        label="住宅借入金等特別控除額"
        required
        validate={amount}
        description={year <= 2019 && '住宅借入金等特別控除申告書の⑭を転記してください。'}
      />
      {_.range(+housingLoanDeductionApplicableCount)
        .map(i => i + 1)
        .map(idx => {
          const name = `${fieldName}housingLoanDetails`;
          return (
            <React.Fragment key={idx}>
              <DateField
                name={`housingLoanDetails${idx - 1}residenceStartOn`}
                label={`居住開始年月日（${idx}回目）`}
                validate={_.range(1, idx - 2).map(idx => requiredField(`${name}${idx}`))}
                required={idx === 1}
                description={
                  idx === 1
                    ? '住宅借入金等特別控除申告書の㋑または㋠を転記してください。'
                    : '住宅借入金等特別控除申告書の㋠を転記してください。'
                }
              />
              <SelectField
                name={`housingLoanDetails${idx - 1}deductionClassification`}
                label={`住宅借入金等特別控除区分（${idx}回目）`}
                options={deductionClassifications(year)}
                validate={_.range(1, idx - 2).map(idx => requiredField(`${name}${idx}`))}
                required={idx === 1}
              />
              <RadioField
                name={`housingLoanDetails${idx - 1}specialDeduction`}
                label={`住宅借入金等特別控除区分（${idx}回目）　特定`}
                options={specialDeductionClassifications(year)}
                description={
                  year <= 2019 || idx === 1
                    ? '住宅借入金等特別控除申告書の㋑または㋠に（特定）等の表記がある場合に選択してください。'
                    : '住宅借入金等特別控除申告書の㋠に（特定）等の表記がある場合に選択してください。'
                }
                required={idx === 1}
                isDirectionColumn
              />
              <AmountField
                name={`housingLoanDetails${idx - 1}yearEndBalance`}
                label={`住宅借入金等 年末残高(${idx}回目)`}
                validate={[..._.range(1, idx - 2).map(idx => requiredField(`${name}${idx}`)), amount]}
                required={idx === 1}
                description={
                  year <= 2019 &&
                  (idx === 1 ? '住宅借入金等特別控除申告書の⑤を転記してください。' : '住宅借入金等特別控除申告書の⑩を転記してください。')
                }
              />
            </React.Fragment>
          );
        })}
      {requestImageUpload !== 'none' && (
        <>
          <FileField
            name="image"
            label="住宅借入金等特別控除申告書の画像"
            required={requestImageUpload === 'required'}
            preview={FilePreview}
            description="対応可能形式：jpg, jpeg, gif, png, pdf"
            disablePreview={false}
          />
          <FileField
            name="certificateImage"
            label="住宅ローンの年末残高証明書の画像"
            required={requestImageUpload === 'required'}
            preview={FilePreview}
            description="対応可能形式：jpg, jpeg, gif, png, pdf"
            disablePreview={false}
          />
        </>
      )}
    </>
  );
};

const QuestionForm =
  (({ handleSubmit, requestImageUpload, year, submitting }) => (
    <div>
      <Loans requestImageUpload={requestImageUpload} year={year} />
      <Buttons>
        <Button primary onClick={handleSubmit} disabled={submitting}>
          次ヘ
        </Button>
      </Buttons>
    </div>
  )) |> reduxForm({ form: 'housingLoan' });

export const formatDetails = housingLoan => {
  const detailFieldNames = idx => ['id', ...detailFields].map(detailName(idx));
  const details =
    _.range(Number(housingLoan.housingLoanDeductionApplicableCount))
    |> fp.takeWhile(idx => !isEmpty(housingLoan, `housingLoanDetails${idx}`))
    |> fp.map(idx => ({
      ...['id', ...detailFields].reduce((ret, field) => ({ ...ret, [field]: housingLoan[detailName(idx, field)] }), {}),
      no: idx + 1
    }));

  return {
    ...(housingLoan |> ([0, 1] |> fp.flatMap(idx => detailFieldNames(idx)) |> fp.omit)),
    housingLoanDetails: details
  };
};

const Question = () => {
  const { data, loading } = useQuery(HOUSING_LOAN);
  const [answer] = useMutation(ANSWER_HOUSING_LOAN);
  const { history } = useReactRouter();
  if (loading) return <LoadingPage />;
  const {
    request: {
      profile,
      employee: { year }
    },
    clientSetting: {
      requestImageUploadSettings: { housingLoan: housingLoanImageSetting }
    }
  } = data;

  const formatHousingLoan = housingLoan => {
    return {
      ...housingLoan,
      housingLoanDeductionApplicableAmount: amountFormat(housingLoan?.housingLoanDeductionApplicableAmount)
    };
  };

  const formatLoanDetails = loanDetails => {
    return loanDetails.map(details => ({
      ...details,
      yearEndBalance: amountFormat(details.yearEndBalance)
    }));
  };

  const initialValues = {
    housingLoanDeductionApplicableCount: '1',
    ...toFormValues(formatHousingLoan(profile.housingLoan), true),
    ...Object.assign(
      {},
      ...formatLoanDetails(profile.housingLoan?.housingLoanDetails || []).map(
        (v, idx) => v |> toFormValues |> fp.mapKeys(k => `housingLoanDetails${idx}${k}`)
      )
    )
  };

  return (
    <Panel>
      <Headding className={styles.primaryPanelHeader}>住宅ローン控除</Headding>
      <Item>
        <Lead>住宅ローン控除情報を記入してください。</Lead>
        <CustomDescription field="top" />
        <ToggleDivImage title={'※下図の書類に関する設問です。'}>
          <img src={image} alt="保険料控除_住宅ローン" />
        </ToggleDivImage>
        <QuestionForm
          initialValues={initialValues}
          requestImageUpload={housingLoanImageSetting}
          year={year}
          onSubmit={async values => {
            await answer({
              variables: {
                input: formatDetails(values)
              }
            });
            history.push('/employee_input');
          }}
        />
      </Item>
    </Panel>
  );
};

export default Question;
