import React, { useRef, useEffect } from 'react';
import './PullDownMenu.scss';

const PullDownMenu = ({ children, setIsMenuOpen }) => {
  const ref = useRef(null);

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsMenuOpen(false);
    }

    event.stopPropagation();
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div ref={ref} className="c-dropdown">
      <div className="dd-menu dd-menu-right">{children}</div>
    </div>
  );
};

export default PullDownMenu;
