import React from 'react';
import { SortingFieldsWithResult } from 'jbc-front/components/SearchForm';
import { useFormValue } from '../components/FormName';
import useReactRouter from 'use-react-router';

const limits = ['10', '20', '50', '100'].map(ele => ({ value: ele, label: `${ele}件を表示` }));
const sortTypes = [
  { value: 'staff_code__asc', label: 'スタッフコード 昇順' },
  { value: 'staff_code__desc', label: 'スタッフコード 降順' },
  { value: 'full_name_kana__asc', label: '氏名 昇順' },
  { value: 'full_name_kana__desc', label: '氏名 降順' },
  { value: 'submit_on__desc', label: '提出日 新しい順' },
  { value: 'submit_on__asc', label: '提出日 古い順' },
  /*  { value: 'employment_type__asc', label: '雇用形態 設定順' },
  { value: 'employment_type__desc', label: '雇用形態 設定逆順' },*/
  { value: 'joined_on__desc', label: '入社日 新しい順' },
  { value: 'joined_on__asc', label: '入社日 古い順' },
  { value: 'resigned_on__desc', label: '退社日 新しい順' },
  { value: 'resigned_on__asc', label: '退社日 古い順' }
];

const SortingFieldsWithResultWrap = ({ sortName, ...rest }) => {
  const { location } = useReactRouter();
  const page = useFormValue('page');
  return <SortingFieldsWithResult {...{ sortName, sortTypes, limits, pathname: location.pathname, page, ...rest }} />;
};
export default SortingFieldsWithResultWrap;
