import { FC } from 'react';
import { Form } from './Form';
import { Preview } from './Preview';
import { ToggleSection, useToggleSection, ViewType } from '../../../components/ToggleSection';

type InsurancesProps = {
  setMode: (viewType: ViewType) => void;
};

export const Insurances: FC<InsurancesProps> = ({ setMode }) => {
  const { viewType, setViewType } = useToggleSection();

  const handleSetViewType = (viewType: ViewType) => {
    setMode(viewType);
    setViewType(viewType);
  };

  const handleClose = () => {
    handleSetViewType('preview');
    window.location.hash = '';
    window.location.hash = '#request-insurances';
  };

  return (
    <ToggleSection viewType={viewType} setViewType={handleSetViewType}>
      <ToggleSection.Header>生命保険・地震保険・社会保険</ToggleSection.Header>
      <ToggleSection.Body>
        {viewType === 'preview' && <Preview />}
        {viewType === 'form' && <Form onCancel={handleClose} />}
      </ToggleSection.Body>
    </ToggleSection>
  );
};
