import React from 'react'
import Icon from 'react-icon-base'

const Custom12 = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="Custom12" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon/employees/Custom7-Copy-12" fill="#3498DB" fillRule="nonzero">
        <g id="Group" transform="translate(6.000000, 2.000000)">
          <path d="M25,0 C26.1045695,0 27,0.8954305 27,2 L27,36 L0,36 L0,2 C0,0.8954305 0.8954305,0 2,0 L25,0 Z M25,2 L2,2 L2,34 L25,34 L25,2 Z" id="Rectangle"></path>
          <path d="M18,25 C19.1045695,25 20,25.8954305 20,27 L20,36 L8,36 L8,27 C8,25.8954305 8.8954305,25 10,25 L18,25 Z M18,27 L10,27 L10,34 L18,34 L18,27 Z" id="Rectangle-Copy"></path>
          <path d="M9,6 L9,12 L4,12 L4,6 L9,6 Z M7.5,7.5 L5.5,7.5 L5.5,10.5 L7.5,10.5 L7.5,7.5 Z" id="Rectangle-Copy-2"></path>
          <path d="M9,14 L9,20 L4,20 L4,14 L9,14 Z M7.5,15.5 L5.5,15.5 L5.5,18.5 L7.5,18.5 L7.5,15.5 Z" id="Rectangle-Copy-8"></path>
          <path d="M16,6 L16,12 L11,12 L11,6 L16,6 Z M14.5,7.5 L12.5,7.5 L12.5,10.5 L14.5,10.5 L14.5,7.5 Z" id="Rectangle-Copy-3"></path>
          <path d="M16,14 L16,20 L11,20 L11,14 L16,14 Z M14.5,15.5 L12.5,15.5 L12.5,18.5 L14.5,18.5 L14.5,15.5 Z" id="Rectangle-Copy-9"></path>
          <path d="M23,6 L23,12 L18,12 L18,6 L23,6 Z M21.5,7.5 L19.5,7.5 L19.5,10.5 L21.5,10.5 L21.5,7.5 Z" id="Rectangle-Copy-4"></path>
          <path d="M23,14 L23,20 L18,20 L18,14 L23,14 Z M21.5,15.5 L19.5,15.5 L19.5,18.5 L21.5,18.5 L21.5,15.5 Z" id="Rectangle-Copy-7"></path>
        </g>
      </g>
    </g>
  </Icon>
)
Custom12.defaultProps = {
  size: 30
}

export default Custom12