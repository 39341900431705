import { gql } from '@apollo/client';

export const EMPLOYEES = gql`
  query employees($per: Int, $page: Int, $search: EmployeeSearchInput, $mode: Mode!) {
    client {
      id
      clientYearly {
        id
        fixed
        selectableEmployeesCount(mode: $mode)
        employeeCountByStatus(search: $search) {
          totalCount
          notRequested
          inProgress
          applying
          rejected
          fixed
          accepted
          notNeedAdj
        }
        employees(per: $per, page: $page, search: $search) {
          totalCount
          list {
            id
            staffCode
            email
            employmentType
            position
            profile {
              id
              fullName
            }
            status
            memos {
              id
              color
            }
          }
        }
      }
    }
  }
`;
