import moment from 'moment';
import { FileType } from '../types';

// @ts-ignore
import { toJpYearDate } from 'jbc-front/utils/jpYear';

const FORMAT = {
  DEFAULT_STRING: '\u2015',
  TRUTHY_STRING: '\u25cb',
  FALSY_STRING: '\u2015'
} as const;

export const displayFormat = (value?: string): string => (value ? value : FORMAT.DEFAULT_STRING);

export const dateFormat = (date?: string, format: 'wareki' | 'seireki' | string = 'wareki'): string => {
  if (!date) return displayFormat();

  switch (format) {
    case 'wareki':
      return toJpYearDate(date);
    case 'seireki':
      return moment(date).format('LL');
    default:
      return moment(date).format(format);
  }
};

export const dateFormFormat = (date?: string, format: 'wareki' | 'seireki' | string = 'wareki'): string => {
  if (!date) return '';

  switch (format) {
    case 'wareki':
      return toJpYearDate(date);
    case 'seireki':
      return moment(date).format('LL');
    default:
      return moment(date).format(format);
  }
};

export const fullNameFormat = (lastName?: string, firstName?: string): string => {
  if (!(lastName && firstName)) return displayFormat();
  return `${lastName} ${firstName}`;
};

export const booleanFormat = (args: { value: boolean; truthyString?: string; falsyString?: string }): string => {
  if (args.value) {
    return args.truthyString === undefined ? FORMAT.TRUTHY_STRING : args.truthyString;
  } else {
    return args.falsyString === undefined ? FORMAT.FALSY_STRING : args.falsyString;
  }
};

export const optionalBooleanFormat = (booleanValue: boolean | undefined, truthyValue: string, falsyValue: string) => {
  if (booleanValue === undefined) return undefined;
  return booleanValue ? truthyValue : falsyValue;
};

export const fileFormat = (file?: FileType | File) => {
  if (file instanceof File) {
    return {
      url: '',
      name: file.name
    };
  }

  return {
    url: file?.url,
    name: file?.filename || file?.file || '未入力'
  };
};

export const fileFormFormat = (file?: FileType | File) => {
  if (file instanceof File) {
    return {
      url: '',
      name: file.name
    };
  }

  if (!file || !file?.filename || !file?.url) {
    return undefined;
  }

  return {
    url: file.url,
    name: file.filename
  };
};

export const fileSchemaFormat = (file?: FileType | File) => {
  if (file instanceof File) return file;

  if (!file || !file?.filename || !file?.url) {
    return null;
  }

  return file;
};

export const amountFormat = (amount?: string) => {
  if (!amount) return '';

  const numberAmount = Number(
    String(amount)
      .split(',')
      .join('')
  );
  return Number.isNaN(numberAmount) ? amount : numberAmount.toLocaleString('ja-JP');
};

export const eraNameFormat = (eraName: string, eraNumber: number, toHeisei?: boolean, toFirstYear?: boolean) => {
  if (toHeisei && eraName === '令和' && eraNumber === 1) {
    return '平成31';
  }

  if (toFirstYear && eraNumber === 1) {
    return `${eraName}元`;
  }

  return `${eraName}${eraNumber}`;
};

export const eraNameShortHandFormat = (eraName: string, eraNumber: number) => {
  switch (eraName) {
    case '平成':
      return `H${eraNumber}`;
    case '令和':
      return `R${eraNumber}`;
    default:
      return null;
  }
};

type DateInput = string | Date;

export const dateOrTime = (dateTime: DateInput): string => {
  if (!dateTime) {
    return displayFormat();
  }
  if (moment().isSame(dateTime, 'day')) {
    return moment(dateTime).format('LT');
  }
  if (
    moment()
      .subtract(1, 'days')
      .isSame(dateTime, 'day')
  ) {
    return '昨日';
  }
  if (
    moment()
      .subtract(2, 'days')
      .isSame(dateTime, 'day')
  ) {
    return '一昨日';
  }
  if (moment().isSame(dateTime, 'year')) {
    return moment(dateTime).format('MMMDo');
  }
  return moment(dateTime).format('LL');
};
