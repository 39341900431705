import React from 'react'
import Icon from 'react-icon-base'

const Office = props => (
  <Icon viewBox="0 0 30 30" {...props}>
    <g id="symbol" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon_office" fill="#fff">
        <circle cx="15" cy="15" r="15" fill="currentColor"/>
        <path d="M9.299 21.916h7.636V8.5H9.299v13.416zm8.386 1.5H8.549a.75.75 0 0 1-.75-.75V7.75a.75.75 0 0 1 .75-.75h9.136a.75.75 0 0 1 .75.75v14.916a.75.75 0 0 1-.75.75z"/>
        <path d="M22.75 23.416h-15a.75.75 0 0 1 0-1.5h15a.75.75 0 0 1 0 1.5"/>
        <path d="M18.435 21.916h2.975V11.794h-2.975v10.122zm3.724 1.5h-4.474a.75.75 0 0 1-.75-.75V11.044a.75.75 0 0 1 .75-.75h4.474a.75.75 0 0 1 .75.75v11.622a.75.75 0 0 1-.75.75zM9.799 11.7h1.354V9.409H9.799zM12.299 11.7h1.354V9.409h-1.354zM14.799 11.7h1.354V9.409h-1.354zM9.799 15.242h1.354V12.95H9.799zM12.299 15.242h1.354V12.95h-1.354zM14.799 15.242h1.354V12.95h-1.354zM9.799 18.784h1.354v-2.292H9.799zM12.299 18.784h1.354v-2.292h-1.354zM14.799 18.784h1.354v-2.292h-1.354z"/>
      </g>
    </g>
  </Icon>
)
Office.defaultProps = {
  size: 30
}

export default Office
