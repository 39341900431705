import React from 'react';
import Button from 'jbc-front/components/Button';
import Hint from 'jbc-front/components/Hint';
import { reduxForm, useFormValue, useAutofill } from '../components/FormName';
import { Panel, Headding, Item, Lead, Notes, Buttons, Line } from '../components/PrimaryPanel';
import { CustomDescription } from '../components/QuestionDescription';
import { FormPlaceholder } from '../components/FormPlaceholder';
import { DescriptionEdit } from '../components/DescriptionEdit';

const QuestionForm =
  (({ handleSubmit, submitting }) => {
    const classification = useFormValue('classification');
    const maritalStatus = useFormValue('maritalStatus');
    const { autofill } = useAutofill();
    return (
      <Panel>
        <Headding icon={<DescriptionEdit />}>配偶者情報</Headding>
        <Item>
          <Lead>配偶者について、該当するものを選んでください。</Lead>
          <CustomDescription field="top" />
          <Buttons>
            <Button
              huge
              secondary
              primary={maritalStatus === 'married'}
              onClick={() => {
                autofill('maritalStatus', 'married');
                autofill('classification', 'none');
              }}
            >
              配偶者あり
            </Button>
            <Button
              huge
              secondary
              primary={maritalStatus === 'unmarried'}
              onClick={() => {
                autofill('maritalStatus', 'unmarried');
                if (classification === 'normal') {
                  autofill('classification', 'none');
                }
              }}
            >
              配偶者なし
              <br />
              （未婚）
            </Button>
            <Button
              huge
              secondary
              primary={maritalStatus === 'divorced_or_bereavement'}
              onClick={() => autofill('maritalStatus', 'divorced_or_bereavement')}
            >
              配偶者なし
              <br />
              （離婚・死別等）
            </Button>
          </Buttons>

          {maritalStatus !== 'married' && (
            <div>
              <Line />
              <Lead>あなたは以下のいずれかに該当しますか？</Lead>
              <Notes>
                <div>
                  <p>
                    ひとり親とは、婚姻をしていないこと又は配偶者の生死の明らかでない一定の人のうち、次の三つの要件の全てに当てはまる人です。
                  </p>
                  <ul>
                    <li>・その人と事実上婚姻関係と同様の事情にあると認められる一定の人がいないこと。</li>
                    <li>
                      ・生計を一にする子がいること。
                      <Hint
                        text={
                          <div>
                            「生計を一にする子」とは、総所得金額等が48万円以下で、他の人の控除対象配偶者や扶養親族になっていない人のことです。
                            「合計所得金額」とは、各種損失の繰越控除を適用する前の総所得金額、譲渡所得、配当所得、雑所得、山林所得、退職所得の合計額をいいます。
                            詳しくは
                            <a href="https://www.nta.go.jp/m/taxanswer/1180_qa.htm#q1" target="_blank" rel="noopener noreferrer">
                              こちら
                            </a>
                          </div>
                        }
                      />
                    </li>
                    <li>
                      ・合計所得金額が500万円以下であること。
                      <Hint
                        text={
                          <div>
                            「合計所得金額」とは、各種損失の繰越控除を適用する前の総所得金額、譲渡所得、配当所得、雑所得、山林所得、退職所得の合計額をいいます。詳しくは
                            <a href="https://www.nta.go.jp/m/taxanswer/1170.htm#aa1" target="_blank" rel="noopener noreferrer">
                              こちら
                            </a>
                          </div>
                        }
                      />
                    </li>
                  </ul>
                </div>
                <div className="u-mt20">
                  <p>
                    寡婦とは、「ひとり親」に該当せず、次のいずれかに当てはまる人です。納税者と事実上婚姻関係と同様の事情にあると認められる一定の人がいる場合は対象となりません。
                  </p>
                  <ul className="m-list-notes">
                    <li>・夫と離婚した後婚姻をしておらず、扶養親族がいる人で、合計所得金額が500万円以下の人</li>
                    <li>・夫と死別した後婚姻をしていない人又は夫の生死が明らかでない一定の人で、合計所得金額が500万円以下の人</li>
                  </ul>
                </div>
              </Notes>

              <Buttons>
                <Button huge secondary primary={classification === 'none'} onClick={() => autofill('classification', 'none')}>
                  対象外
                </Button>
                <Button
                  huge
                  secondary
                  primary={classification === 'single_parent'}
                  onClick={() => autofill('classification', 'single_parent')}
                >
                  ひとり親
                </Button>
                <Button huge secondary primary={classification === 'normal'} onClick={() => autofill('classification', 'normal')}>
                  寡婦
                </Button>
              </Buttons>

              {classification !== 'none' && (
                <React.Fragment>
                  <Lead>以下の情報の記入が必要です。</Lead>
                  <FormPlaceholder />
                  <Buttons>
                    <Button primary disabled>
                      次ヘ
                    </Button>
                  </Buttons>
                </React.Fragment>
              )}
            </div>
          )}
        </Item>
      </Panel>
    );
  }) |> reduxForm({ form: 'widow' });

const Question = ({ handleSubmit }) => {
  return <QuestionForm initialValues={{ maritalStatus: 'married', classification: 'none' }} />;
};

export default Question;
