import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { Row } from 'components/form/Row';
import { Input } from 'components/form/Input';
import { Radio } from 'components/form/Radio';
import { FileField } from 'components/react-hook-form/FileField';
import { Schema } from '../schema';
import { FilePreview } from 'components/feature/FilePreview';
import { useYear } from 'hooks/useYear';
import { calcNewTypeDeduction, calcOldTypeDeduction } from 'utils/calcurators/lifeInsurance';
import { AmountField } from 'components/react-hook-form/AmountField';

type GeneralLifeInsuranceType = {
  index: number;
  newTypeTotalAmount: number;
  oldTypeTotalAmount: number;
};

export const GeneralLifeInsurance: FC<GeneralLifeInsuranceType> = ({ index, newTypeTotalAmount, oldTypeTotalAmount }) => {
  const year = useYear();
  const {
    formState: { errors },
    watch,
    register,
    setValue
  } = useFormContext<Schema>();

  const image = watch(`lifeInsurances.${index}.image`);

  const newOrOld = watch(`lifeInsurances.${index}.newOrOld`);

  const deductionLimitAlert = () => {
    const newTypeDeduction = calcNewTypeDeduction(newTypeTotalAmount, year);
    const oldTypeDeduction = calcOldTypeDeduction(oldTypeTotalAmount, year);

    if (oldTypeDeduction >= 50000) {
      return '一般の生命保険料の控除額の上限を超過しています。この金額を超えて一般の生命保険料の申告をしても控除額に変更は生じません。';
    }

    const isOverOldTypeDeduction = oldTypeDeduction >= 40000;
    const isOverTotalDeduction = oldTypeDeduction + newTypeDeduction >= 40000;
    if ((isOverOldTypeDeduction || isOverTotalDeduction) && newOrOld === 'new_type') {
      return '一般の生命保険料（新）の控除額の上限を超過しています。この金額を超えて一般の生命保険料（新）の申告をしても控除額に変更は生じません。一般の生命保険料（旧）は控除額の上限を超過していないため、申告いただいた場合控除額が増加する余地があります。';
    }

    return undefined;
  };

  return (
    <>
      <Radio.Container
        label="新・旧の区分"
        required
        note="保険料控除証明書の表記をご確認ください"
        error={errors.lifeInsurances && errors.lifeInsurances[index]?.newOrOld?.message}
      >
        <Radio {...register(`lifeInsurances.${index}.newOrOld`)} value="new_type">
          新
        </Radio>
        <Radio {...register(`lifeInsurances.${index}.newOrOld`)} value="old_type">
          旧
        </Radio>
      </Radio.Container>
      <AmountField
        label="保険料金額"
        required
        placeholder="例：100000"
        {...register(`lifeInsurances.${index}.amount`)}
        error={(errors.lifeInsurances && errors.lifeInsurances[index]?.amount?.message) || deductionLimitAlert()}
        isError={errors.lifeInsurances && !!errors.lifeInsurances[index]?.amount?.message}
      />
      <Row>
        <Input
          label="保険会社等の名称"
          required
          {...register(`lifeInsurances.${index}.name`)}
          error={errors.lifeInsurances && errors.lifeInsurances[index]?.name?.message}
          isError={errors.lifeInsurances && !!errors.lifeInsurances[index]?.name?.message}
        />
        <Input
          label="保険等の種類"
          required
          note="保険料控除証明書に記載された保険種類を記入してください"
          noteMaxLength={8}
          {...register(`lifeInsurances.${index}.insuranceType`)}
          error={errors.lifeInsurances && errors.lifeInsurances[index]?.insuranceType?.message}
          isError={errors.lifeInsurances && !!errors.lifeInsurances[index]?.insuranceType?.message}
        />
      </Row>
      <Row>
        <Input
          label="保険期間"
          required
          note="保険料控除証明書に記載された保険期間を記入してください"
          noteMaxLength={8}
          placeholder="例：終身、10年　等"
          {...register(`lifeInsurances.${index}.insuranceDuration`)}
          error={errors.lifeInsurances && errors.lifeInsurances[index]?.insuranceDuration?.message}
          isError={errors.lifeInsurances && !!errors.lifeInsurances[index]?.insuranceDuration?.message}
        />
        <Input
          label="契約者氏名"
          required
          note="保険料控除証明書に記載された契約者の氏名を記入してください"
          noteMaxLength={8}
          placeholder="例：ジョブカン　太朗"
          {...register(`lifeInsurances.${index}.nameOfContractor`)}
          error={errors.lifeInsurances && errors.lifeInsurances[index]?.nameOfContractor?.message}
          isError={errors.lifeInsurances && !!errors.lifeInsurances[index]?.nameOfContractor?.message}
        />
      </Row>
      <Row>
        <Input
          label="受取人・氏名"
          required
          note="保険料控除証明書に記載された受給人の氏名を記入してください"
          noteMaxLength={8}
          placeholder="例：ジョブカン　花子"
          {...register(`lifeInsurances.${index}.nameOfRecipient`)}
          error={errors.lifeInsurances && errors.lifeInsurances[index]?.nameOfRecipient?.message}
          isError={errors.lifeInsurances && !!errors.lifeInsurances[index]?.nameOfRecipient?.message}
        />
        <Input
          label="受取人・続柄"
          required
          note="保険料控除証明書に記載された受給人と本人の関係を記入してください。受給人が本人の場合、「本人」を記入してください。"
          noteMaxLength={8}
          placeholder="例：本人、妻　等"
          {...register(`lifeInsurances.${index}.relationshipOfRecipient`)}
          error={errors.lifeInsurances && errors.lifeInsurances[index]?.relationshipOfRecipient?.message}
          isError={errors.lifeInsurances && !!errors.lifeInsurances[index]?.relationshipOfRecipient?.message}
        />
      </Row>
      <FileField
        label="証明書画像"
        note="管理者が確認できるよう保険料控除証明書の画像（対応可能形式：jpg, jpeg, gif, png, pdf）をアップロードしてください。原本は必ず会社に提出してください。"
        noteMaxLength={9}
        name={`lifeInsurances.${index}.image`}
        error={errors.lifeInsurances && errors.lifeInsurances[index]?.image?.message}
        isError={errors.lifeInsurances && !!errors.lifeInsurances[index]?.image?.message}
      >
        {image && (
          <FileField.FileContainer>
            <FileField.File file={image} onRemove={() => setValue(`lifeInsurances.${index}.image`, null)} />
            <FilePreview file={image} />
          </FileField.FileContainer>
        )}
      </FileField>
    </>
  );
};
