import { gql } from '@apollo/client';

export type QueryResult = {
  client: {
    id: string;
    customMail?: CustomMail;
  };
  defaultMail: DefaultMail;
};

export type CustomMail = {
  id: string;
  subject?: string;
  body?: string;
};

export type DefaultMail = {
  id: string;
  subject: string;
  header: string;
  footer: string;
};

export const FETCH_CUSTOM_MAIL = gql`
  query fetchCustomMail($emailType: EmailSearchInput!) {
    client {
      id
      customMail(emailType: $emailType) {
        id
        subject
        body
      }
    }
    defaultMail(emailType: $emailType) {
      id
      subject
      header
      footer
    }
  }
`;
