import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { ActionButton } from 'jbc-front/components/ActionButton';
import { Copy } from 'jbc-front/components/icons';

import { FormSection } from 'components/form/FormSection';
import { Radio } from 'components/form/Radio';
import { Section } from 'components/ui/Section';
import { FileField } from 'components/react-hook-form/FileField';
import { Textarea } from 'components/form/Textarea';

import { DifferentAddress } from './DifferentAddress';
import { DifferentAndForeignAddress } from './DifferentAndForeignAddress';
import { Schema } from '../schema';

import { Grid } from '../../../../../components/Grid';
import { FormSeparator } from '../../../../../components/FormSeparator';
import styles from './NextYear.scss';
import { FilePreview } from 'components/feature/FilePreview';
import { AmountField } from 'components/react-hook-form/AmountField';

type NextYearType = {
  index: number;
};

export const NextYear: FC<NextYearType> = ({ index }) => {
  const {
    formState: { errors },
    register,
    setValue,
    getValues,
    watch
  } = useFormContext<Schema>();

  const copyThisYear = () => {
    const thisYear = getValues(`otherFamilies.${index}.thisYear`);
    setValue(`otherFamilies.${index}.nextYear.dependentTaxLaw`, thisYear?.dependentTaxLaw);
    setValue(`otherFamilies.${index}.nextYear.yearlyInfo.residenceStatus`, thisYear?.yearlyInfo?.residenceStatus);
    setValue(`otherFamilies.${index}.nextYear.yearlyInfo.postcode0`, thisYear?.yearlyInfo?.postcode0);
    setValue(`otherFamilies.${index}.nextYear.yearlyInfo.postcode1`, thisYear?.yearlyInfo?.postcode1);
    setValue(`otherFamilies.${index}.nextYear.yearlyInfo.prefectureId`, thisYear?.yearlyInfo?.prefectureId);
    setValue(`otherFamilies.${index}.nextYear.yearlyInfo.city`, thisYear?.yearlyInfo?.city);
    setValue(`otherFamilies.${index}.nextYear.yearlyInfo.street`, thisYear?.yearlyInfo?.street);
    setValue(`otherFamilies.${index}.nextYear.yearlyInfo.building`, thisYear?.yearlyInfo?.building);
    setValue(`otherFamilies.${index}.nextYear.yearlyInfo.addressForeign`, thisYear?.yearlyInfo?.addressForeign);
    setValue(`otherFamilies.${index}.nextYear.yearlyInfo.isNonResident`, thisYear?.yearlyInfo?.isNonResident);
    setValue(`otherFamilies.${index}.nextYear.yearlyInfo.isStudyAbroad`, thisYear?.yearlyInfo?.isStudyAbroad);
    setValue(`otherFamilies.${index}.nextYear.yearlyInfo.remittance`, thisYear?.yearlyInfo?.remittance);
    setValue(`otherFamilies.${index}.nextYear.yearlyInfo.handicapType`, thisYear?.yearlyInfo?.handicapType);
    setValue(`otherFamilies.${index}.nextYear.yearlyInfo.handicapDetail`, thisYear?.yearlyInfo?.handicapDetail);
    setValue(`otherFamilies.${index}.nextYear.yearlyInfo.earnings`, thisYear?.yearlyInfo?.earnings);
  };

  const thisYearDependentTaxLaw = watch(`otherFamilies.${index}.thisYear.dependentTaxLaw`);
  const dependentTaxLaw = watch(`otherFamilies.${index}.nextYear.dependentTaxLaw`);
  const residenceStatus = watch(`otherFamilies.${index}.nextYear.yearlyInfo.residenceStatus`);
  const handicapType = watch(`otherFamilies.${index}.nextYear.yearlyInfo.handicapType`);
  const handicapImage = watch(`otherFamilies.${index}.nextYear.yearlyInfo.handicapImage`);

  const otherFamilyErrors = errors.otherFamilies ? errors.otherFamilies[index] : undefined;

  return (
    <Section>
      <Section.Header>来年の税の扶養</Section.Header>
      <Section.Body>
        <FormSection>
          {thisYearDependentTaxLaw === 'true' && (
            <Grid>
              <ActionButton className={styles.copyThisYear} icon={<Copy size={13} />} onClick={copyThisYear}>
                今年の扶養情報をコピーする
              </ActionButton>
            </Grid>
          )}

          <Grid>
            <Radio.Container
              label="来年の税の扶養対象"
              required
              error={otherFamilyErrors?.nextYear && otherFamilyErrors.nextYear.dependentTaxLaw?.message}
            >
              <Radio value="true" {...register(`otherFamilies.${index}.nextYear.dependentTaxLaw`)}>
                対象
              </Radio>
              <Radio value="false" {...register(`otherFamilies.${index}.nextYear.dependentTaxLaw`)}>
                対象外
              </Radio>
            </Radio.Container>
          </Grid>

          {dependentTaxLaw === 'true' && (
            <>
              <FormSeparator />
              <Grid>
                <Radio.Container label="同居・別居" required error={otherFamilyErrors?.nextYear?.yearlyInfo?.residenceStatus?.message}>
                  <Radio value="same_address" {...register(`otherFamilies.${index}.nextYear.yearlyInfo.residenceStatus`)}>
                    同居
                  </Radio>
                  <Radio value="different_address" {...register(`otherFamilies.${index}.nextYear.yearlyInfo.residenceStatus`)}>
                    別居（国内）
                  </Radio>
                  <Radio value="different_and_foreign_address" {...register(`otherFamilies.${index}.nextYear.yearlyInfo.residenceStatus`)}>
                    別居（国外）
                  </Radio>
                </Radio.Container>
              </Grid>

              {residenceStatus === 'different_address' && <DifferentAddress index={index} />}
              {residenceStatus === 'different_and_foreign_address' && <DifferentAndForeignAddress index={index} />}

              <FormSeparator />

              <Grid>
                <Radio.Container label="障害者区分" required error={otherFamilyErrors?.nextYear?.yearlyInfo?.handicapType?.message}>
                  <Radio value="none" {...register(`otherFamilies.${index}.nextYear.yearlyInfo.handicapType`)}>
                    対象外
                  </Radio>
                  <Radio value="normal" {...register(`otherFamilies.${index}.nextYear.yearlyInfo.handicapType`)}>
                    一般障害者
                  </Radio>
                  <Radio value="special" {...register(`otherFamilies.${index}.nextYear.yearlyInfo.handicapType`)}>
                    特別障害者
                  </Radio>
                </Radio.Container>

                {['normal', 'special'].includes(handicapType) && (
                  <>
                    <Textarea label="詳細" rows={3} {...register(`otherFamilies.${index}.nextYear.yearlyInfo.handicapDetail`)} />

                    <FileField label="障害者手帳画像" name={`otherFamilies.${index}.nextYear.yearlyInfo.handicapImage`}>
                      {handicapImage && (
                        <FileField.FileContainer>
                          <FileField.File
                            file={handicapImage}
                            onRemove={() => setValue(`otherFamilies.${index}.nextYear.yearlyInfo.handicapImage`, null)}
                          />
                          <FilePreview file={handicapImage} />
                        </FileField.FileContainer>
                      )}
                    </FileField>
                  </>
                )}
              </Grid>

              <FormSeparator />
              <Grid colGap>
                <AmountField
                  label="所得見積額"
                  note="所得が48万円を超える場合扶養親族に該当しません。（配偶者は95万円）"
                  error={otherFamilyErrors?.nextYear?.yearlyInfo?.earnings?.message}
                  isError={!!otherFamilyErrors?.nextYear?.yearlyInfo?.earnings?.message}
                  {...register(`otherFamilies.${index}.nextYear.yearlyInfo.earnings`)}
                />
              </Grid>
            </>
          )}
        </FormSection>
      </Section.Body>
    </Section>
  );
};
