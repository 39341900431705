import React from 'react';
import Button from 'jbc-front/components/Button';
import { reduxForm, useFormValue, useAutofill } from '../components/FormName';
import { Panel, Headding, Item, Lead, Notes, Buttons, Line } from '../components/PrimaryPanel';
import { DescriptionEdit } from '../components/DescriptionEdit';
import { CustomDescription } from '../components/QuestionDescription';
import { FormPlaceholder } from '../components/FormPlaceholder';

const QuestionForm =
  (({ dispatch, handleSubmit, submitting }) => {
    const classification = useFormValue('classification');
    const { autofill } = useAutofill();
    return (
      <Panel>
        <Headding icon={<DescriptionEdit />}>障害者情報</Headding>
        <Item>
          <Lead>あなたは以下のいずれかに該当しますか？</Lead>
          <CustomDescription field="under_note" />
          <Notes>
            <div>一般障害者とは、以下に該当する方です。</div>
            <ul className="u-mb20">
              <li>・身体障害者手帳 3〜6級</li>
              <li>・精神障害者保険福祉手帳 2〜3級</li>
              <li>・愛護手帳（療育手帳） B</li>
            </ul>
            <div>特別障害者とは、以下に該当する方です。</div>
            <ul>
              <li>・身体障害者手帳 1~2級</li>
              <li>・精神障害者保険福祉手帳 1級</li>
              <li>・愛護手帳（療育手帳）A</li>
              <li>・常に寝たきりである方</li>
            </ul>
          </Notes>
          <Buttons>
            <Button huge secondary primary={classification === 'none'} onClick={() => autofill('classification', 'none')}>
              対象外
            </Button>
            <Button huge secondary primary={classification === 'normal'} onClick={() => autofill('classification', 'normal')}>
              一般障害者
            </Button>
            <Button huge secondary primary={classification === 'special'} onClick={() => autofill('classification', 'special')}>
              特別障害者
            </Button>
          </Buttons>

          {classification !== 'none' && (
            <React.Fragment>
              <Line />
              <Lead>以下の情報の記入が必要です。</Lead>
              <FormPlaceholder />
              <Buttons>
                <Button primary disabled>
                  次ヘ
                </Button>
              </Buttons>
            </React.Fragment>
          )}
        </Item>
      </Panel>
    );
  }) |> reduxForm({ form: 'handicap' });

const Question = ({ dispatch, handleSubmit }) => {
  return <QuestionForm initialValues={{ classification: 'none' }} />;
};

export default Question;
