import { gql } from '@apollo/client';
import { Schema } from './schema';

type NotNeedAdjReason = 'resign_before_year_end' | 'salary_more_than_others' | 'has_no_payment' | 'other';

export type QueryVariables = {
  input: {
    employeeId: string;
    notNeedAdjReason?: NotNeedAdjReason;
    otherReason?: string;
    salaryLessThanOthersNextYear?: boolean;
    isNotCoveredByNextYear: boolean;
  };
};

export const convertFormDataToQueryVariables = (employeeId: string, data: Schema): QueryVariables => {
  const notNeedAdjReason = data.reason;
  const salaryLessThanOthersNextYear = data.reason === 'salary_more_than_others' ? data.salaryMoreThanOthersNextYear : true;

  return {
    input: {
      employeeId,
      notNeedAdjReason,
      otherReason: data.otherReason,
      salaryLessThanOthersNextYear,
      isNotCoveredByNextYear: data.isNotCoveredByNextYear
    }
  };
};

export const MOVE_NOT_NEED_ADJ = gql`
  mutation MoveNotNeedAdj($input: MoveNotNeedAdjInput!) {
    moveNotNeedAdj(input: $input) {
      employee {
        id
      }
    }
  }
`;
