import { ComponentProps, FC, ReactNode } from 'react';
import { FormField, FieldBaseProps } from '../FormField';
import { Label } from '../Label';
import { FileInput as JbcFileInput } from 'jbc-front/components/presenters/form/FileInput';
import { Required } from 'jbc-front/components/presenters/form/Label';
import { ErrorMessage } from 'jbc-front/components/presenters/form/ErrorMessage';
import { Note } from 'jbc-front/components/presenters/ui/Note';
import { FileType } from '@jbc-year-end-adj/types';
import { fileFormFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { ReadMoreReadLess } from 'jbc-front/components/presenters/ui/ReadMoreReadLess';
import { Hint } from 'jbc-front/components/presenters/ui/Hint';

import styles from './FileField.scss';

type FileFieldProps = ComponentProps<typeof JbcFileInput> &
  FieldBaseProps & {
    disabled?: boolean;
    children?: ReactNode;
    note?: string;
    noteMaxLength?: number;
  };

export const FileInput: FC<FileFieldProps> = ({
  label,
  required = false,
  hint,
  note,
  noteMaxLength,
  error = undefined,
  disabled = false,
  children,
  ...fileInputProps
}) => {
  return (
    <FormField>
      {label && (
        <FormField.LabelContainer>
          <Label>
            {label}
            {required && <Required />}
            {hint && <Hint>{hint}</Hint>}
          </Label>
        </FormField.LabelContainer>
      )}

      {!disabled && (
        <div className={styles.fileInput}>
          <JbcFileInput {...fileInputProps} />
        </div>
      )}

      {children && <div className={styles.fileContainer}>{children}</div>}

      {note && (
        <FormField.NoteContainer>
          {noteMaxLength === undefined ? (
            <Note>{note}</Note>
          ) : (
            <Note>
              <ReadMoreReadLess maxLength={noteMaxLength}>{note}</ReadMoreReadLess>
            </Note>
          )}
        </FormField.NoteContainer>
      )}

      {error && (
        <FormField.ErrorContainer>
          <ErrorMessage>{error}</ErrorMessage>
        </FormField.ErrorContainer>
      )}
    </FormField>
  );
};

type FileItemProps = {
  file: File | FileType | undefined;
  onRemove?: ComponentProps<typeof JbcFileInput.File>['onRemove'];
};

const emptyFunc = () => {
  /* empty function */
};

const FileItem: FC<FileItemProps> = ({ file, onRemove = emptyFunc }) => {
  const formattedFile = fileFormFormat(file);

  if (formattedFile) {
    return <JbcFileInput.File file={formattedFile} onRemove={onRemove} />;
  } else {
    return null;
  }
};

export const FileField = Object.assign(FileInput, {
  FileContainer: JbcFileInput.Container,
  File: FileItem
});
