import React from 'react'
import Icon from 'react-icon-base'

const Kintai = props => (
  <Icon viewBox="0 0 20 20" {...props}>
    <g fill='currentColor' fillRule='evenodd'>
      <path d='M10 0C4.478 0 0 4.478 0 10s4.478 10 10 10 10-4.478 10-10S15.522 0 10 0m0 1.7c4.577 0 8.3 3.723 8.3 8.3 0 4.577-3.723 8.3-8.3 8.3-4.577 0-8.3-3.723-8.3-8.3 0-4.577 3.723-8.3 8.3-8.3' />
      <path d='M10 11.25a.851.851 0 0 1-.594-.242L7.327 8.98a.85.85 0 1 1 1.187-1.218l1.479 1.444 3.343-3.344a.851.851 0 0 1 1.203 1.202l-3.938 3.938a.846.846 0 0 1-.601.248M4.757 11.017h-1.25a.85.85 0 0 1 0-1.7h1.25a.85.85 0 0 1 0 1.7M16.462 11.017h-1.25a.85.85 0 0 1 0-1.7h1.25a.85.85 0 0 1 0 1.7M9.983 5.789a.85.85 0 0 1-.85-.85v-1.25a.85.85 0 0 1 1.7 0v1.25a.85.85 0 0 1-.85.85M9.983 17.495a.85.85 0 0 1-.85-.85v-1.25a.85.85 0 0 1 1.7 0v1.25a.85.85 0 0 1-.85.85M14.565 15.597a.848.848 0 0 1-.6-.249l-.885-.883a.85.85 0 1 1 1.201-1.203l.885.883a.85.85 0 0 1-.6 1.452M6.32 7.159a.846.846 0 0 1-.602-.25l-.885-.884a.85.85 0 1 1 1.202-1.202l.885.885a.85.85 0 0 1-.6 1.45M5.403 15.597a.85.85 0 0 1-.601-1.452l.885-.882a.85.85 0 1 1 1.201 1.203l-.884.882a.848.848 0 0 1-.6.25' />
    </g>
  </Icon>
)
Kintai.defaultProps = {
  size: 20
}

export default Kintai
