import React from 'react';
import { handicapClassifications } from '../questions/Handicap';
import { Section } from 'jbc-front/components/Form';
import { useFormValue } from '../components/FormName';
import { useYear } from '../components/useYear';
import { workingStudentClassifications } from '../questions/WorkingStudent';
import { widowClassifications } from '../questions/Widow';
import { toFormValues } from '../utils/form';
import _ from 'lodash';
import { HANDICAP_FRAGMENT } from '../questions/Handicap';
import { WORKING_STUDENT_FRAGMENT } from '../questions/WorkingStudent';
import { WIDOW_FRAGMENT } from '../questions/Widow';
import { gql } from '@apollo/client';
import { FormSection, RadioField, TextAreaField, FileField } from '../components/FieldWithDiff';
import { LabelMapper } from 'jbc-front/components/FormErrors';

export const TAX_CLASSIFICATIONS_FRAGMENT = gql`
  fragment TaxClassifications on Profile {
    ...Widow
    ...WorkingStudent
    ...Handicap
  }
  ${HANDICAP_FRAGMENT}
  ${WORKING_STUDENT_FRAGMENT}
  ${WIDOW_FRAGMENT}
`;

const TaxClassifications = () => {
  const year = useYear();
  const handicapClassification = useFormValue('taxClassifications.handicapClassification');
  const workingStudentClassification = useFormValue('taxClassifications.workingStudentClassification');
  const widowClassification = useFormValue('taxClassifications.widowClassification');
  const maritalStatus = useFormValue('spouseInformation.maritalStatus');
  const gender = useFormValue('basicInformation.gender');
  return (
    <Section title="税区分情報">
      <FormSection name="taxClassifications">
        <LabelMapper name="taxClassifications" label="税区分情報の" />
        <RadioField name="workingStudentClassification" label="勤労学生区分" options={workingStudentClassifications} required />
        {workingStudentClassification !== 'none' && (
          <>
            <TextAreaField name="workingStudentDetail" label="詳細" />
            <LabelMapper name="workingStudentDetail" label="勤労学生区分の詳細" />
            <FileField name="workingStudentImage" label="学生手帳画像" />
          </>
        )}
        <RadioField name="handicapClassification" label="障害者区分" options={handicapClassifications} required />
        {handicapClassification !== 'none' && (
          <>
            <TextAreaField name="handicapDetail" label="詳細" />
            <LabelMapper name="handicapDetail" label="障害者区分の詳細" />
            <FileField name="handicapImage" label="障害者手帳画像" />
          </>
        )}
        <RadioField
          name="widowClassification"
          label={year >= 2020 ? 'ひとり親・寡婦区分' : '寡婦（寡夫）区分'}
          options={widowClassifications(maritalStatus, gender, year)}
          required
        />
        {year <= 2019 && widowClassification !== 'none' && (
          <>
            <TextAreaField name="widowDetail" label="詳細" />
            <LabelMapper name="widowDetail" label="寡婦（寡夫）区分の詳細" />
          </>
        )}
      </FormSection>
    </Section>
  );
};

const defaultRcd = { classification: 'none' };

const addPrefix = (obj, prefix) => _.mapKeys(obj, (v, k) => _.camelCase(`${prefix}_${k}`));

export const makeInitialValues = profile => ({
  ...addPrefix(toFormValues(profile.handicapClassification || defaultRcd, true), 'handicap'),
  ...addPrefix(toFormValues(profile.widowClassification || defaultRcd, true), 'widow'),
  ...addPrefix(toFormValues(profile.workingStudentClassification || defaultRcd, true), 'workingStudent')
});

export default TaxClassifications;
