import React from 'react'
import Icon from 'react-icon-base'

const SendRequest = props => (
  <Icon viewBox="0 0 39 36" {...props}>
    <g fill="none">
      <path d="M-1-2h40v40H-1z" />
      <g stroke="currentColor" strokeWidth="2.5">
        <path strokeLinejoin="round" d="M2 7.47l11.246 11.51 5.62 15.035L36.967 1.5z" />
        <path d="M13 19.202L36.434 2" />
      </g>
    </g>
  </Icon>
)

SendRequest.defaultProps = {
  size: 15
}

export default SendRequest
