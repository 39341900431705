import React from 'react'
import Icon from 'react-icon-base'

const NotableMark = props => (
  <Icon viewBox="0 0 20 20" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle cx="8" cy="8" r="8" fill="#D0021B"/>
      <g fill="#FFF" transform="translate(7 2)">
        <circle cx="1" cy="11" r="1"/>
        <path d="M.543 8.52L0 .996C.084.339.417.006 1 0c.583-.007.916.325 1 .996L1.55 8.52H.542z"/>
      </g>
    </g>
  </Icon>
)
NotableMark.defaultProps = {
  size: 20
}

export default NotableMark