import React, { Component } from 'react';
import CommonModal from 'jbc-front/components/CommonModal';

const { Provider, Consumer } = React.createContext({});

export class Modal extends Component {
  state = {
    isOpen: false
  };

  render() {
    const { isOpen } = this.state;
    const { children } = this.props;
    const funcs = {
      showModal: () => this.setState({ isOpen: true }),
      hideModal: () => this.setState({ isOpen: false }),
      toogleModal: () => this.setState({ isOpen: !isOpen }),
      isOpen
    };
    return <Provider value={funcs}>{children(funcs)}</Provider>;
  }
}

Modal.Header = ({ children, ...rest }) => (
  <Consumer>
    {({ hideModal }) => (
      <CommonModal.Header hideModal={hideModal} {...rest}>
        {children}
      </CommonModal.Header>
    )}
  </Consumer>
);
Modal.Modal = ({ children, ...rest }) => (
  <Consumer>
    {({ isOpen }) => (
      <CommonModal isOpen={isOpen} {...rest}>
        {children}
      </CommonModal>
    )}
  </Consumer>
);
Modal.Body = CommonModal.Body;
Modal.Footer = CommonModal.Footer;
Modal.Buttons = CommonModal.Buttons;

export default Modal;
