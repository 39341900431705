import React from 'react'
import Icon from 'react-icon-base'

const Same = props => (
  <Icon viewBox="0 0 20 20" {...props}>
    <g fill="none">
      <path fill="currentColor" d="M15 11.538A3.472 3.472 0 0 1 11.538 15H3.462A3.472 3.472 0 0 1 0 11.538V3.462A3.472 3.472 0 0 1 3.462 0h8.076A3.472 3.472 0 0 1 15 3.462v8.076z"/>
      <path fill="#FFF" d="M6.433 9.027H8.49V7.562H6.433v1.465zm-.942 1.57c-.11 0-.12-.012-.12-.115 0-.072.022-.33.022-1.733v-.846c0-.816-.022-1.085-.022-1.157 0-.102.01-.112.12-.112.075 0 .357.02 1.19.02h1.55c.855 0 1.126-.02 1.202-.02.108 0 .119.01.119.112 0 .063-.022.33-.022.97v.867c0 1.374.022 1.621.022 1.682 0 .115-.01.124-.12.124H8.61c-.108 0-.12-.01-.12-.124v-.33H6.433v.547c0 .103-.01.115-.12.115H5.49zm.845-4.8c-.954 0-1.28.02-1.354.02-.11 0-.12-.01-.12-.123V4.93c0-.102.01-.114.12-.114.075 0 .4.021 1.354.021H8.61c.954 0 1.289-.02 1.354-.02.108 0 .119.01.119.113v.764c0 .114-.01.124-.12.124-.064 0-.4-.02-1.353-.02H6.336zm5.642 3.22c0 .897.022 1.434.022 1.785-.01.382-.12.65-.347.847-.217.164-.616.279-1.636.279-.335 0-.335.009-.41-.332a3.012 3.012 0 0 0-.304-.814c.477.051.877.062 1.105.062.314 0 .422-.104.422-.352v-6.47H4.17v5.996c0 1.197.021 1.806.021 1.868 0 .103-.01.114-.13.114h-.943C3.011 12 3 11.989 3 11.886c0-.073.02-.753.02-2.157V5.291C3.02 3.888 3 3.186 3 3.115 3 3.01 3.01 3 3.118 3c.077 0 .435.022 1.44.022h5.883c1.006 0 1.364-.022 1.44-.022.108 0 .119.01.119.115 0 .07-.022.804-.022 2.207v3.695z"/>
    </g>
  </Icon>
)
Same.defaultProps = {
  size: 20
}

export default Same
