import React, { useMemo, useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import Button from 'jbc-front/components/Button';
import { useMutation, Modal, useYear, useSession, useQuery, Loading, useFormValue, reduxForm } from '../components';
import { Field } from 'redux-form';
import { renderRadioField } from './DownloadModal';
import { required } from 'jbc-front/utils/validators';
import styles from '../pages/Result.scss';
import _ from 'lodash';
import moment from 'moment';

const BULK_RESULT_FRAGMENT = gql`
  fragment CsvDetailFragment on EtaxCsv {
    id
    generatedAt
    status
    error
    creationFormat
  }
  fragment EtaxCsvFragment on ClientYearly {
    id
    etaxCsvCity {
      ...CsvDetailFragment
    }
    etaxCsvTaxOffice {
      ...CsvDetailFragment
    }
  }
`;

const ETAX_CSV = gql`
  query EtaxCsv($year: Int!) {
    client {
      id
      clientYearly(year: $year) {
        ...EtaxCsvFragment
      }
    }
  }
  ${BULK_RESULT_FRAGMENT}
`;

const MAKE_ETAX_CSV = gql`
  mutation makeEtaxCsv($input: MakeEtaxCsvInput!) {
    makeEtaxCsv(input: $input) {
      clientYearly {
        ...EtaxCsvFragment
      }
    }
  }
  ${BULK_RESULT_FRAGMENT}
`;

const creationFormats = [
  { value: 'solo', label: '給与支払報告書のCSVファイルを作成する' },
  { value: 'withWithholdingSlip', label: '給与支払報告書と源泉徴収票のCSVファイルを一括作成する' }
];

const creationFormatTitle = {
  solo: '給与支払報告書のCSVファイル',
  withWithholdingSlip: '給与支払報告書と源泉徴収票のCSVファイル'
};

const FormLabel = ({ children }) => <div className={styles.bulkModalFormLabel}>{children}</div>;
const Label = ({ children }) => <div className={styles.bulkModalLabel}>{children}</div>;

const PayrollReportForm =
  (({ handleSubmit, hideModal, etaxCsv, sendTo }) => {
    const year = useYear();
    const creationFormat = useFormValue('creationFormat');
    const inProgress = etaxCsv && (etaxCsv.status === 'waiting' || etaxCsv.status === 'in_progress');

    return (
      <>
        <Modal.Body>
          {sendTo === 'city' && (
            <form onSubmit={handleSubmit} className="u-mb20">
              <Field component={renderRadioField} label="作成形式" name="creationFormat" options={creationFormats} validate={required} />
            </form>
          )}
          {etaxCsv?.creationFormat == creationFormat && (
            <>
              <FormLabel>直近に作成した提出用CSVファイル</FormLabel>
              <div className={styles.downloadReport}>
                {inProgress ? (
                  <>
                    作成中 <Loading className={styles.loading} />
                  </>
                ) : etaxCsv.status === 'failed' ? (
                  etaxCsv.error || '作成が失敗しました'
                ) : (
                  <>
                    <a href={`/etax_csv?send_to=${sendTo}&year=${year}`} target="_blank" rel="noopener noreferrer" className="u-txt-link">
                      {creationFormat && (sendTo === 'city' ? creationFormatTitle[creationFormat] : '税務署提出用CSVファイル')}
                      ダウンロード
                    </a>
                    （{moment(etaxCsv.generatedAt).format('YYYY/MM/DD HH:mm')}作成）
                  </>
                )}
              </div>
            </>
          )}
          <Label>作成が完了しましたらメールにてお送りいたします。</Label>
          <ul>
            <li>※従業員数によっては時間がかかる場合がございます。予めご了承ください。</li>
            <li>※ファイルは作成日から1ヶ月間サーバーに保存されます。</li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Buttons>
            <Button onClick={hideModal} disabled={false}>
              キャンセル
            </Button>
            <Button primary onClick={handleSubmit} disabled={inProgress} className={styles.bulkButton}>
              作成
            </Button>
          </Modal.Buttons>
        </Modal.Footer>
      </>
    );
  })
  |> reduxForm({
    form: 'downloadReports'
  });

export const PayrollReportModal = ({ header, sendTo, hasMyNumberPermission }) => {
  const { clientYearly } = useSession();
  const [makeEtaxCsv] = useMutation(MAKE_ETAX_CSV);
  const year = useYear();
  const { data, startPolling, stopPolling } = useQuery(ETAX_CSV, {
    variables: { year },
    fetchPolicy: 'network-only'
  });
  console.log(data?.client?.clientYearly);
  const etaxCsvs = data?.client?.clientYearly || {};
  const etaxCsv = etaxCsvs && etaxCsvs[_.camelCase(`etax_csv_${sendTo}`)];
  const polling = useMemo(() => {
    const status = etaxCsv?.status;
    return status === 'waiting' || status === 'in_progress';
  }, [etaxCsv]);
  useEffect(() => {
    if (polling) {
      startPolling(3000);
      return () => {
        stopPolling();
      };
    }
    return undefined;
  });
  return clientYearly.fixed ? (
    <Modal>
      {({ isOpen, showModal, hideModal }) => (
        <>
          <Button
            primary
            as="button"
            type="button"
            onClick={showModal}
            disabled={!hasMyNumberPermission}
            disabledReason={'操作権限がありません'}
          >
            提出用CSVダウンロード
          </Button>
          <Modal.Modal isOpen={isOpen}>
            <Modal.Header>{header}</Modal.Header>
            <PayrollReportForm
              hideModal={hideModal}
              initialValues={{ creationFormat: etaxCsv?.creationFormat || 'solo' }}
              etaxCsv={etaxCsv}
              sendTo={sendTo}
              onSubmit={async ({ creationFormat }) => {
                await makeEtaxCsv({
                  variables: {
                    input: {
                      sendTo,
                      creationFormat,
                      year
                    }
                  }
                });
              }}
            />
          </Modal.Modal>
        </>
      )}
    </Modal>
  ) : null;
};

export default PayrollReportModal;
