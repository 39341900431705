import { gql } from '@apollo/client';
import { Schema } from './schema';

export type QueryVariables = {
  input: {
    employeeId: string;
    attributes: {
      code?: string;
      residentsTaxCollectionMethod: string;
    };
  };
};

export const convertFormDataToQueryVariables = (employeeId: string, data: Schema): QueryVariables => {
  return {
    input: {
      employeeId,
      attributes: {
        code: data.code,
        residentsTaxCollectionMethod: data.residentsTaxCollectionMethod
      }
    }
  };
};

export const UPDATE_RESIDENT_CARD = gql`
  mutation UpdateResidentTax($input: UpdateResidentTaxInput!) {
    updateResidentTax(input: $input) {
      employee {
        id
      }
    }
  }
`;
