import React from 'react';
import Button from 'jbc-front/components/Button';
import { useMutation, useQuery } from '../components/Graphql';
import { gql } from '@apollo/client';
import { reduxForm, useFormValue } from '../components/FormName';
import { Panel, Headding, Item, Lead, Description, Buttons } from '../components/PrimaryPanel';
import { SelectField, TextField } from 'jbc-front/components/Form';
import { toFormValues } from '../utils/form';
import _ from 'lodash';
import LoadingPage from '../components/LoadingPage';
import useReactRouter from 'use-react-router';
import { CustomDescription } from '../components/QuestionDescription';

export const HOUSEHOLDER_FRAGMENT = gql`
  fragment Householder on Profile {
    id
    householder {
      id
      relationship
      name
    }
  }
`;

const HOUSEHOLDER = gql`
  query householder {
    request {
      id
      profile {
        ...Householder
      }
    }
  }
  ${HOUSEHOLDER_FRAGMENT}
`;

export const relationships = [
  '本人',
  '夫',
  '妻',
  '実父',
  '実母',
  '義父',
  '義母',
  '長男',
  '長女',
  '兄',
  '姉',
  '弟',
  '妹',
  '祖父',
  '祖母',
  '叔父',
  '叔母'
].map(val => ({ value: val, label: val }));

const ANSWER_HOUSEHOLDER = gql`
  mutation AnswerHouseholder($input: AnswerHouseholderInput!) {
    answerHouseholder(input: $input) {
      questions {
        id
        status
      }
      profile {
        ...Householder
      }
    }
  }
  ${HOUSEHOLDER_FRAGMENT}
`;

const formName = 'householder';

const QuestionForm =
  (({ dispatch, handleSubmit }) => {
    const relationship = useFormValue('relationship');
    return (
      <Panel>
        <Headding>世帯主の続柄</Headding>
        <Item>
          <Lead>世帯主情報を確認します</Lead>
          <CustomDescription field="top" />
          <SelectField
            name="relationship"
            label="世帯主の続柄"
            options={relationships}
            creatable
            note="リストにない続柄は直接入力して追加できます"
            description="一人暮らしの場合世帯主は「本人」です。"
            required
          />
          {relationship !== '本人' && <TextField name="name" label="氏名" required />}
          <Buttons>
            <Button primary onClick={handleSubmit}>
              次ヘ
            </Button>
          </Buttons>
        </Item>
      </Panel>
    );
  }) |> reduxForm({ form: formName });

const Question = ({ dispatch, handleSubmit }) => {
  const { loading, data } = useQuery(HOUSEHOLDER);
  const [answer] = useMutation(ANSWER_HOUSEHOLDER);
  const { history } = useReactRouter();
  if (loading) return <LoadingPage />;
  return (
    <QuestionForm
      initialValues={toFormValues(_.omit(data.request.profile.householder || { relationship: '本人' }, 'id'))}
      onSubmit={async values => {
        await answer({ variables: { input: values } });
        history.push('/employee_input');
      }}
    />
  );
};

export default Question;
