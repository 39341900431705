import React from 'react'
import Icon from 'react-icon-base'

const VariousProceduresLine = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="iconVariousProceduresLine" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon" transform="translate(-0.000000, 0.028427)" fill={props.color} fillRule="nonzero">
        <path d="M20.88652,1.97157288 C21.4388047,1.97157288 21.88652,2.41928813 21.88652,2.97157288 C21.88652,3.48440871 21.5004798,3.90708004 21.0031411,3.96484514 L20.88652,3.97157288 L3.84210526,3.97157288 C2.87560695,3.97157288 2.08295543,4.71589948 2.00610653,5.66259687 L2,5.81367814 L2,36.1294676 C2,37.0959659 2.7443266,37.8886174 3.69102399,37.9654663 L3.84210526,37.9715729 L34.1578947,37.9715729 C35.124393,37.9715729 35.9170446,37.2272463 35.9938935,36.2805489 L36,36.1294676 L36,19.0863681 C36,18.5340834 36.4477153,18.0863681 37,18.0863681 C37.5128358,18.0863681 37.9355072,18.4724083 37.9932723,18.969747 L38,19.0863681 L38,36.1294676 C38,38.1850933 36.3856616,39.86367 34.355609,39.9665736 L34.1578947,39.9715729 L3.84210526,39.9715729 C1.78647962,39.9715729 0.107902921,38.3572345 0.00499932353,36.3271818 L0,36.1294676 L0,5.81367814 C0,3.7580525 1.6143384,2.0794758 3.64439104,1.9765722 L3.84210526,1.97157288 L20.88652,1.97157288 Z M34.4629509,0.585786438 L39.4126984,5.53553391 C40.193747,6.31658249 40.193747,7.58291245 39.4126984,8.36396103 L19.6137085,28.1629509 L12.1890873,27.8093975 L11.8355339,20.3847763 L31.6345238,0.585786438 C32.4155724,-0.195262146 33.6819023,-0.195262146 34.4629509,0.585786438 Z M33.0487373,2 L13.875537,21.1725555 L14.1003969,25.897443 L18.8252844,26.122303 L37.9984848,6.94974747 L33.0487373,2 Z" id="Combined-Shape"></path>
      </g>
    </g>
  </Icon>
)
VariousProceduresLine.defaultProps = {
  size: 40,
  color: "#3498DB"
}

export default VariousProceduresLine
