import { FC } from 'react';

// @ts-ignore
import { Link } from 'react-router-dom';

// @ts-ignore
import ListGroup from 'jbc-front/components/ListGroup';

const emailTypes = {
  request_input: '年末調整の依頼メール',
  rejected: '年末調整の修正依頼メール',
  accept: '年末調整の承認完了メール'
};

export const CustomMails: FC = () => (
  <div>
    <div className="l-main-title-wrap">
      <h1 className="m-title-main">メールカスタマイズ</h1>
      <p className="m-title-main-note">メールの内容をカスタマイズできます</p>
    </div>
    <div className="l-wrap-xs l-contents-wrap">
      <div className="l-breadcrumb u-mb20">
        <Link to="/settings" className="l-breadcrumb-link">
          設定
        </Link>
        <span className="l-breadcrumb-here">メールカスタマイズ</span>
      </div>
      <ListGroup>
        <ListGroup.Title>メールカスタマイズ</ListGroup.Title>
        {Object.entries(emailTypes).map(([key, value]) => (
          <ListGroup.Item as={Link} to={`/settings/custom_mails/${key}`} key={key}>
            {value}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  </div>
);
