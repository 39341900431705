import React from 'react'
import Icon from 'react-icon-base'

const Custom22 = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="icon/employees/Custom22" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group-2" transform="translate(2.414214, 2.000501)" fill={props.color} fillRule="nonzero">
        <path d="M18.2928932,0.292893219 L34.8786797,16.2923927 C35.2692039,16.682917 35.2692039,17.316082 34.8786797,17.7066063 C34.6911433,17.8941426 34.4367894,17.9994995 34.1715729,17.9994995 L31.585,17.998713 L31.5857864,35.9994995 L3.58578644,35.9994995 L3.585,17.998713 L1,17.9994995 C0.44771525,17.9994995 0,17.5517842 0,16.9994995 C0,16.734283 0.10535684,16.4799291 0.292893219,16.2923927 L16.8786797,0.292893219 C17.2692039,-0.0976310729 17.9023689,-0.0976310729 18.2928932,0.292893219 Z M17.5852864,2.39834707 L3.41478644,15.9984995 L5.5849126,15.9981045 L5.58478644,33.9984995 L29.5857864,33.9984995 L29.5849126,15.9981048 L31.7557864,15.9984995 L17.5852864,2.39834707 Z" id="Combined-Shape"></path>
        <path d="M19.5857864,22.9994995 C21.7949254,22.9994995 23.5857864,24.7903605 23.5857864,26.9994995 L23.5857864,35.9994995 L11.5857864,35.9994995 L11.5857864,26.9994995 C11.5857864,24.7903605 13.3766474,22.9994995 15.5857864,22.9994995 L19.5857864,22.9994995 Z M19.5857864,24.9994995 L15.5857864,24.9994995 C14.5314246,24.9994995 13.6676213,25.8153773 13.5912722,26.8502371 L13.5857864,26.9994995 L13.5857864,33.9994995 L21.5857864,33.9994995 L21.5857864,26.9994995 C21.5857864,25.9451377 20.7699086,25.0813344 19.7350488,25.0049852 L19.5857864,24.9994995 Z" id="Rectangle"></path>
        <g id="Group" transform="translate(12.585786, 11.999499)">
          <path d="M7.57575758,0 C8.9146297,0 10,1.0853703 10,2.42424242 L10,8.18181818 L0,8.18181818 L0,2.42424242 C0,1.0853703 1.0853703,0 2.42424242,0 L7.57575758,0 Z M7.57575758,1.5 L2.42424242,1.5 C1.95025779,1.5 1.55960702,1.85679472 1.50621805,2.31645623 L1.5,2.42424242 L1.5,6.681 L8.5,6.681 L8.5,2.42424242 C8.5,1.95025779 8.14320528,1.55960702 7.68354377,1.50621805 L7.57575758,1.5 Z" id="Rectangle-Copy"></path>
          <polygon id="Line" points="5.45454545 0.909090909 5.45454545 7.27272727 4.54545455 7.27272727 4.54545455 0.909090909"></polygon>
          <polygon id="Line" points="9.09090909 3.63636364 9.09090909 4.54545455 0.974025974 4.54545455 0.974025974 3.63636364"></polygon>
        </g>
      </g>
    </g>
  </Icon>
)
Custom22.defaultProps = {
  size: 30,
  color: "#3498DB"
}

export default Custom22