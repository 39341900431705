import { FC } from 'react';
import { Income as IncomeTemplate } from 'features/questions/templates/Income';
import { ButtonContainer } from 'features/questions/components/ButtonContainer';
import Button from 'jbc-front/components/Button';
import { useWizard } from '../WizardProvider';

export const Income: FC = () => {
  const { question, request } = useWizard();
  const hasIncome = !!request.profile?.income;

  return (
    <IncomeTemplate question={question}>
      <ButtonContainer>
        <Button
          huge
          primary={hasIncome && !request.profile?.income?.hasSideJob}
          secondary={hasIncome && request.profile?.income?.hasSideJob}
          disabled
        >
          はい
        </Button>
        <Button
          huge
          primary={hasIncome && request.profile?.income?.hasSideJob}
          secondary={hasIncome && !request.profile?.income?.hasSideJob}
          disabled
        >
          いいえ
        </Button>
      </ButtonContainer>
    </IncomeTemplate>
  );
};
