import React from 'react';
import { useDispatch } from 'react-redux';
import { FieldArray, change, Field } from 'redux-form';
import { addOptionsByYear, saveAddedFields } from './utils';
import { MultiTagFilteringFieldRow, SearchSelectBox, GroupsSelectBox } from 'jbc-front/components/SearchForm';
import styles from 'jbc-front/components/SearchForm.scss';
import { Close } from 'jbc-front/components/icons';
import { DateRow } from './searchRow';
import { useFormValue, useAutofill, useFormName, useQuery, useSession, useYear } from '../components';
import { MEMO_COLOR_OPTIONS } from '../components/Memo';
import { handicapClassifications } from '../questions/Handicap';
import { maritalStatuses, widowClassifications } from '../questions/Widow';
import { workingStudentClassifications } from '../questions/WorkingStudent';
import _ from 'lodash';
import { gql } from '@apollo/client';

const hasDependents = [
  { name: '有', id: 'true' },
  { name: '無', id: 'false' }
];

export const fromOpts = opt => opt.map(opt => ({ name: opt.label, id: opt.value }));
export const toOpts = data => data.map(item => ({ label: item.name, value: `${item.id}` }));
export const withNull = lst => [...(lst || []), { name: '未入力', id: 'empty' }];
export const withNullOpt = lst => [...(lst || []), { label: '未入力', value: 'empty' }];
export const idToStr = item => ({ ...item, id: `${item.id}` });

const taxTableClassifications = [
  { value: 'first', label: '甲欄' },
  { value: 'second', label: '乙欄' }
];

const ADDITIONAL_SEARCH_QUERY = gql`
  query additionalSearchQuery($year: Int!) {
    client {
      id
      clientYearly(year: $year) {
        id
        employmentTypes
        groups
        positions
      }
    }
  }
`;

const MemoColors = ({ input: inputProps, list }) => (
  <div>
    {list.map(item => (
      <label key={item.id} style={{ marginRight: 20 }}>
        <input
          type="checkbox"
          className="m-checkbox-input"
          checked={inputProps.value.indexOf(item.id) >= 0}
          onChange={() => {
            const newValues =
              inputProps.value.indexOf(item.id) >= 0 ? inputProps.value.filter(ele => ele !== item.id) : [...inputProps.value, item.id];
            inputProps.onChange(newValues);
            inputProps.onBlur(newValues);
          }}
        />
        <span className="m-checkbox-parts">{item.id === 'empty' ? 'メモ無し' : item.name}</span>
      </label>
    ))}
  </div>
);

const renderAddedField = (field, year) => {
  switch (field) {
    case 'taxTableClassifications':
      return (
        <MultiTagFilteringFieldRow
          header="税額表区分"
          fieldName="taxTableClassifications"
          list={withNull(fromOpts(taxTableClassifications))}
        />
      );
    case 'handicapClassifications':
      return (
        <MultiTagFilteringFieldRow
          header="障害者区分"
          fieldName="handicapClassifications"
          list={withNull(fromOpts(handicapClassifications))}
        />
      );
    case 'widowClassifications':
      return (
        <MultiTagFilteringFieldRow
          header={year >= 2020 ? 'ひとり親・寡婦' : '寡婦（夫）区分 '}
          fieldName="widowClassifications"
          list={withNull(fromOpts(widowClassifications(null, null, year)))}
        />
      );
    case 'workingStudentClassifications':
      return (
        <MultiTagFilteringFieldRow
          header="勤労学生区分"
          fieldName="workingStudentClassifications"
          list={withNull(fromOpts(workingStudentClassifications))}
        />
      );
    case 'hasDependents':
      return <MultiTagFilteringFieldRow header="扶養の有無" fieldName="hasDependents" list={hasDependents} />;

    case 'maritalStatuses':
      return <MultiTagFilteringFieldRow header="配偶者の有無" fieldName="maritalStatuses" list={withNull(fromOpts(maritalStatuses))} />;

    case 'resignedOn':
      return <DateRow header="退職日" errorLabel="日付" prefix="resignedOn" />;
    /*
    case 'retire_types':
      return (
        <MultiTagFilteringFieldRow header="退職区分" fieldName="retire_types" list={withNull(fromOpts(retireTypes))} />
      )*/
    default:
      return null;
  }
};

const AddedFields = ({ fields }) => {
  const addedFields = useFormValue('_addedFields') || [];
  const { withSelector } = useAutofill();
  const { me } = useSession();
  const year = useYear();
  return (
    <>
      {addedFields.map((field, index) => (
        <AddedField key={field} {...{ fields, index, field }} />
      ))}
      <div className={styles.addFieldSelect}>
        <SearchSelectBox
          options={addOptionsByYear(year).filter(option => !addedFields || !addedFields.includes(option.value))}
          width={220}
          input={{
            onChange: v => {
              fields.push(v);
              withSelector(selector => {
                saveAddedFields(me, selector('_addedFields'));
              });
            }
          }}
          closeOnSelect={false}
          placeholder="検索条件追加"
        />
      </div>
    </>
  );
};

const AddedField = ({ field, fields, index }) => {
  const dispatch = useDispatch();
  const { formName } = useFormName();
  const { withSelector } = useAutofill();
  const { me } = useSession();
  const year = useYear();
  const onClick = () => {
    fields.remove(index);
    const opt = addOptionsByYear(year).find(opt => opt.value === field);
    (opt.fields || [opt.value]).forEach(field => {
      dispatch(change(formName, field, null));
    });
    withSelector(selector => {
      saveAddedFields(me, selector('_addedFields'));
    });
  };
  return (
    <div className={styles.addedField}>
      {renderAddedField(field, year)}
      <a className={styles.close} onClick={onClick} onKeyPress={onClick} role="button" tabIndex={-1}>
        <Close />
      </a>
    </div>
  );
};

const AdditionalSearchFields = ({ groups = [], positions = [], employmentTypes = [], occupations = [] }) => {
  const year = useYear();
  const { data, loading } = useQuery(ADDITIONAL_SEARCH_QUERY, { fetchPolicy: 'network-only', variables: { year } });
  if (loading) return null;
  return (
    <div className={styles.details}>
      <div className={styles.detailsRow}>
        <div className={styles.detailsHeader}>メモカラー</div>
        <div className={styles.detailsBody}>
          <Field
            name="memoColors"
            component={MemoColors}
            list={withNull(_.compact(MEMO_COLOR_OPTIONS).map(type => ({ name: type.label, id: type.value })))}
          />
        </div>
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.detailsHeader}>グループ</div>
        <div className={styles.detailsBody}>
          <div className={styles.detailsBodyItems}>
            <Field
              name="groups"
              component={GroupsSelectBox}
              options={withNullOpt(_.compact(data.client.clientYearly.groups).map(group => ({ value: group, label: group })))}
            />
          </div>
        </div>
      </div>
      <MultiTagFilteringFieldRow
        header="役職"
        fieldName="positions"
        list={withNull(_.compact(data.client.clientYearly.positions).map(type => ({ name: type, id: type })))}
      />
      <MultiTagFilteringFieldRow
        header="雇用形態"
        fieldName="employmentTypes"
        list={withNull(_.compact(data.client.clientYearly.employmentTypes).map(type => ({ name: type, id: type })))}
      />
      {<DateRow header="入社日" errorLabel="日付" prefix="joinedOn" />}
      {<FieldArray name="_addedFields" component={AddedFields} />}
    </div>
  );
};

export default AdditionalSearchFields;
