import { FC, ReactNode } from 'react';
import { Section } from 'components/ui/Section';

import { Question } from '../type';

import { Lead } from './parts/Lead';
import { Description } from './parts/Description';
import { Content } from './parts/Content';

type SalaryMoreNextProps = {
  officeName?: string;
  question: Question;
  children: ReactNode;
};

export const SalaryMoreNext: FC<SalaryMoreNextProps> = ({ officeName, question, children }) => {
  const description = question.questionDescriptions.find(description => description.field == 'top')?.description;

  return (
    <Section>
      <Section.Header>来年の給与</Section.Header>

      <Section.Body>
        <Content>
          <Lead>来年も{officeName || 'こちらの会社'}のお給料が少ない予定ですか？</Lead>

          <Description>{description}</Description>

          {children}
        </Content>
      </Section.Body>
    </Section>
  );
};
