import { gql } from '@apollo/client';

export type QueryResult = {
  cities: {
    totalCount: number;
    list: City[];
  };
};

type City = {
  id: string;
  code: string;
  name: string;
};

export const SEARCH_CITIES = gql`
  query searchCities($page: Int, $per: Int, $query: String) {
    cities(page: $page, per: $per, query: $query) {
      totalCount
      list {
        id
        code
        name
      }
    }
  }
`;
