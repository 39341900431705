import React from 'react'
import Icon from 'react-icon-base'

const BulkDownload = props => (
  <Icon viewBox="0 0 44 47" {...props}>
    <defs>
      <rect height="28" id="a" width="23" rx="3" x="5"/>
      <rect height="28" id="b" width="23" rx="3" y="5"/>
    </defs>
    <g fill="none">
      <g transform="translate(16)">
        <rect height="28" width="23" rx="3" stroke="#fff" x="5"/>
        <rect height="27" width="22" rx="3" fill="currentColor" x="5.5" y=".5"/>
      </g>
      <g transform="translate(16)">
        <rect height="28" width="23" rx="3" stroke="#fff" y="5"/>
        <rect height="27" width="22" rx="3" fill="currentColor" x=".5" y="5.5"/>
      </g>
      <g>
        <g transform="translate(1 16)">
          <ellipse cx="15" cy="14.516" fill="#FFF" rx="15" ry="14.516"/>
          <path d="M15 7.276V22.53M9.063 16.642l5.938 5.889 5.936-5.889" stroke="currentColor" strokeLinecap="round" strokeWidth="2.307"/>
          <path d="M29.857 14.903c0 8.14-6.652 14.738-14.857 14.738C6.796 29.64.143 23.043.143 14.903S6.796.166 15 .166c8.205 0 14.857 6.598 14.857 14.737z" stroke="currentColor" strokeLinecap="round" strokeWidth="2.307"/>
        </g>
      </g>
    </g>
  </Icon>
)
BulkDownload.defaultProps = {
  size: 16
}

export default BulkDownload
