import { FC, ReactNode, useState, createContext, useContext } from 'react';

import ActionButton from 'jbc-front/components/ActionButton';
import { Pulldown } from 'jbc-front/components/icons';

import styles from './ToggleImageContainer.scss';

const ToggleImageContainerContext = createContext({
  isOpen: false,
  toggle: () => {
    /* do nothing */
  }
});

type ToggleImageProps = {
  children: ReactNode;
  initiallyOpen?: boolean;
};

const Container: FC<ToggleImageProps> = ({ children, initiallyOpen = true }) => {
  const [isOpen, setIsOpen] = useState<boolean>(initiallyOpen);
  const contextValue = {
    isOpen,
    toggle: () => {
      setIsOpen(prev => !prev);
    }
  };
  return (
    <ToggleImageContainerContext.Provider value={contextValue}>
      <div className={styles.container}>{children}</div>
    </ToggleImageContainerContext.Provider>
  );
};

type HeaderProps = {
  children: ReactNode;
};

const Header: FC<HeaderProps> = ({ children }) => {
  const { isOpen, toggle } = useContext(ToggleImageContainerContext);

  return (
    <div className={styles.header} role="presentation">
      <span>{children}</span>
      <ActionButton primary={isOpen} as="button" type="button" className={styles.toggleButton} onClick={toggle}>
        {isOpen ? '閉じる' : '開く'}
        <Pulldown className={styles.icon} size={14} transform={isOpen ? 'rotate(180)' : 'rotate(0)'} />
      </ActionButton>
    </div>
  );
};

type BodyProps = {
  children: ReactNode;
};

const Body: FC<BodyProps> = ({ children }) => {
  const { isOpen } = useContext(ToggleImageContainerContext);

  if (isOpen) {
    return <div className={styles.body}>{children}</div>;
  } else {
    return null;
  }
};

export const ToggleImageContainer = Object.assign(Container, { Header, Body });
