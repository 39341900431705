import React from 'react';
import Button from 'jbc-front/components/Button';
import { useQuery, useMutation } from '../components/Graphql';
import { gql } from '@apollo/client';
import { FormSection } from 'redux-form';
import { DateField, RadioField, Section, TextField } from 'jbc-front/components/Form';
import { AmountField } from '../components/FieldWithDiff';
import { toFormValues, toBooleanProps } from '../utils/form';
import Name from '../components/Name';
import Address, { addressFields } from '../components/Address';
import { reduxForm, useFormValue, useAutofill } from '../components/FormName';
import { Panel, Headding, Item, Lead, Description, Buttons } from '../components/PrimaryPanel';
import ActionButton from 'jbc-front/components/ActionButton';
import { Copy } from 'jbc-front/components/icons';
import { LabelMapper } from 'jbc-front/components/FormErrors';
import { amount } from '../validators';
import useReactRouter from 'use-react-router';
import LoadingPage from '../components/LoadingPage';
import { CustomDescription } from '../components/QuestionDescription';
import styles from './BasicInfos.scss';

export const genderOptions = [
  { value: 'male', label: '男性' },
  { value: 'female', label: '女性' }
];

export const nationalTypes = [
  { value: 'japanese', label: '日本人' },
  { value: 'foreigner', label: '外国人' }
];

export const isDisasterSuffererOptions = [
  { value: 'false', label: '対象外' },
  { value: 'true', label: '災害者' }
];

export const BASIC_INFORMATION_FRAGMENT = gql`
  fragment BasicInformation on Profile {
    lastName
    firstName
    lastNameKana
    firstNameKana
    ${addressFields.join('\n')}
    birthday
    gender
    nationalType
    isDisasterSufferer
    taxPostponementAmount
    residentCard {
      id
    ${addressFields.join('\n')}
    }
  }
`;

const BASIC_INFORMATION = gql`
  query basicInformation {
    request {
      id
      profile {
        id
        ...BasicInformation
      }
    }
  }
  ${BASIC_INFORMATION_FRAGMENT}
`;

const ANSWER_BASIC_INFOS = gql`
  mutation answerBasicInfos($input: AnswerBasicInfosInput!) {
    answerBasicInfos(input: $input) {
      questions {
        id
        status
      }
      profile {
        id
        ...BasicInformation
      }
    }
  }
  ${BASIC_INFORMATION_FRAGMENT}
`;

const formName = 'basicInfos';

export const CopyAddress = ({ onClick }) => (
  <ActionButton icon={<Copy size={13} />} onClick={onClick} className="u-mb20">
    現住所をコピーする
  </ActionButton>
);

const QuestionForm =
  (({ handleSubmit, anwser, address }) => {
    const isDisasterSufferer = useFormValue('isDisasterSufferer');
    const { autofill, withSelector } = useAutofill();
    return (
      <Panel>
        <form onSubmit={handleSubmit}>
          <Headding className={styles.primaryPanelHeader}>氏名・住所等</Headding>
          <Item>
            <Lead>個人情報を確認します</Lead>
            <CustomDescription field="top" />
            <Section title="基本情報" style={{ maxWidth: '760px' }}>
              <Name required />
              <DateField name="birthday" label="生年月日" viewMode="years" example="例）1981/01/23、S56.1.23" required />
              <RadioField name="gender" label="性別" options={genderOptions} required />
              <Address withKana={false} postcodeDescription="現在の住所をご記入ください。" required />
              <RadioField name="nationalType" label="外国人区分" options={nationalTypes} required />
              <RadioField
                name="isDisasterSufferer"
                label="（源泉徴収票）災害者"
                {...toBooleanProps}
                options={isDisasterSuffererOptions}
                description="給与所得者が災害減免法により源泉所得税及び復興特別所得税の徴収猶予又は還付を受けた場合は年末調整の対象外になります。"
                required
              />
              {isDisasterSufferer && <AmountField name="taxPostponementAmount" label="徴収猶予税額" required validate={amount} />}
            </Section>
            <FormSection name="residentCard">
              <LabelMapper name="residentCard" label="住民票住所の" />
              <Section title="住民票住所" style={{ maxWidth: '760px' }}>
                <CopyAddress
                  onClick={() => {
                    withSelector(selector => {
                      addressFields.forEach(field => autofill(`residentCard.${field}`, selector(field)));
                    });
                  }}
                />
                <Address withKana={false} postcodeDescription="住民票住所をご確認ください。" required />
              </Section>
            </FormSection>
            <Buttons>
              <Button primary onClick={handleSubmit}>
                次ヘ
              </Button>
            </Buttons>
          </Item>
        </form>
      </Panel>
    );
  }) |> reduxForm({ form: formName });

const Question = ({ handleSubmit }) => {
  const { loading, data } = useQuery(BASIC_INFORMATION);
  const { history } = useReactRouter();
  const [answer] = useMutation(ANSWER_BASIC_INFOS);
  if (loading) return <LoadingPage />;
  return (
    <QuestionForm
      initialValues={{
        ...toFormValues(data.request.profile, true),
        residentCard: toFormValues(data.request.profile.residentCard, true)
      }}
      onSubmit={async values => {
        await answer({ variables: { input: values } });
        history.push('/employee_input');
      }}
      anwser={answer}
    />
  );
};

export default Question;
