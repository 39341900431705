import React, { useState } from 'react';
import Button from 'jbc-front/components/Button';
import { Panel, Headding, Item, Lead, Buttons, Text, Notes, Label } from '../components/PrimaryPanel';
import Hint from 'jbc-front/components/Hint';
import { DescriptionEdit } from '../components/DescriptionEdit';
import { CustomDescription } from '../components/QuestionDescription';
import { FormPlaceholder } from '../components/FormPlaceholder';
import { ToggleDivImage } from '../components/ToggleDivImage';
import image from '../../images/img-Dependent_Exemption_Form_AC.png';
import image2 from '../../images/img-Basic_Consignment_Spouse.png';

const Question = () => {
  const [hasSpouseDeduction, setHasSpouseDeduction] = useState(true);
  return (
    <Panel>
      <Headding icon={<DescriptionEdit />}>配偶者控除情報</Headding>
      <Item>
        <Lead>配偶者控除・配偶者特別控除等の対象ですか？</Lead>
        <Text>
          <div>以下のいずれか1つ以上のすべての条件に該当する方です。</div>
          <Notes>
            <Label>今年の配偶者控除または配偶者特別控除対象</Label>
            <ul>
              <li>
                ・従業員本人の合計所得金額が1,000万円以下（給与収入だけの場合、1,195万円以下）
                <Hint
                  text={
                    <div>
                      「合計所得金額」とは、各種損失の繰越控除を適用する前の総所得金額、譲渡所得、配当所得、雑所得、山林所得、退職所得の合計額をいいます。
                      <br />
                      「生計を一にする」とは、必ずしも同居を要件とするものではありません。例えば、別居している場合であっても、余暇には起居を共にすることを常例としている場合や、常に生活費等の送金が行われている場合には、「生計を一にする」ものとして取り扱われます。なお、親族が同一の家屋に起居している場合には、明らかに互いに独立した生活を営んでいると認められる場合を除き、「生計を一にする」ものとして取り扱われます。
                    </div>
                  }
                />
              </li>
              <li>・配偶者の合計所得金額が133万円以下</li>
              <li>・民法上の配偶者であり、生計を一にしている</li>
              <li>・配偶者が他の人の扶養親族ではない</li>
            </ul>
            <Label>今年の障害者控除対象</Label>
            <ul>
              <li>・配偶者が所得税法上の障害者に当てはまる</li>
              <li>・配偶者の合計所得金額が48万円以下</li>
              <li>・民法上の配偶者であり、生計を一にしている</li>
              <li>・配偶者が他の人の扶養親族ではない</li>
            </ul>
            <Label>来年の源泉控除対象配偶者</Label>
            <ul>
              <li>・従業員本人の来年の合計所得金額が900万円以下（予定）</li>
              <li>・配偶者の来年の合計所得金額が95万円以下（予定）</li>
              <li>・民法上の配偶者であり、生計を一にしている</li>
              <li>・配偶者が他の人の扶養親族ではない</li>
            </ul>
          </Notes>
          <CustomDescription field="under_note" as={Text} />
        </Text>
        <Buttons>
          <Button huge onClick={() => setHasSpouseDeduction(true)} primary={hasSpouseDeduction}>
            はい
          </Button>
          <Button huge onClick={() => setHasSpouseDeduction(false)} primary={!hasSpouseDeduction}>
            いいえ
          </Button>
        </Buttons>
        {hasSpouseDeduction && (
          <>
            <ToggleDivImage title={'主に「扶養控除等(異動)申告書」のA欄、C欄や「配偶者控除等申告書」欄等に反映されます。'}>
              <img src={image} alt="扶養控除申告書_AC" />
              <img src={image2} alt="基配所_配偶者" />
            </ToggleDivImage>
            <FormPlaceholder />
            <Buttons>
              <Button primary disabled>
                次ヘ
              </Button>
            </Buttons>
          </>
        )}
      </Item>
    </Panel>
  );
};

export default Question;
