import { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { usePrefectureOptions } from 'hooks/usePrefectureOptions';
import { useAddress } from 'hooks/useAddress';
import { useYear } from 'hooks/useYear';

import { PostcodeInput } from 'components/form/PostcodeInput';
import { SelectField } from 'components/react-hook-form/SelectField';
import { Input } from 'components/form/Input';
import { Schema } from '../../schema';
import { Grid } from '../../../../../../components/Grid';

export const DifferentAddress: FC = () => {
  const {
    formState: { errors },
    register,
    watch,
    setValue,
    trigger
  } = useFormContext<Schema>();

  const { prefectureOptions } = usePrefectureOptions();
  const { fetchAddress, address } = useAddress();
  const year = useYear();

  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  const postcode0 = watch('spouse.thisYear.yearlyInfo.postcode0');
  const postcode1 = watch('spouse.thisYear.yearlyInfo.postcode1');
  const prefectureId = watch('spouse.thisYear.yearlyInfo.prefectureId');

  useEffect(() => {
    if (!isFirstRender) {
      if (postcode0 && postcode1) {
        const postcode = Number(`${postcode0}${postcode1}`);
        fetchAddress({ variables: { postcode, year } });
      }
    } else {
      setIsFirstRender(false);
    }
  }, [postcode0, postcode1]);

  useEffect(() => {
    if (address) {
      const prefecture = prefectureOptions.find(option => option.label === address.prefecture);
      setValue('spouse.thisYear.yearlyInfo.prefectureId', prefecture?.value);
      setValue('spouse.thisYear.yearlyInfo.city', address.city);
      setValue('spouse.thisYear.yearlyInfo.street', address.street);

      trigger(['spouse.thisYear.yearlyInfo.prefectureId', 'spouse.thisYear.yearlyInfo.city', 'spouse.thisYear.yearlyInfo.street']);
    }
  }, [address]);

  const postcodeErrors = [
    errors.spouse?.thisYear?.yearlyInfo?.postcode0?.message,
    errors.spouse?.thisYear?.yearlyInfo?.postcode1?.message
  ].reduce<string[]>((errors, message) => {
    if (message) {
      errors.push(message);
    }

    return errors;
  }, []);

  return (
    <Grid colGap>
      <PostcodeInput
        required
        errors={postcodeErrors}
        firstCodeProps={{
          isError: !!errors.spouse?.thisYear?.yearlyInfo?.postcode0?.message,
          ...register('spouse.thisYear.yearlyInfo.postcode0')
        }}
        lastCodeProps={{
          isError: !!errors.spouse?.thisYear?.yearlyInfo?.postcode1?.message,
          ...register('spouse.thisYear.yearlyInfo.postcode1')
        }}
      />

      <SelectField
        label="都道府県"
        options={prefectureOptions}
        value={prefectureOptions.find(option => option.value === prefectureId)}
        required
        name="spouse.thisYear.yearlyInfo.prefectureId"
        error={errors.spouse?.thisYear?.yearlyInfo?.prefectureId?.message}
      />
      <Input
        label="市区町村"
        required
        error={errors.spouse?.thisYear?.yearlyInfo?.city?.message}
        isError={!!errors.spouse?.thisYear?.yearlyInfo?.city?.message}
        {...register('spouse.thisYear.yearlyInfo.city')}
      />
      <Input
        label="丁目番地号"
        error={errors.spouse?.thisYear?.yearlyInfo?.street?.message}
        isError={!!errors.spouse?.thisYear?.yearlyInfo?.street?.message}
        {...register('spouse.thisYear.yearlyInfo.street')}
      />
      <Input
        label="建物名"
        error={errors.spouse?.thisYear?.yearlyInfo?.building?.message}
        isError={!!errors.spouse?.thisYear?.yearlyInfo?.building?.message}
        {...register('spouse.thisYear.yearlyInfo.building')}
      />
    </Grid>
  );
};
