import { FC, ComponentProps } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { DateField as UnControlledDateField } from '@jbc-year-end-adj/2024/components/form/DateField';
import { dateValidator } from '@jbc-year-end-adj/common/utils/validators';
import moment, { Moment } from 'moment';

// @ts-ignore
import { toAdYearDate, toJpYearDate } from 'jbc-front/utils/jpYear';

type DateFieldProps = ComponentProps<typeof UnControlledDateField> & {
  name: string;
};

export const DateField: FC<DateFieldProps> = ({ name, ...dateFieldProps }) => {
  const { control } = useFormContext();
  const { field } = useController({
    name,
    control
  });

  const handleChange = (value: string | Moment) => {
    if (typeof value === 'string') {
      field.onChange(value);
    } else {
      const v = value.format('YYYY/MM/DD');
      field.onChange(v);
    }
  };

  const handleClose = () => {
    if (!dateValidator(field.value)) {
      const adYearDate = toAdYearDate(field.value);

      if (adYearDate) {
        field.value = adYearDate;
        field.onChange(adYearDate);
      }
    }
    field.onBlur();
  };

  const inputProps = {
    value: field.value,
    display: field.value && dateValidator(field.value) && toJpYearDate(moment(field.value, 'YYYY/MM/DD'))
  };

  return (
    <UnControlledDateField
      {...dateFieldProps}
      initialValue={field.value}
      onChange={handleChange}
      onClose={handleClose}
      inputProps={inputProps}
    />
  );
};
