import { yup } from '@jbc-year-end-adj/common/yup';
import { Employee } from '../query';

export type Reason = 'resign_before_year_end' | 'salary_more_than_others' | 'has_no_payment' | 'other';

export type Schema = {
  isNotNeedAdj: boolean;
  reason?: Reason;
  otherReason?: string;
  salaryMoreThanOthersNextYear: boolean;
  isNotCoveredByNextYear: boolean;
};

export const schema = yup.object<Schema>({
  isNotNeedAdj: yup.boolean(),
  reason: yup.mixed<Reason>().when(['isNotNeedAdj'], {
    is: (isNotNeedAdj: boolean) => isNotNeedAdj,
    then: schema => schema.required().label('対象外理由')
  }),
  otherReason: yup
    .string()
    .max(31, '対象外理由（その他）は31文字以内で入力してください')
    .nullable()
    .label('対象外理由（その他）'),
  salaryMoreThanOthersNextYear: yup.boolean(),
  isNotCoveredByNextYear: yup
    .boolean()
    .required()
    .label('来年度も対象外')
});

export const generateDefaultValues = (employee: Employee): Schema => {
  const reason = checkReason(employee);
  const otherReason = reason === 'other' ? employee.profile.otherReason : undefined;
  const salaryMoreThanOthersNextYear = reason === 'salary_more_than_others' ? employee.profile.salaryLessThanOthersNextYear : false;
  const isNotCoveredByNextYear = employee.profile.isNotCoveredByNextYear;

  return {
    isNotNeedAdj: employee.status === 'not_need_adj',
    reason,
    otherReason,
    salaryMoreThanOthersNextYear,
    isNotCoveredByNextYear
  };
};

const checkReason = (employee: Employee): Reason | undefined => {
  if (employee.profile.resignBeforeYearEnd) {
    return 'resign_before_year_end';
  } else if (!employee.profile.salaryMoreThanOthers) {
    return 'salary_more_than_others';
  } else if (employee.hasNoPayment) {
    return 'has_no_payment';
  } else if (employee.profile.other) {
    return 'other';
  } else {
    return undefined;
  }
};
