import { FC } from 'react';
import { Dl, Dt, Dd } from '../../../components/DefinitionList';
import { Grid } from '../../../components/Grid';
import { useEmployeeInfo } from '../EmployeeInfoProvider';

export const Preview: FC = () => {
  const { employee } = useEmployeeInfo();
  const residentCard = employee.profile.residentCard;
  const residentCardAddress = residentCard ? (
    <>
      {residentCard.postcode0}-{residentCard.postcode1}
      <br />
      {residentCard.fullAddress}
    </>
  ) : (
    undefined
  );

  return (
    <Grid>
      <Dl>
        <Dt>住民票住所</Dt>
        <Dd>{residentCardAddress}</Dd>
      </Dl>
    </Grid>
  );
};
