import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';

export type Prefecture = {
  id: number;
  name: string;
};

type QueryResult = {
  prefectures: Prefecture[];
};

const FETCH_PREFECTURES = gql`
  query fetchPrefectures {
    prefectures {
      id
      name
    }
  }
`;

export const usePrefectures = () => {
  const { data, loading } = useQuery<QueryResult>(FETCH_PREFECTURES, {
    fetchPolicy: 'network-only'
  });

  return { prefectures: data?.prefectures, loading };
};
