import React from 'react'
import Icon from 'react-icon-base'

const StartMaternity = (props) => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(7.5, 0)" fill="#3498DB" fillRule="nonzero">
        <path d="M24.3982793,26.2423877 C24.6731823,26.2162535 24.917221,26.4179204 24.9433552,26.6928233 C24.9810336,27.0891585 25,27.4871588 25,27.8857911 C25,33.7183892 20.9113708,38.7260665 15.2518871,39.9880151 C14.9823638,40.0481133 14.7151528,39.8783407 14.6550546,39.6088174 C14.5949564,39.339294 14.7647291,39.072083 15.0342524,39.0119849 C20.2401436,37.851178 24,33.2461756 24,27.8857911 C24,27.5188349 23.9825386,27.1524158 23.9478437,26.7874636 C23.9217095,26.5125607 24.1233764,26.2685219 24.3982793,26.2423877 Z M12.5,15 C12.7761424,15 13,15.2238576 13,15.5 C13,15.7761424 12.7761424,16 12.5,16 C6.14782939,16 1,21.1066022 1,27.4038962 L1,29.508 L1.15713438,29.1726462 C1.28906954,28.9014487 1.43098313,28.6334034 1.58420548,28.3682358 L1.82268685,27.9726083 C3.23518798,25.724801 5.22718704,24.2016279 7.91173352,23.7426278 C8.18392596,23.6960887 8.44230892,23.8790169 8.48884801,24.1512093 C8.5353871,24.4234018 8.35245892,24.6817847 8.08026648,24.7283238 C5.70152993,25.1350369 3.940828,26.4813498 2.66939235,28.5046701 C2.06155902,29.4719559 1.62603782,30.4896424 1.28827186,31.5687374 C1.20774766,31.8259962 1.04219872,32.4069067 1.039703,32.4149622 C1.02233856,32.4710103 1.00787324,32.5126798 0.990717405,32.5518465 C0.9711299,32.5966828 0.9711299,32.5966828 0.913742222,32.6816745 C0.786486473,32.8484677 0.786486473,32.8484677 0.276613878,32.8154197 C0.037022026,32.5818952 0.037022026,32.5818952 0.0226533016,32.4857336 C0.00898427033,32.4194628 0.00898427033,32.4194628 0.00502825589,32.3773739 C0.0013532117,32.3351354 0,32.2866516 0,32.223304 L0,27.4038962 C0,20.5525095 5.59733661,15 12.5,15 Z"></path>
        <path d="M12.5,0 C16.918278,0 20.5,3.581722 20.5,8 C20.5,12.418278 16.918278,16 12.5,16 C8.081722,16 4.5,12.418278 4.5,8 C4.5,3.581722 8.081722,0 12.5,0 Z M12.5,1 C8.63400675,1 5.5,4.13400675 5.5,8 C5.5,11.8659932 8.63400675,15 12.5,15 C16.3659932,15 19.5,11.8659932 19.5,8 C19.5,4.13400675 16.3659932,1 12.5,1 Z"></path>
        <path d="M12.6695512,28.1673117 C16.5429916,24.8905322 21.1265567,27.2784367 20.4946736,31.5727882 C20.1225203,34.1019852 17.5271521,36.6973535 12.7484707,39.4338925 L12.500227,39.5760507 L12.2518713,39.4340882 C7.46397995,36.6972829 4.86816617,34.1014691 4.50506927,31.5710186 C3.88873432,27.2757324 8.46123512,24.8913425 12.3309925,28.1675117 L12.5002078,28.313987 L12.6695512,28.1673117 Z M19.5053264,31.4272128 C20.0236649,27.9045239 16.1515603,26.0560786 12.8536872,29.356449 L12.4999096,29.7104944 L12.1462225,29.3563586 C8.8511468,26.0571018 4.98891976,27.9025526 5.49493073,31.4289824 C5.80145854,33.5651985 8.20564477,35.9693847 12.7481287,38.5659128 L12.5002078,38.421987 L12.6349983,38.3439984 C16.7957977,35.9060034 19.0640662,33.6407418 19.4732485,31.611077 L19.5053264,31.4272128 Z"></path>
        <path d="M19.8034884,14.3031598 C20.1997778,13.9184871 20.8328728,13.9279043 21.2175455,14.3241937 L26.0508788,19.3034884 L26.0670697,19.3227053 L26.1136446,19.374551 L26.1260697,19.3917053 L26.1410697,19.4127053 L26.1776454,19.4639118 L26.1880697,19.4837053 L26.1977805,19.4968559 L26.2120697,19.5267053 L26.2316922,19.5602253 L26.2380697,19.5767053 L26.251236,19.6025954 L26.2670697,19.6447053 L26.2750347,19.6627412 L26.2790697,19.6787053 L26.2913595,19.7127105 L26.3000697,19.7507053 L26.3069226,19.7707092 L26.3090697,19.7897053 L26.3181499,19.825955 L26.3220697,19.8587053 L26.3266056,19.8833789 L26.3280697,19.9117053 L26.3316057,19.9410826 L26.3310697,19.9667053 L26.3333333,20 L26.3310697,20.0327053 L26.3317258,20.0568471 L26.3280697,20.0837053 L26.3266056,20.1166211 L26.3210697,20.1417053 L26.3185087,20.1720024 L26.3110697,20.2027053 L26.3069226,20.2292908 L26.2980697,20.2567053 L26.2919532,20.2853021 L26.2820697,20.3087053 L26.2750347,20.3372588 L26.2590697,20.3717053 L26.2520581,20.3955002 L26.2440697,20.4097053 L26.2316922,20.4397747 L26.2090697,20.4797053 L26.198822,20.5013503 L26.1890697,20.5137053 L26.1776454,20.5360882 L26.1510697,20.5717053 L26.1322436,20.6016064 L26.1210697,20.6137053 L26.1136446,20.625449 L26.0810697,20.6607053 L26.0523217,20.6950221 L21.2189884,25.6950221 C20.8351383,26.0921084 20.2020642,26.1028385 19.8049779,25.7189884 C19.4078916,25.3351383 19.3971615,24.7020642 19.7810116,24.3049779 L22.9750697,20.9997053 L15.1430697,21 C14.6302339,21 14.2075626,20.6139598 14.1497975,20.1166211 L14.1430697,20 C14.1430697,19.4477153 14.590785,19 15.1430697,19 L22.9690697,18.9997053 L19.7824545,15.7172169 C19.427372,15.3514113 19.408082,14.7838306 19.7189078,14.3961194 L19.8034884,14.3031598 Z"></path>
      </g>
    </g>
  </Icon>
)
StartMaternity.defaultProps = {
  size: 40,
}

export default StartMaternity
