import React from 'react'
import Icon from 'react-icon-base'

const DependentDelete = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="symbol" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <g id="icon_fuyousakujo" stroke="currentColor" strokeWidth="2.5">
        <g id="Page-1" transform="translate(20.000000, 23.500000) scale(-1, 1) translate(-20.000000, -23.500000) translate(1.000000, 11.000000)">
          <path d="M33.0673145,6.90679 C33.0673145,3.40952333 30.2312478,0.573456667 26.7339811,0.573456667 C23.2367145,0.573456667 20.4006478,3.40952333 20.4006478,6.90679 C20.4006478,10.4040567 23.2367145,13.2401233 26.7339811,13.2401233 C30.2312478,13.2401233 33.0673145,10.4040567 33.0673145,6.90679 Z" id="Stroke-1"></path>
          <path d="M37.5798145,24.0859567 C37.5798145,18.0965233 32.7234145,13.2401233 26.7339811,13.2401233 C20.7445478,13.2401233 15.8881478,18.0965233 15.8881478,24.0859567" id="Stroke-3"></path>
          <path d="M12.7180611,12.0180433 C12.7180611,9.56071 10.7262278,7.56887667 8.26889447,7.56887667 C5.81219447,7.56887667 3.82036114,9.56071 3.82036114,12.0180433 C3.82036114,14.4747433 5.81219447,16.4665767 8.26889447,16.4665767 C10.7262278,16.4665767 12.7180611,14.4747433 12.7180611,12.0180433 Z" id="Stroke-5"></path>
          <path d="M15.8881478,24.0859567 C15.8881478,19.87809 12.4763811,16.4669567 8.26914781,16.4669567 C4.06191447,16.4669567 0.650147806,19.87809 0.650147806,24.0859567" id="Stroke-7"></path>
        </g>
        <path d="M26.6855018,10 L37.039186,10" id="Stroke-5"></path>
        <path d="M37.039186,10 L33.039186,5.64631579" id="Stroke-7"></path>
        <path d="M37.039186,14.3536842 L33.039186,10" id="Stroke-7" transform="translate(35.039186, 12.176842) scale(1, -1) translate(-35.039186, -12.176842) "></path>
      </g>
    </g>
  </Icon>
)

DependentDelete.defaultProps = {
  size: 40
}

export default DependentDelete
