import { FC, ReactNode } from 'react';
import { year } from '@jbc-year-end-adj/2024/consts';

type TitleProps = {
  children: ReactNode;
};

export const Title: FC<TitleProps> = ({ children }) => {
  return (
    <h1 className="m-title-main">
      <span>{`${year}年の`}</span>
      {children}
    </h1>
  );
};
