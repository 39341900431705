import React from 'react'
import Icon from 'react-icon-base'

const RoumuLogo = props => (
  <Icon viewBox="0 0 938.7 128.4" {...props}>
    <g fill='none' fillRule='evenodd'>
      <path fill='#007CFF' d='M62.6 76.5C42.7 74.1 17.7 72.1 0 71.6v-23c18.7.5 44.2 2.4 62.6 4.6zm1.6-39.2c-19-2.1-45.6-4.4-62.6-4.9V9.2c18 .7 42.8 2.4 62.6 4.7zM2.8 102.8c57-1.3 84.1-19.2 88.8-73.8h26.3c-4.7 73.2-48.5 97.8-115.1 98.1zm80-78.4V5.3h14.6v19.1zm18.9 0V5.3h14.7v19.1zM148.7 126.5v-21.8h72V85.9h-70.3V64.2h70.3V48.4h-71.9v-22h96.6v100.3zM392.2 19c-1.8 73-45.2 104.5-98.8 108.8v-22.9c41.2-6.2 66.4-30.6 70.8-70.3h-85.6V11.1h92.3V0h14.8v11.1h3.9V0h14.7v19zM477.1 127.1v-23.2H497c9.2 0 12.6-3.5 12.6-12.5V49.6h-31.4c-3.2 44.3-29.2 70.1-58.5 78.8v-24.7c18.1-10 32.1-27.6 33.3-54.1h-33.3v-23h33.4V6.2h25.6v20.4h57.2v66.6c0 23.9-10.4 33.9-33 33.9zM627.2 43.2a504.1 504.1 0 0 0-60.9-7.7V10.8a581.8 581.8 0 0 1 60.9 7.1zm-58.8 59.3c50.7-3.5 81.4-17.4 85.5-71.8h26.7c-4.1 68.3-43 94.8-112.2 96.2z'/>
      <path fill='#333' d='M744 124.1c11.5-.4 13-1.1 13-18.2h-19.6c-2.1 8.9-7.7 17.7-22.5 21.3l-1.1-2.1c13.8-2.9 19.3-10.9 21.3-19.2h-16.7v-2.2h17.1a45.7 45.7 0 0 0 .8-9.5h2.2a51.9 51.9 0 0 1-.7 9.5h21.3c0 19.9-1.2 21.7-14.4 22.6zm19.2-23.9v-9.6h-46.1v9.7H715V88.5h33.2c2.5-2.5 8.3-8.4 11.8-15.3l2.1 1c-3.9 7.1-9.6 12.5-11.4 14.3h14.7v11.7zm-38.6-14.3s-3.1-5.1-7.2-10.6l1.8-1.4c4.1 5.9 7.2 11 7.2 11zm15.9-1a115 115 0 0 0-6.3-10.3l1.9-1.3c3.4 5.5 6.2 10.6 6.2 10.6zM792.1 96h-.6c-1.8 7.5-4.2 14.5-11.2 22.5l-1.2-2.3c6.2-6.8 8.6-13.2 10.6-20.2h-10.6v-2h15.7a95.7 95.7 0 0 0-10.7-8.7l1.4-1.5 5.6 4.4a39 39 0 0 0 8.4-10.8h-18.6v-1.9h21v2a53.5 53.5 0 0 1-9.2 11.9l4 3.6-1.3 1h8.8v2.1a52.2 52.2 0 0 1-4.9 13.4l-1.6-1.5a33.6 33.6 0 0 0 4.5-12h-7.9v23.4c0 5.2-1.2 7.5-10 7.5l-1-2.3c8.4 0 8.8-1.3 8.8-5.3zm40 5.3a53.1 53.1 0 0 1-13.1-7.9c-5.3 5.5-13.7 8.6-13.7 8.6l-.9-2s8.1-2.8 13-7.6c-2.8-2.2-5.2-5.4-8.2-9a38.8 38.8 0 0 1-5.8 6.1l-1.4-1.6s8.1-6.3 10.7-14.7l2.1.5a34.1 34.1 0 0 1-2.5 4.6h19.5v2.1h-4.3a33.6 33.6 0 0 1-7 11.8 47.7 47.7 0 0 0 12.7 7.1zm-14.3 23.3c10.7-.7 11-.8 11-15.5h-11.1c-1.8 7.8-5.5 13.3-14.1 18.1l-1.3-1.9c7.4-3.6 11.8-9.2 13.3-16.2h-11.5v-2.2h11.8a42.2 42.2 0 0 0 .6-7.5h2a59.2 59.2 0 0 1-.7 7.5H831c0 17.4-.1 19.1-12.2 19.8zm-6.3-44.2-.9 1.4a46.6 46.6 0 0 0 8.3 9.4 29 29 0 0 0 6.4-10.8zM881.3 125.8v-24.3h-28.1v24.3h-3V76.6h3v22.3h28.1V76.6h2.9v49.2zM935.3 125.8l-13-22.1H906v22.1h-2.9V76.6h17.1c8.7 0 15.3 4.1 15.3 13.1s-5.1 12.7-10 13.7l13.2 22.4zM920 101.2c7.5 0 12.6-3.7 12.6-11.5s-5.8-10.5-12.8-10.5H906v22z'/>
    </g>
  </Icon>
)
RoumuLogo.defaultProps = {
  size: 160
}

export default RoumuLogo
