import { gql } from '@apollo/client';

export type QueryVariables = {
  input: {
    employeeId: string;
    comment?: string;
  };
};

export const RE_REQUEST = gql`
  mutation ReRequest($input: ReRequestInput!) {
    reRequest(input: $input) {
      employee {
        id
      }
    }
  }
`;
