import { useState } from 'react';

export type ViewType = 'form' | 'preview';

export const useToggleSection = (defaultViewType: ViewType | undefined = 'preview') => {
  const [viewType, setViewType] = useState<ViewType>(defaultViewType);

  return {
    viewType,
    setViewType
  };
};
