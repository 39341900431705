import React from 'react';
import ReactGA from 'react-ga';
import _ from 'lodash';
import useDeepCompareEffect from 'use-deep-compare-effect';

const pageTracker = WrappedComponent => props => {
  useDeepCompareEffect(() => {
    if (!props.match.path) return;
    const pathName = props.match.path.replace(/\/:year\(\\d\+\)/, '') || '/';
    if (process.env.NODE_ENV === 'production') {
      ReactGA.set({ page: pathName });
      ReactGA.pageview(pathName);
    }
  }, [props.match]);
  return <WrappedComponent {...props} />;
};

export const withTracker = components => _.fromPairs(_.map(components, (component, name) => [name, pageTracker(component)]));
