import React from 'react'
import Icon from 'react-icon-base'

const PersonalInformation = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="icon/employees/PersonalInformation" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group" transform="translate(2.000000, 2.000000)" fill={props.color}>
        <path d="M31.8064516,0 C33.0179149,0 34,0.976833273 34,2.18181818 L34,33.8181818 C34,35.0231667 33.0179149,36 31.8064516,36 L2.19354839,36 C0.982085065,36 0,35.0231667 0,33.8181818 L0,2.18181818 C0,0.976833273 0.982085065,0 2.19354839,0 L31.8064516,0 Z M31,2 L3,2 C2.44771525,2 2,2.44771525 2,3 L2,33 C2,33.5522847 2.44771525,34 3,34 L31,34 C31.5522847,34 32,33.5522847 32,33 L32,3 C32,2.44771525 31.5522847,2 31,2 Z" id="Rectangle" fillRule="nonzero"></path>
        <path d="M14.181909,8 C17.3803965,8 19.974013,10.5936165 19.974013,13.7921039 C19.974013,15.5999447 19.1454285,17.2145469 17.8473681,18.2768019 C21.0937539,19.6912028 23.3638181,22.9293332 23.3638181,26.6971514 C23.3638181,27.2684805 22.9006644,27.7316342 22.3293353,27.7316342 C21.7580063,27.7316342 21.2948526,27.2684805 21.2948526,26.6971514 C21.2948526,22.7691981 18.1098624,19.5842079 14.181909,19.5842079 C10.2539557,19.5842079 7.06896552,22.7691981 7.06896552,26.6971514 C7.06896552,27.2684805 6.60581181,27.7316342 6.03448276,27.7316342 C5.46315371,27.7316342 5,27.2684805 5,26.6971514 C5,22.9291959 7.27022962,19.6909668 10.5173263,18.2758265 C9.21868239,17.2151174 8.3898051,15.6002641 8.3898051,13.7921039 C8.3898051,10.5936165 10.9834216,8 14.181909,8 Z M14.181909,10.0689655 C12.1260797,10.0689655 10.4587706,11.7362746 10.4587706,13.7921039 C10.4587706,15.8479333 12.1260797,17.5152424 14.181909,17.5152424 C16.2377384,17.5152424 17.9050475,15.8479333 17.9050475,13.7921039 C17.9050475,11.7362746 16.2377384,10.0689655 14.181909,10.0689655 Z" id="Stroke-3" fillRule="nonzero"></path>
        <path d="M28,25 C28.7402524,25 29.3865739,25.4021661 29.7323937,25.9999275 L35.047619,26 C35.5736045,26 36,26.4477153 36,27 C36,27.506261 35.6417094,27.9246546 35.1768515,27.9908712 L35.047619,28 L29.7318119,28.0010775 C29.3858493,28.5982846 28.7398375,29 28,29 C26.8954305,29 26,28.1045695 26,27 C26,25.8954305 26.8954305,25 28,25 Z M28,19 C28.7402524,19 29.3865739,19.4021661 29.7323937,19.9999275 L35.047619,20 C35.5736045,20 36,20.4477153 36,21 C36,21.506261 35.6417094,21.9246546 35.1768515,21.9908712 L35.047619,22 L29.7318119,22.0010775 C29.3858493,22.5982846 28.7398375,23 28,23 C26.8954305,23 26,22.1045695 26,21 C26,19.8954305 26.8954305,19 28,19 Z M28,13 C28.7402524,13 29.3865739,13.4021661 29.7323937,13.9999275 L35.047619,14 C35.5736045,14 36,14.4477153 36,15 C36,15.506261 35.6417094,15.9246546 35.1768515,15.9908712 L35.047619,16 L29.7318119,16.0010775 C29.3858493,16.5982846 28.7398375,17 28,17 C26.8954305,17 26,16.1045695 26,15 C26,13.8954305 26.8954305,13 28,13 Z M28,7 C28.7402524,7 29.3865739,7.40216612 29.7323937,7.99992752 L35.047619,8 C35.5736045,8 36,8.44771525 36,9 C36,9.50626102 35.6417094,9.92465461 35.1768515,9.99087118 L35.047619,10 L29.7318119,10.0010775 C29.3858493,10.5982846 28.7398375,11 28,11 C26.8954305,11 26,10.1045695 26,9 C26,7.8954305 26.8954305,7 28,7 Z" id="Combined-Shape"></path>
      </g>
    </g>
  </Icon>
)

PersonalInformation.defaultProps = {
  size: 30,
  color: "#3498DB"
}

export default PersonalInformation
