import { yup } from '@jbc-year-end-adj/common/yup';
import { Income } from '../../../../query';
import { amountFormat } from '@jbc-year-end-adj/common/utils/formatter';

export type Schema = {
  mainJobIncome?: string;
  sideJobIncome?: string;
  businessIncome?: string;
  businessExpense?: string;
  miscellaneousIncomeOfficalPension?: string;
  miscellaneousIncomeOther?: string;
  miscellaneousExpense?: string;
  dividendIncome?: string;
  dividendExpense?: string;
  realEstateIncome?: string;
  realEstateExpense?: string;
  retirementIncome?: string;
  lengthOfService?: string;
  retireForDisablity?: boolean;
  isOfficerRetirementAllowance?: boolean;
  otherIncome?: string;
  otherExpense?: string;
  otherExpenseSpecialDeduction?: string;
  otherEarings?: string;
};

export const schema = yup.object({
  mainJobIncome: yup
    .string()
    .positiveAmountFormat()
    .maxAmount(999999999)
    .label('給与所得 収入金額（当社）'),
  sideJobIncome: yup
    .string()
    .positiveAmountFormat()
    .maxAmount(999999999)
    .label('給与所得 収入金額（他社）'),
  businessIncome: yup
    .string()
    .positiveAmountFormat()
    .maxAmount(999999999)
    .label('事業所得 収入金額'),
  businessExpense: yup
    .string()
    .positiveAmountFormat()
    .maxAmount(999999999)
    .label('事業所得 必要経費'),
  miscellaneousIncomeOfficalPension: yup
    .string()
    .positiveAmountFormat()
    .maxAmount(999999999)
    .label('雑所得 収入金額（公的年金等に係る雑所得）'),
  miscellaneousIncomeOther: yup
    .string()
    .positiveAmountFormat()
    .maxAmount(999999999)
    .label('雑所得 収入金額（公的年金等以外の雑所得）'),
  miscellaneousExpense: yup
    .string()
    .positiveAmountFormat()
    .maxAmount(999999999)
    .label('雑所得 必要経費'),
  dividendIncome: yup
    .string()
    .positiveAmountFormat()
    .maxAmount(999999999)
    .label('配当所得 収入金額'),
  dividendExpense: yup
    .string()
    .positiveAmountFormat()
    .maxAmount(999999999)
    .label('配当所得 必要経費'),
  realEstateIncome: yup
    .string()
    .positiveAmountFormat()
    .maxAmount(999999999)
    .label('不動産所得 収入金額'),
  realEstateExpense: yup
    .string()
    .positiveAmountFormat()
    .maxAmount(999999999)
    .label('不動産所得 必要経費'),
  retirementIncome: yup
    .string()
    .positiveAmountFormat()
    .maxAmount(999999999)
    .label('退職所得 収入金額'),
  lengthOfService: yup
    .string()
    .numberFormat()
    .label('勤続年数'),
  retireForDisablity: yup.boolean().label('障害者になったことに直接基因して退職した'),
  isOfficerRetirementAllowance: yup.boolean().label('退職手当等が特定役員退職手当等に該当する'),
  otherIncome: yup
    .string()
    .positiveAmountFormat()
    .maxAmount(999999999)
    .label('その他所得 収入金額'),
  otherExpense: yup
    .string()
    .positiveAmountFormat()
    .maxAmount(999999999)
    .label('その他所得 必要経費'),
  otherExpenseSpecialDeduction: yup
    .string()
    .positiveAmountFormat()
    .maxAmount(999999999)
    .label('その他所得 必要経費 うち特別控除額'),
  otherEarings: yup
    .string()
    .positiveAmountFormat()
    .maxAmount(999999999)
    .label('その他所得 所得金額')
});

export const generateDefaultValues = (income: Income | undefined) => {
  return {
    mainJobIncome: amountFormat(income?.mainJobIncome),
    sideJobIncome: amountFormat(income?.sideJobIncome),
    businessIncome: amountFormat(income?.businessIncome),
    businessExpense: amountFormat(income?.businessExpense),
    miscellaneousIncomeOfficalPension: amountFormat(income?.miscellaneousIncomeOfficalPension),
    miscellaneousIncomeOther: amountFormat(income?.miscellaneousIncomeOther),
    miscellaneousExpense: amountFormat(income?.miscellaneousExpense),
    dividendIncome: amountFormat(income?.dividendIncome),
    dividendExpense: amountFormat(income?.dividendExpense),
    realEstateIncome: amountFormat(income?.realEstateIncome),
    realEstateExpense: amountFormat(income?.realEstateExpense),
    retirementIncome: amountFormat(income?.retirementIncome),
    lengthOfService: income?.lengthOfService,
    retireForDisablity: !!income?.retireForDisablity,
    isOfficerRetirementAllowance: !!income?.isOfficerRetirementAllowance,
    otherIncome: amountFormat(income?.otherIncome),
    otherExpense: amountFormat(income?.otherExpense),
    otherExpenseSpecialDeduction: amountFormat(income?.otherExpenseSpecialDeduction),
    otherEarings: amountFormat(income?.otherEarings)
  };
};
