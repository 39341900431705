import { FC, ReactNode } from 'react';
import { DeleteSquare } from 'jbc-front/components/icons';
import { Section } from 'components/ui/Section';
import styles from './DeletableSection.scss';

type DeletableSectionProps = {
  children: ReactNode;
};

export const DeletableSection: FC<DeletableSectionProps> = ({ children }) => {
  return <Section>{children}</Section>;
};

type HeaderProps = {
  onDelete: () => void;
  children: ReactNode;
};

export const Header: FC<HeaderProps> = ({ onDelete, children }) => {
  return (
    <Section.Header className={styles.header}>
      {children}
      <DeleteSquare onClick={onDelete} className={styles.icon} />
    </Section.Header>
  );
};

type BodyProps = {
  children: ReactNode;
};

export const Body: FC<BodyProps> = ({ children }) => {
  return <Section.Body className={styles.body}>{children}</Section.Body>;
};
