import { FC, ReactNode } from 'react';
import { Section } from 'components/ui/Section';

import { Question } from '../type';

import { Lead } from './parts/Lead';
import { Description } from './parts/Description';
import { Content } from './parts/Content';

type AttachmentsProps = {
  question: Question;
  children: ReactNode;
};

export const Attachments: FC<AttachmentsProps> = ({ question, children }) => {
  const description = question.questionDescriptions.find(description => description.field == 'top')?.description;

  return (
    <Section>
      <Section.Header>ファイルの添付</Section.Header>

      <Section.Body>
        <Content>
          <Lead>提出ファイルがあれば添付してください。</Lead>

          <Description>{description}</Description>

          {children}
        </Content>
      </Section.Body>
    </Section>
  );
};
