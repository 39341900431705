import { FC } from 'react';
import { Grid } from '../../../components/Grid';
import { Diff } from '../../../components/Diff';
import { PreviewSubSection } from '../../../components/PreviewSubSection';
import { FormerJob, OtherFamily } from '../../../query';
import { useResult } from '../ResultProvider';
import { Section } from 'components/ui/Section';
import styles from './Preview.scss';
import { amountFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { useYear } from 'hooks/useYear';
import { HandicapType } from '@jbc-year-end-adj/types';

import {
  calcTotalSalaryIncome,
  salaryEarnings as calcSalaryEarnings,
  totalEarnings as calcTotalEarnings,
  miscellaneousEarnings as calcMiscellaneousEarnings
  // @ts-ignore
} from 'utils/income';
import { applicableForEmployee, applicableForSpouse, applicableForOtherFamily } from '@jbc-year-end-adj/ancient/utils/incomeAdjustment';

export const Preview: FC = () => {
  const year = useYear();
  const {
    employee: { profile, request }
  } = useResult();
  const income = profile.income;
  const requestIncome = request?.profile?.income;

  const calcFormerJobsPaymentAmount = (formerJobs: FormerJob[] | undefined) => {
    if (!formerJobs) return 0;

    return formerJobs.reduce((totalIncome, formerJob) => {
      if (formerJob.paymentAmount) {
        return totalIncome + Number(formerJob.paymentAmount);
      } else {
        return totalIncome;
      }
    }, 0);
  };

  const calcIncomeAdjustmentDeduction = (
    otherFamilies: OtherFamily[],
    handicapClassification?: HandicapType,
    spouseHandicapClassification?: HandicapType,
    spouseTotalEarnings?: string
  ) => {
    return (
      applicableForEmployee(handicapClassification) ||
      applicableForSpouse(spouseHandicapClassification, spouseTotalEarnings) ||
      otherFamilies.some(otherFamily => applicableForOtherFamily(otherFamily, year))
    );
  };

  const requestIncomeAdjustmentDeduction = calcIncomeAdjustmentDeduction(
    request?.profile?.otherFamilies || [],
    request?.profile?.handicapClassification?.classification,
    request?.profile?.spouse?.thisYear?.handicapType,
    request?.profile?.spouse?.income?.totalEarnings
  );
  const requestFormerJobsPaymentAmount = String(calcFormerJobsPaymentAmount(request?.profile?.formerJobs));
  const requestTotalSalaryIncome = String(
    calcTotalSalaryIncome(requestIncome?.mainJobIncome, requestIncome?.sideJobIncome, requestFormerJobsPaymentAmount)
  );
  const requestSalaryEarnings = calcSalaryEarnings(requestTotalSalaryIncome, year, requestIncomeAdjustmentDeduction);
  const requestMiscellaneousEarnings = calcMiscellaneousEarnings(
    requestIncome?.miscellaneousIncomeOfficalPension,
    requestIncome?.miscellaneousIncomeOther,
    requestIncome?.miscellaneousExpense,
    request?.profile?.birthday,
    year,
    {
      salary: requestSalaryEarnings,
      business: requestIncome?.businessEarnings,
      dividend: requestIncome?.dividendEarnings,
      realEstate: requestIncome?.realEstateEarnings,
      retirement: requestIncome?.retirementEarnings,
      other: requestIncome?.otherEarings
    }
  );
  const requestTotalEarnings = calcTotalEarnings(
    requestSalaryEarnings,
    requestIncome?.businessEarnings,
    requestMiscellaneousEarnings,
    requestIncome?.dividendEarnings,
    requestIncome?.realEstateEarnings,
    requestIncome?.retirementEarnings,
    requestIncome?.otherEarings
  );

  const incomeAdjustmentDeduction = calcIncomeAdjustmentDeduction(
    profile.otherFamilies,
    profile.handicapClassification?.classification,
    profile.spouse?.thisYear?.handicapType,
    profile.spouse?.income?.totalEarnings
  );
  const formerJobsPaymentAmount = String(calcFormerJobsPaymentAmount(profile.formerJobs));
  const totalSalaryIncome = String(calcTotalSalaryIncome(income?.mainJobIncome, income?.sideJobIncome, formerJobsPaymentAmount));
  const salaryEarnings = calcSalaryEarnings(totalSalaryIncome, year, incomeAdjustmentDeduction);
  const miscellaneousEarnings = calcMiscellaneousEarnings(
    income?.miscellaneousIncomeOfficalPension,
    income?.miscellaneousIncomeOther,
    income?.miscellaneousExpense,
    profile.birthday,
    year,
    {
      salary: salaryEarnings,
      business: income?.businessEarnings,
      dividend: income?.dividendEarnings,
      realEstate: income?.realEstateEarnings,
      retirement: income?.retirementEarnings,
      other: income?.otherEarings
    }
  );
  const totalEarnings = calcTotalEarnings(
    salaryEarnings,
    income?.businessEarnings,
    miscellaneousEarnings,
    income?.dividendEarnings,
    income?.realEstateEarnings,
    income?.retirementEarnings,
    income?.otherEarings
  );

  return (
    <div className={styles.preview}>
      <Grid>
        <Diff title="合計所得金額" source={amountFormat(totalEarnings)} input={amountFormat(requestTotalEarnings)} />
      </Grid>

      <Section>
        <Section.Header>本人の給与収入</Section.Header>
        <Section.Body>
          <Grid>
            <PreviewSubSection>
              <PreviewSubSection.Header>給与所得</PreviewSubSection.Header>
              <PreviewSubSection.Body>
                <Diff
                  title="収入金額（当社）"
                  source={amountFormat(income?.mainJobIncome)}
                  input={amountFormat(requestIncome?.mainJobIncome)}
                />
                <Diff
                  title="収入金額（他社）"
                  source={amountFormat(income?.sideJobIncome)}
                  input={amountFormat(requestIncome?.sideJobIncome)}
                />
                <Diff
                  title="収入金額（前職）"
                  source={amountFormat(formerJobsPaymentAmount)}
                  input={amountFormat(requestFormerJobsPaymentAmount)}
                />
                <Diff title="収入金額（合計)" source={amountFormat(totalSalaryIncome)} input={amountFormat(requestTotalSalaryIncome)} />
                <Diff title="所得金額" source={amountFormat(salaryEarnings)} input={amountFormat(requestSalaryEarnings)} />
              </PreviewSubSection.Body>
            </PreviewSubSection>
          </Grid>
        </Section.Body>
      </Section>

      <Section>
        <Section.Header>本人の給与収入以外の収入</Section.Header>
        <Section.Body>
          <Grid colGap>
            <PreviewSubSection>
              <PreviewSubSection.Header>事業所得</PreviewSubSection.Header>
              <PreviewSubSection.Body>
                <Diff title="収入金額" source={amountFormat(income?.businessIncome)} input={amountFormat(requestIncome?.businessIncome)} />
                <Diff
                  title="必要経費"
                  source={amountFormat(income?.businessExpense)}
                  input={amountFormat(requestIncome?.businessExpense)}
                />
                <Diff
                  title="所得金額"
                  source={amountFormat(income?.businessEarnings)}
                  input={amountFormat(requestIncome?.businessEarnings)}
                />
              </PreviewSubSection.Body>
            </PreviewSubSection>

            <PreviewSubSection>
              <PreviewSubSection.Header>雑所得</PreviewSubSection.Header>
              <PreviewSubSection.Body>
                <Diff
                  title="収入金額（公的年金等）"
                  source={amountFormat(income?.miscellaneousIncomeOfficalPension)}
                  input={amountFormat(requestIncome?.miscellaneousIncomeOfficalPension)}
                />
                <Diff
                  title="収入金額（公的年金等以外）"
                  source={amountFormat(income?.miscellaneousIncomeOther)}
                  input={amountFormat(requestIncome?.miscellaneousIncomeOther)}
                />
                <Diff
                  title="必要経費"
                  source={amountFormat(income?.miscellaneousExpense)}
                  input={amountFormat(requestIncome?.miscellaneousExpense)}
                />
                <Diff title="所得金額" source={amountFormat(miscellaneousEarnings)} input={amountFormat(requestMiscellaneousEarnings)} />
              </PreviewSubSection.Body>
            </PreviewSubSection>

            <PreviewSubSection>
              <PreviewSubSection.Header>配当所得</PreviewSubSection.Header>
              <PreviewSubSection.Body>
                <Diff title="収入金額" source={amountFormat(income?.dividendIncome)} input={amountFormat(requestIncome?.dividendIncome)} />
                <Diff
                  title="必要経費"
                  source={amountFormat(income?.dividendExpense)}
                  input={amountFormat(requestIncome?.dividendExpense)}
                />
                <Diff
                  title="所得金額"
                  source={amountFormat(income?.dividendEarnings)}
                  input={amountFormat(requestIncome?.dividendEarnings)}
                />
              </PreviewSubSection.Body>
            </PreviewSubSection>

            <PreviewSubSection>
              <PreviewSubSection.Header>不動産所得</PreviewSubSection.Header>
              <PreviewSubSection.Body>
                <Diff
                  title="収入金額"
                  source={amountFormat(income?.realEstateIncome)}
                  input={amountFormat(requestIncome?.realEstateIncome)}
                />
                <Diff
                  title="必要経費"
                  source={amountFormat(income?.realEstateExpense)}
                  input={amountFormat(requestIncome?.realEstateExpense)}
                />
                <Diff
                  title="所得金額"
                  source={amountFormat(income?.realEstateEarnings)}
                  input={amountFormat(requestIncome?.realEstateEarnings)}
                />
              </PreviewSubSection.Body>
            </PreviewSubSection>

            <PreviewSubSection>
              <PreviewSubSection.Header>退職所得</PreviewSubSection.Header>
              <PreviewSubSection.Body>
                <Diff
                  title="収入金額"
                  source={amountFormat(income?.retirementIncome)}
                  input={amountFormat(requestIncome?.retirementIncome)}
                />
                <Diff title="勤続年数" source={income?.lengthOfService} input={requestIncome?.lengthOfService} />
                <Diff
                  title="障害者になったことに直接基因して退職した"
                  source={income?.retireForDisablity ? 'はい' : 'いいえ'}
                  input={requestIncome?.retireForDisablity ? 'はい' : 'いいえ'}
                />
                <Diff
                  title="退職手当等が特定役員退職手当等に該当する"
                  source={income?.isOfficerRetirementAllowance ? 'はい' : 'いいえ'}
                  input={requestIncome?.isOfficerRetirementAllowance ? 'はい' : 'いいえ'}
                />
                <Diff
                  title="退職所得控除額"
                  source={amountFormat(income?.retirementIncomeDeductionAmount)}
                  input={amountFormat(requestIncome?.retirementIncomeDeductionAmount)}
                />
                <Diff
                  title="所得金額"
                  source={amountFormat(income?.retirementEarnings)}
                  input={amountFormat(requestIncome?.retirementEarnings)}
                />
              </PreviewSubSection.Body>
            </PreviewSubSection>

            <PreviewSubSection>
              <PreviewSubSection.Header>その他所得</PreviewSubSection.Header>
              <PreviewSubSection.Body>
                <Diff title="収入金額" source={amountFormat(income?.otherIncome)} input={amountFormat(requestIncome?.otherIncome)} />
                <Diff title="必要経費" source={amountFormat(income?.otherExpense)} input={amountFormat(requestIncome?.otherExpense)} />
                <Diff
                  title="必要経費 うち特別控除額"
                  source={amountFormat(income?.otherExpenseSpecialDeduction)}
                  input={amountFormat(requestIncome?.otherExpenseSpecialDeduction)}
                />
                <Diff title="所得金額" source={amountFormat(income?.otherEarings)} input={amountFormat(requestIncome?.otherEarings)} />
              </PreviewSubSection.Body>
            </PreviewSubSection>
          </Grid>
        </Section.Body>
      </Section>
    </div>
  );
};
