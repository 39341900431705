import React from 'react';
import { useApolloClient } from '@apollo/client';
import { PreviewPdf, useSession, useYear, useReportList } from '../components';
import ListGroup from 'jbc-front/components/ListGroup';
import { Preview } from 'jbc-front/components/icons';
import { useNotify } from '../actions';

const Item = ({ name, employee, apolloClient, setPdf, show, notify, reports, hasMyNumberPermission }) => {
  const year = useYear();
  const reportOnClick = e => {
    e.stopPropagation();
    reports[name].show({
      client: apolloClient,
      setPdf,
      show,
      notify,
      employeeId: employee.id,
      hasMyNumberPermission,
      year
    });
  };
  return (
    <ListGroup.Item as="a" onClick={reportOnClick} onKeyPress={reportOnClick}>
      <Preview size={16} className="u-mr10" />
      <div style={{ width: 250 }}>{reports[name].title}</div>
    </ListGroup.Item>
  );
};

const ReportList = ({ employee, profile }) => {
  const {
    me: { hasMyNumberPermission },
    clientYearly: { year }
  } = useSession();
  const apolloClient = useApolloClient();
  const notify = useNotify();
  const reports = useReportList(year);

  if (!reports) return null;

  return (
    <PreviewPdf>
      {({ show, isOpen, setPdf }) => {
        if (!profile || (!profile.needAdjThisYear && !profile.nextYearOnly) || employee.salaryGreaterThan20Million) {
          return null;
        }
        const itemProps = { employee, apolloClient, setPdf, show, notify, hasMyNumberPermission, reports };
        return profile.nextYearOnly ? (
          <ListGroup>
            <Item name="dependentDeductionNextYear" {...itemProps} />
          </ListGroup>
        ) : (
          <ListGroup>
            <Item name="dependentDeductionThisYear" {...itemProps} />
            {!profile.isNotCoveredByNextYear && <Item name="dependentDeductionNextYear" {...itemProps} />}
            <Item name="insuranceDeduction" {...itemProps} />
            {year >= 2020 && <Item name="bsiDeduction" {...itemProps} />}
            {year <= 2019 && profile.hasSpouseDeductionThisYear && <Item name="spouseDeduction" {...itemProps} />}
            <Item name="fileMount" {...itemProps} />
          </ListGroup>
        );
      }}
    </PreviewPdf>
  );
};

export default ReportList;
