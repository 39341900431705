import { FC } from 'react';
import useRouter from 'use-react-router';
import { Loading } from 'jbc-front/components/presenters/ui/Loading';
// @ts-ignore
import { Link } from 'react-router-dom';

import { useAdminQuery as useQuery } from '@jbc-year-end-adj/2024/hooks/graphql/useQuery';
import { FETCH_CUSTOM_MAIL, QueryResult } from './query';
import { Form } from './parts/Form';
import { EmailTypeKeys, emailTypes } from './consts';

export const CustomMailsEdit: FC = () => {
  const { match } = useRouter();
  const emailType = match.params.email_type as EmailTypeKeys;

  const { loading, data } = useQuery<QueryResult>(FETCH_CUSTOM_MAIL, {
    fetchPolicy: 'network-only',
    variables: {
      emailType: { emailType }
    }
  });

  if (loading) return <Loading />;

  return (
    <div>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">メールカスタマイズ</h1>
        <p className="m-title-main-note">メールの内容をカスタマイズできます</p>
      </div>
      <div className="l-wrap-xs l-contents-wrap">
        <div className="l-breadcrumb u-mb20">
          <Link to="/settings" className="l-breadcrumb-link">
            設定
          </Link>
          <Link to="/settings/custom_mails" className="l-breadcrumb-link">
            メールカスタマイズ
          </Link>
          <span className="l-breadcrumb-here">{emailTypes[emailType]}</span>
        </div>

        {data && <Form defaultMail={data.defaultMail} customMail={data.client.customMail} emailType={emailType} />}
      </div>
    </div>
  );
};
