import React from 'react'
import Icon from 'react-icon-base'

const TmNotification = props => (
  <Icon viewBox="0 0 20 20" {...props}>
    <g id="Symbols" strokeWidth="1">
      <g>
        <rect fill="#FFFFFF" x="0" y="0" width="20" height="20"></rect>
        <g id="Group-Copy" transform="translate(1.000000, 3.000000)" fillRule="nonzero">
          <path d="M16.7586207,0 C17.4442156,0 18,0.555784448 18,1.24137931 L18,13.0344828 C18,13.7200776 17.4442156,14.2758621 16.7586207,14.2758621 L1.24137931,14.2758621 C0.555784448,14.2758621 0,13.7200776 0,13.0344828 L0,1.24137931 C0,0.555784448 0.555784448,0 1.24137931,0 L16.7586207,0 Z M16.3,1.7 L1.7,1.7 L1.7,12.575 L16.3,12.575 L16.3,1.7 Z" id="Rectangle"></path>
          <path d="M2.88641986,3.57694145 C3.13653936,3.22677414 3.60345888,3.1232475 3.97377966,3.31859463 L4.07214577,3.37932046 L9.24760216,7.07607503 C9.62960286,7.34893267 9.71808079,7.87980024 9.44522315,8.26180094 C9.19510364,8.61196825 8.72818412,8.7154949 8.35786334,8.52014776 L8.25949723,8.45942193 L3.08404084,4.76266737 C2.70204014,4.48980972 2.61356221,3.95894215 2.88641986,3.57694145 Z" id="Path-3"></path>
          <path d="M8.03763935,3.45759407 C8.26962204,3.09515657 8.73067064,2.9680118 9.11044926,3.14426783 L9.21177713,3.19991116 L15.0444343,6.93317688 C15.4398207,7.1862489 15.5551893,7.71192829 15.3021172,8.10731465 C15.0701346,8.46975216 14.609086,8.59689692 14.2293073,8.42064089 L14.1279795,8.36499756 L8.29532226,4.63173184 C7.8999359,4.37865982 7.78456733,3.85298043 8.03763935,3.45759407 Z" id="Path-3" transform="translate(11.669878, 5.782454) scale(1, -1) translate(-11.669878, -5.782454) "></path>
        </g>
      </g>
    </g>
  </Icon>
)
TmNotification.defaultProps = {
  size: 20
}

export default TmNotification
