import { useCallback, useState, useRef } from 'react';
import { FieldError, FieldErrors, FieldValues } from 'react-hook-form';
import { FormError, FormErrors as Presenter } from '@jbc-year-end-adj/2024/components/form/FormErrors';

export const useFormErrors = <TFieldValues extends FieldValues>() => {
  const ref = useRef<HTMLDivElement>(null);
  const [errors, setErrors] = useState<FormError[]>([]);

  const onSubmitFail = useCallback((errors: FieldErrors<TFieldValues>) => {
    const err = [...flattenErrors(errors)];
    setErrors(err);
    if (err.length > 0) {
      ref.current?.scrollIntoView();
    }
  }, []);

  const FormErrors = () => <Presenter ref={ref} errors={errors} />;

  return {
    onSubmitFail,
    FormErrors
  };
};

const isFieldError = (obj: object): obj is FieldError => 'type' in obj && 'message' in obj;

const flattenErrors = function*<TFieldValues extends FieldValues>(
  errors: FieldErrors<TFieldValues>,
  path: (string | number)[] = []
): Generator<FormError> {
  if (Array.isArray(errors)) {
    for (let index = 0; index < errors.length; index++) {
      yield* flattenErrors(errors[index], [...path, index]);
    }
  } else if (isFieldError(errors)) {
    yield {
      ...errors,
      path: path.reduce((p, c) => (typeof c === 'number' ? `${p}[${c}]` : `${p}.${c}`)) as string,
      message: errors.message || ''
    };
  } else {
    for (const key in errors) {
      yield* flattenErrors(errors[key] as FieldErrors, [...path, key]);
    }
  }
};
