import fp from 'lodash/fp';
import { toJpYearDate } from 'jbc-front/utils/jpYear';
import moment from 'moment';
import _ from 'lodash';

export const recordDisplay = text => (text ? text : '―');

export const numberWithSplit = num => {
  const numStr = `${num ?? recordDisplay()}`;
  if (numStr.startsWith('-')) {
    return '-' + numberWithSplit(numStr.substr(1));
  }
  return numStr |> fp.toArray |> fp.reverse |> fp.chunk(3) |> fp.reverse |> fp.map(arr => arr.reverse().join('')) |> fp.join(',');
};

recordDisplay.yen = record => {
  if (!record) {
    return recordDisplay();
  }
  return numberWithSplit(record) + '円';
};

recordDisplay.date = (date, format = 'wareki') => {
  if (!date) {
    return recordDisplay();
  }
  if (format === 'wareki') {
    return toJpYearDate(date);
  }
  if (format === 'seireki') {
    return moment(date).format('LL');
  }
  return moment(date).format(format);
};

recordDisplay.numbers = (record, name, length, split = '-') => {
  if (!record) {
    return recordDisplay();
  }
  const fields = _.range(0, length).map(idx => record[`${name}${idx}`]);
  if (_.some(fields, field => !field)) {
    return recordDisplay();
  }
  return fields.join(split);
};

recordDisplay.dateOrTime = dateTime => {
  if (!dateTime) {
    return recordDisplay();
  }
  if (moment().isSame(dateTime, 'day')) {
    return moment(dateTime).format('LT');
  }
  if (
    moment()
      .subtract(1, 'days')
      .isSame(dateTime, 'day')
  ) {
    return '昨日';
  }
  if (
    moment()
      .subtract(2, 'days')
      .isSame(dateTime, 'day')
  ) {
    return '一昨日';
  }
  if (moment().isSame(dateTime, 'year')) {
    return moment(dateTime).format('MMMDo');
  }
  return moment(dateTime).format('LL');
};

export default recordDisplay;
