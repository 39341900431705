import { forwardRef } from 'react';
import { Section } from 'jbc-front/components/presenters/form/Section';
import styles from './FormErrors.scss';

export interface FormError {
  path: string;
  message: string;
}

interface FormErrorsProps {
  errors: FormError[];
}

export const FormErrors = forwardRef<HTMLDivElement, FormErrorsProps>(({ errors }, ref) => {
  return (
    <div ref={ref}>
      {errors.length > 0 && (
        <Section title="入力内容に誤りがあります" error>
          <ul className={styles.errorHead}>
            {errors.map(err => (
              <li key={err.path}>{err.message}</li>
            ))}
          </ul>
        </Section>
      )}
    </div>
  );
});
