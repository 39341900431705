import { FC } from 'react';
import { Form } from './Form';
import { Preview } from './Preview';
import { ToggleSection, useToggleSection, ViewType } from '../../../components/ToggleSection';
import { Tag } from '../../../components/Tag';
import { useResult } from '../ResultProvider';
import { Premium as PremiumType } from '../../../query';
import styles from './Premium.scss';

type PremiumProps = {
  setMode: (viewType: ViewType) => void;
};

export const Premium: FC<PremiumProps> = ({ setMode }) => {
  const { viewType, setViewType } = useToggleSection();
  const { employee } = useResult();

  const premium = employee.profile.premium;
  const requestPremium = employee.request?.profile?.premium;

  const handleSetViewType = (viewType: ViewType) => {
    setMode(viewType);
    setViewType(viewType);
  };

  const handleClose = () => {
    handleSetViewType('preview');
    window.location.hash = '';
    window.location.hash = '#request-premium';
  };

  return (
    <ToggleSection viewType={viewType} setViewType={handleSetViewType}>
      <ToggleSection.Header>
        <div className={styles.header}>
          小規模企業共済等掛金
          <AddOrDeleteTag premium={premium} requestPremium={requestPremium} />
        </div>
      </ToggleSection.Header>
      <ToggleSection.Body>
        {viewType === 'preview' && <Preview />}
        {viewType === 'form' && <Form onCancel={handleClose} />}
      </ToggleSection.Body>
    </ToggleSection>
  );
};

type AddOrDeleteTagProps = {
  premium?: PremiumType;
  requestPremium?: PremiumType;
};

const AddOrDeleteTag: FC<AddOrDeleteTagProps> = ({ premium, requestPremium }) => {
  if (!!premium && !requestPremium) {
    return <Tag color="red">削除</Tag>;
  }

  if (!premium && !!requestPremium) {
    return <Tag color="blue">追加</Tag>;
  }

  return null;
};
