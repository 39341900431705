import React, { useState } from 'react';
import Button from 'jbc-front/components/Button';
import Hint from 'jbc-front/components/Hint';
import { Panel, Headding, Item, Lead, Notes, Buttons, Line } from '../components/PrimaryPanel';
import { DescriptionEdit } from '../components/DescriptionEdit';
import { CustomDescription } from '../components/QuestionDescription';
import { FormPlaceholder } from '../components/FormPlaceholder';

const Question = () => {
  const [isPositive, setPositive] = useState(true);
  return (
    <Panel>
      <Headding icon={<DescriptionEdit />}>勤労学生情報</Headding>
      <Item>
        <Lead>あなたは以下の条件を満たしている、勤労学生ですか？</Lead>
        <CustomDescription field="under_note" />
        <Notes>
          <ul>
            <li>
              ・今年の合計所得金額75万円以下　※給与収入のみの場合、給与収入が130万円以下
              <Hint
                text={
                  <div>
                    <div>
                      「合計所得金額」とは、各種損失の繰越控除を適用する前の総所得金額、譲渡所得、配当所得、雑所得、山林所得、退職所得の合計額をいいます。
                    </div>
                    <p>「特定の学校」とは、次のいずれかの学校です。</p>
                    <ul className="m-list-notes">
                      <li>イ 学校教育法に規定する小学校、中学校、高等学校、大学、高等専門学校など</li>
                      <li>ロ 国、地方公共団体、学校法人等により設置された専修学校又は各種学校のうち一定の課程を履修させるもの</li>
                      <li>ハ 職業能力開発促進法の規定による認定職業訓練を行う職業訓練法人で一定の課程を履修させるもの</li>
                    </ul>
                  </div>
                }
              />
            </li>
            <li>・今年の給与所得以外の所得が10万円以下</li>
            <li>・特定の学校の学生、生徒であること</li>
          </ul>
        </Notes>
        <Buttons>
          <Button huge secondary primary={isPositive} onClick={() => setPositive(true)}>
            はい
          </Button>
          <Button huge secondary primary={!isPositive} onClick={() => setPositive(false)}>
            いいえ
          </Button>
        </Buttons>

        {isPositive && (
          <React.Fragment>
            <Line />
            <Lead>以下の情報の記入が必要です。</Lead>
            <FormPlaceholder />
            <Buttons>
              <Button primary disabled>
                次ヘ
              </Button>
            </Buttons>
          </React.Fragment>
        )}
      </Item>
    </Panel>
  );
};

export default Question;
