import React from 'react'
import Icon from 'react-icon-base'

const Link = props => (
  <Icon viewBox="0 0 24 18" {...props}>
    <path fill='currentColor' d='M9.744 12.202L7.1 14.844a1.417 1.417 0 0 1-2 0l-2.283-2.283a1.405 1.405 0 0 1-.415-1c0-.378.148-.733.415-1l5.116-5.117a1.404 1.404 0 0 1 1-.414c.378 0 .733.147 1 .414l2.284 2.284c.384.385.514.965.331 1.478a.977.977 0 1 0 1.841.657 3.386 3.386 0 0 0-.79-3.518l-2.283-2.283a3.347 3.347 0 0 0-2.383-.987c-.9 0-1.746.35-2.382.987L1.435 9.178a3.348 3.348 0 0 0-.987 2.383c0 .9.35 1.746.987 2.382l2.283 2.284c.657.657 1.52.985 2.383.985a3.36 3.36 0 0 0 2.383-.985l2.642-2.643a.977.977 0 1 0-1.382-1.382zm12.472-8.934L19.932.985a3.373 3.373 0 0 0-4.764 0l-2.643 2.643a.977.977 0 1 0 1.382 1.383l2.643-2.643a1.416 1.416 0 0 1 2 0l2.284 2.283c.267.267.414.622.414 1s-.148.733-.414 1l-5.117 5.116a1.404 1.404 0 0 1-1 .414c-.378 0-.733-.147-1-.414l-2.283-2.283a1.423 1.423 0 0 1-.332-1.477.978.978 0 1 0-1.841-.658 3.386 3.386 0 0 0 .79 3.518l2.283 2.283a3.36 3.36 0 0 0 2.383.985c.863 0 1.726-.328 2.383-.985l5.116-5.116a3.348 3.348 0 0 0 .987-2.383c0-.9-.35-1.746-.987-2.383z' />
  </Icon>
)
Link.defaultProps = {
  size: 24
}

export default Link
