import React, { useCallback, useEffect } from 'react';

export const useBeforeUnload = (returnValue = '') => {
  const handleBeforeUnload = useCallback(
    event => {
      event.preventDefault();
      event.returnValue = returnValue;
    },
    [returnValue]
  );

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [handleBeforeUnload]);
};
