import React from 'react'
import Icon from 'react-icon-base'

const BlankTarget = props => (
  <Icon viewBox="0 0 20 20" {...props}>
    <g id="artboart/20x20/icon_blank-2" stroke="none" strokeWidth="1" fill="currentColor" fillRule="evenodd">
      <path d="M17,1 C18.1045695,1 19,1.8954305 19,3 L19,13 C19,14.1045695 18.1045695,15 17,15 L7.57276289,15 L9.08776289,13.5 L16.5,13.5 C17.0128358,13.5 17.4355072,13.1139598 17.4932723,12.6166211 L17.5,12.5 L17.5,3.5 C17.5,2.94771525 17.0522847,2.5 16.5,2.5 L7.5,2.5 C6.98716416,2.5 6.56449284,2.88604019 6.50672773,3.38337887 L6.5,3.5 L6.49976289,10.434 L4.99976289,11.919 L5,3 C5,1.8954305 5.8954305,1 7,1 L17,1 Z" id="Combined-Shape" fill="currentColor"></path>
      <path d="M6.40000005,8.26230015 C6.2895431,8.26230015 6.20000005,8.1727571 6.20000005,8.06230015 C6.20000005,8.01902611 6.21403563,7.97691939 6.24000005,7.94230015 L8.84000005,4.47563349 C8.90627422,4.38726793 9.03163449,4.36935932 9.12000005,4.43563349 C9.13516118,4.44700434 9.1486292,4.46047235 9.16000005,4.47563349 L11.7600001,7.94230015 C11.8262742,8.03066571 11.8083656,8.15602598 11.7200001,8.22230015 C11.6853808,8.24826458 11.6432741,8.26230015 11.6000001,8.26230015 L9.76000005,8.26230015 L9.76000005,16.7623002 C9.76000005,17.0384425 9.53614243,17.2623002 9.26000005,17.2623002 L8.76000005,17.2623002 C8.48385768,17.2623002 8.26000005,17.0384425 8.26000005,16.7623002 L8.26000005,8.26230015 L6.40000005,8.26230015 Z" id="Combined-Shape" fill="currentColor" transform="translate(9.000006, 10.828961) rotate(-315.000000) translate(-9.000006, -10.828961) "></path>
      <path d="M15,15 L15,17 C15,18.1045695 14.1045695,19 13,19 L3,19 C1.8954305,19 1,18.1045695 1,17 L1,7 C1,5.8954305 1.8954305,5 3,5 L5,5 L5,6.5 L3.5,6.5 C2.98716416,6.5 2.56449284,6.88604019 2.50672773,7.38337887 L2.5,7.5 L2.5,16.5 C2.5,17.0522847 2.94771525,17.5 3.5,17.5 L3.5,17.5 L12.5,17.5 C13.0522847,17.5 13.5,17.0522847 13.5,16.5 L13.5,16.5 L13.5,15 L15,15 Z" id="Combined-Shape-Copy" fill="currentColor"></path>
    </g>
  </Icon>
)

BlankTarget.defaultProps = {
  size: 30
}

export default BlankTarget
