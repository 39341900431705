import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';

export type YearMaster = {
  id: number;
  eraName: string;
  eraCode: string;
  eraNumber: number;
  year: number;
};

type QueryResult = {
  yearMasters: YearMaster[];
};

const FETCH_YEAR_MASTER = gql`
  query yearMasters {
    yearMasters {
      id
      eraName
      eraCode
      eraNumber
      year
    }
  }
`;

export const useYearMasters = () => {
  const { data, loading } = useQuery<QueryResult>(FETCH_YEAR_MASTER);

  const yearMasters = useMemo(() => {
    return data?.yearMasters ? data.yearMasters : [];
  }, [data?.yearMasters]);

  return { yearMasters, loading };
};
