import React from 'react'
import Icon from 'react-icon-base'

const Custom11 = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="Custom11" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon/employees/Custom7-Copy-11" fill="#3498DB" fillRule="nonzero">
        <path d="M17.564014,5 C20.493528,5 23.1205403,6.33147634 24.9036835,8.43788362 C25.6479983,8.15071742 26.4547827,7.9934494 27.2972973,7.9934494 C31.0588042,7.9934494 34.1081081,11.1282457 34.1081081,14.995213 L34.1025372,15.2810215 C36.4431799,16.7814378 38,19.4523655 38,22.4971026 C38,27.1927057 34.2972739,30.9992442 29.7297297,30.9992442 C29.3085666,30.9992442 28.8947563,30.9668799 28.4905239,30.9044387 C26.7203391,32.8120083 24.2230741,34 21.4559059,34 C18.9550909,34 16.6747208,33.0297014 14.9515783,31.4353293 C13.9430214,31.8010508 12.8571414,31.9994961 11.7261761,31.9994961 C6.35455738,31.9994961 2,27.5228512 2,22.0006299 C2,17.7994291 4.52036404,14.203395 8.09149044,12.7233322 C9.09210886,8.29804795 12.9534151,5 17.564014,5 Z M17.564014,7 C14.0500806,7 10.9994912,9.45398701 10.1026201,12.9154811 L10.0422435,13.1644246 L9.81386072,14.1744587 L8.85723435,14.570935 C5.95341305,15.7744315 4,18.6944685 4,22.0006299 C4,26.4304688 7.47169247,29.9994961 11.7261761,29.9994961 C12.4959057,29.9994961 13.2475523,29.8835586 13.9646288,29.6583281 L14.2697827,29.5551286 L15.4154079,29.1397037 L16.3098759,29.9673281 C17.718493,31.2706793 19.5309168,32 21.4559059,32 C23.4840116,32 25.3853523,31.1889532 26.8138231,29.7625535 L27.0245039,29.5440028 L27.7466477,28.7658132 L28.7958402,28.9278806 C29.1025608,28.9752593 29.4143282,28.9992442 29.7297297,28.9992442 C33.1801388,28.9992442 36,26.1003233 36,22.4971026 C36,20.303612 34.9466102,18.3063868 33.2399136,17.1100991 L33.0232028,16.9647784 L32.0593849,16.3469446 L32.1040606,15.2029745 C32.106756,15.133957 32.1081081,15.0646959 32.1081081,14.995213 C32.1081081,12.2206282 29.9416692,9.9934494 27.2972973,9.9934494 C26.8137124,9.9934494 26.3417526,10.0670289 25.8912417,10.2098548 L25.6235876,10.3038252 L24.2963494,10.8158907 L23.3771963,9.73010533 C21.9155533,8.00348269 19.8185439,7 17.564014,7 Z" id="Combined-Shape"></path>
      </g>
    </g>
  </Icon>
)
Custom11.defaultProps = {
  size: 30
}

export default Custom11