import { FC } from 'react';
import Button from 'jbc-front/components/Button';

import { Section } from '@jbc-year-end-adj/2024/components/ui/Section';
import { Question } from '@jbc-year-end-adj/2024/features/questions/types';
import { TITLE, Content } from '@jbc-year-end-adj/2024/features/questions/templates/HasDependent';
import { ButtonContainer } from '@jbc-year-end-adj/2024/features/questions/components/ButtonContainer';

import { EditModal } from '../EditModal';
import { filteredDescritpions } from './utils';
import styles from './Questions.scss';

type HasDependentProps = {
  question: Question;
};

export const HasDependent: FC<HasDependentProps> = ({ question }) => {
  const descriptions = filteredDescritpions(question, ['under_note']);

  return (
    <Section>
      <Section.Header className={styles.header}>
        {TITLE}
        <EditModal questionId={String(question.id)} descriptions={descriptions} />
      </Section.Header>
      <Section.Body>
        <Content question={question}>
          <ButtonContainer>
            <Button huge disabled>
              はい
            </Button>
            <Button huge disabled>
              いいえ
            </Button>
          </ButtonContainer>
        </Content>
      </Section.Body>
    </Section>
  );
};
