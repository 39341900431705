import { FC } from 'react';
import { SalaryMoreNext as SalaryMoreNextTemplate } from 'features/questions/templates/SalaryMoreNext';
import { ButtonContainer } from 'features/questions/components/ButtonContainer';
import Button from 'jbc-front/components/Button';
import { useWizard } from '../WizardProvider';

export const SalaryMoreNext: FC = () => {
  const { question, request } = useWizard();

  return (
    <SalaryMoreNextTemplate question={question}>
      <ButtonContainer>
        <Button
          huge
          primary={request.profile?.salaryLessThanOthersNextYear}
          secondary={!request.profile?.salaryLessThanOthersNextYear}
          disabled
        >
          はい
        </Button>
        <Button
          huge
          primary={!request.profile?.salaryLessThanOthersNextYear}
          secondary={request.profile?.salaryLessThanOthersNextYear}
          disabled
        >
          いいえ
        </Button>
      </ButtonContainer>
    </SalaryMoreNextTemplate>
  );
};
