import React from 'react'
import Icon from 'react-icon-base'

const Custom20 = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="Custom20" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon/employees/Custom7-Copy-17" fill="#3498DB" fillRule="nonzero">
        <g id="Group" transform="translate(1.460435, 11.000000)">
          <path d="M18.5595794,0 C22.3953356,0 26.2310202,0.523945988 30.0666331,1.57183796 C30.9363471,1.80942695 31.5395651,2.59955118 31.5395651,3.50113358 L31.5395651,15.5014241 C31.5395651,16.4020315 30.9376148,17.1915515 30.069155,17.4300284 C26.2575842,18.4766761 22.4267449,19 18.5766374,19 C14.7227037,19 10.8686374,18.4756355 7.01443834,17.4269065 C6.14377174,17.189979 5.53956513,16.3993996 5.53956513,15.497072 L5.53956513,3.50347396 C5.53956513,2.60096927 6.14399302,1.81027804 7.01488851,1.57351706 C10.8735448,0.524505685 14.7217751,0 18.5595794,0 Z M18.5595794,2 C15.144792,2 11.7184783,2.43597079 8.2772089,3.30957041 L7.53956387,3.50346933 L7.53956387,15.497072 L8.27636958,15.690891 C11.7144526,16.5641355 15.1467078,17 18.5766374,17 C22.0019438,17 25.4122858,16.5653145 28.8113615,15.6946612 L29.5395639,15.5014192 L29.5395639,3.50113358 L28.8065614,3.30754443 C25.3863697,2.43533612 21.9718897,2 18.5595794,2 Z" id="Rectangle"></path>
          <path d="M18.0395651,3.29731956 L18.2156576,3.29890193 L18.598497,3.31156077 L19.0222545,3.33687803 L19.4869842,3.3748533 L19.9927405,3.42548613 L20.5395775,3.48877611 L21.1275496,3.56472281 L21.7567109,3.65332578 L22.4271158,3.75458462 L23.1388186,3.86849889 L24.2839247,4.06309828 L25.0991094,4.20864925 L26.3996927,4.45070169 L27.3186983,4.62788668 C27.475333,4.65847193 27.6337021,4.68958438 27.7938065,4.72122403 C28.0647097,4.77475959 28.240921,5.03776919 28.1873855,5.30867241 C28.1338499,5.57957563 27.8708403,5.75578693 27.5999371,5.70225137 L26.2242744,5.43529482 L25.3586889,5.27293877 L24.1377309,5.05282612 L23.0098399,4.86081789 L21.9751991,4.69691263 L21.3373345,4.60325451 L20.7410501,4.52208552 L20.1864001,4.45340525 L19.6734388,4.39721326 C19.5914223,4.38888859 19.5111451,4.38108425 19.4326083,4.37380023 L18.9822817,4.33633986 L18.5737794,4.31136671 L18.2071556,4.29888033 C18.1495445,4.29783981 18.0936807,4.29731956 18.0395651,4.29731956 C17.5871022,4.29731956 17.0475263,4.3338574 16.4213558,4.40697566 L15.8634435,4.47860793 L15.26319,4.56816309 C15.0560567,4.60100262 14.8418787,4.6368303 14.620668,4.67564711 L13.9359499,4.80106596 C13.8183179,4.823464 13.6989308,4.84660957 13.57779,4.8705028 L12.8299139,5.02283504 L11.6293449,5.28499099 L10.776568,5.4822087 L9.88195791,5.69739103 C9.61373898,5.76306613 9.34306439,5.59887192 9.27738929,5.33065299 C9.21171419,5.06243407 9.37590839,4.79175948 9.64412732,4.72608437 L10.5560649,4.50675744 L11.4259568,4.305675 L12.2538757,4.12284301 L13.0398939,3.95826743 L13.7840841,3.81195422 L14.4865189,3.68390933 C14.6001158,3.66409121 14.7119758,3.64503451 14.8221006,3.62673937 L15.4620382,3.52610814 C15.6684177,3.49561128 15.8678681,3.46816161 16.0604016,3.44376014 L16.6172635,3.37970132 C17.1533988,3.32478908 17.6273905,3.29731956 18.0395651,3.29731956 Z" id="Path-10"></path>
          <path d="M18.0395651,13.2973196 L18.2156576,13.2989019 L18.598497,13.3115608 L19.0222545,13.336878 L19.4869842,13.3748533 L19.9927405,13.4254861 L20.5395775,13.4887761 L21.1275496,13.5647228 L21.7567109,13.6533258 L22.4271158,13.7545846 L23.1388186,13.8684989 L24.2839247,14.0630983 L25.0991094,14.2086493 L26.3996927,14.4507017 L27.3186983,14.6278867 C27.475333,14.6584719 27.6337021,14.6895844 27.7938065,14.721224 C28.0647097,14.7747596 28.240921,15.0377692 28.1873855,15.3086724 C28.1338499,15.5795756 27.8708403,15.7557869 27.5999371,15.7022514 L26.2242744,15.4352948 L25.3586889,15.2729388 L24.1377309,15.0528261 L23.0098399,14.8608179 L21.9751991,14.6969126 L21.3373345,14.6032545 L20.7410501,14.5220855 L20.1864001,14.4534052 L19.6734388,14.3972133 C19.5914223,14.3888886 19.5111451,14.3810843 19.4326083,14.3738002 L18.9822817,14.3363399 L18.5737794,14.3113667 L18.2071556,14.2988803 C18.1495445,14.2978398 18.0936807,14.2973196 18.0395651,14.2973196 C17.5871022,14.2973196 17.0475263,14.3338574 16.4213558,14.4069757 L15.8634435,14.4786079 L15.26319,14.5681631 C15.0560567,14.6010026 14.8418787,14.6368303 14.620668,14.6756471 L13.9359499,14.801066 C13.8183179,14.823464 13.6989308,14.8466096 13.57779,14.8705028 L12.8299139,15.022835 L11.6293449,15.284991 L10.776568,15.4822087 L9.88195791,15.697391 C9.61373898,15.7630661 9.34306439,15.5988719 9.27738929,15.330653 C9.21171419,15.0624341 9.37590839,14.7917595 9.64412732,14.7260844 L10.5560649,14.5067574 L11.4259568,14.305675 L12.2538757,14.122843 L13.0398939,13.9582674 L13.7840841,13.8119542 L14.4865189,13.6839093 C14.6001158,13.6640912 14.7119758,13.6450345 14.8221006,13.6267394 L15.4620382,13.5261081 C15.6684177,13.4956113 15.8678681,13.4681616 16.0604016,13.4437601 L16.6172635,13.3797013 C17.1533988,13.3247891 17.6273905,13.2973196 18.0395651,13.2973196 Z" id="Path-10-Copy" transform="translate(18.729947, 14.504587) scale(1, -1) translate(-18.729947, -14.504587) "></path>
          <path d="M17.8273635,8.50073777 L18.5815103,8.50424349 L19.2181317,8.5169739 L20.775629,8.56789518 L22.7125078,8.65276323 L25.8859415,8.81872562 L27.7262142,8.92433712 C28.0018806,8.94054247 28.2122154,9.17715132 28.1960101,9.45281778 C28.1798047,9.72848424 27.9431959,9.93881903 27.6675294,9.92261368 L24.1706391,9.72727039 L22.3279547,9.63570415 L20.4653168,9.55681696 L19.2039109,9.51690413 L18.3853109,9.50187823 L17.8396916,9.5006752 L17.1439795,9.51151974 L16.3421962,9.53560001 L15.434427,9.57291798 L13.6861859,9.66453706 L11.644172,9.79294217 L9.79930985,9.92215835 C9.52389486,9.9421882 9.2843895,9.73515763 9.26435966,9.45974264 C9.24432982,9.18432765 9.45136039,8.94482229 9.72677538,8.92479245 L12.4359255,8.73896935 L13.6325501,8.66595913 L15.3932172,8.57376569 L16.591641,8.52655739 L17.3680268,8.50663968 L17.8273635,8.50073777 Z" id="Path-11"></path>
          <path d="M5.91770157,3.5079209 C6.46811282,3.46246873 6.95115556,3.87181894 6.99660773,4.42223019 C7.0420599,4.97264145 6.63270968,5.45568419 6.08229843,5.50113636 C2.55370503,5.79252253 1,7.08610402 1,9.5 C1,11.9145762 2.55464359,13.211286 6.08379239,13.5080454 C6.63413488,13.5543227 7.04276049,14.0379785 6.99648323,14.588321 C6.95020598,15.1386635 6.46655011,15.5472891 5.91620761,15.5010119 C1.44535641,15.1250665 -1,13.0854238 -1,9.5 C-1,5.91389598 1.44629497,3.87716322 5.91770157,3.5079209 Z" id="Path-12"></path>
          <path d="M36.9177016,3.5079209 C37.4681128,3.46246873 37.9511556,3.87181894 37.9966077,4.42223019 C38.0420599,4.97264145 37.6327097,5.45568419 37.0822984,5.50113636 C33.553705,5.79252253 32,7.08610402 32,9.5 C32,11.9145762 33.5546436,13.211286 37.0837924,13.5080454 C37.6341349,13.5543227 38.0427605,14.0379785 37.9964832,14.588321 C37.950206,15.1386635 37.4665501,15.5472891 36.9162076,15.5010119 C32.4453564,15.1250665 30,13.0854238 30,9.5 C30,5.91389598 32.446295,3.87716322 36.9177016,3.5079209 Z" id="Path-12-Copy" transform="translate(34.000028, 9.504529) scale(-1, 1) translate(-34.000028, -9.504529) "></path>
        </g>
      </g>
    </g>
  </Icon>
)
Custom20.defaultProps = {
  size: 30
}

export default Custom20