import React from 'react'
import Icon from 'react-icon-base'

const MinusSquare = props => (
  <Icon viewBox="0 0 20 20" {...props}>
    <g fill="#fff">
      <rect height="19" width="19" rx="2" stroke="#CCC" x=".5" y=".5"/>
      <path d="M14.911 10.746L14.9 9.254H5.111v1.48z" fill="currentColor"/>
    </g>
  </Icon>
)
MinusSquare.defaultProps = {
  size: 20
}

export default MinusSquare
