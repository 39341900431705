import React from 'react'
import Icon from 'react-icon-base'

const CalenderChanged = props => (
  <Icon viewBox="0 0 45 50" {...props}>
    <g fill="none" fillRule="evenodd">
      <g fill="#3498DB">
        <path d="M44.9 33.602c-.064.153-.155.292-.27.407l-5.313 5.312c-.244.244-.564.367-.884.367-.32 0-.64-.123-.883-.367-.49-.488-.49-1.28 0-1.767l3.178-3.179H29.263l3.179 3.179c.489.487.489 1.279 0 1.767-.244.244-.564.367-.884.367-.32 0-.64-.123-.884-.367l-5.31-5.312c-.117-.115-.208-.254-.272-.407-.126-.306-.126-.65 0-.956.064-.152.155-.291.271-.406l5.311-5.311c.49-.489 1.28-.489 1.768 0 .489.487.489 1.279 0 1.767l-3.179 3.179h11.465l-3.178-3.179c-.49-.489-.49-1.28 0-1.767.488-.489 1.28-.489 1.767 0l5.313 5.311c.114.115.206.254.27.406.126.306.126.65 0 .956zM41.33 12.5H3.663V7.766c0-.837.679-1.516 1.517-1.516h5.353v1.565c0 .861.698 1.56 1.56 1.56.861 0 1.559-.699 1.559-1.56V6.25H31.34v1.565c0 .861.697 1.56 1.558 1.56.863 0 1.56-.699 1.56-1.56V6.25h5.355c.838 0 1.517.679 1.517 1.516V12.5zm1.25-9.375h-8.122V1.56c0-.863-.697-1.56-1.56-1.56-.861 0-1.559.698-1.559 1.56v1.565H13.652V1.56c0-.863-.697-1.56-1.559-1.56s-1.56.698-1.56 1.56v1.565h-6.87c-1.716 0-3.12 1.406-3.12 3.125l.1 30.252C.643 38.227 2.14 40 3.86 40h18.324c.864 0 1.563-.7 1.563-1.563s-.699-1.562-1.563-1.562H5.223c-.862 0-1.56-.699-1.56-1.56v-19.69H41.33v9.095c0 .845.683 1.53 1.528 1.53.844 0 1.527-.683 1.53-1.525L44.45 6.25c0-1.719-1.404-3.125-3.12-3.125h1.25z" transform="translate(-765 -328) translate(310 233) translate(330 10) translate(125 85) translate(2.5 5)"/>
      </g>
    </g>
  </Icon>
)

CalenderChanged.defaultProps = {
  size: 20
}

export default CalenderChanged
