import { FC } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';

import Button from 'jbc-front/components/Button';
import { ButtonRow } from 'jbc-front/components/presenters/layout/ButtonRow';

import { FormSection } from 'components/form/FormSection';
import { FileField } from 'components/react-hook-form/FileField';
import { FormAddButton } from 'components/form/FormAddButton';
import { Section } from 'components/ui/Section';
import { DateField } from 'components/react-hook-form/DateField';
import { SelectField } from 'components/react-hook-form/SelectField';

import { Schema, schema, generateDefaultValues, housingLoanDetailObj, housingLoanObj } from './schema';
import { UPDATE_REQUEST_HOUSING_LOAN, convertFormDataToQueryVariables } from './mutation';
import { useResult } from '../../ResultProvider';
import { FETCH_EMPLOYEE } from '../../../../query';
import { DeletableSection } from '../../../../components/DeletableSection';
import { Grid } from '../../../../components/Grid';
import { FloatingButton } from '../../../../components/FloatingButton';
import { FormSeparator } from '../../../../components/FormSeparator';
import { FilePreview } from 'components/feature/FilePreview';

// @ts-ignore
import { useNotify } from 'actions';
// @ts-ignore
import { useMutation } from 'components/Graphql';
import { AmountField } from 'components/react-hook-form/AmountField';

type FormProps = {
  onCancel: () => void;
};

const deductionClassificationOptions = [
  { value: 'normal', label: '住 [一般の住宅借入金等特別控除（増改築等を含む）]' },
  {
    value: 'special_case_house_normal',
    label: '住(特家) [一般の住宅借入金等特別控除の場合（増改築等を含む。）で住宅が特例居住用家屋に該当するとき]'
  },
  { value: 'ceritified', label: '認 [認定住宅の新築等に係る住宅借入金等特別控除]' },
  {
    value: 'special_case_house_certified',
    label: '認（特家）[認定住宅等の新築等に係る住宅借入金等特別控除の場合で住宅が特例認定住宅等に該当するとき]'
  },
  { value: 'extension', label: '増 [特定増改築等住宅借入金等特別控除]' },
  {
    value: 'earthquake',
    label: '震 [震災特例法第13条の2第１項「住宅の再取得等に係る住宅借入金等特別控除」の規定の適用を選択した場合]'
  },
  {
    value: 'special_case_house_earthquake',
    label: '震（特家）[震災再取得等の適用を選択した場合で住宅が特例居住用家屋に該当するとき]'
  }
];

const specialDeductionOptiosn = [
  { value: 'none', label: '対象外' },
  { value: 'applied', label: '特定取得' },
  { value: 'special_applied', label: '特別特定取得' },
  { value: 'special_exception_applied', label: '特例特別特例取得' }
];

export const Form: FC<FormProps> = ({ onCancel }) => {
  const { employee } = useResult();
  const profile = employee.request?.profile;
  const [update, { loading }] = useMutation(UPDATE_REQUEST_HOUSING_LOAN);
  const notify = useNotify();

  const methods = useForm<Schema>({
    defaultValues: generateDefaultValues(profile?.housingLoan),
    resolver: yupResolver(schema),
    mode: 'onBlur'
  });

  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
    setValue,
    reset
  } = methods;

  const onSubmit: SubmitHandler<Schema> = async (data: Schema) => {
    const input = convertFormDataToQueryVariables(data, String(employee.id));
    const variables = input;

    await update({
      variables,
      refetchQueries: [FETCH_EMPLOYEE]
    });
    onCancel();
    notify('住宅ローン情報を保存しました。', 'success');
  };

  const handleDelete = () => {
    reset({ housingLoan: undefined });
  };

  const handleAddHousingLoan = () => {
    reset({ housingLoan: housingLoanObj });
  };

  const handleDeleteSecondDetail = () => {
    setValue('housingLoan.secondDetail', undefined);
  };

  const handleAddSecondDetail = () => {
    setValue('housingLoan.secondDetail', housingLoanDetailObj);
  };

  const housingLoan = watch('housingLoan');
  const image = watch('housingLoan.image');
  const certificateImage = watch('housingLoan.certificateImage');

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormSection>
          {housingLoan ? (
            <DeletableSection>
              <DeletableSection.Header onDelete={handleDelete}>住宅ローン</DeletableSection.Header>
              <DeletableSection.Body>
                <FormSection>
                  <Grid>
                    <AmountField
                      label="住宅借入金等特別控除額"
                      required
                      error={errors.housingLoan?.housingLoanDeductionApplicableAmount?.message}
                      isError={!!errors.housingLoan?.housingLoanDeductionApplicableAmount?.message}
                      {...register('housingLoan.housingLoanDeductionApplicableAmount')}
                    />
                  </Grid>

                  <Grid colGap col2>
                    <Section>
                      <Section.Header>1回目</Section.Header>
                      <Section.Body>
                        <FormSection>
                          <DateField
                            label="居住開始年月日（1回目）"
                            required
                            note="住宅借入金等特別控除申告書の㋑または㋠を転記してください。"
                            error={errors.housingLoan?.firstDetail?.residenceStartOn?.message}
                            name="housingLoan.firstDetail.residenceStartOn"
                          />

                          <SelectField
                            label="住宅借入金等特別控除区分（1回目）"
                            required
                            error={errors.housingLoan?.firstDetail?.deductionClassification?.message}
                            options={deductionClassificationOptions}
                            name="housingLoan.firstDetail.deductionClassification"
                          />

                          <SelectField
                            label="住宅借入金等特別控除区分（1回目）　特定"
                            required
                            note="住宅借入金等特別控除申告書の㋑または㋠に（特定）等の表記がある場合に選択してください。"
                            error={errors.housingLoan?.firstDetail?.specialDeduction?.message}
                            options={specialDeductionOptiosn}
                            name="housingLoan.firstDetail.specialDeduction"
                          />

                          <AmountField
                            label="住宅借入金等 年末残高（1回目）"
                            required
                            error={errors.housingLoan?.firstDetail?.yearEndBalance?.message}
                            isError={!!errors.housingLoan?.firstDetail?.yearEndBalance?.message}
                            {...register('housingLoan.firstDetail.yearEndBalance')}
                          />
                        </FormSection>
                      </Section.Body>
                    </Section>

                    {housingLoan.secondDetail ? (
                      <DeletableSection>
                        <DeletableSection.Header onDelete={handleDeleteSecondDetail}>2回目</DeletableSection.Header>
                        <DeletableSection.Body>
                          <FormSection>
                            <DateField
                              label="居住開始年月日（2回目）"
                              required
                              note="住宅借入金等特別控除申告書の㋠を転記してください。"
                              error={errors.housingLoan?.secondDetail?.residenceStartOn?.message}
                              name="housingLoan.secondDetail.residenceStartOn"
                            />

                            <SelectField
                              label="住宅借入金等特別控除区分（2回目）"
                              required
                              error={errors.housingLoan?.secondDetail?.deductionClassification?.message}
                              options={deductionClassificationOptions}
                              name="housingLoan.secondDetail.deductionClassification"
                            />

                            <SelectField
                              label="住宅借入金等特別控除区分（2回目）　特定"
                              required
                              note="住宅借入金等特別控除申告書の㋠に（特定）等の表記がある場合に選択してください。"
                              error={errors.housingLoan?.secondDetail?.specialDeduction?.message}
                              options={specialDeductionOptiosn}
                              name="housingLoan.secondDetail.specialDeduction"
                            />

                            <AmountField
                              label="住宅借入金等 年末残高（2回目）"
                              required
                              error={errors.housingLoan?.secondDetail?.yearEndBalance?.message}
                              isError={!!errors.housingLoan?.secondDetail?.yearEndBalance?.message}
                              {...register('housingLoan.secondDetail.yearEndBalance')}
                            />
                          </FormSection>
                        </DeletableSection.Body>
                      </DeletableSection>
                    ) : (
                      <FormAddButton onClick={handleAddSecondDetail}>2回目の住宅ローン情報追加</FormAddButton>
                    )}
                  </Grid>

                  <Grid>
                    <FileField
                      label="住宅借入金等特別控除申告書の画像"
                      note="対応可能形式：jpg, jpeg, gif, png, pdf"
                      name={`housingLoan.image`}
                    >
                      {image && (
                        <FileField.FileContainer>
                          <FileField.File file={image} onRemove={() => setValue(`housingLoan.image`, undefined)} />
                          <FilePreview file={image} />
                        </FileField.FileContainer>
                      )}
                    </FileField>
                    <FileField
                      label="住宅ローンの年末残高証明書の画像"
                      note="対応可能形式：jpg, jpeg, gif, png, pdf"
                      name={`housingLoan.certificateImage`}
                    >
                      {certificateImage && (
                        <FileField.FileContainer>
                          <FileField.File file={certificateImage} onRemove={() => setValue(`housingLoan.certificateImage`, undefined)} />
                          <FilePreview file={certificateImage} />
                        </FileField.FileContainer>
                      )}
                    </FileField>
                  </Grid>
                </FormSection>
              </DeletableSection.Body>
            </DeletableSection>
          ) : (
            <FormAddButton onClick={handleAddHousingLoan}>住宅ローン情報追加</FormAddButton>
          )}

          <FormSeparator />

          <FloatingButton>
            <ButtonRow>
              <Button onClick={onCancel} disabled={loading}>
                キャンセル
              </Button>
              <Button primary onClick={handleSubmit(onSubmit)} disabled={loading}>
                保存
              </Button>
            </ButtonRow>
          </FloatingButton>
        </FormSection>
      </form>
    </FormProvider>
  );
};
