import { FC, useMemo } from 'react';
import { Income as IncomeIcon } from 'jbc-front/components/icons';
import { Dl, Dt, Dd, FileDd, FileDl } from '../../../../components/DefinitionList';
import { PreviewSubSection } from '../../../../components/PreviewSubSection';
import { Grid } from '../../../../components/Grid';
import { MARITAL_STATUS, GENDER, RESIDENT_STATUS, HANDICAP_CLASSIFICATION } from '../../../../consts';
import { useEmployeeInfo } from '../../EmployeeInfoProvider';
import { fullNameFormat, dateFormat, displayFormat, amountFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { useYear } from 'hooks/useYear';
import { useRightExpansionContainer } from 'components/feature/RightExpansionContainer';
import { Income } from './Income';
import styles from './Preview.scss';
import { DependentYearlyInfo, Profile } from '../../../../query';

// @ts-ignore
import { salaryEarnings as calcSalaryEarnings, calcTotalSalaryIncome } from 'utils/income';

export const Preview: FC = () => {
  const year = useYear();
  const { employee } = useEmployeeInfo();
  const profile = employee.profile;

  const thisYearIncome = (profile: Profile) => {
    if (profile.spouse?.thisYear?.dependentTaxLaw) {
      return profile.spouse?.income;
    } else if (Number(profile.spouse?.income?.retirementIncome) > 0) {
      // 今年の税の扶養対象ではないが、退職手当を有する
      return profile.spouse?.income;
    }

    return undefined;
  };

  // 配偶者の今年の収入
  const income = thisYearIncome(profile);
  const { setContent } = useRightExpansionContainer();

  const key = useMemo(() => `spouseIncome-${crypto.randomUUID()}`, [income]);
  const handleClickIncome = () => {
    setContent(<Income income={income} year={year} />, key, 400);
  };

  const fullAddress = (yearlyInfo?: DependentYearlyInfo) => {
    if (!yearlyInfo) return undefined;

    return `${yearlyInfo.postcode0}-${yearlyInfo.postcode1} ${yearlyInfo.fullAddress}`;
  };

  const hasRetirementEarnings =
    profile.spouse?.income?.retirementIncome && Number(profile.spouse.income.retirementIncome.split(',').join('')) > 0;

  return (
    <div className={styles.preview}>
      <Grid>
        <Dl>
          <Dt>配偶者の有無</Dt>
          {profile.maritalStatus ? (
            <Dd>{MARITAL_STATUS[profile.maritalStatus]}</Dd>
          ) : (
            <Dd className={styles.alert}>この項目は入力必須です</Dd>
          )}
        </Dl>
      </Grid>

      {profile.maritalStatus !== 'unmarried' && (
        <>
          <PreviewSubSection>
            <PreviewSubSection.Header>配偶者の基本情報</PreviewSubSection.Header>
            <PreviewSubSection.Body>
              <Dl>
                <Dt>氏名</Dt>
                <Dd>{fullNameFormat(profile.spouse?.lastName, profile.spouse?.firstName)}</Dd>
              </Dl>

              <Dl>
                <Dt>氏名（カナ）</Dt>
                <Dd>{fullNameFormat(profile.spouse?.lastNameKana, profile.spouse?.firstNameKana)}</Dd>
              </Dl>

              <Dl>
                <Dt>生年月日</Dt>
                <Dd>{dateFormat(profile.spouse?.birthday, 'L')}</Dd>
              </Dl>

              <Dl>
                <Dt>性別</Dt>
                <Dd>{profile.spouse?.gender ? GENDER[profile.spouse?.gender] : displayFormat()}</Dd>
              </Dl>
            </PreviewSubSection.Body>
          </PreviewSubSection>

          <Grid colGap col2>
            <PreviewSubSection>
              <PreviewSubSection.Header>今年の扶養情報</PreviewSubSection.Header>
              <PreviewSubSection.Body>
                <Dl>
                  <Dt>今年の源泉控除対象、配偶者特別控除対象、または障害者控除対象</Dt>
                  <Dd>
                    {profile.spouse?.thisYear?.dependentTaxLaw === undefined
                      ? displayFormat()
                      : profile.spouse?.thisYear?.dependentTaxLaw
                      ? '対象'
                      : '対象外'}
                  </Dd>
                </Dl>

                {!profile.spouse?.thisYear?.dependentTaxLaw && (
                  <Dl>
                    <Dt>今年の税の扶養対象ではないが、退職手当等を有する配偶者の対象</Dt>
                    <Dd>{hasRetirementEarnings ? '対象' : '対象外'}</Dd>
                  </Dl>
                )}

                <Dl>
                  <Dt>同居・別居</Dt>
                  <Dd>
                    {profile.spouse?.thisYear?.residenceStatus
                      ? RESIDENT_STATUS[profile.spouse?.thisYear?.residenceStatus]
                      : displayFormat()}
                  </Dd>
                </Dl>

                {profile.spouse?.thisYear?.residenceStatus === 'different_address' && (
                  <Dl>
                    <Dt>住所</Dt>
                    <Dd>{fullAddress(profile.spouse?.thisYear)}</Dd>
                  </Dl>
                )}

                {profile.spouse?.thisYear?.residenceStatus === 'different_and_foreign_address' && (
                  <>
                    <Dl>
                      <Dt>Address</Dt>
                      <Dd>{profile.spouse?.thisYear?.addressForeign}</Dd>
                    </Dl>

                    <Dl>
                      <Dt>非居住者</Dt>
                      <Dd>{profile.spouse?.thisYear?.isNonResident ? 'はい' : 'いいえ'}</Dd>
                    </Dl>

                    <Dl>
                      <Dt>国外居住親族への送金額</Dt>
                      <Dd>{amountFormat(profile.spouse?.thisYear?.remittance)}</Dd>
                    </Dl>

                    <FileDl>
                      <Dt>親族関係書類</Dt>
                      <FileDd file={profile.spouse?.thisYear?.relatedToRelativesDocument} />
                    </FileDl>

                    <FileDl>
                      <Dt>送金関係書類</Dt>
                      <FileDd file={profile.spouse?.thisYear?.relatedToRemittanceDocument} />
                    </FileDl>
                  </>
                )}

                <Dl>
                  <Dt>障害者区分</Dt>
                  <Dd>
                    {profile.spouse?.thisYear?.handicapType
                      ? HANDICAP_CLASSIFICATION[profile.spouse?.thisYear?.handicapType]
                      : displayFormat()}
                  </Dd>
                </Dl>

                <Dl>
                  <Dt>障害者詳細</Dt>
                  <Dd>{profile.spouse?.thisYear?.handicapDetail}</Dd>
                </Dl>

                <FileDl>
                  <Dt>障害者手帳画像</Dt>
                  <FileDd file={profile.spouse?.thisYear?.handicapImage} />
                </FileDl>

                <Dl>
                  <Dt>
                    <div className={styles.incomeDetailHeader}>
                      合計所得金額
                      <i className={styles.incomeIcon} onClick={handleClickIncome}>
                        <IncomeIcon />
                      </i>
                    </div>
                  </Dt>
                  <Dd>{amountFormat(profile.spouse?.thisYear?.earnings)}</Dd>
                </Dl>
              </PreviewSubSection.Body>
            </PreviewSubSection>

            <PreviewSubSection>
              <PreviewSubSection.Header>来年の扶養情報</PreviewSubSection.Header>
              <PreviewSubSection.Body>
                <Dl>
                  <Dt>来年の源泉控除対象</Dt>
                  <Dd>
                    {profile.spouse?.nextYear?.dependentTaxLaw === undefined
                      ? displayFormat()
                      : profile.spouse?.nextYear?.dependentTaxLaw
                      ? '対象'
                      : '対象外'}
                  </Dd>
                </Dl>

                <Dl>
                  <Dt>同居・別居</Dt>
                  <Dd>
                    {profile.spouse?.nextYear?.residenceStatus
                      ? RESIDENT_STATUS[profile.spouse?.nextYear?.residenceStatus]
                      : displayFormat()}
                  </Dd>
                </Dl>

                {profile.spouse?.nextYear?.residenceStatus === 'different_address' && (
                  <Dl>
                    <Dt>住所</Dt>
                    <Dd>{fullAddress(profile.spouse?.nextYear)}</Dd>
                  </Dl>
                )}

                {profile.spouse?.nextYear?.residenceStatus === 'different_and_foreign_address' && (
                  <>
                    <Dl>
                      <Dt>Address</Dt>
                      <Dd>{profile.spouse?.nextYear?.addressForeign}</Dd>
                    </Dl>

                    <Dl>
                      <Dt>非居住者</Dt>
                      <Dd>{profile.spouse?.nextYear?.isNonResident ? 'はい' : 'いいえ'}</Dd>
                    </Dl>

                    <Dl>
                      <Dt>国外居住親族への送金額</Dt>
                      <Dd>{amountFormat(profile.spouse?.nextYear?.remittance)}</Dd>
                    </Dl>

                    <FileDl>
                      <Dt>親族関係書類</Dt>
                      <FileDd file={profile.spouse.nextYear.relatedToRelativesDocument} />
                    </FileDl>

                    <FileDl>
                      <Dt>送金関係書類</Dt>
                      <FileDd file={profile.spouse.nextYear.relatedToRemittanceDocument} />
                    </FileDl>
                  </>
                )}

                <Dl>
                  <Dt>障害者区分</Dt>
                  <Dd>
                    {profile.spouse?.nextYear?.handicapType
                      ? HANDICAP_CLASSIFICATION[profile.spouse?.nextYear?.handicapType]
                      : displayFormat()}
                  </Dd>
                </Dl>

                <Dl>
                  <Dt>障害者詳細</Dt>
                  <Dd>{profile.spouse?.nextYear?.handicapDetail}</Dd>
                </Dl>

                <FileDl>
                  <Dt>障害者手帳画像</Dt>
                  <FileDd file={profile.spouse?.nextYear?.handicapImage} />
                </FileDl>

                <Dl>
                  <Dt>給与収入等</Dt>
                  <Dd>{amountFormat(profile.spouse?.nextYear?.income)}</Dd>
                </Dl>

                <Dl>
                  <Dt>所得見積額</Dt>
                  <Dd>{amountFormat(profile.spouse?.nextYear?.earnings)}</Dd>
                </Dl>
              </PreviewSubSection.Body>
            </PreviewSubSection>
          </Grid>

          {(profile.spouse?.thisYear || profile.spouse?.nextYear) && (
            <Grid>
              <Dl>
                <Dt>源泉控除対象配偶者になった日</Dt>
                <Dd>{dateFormat(profile.spouse?.dependentFrom, 'L')}</Dd>
              </Dl>

              <Dl>
                <Dt>源泉控除対象配偶者になった理由</Dt>
                <Dd>{displayFormat(profile.spouse?.dependentReason)}</Dd>
              </Dl>

              <Dl>
                <Dt>死亡日</Dt>
                <Dd>{dateFormat(profile.spouse?.diedOn, 'L')}</Dd>
              </Dl>
            </Grid>
          )}
        </>
      )}
    </div>
  );
};
