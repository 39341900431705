import React, { useEffect } from 'react';
import Button from 'jbc-front/components/Button';
import { gql, useQuery, useApolloClient } from '@apollo/client';
import { useSession, Loading, useYear } from '../components';
import styles from './Report.scss';
import PreviewPdf from '../components/PreviewPdf';

const REPORT = gql`
  query clientCity($clientCityId: ID!, $year: Int!) {
    client {
      id
      clientYearly(year: $year) {
        id
        clientCity(id: $clientCityId) {
          id
          status
        }
      }
    }
  }
`;

const REPORT_QUERY = gql`
  query cityReport($clientCityId: ID!, $year: Int!) {
    client {
      id
      clientYearly(year: $year) {
        id
        clientCity(id: $clientCityId) {
          id
          reportPreview
          reportUrl
        }
      }
    }
  }
`;

export const PollingController = ({ reportStatus, stopPolling, startPolling }) => {
  useEffect(() => {
    if (reportStatus !== 'success' && reportStatus !== 'failed') {
      startPolling(3000);
      return () => {
        stopPolling();
      };
    }
  });
  return null;
};

const Report = ({ clientCityId, showMyNumber }) => {
  const year = useYear();
  const { data, loading, error, startPolling, stopPolling } = useQuery(REPORT, {
    variables: { clientCityId, year },
    fetchPolicy: 'network-only'
  });
  const {
    me: { hasMyNumberPermission }
  } = useSession();
  const client = useApolloClient();
  if (loading || error) return null;
  const {
    client: {
      clientYearly: {
        clientCity: { status: reportStatus }
      }
    }
  } = data;
  const title = '給与支払報告書';
  return (
    <PreviewPdf>
      {({ show, isOpen, setPdf }) => (
        <Button
          primary
          className={styles.loadingButton}
          disabled={reportStatus !== 'success'}
          disabledReason={
            reportStatus !== 'success' &&
            (reportStatus === 'failed' ? '給与支払報告書の作成に失敗しました。' : '給与支払報告書を作成中です')
          }
          onClick={
            reportStatus === 'success' &&
            (async () => {
              show({
                title,
                edit: null,
                disabledReason: !hasMyNumberPermission && showMyNumber ? 'マイナンバーが出力された帳票を閲覧する権限がありません' : null
              });
              const {
                data: {
                  client: {
                    clientYearly: {
                      clientCity: { reportPreview, reportUrl }
                    }
                  }
                }
              } = await client.query({
                query: REPORT_QUERY,
                fetchPolicy: 'no-cache',
                variables: { clientCityId, year }
              });
              setPdf(reportPreview, reportUrl, { downloadUrl: `${reportUrl}&disposition=attachment` });
            })
          }
        >
          <PollingController {...{ reportStatus, startPolling, stopPolling }} />
          {reportStatus !== 'success' && reportStatus !== 'failed' && <Loading className={styles.loading} />}
          給与支払報告書
        </Button>
      )}
    </PreviewPdf>
  );
};

export default Report;
