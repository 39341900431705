import { gql } from '@apollo/client';
import { ASYNC_TASK_FRAGMENT } from '@jbc-year-end-adj/2024/features/AsyncTask/query';

export const IMPORT_EMPLOYEES = gql`
  mutation importEmployees(
    $service: String!
    $filterBySelection: Boolean
    $needsOffice: Boolean
    $needsEmployee: Boolean
    $needsWithholdingSlip: Boolean
  ) {
    importEmployees(
      input: {
        service: $service
        filterBySelection: $filterBySelection
        needsOffice: $needsOffice
        needsEmployee: $needsEmployee
        needsWithholdingSlip: $needsWithholdingSlip
      }
    ) {
      clientYearly {
        id
        asyncTask {
          ...AsyncTaskFields
        }
      }
    }
  }
  ${ASYNC_TASK_FRAGMENT}
`;

export const IMPORT_FROM_PREV_YEAR = gql`
  mutation importFromPrevYear {
    importFromPrevYear(input: {}) {
      clientYearly {
        id
        asyncTask {
          ...AsyncTaskFields
        }
      }
    }
  }
  ${ASYNC_TASK_FRAGMENT}
`;
