import React from 'react'
import Icon from 'react-icon-base'

const Custom15 = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="Custom15" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon/employees/Custom7-Copy-5" fill="#3498DB" fillRule="nonzero">
        <g id="Group" transform="translate(3.000000, 5.000000)">
          <path d="M30,0 C31.1045695,0 32,0.8954305 32,2 L32,19 C32,20.1045695 31.1045695,21 30,21 L4,21 C2.8954305,21 2,20.1045695 2,19 L2,2 C2,0.8954305 2.8954305,0 4,0 L30,0 Z M30,2 L4,2 L4,19 L30,19 L30,2 Z" id="Rectangle"></path>
          <path d="M28,3 C28.5522847,3 29,3.44771525 29,4 L29,17 C29,17.5522847 28.5522847,18 28,18 L6,18 C5.44771525,18 5,17.5522847 5,17 L5,4 C5,3.44771525 5.44771525,3 6,3 L28,3 Z M27,5 L7,5 L7,16 L27,16 L27,5 Z" id="Rectangle"></path>
          <polygon id="Line" points="15.5877058 3.79835358 16.2016464 4.5877058 6.4122942 12.2016464 5.79835358 11.4122942"></polygon>
          <polygon id="Line-Copy" points="20.5842484 3.79793005 21.2020699 4.58424839 6.41575161 16.2020699 5.79793005 15.4157516"></polygon>
          <path d="M30.3308473,19 C31.2974196,19 32.1256814,19.6912477 32.2985871,20.6422291 L33.5713144,27.6422291 C33.7689058,28.7289818 33.0480981,29.7701484 31.9613454,29.9677398 C31.8432976,29.9892031 31.7235578,30 31.6035746,30 L2.39642543,30 C1.29185593,30 0.396425434,29.1045695 0.396425434,28 C0.396425434,27.8800168 0.407222366,27.760277 0.428685614,27.6422291 L1.70141289,20.6422291 C1.87431859,19.6912477 2.70258042,19 3.66915271,19 L30.3308473,19 Z M30.3308473,21 L3.66915271,21 L2.39642543,28 L31.6035746,28 L30.3308473,21 Z" id="Rectangle-Copy"></path>
          <path d="M29,22 L29.5507231,25 L4.47335681,25 L5,22 L29,22 Z M28.167,23 L5.839,23 L5.663,24 L28.35,24 L28.167,23 Z" id="Rectangle"></path>
          <polygon id="Rectangle-Copy-2" points="22.7364684 26 23 27 11 27 11.2520089 26"></polygon>
        </g>
      </g>
    </g>
  </Icon>
)
Custom15.defaultProps = {
  size: 30
}

export default Custom15