import React from 'react'
import Icon from 'react-icon-base'

const CheckCircle = props => (
  <Icon viewBox="0 0 18 18" {...props}>
    <g fill='none' fillRule='evenodd' stroke='currentColor' strokeWidth='1.5' transform='translate(1 1)'>
      <path d='M3.619 7.697l3.165 2.697L12.679 5' />
      <circle cx='8' cy='8' r='8' />
    </g>
  </Icon>
)

CheckCircle.defaultProps = {
  size: 18
}

export default CheckCircle
