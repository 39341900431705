import { FC } from 'react';
import { HasInsurances as HasInsurancesTemplate } from 'features/questions/templates/HasInsurances';
import { findQuestionGroup } from 'features/questions/utils';
import { ToggleButton } from 'components/form/ToggleButton';
import { useWizard } from '../WizardProvider';

type questionCode = 'life_insurances' | 'earthquake_insurances' | 'social_insurances' | 'premium' | 'housing_loan';

export const HasInsurances: FC = () => {
  const { question, questionGroups } = useWizard();

  const group = findQuestionGroup(questionGroups, 'check_insurance_and_loan');

  const getRequestQuestionStatus = (code: questionCode) => {
    const questions = group?.questions;
    return questions?.find(q => q.code === code)?.requestQuestion?.status;
  };

  const hasLifeInsurance = getRequestQuestionStatus('life_insurances') !== 'skipped';
  const hasEarthquakeInsurance = getRequestQuestionStatus('earthquake_insurances') !== 'skipped';
  const hasSocialInsurance = getRequestQuestionStatus('social_insurances') !== 'skipped';
  const hasPremium = getRequestQuestionStatus('premium') !== 'skipped';
  const hasHousingLoan = getRequestQuestionStatus('housing_loan') !== 'skipped';

  return (
    <HasInsurancesTemplate>
      <HasInsurancesTemplate.LifeInsurance question={question}>
        <ToggleButton.Container>
          <ToggleButton checked={hasLifeInsurance} disabled>
            はい
          </ToggleButton>
          <ToggleButton checked={!hasLifeInsurance} disabled>
            いいえ
          </ToggleButton>
        </ToggleButton.Container>
      </HasInsurancesTemplate.LifeInsurance>
      <HasInsurancesTemplate.EarthquakeInsurance question={question}>
        <ToggleButton.Container>
          <ToggleButton checked={hasEarthquakeInsurance} disabled>
            はい
          </ToggleButton>
          <ToggleButton checked={!hasEarthquakeInsurance} disabled>
            いいえ
          </ToggleButton>
        </ToggleButton.Container>
      </HasInsurancesTemplate.EarthquakeInsurance>
      <HasInsurancesTemplate.SocialInsurance question={question}>
        <ToggleButton.Container>
          <ToggleButton checked={hasSocialInsurance} disabled>
            はい
          </ToggleButton>
          <ToggleButton checked={!hasSocialInsurance} disabled>
            いいえ
          </ToggleButton>
        </ToggleButton.Container>
      </HasInsurancesTemplate.SocialInsurance>
      <HasInsurancesTemplate.Premium question={question}>
        <ToggleButton.Container>
          <ToggleButton checked={hasPremium} disabled>
            はい
          </ToggleButton>
          <ToggleButton checked={!hasPremium} disabled>
            いいえ
          </ToggleButton>
        </ToggleButton.Container>
      </HasInsurancesTemplate.Premium>
      <HasInsurancesTemplate.HousingLoan question={question}>
        <ToggleButton.Container>
          <ToggleButton checked={hasHousingLoan} disabled>
            はい
          </ToggleButton>
          <ToggleButton checked={!hasHousingLoan} disabled>
            いいえ
          </ToggleButton>
        </ToggleButton.Container>
      </HasInsurancesTemplate.HousingLoan>
    </HasInsurancesTemplate>
  );
};
