import { gql, useQuery } from '@apollo/client';

interface YearMaster {
  id: number;
  eraName: string;
  eraCode: string;
  eraNumber: number | string;
  year: number;
}

interface YearMastersData {
  yearMasters: YearMaster[];
}

const YEAR_MASTERS = gql`
  query yearMasters {
    yearMasters {
      id
      eraName
      eraCode
      eraNumber
      year
    }
  }
`;

interface Options {
  year?: number | null;
  toHeiseiEra?: boolean;
  toJpFirstYear?: boolean;
}

const DEFAULT_OPTIONS: Required<Options> = {
  year: null,
  toHeiseiEra: false,
  toJpFirstYear: false
} as const;

const makeFuncs = (options: Options) => ({
  pickIf: (masters: YearMaster[]): YearMaster[] => {
    if (!options.year) return masters;
    const currentYearIndex = masters.findIndex(master => master.year === options.year);
    if (currentYearIndex === -1) return [];
    return [masters[currentYearIndex], masters[currentYearIndex + 1]].filter((master): master is YearMaster => master !== undefined);
  },
  toHeiseiEraIf: (master: YearMaster): YearMaster =>
    options.toHeiseiEra && master.year === 2019 ? { ...master, eraName: '平成', eraCode: 'H', eraNumber: 31 } : master,
  toJpFirstYearIf: (master: YearMaster): YearMaster =>
    options.toJpFirstYear && master.eraNumber === 1 ? { ...master, eraNumber: '元' } : master
});

export const useYearMasters = (options: Options = {}): YearMaster[] => {
  const mergedOptions = { ...DEFAULT_OPTIONS, ...options } as const;
  const { loading, data } = useQuery<YearMastersData>(YEAR_MASTERS);
  const { pickIf, toHeiseiEraIf, toJpFirstYearIf } = makeFuncs(mergedOptions);

  if (loading || !data) return [];

  return pickIf(data.yearMasters).map(master => toJpFirstYearIf(toHeiseiEraIf(master)));
};
