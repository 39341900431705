import React from 'react'
import Icon from 'react-icon-base'

const Sick = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <path d="M14.792 25.208v9.372c0 .786.634 1.42 1.42 1.42h7.576c.786 0 1.42-.634 1.42-1.42v-9.372h9.372c.786 0 1.42-.634 1.42-1.42v-7.576c0-.786-.634-1.42-1.42-1.42h-9.372V5.42c0-.786-.634-1.42-1.42-1.42h-7.576c-.786 0-1.42.634-1.42 1.42v9.372H5.42c-.786 0-1.42.634-1.42 1.42v7.576c0 .786.634 1.42 1.42 1.42h9.372z" fill="none" stroke="currentColor" strokeWidth="2.5"/>
  </Icon>
)
Sick.defaultProps = {
  size: 40
}

export default Sick
