import React from 'react'
import Icon from 'react-icon-base'

const Email = props => (
  <Icon viewBox="0 0 15 10" {...props}>
    <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth="1.2">
      <path d="M13.25 11H1.75A.759.759 0 0 1 1 10.232V1.768C1 1.344 1.335 1 1.749 1H13.25c.414 0 .749.344.749.768v8.464a.759.759 0 0 1-.75.768z" />
      <path d="M13.44 5L7.446 8 1.559 5C1.204 4.877 1 4.769 1 4.483V1.516C1 1.231 1.25 1 1.56 1h11.88c.31 0 .56.231.56.516v2.967c0 .286-.091.26-.56.517z" />
    </g>
  </Icon>
)

Email.defaultProps = {
  size: 15
}

export default Email
