import React from 'react'
import Icon from 'react-icon-base'

const Custom17 = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="icon/employees/Custom17" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group" transform="translate(2.000000, 3.500000)" fill={props.color} fillRule="nonzero">
        <path d="M31.8666667,0 C33.0448741,0 34,0.980709596 34,2.19047619 L34,20.8095238 C34,22.0192904 33.0448741,23 31.8666667,23 L4.13333333,23 C2.95512587,23 2,22.0192904 2,20.8095238 L2,2.19047619 C2,0.980709596 2.95512587,0 4.13333333,0 L31.8666667,0 Z M32,2 L4,2 L4,21 L32,21 L32,2 Z" id="Rectangle"></path>
        <path d="M29,4 C29.5522847,4 30,4.44771525 30,5 L30,18 C30,18.5522847 29.5522847,19 29,19 L7,19 C6.44771525,19 6,18.5522847 6,18 L6,5 C6,4.44771525 6.44771525,4 7,4 L29,4 Z M28,6 L8,6 L8,17 L28,17 L28,6 Z" id="Rectangle"></path>
        <path d="M32.4516847,21 C33.4995386,21 34.3974511,21.7540884 34.5848969,22.7915227 L35.9646511,30.4278863 C36.1788584,31.6134347 35.3974362,32.7492528 34.2192957,32.9648071 C34.0913209,32.9882215 33.9615118,33 33.8314389,33 L2.16818517,33 C0.970729564,33 0,32.0231667 0,30.8181818 C0,30.6872911 0.0117048742,30.5566658 0.0349730217,30.4278863 L1.41472722,22.7915227 C1.60217301,21.7540884 2.50008551,21 3.54793936,21 L32.4516847,21 Z M32.6055718,23 L3.39442821,23 L2,31 L34,31 L32.6055718,23 Z" id="Rectangle-Copy"></path>
        <path d="M23.7364684,28 L24,29 L12,29 L12.2520089,28 L23.7364684,28 Z M30,24 L30.5507231,27 L5.47335681,27 L6,24 L30,24 Z M29.167,25 L6.839,25 L6.663,26 L29.35,26 L29.167,25 Z" id="Combined-Shape"></path>
      </g>
    </g>
  </Icon>
)
Custom17.defaultProps = {
  size: 30,
  color: "#3498DB"
}

export default Custom17