import { FC } from 'react';
import { PreviewSubSection } from '../../../../components/PreviewSubSection';
import { Grid } from '../../../../components/Grid';
import { Dl, Dt, Dd } from '../../../../components/DefinitionList';
import { Income as IncomeType } from '../../../../query';
import { Section } from 'components/ui/Section';
import { amountFormat } from '@jbc-year-end-adj/common/utils/formatter';
import styles from './Income.scss';

// @ts-ignore
import { salaryEarnings as calcSalaryEarnings, calcTotalSalaryIncome } from 'utils/income';

type IncomeProps = {
  income?: IncomeType;
  year?: number;
};

export const Income: FC<IncomeProps> = ({ income, year }) => {
  const salaryEarnings = calcSalaryEarnings(income?.salaryIncome, year);

  return (
    <div className={styles.container}>
      <Grid>
        <Dl>
          <Dt className={styles.fontBolder}>合計所得金額</Dt>
          <Dd className={styles.fontBolder}>{amountFormat(income?.totalEarnings)}</Dd>
        </Dl>
      </Grid>

      <Section>
        <Section.Header>配偶者の給与収入</Section.Header>
        <Section.Body>
          <Grid colGap>
            <PreviewSubSection>
              <PreviewSubSection.Header>給与所得</PreviewSubSection.Header>
              <PreviewSubSection.Body>
                <Dl>
                  <Dt>収入金額</Dt>
                  <Dd>{amountFormat(income?.salaryIncome)}</Dd>
                </Dl>
                <Dl>
                  <Dt className={styles.fontBolder}>所得金額</Dt>
                  <Dd className={styles.fontBolder}>{amountFormat(salaryEarnings)}</Dd>
                </Dl>
              </PreviewSubSection.Body>
            </PreviewSubSection>
          </Grid>
        </Section.Body>
      </Section>

      <Section>
        <Section.Header>配偶者の給与収入以外の収入</Section.Header>
        <Section.Body>
          <Grid colGap>
            <PreviewSubSection>
              <PreviewSubSection.Header>事業所得</PreviewSubSection.Header>
              <PreviewSubSection.Body>
                <Dl>
                  <Dt>収入金額</Dt>
                  <Dd>{amountFormat(income?.businessIncome)}</Dd>
                </Dl>
                <Dl>
                  <Dt>必要経費</Dt>
                  <Dd>{amountFormat(income?.businessExpense)}</Dd>
                </Dl>
                <Dl>
                  <Dt className={styles.fontBolder}>所得金額</Dt>
                  <Dd className={styles.fontBolder}>{amountFormat(income?.businessEarnings)}</Dd>
                </Dl>
              </PreviewSubSection.Body>
            </PreviewSubSection>

            <PreviewSubSection>
              <PreviewSubSection.Header>雑所得</PreviewSubSection.Header>
              <PreviewSubSection.Body>
                <Dl>
                  <Dt>収入金額（公的年金等）</Dt>
                  <Dd>{amountFormat(income?.miscellaneousIncomeOfficalPension)}</Dd>
                </Dl>
                <Dl>
                  <Dt>収入金額（公的年金等以外）</Dt>
                  <Dd>{amountFormat(income?.miscellaneousIncomeOther)}</Dd>
                </Dl>
                <Dl>
                  <Dt>必要経費</Dt>
                  <Dd>{amountFormat(income?.miscellaneousExpense)}</Dd>
                </Dl>
                <Dl>
                  <Dt className={styles.fontBolder}>所得金額</Dt>
                  <Dd className={styles.fontBolder}>{amountFormat(income?.miscellaneousEarnings)}</Dd>
                </Dl>
              </PreviewSubSection.Body>
            </PreviewSubSection>

            <PreviewSubSection>
              <PreviewSubSection.Header>配当所得</PreviewSubSection.Header>
              <PreviewSubSection.Body>
                <Dl>
                  <Dt>収入金額</Dt>
                  <Dd>{amountFormat(income?.dividendIncome)}</Dd>
                </Dl>
                <Dl>
                  <Dt>必要経費</Dt>
                  <Dd>{amountFormat(income?.dividendExpense)}</Dd>
                </Dl>
                <Dl>
                  <Dt className={styles.fontBolder}>所得金額</Dt>
                  <Dd className={styles.fontBolder}>{amountFormat(income?.dividendEarnings)}</Dd>
                </Dl>
              </PreviewSubSection.Body>
            </PreviewSubSection>

            <PreviewSubSection>
              <PreviewSubSection.Header>不動産所得</PreviewSubSection.Header>
              <PreviewSubSection.Body>
                <Dl>
                  <Dt>収入金額</Dt>
                  <Dd>{amountFormat(income?.realEstateIncome)}</Dd>
                </Dl>
                <Dl>
                  <Dt>必要経費</Dt>
                  <Dd>{amountFormat(income?.realEstateExpense)}</Dd>
                </Dl>
                <Dl>
                  <Dt className={styles.fontBolder}>所得金額</Dt>
                  <Dd className={styles.fontBolder}>{amountFormat(income?.realEstateEarnings)}</Dd>
                </Dl>
              </PreviewSubSection.Body>
            </PreviewSubSection>

            <PreviewSubSection>
              <PreviewSubSection.Header>退職所得</PreviewSubSection.Header>
              <PreviewSubSection.Body>
                <Dl>
                  <Dt>収入金額</Dt>
                  <Dd>{amountFormat(income?.retirementIncome)}</Dd>
                </Dl>
                <Dl>
                  <Dt>勤続年数</Dt>
                  <Dd>{income?.lengthOfService}</Dd>
                </Dl>
                <Dl>
                  <Dt>障害者になったことに直接基因して退職した</Dt>
                  <Dd>{income?.retireForDisablity ? 'はい' : 'いいえ'}</Dd>
                </Dl>
                <Dl>
                  <Dt>退職手当等が特定役員退職手当等に該当する</Dt>
                  <Dd>{income?.isOfficerRetirementAllowance ? 'はい' : 'いいえ'}</Dd>
                </Dl>
                <Dl>
                  <Dt>控除額</Dt>
                  <Dd>{amountFormat(income?.retirementIncomeDeductionAmount)}</Dd>
                </Dl>
                <Dl>
                  <Dt className={styles.fontBolder}>所得金額</Dt>
                  <Dd className={styles.fontBolder}>{amountFormat(income?.retirementEarnings)}</Dd>
                </Dl>
              </PreviewSubSection.Body>
            </PreviewSubSection>

            <PreviewSubSection>
              <PreviewSubSection.Header>その他所得</PreviewSubSection.Header>
              <PreviewSubSection.Body>
                <Dl>
                  <Dt>収入金額</Dt>
                  <Dd>{amountFormat(income?.otherIncome)}</Dd>
                </Dl>
                <Dl>
                  <Dt>必要経費</Dt>
                  <Dd>{amountFormat(income?.otherExpense)}</Dd>
                </Dl>
                <Dl>
                  <Dt>必要経費 うち特別控除額</Dt>
                  <Dd>{amountFormat(income?.otherExpenseSpecialDeduction)}</Dd>
                </Dl>
                <Dl>
                  <Dt className={styles.fontBolder}>所得金額</Dt>
                  <Dd className={styles.fontBolder}>{amountFormat(income?.otherEarings)}</Dd>
                </Dl>
              </PreviewSubSection.Body>
            </PreviewSubSection>
          </Grid>
        </Section.Body>
      </Section>
    </div>
  );
};
