import React from 'react'
import Icon from 'react-icon-base'

const BulkPrint = props => (
  <Icon viewBox="0 0 45 46" {...props}>
    <defs>
      <rect id="a" width="23" height="28" x="22" rx="3" />
      <rect id="b" width="23" height="28" x="17" y="5" rx="3" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <rect height="28" width="23" rx="3" stroke="#FFF" x="22"/>
        <rect height="27" width="22" rx="3" fill="currentColor" x="22.5" y=".5"/>
      </g>
      <g>
        <rect height="28" width="23" rx="3" stroke="#FFF" x="17" y="5"/>
        <rect height="27" width="22" rx="3" fill="currentColor" x="17.5" y="5.5"/>
      </g>
      <g fill="#FFF">
        <path d="M7 18h17v17H7z" />
        <path d="M15 24h13v13H15z" />
      </g>
      <path fill="currentColor" d="M27.544 36.482H25V30.97H5v5.512H2.456V24.52h25.088v11.962zm-5 7.062H7.456V33.426h15.088v10.118zM7.456 22.064h15.088v-3.608H7.456v3.608zm20.79 0H25V17.91c0-1.052-.86-1.91-1.91-1.91H6.91C5.86 16 5 16.858 5 17.91v4.154H1.754C.79 22.064 0 22.852 0 23.816v13.368c0 .964.79 1.754 1.754 1.754H5v5.152C5 45.14 5.86 46 6.91 46h16.18c1.05 0 1.91-.86 1.91-1.91v-5.152h3.246A1.76 1.76 0 0 0 30 37.184V23.816c0-.964-.79-1.752-1.754-1.752z"
      />
    </g>
  </Icon>
)
BulkPrint.defaultProps = {
  size: 16
}

export default BulkPrint
