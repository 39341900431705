import { yup } from '@jbc-year-end-adj/common/yup';
import { QuestionDescription } from '@jbc-year-end-adj/2024/features/questions/types';

export type Schema = {
  descriptions: Description[];
};

type Description = {
  field: string;
  description?: string;
};

export const schema = yup.object({
  descirptions: yup.array().of(
    yup.object({
      field: yup.string().required(),
      description: yup.string().nullable()
    })
  )
});

export const buildDefaultValues = (descriptions: QuestionDescription[]) => {
  return {
    descriptions: descriptions.map(description => ({
      field: description.field,
      description: description.description || description.defaultDescription || ''
    }))
  };
};
