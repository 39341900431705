import { FC, useContext, useEffect, ReactNode, createContext } from 'react';
import { AsyncTaskLoading } from './AsyncTaskLoading';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { useMutation } from '@apollo/client';
import useReactRouter from 'use-react-router';
import { year } from '@jbc-year-end-adj/2024/consts';
import { FETCH_ASYNC_TASK, QueryResult, AsyncTask as AsyncTaskType } from './query';
import { CONFIRM } from './mutation';
import { convertMessage, FailedMessage } from './AsyncTaskMessage';

type AsyncTaskContextType = {
  asyncTask: AsyncTaskType | undefined;
  refetch?: () => void;
  taskRunningProps: TaskRunnningProps;
  confirm?: () => void;
};

export type TaskRunnningProps = {
  disabled: boolean;
  disabledReason: string | JSX.Element | null;
};

const defaultTaskRunningProps = {
  disabled: false,
  disabledReason: null
};

const AsyncTaskContext = createContext<AsyncTaskContextType>({
  asyncTask: undefined,
  refetch: undefined,
  taskRunningProps: defaultTaskRunningProps,
  confirm: undefined
});

export const useAsyncTask = () => useContext(AsyncTaskContext);

type AsyncTaskProps = {
  children: ReactNode;
};

export const AsyncTaskProvider: FC<AsyncTaskProps> = ({ children }) => {
  const { location } = useReactRouter();

  const { data, loading, error, startPolling, stopPolling, refetch } = useQuery<QueryResult>(FETCH_ASYNC_TASK, {
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only',
    variables: { year }
  });

  const [confirm] = useMutation(CONFIRM);
  const asyncTask = data?.client?.clientYearly?.asyncTask;

  const progressText = (() => {
    switch (asyncTask?.status) {
      case 'waiting':
        return '0%';
      case 'in_progress':
        return `${percentage(asyncTask.excutedAt)}%`;
      case 'success':
        return '完了';
      default:
        return 'エラー';
    }
  })();

  const confirmAndRefetch = async () => {
    await confirm({ variables: { id: asyncTask?.id } });
    refetch();
  };

  const value = {
    asyncTask,
    refetch,
    taskRunningProps: {
      disabled: ['waiting', 'in_progress'].includes(String(asyncTask?.status)),
      disabledReason: convertMessage(asyncTask?.taskType, asyncTask?.status)
    },
    confirm: confirmAndRefetch
  };

  // polling async task
  useEffect(() => {
    if (!loading && asyncTask && ['waiting', 'in_progress'].includes(asyncTask.status)) {
      startPolling(3000);
      return () => stopPolling();
    }

    return;
  }, [loading, error, asyncTask, startPolling, stopPolling]);

  // 画面遷移時にrefetch async task
  useEffect(() => {
    refetch();
  }, [location.pathname, refetch]);

  return (
    <AsyncTaskContext.Provider value={value}>
      {children}
      {!loading && asyncTask && (
        <AsyncTaskLoading.Container error={['failed', 'partial_success'].includes(asyncTask.status)} done={asyncTask.status === 'success'}>
          <AsyncTaskLoading.Loading>{progressText}</AsyncTaskLoading.Loading>
          <AsyncTaskLoading.CloseButton onClose={confirmAndRefetch}>
            {asyncTask.status === 'failed' ? (
              <FailedMessage year={year} taskType={asyncTask.taskType} />
            ) : (
              convertMessage(asyncTask.taskType, asyncTask.status)
            )}
          </AsyncTaskLoading.CloseButton>
        </AsyncTaskLoading.Container>
      )}
    </AsyncTaskContext.Provider>
  );
};

const percentage = (excutedAt?: string) => {
  Math.min(Math.max(Math.floor(100 * (1 - Math.exp(-moment().diff(excutedAt, 'second') / 7))), 0), 99);
};
