import React from 'react'
import Icon from 'react-icon-base'

const Name = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="symbol" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <g id="icon_name" stroke="currentColor" strokeWidth="2.5">
        <path d="M16.1705357,36.902189 L33.7278106,19.3473904 C33.982862,19.092339 34.1933413,18.5880141 34.1933413,18.2281358 L34.1933413,6.5593287 C34.1933413,6.19862497 33.8928924,5.90395395 33.5330141,5.90395395 L21.8666832,5.90395395 C21.5051541,5.90395395 21.0008292,6.11278243 20.7466032,6.36783381 L3.19097913,23.9218071 C2.93592775,24.1776839 2.93675316,24.5953408 3.19097913,24.8487414 L11.6200559,33.2786435 L16.1705357,36.902189 Z" id="Page-1"></path>
        <path d="M25.3845764,13.2624752 C25.7840743,12.8629773 26.431195,12.8629773 26.830693,13.2624752 C27.2301909,13.6619732 27.2301909,14.3090938 26.830693,14.7077664 C26.431195,15.1080897 25.7840743,15.1080897 25.3845764,14.7077664 C24.9850784,14.3090938 24.9850784,13.6619732 25.3845764,13.2624752 Z" id="Path" fill="currentColor"></path>
      </g>
    </g>
  </Icon>
)
Name.defaultProps = {
  size: 40
}

export default Name
