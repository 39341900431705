import React from 'react'
import Icon from 'react-icon-base'

const Excluded = props => (
  <Icon viewBox="0 0 15 15" {...props}>
    <defs>
      <path id='a' d='M14.999 15H0V0h14.999z' />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <mask id='b' fill='currentColor'>
        <use xlinkHref='#a' />
      </mask>
      <path fill='currentColor' d='M3.123 12.021L7.5 8.348l4.377 3.673a6.273 6.273 0 0 1-8.754 0zm-.84-8.05l4.206 3.53-4.205 3.528A6.263 6.263 0 0 1 1.2 7.5c0-1.307.4-2.52 1.084-3.529zm9.594-.992L7.5 6.651 3.123 2.98a6.274 6.274 0 0 1 8.754 0zM8.512 7.5l4.205-3.528A6.267 6.267 0 0 1 13.8 7.5c0 1.306-.4 2.521-1.083 3.53L8.512 7.5zM7.5 0C3.365 0 0 3.364 0 7.5 0 11.635 3.365 15 7.5 15s7.499-3.365 7.499-7.5c0-4.136-3.364-7.5-7.5-7.5z' />
    </g>
  </Icon>
)

Excluded.defaultProps = {
  size: 15
}

export default Excluded
