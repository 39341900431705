import { FC } from 'react';
import { FormSection } from 'components/form/FormSection';
import { Row } from 'components/form/Row';
import { Input } from 'components/form/Input';
import { Radio } from 'components/form/Radio';
import { DateField } from 'components/form/DateField';
import { Option } from 'components/form/SelectField';
import { CreatableSelectField } from 'components/form/SelectField';
import { ThisYear } from './ThisYear';
import { Income } from './Income';
import { NextYear } from './NextYear';
import { OtherFamily as OtherFamilyType } from '../../../../query';
import { dateFormFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { useYear } from 'hooks/useYear';
import { Section } from 'components/ui/Section';

export const RELATION_OTHERS = [
  '実父',
  '実母',
  '義父',
  '義母',
  '長男',
  '長女',
  '次男',
  '次女',
  '三男',
  '三女',
  '兄',
  '姉',
  '弟',
  '妹',
  '祖父',
  '祖母',
  '曽祖父母',
  '孫息子',
  '孫娘',
  '叔父',
  '叔母',
  '甥',
  '姪'
];

type OtherFamilyProps = {
  otherFamily: OtherFamilyType;
  index: number;
};

export const OtherFamily: FC<OtherFamilyProps> = ({ otherFamily, index }) => {
  const year = useYear();
  const thisYear = otherFamily.thisYear;
  const nextYear = otherFamily.nextYear;

  const relationshipOptions: Option[] = RELATION_OTHERS.map(val => ({ value: val, label: val }));
  const relationOther = otherFamily.relationOther;
  if (relationOther) {
    relationshipOptions.push({ label: relationOther, value: relationOther });
  }

  return (
    <Section>
      <Section.Header>扶養親族（{index + 1}）</Section.Header>
      <Section.Body>
        <FormSection>
          <Row>
            <Input label="姓" required value={otherFamily.lastName} disabled />
            <Input label="名" required value={otherFamily.firstName} disabled />
          </Row>

          <Row>
            <Input label="姓（カナ）" required value={otherFamily.lastNameKana} disabled />
            <Input label="名（カナ）" required value={otherFamily.firstNameKana} disabled />
          </Row>

          <Radio.Container label="性別" required>
            <Radio checked={otherFamily.gender === 'male'} disabled>
              男性
            </Radio>
            <Radio checked={otherFamily.gender === 'female'} disabled>
              女性
            </Radio>
          </Radio.Container>

          <DateField label="生年月日" required note="例）1981/01/23、S56.1.23" value={dateFormFormat(otherFamily.birthday, 'L')} disabled />

          <CreatableSelectField
            label="続柄詳細"
            required
            note="リストにない続柄は直接入力して追加できます"
            options={relationshipOptions}
            value={relationshipOptions.find(option => option.value === otherFamily.relationOther)}
            disabled
          />

          <ThisYear thisYear={thisYear} />
          {(thisYear?.dependentTaxLaw || thisYear?.incomeAdjustmentDeduction) && <Income otherFamily={otherFamily} />}
          <NextYear nextYear={nextYear} />

          {(thisYear?.dependentTaxLaw || nextYear?.dependentTaxLaw) && (
            <>
              <DateField label="税法上の扶養家族になった日" value={dateFormFormat(otherFamily.dependentFrom, 'L')} disabled />
              <Input label="税法上の扶養に追加された理由" value={otherFamily.dependentReason} disabled />
              <DateField
                label="死亡日"
                note={`扶養親族が今年（${year}年）亡くなられた場合、死亡日をご記入ください。`}
                value={dateFormFormat(otherFamily.diedOn, 'L')}
                disabled
              />
            </>
          )}
        </FormSection>
      </Section.Body>
    </Section>
  );
};
