import { FC, ReactNode } from 'react';
import { Section } from '@jbc-year-end-adj/2024/components/ui/Section';

import { Question } from '../types';
import { findDescriptions } from '../utils';

import { ToggleImageContainer } from './parts/ToggleImageContainer';
import { Lead } from './parts/Lead';
import { Description } from './parts/Description';
import { Content as ContentContainer } from './parts/Content';

import styles from './templates.scss';

// @ts-ignore
import image from '@jbc-year-end-adj/2024/images/img-Premium_Deduction_Life_Insurance.png';

type LifeInsurancesProps = {
  question: Question;
  children: ReactNode;
  initiallyOpen?: boolean;
};

export const LifeInsurances: FC<LifeInsurancesProps> = ({ question, children, initiallyOpen = false }) => {
  return (
    <Section>
      <Section.Header className={styles.sectionHeader}>{TITLE}</Section.Header>

      <Section.Body>
        <Content question={question} initiallyOpen={initiallyOpen}>
          {children}
        </Content>
      </Section.Body>
    </Section>
  );
};

export const TITLE = '生命保険';

type ContentProps = {
  question: Question;
  children: ReactNode;
  initiallyOpen?: boolean;
};

export const Content: FC<ContentProps> = ({ question, children, initiallyOpen = false }) => {
  const questionDescriptions = findDescriptions(question, ['top']);

  return (
    <ContentContainer>
      <Lead>今年支払った生命保険料情報を記入して下さい</Lead>

      <Description>{questionDescriptions['top']?.description}</Description>

      <ToggleImageContainer initiallyOpen={initiallyOpen}>
        <ToggleImageContainer.Header>※赤枠に関する設問です。</ToggleImageContainer.Header>
        <ToggleImageContainer.Body>
          <img src={image} alt="保険料控除_生命保険" />
        </ToggleImageContainer.Body>
      </ToggleImageContainer>

      {children}
    </ContentContainer>
  );
};
