import React from 'react';
import { PlusSquare } from 'jbc-front/components/icons';
import styles from './FormAdd.scss';
import { useDiff } from '../components/FieldWithDiff';

const FormAdd = ({ onClick, name }) => {
  const { fixed } = useDiff();
  return (
    <div className="u-ta-r u-mt20 u-mb20">
      <div
        role={fixed ? undefined : 'button'}
        tabIndex={fixed ? undefined : 0}
        onKeyPress={fixed ? undefined : onClick}
        onClick={fixed ? undefined : onClick}
        className={fixed ? styles.addFixed : styles.add}
      >
        <PlusSquare size={20} className="u-txt-addinfo-icon" />
        {name}
        を追加
      </div>
    </div>
  );
};

export default FormAdd;
