import { createContext, useContext, FC, ReactNode } from 'react';
import { Employee } from '../../query';

type ResultContextValue = {
  employee: Employee;
};

const ResultContext = createContext<ResultContextValue | null>(null);

type ResultProviderType = {
  employee: Employee;
  children: ReactNode;
};

export const ResultProvider: FC<ResultProviderType> = ({ employee, children }) => {
  return <ResultContext.Provider value={{ employee }}>{children}</ResultContext.Provider>;
};

export function useResult(): ResultContextValue {
  const value = useContext(ResultContext);

  if (value === null) throw new Error();

  return value;
}
