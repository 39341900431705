import { gql } from '@apollo/client';

export type QueryVariables = {
  input: {
    employeeId: string;
  };
};

export const MOVE_NOT_REQUESTED = gql`
  mutation MoveNotRequested($input: MoveNotRequestedInput!) {
    moveNotRequested(input: $input) {
      employee {
        id
      }
    }
  }
`;
