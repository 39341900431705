import { FC, ReactNode } from 'react';
import styles from './Tag.scss';

type TagProps = {
  children: ReactNode;
};

export const Tag: FC<TagProps> = ({ children }) => {
  return <div className={styles.tag}>{children}</div>;
};
