import React from 'react';

export class ErrorBoundary extends React.Component {
  state = {
    error: null,
    info: null
  };

  componentDidCatch(error, info) {
    this.setState({ error, info });
    window.error = error;
  }

  render() {
    const { error } = this.state;
    const { isEmployee } = this.props;
    if (error) {
      return (
        <div>
          <div className="l-title-wrap" />
          <div className="l-wrap-xs">
            <div className="l-box-message">
              <p className="m-title-sub u-pt0 u-ta-c">エラーが発生しました</p>
              {(error.networkError && error.networkError.message.match(/401/) && (
                <div>
                  認証が切れました。
                  <br />
                  {isEmployee ? 'メールのリンクから' : 'ジョブカン労務HRまたはジョブカン給与計算'}
                  からもう一度年末調整に入ってください。
                </div>
              )) ||
                (error.networkError && error.networkError.message.match(/404/) && (
                  <div>
                    ページを表示できません
                    <br />
                    アクセスしようとしたページはアクセス権限がないか、削除・変更された可能性があります。
                    <br />
                    <a href="/" className="u-txt-link">
                      TOPへ
                    </a>
                  </div>
                )) || (
                  <>
                    <pre>
                      {this.state.error.message}
                      {process.env.NODE_ENV !== 'production' && this.state.info.componentStack}
                    </pre>
                    <div className="u-ta-c u-mt20">
                      <a
                        role="link"
                        tabIndex="0"
                        onClick={() => {
                          window.location.reload(true);
                        }}
                        onKeyPress={() => {
                          window.location.reload(true);
                        }}
                        className="u-txt-link"
                      >
                        ページリロード
                      </a>
                    </div>
                  </>
                )}
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
