import React from 'react';
import 'jbc-front/styles/react-select.scss';
import styles from './SearchForm.scss';
import classNames from 'classnames';
import _ from 'lodash';

const filteringTagClassNames = (color, active, selected) => {
  if (color) {
    return classNames(color, { [active]: selected });
  } else {
    return classNames(selected ? styles.filteringTagSelected : styles.filteringTagDefault);
  }
};

const FilteringTag = ({ children, selected, onClick, button, color, active }) => (
  <div className={styles.filteringTag}>
    <div aria-hidden="true" role={button} className={filteringTagClassNames(color, active, selected)} onClick={onClick}>
      {children}
    </div>
  </div>
);

export const SearchResultUpdate = ({ total }) => {
  return <p className={styles.SearchResultUpdate}>{total} 件</p>;
};

export const SearchEmploymentStatusField = ({ input: inputProps, employmentStatuses, onEmploymentStatusChange, countByStatus }) => {
  const totalCount = item => {
    switch (item) {
      case 'all':
        return countByStatus?.totalCount || 0;
      default:
        return countByStatus?.[_.camelCase(item)] || 0;
    }
  };

  return (
    <div className={styles.searchEmploymentStatusField}>
      {employmentStatuses.map(item => (
        <div key={item.id} className={styles.filteringTagBox}>
          <FilteringTag
            selected={item.id === inputProps.value}
            color={item.color}
            active={item.active}
            onClick={() => {
              const newValue = item.id;
              if (inputProps.value !== newValue) {
                inputProps.onChange(newValue);
                inputProps.onBlur(newValue);
                onEmploymentStatusChange();
              }
            }}
          >
            {item.name}
            <SearchResultUpdate total={totalCount(item.id)} />
          </FilteringTag>
        </div>
      ))}
    </div>
  );
};
