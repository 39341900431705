import React, { useState, useEffect } from 'react';
import styles from './ScrollToTop.scss';
import { Pulldown } from 'jbc-front/components/icons';

const ScrollToTop = ({ button }) => {
  const [showTopBtn, setShowTopBtn] = useState(false);
  useEffect(() => {
    function scroolEventFunct() {
      if (window.scrollY > 600) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    }
    window.addEventListener('scroll', scroolEventFunct);
    return () => window.removeEventListener('scroll', scroolEventFunct);
  }, []);
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  return (
    <div className={styles.goToTopBtn} onClick={goToTop} aria-hidden="true" role={button}>
      {showTopBtn && (
        <>
          <Pulldown className={styles.goToTopIcon} size={20} />
          <span>トップへ</span>
        </>
      )}
    </div>
  );
};
export default ScrollToTop;
