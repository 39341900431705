import { yup } from '@jbc-year-end-adj/common/yup';
import { dateFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { Profile } from '../../../../query';

export type Schema = {
  lastName: string;
  firstName: string;
  lastNameKana: string;
  firstNameKana: string;
  birthday: string;
  gender: 'male' | 'female';
  postcode0: string;
  postcode1: string;
  prefectureId: string;
  city: string;
  street: string;
  building: string;
  householderRelationship: string;
  householderName: string;
  nationalType: 'japanese' | 'foreigner';
  resignBeforeYearEnd: string;
};

export const schema = yup.object({
  lastName: yup
    .string()
    .required()
    .label('姓'),
  firstName: yup
    .string()
    .required()
    .label('名'),
  lastNameKana: yup
    .string()
    .required()
    .kana()
    .label('姓（カナ）'),
  firstNameKana: yup
    .string()
    .required()
    .kana()
    .label('名（カナ）'),
  birthday: yup
    .string()
    .required()
    .dateFormat()
    .label('生年月日'),
  gender: yup
    .mixed()
    .oneOf(['male', 'female'])
    .required()
    .label('性別'),
  postcode0: yup
    .string()
    .required()
    .numberFormat()
    .max(3, '郵便番号1は3桁で入力してください')
    .label('郵便番号1'),
  postcode1: yup
    .string()
    .required()
    .numberFormat()
    .max(4, '郵便番号2は4桁で入力してください')
    .label('郵便番号2'),
  prefectureId: yup
    .string()
    .required()
    .label('都道府県'),
  city: yup
    .string()
    .required()
    .label('市区町村'),
  street: yup.string().label('番地丁目号'),
  building: yup.string().label('建物名'),
  householderRelationship: yup
    .string()
    .nullable()
    .label('世帯主の続柄'),
  householderName: yup.string().nullable(),
  nationalType: yup
    .mixed()
    .oneOf(['japanese', 'foreigner'])
    .required()
    .label('外国人区分')
});

export const generateDefaultValues = (profile: Profile | undefined) => {
  return {
    lastName: profile?.lastName,
    firstName: profile?.firstName,
    lastNameKana: profile?.lastNameKana,
    firstNameKana: profile?.firstNameKana,
    birthday: dateFormat(profile?.birthday, 'L'),
    gender: profile?.gender,
    postcode0: profile?.postcode0,
    postcode1: profile?.postcode1,
    prefectureId: profile?.prefectureId,
    city: profile?.city,
    street: profile?.street,
    building: profile?.building || '',
    householderRelationship: profile?.householder?.relationship || '',
    householderName: profile?.householder?.name || '',
    nationalType: profile?.nationalType,
    resignBeforeYearEnd: profile?.resignBeforeYearEnd ? 'true' : 'false'
  };
};
