import React from 'react';
import { gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { FileField } from 'jbc-front/components/Form';
import Button from 'jbc-front/components/Button';
import { Upload } from 'jbc-front/components/icons';
import { useMutation, useYear, Hoc } from '../components';
import FileImportDescription from '../employees/FileImportDescription';
import styles from './ImportWithholdingSlips.scss';
import { useNotify } from '../actions';
import useReactRouter from 'use-react-router';
import { useAsyncTask, ASYNC_TASK_FRAGMENT } from '../components/AsyncTask';

const IMPORT_EMPLOYEES_CSV = gql`
  mutation importEmployeesCsv($input: ImportEmployeesCsvInput!) {
    importEmployeesCsv(input: $input) {
      clientYearly {
        id
        asyncTask {
          ...AsyncTaskFields
        }
      }
    }
  }
  ${ASYNC_TASK_FRAGMENT}
`;

const UploadForm = () => {
  const [importEmployeesCsv] = useMutation(IMPORT_EMPLOYEES_CSV);
  const notify = useNotify();
  const { history } = useReactRouter();
  const year = useYear();
  const { taskRunningProps } = useAsyncTask();
  return (
    <Hoc
      makeHoc={reduxForm}
      form="importEmployeesCsv"
      onSubmit={async values => {
        if (!values.file) {
          notify('ファイルを選択してください', 'error');
          return;
        }
        await importEmployeesCsv({ variables: { input: { ...values, year } } });
        history.push(`/${year}`);
      }}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <dl className={styles.label}>
            <dt className={styles.number}>1</dt>
            <dd className={styles.detail}>
              <p>
                従業員データを
                <Link to={`/${year}`} className="u-txt-link">
                  こちら
                </Link>
                の従業員データダウンロードよりダウンロードしてください。
              </p>
            </dd>
          </dl>
          <dl className={styles.label}>
            <dt className={styles.number}>2</dt>
            <dd className={styles.detail}>
              <p className={styles.text}>下の説明を参考にしながら、ダウンロードしたCSVファイルに、変更したいデータを入力して下さい。</p>
              <p className={styles.text}>
                <span className={styles.important}>【注意】「スタッフコード」</span>
                は必ず項目に入れる必要があります。
                <br />
                ※「スタッフコード」とその他必要な項目に絞って更新が可能です。
                <br />
                ただし、画面上で「必須」となっている項目を更新する際は「空白」での更新はできかねます。（例：姓、名　等）
              </p>
              <p className={styles.text}>
                <span className={styles.important}>
                  【注意】前職情報、扶養情報、保険情報、メモ情報の更新には必ず「ID」が必要となります。
                  <br />
                </span>
                ※「ID」の更新ルールは下記ヘルプページをご確認ください。
                <br />
                ■&nbsp;
                <a
                  className="u-txt-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://jobcan-payroll.zendesk.com/hc/ja/articles/900002468983#Contentsid"
                >
                  従業員情報CSV更新「ID」の更新ルール
                </a>
              </p>
              <p>
                <span className={styles.important}>
                  ※ステータスが「入力済」「修正済」「再依頼中」（従業員回答後のステータス）の場合は従業員の回答した内容は更新されません。
                </span>
              </p>
            </dd>
          </dl>
          <dl className={styles.label}>
            <dt className={styles.number}>3</dt>
            <dd className={styles.detail}>
              <p className={styles.text}>完成したファイルを選択し、アップロードして下さい。</p>
              <FileField name="file" />
            </dd>
          </dl>
          <Button as="button" icon={<Upload size={16} />} primary className={styles.large} {...taskRunningProps}>
            アップロード
          </Button>
        </form>
      )}
    </Hoc>
  );
};

const ImportWithholdingSlips = () => {
  return (
    <div>
      <div className="l-contents-wrap">
        <h1 className="m-title-main">従業員データの一括更新</h1>
        <div className={styles.wrap}>
          <div className={styles.head}>アップロード手順</div>
          <div className={styles.body}>
            <UploadForm />
          </div>
        </div>
        <div className={styles.description}>
          <FileImportDescription />
        </div>
      </div>
    </div>
  );
};

export default ImportWithholdingSlips;
