import React from 'react'
import Icon from 'react-icon-base'

const CustomNumber = props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <defs>
      <path id="a" d="M5.573 15H0V.555h5.573z"/>
    </defs>
    <g fill="none">
      <g transform="translate(0 .445)">
        <mask id="b" fill="#fff">
          <path d="M5.573 15H0V.555h5.573z"/>
        </mask>
        <path d="M0 2.235L3.444.555h.345v11.95c0 .793.033 1.288.099 1.482a.772.772 0 0 0 .412.45c.209.103.633.163 1.273.176V15H.25v-.387c.668-.013 1.1-.07 1.295-.172a.858.858 0 0 0 .407-.407c.076-.17.114-.68.114-1.529v-7.64c0-1.029-.034-1.69-.104-1.983-.048-.222-.137-.386-.266-.49a.711.711 0 0 0-.465-.157c-.257 0-.615.108-1.074.324L0 2.235z" fill="currentColor"/>
      </g>
      <path d="M15.845 12.72l-.99 2.725h-8.35v-.386c2.455-2.24 4.184-4.07 5.186-5.49 1.002-1.42 1.503-2.717 1.503-3.893 0-.897-.275-1.635-.825-2.213-.549-.577-1.207-.866-1.972-.866-.696 0-1.32.204-1.873.61-.554.408-.963 1.004-1.227 1.79h-.386c.174-1.287.621-2.275 1.341-2.964C8.972 1.345 9.872 1 10.95 1c1.148 0 2.106.369 2.875 1.107.77.737 1.154 1.607 1.154 2.609 0 .716-.167 1.433-.5 2.15-.516 1.127-1.35 2.32-2.506 3.58-1.732 1.893-2.815 3.033-3.246 3.423h3.695c.751 0 1.278-.027 1.581-.084.302-.055.575-.168.82-.338.243-.171.455-.413.636-.726h.386z" fill="currentColor"/>
    </g>
  </Icon>
)
CustomNumber.defaultProps = {
  size: 16
}

export default CustomNumber
