import React from 'react'
import Icon from 'react-icon-base'

const Checkbox = props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <g id="symbol" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="checkbox_on">
        <rect id="checkbox" fill="currentColor" x="0" y="0" width="16" height="16" rx="2"></rect>
        <path d="M12.8966253,6.01993212 L7.25912829,11.8112374 C7.13662873,11.9370791 6.98811898,12 6.81359906,12 C6.63907914,12 6.49056939,11.9370791 6.36806983,11.8112374 L3.10379211,8.45790012 C2.98129255,8.3320584 2.92004277,8.17949685 2.92004277,8.00021548 C2.92004277,7.82093412 2.98129255,7.66837257 3.10379211,7.54253084 L3.99527009,6.62673059 C4.11776965,6.50088886 4.26627939,6.437968 4.44079932,6.437968 C4.61531924,6.437968 4.76382899,6.50088886 4.88632855,6.62673059 L6.81359906,8.61304746 L11.1136693,4.18876259 C11.2361689,4.06292086 11.3846786,4 11.5591985,4 C11.7337185,4 11.8822282,4.06292086 12.0047278,4.18876259 L12.8962057,5.10456284 C13.0187053,5.23011726 13.0799551,5.38267881 13.0799551,5.56224748 C13.0803746,5.74181616 13.0191248,5.8943777 12.8966253,6.01993212 Z" id="Check" fill="#FFFFFF"></path>
      </g>
    </g>
  </Icon>
)
Checkbox.defaultProps = {
  size: 40
}

export default Checkbox
