import React from 'react'
import Icon from 'react-icon-base'

const Pulldown = props => (
  <Icon viewBox="0 0 12 8" {...props}>
    <path d="M.886 1.978L1.933.916l4.06 4.074L10.066.916l1.047 1.062-5.122 5.106z" fill="currentColor"/>
  </Icon>
)
Pulldown.defaultProps = {
  size: 12
}

export default Pulldown
