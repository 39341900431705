import React from 'react'
import Icon from 'react-icon-base'

const MyNumberLine = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="iconMyNumberLine" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path d="M38,5 C39.1045695,5 40,5.8954305 40,7 L40,33 C40,34.1045695 39.1045695,35 38,35 L2,35 C0.8954305,35 0,34.1045695 0,33 L0,7 C0,5.8954305 0.8954305,5 2,5 L38,5 Z M37.5,7 L2.5,7 C2.22385763,7 2,7.22385763 2,7.5 L2,32.5 C2,32.7761424 2.22385763,33 2.5,33 L37.5,33 C37.7761424,33 38,32.7761424 38,32.5 L38,7.5 C38,7.22385763 37.7761424,7 37.5,7 Z M32.5,27 C32.7761424,27 33,27.2238576 33,27.5 L33,28.5 C33,28.7761424 32.7761424,29 32.5,29 L7.5,29 C7.22385763,29 7,28.7761424 7,28.5 L7,27.5 C7,27.2238576 7.22385763,27 7.5,27 L32.5,27 Z M32.5,23 C32.7761424,23 33,23.2238576 33,23.5 L33,24.5 C33,24.7761424 32.7761424,25 32.5,25 L7.5,25 C7.22385763,25 7,24.7761424 7,24.5 L7,23.5 C7,23.2238576 7.22385763,23 7.5,23 L32.5,23 Z M19,11 C20.1045695,11 21,11.8954305 21,13 L21,19 C21,20.1045695 20.1045695,21 19,21 L9,21 C7.8954305,21 7,20.1045695 7,19 L7,13 C7,11.8954305 7.8954305,11 9,11 L19,11 Z M18.8,13 L9.2,13 C9.08954305,13 9,13.0895431 9,13.2 L9,18.8 C9,18.9104569 9.08954305,19 9.2,19 L18.8,19 C18.9104569,19 19,18.9104569 19,18.8 L19,13.2 C19,13.0895431 18.9104569,13 18.8,13 Z" id="Combined-Shape" fill={props.color} fillRule="nonzero"></path>
    </g>
  </Icon>
)
MyNumberLine.defaultProps = {
  size: 40,
  color: "#3498DB"
}

export default MyNumberLine

