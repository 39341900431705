import { FC } from 'react';
import { year } from '@jbc-year-end-adj/2024/consts';
import { SettingItem } from './parts/SettingItem/SettingItem';
import styles from './Settings.scss';

export const Settings: FC = () => {
  return (
    <div>
      <div className="l-main-title-wrap">
        <h2 className="m-title-main">設定</h2>
      </div>
      <div className="l-wrap-l l-contents-wrap">
        <div className={styles.main}>
          <section className={styles.section}>
            <h3 className={styles.category}>共通設定</h3>
            <SettingItem.Container>
              <SettingItem.Item to={`/${year}/settings/question_descriptions`}>
                <SettingItem.Title>従業員フォームの説明文カスタマイズ</SettingItem.Title>
                <SettingItem.Description>従業員入力フォームの各STEPの説明文をカスタマイズすることができます</SettingItem.Description>
              </SettingItem.Item>

              <SettingItem.Item to={`/${year}/settings/custom_mails`}>
                <SettingItem.Title>メールカスタマイズ</SettingItem.Title>
                <SettingItem.Description>メールの内容をカスタマイズできます</SettingItem.Description>
              </SettingItem.Item>
            </SettingItem.Container>
          </section>
          <section className={styles.section}>
            <h3 className={styles.category}>{year}年設定</h3>

            <SettingItem.Container>
              <SettingItem.Item to={`/${year}/client_setting`}>
                <SettingItem.Title>基本設定</SettingItem.Title>
                <SettingItem.Description>
                  紙の提出の依頼有無、証明書の画像データの添付有無、帳票に出力する住所等を設定できます
                </SettingItem.Description>
              </SettingItem.Item>

              <SettingItem.Item to={`/${year}/resident_tax_payment_address`}>
                <SettingItem.Title>住民税納付先</SettingItem.Title>
                <SettingItem.Description>住民税納付先の設定ができます</SettingItem.Description>
              </SettingItem.Item>
            </SettingItem.Container>
          </section>
        </div>
      </div>
    </div>
  );
};
