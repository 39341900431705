import React from 'react';
import { Toggles, Toggle } from '../components/PrimaryPanel';
import { Field } from 'redux-form';

const ToggleButton = ({ input, options = [] }) => {
  return (
    <Toggles>
      {options.map(option => {
        return (
          <SizeButton
            key={option.value}
            isSelected={input.value === option.value}
            disabled={option.disabled}
            onClick={() => {
              input.onChange(option.value);
              input.onBlur(option.value);
            }}
          >
            {option.label}
          </SizeButton>
        );
      })}
    </Toggles>
  );
};

export const SizeButton = ({ children, isSelected, onClick, disabled }) => {
  return (
    <Toggle onClick={onClick} active={isSelected} role="presentation" disabled={disabled}>
      {children}
    </Toggle>
  );
};

export default props => <Field component={ToggleButton} {...props} />;
