import React from 'react'
import Icon from 'react-icon-base'

const Task = props => (
  <Icon viewBox="0 0 20 20" {...props}>
    <g fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="1.7">
      <path d="M16.924 12.55h-4.547a1.226 1.226 0 0 0-1.226 1.226v5.204l5.773-6.43V2.444c0-.678-.549-1.227-1.226-1.227H4.302a1.228 1.228 0 0 0-1.226 1.226v15.311c0 .678.549 1.227 1.226 1.227h3.912M6.215 5.345h7.57M6.215 8.448h7.57"/>
    </g>
  </Icon>
)
Task.defaultProps = {
  size: 20
}

export default Task
