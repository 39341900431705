import { FC, ReactNode } from 'react';
import styles from './ButtonContainer.scss';

type ButtonContainerProps = {
  children: ReactNode;
};

export const ButtonContainer: FC<ButtonContainerProps> = ({ children }) => {
  return <div className={styles.container}>{children}</div>;
};
