import React from 'react'
import Icon from 'react-icon-base'

const DocumentStepLine = props => (
  <Icon viewBox="0 0 205 6" {...props}>
    <g id="E_書類作成" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Artboard" transform="translate(-566.000000, -556.000000)" fill={props.color} fillRule="nonzero">
        <polygon id="Path" points="771 556 771 562 566 562 566 556"></polygon>
      </g>
    </g>
  </Icon>
)

DocumentStepLine.defaultProps = {
  size: 30,
  color: "#3498DB"
}

export default DocumentStepLine
