import React from 'react'
import Icon from 'react-icon-base'

const MenuEllipsis = props => (
  <Icon viewBox="0 0 10 20" {...props}>
    <g fill="currentColor" transform="translate(3 2)">
      <circle cx="2" cy="2" r="2"/>
      <circle cx="2" cy="8" r="2"/>
      <circle cx="2" cy="14" r="2"/>
    </g>
  </Icon>
)

MenuEllipsis.defaultProps = {
  size: 20
}

export default MenuEllipsis
