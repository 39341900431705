import { gql } from '@apollo/client';
import { EMPLOYEES_FRAGMENT, ASYNC_TASK_FRAGMENT } from '../components';

export const EMPLOYEES = gql`
  query employees($per: Int, $page: Int, $search: EmployeeSearchInput, $year: Int!, $mode: Mode!) {
    client {
      id
      clientYearly(year: $year) {
        id
        fixed
        selectableEmployeesCount(mode: $mode)
        employeeCountByStatus(search: $search) {
          totalCount
          notRequested
          inProgress
          applying
          rejected
          fixed
          accepted
          notNeedAdj
        }
        employees(per: $per, page: $page, search: $search) {
          ...Employees
        }
      }
    }
  }
  ${EMPLOYEES_FRAGMENT}
`;

export const REQUEST = gql`
  mutation request($input: RequestInput!) {
    request(input: $input) {
      employees {
        id
        status
      }
    }
  }
`;

export const REQUEST_ASYNC = gql`
  mutation requestAsync($input: RequestAsyncInput!) {
    requestAsync(input: $input) {
      clientYearly {
        id
        asyncTask {
          ...AsyncTaskFields
        }
      }
    }
  }
  ${ASYNC_TASK_FRAGMENT}
`;

export const UPDATE_STATUS = gql`
  mutation updateStatus($input: UpdateStatusInput!) {
    updateStatus(input: $input) {
      employees {
        id
      }
      failureEmployees {
        id
        staffCode
        status
        profile {
          fullName
        }
      }
    }
  }
`;

export const UPDATE_STATUS_ASYNC = gql`
  mutation updateStatusAsync($input: UpdateStatusAsyncInput!) {
    updateStatusAsync(input: $input) {
      clientYearly {
        id
        asyncTask {
          ...AsyncTaskFields
        }
      }
    }
  }
  ${ASYNC_TASK_FRAGMENT}
`;
