import React from 'react'
import Icon from 'react-icon-base'

const StressCheck = props => (
  <Icon viewBox="0 0 18 20" {...props}>
    <g fill="none" stroke="currentColor" strokeWidth="1.6">
      <rect width="16.4" height="18.346" x=".8" y=".8" rx="2"/>
      <path strokeLinecap="round" d="M3.892 4.622h9.816M3.892 8.027h9.816"/>
      <path strokeLinecap="round" strokeLinejoin="round" d="M7.784 13.947L9.747 15.821 13.523 12.649"/>
    </g>
  </Icon>
)

StressCheck.defaultProps = {
  size: 20
}

export default StressCheck
