import { FC, ReactNode } from 'react';
import styles from './FormField.scss';

export type FieldBaseProps = {
  label?: string;
  required?: boolean;
  note?: string;
  hint?: ReactNode;
  error?: string;
};

type FormFieldProps = FieldBaseProps & {
  children: ReactNode;
};

const Field: FC<FormFieldProps> = ({ children }) => {
  return <div className={styles.field}>{children}</div>;
};

type LabelContainerProps = {
  children: ReactNode;
};

const LabelContainer: FC<LabelContainerProps> = ({ children }) => {
  return <div className={styles.label}>{children}</div>;
};

type NoteContainerProps = {
  children: ReactNode;
};

const NoteContainer: FC<NoteContainerProps> = ({ children }) => {
  return <div className={styles.note}>{children}</div>;
};

type ErrorContainerProps = {
  children: ReactNode;
};

// MEMO: 削除
const ErrorContainer: FC<ErrorContainerProps> = ({ children }) => {
  return <div>{children}</div>;
};

export const FormField = Object.assign(Field, {
  LabelContainer,
  NoteContainer,
  ErrorContainer
});
