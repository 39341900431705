import { gql } from '@apollo/client';
import _ from 'lodash';
import { useQuery } from './Graphql';

const YEAR_MASTERS = gql`
  query yearMasters {
    yearMasters {
      id
      eraName
      eraCode
      eraNumber
      year
    }
  }
`;

const makeFuncs = options => ({
  pickIf: masters => {
    if (!options.year) return masters;
    const currentYearIndex = _.findIndex(masters, master => master.year === options.year);
    return [masters[currentYearIndex], masters[currentYearIndex + 1]];
  },
  toHeiseiEraIf: master =>
    options.toHeiseiEra && master.year === 2019 ? { ...master, eraName: '平成', eraCode: 'H', eraNumber: 31 } : master,
  toJpFirstYearIf: master => (options.toJpFirstYear && master.eraNumber === 1 ? { ...master, eraNumber: '元' } : master)
});

export const useYearMasters = (options = { year: null, toHeiseiEra: false, toJpFirstYear: false }) => {
  const { loading, data } = useQuery(YEAR_MASTERS);
  const { pickIf, toHeiseiEraIf, toJpFirstYearIf } = makeFuncs(options);
  if (loading) return [];
  return pickIf(data.yearMasters).map(master => master |> toHeiseiEraIf |> toJpFirstYearIf);
};

export default useYearMasters;
