import { FC, ReactNode } from 'react';
import { Section } from 'components/ui/Section';

import { Question } from '../type';

import { Lead } from './parts/Lead';
import { Description } from './parts/Description';
import { Label } from './parts/Label';
import { Note } from './parts/Note';
import { ToggleImageContainer } from './parts/ToggleImageContainer';
import { Content } from './parts/Content';

import { Hint } from 'jbc-front/components/presenters/ui/Hint';

import styles from './templates.scss';

// @ts-ignore
import image from '../../../../images/img-Dependent_Exemption_Form_AC.png';
// @ts-ignore
import image2 from '../../../../images/img-Basic_Consignment_Spouse.png';

type WorkingStudentProps = {
  children: ReactNode;
};

const MainSection: FC<WorkingStudentProps> = ({ children }) => {
  return (
    <Section>
      <Section.Header className={styles.sectionHeader}>配偶者控除情報</Section.Header>

      <Section.Body>
        <Content>{children}</Content>
      </Section.Body>
    </Section>
  );
};

type CheckWorkingStudentProps = {
  question: Question;
  children: ReactNode;
};

const CheckHasSpouseDeduction: FC<CheckWorkingStudentProps> = ({ question, children }) => {
  const description = question.questionDescriptions.find(description => description.field == 'under_note')?.description;

  return (
    <>
      <Lead>配偶者控除・配偶者特別控除等の対象ですか？</Lead>

      <Description>以下のいずれか1つ以上のすべての条件に該当する方です。</Description>

      <Note>
        <Label>今年の配偶者控除または配偶者特別控除対象</Label>
        <ul>
          <li>
            ・従業員本人の合計所得金額が1,000万円以下（給与収入だけの場合、1,195万円以下）
            <Hint>
              「合計所得金額」とは、各種損失の繰越控除を適用する前の総所得金額、譲渡所得、配当所得、雑所得、山林所得、退職所得の合計額をいいます。
              <br />
              「生計を一にする」とは、必ずしも同居を要件とするものではありません。例えば、別居している場合であっても、余暇には起居を共にすることを常例としている場合や、常に生活費等の送金が行われている場合には、「生計を一にする」ものとして取り扱われます。なお、親族が同一の家屋に起居している場合には、明らかに互いに独立した生活を営んでいると認められる場合を除き、「生計を一にする」ものとして取り扱われます。
            </Hint>
          </li>
          <li>・配偶者の合計所得金額が133万円以下</li>
          <li>・民法上の配偶者であり、生計を一にしている</li>
          <li>・配偶者が他の人の扶養親族ではない</li>
        </ul>
        <Label>今年の障害者控除対象</Label>
        <ul>
          <li>・配偶者が所得税法上の障害者に当てはまる</li>
          <li>・配偶者の合計所得金額が48万円以下</li>
          <li>・民法上の配偶者であり、生計を一にしている</li>
          <li>・配偶者が他の人の扶養親族ではない</li>
        </ul>
        <Label>来年の源泉控除対象配偶者</Label>
        <ul>
          <li>・従業員本人の来年の合計所得金額が900万円以下（予定）</li>
          <li>・配偶者の来年の合計所得金額が95万円以下（予定）</li>
          <li>・民法上の配偶者であり、生計を一にしている</li>
          <li>・配偶者が他の人の扶養親族ではない</li>
        </ul>
      </Note>

      <Description>{description || ''}</Description>
      {children}
    </>
  );
};

type DetailInputProps = {
  children: ReactNode;
  initiallyOpen?: boolean;
};

const DetailInput: FC<DetailInputProps> = ({ children, initiallyOpen = false }) => {
  return (
    <>
      <ToggleImageContainer initiallyOpen={initiallyOpen}>
        <ToggleImageContainer.Header>
          主に「扶養控除等(異動)申告書」のA欄、C欄や「配偶者控除等申告書」欄等に反映されます。
        </ToggleImageContainer.Header>
        <ToggleImageContainer.Body>
          <img src={image} alt="扶養控除申告書_AC" />
          <img src={image2} alt="基配所_配偶者" />
        </ToggleImageContainer.Body>
      </ToggleImageContainer>
      {children}
    </>
  );
};

export const Spouse = Object.assign(MainSection, { CheckHasSpouseDeduction, DetailInput });
