import React, { FC, useEffect, useState } from 'react';
import classnames from 'classnames';
import Button from 'jbc-front/components/Button';
import { Link as LinkIcon, Pulldown } from 'jbc-front/components/icons';
import { useOutsideClick } from '@jbc-year-end-adj/common/hooks/useOutsideClick';

import { year } from '@jbc-year-end-adj/2024/consts';
import { useAdminQuery as useQuery } from '@jbc-year-end-adj/2024/hooks/graphql/useQuery';
import { useAdminMutation as useMutation } from '@jbc-year-end-adj/2024/hooks/graphql/useMutation';
import { useSession } from '@jbc-year-end-adj/2024/features/AdminSessionProvider';
import { useAsyncTask } from '@jbc-year-end-adj/2024/features/AsyncTask/AsyncTaskProvider';
import { Balloon } from '@jbc-year-end-adj/2024/components/ui/Balloon';

import { PrevYearImportModal } from './PrevYearImportModal';
import { LmsImportModal } from './LmsImportModal';
import { PayrollImportModal } from './PayrollImportModal';
import { PayrollDataImportModal } from './PayrollDataImportModal';
import { OfficeQueryResult, ApplicationsQueryResult, OFFICE, APPLICATIONS } from './query';
import { IMPORT_EMPLOYEES } from './mutation';
import styles from './Cooperation.scss';

type CooperationProps = {
  refetchEmployees: () => void;
  fixed: boolean;
};

export const Cooperation: FC<CooperationProps> = ({ refetchEmployees, fixed }) => {
  const [isOpenPrevYearImpoerModal, setIsOpenPrevYearImportModal] = useState(false);
  const [isOpenLmsImportModal, setIsOpenLmsImportModal] = useState(false);
  const [isOpenPayrollImportModal, setIsOpenPayrollImportModal] = useState(false);
  const [isOpenPayrollDataImportModal, setIsOpenPayrollDataImportModal] = useState(false);

  const { data, loading: fetchApplicationLoading, refetch: refetchApplications } = useQuery<ApplicationsQueryResult>(APPLICATIONS);
  const [importEmployees] = useMutation(IMPORT_EMPLOYEES);

  const { clientYearlies } = useSession();
  const prevYear = clientYearlies.find(clientYearly => clientYearly.year === year - 1);
  const { taskRunningProps } = useAsyncTask();
  const { refetch: refetchOffice, data: dataOffice } = useQuery<OfficeQueryResult>(OFFICE);
  const isOfficeNotYetImported = !dataOffice?.client?.clientYearly?.office;

  const [isOpenBalloon, setIsOpenBalloon] = useState(false);
  const refForOutsideClick = useOutsideClick(() => {
    setIsOpenBalloon(false);
  });

  useEffect(() => {
    if (taskRunningProps === undefined) return;
    if (!taskRunningProps.disabled) refetchOffice();
  }, [taskRunningProps, refetchOffice]);

  if (fetchApplicationLoading || !data) return null;

  const {
    client: { applications }
  } = data;

  const findApplication = (name: string) => applications.find(application => application.name === name);

  const refetch = () => {
    refetchApplications();
    refetchEmployees();
  };

  const handleOpenInsuranceModal = () => {
    setIsOpenPrevYearImportModal(true);
    setIsOpenBalloon(false);
  };

  const handleImportOffice = async (applicationName: string) => {
    await importEmployees({
      variables: {
        service: applicationName,
        needsOffice: true,
        needsEmployee: false,
        needsWithholdingSlip: false,
        filterBySelection: false
      }
    });

    setIsOpenBalloon(false);
  };

  const handleOpenLmsModal = () => {
    setIsOpenLmsImportModal(true);
    setIsOpenBalloon(false);
  };

  const handleOpenPayrollModal = () => {
    setIsOpenPayrollImportModal(true);
    setIsOpenBalloon(false);
  };

  const handleOpenPayrollDataModal = () => {
    setIsOpenPayrollDataImportModal(true);
    setIsOpenBalloon(false);
  };

  return (
    <>
      <Balloon.Container>
        <Button
          onClick={() => setIsOpenBalloon(prev => !prev)}
          icon={<LinkIcon size={17} />}
          widthAuto
          disabled={fixed}
          disabledReason="年末調整情報が確定していますので連携（取得）はできません。"
        >
          連携（取得）
          <span className={styles.triangle} />
        </Button>

        {isOpenBalloon && (
          <Balloon.Content ref={refForOutsideClick} center>
            <Balloon.Ul>
              <Balloon.Li onClick={prevYear ? handleOpenInsuranceModal : undefined}>
                <span className={classnames(prevYear ? styles.pointer : styles.disabledAnchor)}>
                  <Pulldown transform="rotate(-90)" />
                  昨年の保険・ローン情報を取得
                </span>
              </Balloon.Li>

              {findApplication('ジョブカン労務HR') && (
                <Balloon.Li>
                  <span>
                    <Pulldown transform="rotate(-90)" />
                    ジョブカン労務HRから取得
                  </span>
                  <Balloon.Ul>
                    <Balloon.Li>
                      <div onClick={() => handleImportOffice('ジョブカン労務HR')} className={classnames(styles.list, styles.pointer)}>
                        「事業所情報」を取得
                      </div>
                    </Balloon.Li>
                    <Balloon.Li>
                      <div
                        onClick={isOfficeNotYetImported ? undefined : () => handleOpenLmsModal()}
                        className={classnames(styles.list, { [styles.pointer]: !isOfficeNotYetImported })}
                      >
                        「従業員情報」を取得
                      </div>
                    </Balloon.Li>
                  </Balloon.Ul>
                </Balloon.Li>
              )}

              {findApplication('ジョブカン給与計算') && (
                <Balloon.Li>
                  <span>
                    <Pulldown transform="rotate(-90)" />
                    ジョブカン給与計算から取得
                  </span>
                  <Balloon.Ul>
                    <Balloon.Li>
                      <div onClick={() => handleImportOffice('ジョブカン給与計算')} className={classnames(styles.list, styles.pointer)}>
                        「事業所情報」を取得
                      </div>
                    </Balloon.Li>
                    <Balloon.Li>
                      <div
                        onClick={isOfficeNotYetImported ? undefined : () => handleOpenPayrollModal()}
                        className={classnames(styles.list, { [styles.pointer]: !isOfficeNotYetImported })}
                      >
                        「従業員情報」を取得
                      </div>
                    </Balloon.Li>
                    <Balloon.Li>
                      <div
                        onClick={isOfficeNotYetImported ? undefined : () => handleOpenPayrollDataModal()}
                        className={classnames(styles.list, { [styles.pointer]: !isOfficeNotYetImported })}
                      >
                        「給与データ」を取得
                      </div>
                    </Balloon.Li>
                  </Balloon.Ul>
                </Balloon.Li>
              )}

              <Balloon.Li>
                <a href="https://jobcan-payroll.zendesk.com/hc/ja/articles/900003088906" target="_blank" rel="noopener noreferrer">
                  <Pulldown transform="rotate(-90)" />
                  連携項目を確認する
                </a>
              </Balloon.Li>
            </Balloon.Ul>
          </Balloon.Content>
        )}
      </Balloon.Container>

      <PrevYearImportModal isOpen={isOpenPrevYearImpoerModal} onClose={() => setIsOpenPrevYearImportModal(false)} />

      <LmsImportModal isOpen={isOpenLmsImportModal} onClose={() => setIsOpenLmsImportModal(false)} refetch={refetch} />

      <PayrollImportModal isOpen={isOpenPayrollImportModal} onClose={() => setIsOpenPayrollImportModal(false)} refetch={refetch} />

      <PayrollDataImportModal
        isOpen={isOpenPayrollDataImportModal}
        onClose={() => setIsOpenPayrollDataImportModal(false)}
        refetch={refetch}
      />
    </>
  );
};
