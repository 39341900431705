import React from 'react'
import Icon from 'react-icon-base'

const Copy = props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <g>
      <g>
        <g>
          <path d="M7.425,3.57h3.427c1.028,0,1.87,0.841,1.87,1.869v6.553c0,1.027-0.842,1.869-1.87,1.869H6.49&#xA;&#x9;&#x9;&#x9;&#x9;c-1.028,0-1.869-0.842-1.869-1.869V6.375L7.425,3.57z" fill="#FFFFFF"/>
          <path d="M10.852,14.611H6.49c-1.444,0-2.619-1.176-2.619-2.619V6.375c0-0.199,0.079-0.39,0.22-0.53L6.895,3.04&#xA;&#x9;&#x9;&#x9;&#x9;c0.141-0.141,0.332-0.22,0.53-0.22h3.427c1.444,0,2.62,1.175,2.62,2.619v6.553C13.472,13.436,12.296,14.611,10.852,14.611z&#xA;&#x9;&#x9;&#x9;&#x9; M5.371,6.686v5.307c0,0.617,0.502,1.119,1.119,1.119h4.361c0.617,0,1.12-0.502,1.12-1.119V5.439c0-0.617-0.503-1.119-1.12-1.119&#xA;&#x9;&#x9;&#x9;&#x9;H7.735L5.371,6.686z" fill="currentColor"/>
        </g>
      </g>
      <path d="M8.407,7.81H4.621c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h3.286V3.57c0-0.276,0.224-0.5,0.5-0.5&#xA;&#x9;&#x9;s0.5,0.224,0.5,0.5V7.31C8.907,7.586,8.684,7.81,8.407,7.81z" fill="currentColor"/>
    </g>
    <g>
      <g>
        <g>
          <path d="M4.603,0.75h3.427c1.028,0,1.87,0.841,1.87,1.869v6.547c0,1.029-0.842,1.869-1.87,1.869H3.668&#xA;&#x9;&#x9;&#x9;&#x9;c-1.027,0-1.869-0.84-1.869-1.869V3.555L4.603,0.75z" fill="#FFFFFF"/>
          <path d="M8.029,11.785H3.668c-1.444,0-2.619-1.174-2.619-2.619V3.555c0-0.199,0.079-0.39,0.22-0.53L4.072,0.22&#xA;&#x9;&#x9;&#x9;&#x9;C4.213,0.079,4.404,0,4.603,0h3.427c1.444,0,2.62,1.175,2.62,2.619v6.547C10.649,10.611,9.474,11.785,8.029,11.785z M2.549,3.865&#xA;&#x9;&#x9;&#x9;&#x9;v5.301c0,0.617,0.502,1.119,1.119,1.119h4.361c0.617,0,1.12-0.502,1.12-1.119V2.619c0-0.617-0.503-1.119-1.12-1.119H4.913&#xA;&#x9;&#x9;&#x9;&#x9;L2.549,3.865z" fill="currentColor"/>
        </g>
      </g>
      <path d="M5.585,4.99H1.799c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h3.286V0.75c0-0.276,0.224-0.5,0.5-0.5&#xA;&#x9;&#x9;s0.5,0.224,0.5,0.5v3.74C6.085,4.766,5.861,4.99,5.585,4.99z" fill="currentColor"/>
    </g>
  </Icon>
)
Copy.defaultProps = {
  size: 15
}

export default Copy
