import React from 'react';
import { FieldArray } from 'redux-form';
import { Section } from 'jbc-front/components/Form';
import { FileField, SelectField, TextField, DateField, FormSection } from '../components/FieldWithDiff';
import { DeleteSquare } from 'jbc-front/components/icons';
import { withholdingSlipStatuses } from '../questions/FormerJobs';
import FormAdd from '../components/FormAdd';
import { amount } from '../validators';
import { LabelMapper } from 'jbc-front/components/FormErrors';
import { useDiff } from '../components/FieldWithDiff';

export { FORMER_JOBS_FRAGMENT, makeInitialValues } from '../questions/FormerJobs';

const FormerJobsFields = ({ fields }) => {
  const { fixed } = useDiff();
  return (
    <>
      {fields.map((field, index) => (
        <div key={index}>
          <Section title={`前職情報(${index + 1})`} icon={fixed ? undefined : <DeleteSquare onClick={() => fields.remove(index)} />}>
            <FormSection name={field}>
              <TextField name="companyName" label="会社名" required />
              <TextField name="address" label="住所" />
              <DateField name="resignedOn" label="退職日" required />
              <DateField name="lastSalaryOn" label="最後の給与支給日" />
              <SelectField name="withholdingSlipStatus" label="源泉徴収票" required options={withholdingSlipStatuses} />
              <FileField name="withholdingSlipImage" label="源泉徴収票画像" />
              <TextField name="paymentAmount" label="支払金額" validate={amount} />
              <TextField name="deductionAmount" label="社会保険料等の金額" validate={amount} />
              <TextField name="taxAmount" label="源泉徴収税額" validate={amount} />
            </FormSection>
          </Section>
        </div>
      ))}
      {fields.length < 10 && (
        <FormAdd
          onClick={() => {
            fields.push({});
          }}
          name="前職情報"
        />
      )}
    </>
  );
};

const FormerJobs = ({ diff = [] }) => (
  <Section title="前職情報">
    <p className="u-mb10">
      ※ここに記載された前職の支払金額、控除額、源泉徴収税額は源泉徴収票の最初の作成時に合計されます。
      <br />
      源泉徴収票情報の入力、CSV一括登録、給与計算との連携などによって源泉徴収票が「入力済」になった後の金額の変動は源泉徴収票の計算及び過不足額の精算に自動反映されませんのでご注意ください。
    </p>
    <LabelMapper name="formerJobs" label="前職情報" />
    <FieldArray name="formerJobs" component={FormerJobsFields} />
  </Section>
);

export default FormerJobs;
