import React from 'react'
import Icon from 'react-icon-base'

const PostitOn = props => (
  <Icon viewBox="0 0 26 26" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle cx="12.839" cy="12.837" r="12.339" fill="currentColor" fillRule="nonzero" />
      <path fill="currentColor" fillRule="nonzero" d="M11.051 8.32H7.57c-.129 0-.251.022-.363.062-.335.125-.57.413-.57.749v9c0 .446.418.812.934.812h6.548l4.73-4.061v-5.75c0-.447-.418-.812-.934-.812h-6.864z" />
      <path stroke="#FFF" strokeLinecap="round" strokeLinejoin="round" d="M11.051 8.32H7.57c-.129 0-.251.022-.363.062-.335.125-.57.413-.57.749v9c0 .446.418.812.934.812h6.548l4.73-4.061v-5.75c0-.447-.418-.812-.934-.812h-6.864z" />
      <path fill="#FFF" fillRule="nonzero" d="M8.759 9.913h8.499a.53.53 0 1 1 0 1.062H8.759a.53.53 0 1 1 0-1.062zM8.759 12.037h8.499a.53.53 0 1 1 0 1.062H8.759a.531.531 0 0 1 0-1.062z" />
      <path fill="currentColor" fillRule="nonzero" stroke="#FFF" d="M18.303 5.786a.405.405 0 0 0-.294-.12.404.404 0 0 0-.293.12l-5.771 5.767v2.077h2.078l5.768-5.767a.41.41 0 0 0 .002-.584l-.002-.002-1.488-1.491z" />
      <path fill="#FFF" fillRule="nonzero" d="M13.921 14.692l5.244-.048-4.543 3.802-.735.529z" />
    </g>
  </Icon>
)
PostitOn.defaultProps = {
  size: 26
}

export default PostitOn
