import { gql } from '@apollo/client';
import { useQuery } from './Graphql';

const DEFAULT_MAIL = gql`
  query defaultMail($emailType: EmailSearchInput!) {
    defaultMail(emailType: $emailType) {
      id
      subject
      header
      footer
    }
  }
`;

export const useDefaultMail = emailType => {
  const { loading, data } = useQuery(DEFAULT_MAIL, {
    variables: {
      emailType: { emailType: emailType }
    }
  });
  return loading ? null : data.defaultMail;
};
