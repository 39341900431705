import React from 'react'
import Icon from 'react-icon-base'

const Tel = props => (
  <Icon viewBox="0 0 14 14" {...props}>
    <path fill='currentColor' fillRule='evenodd' d='M5.675 3.213a.476.476 0 0 1-.124.365c-.717.777-1.34 1.733-1.307 2.005.058.484 1.577 3.036 4.296 4.366.406-.064 1.346-.684 2.027-1.265.116-.1.271-.14.422-.1 2.157.523 2.935 2.003 2.968 2.066.046.09.062.193.046.292-.364 2.286-1.851 2.986-1.914 3.015a.472.472 0 0 1-.345.02c-.1-.032-.23-.068-.388-.112-2.059-.57-8.32-2.302-11.333-11.65a.473.473 0 0 1-.01-.258C.03 1.897.41.426 2.935.007a.465.465 0 0 1 .277.037c.093.042 2.281 1.071 2.463 3.169z' />
  </Icon>
)
Tel.defaultProps = {
  size: 14
}

export default Tel
