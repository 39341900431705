import { combineEpics } from 'redux-observable';
import { debounceTime, filter, map } from 'rxjs/operators';
import { submit } from 'redux-form';

const DEFAULT_EXCLUDE_FIELDS = ['limit', 'sortType', 'page'];

const search = (formName, excludeFields = DEFAULT_EXCLUDE_FIELDS) => action$ =>
  action$.pipe(
    filter(action => action.type && action.type.match(/@@redux-form\/CHANGE/) && action.meta.form === formName),
    filter(action => !excludeFields.includes(action.meta.field)),
    debounceTime(1000),
    map(() => submit(formName))
  );

const rootEpic = combineEpics(
  search('employeeSearchTop', ['status', ...DEFAULT_EXCLUDE_FIELDS]),
  search('employeeSearchResult', ['withholdingSlipStatus', DEFAULT_EXCLUDE_FIELDS]),
  search('employeeSearchDifferenceApply'),
  search('employeeSearchTaxOffice'),
  search('employeeSearchWithholdingSlipDeliver')
);

export default rootEpic;
