// @ts-ignore
import { parse } from 'query-string';

export const makeBackUrl = ({ pathname, search }: { pathname: string; search: string }) => {
  return encodeURIComponent(pathname + search);
};

export const toBlob = (base64: string, mimeCtype: string) => {
  const bin = atob(base64);
  const buffer = new Uint8Array(bin.length).map((_, i) => bin.charCodeAt(i));
  return new Blob([buffer.buffer], { type: mimeCtype });
};
