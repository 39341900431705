import { FC, ReactNode, createContext, useContext, useState } from 'react';
import { HoverDescription, DescriptionContainer } from 'jbc-front/components/presenters/ui/HoverDescription';
import styles from './TabBar.scss';
import classnames from 'classnames';

type ContentType = 'employee' | 'result' | 'progress';

const CONTENT_COLORS = {
  employee: 'blue',
  result: 'green',
  progress: 'blue'
};

type TabBarContextType = {
  content: ContentType;
  setContent?: (content: ContentType) => void;
};

const TabBarContext = createContext<TabBarContextType | null>(null);

type TabBarProps = {
  children: ReactNode;
  content: ContentType;
  setContent: (content: ContentType) => void;
};

const TabBarComponent: FC<TabBarProps> = ({ children, content, setContent }) => {
  return (
    <TabBarContext.Provider value={{ content, setContent }}>
      <div className={styles[`${CONTENT_COLORS[content]}TabBar`]}>{children}</div>
    </TabBarContext.Provider>
  );
};

type TabContainerProps = {
  children: ReactNode;
};

const TabContainer: FC<TabContainerProps> = ({ children }) => {
  return <div className={styles.tabContainer}>{children}</div>;
};

type TabProps = {
  children: ReactNode;
  content: ContentType;
  disabled?: boolean;
  disabledReason?: string;
};

const Tab: FC<TabProps> = ({ children, content, disabled = false, disabledReason = '表示できないステータスです。' }) => {
  const { content: currentContent, setContent } = useContext(TabBarContext) ?? {};

  const handleClick = () => {
    if (!disabled && setContent) {
      setContent(content);
    }
  };

  const isResultTab = content === 'result';

  const tabStyle = classnames(
    styles.tab,
    { [styles.result]: isResultTab },
    currentContent === content && styles[`${CONTENT_COLORS[currentContent]}Tab`]
  );

  const [isHover, setIsHover] = useState<boolean>(false);
  const handleEnter = () => {
    setIsHover(true);
  };

  const handleLeave = () => {
    setIsHover(false);
  };

  return (
    <>
      {disabled ? (
        <DescriptionContainer onMouseEnter={handleEnter} onMouseLeave={handleLeave}>
          <div onClick={handleClick} className={styles.disabled}>
            {children}
            <HoverDescription isHover={isHover}>{disabledReason}</HoverDescription>
          </div>
        </DescriptionContainer>
      ) : (
        <div onClick={handleClick} className={tabStyle}>
          {children}
        </div>
      )}
    </>
  );
};

type ToolContainerProps = {
  children: ReactNode;
};

const ToolContainer: FC<ToolContainerProps> = ({ children }) => {
  return <div className={styles.toolContainer}>{children}</div>;
};

export const TabBar = Object.assign(TabBarComponent, { TabContainer, Tab, ToolContainer });
