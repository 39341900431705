import React from 'react'
import Icon from 'react-icon-base'

const Clock = props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <path fill="currentColor" d="M14.4 8c0-3.529-2.871-6.4-6.4-6.4A6.407 6.407 0 0 0 1.6 8c0 3.529 2.871 6.4 6.4 6.4 3.529 0 6.4-2.871 6.4-6.4M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.2.8a.8.8 0 0 1-.8.8H8.8A1.6 1.6 0 0 1 7.2 8V4a.8.8 0 0 1 1.6 0v3.2c0 .44.36.8.8.8H12a.8.8 0 0 1 .8.8"/>
  </Icon>
)
Clock.defaultProps = {
  size: 20
}

export default Clock
