import React from 'react'
import Icon from 'react-icon-base'

const CalenderChecked = props => (
  <Icon viewBox="0 0 45 50" {...props}>
    <g fill="none" fillRule="evenodd">
      <g fill="#3498DB">
        <path d="M3.658 7.766c0-.837.678-1.516 1.516-1.516h5.353v1.565c0 .862.7 1.56 1.56 1.56.862 0 1.56-.697 1.56-1.56V6.25H31.334v1.565c0 .862.698 1.56 1.56 1.56.861 0 1.56-.697 1.56-1.56V6.25h5.353c.838 0 1.517.679 1.517 1.516V12.5H3.657V7.766zM32.894 0l.142.006c.794.072 1.418.74 1.418 1.554v1.565h6.87c1.716 0 3.12 1.406 3.12 3.125l-.054 17.221c-.002.845-.688 1.529-1.532 1.529-.848 0-1.534-.686-1.534-1.534v-7.841H3.658v19.69c0 .863.698 1.56 1.56 1.56h20.71c.863 0 1.562.7 1.562 1.563S26.791 40 25.927 40H3.855C2.134 40 .638 38.227.638 36.502L.537 6.25c0-1.719 1.403-3.125 3.12-3.125h6.87V1.56c0-.861.698-1.56 1.56-1.56l.141.006c.795.072 1.419.74 1.419 1.554v1.565h17.686V1.56c0-.861.698-1.56 1.56-1.56zm12.494 27.496c.54.562.538 1.452-.006 2.012L35.538 39.62c-.491.507-1.302.505-1.794 0l-4.396-4.538c-.543-.56-.544-1.45-.004-2.01.568-.59 1.51-.593 2.08-.005l3.215 3.306 8.67-8.886c.572-.584 1.513-.582 2.079.008z" transform="translate(-445 -328) translate(310 233) translate(10 10) translate(125 85) translate(2.5 5)"/>
      </g>
    </g>
  </Icon>
)

CalenderChecked.defaultProps = {
  size: 20
}

export default CalenderChecked
