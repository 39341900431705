import React, { useState } from 'react';
import Button from 'jbc-front/components/Button';
import { Panel, Headding, Item, Lead, Buttons, Line } from '../components/PrimaryPanel';
import { DescriptionEdit } from '../components/DescriptionEdit';
import { CustomDescription } from '../components/QuestionDescription';
import { FormPlaceholder } from '../components/FormPlaceholder';

const Question = () => {
  const [isPositive, setPositive] = useState(true);
  return (
    <Panel>
      <Headding icon={<DescriptionEdit />}>前職情報</Headding>
      <Item>
        <Lead>今年給料をもらった前職は有りますか？</Lead>
        <CustomDescription field="top" />
        <Buttons>
          <Button huge secondary primary={isPositive} onClick={() => setPositive(true)}>
            はい
          </Button>
          <Button huge secondary primary={!isPositive} onClick={() => setPositive(false)}>
            いいえ
          </Button>
        </Buttons>
        {isPositive && (
          <>
            <Line />
            <Lead>以下の情報の記入が必要です。</Lead>
            <FormPlaceholder />
            <div>
              <Buttons>
                <Button primary disabled>
                  次ヘ
                </Button>
              </Buttons>
            </div>
          </>
        )}
      </Item>
    </Panel>
  );
};

export default Question;
