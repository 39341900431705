import React from 'react'
import Icon from 'react-icon-base'

const DocumentStep4 = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="E_書類作成" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Artboard" transform="translate(-955.000000, -628.000000)">
        <g id="4" transform="translate(955.000000, 628.000000)">
          <circle id="Oval" fill={props.color} cx="20" cy="20" r="20"></circle>
          <path d="M20,2.22222222 C29.8183956,2.22222222 37.7777778,10.1816044 37.7777778,20 C37.7777778,29.8183956 29.8183956,37.7777778 20,37.7777778 C10.1816044,37.7777778 2.22222222,29.8183956 2.22222222,20 C2.22222222,10.1816044 10.1816044,2.22222222 20,2.22222222 Z M20,5.22222222 C11.8384587,5.22222222 5.22222222,11.8384587 5.22222222,20 C5.22222222,28.1615413 11.8384587,34.7777778 20,34.7777778 C28.1615413,34.7777778 34.7777778,28.1615413 34.7777778,20 C34.7777778,11.8384587 28.1615413,5.22222222 20,5.22222222 Z" id="Oval" fill="#FFFFFF" fillRule="nonzero"></path>
          <path d="M23.09,28.04 L23.09,24.58 L24.99,24.58 L24.99,22.7 L23.09,22.7 L23.09,12.68 L19.93,12.68 L14.97,22.82 L14.97,24.58 L20.79,24.58 L20.79,28.04 L23.09,28.04 Z M20.79,22.7 L17.05,22.7 L18.93,18.66 C19.55,17.32 20.13,15.98 20.63,14.66 L20.91,14.66 C20.85,16.2 20.79,17.72 20.79,19.34 L20.79,22.7 Z" fill="#FFFFFF" fillRule="nonzero"></path>
        </g>
      </g>
    </g>
  </Icon>
)

DocumentStep4.defaultProps = {
  size: 30,
  color: "#3498DB"
}

export default DocumentStep4
