import { FC } from 'react';
import { Dl, Dt, Dd } from '../../../components/DefinitionList';
import { Grid } from '../../../components/Grid';
import { displayFormat, dateFormat, fullNameFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { useEmployeeInfo } from '../EmployeeInfoProvider';
import { NATIONAL_TYPE, GENDER } from '../../../consts';

export const Preview: FC = () => {
  const { employee } = useEmployeeInfo();
  const profile = employee.profile;

  return (
    <>
      <Grid>
        <Dl>
          <Dt>入社日</Dt>
          <Dd>{dateFormat(employee.joinedOn, 'L')}</Dd>
        </Dl>
        <Dl>
          <Dt>退職日</Dt>
          <Dd>{dateFormat(employee.resignedOn, 'L')}</Dd>
        </Dl>
      </Grid>
      <Grid>
        <Dl>
          <Dt>氏名</Dt>
          <Dd>{fullNameFormat(profile.lastName, profile.firstName)}</Dd>
        </Dl>

        <Dl>
          <Dt>氏名（カナ）</Dt>
          <Dd>{fullNameFormat(profile.lastNameKana, profile.firstNameKana)}</Dd>
        </Dl>

        <Dl>
          <Dt>生年月日</Dt>
          <Dd>{dateFormat(profile.birthday, 'L')}</Dd>
        </Dl>

        <Dl>
          <Dt>性別</Dt>
          <Dd>{GENDER[profile.gender]}</Dd>
        </Dl>

        <Dl>
          <Dt>雇用形態</Dt>
          <Dd>{displayFormat(employee.employmentType)}</Dd>
        </Dl>

        <Dl>
          <Dt>役職</Dt>
          <Dd>{displayFormat(employee.position)}</Dd>
        </Dl>

        <Dl>
          <Dt>非居住者</Dt>
          <Dd>{employee.isNonResident ? '非居住者' : '対象外'}</Dd>
        </Dl>

        {!employee.isNonResident && (
          <Dl>
            <Dt>住所</Dt>
            <Dd>{displayFormat(profile.fullAddress)}</Dd>
          </Dl>
        )}

        <Dl>
          <Dt>世帯主続柄</Dt>
          <Dd>{displayFormat(profile.householder?.relationship)}</Dd>
        </Dl>

        <Dl>
          <Dt>世帯主の氏名</Dt>
          <Dd>{displayFormat(profile.householder?.name)}</Dd>
        </Dl>

        <Dl>
          <Dt>外国人区分</Dt>
          <Dd>{NATIONAL_TYPE[profile.nationalType]}</Dd>
        </Dl>
      </Grid>
    </>
  );
};
