import { yup } from '@jbc-year-end-adj/common/yup';
import { ResidentCard } from '../../../../query';

export type Schema = {
  postcode0: string;
  postcode1: string;
  prefectureId: string;
  city: string;
  street: string;
  building: string;
};

export const schema = yup.object({
  postcode0: yup
    .string()
    .required()
    .numberFormat()
    .max(3, '郵便番号1は3桁で入力してください')
    .label('郵便番号1'),
  postcode1: yup
    .string()
    .required()
    .numberFormat()
    .max(4, '郵便番号2は4桁で入力してください')
    .label('郵便番号2'),
  prefectureId: yup
    .string()
    .required()
    .label('都道府県'),
  city: yup
    .string()
    .required()
    .label('市区町村'),
  street: yup.string().label('番地丁目号'),
  building: yup.string().label('建物名')
});

export const generateDefaultValues = (residentCard: ResidentCard | undefined) => ({
  postcode0: residentCard?.postcode0,
  postcode1: residentCard?.postcode1,
  prefectureId: residentCard?.prefectureId,
  city: residentCard?.city,
  street: residentCard?.street,
  building: residentCard?.building || ''
});
