import React from 'react'
import Icon from 'react-icon-base'

const Hamburger = props => (
  <Icon viewBox="0 0 12 12" {...props}>
    <rect height="12" width="12" fill="#FFFFFF"/>
    <line fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="1.5" x1="0.797" x2="11.199" y1="1.732" y2="1.732"/>
    <line fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="1.5" x1="0.797" x2="11.199" y1="5.982" y2="5.982"/>
    <line fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="1.5" x1="0.797" x2="11.199" y1="10.23" y2="10.23"/>
  </Icon>
)
Hamburger.defaultProps = {
  size: 12
}


export default Hamburger
