import { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import useRouter from 'use-react-router';
import { Modal } from 'jbc-front/components/presenters/ui/Modal';
import Button from 'jbc-front/components/Button';
// @ts-ignore
import { Link } from 'react-router-dom';

import { year } from '@jbc-year-end-adj/2024/consts';
import { useSession } from '@jbc-year-end-adj/2024/features/AdminSessionProvider';
import { useAdminMutation as useMutation } from '@jbc-year-end-adj/2024/hooks/graphql/useMutation';
import { Input } from '@jbc-year-end-adj/2024/components/form/Input';
import { Textarea } from '@jbc-year-end-adj/2024/components/form/Textarea';
import { FormSection } from '@jbc-year-end-adj/2024/components/form/FormSection';
import { FormField } from '@jbc-year-end-adj/2024/components/form/FormField';
import { Label } from '@jbc-year-end-adj/2024/components/form/Label';
import { CustomMail, DefaultMail } from '../../query';
import { EmailTypeKeys, emailTypes } from '../../consts';
import { UPDATE_CUSTOM_MAIL } from './mutation';
import { Schema, schema } from './schema';
import styles from './Form.scss';
import { yupResolver } from '@hookform/resolvers/yup';

type FormProps = {
  customMail?: CustomMail;
  defaultMail: DefaultMail;
  emailType: EmailTypeKeys;
};

export const Form: FC<FormProps> = ({ customMail, defaultMail, emailType }) => {
  const { history } = useRouter();
  const { me } = useSession();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [updateCustomMail, { loading: submitting }] = useMutation(UPDATE_CUSTOM_MAIL);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      subject: customMail?.subject,
      body: customMail?.body
    }
  });

  const subject = watch('subject');
  const body = watch('body');

  const replaceApplicationName = (target: string) => {
    if (me?.application.name) {
      return target.replace('%application%', me.application.name);
    }

    return '';
  };

  const onSubmit: SubmitHandler<Schema> = async data => {
    await updateCustomMail({
      variables: {
        input: {
          attributes: {
            emailType,
            subject: data.subject,
            body: data.body
          }
        }
      }
    });

    history.push(`/${year}/settings/custom_mails`);
  };

  return (
    <>
      <FormSection className={styles.section}>
        <Input
          label="件名"
          placeholder={replaceApplicationName(defaultMail.subject)}
          {...register('subject')}
          isError={!!errors?.subject?.message}
          error={errors?.subject?.message}
        />

        <FormField>
          <FormField.LabelContainer>
            <Label>デフォルト本文</Label>
          </FormField.LabelContainer>
          <p />

          <div className={styles.defaultBody}>
            <p dangerouslySetInnerHTML={{ __html: defaultMail?.header || '' }} />
          </div>
        </FormField>

        <Textarea label="カスタマイズ文" {...register('body')} isError={!!errors?.body?.message} error={errors?.body?.message} />

        <div>
          デフォルトの本文は編集できません。
          <ul className={styles.help}>
            <li>
              {'<b>'}と{'</b>'}の間に文字を入れると太字になります。
            </li>
            <li>
              {'<u>'}と{'</u>'}の間に文字を入れると下線が付きます。
            </li>
          </ul>
        </div>
      </FormSection>

      <div className="u-ta-c">
        <Button className="u-mr20" onClick={() => setIsOpen(true)}>
          プレビュー
        </Button>
        <Button onClick={handleSubmit(onSubmit)} disabled={submitting} primary>
          保存
        </Button>
      </div>

      {isOpen && defaultMail && (
        <Modal className={styles.modal} isOpen={isOpen} onClose={() => setIsOpen(false)}>
          <Modal.Header onClose={() => setIsOpen(false)}>{emailTypes[emailType]}</Modal.Header>
          <Modal.Body className={styles.body}>
            <div className={styles.mailTitle}>
              <h3 className={styles.mailHeading}>件名</h3>
              <p className={styles.mailPreview}>{subject || replaceApplicationName(defaultMail.subject)}</p>
            </div>
            <div>
              <h3 className={styles.mailHeading}>本文</h3>
              <div className={styles.mailPreview}>
                <p dangerouslySetInnerHTML={{ __html: defaultMail.header }} />
                <br />
                <p dangerouslySetInnerHTML={parseHtml(body)} />
                <br />
                <p dangerouslySetInnerHTML={{ __html: replaceApplicationName(defaultMail.footer) }} />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setIsOpen(false)}>閉じる</Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

const parseHtml = (html?: string) => {
  if (!html) {
    return { __html: '' };
  }

  const escapedHtml = html
    .replace(new RegExp('&', 'g'), '&amp;')
    .replace(new RegExp('<', 'g'), '&lt;')
    .replace(new RegExp('>', 'g'), '&gt;')
    .replace(new RegExp('"', 'g'), '&quot;')
    .replace(new RegExp("'", 'g'), '&apos;')
    .replace(new RegExp(`&lt;b&gt;`, 'g'), `<b>`)
    .replace(new RegExp(`&lt;/b&gt;`, 'g'), `</b>`)
    .replace(new RegExp(`&lt;u&gt;`, 'g'), `<u>`)
    .replace(new RegExp(`&lt;/u&gt;`, 'g'), `</u>`)
    .replace(/&lt;a href=(&quot;|&#39;)(https?:\/\/.+?)(&quot;|&#39;)&gt;(.+?)&lt;\/a&gt;/g, '<a href="$2">$4</a>')
    .replace(/(^|[^"'>])(https?:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+)/g, '$1<a href="$2">$2</a>')
    .replace(/\r\n|\r|\n/g, '<br />');

  return { __html: escapedHtml };
};
