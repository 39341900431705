import { FC } from 'react';
import { useEmployeeInfo } from '../../EmployeeInfoProvider';
import { dateFormat, displayFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { GENDER } from '../../../../consts';
import { OtherFamilyRow } from './OtherFamilyRow';

import styles from './Preview.scss';
import classnames from 'classnames';

export const Preview: FC = () => {
  const { employee } = useEmployeeInfo();
  const profile = employee.profile;
  const spouse = profile.spouse;

  const handleSpouseClick = () => {
    window.location.href = '#spouse';
  };

  if (profile.otherFamilies.length === 0 && !spouse) {
    return <p>扶養親族情報がありません</p>;
  }

  return (
    <div className="l-overflow-scroll">
      <table className={classnames('m-table-list', styles.expandableTable)}>
        <thead>
          <tr>
            <th className={classnames(styles.relationType)}>続柄</th>
            <th className={styles.fullName}>氏名</th>
            <th className={styles.birthday}>生年月日</th>
            <th className={styles.gender}>性別</th>
            <th className={styles.taxLaw}>今年の税扶養</th>
            <th className={styles.taxLaw}>来年の税扶養</th>
          </tr>
        </thead>
        <tbody>
          {spouse && (
            <tr className={styles.row} onClick={handleSpouseClick}>
              <td className={styles.spouseColumn}>配偶者</td>
              <td>{spouse ? `${spouse.lastName}${spouse.firstName}（${spouse.lastNameKana}${spouse.firstNameKana}）` : displayFormat()}</td>
              <td>{dateFormat(spouse?.birthday, 'L')}</td>
              <td className={styles.genderTd}>{spouse?.gender ? GENDER[spouse.gender] : displayFormat()}</td>
              <td className={styles.taxLawTd}>{spouse?.thisYear?.dependentTaxLaw ? '○' : '×'}</td>
              <td className={styles.taxLawTd}>{spouse?.nextYear?.dependentTaxLaw ? '○' : '×'}</td>
            </tr>
          )}
          {profile.otherFamilies.map((otherFamily, index) => (
            <OtherFamilyRow key={index} otherFamily={otherFamily} />
          ))}
        </tbody>
      </table>
    </div>
  );
};
