import { yup } from '@jbc-year-end-adj/common/yup';

export type Schema = {
  subject?: string;
  body?: string;
};

export const schema = yup.object({
  subject: yup.string().test('oneOfRequired', '件名またはカスタマイズ文を入力してください', function() {
    return this.parent.subject || this.parent.body;
  }),
  body: yup.string().test('oneOfRequired', '件名またはカスタマイズ文を入力してください', function() {
    return this.parent.subject || this.parent.body;
  })
});
