import { yup } from '@jbc-year-end-adj/common/yup';

export const schema = yup.object({
  reservedTime: yup.object({
    date: yup
      .string()
      .required()
      .matches(/^[0-9]{4}\/[0-9]{2}\/[0-9]{2}$/, 'YYYY/MM/DDの形式で入力してください。')
      .label('日付'),
    time: yup
      .string()
      .required()
      .label('時刻')
  }),
  isSentMail: yup
    .boolean()
    .nullable()
    .label('メール送信')
});
