import { FC, useState } from 'react';
import Button from 'jbc-front/components/Button';

import { Section } from '@jbc-year-end-adj/2024/components/ui/Section';
import { Question } from '@jbc-year-end-adj/2024/features/questions/types';
import { TITLE, CheckWorkingStudent, DetailInput } from '@jbc-year-end-adj/2024/features/questions/templates/WorkingStudent';
import { ButtonContainer } from '@jbc-year-end-adj/2024/features/questions/components/ButtonContainer';
import { Content } from '@jbc-year-end-adj/2024/features/questions/templates/parts/Content';

import { EditModal } from '../EditModal';
import { EmptyBody } from '../EmptyBody';
import { filteredDescritpions } from './utils';
import styles from './Questions.scss';

type WorkingStudentProps = {
  question: Question;
};

export const WorkingStudent: FC<WorkingStudentProps> = ({ question }) => {
  const [isShow, setIsShow] = useState(true);
  const descriptions = filteredDescritpions(question, ['under_note']);

  return (
    <Section>
      <Section.Header className={styles.header}>
        {TITLE}
        <EditModal questionId={String(question.id)} descriptions={descriptions} />
      </Section.Header>
      <Section.Body>
        <Content>
          <CheckWorkingStudent question={question}>
            <ButtonContainer>
              <Button huge primary={isShow} onClick={() => setIsShow(true)}>
                はい
              </Button>
              <Button huge primary={!isShow} onClick={() => setIsShow(false)}>
                いいえ
              </Button>
            </ButtonContainer>
          </CheckWorkingStudent>

          {isShow && (
            <DetailInput>
              <EmptyBody />
              <ButtonContainer>
                <Button primary disabled>
                  次へ
                </Button>
              </ButtonContainer>
            </DetailInput>
          )}
        </Content>
      </Section.Body>
    </Section>
  );
};
