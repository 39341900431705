import { FC } from 'react';
import { useQuery, gql } from '@apollo/client';

import { Section } from 'components/ui/Section';
import { FormSection } from 'components/form/FormSection';
import { Radio } from 'components/form/Radio';
import { Textarea } from 'components/form/Textarea';
import { FileField } from 'components/form/FileField';
import { FilePreview } from 'components/feature/FilePreview';
import { DifferentAddress } from './DifferentAddress';
import { DifferentAndForeignAddress } from './DifferentAndForeignAddress';
import { Note } from 'jbc-front/components/presenters/ui/Note';
import { useYear } from 'hooks/useYear';
import { DependentYearlyInfo } from '../../../../query';

const IMAGE_SETTINGS = gql`
  query imageSettings($year: Int!) {
    client {
      clientYearly(year: $year) {
        clientSetting {
          id
          requestImageUploadSettings {
            handicap
          }
        }
      }
    }
  }
`;

type ThisYearProps = {
  thisYear?: DependentYearlyInfo;
};

export const ThisYear: FC<ThisYearProps> = ({ thisYear }) => {
  const year = useYear();
  const { data, loading } = useQuery(IMAGE_SETTINGS, { variables: { year } });
  if (loading) return null;
  const {
    client: {
      clientYearly: {
        clientSetting: {
          requestImageUploadSettings: { handicap: handicapImageSetting }
        }
      }
    }
  } = data;

  return (
    <Section>
      <Section.Header>今年の扶養情報</Section.Header>
      <Section.Body>
        <FormSection>
          <Radio.Container label="今年の税の扶養対象" note="今年の給与所得者の扶養控除等(異動)申告書に記載されます。" required>
            <Radio checked={thisYear && thisYear.dependentTaxLaw} disabled>
              対象
            </Radio>
            <Radio checked={thisYear && !thisYear.dependentTaxLaw} disabled>
              対象外
            </Radio>
          </Radio.Container>

          {thisYear && !thisYear.dependentTaxLaw && (
            <>
              <Radio.Container label="今年の税の扶養対象ではないが、所得金額調整控除の対象、退職手当を有する扶養親族" required>
                <Radio checked={thisYear.incomeAdjustmentDeduction} disabled>
                  対象
                </Radio>
                <Radio checked={!thisYear.incomeAdjustmentDeduction} disabled>
                  対象外
                </Radio>
              </Radio.Container>
              <Note>
                同一生計内の他の所得者に扶養される年齢23歳未満、もしくは特別障害者に該当する扶養親族は、
                本人の収入が850万円を超えている場合に所得金額調整控除の対象になります。詳しくは
                <a
                  className="u-txt-link"
                  href="https://www.nta.go.jp/taxes/shiraberu/taxanswer/shotoku/1411.htm"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  国税庁HP
                </a>
                をご覧ください。
                <br />
                扶養控除等(異動)申告書の「退職手当等を有する配偶者・扶養親族」に反映されます。
                所得税の計算上では扶養対象外でも住民税の計算上は扶養対象となる場合があります。
              </Note>
            </>
          )}

          {thisYear && (thisYear.dependentTaxLaw || thisYear.incomeAdjustmentDeduction) && (
            <>
              <Radio.Container label="同居・別居" required>
                <Radio checked={thisYear.residenceStatus === 'same_address'} disabled>
                  同居
                </Radio>
                <Radio checked={thisYear.residenceStatus === 'different_address'} disabled>
                  別居（国内）
                </Radio>
                <Radio checked={thisYear.residenceStatus === 'different_and_foreign_address'} disabled>
                  別居（国外）
                </Radio>
              </Radio.Container>

              {thisYear.residenceStatus === 'different_address' && <DifferentAddress yearlyInfo={thisYear} />}
              {thisYear.residenceStatus === 'different_and_foreign_address' && <DifferentAndForeignAddress yearlyInfo={thisYear} />}

              <Radio.Container label="障害者区分" required>
                <Radio checked={thisYear.handicapType === 'none'} disabled>
                  対象外
                </Radio>
                <Radio checked={thisYear.handicapType === 'normal'} disabled>
                  一般障害者
                </Radio>
                <Radio checked={thisYear.handicapType === 'special'} disabled>
                  特別障害者
                </Radio>
              </Radio.Container>

              {(thisYear.handicapType === 'normal' || thisYear.handicapType === 'special') && (
                <>
                  <Textarea label="詳細" value={thisYear.handicapDetail} disabled />

                  {handicapImageSetting !== 'none' && (
                    <FileField label="障害者手帳画像" required={handicapImageSetting === 'required'} disabled>
                      {thisYear.handicapImage && (
                        <FileField.FileContainer>
                          <FileField.File file={thisYear.handicapImage} />
                          <FilePreview file={thisYear.handicapImage} />
                        </FileField.FileContainer>
                      )}
                    </FileField>
                  )}
                </>
              )}
            </>
          )}
        </FormSection>
      </Section.Body>
    </Section>
  );
};
