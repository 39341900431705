import React from 'react';
import { useDispatch } from 'react-redux';
import { submit } from 'redux-form';
import { SearchResult } from 'jbc-front/components/SearchForm';
import Paginator from 'jbc-front/components/Paginator';
import useReactRouter from 'use-react-router';

import { useFormValue, useAutofill, useFormName } from '../FormName/FormNameProvider';
import { useSession } from '../AdminSessionProvider';
import { getSavedDisplayEmployeeLimit } from '@jbc-year-end-adj/2024/utils/employeeForm';
import styles from './PaginatorWithResult.scss';

export default ({ count, isLoading, left, center, failureMessage = '従業員が見つかりませんでした' }) => {
  const { autofill } = useAutofill();
  const { formName } = useFormName();
  const dispatch = useDispatch();
  const page = useFormValue('page');
  const { location } = useReactRouter();
  const { me } = useSession();
  const limit = getSavedDisplayEmployeeLimit(location.pathname, me);
  const handlePagination = page => {
    autofill('page', page.toString());
    dispatch(submit(formName));
  };
  const searchResultProps = {
    className: 'u-ta-r',
    total: count,
    page,
    limit,
    isLoading,
    failureMessage
  };
  const paginatorProps = {
    current: page,
    totalResult: count,
    rowsPerPage: limit,
    pageLinks: 7,
    onClick: handlePagination
  };

  if (count <= 0) return null;

  return (
    <div className={styles.paginatorWithResult}>
      {center ? (
        <>
          <div className={styles.row}>
            <div>{left}</div>
            <div>{center}</div>
            <SearchResult {...searchResultProps} />
          </div>
          <div className="u-mt30">
            <Paginator {...paginatorProps} />
          </div>
        </>
      ) : (
        <div className={styles.row}>
          <div>{left}</div>
          <div>
            <Paginator {...paginatorProps} />
          </div>
          <SearchResult {...searchResultProps} />
        </div>
      )}
    </div>
  );
};
