import React, { useMemo, useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import Button from 'jbc-front/components/Button';
import { RadioField, SelectField, CheckboxField } from 'jbc-front/components/Form';
import {
  useMutation,
  Modal,
  useYear,
  useSession,
  useReportList,
  useQuery,
  Loading,
  useFormValue,
  reduxForm,
  useAutofill
} from '../components';
import styles from '../pages/Result.scss';
import _ from 'lodash';
import moment from 'moment';
import useReactRouter from 'use-react-router';
import { parse, stringify } from 'query-string';

const BULK_RESULT_FRAGMENT = gql`
  fragment ReportDetail on ClientReport {
    id
    generatedAt
    status
    showMyNumber
  }
  fragment BulkReports on ClientYearly {
    id
    bulkWithholdingBookReport {
      ...ReportDetail
    }
    bulkDependentDeductionThisYearReport {
      ...ReportDetail
    }
    bulkDependentDeductionNextYearReport {
      ...ReportDetail
    }
    bulkSpouseDeductionReport {
      ...ReportDetail
    }
    bulkInsuranceDeductionReport {
      ...ReportDetail
    }
    bulkBsiDeductionReport {
      ...ReportDetail
    }
    bulkFileMountReport {
      ...ReportDetail
    }
  }
`;

const BULK_RESULT_REPORT = gql`
  query BulkResultReport($year: Int!) {
    client {
      id
      clientYearly(year: $year) {
        ...BulkReports
      }
    }
  }
  ${BULK_RESULT_FRAGMENT}
`;

const MAKE_BULK_RESULT_REPORT = gql`
  mutation makeBulkResultReport($input: MakeBulkResultReportInput!) {
    makeBulkResultReport(input: $input) {
      clientYearly {
        ...BulkReports
      }
    }
  }
  ${BULK_RESULT_FRAGMENT}
`;

const targetOptions = [
  { value: 'all', label: '全従業員' },
  { value: 'search', label: '検索絞込中の従業員' }
];

const FormLabel = ({ children }) => <div className={styles.bulkModalFormLabel}>{children}</div>;
const Label = ({ children }) => <div className={styles.bulkModalLabel}>{children}</div>;

const reportTypes = year => [
  ...(year >= WITHHOLDING_BOOK_YEAR ? ['withholding_book'] : []),
  'dependent_deduction_this_year',
  'dependent_deduction_next_year',
  year >= 2020 ? 'bsi_deduction' : 'spouse_deduction',
  'insurance_deduction',
  'file_mount'
];

const WITHHOLDING_BOOK_YEAR = 2019;

const Form =
  (({ handleSubmit, hideModal, bulkReports }) => {
    const year = useYear();
    const reports = useReportList(year);
    const reportOptions = reports
      ? reportTypes(year).map(reportName => ({ label: reports[_.camelCase(reportName)].title, value: reportName }))
      : [];

    const reportType = useFormValue('reportType');
    const bulkReport = reportType && bulkReports[_.camelCase(`bulk_${reportType}_report`)];
    const reportMaster = reportType && reports[_.camelCase(reportType)];
    const inProgress = bulkReport && (bulkReport.status === 'waiting' || bulkReport.status === 'in_progress');
    const { autofill } = useAutofill();
    useEffect(() => {
      autofill('showMyNumber', bulkReport ? bulkReport.showMyNumber : false);
    }, [bulkReport, autofill]);
    const { me } = useSession();
    const hasNoMyNumberPermission = bulkReport?.showMyNumber && !me.hasMyNumberPermission;
    return (
      <>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <SelectField name="reportType" options={reportOptions} required label="ダウンロードしたい帳票" />
            <RadioField name="target" options={targetOptions} label="ダウンロード対象選択" />
            {reportMaster?.hasMyNumber && <CheckboxField label="マイナンバーを出力する" name="showMyNumber" />}
          </form>
          {bulkReport && (
            <>
              <FormLabel>直近に作成したこの帳票のファイル</FormLabel>
              <div className={styles.downloadReport}>
                {inProgress ? (
                  <>
                    作成中 <Loading className={styles.loading} />
                  </>
                ) : bulkReport.status === 'failed' ? (
                  '作成が失敗しました'
                ) : (
                  <>
                    {hasNoMyNumberPermission ? (
                      <>{reportMaster?.title}ダウンロード</>
                    ) : (
                      <a
                        href={`/report?report_type=bulk_${reportType}_report&disposition=attachment&year=${year}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="u-txt-link"
                      >
                        {reportMaster?.title}ダウンロード
                      </a>
                    )}
                    （{moment(bulkReport.generatedAt).format('YYYY/MM/DD HH:mm')}作成）
                    {hasNoMyNumberPermission && (
                      <>
                        <br />
                        ※マイナンバーが出力された帳票を閲覧する権限がありません。
                      </>
                    )}
                  </>
                )}
              </div>
            </>
          )}
          <Label>作成が完了しましたらメールにてお送りいたします。</Label>
          <ul>
            <li>※従業員数によっては時間がかかる場合がございます。予めご了承ください。</li>
            <li>※ファイルは作成日から1ヶ月間サーバーに保存されます。</li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Buttons>
            <Button onClick={hideModal} disabled={false}>
              キャンセル
            </Button>
            <Button primary onClick={handleSubmit} disabled={inProgress} className={styles.bulkButton}>
              作成
            </Button>
          </Modal.Buttons>
        </Modal.Footer>
      </>
    );
  })
  |> reduxForm({
    form: 'downloadReports'
  });

const ConfirmBeforeFixedForm = ({ hideModal, onConfirm }) => {
  return (
    <>
      <Modal.Body>
        帳票は確定前の状態です。
        <br />
        ダウンロードしますか？
      </Modal.Body>
      <Modal.Footer>
        <Modal.Buttons>
          <Button primary onClick={onConfirm}>
            はい
          </Button>
          <Button onClick={hideModal}>いいえ</Button>
        </Modal.Buttons>
      </Modal.Footer>
    </>
  );
};

const BulkDownload = ({ search }) => {
  const { clientYearly } = useSession();
  const [makeBulkResultReport] = useMutation(MAKE_BULK_RESULT_REPORT);
  const year = useYear();
  const { data, startPolling, stopPolling } = useQuery(BULK_RESULT_REPORT, {
    variables: { year },
    fetchPolicy: 'network-only'
  });
  const {
    history,
    location: { pathname, search: querySearch }
  } = useReactRouter();
  const { bulk_report: showBulkReport } = parse(querySearch);
  const [isOpen, setIsOpen] = useState(!!showBulkReport);
  const [isConfirmedBeforeFixed, setIsConfirmedBeforeFixed] = useState(false);
  const hideModal = () => {
    setIsOpen(false);
    setIsConfirmedBeforeFixed(false);
    if (showBulkReport) {
      history.replace({ pathname, search: stringify(_.omit(parse(querySearch), 'bulk_report')) });
    }
  };
  const bulkReports = data?.client?.clientYearly || {};
  const polling = useMemo(
    () =>
      bulkReports &&
      reportTypes(year).some(reportType => {
        const status = bulkReports[_.camelCase(`bulk_${reportType}_report`)]?.status;
        return status === 'waiting' || status === 'in_progress';
      }),
    [bulkReports, year]
  );
  useEffect(() => {
    if (polling) {
      startPolling(3000);
      return () => {
        stopPolling();
      };
    }
    return undefined;
  });
  return (
    <>
      <Button primary onClick={() => setIsOpen(true)}>
        帳票一括ダウンロード
      </Button>
      <Modal.Modal isOpen={isOpen}>
        <Modal.Header hideModal={hideModal}>帳票一括ダウンロード</Modal.Header>
        {clientYearly.fixed || isConfirmedBeforeFixed ? (
          <Form
            hideModal={hideModal}
            initialValues={{ target: 'all', reportType: showBulkReport }}
            bulkReports={bulkReports}
            onSubmit={async ({ target, reportType, showMyNumber }) => {
              await makeBulkResultReport({
                variables: { input: { year, reportType, showMyNumber, search: target === 'all' ? null : search } }
              });
            }}
          />
        ) : (
          <ConfirmBeforeFixedForm hideModal={hideModal} onConfirm={() => setIsConfirmedBeforeFixed(true)} />
        )}
      </Modal.Modal>
    </>
  );
};

export default BulkDownload;
