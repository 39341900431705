import { FC, ReactNode, createContext, useContext } from 'react';
import styles from './AsyncTask.scss';
import { Close } from 'jbc-front/components/icons';

type AsyncTaskLoadingContextType = {
  error: boolean;
  done: boolean;
};

const AsyncTaskLoadingContext = createContext<AsyncTaskLoadingContextType>({
  error: false,
  done: false
});

type AsyncTaskLoadingProps = {
  error: boolean;
  done: boolean;
  children: ReactNode;
};

const Container: FC<AsyncTaskLoadingProps> = ({ error, done, children }) => {
  return (
    <AsyncTaskLoadingContext.Provider value={{ error, done }}>
      <div className={styles.wrap}>
        <div className={styles.message}>{children}</div>
      </div>
    </AsyncTaskLoadingContext.Provider>
  );
};

type LoadingProps = {
  children: ReactNode;
};

const Loading: FC<LoadingProps> = ({ children }) => {
  const { error, done } = useContext(AsyncTaskLoadingContext);
  return (
    <div className={error ? styles.circleError : done ? styles.circleDone : styles.circle}>
      <div className="sk-circle1 sk-child" />
      <div className="sk-circle2 sk-child" />
      <div className="sk-circle3 sk-child" />
      <div className="sk-circle4 sk-child" />
      <div className="sk-circle5 sk-child" />
      <div className="sk-circle6 sk-child" />
      <div className="sk-circle7 sk-child" />
      <div className="sk-circle8 sk-child" />
      <div className="sk-circle9 sk-child" />
      <div className="sk-circle10 sk-child" />
      <div className="sk-circle11 sk-child" />
      <div className="sk-circle12 sk-child" />
      <span className={styles.text}>{children}</span>
    </div>
  );
};

type CloseButtonProps = {
  onClose: () => void;
};

const CloseButton: FC<CloseButtonProps> = ({ onClose }) => {
  const { error, done } = useContext(AsyncTaskLoadingContext);
  return (
    <>
      {(error || done) && (
        <a className={styles.close} onClick={onClose} role="link" tabIndex={0}>
          <Close />
        </a>
      )}
    </>
  );
};

export const AsyncTaskLoading = { Container, Loading, CloseButton };
