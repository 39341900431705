import { FC } from 'react';
import { Section } from 'components/ui/Section';
import { Dl, Dt, Dd, FileDd, FileDl } from '../../../../components/DefinitionList';
import { Grid } from '../../../../components/Grid';
import { PreviewSubSection } from '../../../../components/PreviewSubSection';
import { useEmployeeInfo } from '../../EmployeeInfoProvider';
import { amountFormat } from '@jbc-year-end-adj/common/utils/formatter';

export const SocialInsurance: FC = () => {
  const { employee } = useEmployeeInfo();
  const profile = employee.profile;

  const insuranceTypeFormat = (value?: string) => {
    switch (value) {
      case 'national_pension':
        return '国民年金';
      case 'national_insurance':
        return '国民年金基金';
      case 'national_health_insurance':
        return '国民健康保険';
      case 'elderly_medical_insurance':
        return '後期高齢者医療保険';
      case 'national_nursing_medical_insurance':
        return '介護保険';
      case 'voluntary_continued_insurance':
        return '任意継続保険';
      case 'farmers_pension':
        return '農業者年金';
      default:
        return undefined;
    }
  };

  return (
    <Section>
      <Section.Header>社会保険</Section.Header>
      <Section.Body>
        <Grid colGap>
          {profile.socialInsurances.length === 0 && <p>社会保険情報はありません。</p>}
          {profile.socialInsurances.map((insurance, index) => (
            <PreviewSubSection key={index}>
              <PreviewSubSection.Header>社会保険（{index + 1}）</PreviewSubSection.Header>
              <PreviewSubSection.Body>
                <Dl>
                  <Dt>社会保険の種類</Dt>
                  <Dd>{insuranceTypeFormat(insurance.insuranceType)}</Dd>
                </Dl>

                <Dl>
                  <Dt>保険料支払先の名称</Dt>
                  <Dd>{insurance.name}</Dd>
                </Dl>

                <Dl>
                  <Dt>保険料を負担することになっている人 氏名</Dt>
                  <Dd>{insurance.nameOfPayer}</Dd>
                </Dl>

                <Dl>
                  <Dt>保険料を負担することになっている人 続柄</Dt>
                  <Dd>{insurance.relationshipOfPayer}</Dd>
                </Dl>

                <Dl>
                  <Dt>本年中に支払った金額</Dt>
                  <Dd>{amountFormat(insurance.amount)}</Dd>
                </Dl>

                <FileDl>
                  <Dt>社会保険の証明書画像</Dt>
                  <FileDd file={insurance.image} />
                </FileDl>
              </PreviewSubSection.Body>
            </PreviewSubSection>
          ))}
        </Grid>
      </Section.Body>
    </Section>
  );
};
