import React from 'react'
import Icon from 'react-icon-base'

const YearEndLine = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="iconYearEnd" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path d="M11.5,1 C11.7761424,1 12,1.22385763 12,1.5 L12,4 L28,4 L28,1.5 C28,1.22385763 28.2238576,1 28.5,1 L30.5,1 C30.7761424,1 31,1.22385763 31,1.5 L31,4 L38,4 C39.1045695,4 40,4.8954305 40,6 L40,35 C40,36.1045695 39.1045695,37 38,37 L2,37 C0.8954305,37 0,36.1045695 0,35 L0,6 C0,4.8954305 0.8954305,4 2,4 L9,4 L9,1.5 C9,1.22385763 9.22385763,1 9.5,1 L11.5,1 Z M38,15 L2,15 L2,34.8 C2,34.9104569 2.08954305,35 2.2,35 L37.8,35 C37.9104569,35 38,34.9104569 38,34.8 L38,15 Z M23.1485152,20.42832 L24.5711468,21.8340652 L19.0442607,27.4273423 L14.7117786,23.2305968 L16.1033097,21.7940589 L19.013,24.613 L23.1485152,20.42832 Z M9,6 L2.2,6 C2.08954305,6 2,6.08954305 2,6.2 L2,13 L38,13 L38,6.2 C38,6.08954305 37.9104569,6 37.8,6 L31,6 L31,8.5 C31,8.77614237 30.7761424,9 30.5,9 L28.5,9 C28.2238576,9 28,8.77614237 28,8.5 L28,6 L12,6 L12,8.5 C12,8.77614237 11.7761424,9 11.5,9 L9.5,9 C9.22385763,9 9,8.77614237 9,8.5 L9,6 Z" id="Combined-Shape" fill={props.color} fillRule="nonzero"></path>
    </g>
  </Icon>
)
YearEndLine.defaultProps = {
  size: 40,
  color: "#3498DB"
}

export default YearEndLine
