import { FC, ReactNode } from 'react';
import { Section } from '@jbc-year-end-adj/2024/components/ui/Section';

import { Question } from '../types';
import { findDescriptions } from '../utils';

import { ToggleImageContainer } from './parts/ToggleImageContainer';
import { Lead } from './parts/Lead';
import { Description } from './parts/Description';
import { Content as ContentContainer } from './parts/Content';

// @ts-ignore
import image from '@jbc-year-end-adj/2024/images/img-Foundation_Exclusion_Form.png';

type AllIncomeProps = {
  question: Question;
  children: ReactNode;
  initiallyOpen?: boolean;
};

export const AllIncome: FC<AllIncomeProps> = ({ question, children, initiallyOpen = false }) => {
  return (
    <Section>
      <Section.Header>給与収入等（本人の収入合計金額）</Section.Header>

      <Section.Body>
        <Content question={question} initiallyOpen={initiallyOpen}>
          {children}
        </Content>
      </Section.Body>
    </Section>
  );
};

export const TITLE = '氏名・住所等';

type ContentProps = {
  question: Question;
  children: ReactNode;
  initiallyOpen?: boolean;
};

export const Content: FC<ContentProps> = ({ question, children, initiallyOpen = false }) => {
  const questionDescriptions = findDescriptions(question, ['top']);

  return (
    <ContentContainer>
      <Lead>各項目の収入の合計金額を入力してください。</Lead>

      <Description>{questionDescriptions['top']?.description}</Description>

      <ToggleImageContainer initiallyOpen={initiallyOpen}>
        <ToggleImageContainer.Header>「基礎控除申告書（赤枠）」に反映されます。</ToggleImageContainer.Header>
        <ToggleImageContainer.Body>
          <img src={image} alt="基配所_基礎控除申告書" />
        </ToggleImageContainer.Body>
      </ToggleImageContainer>

      {children}
    </ContentContainer>
  );
};
