import React from 'react'
import Icon from 'react-icon-base'

const CustomText = props => (
  <Icon viewBox="0 0 13 16" {...props}>
    <defs>
      <path id="a" d="M12.652 15.075V0H0v15.075z"/>
    </defs>
    <g fill="none">
      <mask id="b" fill="#fff">
        <path d="M12.652 15.075V0H0v15.075z"/>
      </mask>
      <path d="M12.485 0l.167 3.536h-.423c-.08-.623-.193-1.068-.333-1.335-.23-.43-.536-.746-.918-.951-.38-.203-.884-.305-1.506-.305H7.348v11.517c0 .927.1 1.505.301 1.735.281.311.715.467 1.3.467h.523v.411H3.08v-.411h.533c.638 0 1.09-.193 1.356-.579.163-.236.245-.777.245-1.623V.945H3.402c-.704 0-1.205.052-1.5.156-.386.141-.716.411-.99.811-.275.4-.438.942-.49 1.624H0L.177 0h12.308z" fill="currentColor"/>
    </g>
  </Icon>
)
CustomText.defaultProps = {
  size: 16
}

export default CustomText
