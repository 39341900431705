import React from 'react'
import Icon from 'react-icon-base'

const Leave = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="symbol" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinejoin="round">
      <g id="icon_leave" stroke="currentColor" strokeWidth="2.5">
        <g id="Page-1" transform="translate(2.933333, 4.000000)">
          <path d="M23.4322526,9.01094737 C23.4322526,4.36084211 19.6613053,0.589894737 15.0112,0.589894737 C10.3610947,0.589894737 6.59014737,4.36084211 6.59014737,9.01094737 C6.59014737,13.6610526 10.3610947,17.432 15.0112,17.432 C19.6613053,17.432 23.4322526,13.6610526 23.4322526,9.01094737 Z" id="Stroke-1" strokeLinecap="round"></path>
          <path d="M23.4997053,20.1943579 C21.1182316,18.4570947 18.1843368,17.4322526 15.0112842,17.4322526 C7.04749474,17.4322526 0.590231579,23.8895158 0.590231579,31.8533053" id="Stroke-3" strokeLinecap="round"></path>
          <polygon id="Rectangle" transform="translate(15.011200, 24.989344) rotate(45.000000) translate(-15.011200, -24.989344) " points="10.1576466 20.1357908 18.4505398 22.2571112 19.8647534 29.8428976 12.278967 28.4286841"></polygon>
          <path d="M23.7521684,27 L34.1058526,27" id="Stroke-5" strokeLinecap="round"></path>
          <path d="M34.1058526,27 L30.1058526,22.6463158" id="Stroke-7" strokeLinecap="round"></path>
          <path d="M34.1058526,31.3536842 L30.1058526,27" id="Stroke-7" strokeLinecap="round" transform="translate(32.105853, 29.176842) scale(1, -1) translate(-32.105853, -29.176842) "></path>
        </g>
      </g>
    </g>
  </Icon>
)
Leave.defaultProps = {
  size: 40
}

export default Leave
