import { FC } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';

import Button from 'jbc-front/components/Button';
import { ButtonRow } from 'jbc-front/components/presenters/layout/ButtonRow';

import { FormSection } from 'components/form/FormSection';
import { Radio } from 'components/form/Radio';
import { Textarea } from 'components/form/Textarea';
import { FileField } from 'components/react-hook-form/FileField';

import { Grid } from '../../../../components/Grid';
import { FormSeparator } from '../../../../components/FormSeparator';
import { FETCH_EMPLOYEE } from '../../../../query';
import { FloatingButton } from '../../../../components/FloatingButton';
import { useResult } from '../../ResultProvider';
import { Schema, schema, generateDefaultValues } from './schema';
import { UPDATE_REQUEST_TAX_CLASSIFICATIONS, convertFormDataToQueryVariables } from './mutation';

// @ts-ignore
import { useNotify } from 'actions';

// @ts-ignore
import { useMutation } from 'components/Graphql';
import { FilePreview } from 'components/feature/FilePreview';
import { AmountField } from 'components/react-hook-form/AmountField';

type FormProps = {
  onCancel: () => void;
};

export const Form: FC<FormProps> = ({ onCancel }) => {
  const { employee } = useResult();
  const requestProfile = employee.request?.profile;
  const [update, { loading }] = useMutation(UPDATE_REQUEST_TAX_CLASSIFICATIONS);
  const notify = useNotify();

  const methods = useForm<Schema>({
    defaultValues: generateDefaultValues(requestProfile),
    resolver: yupResolver(schema),
    mode: 'onBlur'
  });

  const {
    formState: { errors },
    handleSubmit,
    register,
    watch,
    setValue
  } = methods;

  const onSubmit: SubmitHandler<Schema> = async (data: Schema) => {
    const variables = convertFormDataToQueryVariables(String(employee.id), data);

    await update({
      variables,
      refetchQueries: [FETCH_EMPLOYEE]
    });
    onCancel();
    notify('税区分情報を保存しました。', 'success');
  };

  const isWorkingStudent = watch('workingStudentClassification.classification') === 'working_student';
  const isHandicap = watch('handicapClassification.classification') !== 'none';

  const workingStudentClassificationImage = watch('workingStudentClassification.image');
  const handicapClassificationImage = watch('handicapClassification.image');

  const handleRemoveFile = (key: 'workingStudentClassification.image' | 'handicapClassification.image') => {
    setValue(key, undefined);
  };

  return (
    <FormProvider {...methods}>
      <form>
        <FormSection>
          <Grid colGap>
            <Radio.Container label="今年の税区分" required>
              <Radio value="first" {...register('salaryMoreThanOthers')}>
                甲欄
              </Radio>
              <Radio value="second" {...register('salaryMoreThanOthers')}>
                乙欄
              </Radio>
            </Radio.Container>

            <Radio.Container label="来年の税区分" required>
              <Radio value="first" {...register('salaryLessThanOthersNextYear')}>
                甲欄
              </Radio>
              <Radio value="second" {...register('salaryLessThanOthersNextYear')}>
                乙欄
              </Radio>
            </Radio.Container>
          </Grid>

          <FormSeparator />

          <Grid colGap>
            <Radio.Container label="勤労学生" required>
              <Radio value="none" {...register('workingStudentClassification.classification')}>
                対象外
              </Radio>
              <Radio value="working_student" {...register('workingStudentClassification.classification')}>
                勤労学生
              </Radio>
            </Radio.Container>
            {isWorkingStudent && (
              <>
                <Textarea
                  label="詳細"
                  rows={3}
                  {...register('workingStudentClassification.detail')}
                  error={errors.workingStudentClassification?.detail?.message}
                  isError={!!errors.workingStudentClassification?.detail?.message}
                />

                <FileField label="学生手帳画像" name={'workingStudentClassification.image'}>
                  {workingStudentClassificationImage && (
                    <FileField.FileContainer>
                      <FileField.File
                        file={workingStudentClassificationImage}
                        onRemove={() => handleRemoveFile('workingStudentClassification.image')}
                      />
                      <FilePreview file={workingStudentClassificationImage} />
                    </FileField.FileContainer>
                  )}
                </FileField>
              </>
            )}
          </Grid>

          <FormSeparator />

          <Grid colGap>
            <Radio.Container label="障害者区分" required>
              <Radio value="none" {...register('handicapClassification.classification')}>
                対象外
              </Radio>
              <Radio value="normal" {...register('handicapClassification.classification')}>
                一般障害者
              </Radio>
              <Radio value="special" {...register('handicapClassification.classification')}>
                特別障害者
              </Radio>
            </Radio.Container>
            {isHandicap && (
              <>
                <Textarea
                  label="詳細"
                  rows={3}
                  {...register('handicapClassification.detail')}
                  error={errors.handicapClassification?.detail?.message}
                  isError={!!errors.handicapClassification?.detail?.message}
                />

                <FileField label="障害者手帳画像" name={'handicapClassification.image'}>
                  {handicapClassificationImage && (
                    <FileField.FileContainer>
                      <FileField.File
                        file={handicapClassificationImage}
                        onRemove={() => handleRemoveFile('handicapClassification.image')}
                      />
                      <FilePreview file={handicapClassificationImage} />
                    </FileField.FileContainer>
                  )}
                </FileField>
              </>
            )}
          </Grid>

          <FormSeparator />

          <Grid colGap>
            <Radio.Container label="ひとり親・寡婦区分" required>
              <Radio value="none" {...register('widowClassification.classification')}>
                対象外
              </Radio>
              <Radio value="single_parent" {...register('widowClassification.classification')}>
                ひとり親
              </Radio>
              <Radio value="normal" {...register('widowClassification.classification')}>
                寡婦
              </Radio>
            </Radio.Container>
          </Grid>

          <FormSeparator />

          <Grid colGap>
            <Radio.Container
              label="（源泉徴収票）災害者"
              required
              note="給与所得者が災害減免法により源泉所得税及び復興特別所得税の徴収猶予又は還付を受けた場合は年末調整の対象外になります。"
              noteMaxLength={19}
            >
              <Radio value="notDisasterSufferer" {...register('isDisasterSufferer')}>
                対象外
              </Radio>
              <Radio value="disasterSufferer" {...register('isDisasterSufferer')}>
                災害者
              </Radio>
            </Radio.Container>

            {watch('isDisasterSufferer') === 'disasterSufferer' && (
              <AmountField
                label="徴収猶予税額"
                required
                error={errors.taxPostponementAmount?.message}
                isError={!!errors.taxPostponementAmount?.message}
                {...register('taxPostponementAmount')}
              />
            )}
          </Grid>

          <FormSeparator />

          <FloatingButton>
            <ButtonRow>
              <Button onClick={onCancel} disabled={loading}>
                キャンセル
              </Button>
              <Button onClick={handleSubmit(onSubmit)} disabled={loading} primary>
                保存
              </Button>
            </ButtonRow>
          </FloatingButton>
        </FormSection>
      </form>
    </FormProvider>
  );
};
