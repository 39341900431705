import React from 'react';
import { gql, useApolloClient } from '@apollo/client';
import _ from 'lodash';
import fp from 'lodash/fp';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { compose, withStateHandlers } from 'recompose';
import { useQuery, useMutation, useYear, DropDownMenu, useSession } from '../components';
import recordDisplay, { numberWithSplit } from '../utils/recordDisplay';
import { Pulldown } from 'jbc-front/components/icons';
import Button from 'jbc-front/components/Button';
import ActionButton from 'jbc-front/components/ActionButton';
import { statuses } from './Top';
import {
  saveDisplayEmployeeLimit,
  getSavedDisplayEmployeeLimit,
  getCurrentQueryFromLocation,
  convertQueryToForm,
  convertFormToGraphQl
} from '../employees/utils';
import styles from './TaxOffice.scss';
import DownloadWithholdSlips from '../components/DownloadWithholdSlips';
import searchForm from '../employees/SearchForm';
import { FormNameProvider } from '../components/FormName';
import PaginatorWithResult from '../employees/PaginatorWithResult';
import LoadingPage from '../components/LoadingPage';
import { saveAs } from 'file-saver';
import { toBlob } from '../utils/url';
import { PayrollReportModal } from '../components/PayrollReportModal';

const searchFormName = 'employeeSearchTaxOffice';
const SearchForm = searchForm(searchFormName);

const WITHHOLDING_SLIP_FOR_TAX_OFFICE = gql`
  query withholdingSlipForTaxOffice($year: Int!) {
    client {
      id
      clientYearly(year: $year) {
        id
        withholdingSlipForTaxOffice {
          id
          status
          generatedAt
          showMyNumber
        }
      }
    }
  }
`;

const MAKE_WITHHOLDING_SLIP_FOR_TAX_OFFICE = gql`
  mutation makeWithholdingSlipForTaxOffice($showMyNumber: Boolean, $year: Int!) {
    makeWithholdingSlipForTaxOffice(input: { showMyNumber: $showMyNumber, year: $year }) {
      clientYearly {
        id
        withholdingSlipForTaxOffice {
          id
          status
          generatedAt
          showMyNumber
        }
      }
    }
  }
`;

const EMPLOYEES = gql`
  query employees($per: Int, $page: Int, $search: EmployeeSearchInput, $year: Int!) {
    client {
      id
      clientYearly(year: $year) {
        id
        employees(per: $per, page: $page, search: $search) {
          totalCount
          list {
            id
            employmentType
            staffCode
            status
            resignedOn
            taxSchedule
            profile {
              id
              fullName
              isDisasterSufferer
            }
            withholdingSlip {
              id
              totalAmountPaid
              needSendToTaxOffice
            }
          }
        }
      }
    }
  }
`;

const SET_NEED_SEND_TO_TAX_OFFICE = gql`
  mutation setNeedSendToTaxOffice($input: SetNeedSendToTaxOfficeInput!) {
    setNeedSendToTaxOffice(input: $input) {
      withholdingSlip {
        id
        needSendToTaxOffice
      }
    }
  }
`;

const EmployeeList = ({ location: { pathname, search }, me }) => {
  const formValues = convertQueryToForm(getCurrentQueryFromLocation({ search, pathname }, me));
  const year = useYear();
  const variables = convertFormToGraphQl(formValues) |> fp.set('search.hasPaymentOnly', true) |> fp.set('year', year);
  const { loading, data } = useQuery(EMPLOYEES, {
    fetchPolicy: 'network-only',
    variables
  });
  const [setNeedSendToTaxOffice] = useMutation(SET_NEED_SEND_TO_TAX_OFFICE);
  const { totalCount, list } = data?.client?.clientYearly?.employees || {};
  return (
    <>
      <SearchForm query={EMPLOYEES} variables={variables} />
      {loading ? (
        <LoadingPage />
      ) : (
        <div className="l-overflow-scroll">
          <table className={`m-table-list ${styles.taxOfficeTable}`}>
            <thead>
              <tr>
                <th width={105}>受給者番号</th>
                <th width={170}>氏名</th>
                <th width={105}>雇用形態</th>
                <th width={105}>支払金額</th>
                <th width={85}>年末調整</th>
                <th width={105}>退職日</th>
                <th width={85}>税額表区分</th>
                <th width={85}>災害者区分</th>
                <th width={105}>税務署提出対象</th>
              </tr>
            </thead>
            <tbody>
              {list.length > 0
                ? list.map(
                    ({
                      id,
                      employmentType,
                      staffCode,
                      status,
                      resignedOn,
                      taxSchedule,
                      profile: { fullName, isDisasterSufferer },
                      withholdingSlip: { id: withholdingSlipId, totalAmountPaid, needSendToTaxOffice }
                    }) => {
                      const set = isSendToTaxOffice => () =>
                        setNeedSendToTaxOffice({
                          variables: {
                            input: {
                              withholdingSlipId,
                              needSendToTaxOffice: isSendToTaxOffice
                            }
                          },
                          optimisticResponse: {
                            setNeedSendToTaxOffice: {
                              __typename: 'SetNeedSendToTaxOfficePayload',
                              withholdingSlip: {
                                __typename: 'WithholdingSlip',
                                id: withholdingSlipId,
                                needSendToTaxOffice: isSendToTaxOffice
                              }
                            }
                          }
                        });
                      return (
                        <tr key={id}>
                          <td>{recordDisplay(staffCode)}</td>
                          <td>{recordDisplay(fullName)}</td>
                          <td>{recordDisplay(employmentType)}</td>
                          <td>{recordDisplay(numberWithSplit(totalAmountPaid))}</td>
                          <td>{recordDisplay(statuses[status])}</td>
                          <td>{recordDisplay.date(resignedOn, 'YYYY/MM/DD')}</td>
                          <td>{recordDisplay(taxSchedules[taxSchedule])}</td>
                          <td>{isDisasterSufferer ? '対象' : '対象外'}</td>
                          <td>
                            <DropDownMenu
                              toggle={setIsOpen => (
                                // eslint-disable-next-line
                                <div
                                  className={styles.dropDownMenuWrap}
                                  onClick={e => {
                                    setIsOpen(x => !x);
                                    e.stopPropagation();
                                  }}
                                >
                                  <p className={`${styles.beSubmitted} ${!needSendToTaxOffice ? styles.excludedFromSubmit : ''}`}>
                                    {needSendToTaxOffice ? '提出対象' : '対象外'}
                                  </p>
                                  <Pulldown />
                                </div>
                              )}
                            >
                              <DropDownMenu.MenuItem role="menuitem" onClick={set(true)} onKeyPress={set(true)}>
                                提出対象
                              </DropDownMenu.MenuItem>
                              <DropDownMenu.MenuItem role="menuitem" onClick={set(false)} onKeyPress={set(false)}>
                                対象外
                              </DropDownMenu.MenuItem>
                            </DropDownMenu>
                          </td>
                        </tr>
                      );
                    }
                  )
                : null}
            </tbody>
          </table>
          <FormNameProvider formName={searchFormName}>
            <PaginatorWithResult count={totalCount} />
          </FormNameProvider>
        </div>
      )}
    </>
  );
};

const withMe = WrappedComponent => () => {
  const { me } = useSession();
  return <WrappedComponent me={me} />;
};

const taxSchedules = { first: '甲欄', second: '乙欄' };

const TaxOffice = ({ isDescriptionOpen, setIsDescriptionOpen, selectedOption, changeSelectedOption, location, history, me }) => {
  const year = useYear();
  const client = useApolloClient();
  const { loading, data } = useQuery(WITHHOLDING_SLIP_FOR_TAX_OFFICE, {
    fetchPolicy: 'network-only',
    variables: { year }
  });
  const {
    me: { hasMyNumberPermission }
  } = useSession();
  if (loading) return <LoadingPage />;
  const {
    client: {
      clientYearly: { withholdingSlipForTaxOffice }
    }
  } = data;
  return (
    <div>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">源泉徴収票（税務署提出用）</h1>
      </div>
      <div className="l-contents-wrap">
        <div className="l-wrap-xl">
          <div className={styles.noticeWrap}>
            <p>源泉徴収票提出対象は以下の基準で決まります。ジョブカンから自動分類しましたが、誤りがある場合手動で変更してください。</p>
          </div>
          <div className={`${styles.descriptionWrap} ${isDescriptionOpen && styles.descriptionOpen}`}>
            {!isDescriptionOpen ? (
              <p>源泉徴収票提出対象の説明</p>
            ) : (
              <>
                <section>
                  <div className={styles.sectionHeadingWrap}>
                    <i className={styles.headingNumber}>
                      <p>1</p>
                    </i>
                    <h4 className={styles.headingText}>年末調整をしたもの</h4>
                  </div>
                  <ul className={styles.description}>
                    <li>
                      <span className={styles.listNumber}>(1)</span>
                      法人の役員(現に役員をしていなくても、その年中に役員であった者を含みます。)については、その年中の給与等の支払金額が150万円を超え
                      るもの。なお役員には、相談役、顧問その他これらに類する方が含まれます。
                    </li>
                    <li>
                      <span className={styles.listNumber}>(2)</span>
                      弁護士、司法書士、税理士等については、その年中の給与等の支払金額が250万円を超えるもの
                    </li>
                    <li>
                      <span className={styles.listNumber}>(3)</span>
                      上記(1)(2)以外の者については、その年中の給与等の支払金額が500万円を超えるもの
                      <br />
                      なお、上記(2)の弁護士等に対する支払は、給与等として支払っている場合の提出範囲ですので、報酬として支払う場合には、「報酬、料金、
                      契約金及び賞金の支払調書」を提出することとなります。
                    </li>
                  </ul>
                </section>
                <section>
                  <div className={styles.sectionHeadingWrap}>
                    <i className={styles.headingNumber}>
                      <p>2</p>
                    </i>
                    <h4 className={styles.headingText}>年末調整をしなかったもの</h4>
                  </div>
                  <ul className={styles.description}>
                    <li>
                      <span className={styles.listNumber}>(1)</span>
                      「給与所得者の扶養控除等申告書」を提出した方で、その年中に退職した方や、災害により被害を受けたため給与所得に対する所得税及び復
                      興特別所得税の源泉徴収の猶予を受けた方については、その年中の給与等の支払金額が250万円を超えるもの
                      <br />
                      ただし、法人の役員については、50万円を超えるもの
                    </li>
                    <li>
                      <span className={styles.listNumber}>(2)</span>
                      「給与所得者の扶養控除等申告書」を提出した方で、その年中の主たる給与等の金額が2,000万円を超えるため、年末調整をしなかったもの
                    </li>
                    <li>
                      <span className={styles.listNumber}>(3)</span>
                      「給与所得者の扶養控除等申告書」を提出しなかった方（給与所得の源泉徴収税額表の月額表又は日額表の乙欄又は丙欄の適用者）について
                      は、その年中の給与等の支払金額が50万円を超えるもの
                    </li>
                  </ul>
                </section>
              </>
            )}
            <div className={styles.pulldownWrap}>
              <button className={styles.pulldownButton} onClick={() => setIsDescriptionOpen(!isDescriptionOpen)}>
                <Pulldown className={`${styles.pulldown} ${isDescriptionOpen ? styles.pulldownReverse : ''}`} size={20} />
                {isDescriptionOpen ? '閉じる' : '開く'}
              </button>
            </div>
          </div>
          <div className={styles.generateButtons}>
            <Button as={Link} to={`/${year}/result/tax_office/legal_records`}>
              法定調書合計表
            </Button>
            <div className={styles.csvButtonControl}>
              <PayrollReportModal header="提出用CSVダウンロード" hasMyNumberPermission={hasMyNumberPermission} sendTo="tax_office" />
            </div>
            <DownloadWithholdSlips
              query={WITHHOLDING_SLIP_FOR_TAX_OFFICE}
              mutation={MAKE_WITHHOLDING_SLIP_FOR_TAX_OFFICE}
              title="税務署提出用"
              getReport={data => data.client.clientYearly.withholdingSlipForTaxOffice}
              callMutation={(makeReport, { showMyNumber }) =>
                makeReport({
                  variables: { showMyNumber, year }
                })
              }
              reportType="withholding_slip_for_tax_office"
              hasMyNumber
              showMyNumber={withholdingSlipForTaxOffice?.showMyNumber}
              hasMyNumberPermission={me.hasMyNumberPermission}
            />
          </div>
          <EmployeeList location={location} me={me} />
        </div>
      </div>
    </div>
  );
};

export default compose(
  withMe,
  withRouter,
  withStateHandlers(
    ({ location: { pathname }, me }) => ({
      isDescriptionOpen: true,
      selectedOption: _.parseInt(getSavedDisplayEmployeeLimit(pathname, me))
    }),
    {
      setIsDescriptionOpen: () => value => ({ isDescriptionOpen: value }),
      changeSelectedOption: (state, { location: { pathname }, me }) => limit => {
        saveDisplayEmployeeLimit(pathname, me, limit);
        return { selectedOption: limit };
      }
    }
  )
)(TaxOffice);
