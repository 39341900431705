import { FC, ReactNode } from 'react';
import styles from './FormItem.scss';

type FormItemProps = {
  children?: ReactNode;
};

export const FormItem: FC<FormItemProps> = ({ children }) => {
  return <div className={styles.formItem}>{children}</div>;
};
