import { gql } from '@apollo/client';

export type OfficeQueryResult = {
  client: {
    id: string;
    clientYearly: {
      id: string;
      office: {
        id: string;
        name: string;
      };
    };
  };
};

export type ApplicationsQueryResult = {
  client: {
    id: string;
    applications: Application[];
  };
};

type Application = {
  id: string;
  name: string;
  connected: boolean;
};

export const OFFICE = gql`
  query Office {
    client {
      id
      clientYearly {
        id
        office {
          id
          name
        }
      }
    }
  }
`;

export const APPLICATIONS = gql`
  query applications {
    client {
      id
      applications {
        id
        name
        connected
      }
    }
  }
`;
