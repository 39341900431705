import { FC } from 'react';
import classnames from 'classnames';
import useReactRouter from 'use-react-router';
import { ScrollSpy } from 'jbc-front/components/ScrollSpy/v2';
import { Loading } from 'jbc-front/components/presenters/ui/Loading';
import { useAdminQuery as useQuery } from '@jbc-year-end-adj/2024/hooks/graphql/useQuery';
import { SideMenu } from './parts/SideMenu';
import { Form } from './parts/Form';
import { FETCH_CLIENT_SETTING, QueryResult } from './query';
import styles from './ClientSetting.scss';

export const ClientSetting: FC = () => {
  const { location } = useReactRouter();
  const { loading, data } = useQuery<QueryResult>(FETCH_CLIENT_SETTING);
  if (loading) return <Loading />;

  return (
    <div>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">年末調整基本設定</h1>
      </div>

      <div className={classnames('l-contents-wrap', styles.mainFlex)}>
        <div className={styles.mainGrid}>
          <ScrollSpy location={location} offset={20}>
            <div className={styles.side}>
              <SideMenu />
            </div>

            <Form clientSetting={data?.client.clientYearly.clientSetting} />
          </ScrollSpy>
        </div>
      </div>
    </div>
  );
};
