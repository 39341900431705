import React from 'react'
import Icon from 'react-icon-base'

const ArrowDoubleNext = props => (
  <Icon viewBox="0 0 13 15" {...props}>
    <path fill='currentColor' fillRule='evenodd' d='M9.964 7.012l-3.858 5.905 1.577 1.107 4.697-7.012L7.683 0 6.106 1.107l3.858 5.905zm-6.106 0L0 12.917l1.577 1.107 4.697-7.012L1.577 0 0 1.107l3.858 5.905z' />
  </Icon>
)
ArrowDoubleNext.defaultProps = {
  size: 15
}

export default ArrowDoubleNext
