import React from 'react'
import Icon from 'react-icon-base'

const Bonus = props => (
  <Icon viewBox="0 0 18 21" {...props}>
    <defs>
      <path id='a' d='M0 16.855V.644h17.303v16.21z' />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <path fill='currentColor' d='M7.026 14.853l4.17 7.503 1.362-2.584 3.05.74-4.169-7.502z' />
      <path fill='currentColor' d='M1.696 20.512l3.049-.74 1.364 2.584 4.168-7.503-4.412-1.843z' />
      <path fill='currentColor' d='M8.652 14.174L5.865 13.01l-4.17 7.503 3.05-.741 1.364 2.585 2.543-4.578 2.543 4.578 1.364-2.585 3.049.74-4.17-7.502z' />
      <path fill='#FFF' d='M6.793 4.083l-2.719-1.03-.418 2.866L.85 6.65l1.514 2.455L.85 11.562l2.806.73.418 2.866 2.72-1.031 1.858 2.235 1.857-2.234 2.72 1.03.418-2.866 2.806-.731-1.514-2.455 1.514-2.456-2.806-.73-.419-2.866-2.719 1.03L8.652 1.85z' />
      <g transform='translate(0 .356)'>
        <mask id='b' fill='#fff'>
          <use xlinkHref='#a' />
        </mask>
        <path fill='currentColor' d='M6.793 12.92c.248 0 .49.109.654.308l1.205 1.448 1.205-1.448a.852.852 0 0 1 .954-.251l1.73.655.265-1.82a.85.85 0 0 1 .628-.7l1.693-.44-.912-1.477a.848.848 0 0 1 0-.892l.91-1.477-1.691-.44a.85.85 0 0 1-.627-.7l-.267-1.82-1.73.657a.852.852 0 0 1-.955-.252L8.651 2.82l-1.204 1.45a.847.847 0 0 1-.955.25l-1.73-.655-.264 1.82a.85.85 0 0 1-.628.699l-1.692.44.91 1.478a.848.848 0 0 1 0 .892l-.91 1.477 1.692.44a.85.85 0 0 1 .627.7l.266 1.819 1.73-.655a.852.852 0 0 1 .3-.055m1.859 3.934a.852.852 0 0 1-.654-.307l-1.47-1.768-2.151.816a.85.85 0 0 1-1.143-.672l-.337-2.308-2.261-.59a.847.847 0 0 1-.509-1.268l1.238-2.01L.127 6.74a.848.848 0 0 1 .509-1.268l2.26-.59.337-2.308a.85.85 0 0 1 1.142-.672l2.152.815L7.998.949a.85.85 0 0 1 1.308 0l1.469 1.769 2.152-.816a.852.852 0 0 1 1.142.672l.337 2.309 2.26.588a.848.848 0 0 1 .51 1.268l-1.238 2.01 1.239 2.009a.85.85 0 0 1-.51 1.268l-2.26.59-.338 2.308a.848.848 0 0 1-1.142.672l-2.152-.815-1.47 1.768a.852.852 0 0 1-.653.306' mask='url(#b)' />
      </g>
      <path fill='currentColor' d='M7.931 10.646a.248.248 0 0 1 .236.169l.484 1.403.484-1.404a.248.248 0 0 1 .401-.106l1.083.956-.31-1.404a.252.252 0 0 1 .058-.22.259.259 0 0 1 .213-.082l1.305.139-1.032-.793a.25.25 0 0 1 0-.396l1.032-.794-1.305.14a.242.242 0 0 1-.213-.082.25.25 0 0 1-.058-.22l.31-1.403-1.083.955a.248.248 0 0 1-.401-.106l-.484-1.403-.485 1.404a.248.248 0 0 1-.173.16.258.258 0 0 1-.229-.054l-1.08-.956.31 1.403a.246.246 0 0 1-.059.22.239.239 0 0 1-.213.082l-1.305-.14 1.032.794a.25.25 0 0 1 0 .396l-1.033.794 1.307-.14a.247.247 0 0 1 .212.083c.054.06.076.142.058.22l-.31 1.403 1.082-.956a.252.252 0 0 1 .166-.062m.72 2.59a.25.25 0 0 1-.236-.17l-.599-1.735-1.378 1.215a.252.252 0 0 1-.292.029.25.25 0 0 1-.117-.27l.401-1.812-1.843.197a.249.249 0 0 1-.258-.156.249.249 0 0 1 .08-.291l1.478-1.137-1.479-1.137a.249.249 0 0 1-.08-.291.242.242 0 0 1 .26-.156l1.842.198-.4-1.812a.252.252 0 0 1 .116-.27.255.255 0 0 1 .293.029l1.377 1.216.6-1.737a.25.25 0 0 1 .472 0l.598 1.736 1.377-1.215a.254.254 0 0 1 .292-.03.25.25 0 0 1 .117.27l-.4 1.813 1.843-.198a.243.243 0 0 1 .258.156.249.249 0 0 1-.079.29l-1.478 1.138 1.478 1.136a.249.249 0 0 1 .08.29.245.245 0 0 1-.259.157l-1.842-.197.384 1.74a.25.25 0 0 1 .035.127c0 .14-.137.233-.257.25a.289.289 0 0 1-.17-.063L9.485 11.33l-.598 1.737a.251.251 0 0 1-.237.169' />
    </g>
  </Icon>
)
Bonus.defaultProps = {
  size: 21
}

export default Bonus
