import { FC, ReactNode, useState } from 'react';
import { createPortal } from 'react-dom';
import { useYear } from 'hooks/useYear';
import { ReportViewer } from 'features/reports/components/ReportViewer';
import { ReportName } from 'features/reports/types';
import { useGenerateReportNameMap } from 'features/reports/hooks/useGenerateReportNameMap';
import { useYearMasters } from 'hooks/useYearMasters';
import { Preview } from 'jbc-front/components/icons';
import { Employee } from '../../query';
import { eraNameShortHandFormat } from '@jbc-year-end-adj/common/utils/formatter';
import styles from './Reports.scss';

// @ts-ignore
import ListGroup from 'jbc-front/components/ListGroup';

type ReportsProps = {
  employee: Employee;
};

export const Reports: FC<ReportsProps> = ({ employee }) => {
  const year = useYear();
  const { yearMasters, loading } = useYearMasters();
  const { reportMap } = useGenerateReportNameMap(year);

  if (loading || !yearMasters) return null;

  const thisYearMaster = yearMasters.find(yearMaster => yearMaster.year === year);
  const nextYearMaster = yearMasters.find(yearMaster => yearMaster.year === year + 1);

  if (!thisYearMaster || !nextYearMaster) return null;

  const formattedThisYearEra = eraNameShortHandFormat(thisYearMaster.eraName, thisYearMaster.eraNumber);
  const formattedNextYearEra = eraNameShortHandFormat(nextYearMaster.eraName, nextYearMaster.eraNumber);

  return (
    <ul className={styles.reportsList}>
      {employee.profile.nextYearOnly ? (
        <ListItem
          employeeId={String(employee.id)}
          year={year}
          reportName="dependentDeductionNextYear"
          title={reportMap['dependentDeductionNextYear']}
          hasMyNumber={false}
        >
          {`${formattedNextYearEra}扶養`}
        </ListItem>
      ) : (
        <>
          <ListItem
            employeeId={String(employee.id)}
            year={year}
            hasMyNumber={true}
            reportName="dependentDeductionThisYear"
            title={reportMap['dependentDeductionThisYear']}
          >
            {`${formattedThisYearEra}扶養`}
          </ListItem>

          {!employee.profile.isNotCoveredByNextYear && (
            <ListItem
              employeeId={String(employee.id)}
              year={year}
              hasMyNumber={true}
              reportName="dependentDeductionNextYear"
              title={reportMap['dependentDeductionNextYear']}
            >
              {`${formattedNextYearEra}扶養`}
            </ListItem>
          )}

          <ListItem
            employeeId={String(employee.id)}
            year={year}
            hasMyNumber={false}
            reportName="insuranceDeduction"
            title={reportMap['insuranceDeduction']}
          >
            保険
          </ListItem>

          <ListItem
            employeeId={String(employee.id)}
            year={year}
            hasMyNumber={true}
            reportName="bsiDeduction"
            title={reportMap['bsiDeduction']}
          >
            基配所
          </ListItem>

          <ListItem employeeId={String(employee.id)} year={year} hasMyNumber={true} reportName="fileMount" title={reportMap['fileMount']}>
            添付
          </ListItem>
        </>
      )}
    </ul>
  );
};

type ListItemProsp = {
  title: string;
  employeeId: string;
  year: number;
  reportName: ReportName;
  hasMyNumber: boolean;
  children: ReactNode;
  classNames?: string;
};

const ListItem: FC<ListItemProsp> = ({ employeeId, reportName, title, children, classNames }) => {
  const [isOpen, setIsOpen] = useState(false);
  const year = useYear();

  return (
    <li key={reportName} className={classNames} title={title}>
      <div onClick={() => setIsOpen(true)}>
        <Preview size={20} color="#3498db" className={styles.reportsIcon} />
        {children}
      </div>
      {isOpen &&
        createPortal(
          <ReportViewer employeeId={employeeId} year={year} reportName={reportName} hasMyNumber={false} onClose={() => setIsOpen(false)} />,
          document.body
        )}
    </li>
  );
};
