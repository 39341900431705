import { FC, ReactNode } from 'react';
import { Section } from 'components/ui/Section';
import { Separator } from 'components/ui/Separator';

import { Question } from '../type';

import { Lead } from './parts/Lead';
import { Description } from './parts/Description';
import { Content } from './parts/Content';
import { Note } from './parts/Note';

type HandicapProps = {
  children: ReactNode;
};

const MainSection: FC<HandicapProps> = ({ children }) => {
  return (
    <Section>
      <Section.Header>障害者情報</Section.Header>

      <Section.Body>
        <Content>{children}</Content>
      </Section.Body>
    </Section>
  );
};

type CheckHandicapProps = {
  question: Question;
  children: ReactNode;
};

const CheckHandicap: FC<CheckHandicapProps> = ({ question, children }) => {
  const description = question.questionDescriptions.find(description => description.field == 'under_note')?.description;
  return (
    <>
      <Lead>あなたは以下のいずれかに該当しますか？</Lead>

      <Description>{description}</Description>

      <Note>
        一般障害者とは、以下に該当する方です。
        <ul className="u-mb20">
          <li>・身体障害者手帳 3〜6級</li>
          <li>・精神障害者保険福祉手帳 2〜3級</li>
          <li>・愛護手帳（療育手帳） B</li>
        </ul>
        特別障害者とは、以下に該当する方です。
        <ul>
          <li>・身体障害者手帳 1~2級</li>
          <li>・精神障害者保険福祉手帳 1級</li>
          <li>・愛護手帳（療育手帳）A</li>
          <li>・常に寝たきりである方</li>
        </ul>
      </Note>

      {children}
    </>
  );
};

type DetailInputProps = {
  children: ReactNode;
};

const DetailInput: FC<DetailInputProps> = ({ children }) => {
  return (
    <>
      <Separator />
      <Lead>以下の情報の記入が必要です。</Lead>
      {children}
    </>
  );
};

export const Handicap = Object.assign(MainSection, { CheckHandicap, DetailInput });
