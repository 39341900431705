import { FC, useState } from 'react';
import { useYear } from 'hooks/useYear';
import { useRightExpansionContainer } from 'components/feature/RightExpansionContainer';
import { Diff, DiffItem, FileDiff } from '../../../../components/Diff';
import { Grid } from '../../../../components/Grid';
import { PreviewSubSection } from '../../../../components/PreviewSubSection';
import { Tag } from '../../../../components/Tag';
import { DependentYearlyInfo } from '../../../../query';
import { dateFormat, amountFormat, optionalBooleanFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { GENDER, RESIDENT_STATUS, HANDICAP_CLASSIFICATION } from '../../../../consts';
import { Pulldown, Income as IncomeIcon } from 'jbc-front/components/icons';
import { DiffType } from './Preview';
import { Income } from './Income';
import styles from './Preview.scss';
import classnames from 'classnames';

type OtherFamilyRowProps = {
  diff: DiffType;
};

export const UpdateOtherFamilyRow: FC<OtherFamilyRowProps> = ({ diff }) => {
  const year = useYear();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { setContent } = useRightExpansionContainer();

  const handleClickIncome = () => {
    setContent(<Income income={diff.source?.income} requestIncome={diff.input?.income} year={year} />, 'spouseIncomeDiff', 400);
  };

  const fullAddress = (yearlyInfo?: DependentYearlyInfo) => {
    if (!yearlyInfo) return undefined;

    return `${yearlyInfo.postcode0}-${yearlyInfo.postcode1} ${yearlyInfo.fullAddress}`;
  };

  const hasDependentYearlyInfoDiff = (source?: DependentYearlyInfo, input?: DependentYearlyInfo) => {
    return (
      source?.dependentTaxLaw !== input?.dependentTaxLaw ||
      source?.residenceStatus !== input?.residenceStatus ||
      source?.postcode0 !== input?.postcode0 ||
      source?.postcode1 !== input?.postcode1 ||
      source?.prefectureId !== input?.prefectureId ||
      source?.city !== input?.city ||
      source?.street !== input?.street ||
      source?.building !== input?.building ||
      source?.addressForeign !== input?.addressForeign ||
      source?.isNonResident !== input?.isNonResident ||
      source?.isStudyAbroad !== input?.isStudyAbroad ||
      source?.remittance !== input?.remittance ||
      source?.relatedToRelativesDocument?.filename !== input?.relatedToRelativesDocument?.filename ||
      source?.relatedToRemittanceDocument?.filename !== input?.relatedToRemittanceDocument?.filename ||
      source?.provingStudyAbroadDocument !== input?.provingStudyAbroadDocument ||
      source?.handicapType !== input?.handicapType ||
      source?.handicapDetail !== input?.handicapDetail ||
      source?.handicapImage?.filename !== input?.handicapImage?.filename ||
      source?.income !== input?.income ||
      source?.earnings !== input?.earnings
    );
  };

  const hasDiff =
    diff.source?.relationOther !== diff.input?.relationOther ||
    diff.source?.lastName !== diff.input?.lastName ||
    diff.source?.firstName !== diff.input?.firstName ||
    diff.source?.lastNameKana !== diff.input?.lastNameKana ||
    diff.source?.firstNameKana !== diff.input?.firstNameKana ||
    diff.source?.birthday !== diff.input?.birthday ||
    diff.source?.gender !== diff.input?.gender ||
    hasDependentYearlyInfoDiff(diff.source?.thisYear, diff.input?.thisYear) ||
    hasDependentYearlyInfoDiff(diff.source?.nextYear, diff.input?.nextYear) ||
    diff.source?.dependentFrom !== diff.input?.dependentFrom ||
    diff.source?.dependentReason !== diff.input?.dependentReason ||
    diff.source?.diedOn !== diff.input?.diedOn;

  return (
    <>
      <tr onClick={() => setIsOpen(!isOpen)} className={classnames(styles.row, isOpen && styles.expand)}>
        <td>
          <div className={styles.toggleColumn}>
            <Pulldown className={styles.icon} transform={isOpen ? 'rotate(0)' : 'rotate(-90)'} />
            <DiffItem source={diff.source?.relationOther} input={diff.input?.relationOther} />
            {hasDiff && <Tag color="green">更新</Tag>}
          </div>
        </td>
        <td>
          <DiffItem
            source={
              diff.source
                ? `${diff.source.lastName}${diff.source.firstName}（${diff.source.lastNameKana}${diff.source.firstNameKana}）`
                : undefined
            }
            input={
              diff.input
                ? `${diff.input.lastName}${diff.input.firstName}（${diff.input.lastNameKana}${diff.input.firstNameKana}）`
                : undefined
            }
          />
        </td>
        <td>
          <DiffItem source={dateFormat(diff.source?.birthday, 'L')} input={dateFormat(diff.input?.birthday, 'L')} />
        </td>
        <td className={styles.genderTd}>
          <DiffItem
            source={diff.source?.gender ? GENDER[diff.source.gender] : undefined}
            input={diff.input?.gender ? GENDER[diff.input.gender] : undefined}
          />
        </td>
        <td className={styles.taxLawTd}>
          <DiffItem source={diff.source?.thisYear?.dependentTaxLaw ? '○' : '×'} input={diff.input?.thisYear?.dependentTaxLaw ? '○' : '×'} />
        </td>
        <td className={styles.taxLawTd}>
          <DiffItem source={diff.source?.nextYear?.dependentTaxLaw ? '○' : '×'} input={diff.input?.nextYear?.dependentTaxLaw ? '○' : '×'} />
        </td>
      </tr>

      {isOpen && (
        <tr>
          <td colSpan={6}>
            <div className={styles.expandableRow}>
              <Grid colGap col2>
                <PreviewSubSection>
                  <PreviewSubSection.Header>今年の税扶養</PreviewSubSection.Header>
                  <PreviewSubSection.Body>
                    <Diff
                      title="今年の源泉控除対象"
                      source={optionalBooleanFormat(diff.source?.thisYear?.dependentTaxLaw, '対象', '対象外')}
                      input={optionalBooleanFormat(diff.input?.thisYear?.dependentTaxLaw, '対象', '対象外')}
                    />

                    <Diff
                      title="今年の税の扶養対象ではないが、所得金額調整控除の対象、退職手当を有する扶養親族"
                      source={diff.source?.thisYear?.incomeAdjustmentDeduction ? '対象' : '対象外'}
                      input={diff.input?.thisYear?.incomeAdjustmentDeduction ? '対象' : '対象外'}
                    />

                    <Diff
                      title="同居・別居"
                      source={diff.source?.thisYear?.residenceStatus ? RESIDENT_STATUS[diff.source?.thisYear?.residenceStatus] : undefined}
                      input={diff.input?.thisYear?.residenceStatus ? RESIDENT_STATUS[diff.input?.thisYear?.residenceStatus] : undefined}
                    />

                    {(diff.source?.thisYear?.residenceStatus === 'different_address' ||
                      diff.input?.thisYear?.residenceStatus === 'different_address') && (
                      <>
                        <Diff title="住所" source={fullAddress(diff.source?.thisYear)} input={fullAddress(diff.input?.thisYear)} />
                      </>
                    )}

                    {(diff.source?.thisYear?.residenceStatus === 'different_and_foreign_address' ||
                      diff.input?.thisYear?.residenceStatus === 'different_and_foreign_address') && (
                      <>
                        <Diff title="Adress" source={diff.source?.thisYear?.addressForeign} input={diff.input?.thisYear?.addressForeign} />
                        <Diff
                          title="非居住者"
                          source={diff.source?.thisYear?.isNonResident ? 'はい' : 'いいえ'}
                          input={diff.input?.thisYear?.isNonResident ? 'はい' : 'いいえ'}
                        />
                        <Diff
                          title="留学"
                          source={diff.source?.thisYear?.isStudyAbroad ? 'はい' : 'いいえ'}
                          input={diff.input?.thisYear?.isStudyAbroad ? 'はい' : 'いいえ'}
                        />
                        <Diff
                          title="国外居住親族への送金額"
                          source={amountFormat(diff.source?.thisYear?.remittance)}
                          input={amountFormat(diff.input?.thisYear?.remittance)}
                        />
                        <FileDiff
                          title="親族関係書類"
                          source={diff.source?.thisYear?.relatedToRelativesDocument}
                          input={diff.input?.thisYear?.relatedToRelativesDocument}
                        />
                        <FileDiff
                          title="送金関係書類"
                          source={diff.source?.thisYear?.relatedToRemittanceDocument}
                          input={diff.input?.thisYear?.relatedToRemittanceDocument}
                        />
                        <FileDiff
                          title="留学関係書類"
                          source={diff.source?.thisYear?.provingStudyAbroadDocument}
                          input={diff.input?.thisYear?.provingStudyAbroadDocument}
                        />
                      </>
                    )}
                    <Diff
                      title="障害者区分"
                      source={
                        diff.source?.thisYear?.handicapType ? HANDICAP_CLASSIFICATION[diff.source?.thisYear?.handicapType] : undefined
                      }
                      input={diff.input?.thisYear?.handicapType ? HANDICAP_CLASSIFICATION[diff.input?.thisYear?.handicapType] : undefined}
                    />
                    <Diff title="障害者詳細" source={diff.source?.thisYear?.handicapDetail} input={diff.input?.thisYear?.handicapDetail} />
                    <FileDiff
                      title="障害者手帳画像"
                      source={diff.source?.thisYear?.handicapImage}
                      input={diff.input?.thisYear?.handicapImage}
                    />
                    <Diff.Container>
                      <Diff.Header>
                        <div className={styles.incomeDetailHeader}>
                          所得見積額
                          <i onClick={handleClickIncome}>
                            <IncomeIcon />
                          </i>
                        </div>
                      </Diff.Header>
                      <Diff.Body
                        source={amountFormat(diff.source?.thisYear?.earnings)}
                        input={amountFormat(diff.input?.thisYear?.earnings)}
                      />
                    </Diff.Container>
                  </PreviewSubSection.Body>
                </PreviewSubSection>

                <PreviewSubSection>
                  <PreviewSubSection.Header>来年の扶養情報</PreviewSubSection.Header>
                  <PreviewSubSection.Body>
                    <Diff
                      title="来年の源泉控除対象"
                      source={optionalBooleanFormat(diff.source?.nextYear?.dependentTaxLaw, '対象', '対象外')}
                      input={optionalBooleanFormat(diff.input?.nextYear?.dependentTaxLaw, '対象', '対象外')}
                    />

                    <Diff
                      title="同居・別居"
                      source={diff.source?.nextYear?.residenceStatus ? RESIDENT_STATUS[diff.source?.nextYear?.residenceStatus] : undefined}
                      input={diff.input?.nextYear?.residenceStatus ? RESIDENT_STATUS[diff.input?.nextYear?.residenceStatus] : undefined}
                    />

                    {(diff.source?.nextYear?.residenceStatus === 'different_address' ||
                      diff.input?.nextYear?.residenceStatus === 'different_address') && (
                      <>
                        <Diff title="住所" source={fullAddress(diff.source?.nextYear)} input={fullAddress(diff.input?.nextYear)} />
                      </>
                    )}

                    {(diff.source?.nextYear?.residenceStatus === 'different_and_foreign_address' ||
                      diff.input?.nextYear?.residenceStatus === 'different_and_foreign_address') && (
                      <>
                        <Diff title="Adress" source={diff.source?.nextYear?.addressForeign} input={diff.input?.nextYear?.addressForeign} />
                        <Diff
                          title="非居住者"
                          source={diff.source?.nextYear?.isNonResident ? 'はい' : 'いいえ'}
                          input={diff.input?.nextYear?.isNonResident ? 'はい' : 'いいえ'}
                        />
                        <Diff
                          title="国外居住親族への送金額"
                          source={amountFormat(diff.source?.nextYear?.remittance)}
                          input={amountFormat(diff.input?.nextYear?.remittance)}
                        />
                        <Diff
                          title="留学"
                          source={diff.source?.nextYear?.isStudyAbroad ? 'はい' : 'いいえ'}
                          input={diff.input?.nextYear?.isStudyAbroad ? 'はい' : 'いいえ'}
                        />
                        <FileDiff
                          title="親族関係書類"
                          source={diff.source?.nextYear?.relatedToRelativesDocument}
                          input={diff.input?.nextYear?.relatedToRelativesDocument}
                        />
                        <FileDiff
                          title="送金関係書類"
                          source={diff.source?.nextYear?.relatedToRemittanceDocument}
                          input={diff.input?.nextYear?.relatedToRemittanceDocument}
                        />
                        <FileDiff
                          title="留学関係書類"
                          source={diff.source?.nextYear?.provingStudyAbroadDocument}
                          input={diff.input?.nextYear?.provingStudyAbroadDocument}
                        />
                      </>
                    )}
                    <Diff
                      title="障害者区分"
                      source={
                        diff.source?.nextYear?.handicapType ? HANDICAP_CLASSIFICATION[diff.source?.nextYear?.handicapType] : undefined
                      }
                      input={diff.input?.nextYear?.handicapType ? HANDICAP_CLASSIFICATION[diff.input?.nextYear?.handicapType] : undefined}
                    />
                    <Diff title="障害者詳細" source={diff.source?.nextYear?.handicapDetail} input={diff.input?.nextYear?.handicapDetail} />
                    <FileDiff
                      title="障害者手帳画像"
                      source={diff.source?.nextYear?.handicapImage}
                      input={diff.input?.nextYear?.handicapImage}
                    />
                    <Diff
                      title="所得見積額"
                      source={amountFormat(diff.source?.nextYear?.earnings)}
                      input={amountFormat(diff.input?.nextYear?.earnings)}
                    />
                  </PreviewSubSection.Body>
                </PreviewSubSection>
              </Grid>

              <Grid colGap>
                <Diff
                  title="税法上の扶養家族になった日"
                  source={dateFormat(diff.source?.dependentFrom, 'L')}
                  input={dateFormat(diff.input?.dependentFrom, 'L')}
                />
                <Diff title="税法上の扶養に追加された理由" source={diff.source?.dependentReason} input={diff.input?.dependentReason} />
                <Diff title="死亡日" source={dateFormat(diff.source?.diedOn, 'L')} input={dateFormat(diff.input?.diedOn, 'L')} />
              </Grid>
            </div>
          </td>
        </tr>
      )}
    </>
  );
};
