import React from 'react'
import Icon from 'react-icon-base'

const Skill = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="icon/employees/Skill" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group" transform="translate(2.000000, 2.000000)" fill={props.color} fillRule="nonzero">
        <path d="M17.9735612,12.976423 C21.6083678,12.976423 24.5558126,15.9681924 24.5558126,19.6576602 C24.5558126,23.347128 21.6083678,26.3388974 17.9735612,26.3388974 C14.3387546,26.3388974 11.3913097,23.347128 11.3913097,19.6576602 C11.3913097,15.9681924 14.3387546,12.976423 17.9735612,12.976423 Z M17.9735612,15.067611 C15.476575,15.067611 13.4515157,17.1231236 13.4515157,19.6576602 C13.4515157,22.1921968 15.476575,24.2477094 17.9735612,24.2477094 C20.4705474,24.2477094 22.4956067,22.1921968 22.4956067,19.6576602 C22.4956067,17.1231236 20.4705474,15.067611 17.9735612,15.067611 Z" id="Stroke-1"></path>
        <path d="M17.9735612,24.2477094 C23.7986133,24.2477094 28.521633,29.0417552 28.521633,34.954406 C28.521633,35.5318716 28.0604402,36 27.49153,36 C26.9226198,36 26.461427,35.5318716 26.461427,34.954406 C26.461427,30.1966864 22.660793,26.3388974 17.9735612,26.3388974 C13.2863294,26.3388974 9.48569542,30.1966864 9.48569542,34.954406 C9.48569542,35.5318716 9.0245026,36 8.45559242,36 C7.88668224,36 7.42548942,35.5318716 7.42548942,34.954406 C7.42548942,29.0417552 12.1485091,24.2477094 17.9735612,24.2477094 Z" id="Stroke-3"></path>
        <path d="M17.3288408,0.417171563 C17.6084973,-0.139057188 18.3915027,-0.139057188 18.6711592,0.417171563 L18.6711592,0.417171563 L20.1530174,3.36528408 L23.3736136,3.88508347 C23.9454594,3.97739851 24.1934304,4.65239817 23.8581718,5.09986618 L23.8581718,5.09986618 L23.7884128,5.18090224 L21.4832064,7.52191981 L21.992235,10.7921016 C22.082449,11.3726648 21.5266244,11.8206322 21.0037614,11.6352626 L21.0037614,11.6352626 L20.9062767,11.5929616 L18,10.0917731 L15.0937233,11.5929616 C14.5776329,11.8594544 13.9861433,11.4613138 13.9982549,10.8992811 L13.9982549,10.8992811 L14.007765,10.7921016 L14.5157889,7.52191981 L12.2115872,5.18090224 C11.8024118,4.76504059 11.9926757,4.07100878 12.523024,3.90902307 L12.523024,3.90902307 L12.6263864,3.88508347 L15.8459779,3.36528408 Z M18,2.44747933 L17.0147145,4.40712361 C16.9052286,4.62488791 16.6997776,4.77640158 16.4619068,4.81480189 L16.4619068,4.81480189 L14.320882,5.16010226 L15.8529669,6.7171098 C15.9988567,6.86538355 16.0773535,7.06667886 16.073172,7.27324555 L16.073172,7.27324555 L16.0641207,7.37674711 L15.7264216,9.54924853 L17.658346,8.55243806 C17.8372446,8.46006052 18.0434162,8.44466426 18.2314057,8.50624929 L18.2314057,8.50624929 L18.341654,8.55243806 L20.2725737,9.54924853 L19.9358793,7.37674711 C19.9037139,7.16974968 19.9567742,6.96006528 20.079775,6.79544407 L20.079775,6.79544407 L20.1470331,6.7171098 L21.6781133,5.16010226 L19.5380932,4.81480189 C19.3342039,4.78188734 19.1541333,4.6658691 19.0378981,4.49625838 L19.0378981,4.49625838 L18.9852855,4.40712361 L18,2.44747933 Z" id="Star-Copy"></path>
        <path d="M28.0457127,8.66235243 C28.2554551,8.24518087 28.842709,8.24518087 29.0524514,8.66235243 L29.0524514,8.66235243 L30.1261122,10.7979729 L32.4598545,11.1752048 C32.8851648,11.2438641 33.0716006,11.7422836 32.8294682,12.0778666 L32.8294682,12.0778666 L32.7709539,12.1470688 L31.1016512,13.8430451 L31.4694417,16.2129493 C31.5365384,16.6447432 31.1271479,16.9787408 30.7378951,16.848697 L30.7378951,16.848697 L30.654973,16.8135944 L28.5487733,15.7255646 L26.4431912,16.8135944 C26.0593489,17.0117984 25.6198959,16.7198007 25.6214567,16.3038462 L25.6214567,16.3038462 L25.6287224,16.2129493 L25.9958954,13.8430451 L24.3272103,12.1470688 C24.022886,11.8377717 24.1606795,11.3233097 24.5508971,11.1962795 L24.5508971,11.1962795 L24.6383096,11.1752048 L26.9714344,10.7979729 Z M28.5487733,10.1850833 L27.8477644,11.5799387 C27.76565,11.743262 27.6115617,11.8568972 27.4331586,11.8856974 L27.4331586,11.8856974 L25.9094934,12.1308293 L26.9997205,13.2397437 C27.1018434,13.3435353 27.1599361,13.4819677 27.1646782,13.6259238 L27.1646782,13.6259238 L27.1580858,13.7344717 L26.9171819,15.281959 L28.2928416,14.5713183 C28.4216485,14.5048064 28.5693066,14.4915041 28.705654,14.5314112 L28.705654,14.5314112 L28.8053225,14.5713183 L30.17936,15.2809392 L29.9400783,13.7344717 C29.9175625,13.5895735 29.9507275,13.4429198 30.0302528,13.3236276 L30.0302528,13.3236276 L30.0984436,13.2397437 L31.1880532,12.1308293 L29.6650055,11.8856974 C29.5163362,11.8616972 29.3845523,11.7787838 29.2972341,11.657297 L29.2972341,11.657297 L29.2503997,11.5799387 L28.5487733,10.1850833 Z" id="Star-Copy-2"></path>
        <path d="M6.94754856,8.66235243 C7.15729095,8.24518087 7.74454493,8.24518087 7.95428732,8.66235243 L7.95428732,8.66235243 L9.02825687,10.7984828 L11.3616904,11.1752048 C11.7870007,11.2438641 11.9734365,11.7422836 11.7313041,12.0778666 L11.7313041,12.0778666 L11.6727897,12.1470688 L10.0027911,13.843555 L10.3712776,16.2129493 C10.4383743,16.6447432 10.0289838,16.9787408 9.63973095,16.848697 L9.63973095,16.848697 L9.55680884,16.8135944 L7.45091794,15.7260745 L5.34502704,16.8135944 C4.96118476,17.0117984 4.52173174,16.7198007 4.52329261,16.3038462 L4.52329261,16.3038462 L4.53055828,16.2129493 L4.89804008,13.843555 L3.22904613,12.1470688 C2.9247219,11.8377717 3.06251542,11.3233097 3.45273296,11.1962795 L3.45273296,11.1962795 L3.54014551,11.1752048 L5.87257433,10.7984828 Z M7.45091794,10.1845734 L6.74960031,11.5799387 C6.66748589,11.743262 6.5133976,11.8568972 6.33499451,11.8856974 L6.33499451,11.8856974 L4.8106334,12.1313392 L5.90155638,13.2397437 C6.00367925,13.3435353 6.06177197,13.4819677 6.06651404,13.6259238 L6.06651404,13.6259238 L6.05992171,13.7344717 L5.81932658,15.2804293 L7.19467746,14.5713183 C7.3234844,14.5048064 7.47114249,14.4915041 7.6074899,14.5314112 L7.6074899,14.5314112 L7.70715842,14.5713183 L9.08150462,15.2804293 L8.84191417,13.7344717 C8.81939837,13.5895735 8.85256336,13.4429198 8.93208863,13.3236276 L8.93208863,13.3236276 L9.00027949,13.2397437 L10.0901978,12.1313392 L8.56684136,11.8856974 C8.41817212,11.8616972 8.28638816,11.7787838 8.19906995,11.657297 L8.19906995,11.657297 L8.15223556,11.5799387 L7.45091794,10.1845734 Z" id="Star-Copy-4"></path>
        <path d="M32.0644106,18.860183 C32.274153,18.4430114 32.861407,18.4430114 33.0711494,18.860183 L33.0711494,18.860183 L33.8437166,20.3966809 L35.5230502,20.6683826 C35.9483605,20.7370419 36.1347963,21.2354614 35.8926639,21.5710444 L35.8926639,21.5710444 L35.8341496,21.6402466 L34.6323861,22.8614965 L34.8976068,24.565975 C34.9647035,24.9977689 34.555313,25.3317665 34.1660602,25.2017227 L34.1660602,25.2017227 L34.083138,25.16662 L32.56778,24.3840326 L31.0524219,25.16662 C30.6685797,25.364824 30.2291266,25.0728263 30.2306875,24.6568719 L30.2306875,24.6568719 L30.2379532,24.565975 L30.5021693,22.8614965 L29.3014104,21.6402466 C28.9970862,21.3309495 29.1348797,20.8164875 29.5250972,20.6894573 L29.5250972,20.6894573 L29.6125098,20.6683826 L31.2908387,20.3966809 Z M32.56778,20.3824039 L32.1676341,21.1787474 C32.0855197,21.3420706 31.9314314,21.4557058 31.7530283,21.4845061 L31.7530283,21.4845061 L30.8829409,21.6244997 L31.5057246,22.2570747 C31.6078474,22.3608663 31.6659402,22.4992987 31.6706822,22.6432549 L31.6706822,22.6432549 L31.6640899,22.7518027 L31.5269372,23.6334723 L32.3115395,23.2292764 C32.4403465,23.1627646 32.5880045,23.1494622 32.7243519,23.1893693 L32.7243519,23.1893693 L32.8240205,23.2292764 L33.6076181,23.6334723 L33.4714701,22.7518027 C33.4489543,22.6069045 33.4821193,22.4602508 33.5616446,22.3409586 L33.5616446,22.3409586 L33.6298354,22.2570747 L34.2516144,21.6244997 L33.3825317,21.4845061 C33.2338624,21.4605059 33.1020785,21.3775925 33.0147603,21.2561056 L33.0147603,21.2561056 L32.9679259,21.1787474 L32.56778,20.3824039 Z" id="Star-Copy-3"></path>
        <path d="M2.92885063,18.860183 C3.13859302,18.4430114 3.725847,18.4430114 3.93558939,18.860183 L3.93558939,18.860183 L4.7081566,20.3966809 L6.38749022,20.6683826 C6.81280052,20.7370419 6.99923634,21.2354614 6.75710396,21.5710444 L6.75710396,21.5710444 L6.69858961,21.6402466 L5.49682607,22.8614965 L5.76204682,24.565975 C5.82914348,24.9977689 5.41975305,25.3317665 5.03050018,25.2017227 L5.03050018,25.2017227 L4.94757806,25.16662 L3.43121534,24.3840326 L1.91686196,25.16662 C1.53301967,25.364824 1.09356665,25.0728263 1.09512752,24.6568719 L1.09512752,24.6568719 L1.10239319,24.565975 L1.36660927,22.8614965 L0.165850412,21.6402466 C-0.138473818,21.3309495 -0.00068029582,20.8164875 0.389537248,20.6894573 L0.389537248,20.6894573 L0.476949796,20.6683826 L2.15527874,20.3966809 Z M3.43222001,20.3824039 L3.03207414,21.1787474 C2.94995971,21.3420706 2.79587142,21.4557058 2.61746833,21.4845061 L2.61746833,21.4845061 L1.7473809,21.6244997 L2.37016458,22.2570747 C2.47228745,22.3608663 2.53038017,22.4992987 2.53512224,22.6432549 L2.53512224,22.6432549 L2.52852991,22.7518027 L2.39137725,23.6334723 L3.17597953,23.2292764 C3.30478648,23.1627646 3.45244457,23.1494622 3.58879197,23.1893693 L3.58879197,23.1893693 L3.68846049,23.2292764 L4.4720581,23.6334723 L4.33591011,22.7518027 C4.31339432,22.6069045 4.34655931,22.4602508 4.42608457,22.3409586 L4.42608457,22.3409586 L4.49427544,22.2570747 L5.11605444,21.6244997 L4.24697169,21.4845061 C4.09830244,21.4605059 3.96651848,21.3775925 3.87920028,21.2561056 L3.87920028,21.2561056 L3.83236588,21.1787474 L3.43222001,20.3824039 Z" id="Star-Copy-5"></path>
      </g>
    </g>
  </Icon>
)

Skill.defaultProps = {
  size: 30,
  color: "#3498DB"
}

export default Skill
