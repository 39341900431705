import React, { useState } from 'react';
import { gql } from '@apollo/client';
import Select from 'react-select';
import _ from 'lodash';
import fp from 'lodash/fp';
import Button from 'jbc-front/components/Button';
import { methods } from './ApplyMethod';
import { MAX_UPDATE_COUNT } from './FloatingButtonArea';
import { useNotify } from '../actions';
import { useMutation, useYear, Modal, ASYNC_TASK_FRAGMENT } from '../components';
import { initialState } from '../pages/DifferenceApply';

const BULK_UPDATE_DIFFERENCE_APPLY_METHODS_BY_ALL_SELECT = gql`
  mutation bulkUpdateDifferenceApplyMethodsByAllSelect($input: BulkUpdateDifferenceApplyMethodsByAllSelectInput!) {
    bulkUpdateDifferenceApplyMethodsByAllSelect(input: $input) {
      employees {
        id
        differenceApplyMethod
      }
    }
  }
`;

const BULK_UPDATE_DIFFERENCE_APPLY_METHODS_BY_ALL_SELECT_ASYNC = gql`
  mutation bulkUpdateDifferenceApplyMethodsByAllSelectAsync($input: BulkUpdateDifferenceApplyMethodsByAllSelectAsyncInput!) {
    bulkUpdateDifferenceApplyMethodsByAllSelectAsync(input: $input) {
      clientYearly {
        id
        asyncTask {
          ...AsyncTaskFields
        }
      }
    }
  }
  ${ASYNC_TASK_FRAGMENT}
`;

const convertBulkUpdateByAllSelectMutation = (selectedTotalCount, mutation, asyncMutation) =>
  selectedTotalCount > MAX_UPDATE_COUNT ? asyncMutation : mutation;

const BulkChangeMethod = ({
  selected,
  allSelectMode,
  selectedTotalCount,
  isDisabledSubmit,
  state,
  setState,
  handleBulkUpdate,
  successMessage,
  taskRunningProps
}) => {
  const year = useYear();
  const notify = useNotify();
  const [method, setMethod] = useState(null);
  const [bulkUpdateDifferenceApplyMethodsByAllSelect, { loading: loadingUpdateByAllSelect }] = useMutation(
    BULK_UPDATE_DIFFERENCE_APPLY_METHODS_BY_ALL_SELECT
  );
  const [bulkUpdateDifferenceApplyMethodsByAllSelectAsync, { loading: loadingUpdateByAllSelectAsync }] = useMutation(
    BULK_UPDATE_DIFFERENCE_APPLY_METHODS_BY_ALL_SELECT_ASYNC
  );
  const loading = loadingUpdateByAllSelect || loadingUpdateByAllSelectAsync;

  const handleBulkUpdateAll = async () => {
    if (allSelectMode) {
      const bulkUpdateMutation = convertBulkUpdateByAllSelectMutation(
        selectedTotalCount,
        bulkUpdateDifferenceApplyMethodsByAllSelect,
        bulkUpdateDifferenceApplyMethodsByAllSelectAsync
      );

      await bulkUpdateMutation({
        variables: {
          input: {
            year,
            differenceApplyMethod: method.value,
            exceptEmployeeIds: Object.keys(_.pickBy(selected))
          }
        }
      });
    } else {
      const nextChanges = {
        ...state.changes,
        ...(selected |> fp.pickBy(fp.identity) |> fp.mapValues(() => ({ differenceApplyMethod: method.value })))
      };
      await handleBulkUpdate(nextChanges);
    }
  };

  return (
    <Modal>
      {({ showModal, hideModal }) => (
        <>
          <Button
            style={{ whiteSpace: 'nowrap' }}
            disabled={isDisabledSubmit()}
            disabledReason="従業員を選択してください"
            {...taskRunningProps}
            onClick={showModal}
          >
            従業員の反映方法一括変更
          </Button>
          <Modal.Modal>
            <Modal.Header>ステータス一括変更</Modal.Header>
            <Modal.Body style={{ overflow: 'visible' }}>
              選択された従業員のステータスを以下に変更します。
              <Select
                value={method}
                onChange={method => setMethod(method)}
                options={_.map(methods, (label, value) => ({ label, value }))}
                className="u-mt30"
                placeholder="反映方法を選択してください"
                searchable={false}
                clearable={false}
              />
            </Modal.Body>
            <Modal.Footer>
              <Modal.Buttons>
                <Button onClick={hideModal}>キャンセル</Button>
                <Button
                  primary
                  disabled={!method || loading}
                  disabledReason={!method ? '反映方法を選択してください' : ''}
                  onClick={async () => {
                    await handleBulkUpdateAll();
                    setState(initialState);
                    hideModal();
                    notify(successMessage);
                  }}
                >
                  保存する
                </Button>
              </Modal.Buttons>
            </Modal.Footer>
          </Modal.Modal>
        </>
      )}
    </Modal>
  );
};

export default BulkChangeMethod;
