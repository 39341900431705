export const NOT_AVAILABLE_TEXT = '※ 空欄の場合は対象外となります';
export const AVAILABLE_LIST = ['対象', '対象外'];
export const RESIDECESTATUS_LIST = ['同居', '別居（国内）', '別居（国外）'];
export const GENDER = ['男', '女'];
export const HANDICAP_CLASSIFICATION = ['対象外', '一般障害者', '特別障害者'];
export const RELATIONSHIP = [
  '本人',
  '夫',
  '妻',
  '実父',
  '実母',
  '義父',
  '義母',
  '長男',
  '長女',
  '兄',
  '姉',
  '弟',
  '妹',
  '祖父',
  '祖母',
  '叔父',
  '叔母'
];
export const DEPENDENT_RELATIONSHIP = [
  '実父',
  '実母',
  '義父',
  '義母',
  '長男',
  '長女',
  '次男',
  '次女',
  '三男',
  '三女',
  '兄',
  '姉',
  '弟',
  '妹',
  '祖父',
  '祖母',
  '孫息子',
  '孫娘',
  '叔父',
  '叔母',
  '甥',
  '姪'
];
export const SOCIAL_INSURANCE = [
  '国民年金',
  '国民年金基金',
  '国民健康保険',
  '後期高齢者医療保険',
  '介護保険',
  '任意継続保険',
  '農業者年金'
];
export const COLORS = ['青', '黄', '桃', '緑', '紫', '橙'];
export const NATIONAL_TYPE = ['日本人', '外国人'];
