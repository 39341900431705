import React from 'react'
import Icon from 'react-icon-base'

const UserAdd = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3">
      <path d="M26.366 13.01a8.422 8.422 0 0 0-16.843 0 8.422 8.422 0 0 0 16.843 0z"/>
      <path d="M26.433 24.194a14.353 14.353 0 0 0-8.488-2.762c-7.964 0-14.421 6.458-14.421 14.421M26.686 30.667h10.353M31.733 35.853V25.5"/>
    </g>
  </Icon>
)
UserAdd.defaultProps = {
  size: 40
}

export default UserAdd
