import React from 'react'
import Icon from 'react-icon-base'

const Memo = props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <g id="symbol" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon_memo" fill="currentColor">
        <g id="Page-1" transform="translate(2.000000, 1.000000)">
          <path d="M10.8543686,12.936685 L1.04574118,12.936685 L1.04574118,2.38912941 L2.49896993,2.38912941 L2.49896993,2.99943007 C2.49896993,3.28821438 2.7332183,3.52230588 3.02184575,3.52230588 C3.31063007,3.52230588 3.54472157,3.28821438 3.54472157,2.99943007 L3.54472157,2.38912941 L5.42707451,2.38912941 L5.42707451,2.99943007 C5.42707451,3.28821438 5.66132288,3.52230588 5.94995033,3.52230588 C6.23878693,3.52230588 6.47282614,3.28821438 6.47282614,2.99943007 L6.47282614,2.38912941 L8.35517908,2.38912941 L8.35517908,2.99943007 C8.35517908,3.28821438 8.58947974,3.52230588 8.8780549,3.52230588 C9.1668915,3.52230588 9.40093072,3.28821438 9.40093072,2.99943007 L9.40093072,2.38912941 L10.8543686,2.38912941 L10.8543686,12.936685 Z M9.40093072,1.34337778 L9.40093072,0.575168627 C9.40093072,0.286384314 9.1668915,0.0522928105 8.8780549,0.0522928105 C8.58947974,0.0522928105 8.35517908,0.286384314 8.35517908,0.575168627 L8.35517908,1.34337778 L6.47282614,1.34337778 L6.47282614,0.575168627 C6.47282614,0.286384314 6.23878693,0.0522928105 5.94995033,0.0522928105 C5.66132288,0.0522928105 5.42707451,0.286384314 5.42707451,0.575168627 L5.42707451,1.34337778 L3.54472157,1.34337778 L3.54472157,0.575168627 C3.54472157,0.286384314 3.31063007,0.0522928105 3.02184575,0.0522928105 C2.7332183,0.0522928105 2.49896993,0.286384314 2.49896993,0.575168627 L2.49896993,1.34337778 L0.522865359,1.34337778 C0.234081046,1.34337778 -1.04575163e-05,1.57741699 -1.04575163e-05,1.86625359 L-1.04575163e-05,13.4595608 C-1.04575163e-05,13.7483451 0.234081046,13.9824366 0.522865359,13.9824366 L11.3772444,13.9824366 C11.6658719,13.9824366 11.9001203,13.7483451 11.9001203,13.4595608 L11.9001203,1.86625359 C11.9001203,1.57741699 11.6658719,1.34337778 11.3772444,1.34337778 L9.40093072,1.34337778 Z" id="Fill-1"></path>
          <path d="M9.04554771,5.89831111 L2.85459346,5.89831111 C2.56575686,5.89831111 2.33171765,6.13261176 2.33171765,6.42118693 C2.33171765,6.71002353 2.56575686,6.94406275 2.85459346,6.94406275 L9.04554771,6.94406275 C9.33417516,6.94406275 9.56842353,6.71002353 9.56842353,6.42118693 C9.56842353,6.13261176 9.33417516,5.89831111 9.04554771,5.89831111" id="Fill-4"></path>
          <path d="M9.04554771,8.82638954 L2.85459346,8.82638954 C2.56575686,8.82638954 2.33171765,9.0606902 2.33171765,9.34926536 C2.33171765,9.63810196 2.56575686,9.87214118 2.85459346,9.87214118 L9.04554771,9.87214118 C9.33417516,9.87214118 9.56842353,9.63810196 9.56842353,9.34926536 C9.56842353,9.0606902 9.33417516,8.82638954 9.04554771,8.82638954" id="Fill-6"></path>
        </g>
      </g>
    </g>
  </Icon>
)
Memo.defaultProps = {
  size: 16
}

export default Memo
