import { FC } from 'react';
import { ProgressBar as Bar } from 'components/ui/ProgressBar';
import { Question, QuestionGroup } from '../../type';
import styles from './ProgressBar.scss';

type ProgressBarProps = {
  questionGroups: QuestionGroup[];
  currentQuestion: Question;
};

export const ProgressBar: FC<ProgressBarProps> = ({ questionGroups, currentQuestion }) => {
  const answeredQuestions = questionGroups.reduce((count, group) => {
    const finishedQuestionCount = group.questions.reduce((innerCount, question) => {
      const status = question.requestQuestion?.status;
      return status === 'answered' || status === 'skipped' ? innerCount + 1 : innerCount;
    }, 0);

    return count + finishedQuestionCount;
  }, 0);

  const questionName = currentQuestionName(questionGroups, currentQuestion);
  const total = totalQuestionsCount(questionGroups);

  return (
    <div className={styles.progress}>
      <div className={styles.caption}>
        <span>{questionName}</span>
        <span>
          {answeredQuestions}/{total}
        </span>
      </div>
      <Bar progress={progress(answeredQuestions, total)} />
    </div>
  );
};

const findQuestionIndex = (currentGroup: QuestionGroup, currentQuestion: Question) => {
  return currentGroup.questions.findIndex(question => question.id === currentQuestion?.id);
};

const currentQuestionName = (questionGroups: QuestionGroup[], currentQuestion: Question) => {
  const group = questionGroups.find(group => {
    return group.questions.some(question => question.code === currentQuestion.code);
  });

  if (group) {
    return `STEP ${group.id} - ${findQuestionIndex(group, currentQuestion) + 1} ${currentQuestion.name}`;
  } else {
    return currentQuestion.name;
  }
};

const progress = (answeredCount: number, total: number) => {
  return (answeredCount / total) * 100;
};

const totalQuestionsCount = (questionGroups: QuestionGroup[]) => {
  return questionGroups.reduce((total, group) => total + group.questions.length, 0);
};
