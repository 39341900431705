import React from 'react'
import Icon from 'react-icon-base'

const Contract = props => (
  <Icon viewBox="0 0 13 13" {...props}>
    <path d="M12.001.504A.503.503 0 0 0 11.498 0H.504A.503.503 0 0 0 0 .504v10.994c0 .278.225.503.504.503H5.96a.503.503 0 1 0 0-1.007H1.007V1.007h9.987v4.952a.503.503 0 1 0 1.007 0V.504zM9.753 6.269a.503.503 0 1 1 0 1.007H7.986l3.866 3.866a.503.503 0 1 1-.712.712L7.274 7.987v1.769a.504.504 0 0 1-1.007 0V6.772c0-.278.225-.503.503-.503h2.983z" fill="currentColor"/>
  </Icon>
)
Contract.defaultProps = {
  size: 13
}

export default Contract
