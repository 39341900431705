import React from 'react'
import Icon from 'react-icon-base'

const SingleEmployee = props => (
  <Icon viewBox="0 0 16 17" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.57 1.7a3.078 3.078 0 0 0-3.075 3.074A3.078 3.078 0 0 0 7.57 7.848a3.078 3.078 0 0 0 3.075-3.074A3.078 3.078 0 0 0 7.57 1.7m6.72 14.568a.85.85 0 0 1-.85-.85 5.828 5.828 0 0 0-1.72-4.15 5.827 5.827 0 0 0-4.15-1.719 5.878 5.878 0 0 0-5.87 5.869.85.85 0 1 1-1.7 0 7.582 7.582 0 0 1 4.557-6.943 4.765 4.765 0 0 1-1.762-3.701A4.78 4.78 0 0 1 7.57 0a4.78 4.78 0 0 1 4.774 4.774 4.763 4.763 0 0 1-1.756 3.697c.86.375 1.651.911 2.335 1.595a7.52 7.52 0 0 1 2.216 5.352c0 .47-.38.85-.849.85"
    />
  </Icon>
)
SingleEmployee.defaultProps = {
  size: 16
}

export default SingleEmployee
