import React from 'react';
import Button from 'jbc-front/components/Button';
import { Panel, Headding, Item, Lead, Buttons } from '../components/PrimaryPanel';
import { DescriptionEdit } from '../components/DescriptionEdit';
import { CustomDescription } from '../components/QuestionDescription';

const Question = () => {
  return (
    <Panel>
      <Headding icon={<DescriptionEdit />}>年内退職予定</Headding>
      <Item>
        <Lead>12月給与の支給前に退職する予定ですか？</Lead>
        <CustomDescription field="top" />
        <Buttons>
          <Button huge secondary disabled>
            退職予定です
          </Button>
          <Button huge secondary disabled>
            退職予定はありません
          </Button>
        </Buttons>
      </Item>
    </Panel>
  );
};

export default Question;
