import React from 'react';
import { Section } from 'jbc-front/components/Form';
import { renderLifeInsurances, LIFE_INSURANCES_FRAGMENT, makeInitialValues as lifeInsrances } from '../questions/LifeInsurances';
import { renderEarthquakeInsurances, EARTHQUAKE_INSURANCES_FRAGMENT } from '../questions/EarthquakeInsurances';
import { FieldArray } from 'redux-form';
import FormAdd from '../components/FormAdd';
import { renderSocialInsurances, SOCIAL_INSURANCES_FRAGMENT } from '../questions/SocialInsurances';
import { useFormValue, useAutofill } from '../components/FormName';
import { useYear } from '../components/useYear';
import { PREMIUM_FRAGMENT } from '../questions/Premium';
import { Loans, formatDetails, HOUSING_LOAN_FRAGMENT } from '../questions/HousingLoan';
import { DeleteSquare } from 'jbc-front/components/icons';
import update from 'lodash/fp/update';
import { gql } from '@apollo/client';
import { toFormValues } from '../utils/form';
import { FormSection, TextField, FileField, useDiff } from '../components/FieldWithDiff';
import { LabelMapper } from 'jbc-front/components/FormErrors';
import fp from 'lodash/fp';
import { amount } from '../validators';
import FilePreview from '../components/FilePreview';

export const INSURANCES_FRAGMENT = gql`
  fragment Insurances on Profile {
    ...LifeInsurances
    ...SocialInsurances
    ...EarthquakeInsurances
    ...Premium
    ...HousingLoan
  }
  ${LIFE_INSURANCES_FRAGMENT}
  ${EARTHQUAKE_INSURANCES_FRAGMENT}
  ${SOCIAL_INSURANCES_FRAGMENT}
  ${PREMIUM_FRAGMENT}
  ${HOUSING_LOAN_FRAGMENT}
`;

const Insurances = () => {
  const year = useYear();
  const { fixed } = useDiff();
  const hasPremium = useFormValue('insurances.premium');
  const hasHousingLoan = useFormValue('insurances.housingLoan');
  const { autofill } = useAutofill();
  return (
    <Section title="保険・ローン">
      <FormSection name="insurances">
        <LabelMapper name="insurances" label="保険・ローンの" />
        <LabelMapper name="generalLifeInsurances" label="一般の生命保険" />
        <LabelMapper name="nursingMedicalInsurances" label="介護医療保険" />
        <LabelMapper name="individualAnnuityInsurances" label="個人年金保険" />
        <LabelMapper name="earthquakeInsurances" label="地震保険" />
        <LabelMapper name="socialInsurances" label="社会保険" />
        <FieldArray
          name="generalLifeInsurances"
          component={renderLifeInsurances}
          insuranceName="一般の生命保険"
          maxCount={4}
          fixed={fixed}
        />
        <FieldArray name="nursingMedicalInsurances" component={renderLifeInsurances} insuranceName="介護医療保険" newOnly fixed={fixed} />
        <FieldArray
          name="individualAnnuityInsurances"
          component={renderLifeInsurances}
          insuranceName="個人年金保険"
          isPension
          fixed={fixed}
        />
        <FieldArray name="earthquakeInsurances" component={renderEarthquakeInsurances} fixed={fixed} />
        <FieldArray component={renderSocialInsurances} name="socialInsurances" fixed={fixed} />

        {hasPremium ? (
          <Section
            title="共済契約掛金・個人型企業型年金掛金"
            icon={
              fixed ? (
                undefined
              ) : (
                <DeleteSquare
                  onClick={() => {
                    autofill('insurances.premium', null);
                  }}
                />
              )
            }
          >
            <FormSection name="premium">
              <LabelMapper name="premium" label="共済契約掛金・個人型企業型年金掛金の" />
              <TextField name="smallAndMediumSizedEnterprises" label="独立行政法人中小企業基盤整備機構の共済契約の掛金" validate={amount} />
              <FileField
                name="smallAndMediumSizedEnterprisesImage"
                label="独立行政法人中小企業基盤整備機構の共済契約の掛金 証明書画像"
                preview={FilePreview}
                description="対応可能形式：jpg, jpeg, gif, png, pdf"
                disablePreview={false}
              />
              <TextField name="corporatePension" label="確定拠出年金法に規定する企業型年金加入者掛金" validate={amount} />
              <FileField
                name="corporatePensionImage"
                label="確定拠出年金法に規定する企業型年金加入者掛金 証明書画像"
                preview={FilePreview}
                description="対応可能形式：jpg, jpeg, gif, png, pdf"
                disablePreview={false}
              />
              <TextField name="personalPension" label="確定拠出年金法に規定する個人型年金加入者掛金" validate={amount} />
              <FileField
                name="personalPensionImage"
                label="確定拠出年金法に規定する個人型年金加入者掛金 証明書画像"
                preview={FilePreview}
                description="対応可能形式：jpg, jpeg, gif, png, pdf"
                disablePreview={false}
              />
              <TextField name="disabilityMutualAid" label="心身障害者扶養共済制度に関する契約の掛金" validate={amount} />
              <FileField
                name="disabilityMutualAidImage"
                label="心身障害者扶養共済制度に関する契約の掛金 証明書画像"
                preview={FilePreview}
                description="対応可能形式：jpg, jpeg, gif, png, pdf"
                disablePreview={false}
              />
            </FormSection>
          </Section>
        ) : (
          <FormAdd
            name="共済契約掛金・個人型企業型年金掛金"
            onClick={() => {
              autofill('insurances.premium', {});
            }}
          />
        )}
        {hasHousingLoan ? (
          <Section
            title="住宅ローン控除"
            icon={
              fixed ? (
                undefined
              ) : (
                <DeleteSquare
                  onClick={() => {
                    autofill('insurances.housingLoan', null);
                  }}
                />
              )
            }
          >
            <FormSection name="housingLoan">
              <LabelMapper name="housingLoan" label="住宅ローンの" />
              <Loans field="insurances.housingLoan" year={year} />
            </FormSection>
          </Section>
        ) : (
          <FormAdd
            name="住宅ローン控除"
            onClick={() => {
              autofill('insurances.housingLoan', { housingLoanDeductionApplicableCount: '1' });
            }}
          />
        )}
      </FormSection>
    </Section>
  );
};

export const formatValues = values => (values.insurances?.housingLoan ? update('insurances.housingLoan', formatDetails, values) : values);

export const makeInitialValues = profile => ({
  ...lifeInsrances(profile),
  earthquakeInsurances: (profile.earthquakeInsurances || []).map(v => toFormValues(v)),
  socialInsurances: profile.socialInsurances.map(toFormValues),
  premium: profile.premium && toFormValues(profile.premium, true),
  housingLoan: profile.housingLoan
    ? {
        ...toFormValues(profile.housingLoan, true),
        ...Object.assign(
          {},
          ...(profile.housingLoan.housingLoanDetails || []).map(
            (v, idx) => v |> toFormValues |> fp.mapKeys(k => `housingLoanDetails${idx}${k}`)
          )
        )
      }
    : null
});

export default Insurances;
