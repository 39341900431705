import React from 'react'
import Icon from 'react-icon-base'

const AutoCalculation = props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <g>
      <path d="M7.762,7.129L5.155,3.813C4.899,3.487,4.956,3.016,5.282,2.76c0.326-0.256,0.797-0.2,1.053,0.126&#xA;&#x9;&#x9;l1.426,1.815l1.425-1.815C9.44,2.562,9.912,2.502,10.239,2.76c0.326,0.256,0.383,0.727,0.127,1.053L7.762,7.129z" fill="currentColor"/>
      <path d="M7.693,12.686c-0.414,0-0.75-0.336-0.75-0.75V5.579c0-0.414,0.336-0.75,0.75-0.75s0.75,0.336,0.75,0.75&#xA;&#x9;&#x9;v6.357C8.443,12.35,8.107,12.686,7.693,12.686z" fill="currentColor"/>
      <path d="M9.831,7.986H5.583c-0.414,0-0.75-0.336-0.75-0.749c0-0.414,0.336-0.75,0.75-0.75h4.248&#xA;&#x9;&#x9;c0.414,0,0.75,0.336,0.75,0.75C10.581,7.65,10.245,7.986,9.831,7.986z" fill="currentColor"/>
      <path d="M9.831,10.178H5.583c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75h4.248&#xA;&#x9;&#x9;c0.414,0,0.75,0.336,0.75,0.75S10.245,10.178,9.831,10.178z" fill="currentColor"/>
    </g>
    <g>
      <path d="M7.5,14.5c-3.998,0-7.25-3.252-7.25-7.25C0.25,3.252,3.502,0,7.5,0c3.998,0,7.25,3.252,7.25,7.25&#xA;&#x9;&#x9;c0,1.611-0.518,3.137-1.496,4.412l-1.189-0.914C12.84,9.736,13.25,8.527,13.25,7.25c0-3.17-2.579-5.75-5.75-5.75&#xA;&#x9;&#x9;c-3.17,0-5.75,2.58-5.75,5.75C1.75,10.42,4.33,13,7.5,13V14.5z" fill="currentColor"/>
      <g>
        <polygon fill="currentColor" points="11.621,8.973 10.118,13.201 14.531,12.389 &#x9;&#x9;"/>
      </g>
    </g>
  </Icon>
)
AutoCalculation.defaultProps = {
  size: 15
}

export default AutoCalculation
