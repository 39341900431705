import { FC } from 'react';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useReactRouter from 'use-react-router';
// @ts-ignore
import { Link } from 'react-router-dom';

import { Upload } from 'jbc-front/components/icons';
import Button from 'jbc-front/components/Button';

import { year } from '@jbc-year-end-adj/2024/consts';
import { useAdminMutation as useMutation } from '@jbc-year-end-adj/2024/hooks/graphql/useMutation';
import { useNotify } from '@jbc-year-end-adj/2024/hooks/useNotify';
import { useAsyncTask } from '@jbc-year-end-adj/2024/features/AsyncTask';
import { FileField } from '@jbc-year-end-adj/2024/components/react-hook-form/FileField';
import { FileImportDescription } from './parts/FileImportDescription';
import { schema, Schema } from './schema';
import styles from './ImportUpdate.scss';

import { IMPORT_EMPLOYEES_CSV } from './mutation';

export const ImportUpdate: FC = () => {
  const [importEmployeesCsv] = useMutation(IMPORT_EMPLOYEES_CSV);
  const notify = useNotify();
  const { history } = useReactRouter();
  const { taskRunningProps } = useAsyncTask();

  const methods = useForm<Schema>({
    resolver: yupResolver(schema)
  });

  const { handleSubmit, watch, setValue } = methods;

  const file = watch('file');

  const onSubmit: SubmitHandler<Schema> = async data => {
    if (!data.file) {
      notify('ファイルを選択してください', 'error');
      return;
    }

    await importEmployeesCsv({ variables: { input: { file: data.file } } });
    history.push(`/${year}`);
  };

  return (
    <div>
      <div className="l-contents-wrap">
        <h1 className="m-title-main">従業員データの一括更新</h1>
        <div className={styles.wrap}>
          <div className={styles.head}>アップロード手順</div>
          <div className={styles.body}>
            <dl className={styles.label}>
              <dt className={styles.number}>1</dt>
              <dd className={styles.detail}>
                <p>
                  従業員データを
                  <Link to={`/${year}`} className="u-txt-link">
                    こちら
                  </Link>
                  の従業員データダウンロードよりダウンロードしてください。
                </p>
              </dd>
            </dl>
            <FormProvider {...methods}>
              <dl className={styles.label}>
                <dt className={styles.number}>2</dt>
                <dd className={styles.detail}>
                  <p className={styles.text}>下の説明を参考にしながら、ダウンロードしたCSVファイルに、変更したいデータを入力して下さい。</p>
                  <p className={styles.text}>
                    <span className={styles.important}>【注意】「スタッフコード」</span>
                    は必ず項目に入れる必要があります。
                    <br />
                    ※「スタッフコード」とその他必要な項目に絞って更新が可能です。
                    <br />
                    ただし、画面上で「必須」となっている項目を更新する際は「空白」での更新はできかねます。（例：姓、名&emsp;等）
                  </p>
                  <p className={styles.text}>
                    <span className={styles.important}>
                      【注意】前職情報、扶養情報、保険情報、メモ情報の更新には必ず「ID」が必要となります。
                      <br />
                    </span>
                    ※「ID」の更新ルールは下記ヘルプページをご確認ください。
                    <br />
                    ■&nbsp;
                    <a
                      className="u-txt-link"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://jobcan-payroll.zendesk.com/hc/ja/articles/900002468983#Contentsid"
                    >
                      従業員情報CSV更新「ID」の更新ルール
                    </a>
                  </p>
                  <p>
                    <span className={styles.important}>
                      ※ステータスが「入力済」「修正済」「再依頼中」（従業員回答後のステータス）の場合は従業員の回答した内容は更新されません。
                    </span>
                  </p>
                </dd>
              </dl>
              <dl className={styles.label}>
                <dt className={styles.number}>3</dt>
                <dd className={styles.detail}>
                  <p className={styles.text}>完成したファイルを選択し、アップロードして下さい。</p>
                  <FileField.FileContainer>
                    <FileField name="file" />
                    <FileField.File file={file} onRemove={() => setValue('file', undefined)} />
                  </FileField.FileContainer>
                </dd>
              </dl>
              <Button icon={<Upload size={16} />} primary className={styles.large} {...taskRunningProps} onClick={handleSubmit(onSubmit)}>
                アップロード
              </Button>
            </FormProvider>
          </div>
        </div>
        <FileImportDescription />
      </div>
    </div>
  );
};
