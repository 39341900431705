import { yup } from '@jbc-year-end-adj/common/yup';
import { Profile } from '../../../../query';
import { FileType } from '@jbc-year-end-adj/types';
import { amountFormat } from '@jbc-year-end-adj/common/utils/formatter';

type IsDisasterSufferer = 'disasterSufferer' | 'notDisasterSufferer';

export type Schema = {
  salaryMoreThanOthers: 'first' | 'second';
  salaryLessThanOthersNextYear: 'first' | 'second';
  workingStudentClassification: {
    classification: 'none' | 'working_student';
    detail?: string;
    image?: File | FileType;
  };
  handicapClassification: {
    classification: 'none' | 'normal' | 'special';
    detail?: string;
    image?: File | FileType;
  };
  widowClassification: {
    classification: 'none' | 'normal' | 'special' | 'single_parent';
  };
  isDisasterSufferer: IsDisasterSufferer;
  taxPostponementAmount: string;
};

const taxPostponementAmountConditions = {
  is: (isDisasterSufferer: IsDisasterSufferer) => isDisasterSufferer === 'disasterSufferer',
  then: () =>
    yup
      .string()
      .positiveAmountFormat()
      .maxAmount(999999999)
      .required()
      .label('徴収猶予税額'),
  otherwise: () => yup.string().nullable()
};

export const schema = yup.object({
  salaryMoreThanOthers: yup
    .mixed<'first' | 'second'>()
    .required()
    .label('今年の税区分'),
  salaryLessThanOthersNextYear: yup
    .mixed<'first' | 'second'>()
    .required()
    .label('来年の税区分'),
  workingStudentClassification: yup.object({
    classification: yup
      .mixed<'none' | 'working_student'>()
      .required()
      .label('勤労学生区分'),
    detail: yup
      .string()
      .max(2000, '勤労学生区分の詳細は2000文字までで入力してください')
      .label('勤労学生区分詳細')
      .nullable(),
    image: yup.mixed<File | FileType>()
  }),
  handicapClassification: yup.object({
    classification: yup
      .mixed<'none' | 'normal' | 'special'>()
      .required()
      .label('障害者区分'),
    detail: yup
      .string()
      .max(2000, '障害者区分の詳細は2000文字までで入力してください')
      .label('障害者区分')
      .nullable(),
    image: yup.mixed<File | FileType>()
  }),
  widowClassification: yup.object({
    classification: yup
      .mixed<'none' | 'normal' | 'special' | 'signle_parent'>()
      .required()
      .label('ひとり親・寡婦区分')
  }),
  isDisasterSufferer: yup
    .mixed<IsDisasterSufferer>()
    .oneOf(['disasterSufferer', 'notDisasterSufferer'])
    .required()
    .label('（源泉徴収票）災害者'),
  taxPostponementAmount: yup.string().when('isDisasterSufferer', taxPostponementAmountConditions)
});

export const generateDefaultValues = (profile: Profile | undefined): Schema => {
  const generateFileTypeObj = (image: FileType | undefined) => {
    if (!image) return undefined;
    if (!image.url && !image.filename && !image.file) return undefined;

    return image;
  };

  const isDisasterSufferer: IsDisasterSufferer = profile?.isDisasterSufferer ? 'disasterSufferer' : 'notDisasterSufferer';

  return {
    salaryMoreThanOthers: !profile?.workForMultiCompanies || profile?.salaryMoreThanOthers ? 'first' : 'second',
    salaryLessThanOthersNextYear: profile?.salaryLessThanOthersNextYear ? 'second' : 'first',
    workingStudentClassification: {
      classification: profile?.workingStudentClassification?.classification || 'none',
      detail: profile?.workingStudentClassification?.detail,
      image: generateFileTypeObj(profile?.workingStudentClassification?.image)
    },
    handicapClassification: {
      classification: profile?.handicapClassification?.classification || 'none',
      detail: profile?.handicapClassification?.detail,
      image: generateFileTypeObj(profile?.handicapClassification?.image)
    },
    widowClassification: {
      classification: profile?.widowClassification?.classification || 'none'
    },
    isDisasterSufferer,
    taxPostponementAmount: amountFormat(profile?.taxPostponementAmount)
  };
};
