import { yup } from '@jbc-year-end-adj/common/yup';

const FILE_ENCODING = ['utf8', 'sjis'] as const;
const TARGET_DATA = ['employee', 'employee_request'] as const;
const DOWNLOAD_TARGET = ['all', 'search'] as const;

type FileEncoding = typeof FILE_ENCODING[number];
type TargetData = typeof TARGET_DATA[number];
type DownloadTarget = typeof DOWNLOAD_TARGET[number];

export type Schema = {
  fileEncoding: FileEncoding;
  targetData: TargetData;
  downloadTarget: DownloadTarget;
};

export const schema = yup.object({
  fileEncoding: yup
    .mixed<FileEncoding>()
    .oneOf(FILE_ENCODING)
    .required(),
  targetData: yup
    .mixed<TargetData>()
    .oneOf(TARGET_DATA)
    .required(),
  downloadTarget: yup
    .mixed<DownloadTarget>()
    .oneOf(DOWNLOAD_TARGET)
    .required()
});

export const buildDefaultValues = (hasConditions: boolean): Schema => ({
  fileEncoding: 'utf8',
  targetData: 'employee',
  downloadTarget: hasConditions ? 'search' : 'all'
});
