import isEmail from 'validator/lib/isEmail';
export const zenkakuKatakanaWithSpace = value =>
  value && !value.match(/^[\u30A0-\u30FF\u3000 ]+$/) ? 'を全角カタカナとスペースで入力してください' : undefined;

export const email = value => (value && !isEmail(value) ? 'が正しくありません' : undefined);

const maxLengthValidators = [];
export const maxLength = max => {
  if (!maxLengthValidators[max]) {
    maxLengthValidators[max] = value => (value && value.length > max ? `を${max}文字以内で入力してください` : undefined);
  }
  return maxLengthValidators[max];
};

export const staffCode = value => (value && !value.match(/^[\w-_]+$/) ? 'は半角英数字と「-」「_」で入力してください。' : undefined);

export const noHankaku = value => (value && value.match(/[ -~]+/) ? 'を全角で入力してください' : undefined);

export const zenkakuKatakanaLoose = value =>
  value && value.match(/[\u4E00-\u9FFF\u3005-\u3006\u3040-\u309f]/) ? 'を全角カタカナとスペースで入力してください' : undefined;

const maxLengthAmountValudators = [];
export const maxLengthAmount = length => {
  if (!maxLengthAmountValudators[length]) {
    maxLengthAmountValudators[length] = value => {
      if (value && !value.match(/^[\d,]+$/)) {
        return 'を正の半角数値と「,」で入力してください';
      }
      if (value && value.replace(/,/g, '').length > length) {
        return `を${length}桁以内の数値で入力してください`;
      }
    };
  }
  return maxLengthAmountValudators[length];
};

export const amount = maxLengthAmount(10);

export const amountAllowMinus = value => {
  if (value && !value.match(/^[\d,-]+$/)) {
    return 'を半角数値と「,」で入力してください';
  }
  if (value && value.replace(/,|-/g, '').length > 10) {
    return 'を10桁以内の数値で入力してください';
  }
};

export const dateString = value => {
  if (!value) return undefined;

  if (!/^\d{4}[/-]\d{1,2}[/-]\d{1,2}$/.test(value)) {
    return 'の入力形式が正しくありません';
  }

  return undefined;
};

export const number = value => (value && !value.match(/^\d+$/) ? 'を半角数字で入力してください' : undefined);

const maxAgeValidators = [];
export const maxAge = max => {
  if (!maxAgeValidators[max]) {
    maxAgeValidators[max] = value => {
      if (value) {
        const parsed = +value;
        if (number(value) || isNaN(parsed) || parsed < 0 || parsed > max) {
          return `は0から${max}までの半角数字で入力してください`;
        }
      }
      return undefined;
    };
  }
  return maxAgeValidators[max];
};

const maxTenThousandYenValidators = [];
export const maxTenThousandYen = max => {
  if (!maxTenThousandYenValidators[max]) {
    maxTenThousandYenValidators[max] = value => {
      if (value) {
        const parsed = +value.replace(/,/g, '');
        if (parsed > max) {
          const yen = max / 10_000;
          return `は${yen}万円以内で入力してください`;
        }
      }
      return undefined;
    };
  }
  return maxTenThousandYenValidators[max];
};

export const designationNumber = value =>
  value && !value.match(/^[a-zA-Z0-9ｦ-ﾝﾞﾟ\--]*$/) ? '半角カタカナ、半角英数字、半角ハイフンで入力してください' : undefined;
