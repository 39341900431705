import { FC, ReactNode, createContext, useContext, useState, useCallback } from 'react';
import { EditSquare, Close, ArrowDownSquare } from 'jbc-front/components/icons';
import { Section } from 'components/ui/Section';
// @ts-ignore
import { useSession } from 'components/SessionProvider';

import styles from './ToggleSection.scss';
import classnames from 'classnames';

type ViewType = 'form' | 'preview';

type ToggleContextType = {
  isShow: boolean;
  toggle: () => void;
  viewType: ViewType;
  setViewType: (viewType: ViewType) => void;
};

const ToggleContext = createContext<ToggleContextType | null>(null);

type ToggleSectionProps = {
  children: ReactNode;
  setViewType: (viewType: ViewType) => void;
  viewType: ViewType;
};

export const ToggleSection: FC<ToggleSectionProps> = ({ viewType, setViewType, children }) => {
  const [isShow, setIsShow] = useState<boolean>(true);
  const toggle = useCallback(() => setIsShow(prev => !prev), []);

  return (
    <ToggleContext.Provider value={{ isShow, toggle, viewType, setViewType }}>
      <Section>{children}</Section>
    </ToggleContext.Provider>
  );
};

type HeaderProps = {
  children: ReactNode;
};

export const Header: FC<HeaderProps> = ({ children }) => {
  const { isShow, toggle, viewType, setViewType } = useToggleSectionContext();
  const { clientYearly } = useSession();

  const Icon = () => {
    switch (viewType) {
      case 'form':
        return <Close className={styles.icon} onClick={() => setViewType('preview')} />;
      case 'preview':
        if (clientYearly.fixed) {
          return (
            <div className={styles.disableEditSquare}>
              <EditSquare className={classnames(styles.icon, styles.editSquare, styles.disabled)} />
              <DisabledReason />
            </div>
          );
        } else {
          return <EditSquare className={classnames(styles.icon, styles.editSquare)} onClick={() => setViewType('form')} />;
        }
    }
  };

  return (
    <Section.Header className={classnames({ [styles.hiddenHeader]: !isShow }, styles.sectionHeader)}>
      <div className={styles.header}>
        {children}
        <div className={styles.iconContainer}>
          <Icon />
          <ArrowDownSquare className={styles.icon} onClick={toggle} transform={isShow ? 'rotate(180)' : 'rotate(0)'} />
        </div>
      </div>
    </Section.Header>
  );
};

type BodyProps = {
  children: ReactNode;
};

export const Body: FC<BodyProps> = ({ children }) => {
  const { isShow, viewType } = useToggleSectionContext();
  const className = classnames({ [styles.hiddenBody]: !isShow, [styles.form]: viewType === 'form' });

  return <Section.Body className={className}>{children}</Section.Body>;
};

const useToggleSectionContext = () => {
  const context = useContext(ToggleContext);

  if (!context) throw Error('プロバイダーを設定してください');

  return context;
};

const DisabledReason = () => (
  <div className={styles.disabledReason}>
    <div className={styles.disabledReasonShape} />
    <div className={styles.disabledReasonMessage}>情報が確定されてます</div>
  </div>
);
