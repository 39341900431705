import React from 'react';
import styles from '../components/Progress.scss';

export const Progress = ({ progress, large, ...rest }) => {
  const barSize = large ? styles.barLarge : styles.bar;
  const valueSize = large ? styles.valueLarge : styles.value;

  return (
    <div className={styles.progress} {...rest}>
      <div className={`${barSize}`}>
        <div className={`${valueSize}`} style={{ width: `${progress}%` }} />
      </div>
    </div>
  );
};

export default Progress;
