import React from 'react'
import Icon from 'react-icon-base'

const Custom5 = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="Custom5" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon/employees/Custom5" fill="#3498DB" fillRule="nonzero">
        <g id="Group-2" transform="translate(5.000000, 5.000000)">
          <path d="M23.176128,17.5421436 L16.676128,28.5421436 C16.3216098,29.1420975 16.7540919,29.9 17.4509619,29.9 L30.4509619,29.9 C31.1478319,29.9 31.580314,29.1420975 31.2257958,28.5421436 L24.7257958,17.5421436 C24.3774402,16.9526188 23.5244836,16.9526188 23.176128,17.5421436 Z M23.951,19.768 L28.874,28.1 L19.027,28.1 L23.951,19.768 Z" id="Triangle"></path>
          <path d="M4.845,3.894 L0.713185901,4.49527283 L0.600369565,4.51868412 C-0.05374349,4.69696082 -0.291657489,5.53711872 0.214393864,6.03039687 L3.204,8.945 L2.49845574,13.0600165 L2.48614811,13.1688981 C2.44924677,13.849029 3.17705884,14.3385409 3.80431025,14.0087753 L7.5,12.065 L11.1956898,14.0087753 L11.295439,14.0541269 C11.9308788,14.2993941 12.6213386,13.7584712 12.5015443,13.0600165 L11.795,8.945 L14.7856061,6.03039687 L14.8631157,5.94514497 C15.2875158,5.41643808 14.9861599,4.59689372 14.2868141,4.49527283 L10.154,3.894 L8.30706247,0.150728965 C7.97693015,-0.518192181 7.02306985,-0.518192181 6.69293753,0.150728965 L4.845,3.894 Z M7.5,2.582 L8.75018591,5.11578777 L8.80491898,5.21146041 C8.94525477,5.4252338 9.17133315,5.57085378 9.4278299,5.60812494 L12.223,6.014 L10.2004873,7.98611986 L10.1167324,8.08002952 C9.96371178,8.2778763 9.89872962,8.53249129 9.94165032,8.78273814 L10.419,11.566 L7.91880701,10.2524198 L7.80361193,10.2017839 C7.5681625,10.1173906 7.30592866,10.1342693 7.08119299,10.2524198 L4.58,11.566 L5.05834968,8.78273814 L5.07091009,8.65753374 C5.07841496,8.40752907 4.9813277,8.16334566 4.79951271,7.98611986 L2.776,6.014 L5.5721701,5.60812494 C5.86530925,5.56552933 6.11871828,5.38141689 6.24981409,5.11578777 L7.5,2.582 Z" id="Star"></path>
          <path d="M8,17 C4.13400675,17 1,20.1340068 1,24 C1,27.8659932 4.13400675,31 8,31 C11.8659932,31 15,27.8659932 15,24 C15,20.1340068 11.8659932,17 8,17 Z M8,19 C10.7614237,19 13,21.2385763 13,24 C13,26.7614237 10.7614237,29 8,29 C5.23857625,29 3,26.7614237 3,24 C3,21.2385763 5.23857625,19 8,19 Z" id="Oval"></path>
          <path d="M23.3631766,0.190983006 L17.1813093,4.68237254 C16.8308219,4.93701655 16.6841637,5.38838387 16.818038,5.80040653 L19.1793012,13.0676275 C19.3131755,13.4796501 19.6971315,13.7586105 20.1303578,13.7586105 L27.771566,13.7586105 C28.2047923,13.7586105 28.5887483,13.4796501 28.7226226,13.0676275 L31.0838858,5.80040653 C31.21776,5.38838387 31.0711019,4.93701655 30.7206145,4.68237254 L24.5387471,0.190983006 C24.1882597,-0.0636610019 23.7136641,-0.0636610019 23.3631766,0.190983006 Z M23.951,2.236 L28.957,5.873 L27.045,11.758 L20.856,11.758 L18.944,5.873 L23.951,2.236 Z" id="Polygon"></path>
        </g>
      </g>
    </g>
  </Icon>
)
Custom5.defaultProps = {
  size: 30
}

export default Custom5