import _ from 'lodash';

export const limit = upperLimit => x => (_.isNaN(x) ? x : _.min([x, upperLimit]));

export const sumWithLimit = (values, limit) => _.min([_.sum(values), limit]);

export const calcGeneralLifeInsuranceOldType = x => {
  if (100000 < x) {
    return 50000;
  } else if (50000 < x) {
    return 25000 + Math.ceil(x / 4);
  } else if (25000 < x) {
    return 12500 + Math.ceil(x / 2);
  }
  return x;
};

export const calcGeneralLifeInsuranceNewType = x => {
  if (80000 < x) {
    return 40000;
  } else if (40000 < x) {
    return 20000 + Math.ceil(x / 4);
  } else if (20000 < x) {
    return 10000 + Math.ceil(x / 2);
  }
  return x;
};

export const calcIndividualAnnuityInsuranceNewType = calcGeneralLifeInsuranceNewType;
export const calcIndividualAnnuityInsuranceOldType = calcGeneralLifeInsuranceOldType;
export const calcNursingMedicalInsuranceNewType = calcGeneralLifeInsuranceNewType;

export const calcEarthquakeInsuranceNewType = value => value |> limit(50000);

export const calcEarthquakeInsuranceOldType = value => {
  const calc = x => {
    if (10000 < x) {
      return 5000 + Math.ceil(x / 2);
    }
    return x;
  };
  return value |> calc |> limit(15000);
};
