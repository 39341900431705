import React from 'react'
import Icon from 'react-icon-base'

const ArrowBold = props => (
  <Icon viewBox="0 0 15 14" {...props}>
    <path fill='currentColor' fillRule='evenodd' d='M6.455 13.497c.339.433.894.433 1.232 0l5.96-7.617c.34-.433.167-.788-.382-.788h-2.23c-.55 0-1-.45-1-1V.678c0-.55-.45-1-1-1H5.11c-.55 0-1 .45-1 1v3.414c0 .55-.45 1-1 1H.879c-.55 0-.722.355-.383.788l5.959 7.617z' />
  </Icon>
)
ArrowBold.defaultProps = {
  size: 15
}

export default ArrowBold
