import React from 'react';
import Button from 'jbc-front/components/Button';
import { CheckboxField, TextAreaField } from 'jbc-front/components/Form';
import { useFormValue } from '../components';
import Modal from '../components/Modal';
import { maxLength } from '../validators';
import { useNotify } from '../actions';
import styles from './AcceptInformation.scss';

export const AcceptInformation = ({ client, handleSubmit, submitting, widthWide = null }) => {
  const notify = useNotify();
  const sendEmail = useFormValue('sendEmail');
  const sendAcceptedEmail = client.clientYearly?.clientSetting?.sendAcceptedEmail;

  return (
    <Modal>
      {({ isOpen, showModal, hideModal }) => (
        <>
          <Button primary onClick={showModal} widthWide={widthWide} disabled={submitting}>
            承認
          </Button>
          <Modal.Modal isOpen={isOpen}>
            <Modal.Header>承認の確認</Modal.Header>
            <Modal.Body>
              <div className="form_box_main">
                <div className={styles.bodyMessage}>入力内容を承認してもよろしいですか？</div>
                {!sendAcceptedEmail && <CheckboxField label="承認完了メールを送信する" name="sendEmail" />}
                {sendEmail && (
                  <TextAreaField
                    name="comment"
                    label="コメント"
                    placeholder="従業員に通知したい内容など入力してください"
                    validate={[maxLength(1000)]}
                  />
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Modal.Buttons>
                <Button onClick={hideModal}>キャンセル</Button>
                <Button
                  primary
                  onClick={async (values = {}) => {
                    await handleSubmit('accept');
                    hideModal();
                    notify('従業員情報を承認しました');
                  }}
                  disabled={submitting || client.clientYearly?.fixed}
                >
                  承認
                </Button>
              </Modal.Buttons>
            </Modal.Footer>
          </Modal.Modal>
        </>
      )}
    </Modal>
  );
};

export const makeInitialValues = sendEmail => ({ sendEmail, comment: '' });
