import { gql } from '@apollo/client';
import { FileType } from '@jbc-year-end-adj/types';
import {
  Schema,
  LifeInsuranceSchema,
  EarthquakeInsuranceSchema,
  SocialInsuranceSchema,
  LifeInsuranceType,
  NewOrOldType,
  SocialInsuranceType
} from './schema';

export type QueryVariables = {
  input: {
    employeeId: string;
    attributes: {
      lifeInsurances: LifeInsurance[];
      earthquakeInsurances: EarthquakeInsurance[];
      socialInsurances: SocialInsurance[];
    };
  };
};

type LifeInsurance = {
  id?: string;
  type: LifeInsuranceType;
  name: string;
  insuranceType: string;
  insuranceDuration: string;
  nameOfContractor: string;
  nameOfRecipient: string;
  relationshipOfRecipient: string;
  newOrOld: NewOrOldType;
  amount: string;
  image: File | FileType | null;
  paymentStartDate?: string;
};

type EarthquakeInsurance = {
  id?: string;
  name: string;
  insuranceType: string;
  insuranceDuration: string;
  nameOfContractor: string;
  nameOfRecipient: string;
  relationshipOfRecipient: string;
  newOrOld: NewOrOldType;
  amount: string;
  image: File | FileType | null;
};

type SocialInsurance = {
  id?: string;
  insuranceType: SocialInsuranceType;
  name: string;
  nameOfPayer: string;
  relationshipOfPayer: string;
  amount: string;
  image: File | FileType | null;
};

export const UPDATE_INSURANCES = gql`
  mutation UpdateInsurances($input: UpdateInsurancesInput!) {
    updateInsurances(input: $input) {
      employee {
        id
      }
    }
  }
`;

export const convertFormDataToQueryVariables = (data: Schema, employeeId: string): QueryVariables => {
  const lifeInsurances = convertLifeInsurance(data.lifeInsurances);
  const earthquakeInsurances = convertEarthquakeInsurance(data.earthquakeInsurances);
  const socialInsurances = convertSocialInsurance(data.socialInsurances);

  return {
    input: {
      employeeId,
      attributes: {
        lifeInsurances,
        earthquakeInsurances,
        socialInsurances
      }
    }
  };
};

const convertLifeInsurance = (insurances: LifeInsuranceSchema[]): LifeInsurance[] => {
  return insurances.map(insurance => {
    const newOrOld = insurance.lifeInsuranceType === 'NursingMedicalInsurance' ? 'new_type' : insurance.newOrOld;

    const paymentStartDate = insurance.lifeInsuranceType === 'IndividualAnnuityInsurance' ? insurance.paymentStartDate : undefined;

    return {
      id: insurance.id,
      type: insurance.lifeInsuranceType,
      name: insurance.name,
      insuranceType: insurance.insuranceType,
      insuranceDuration: insurance.insuranceDuration,
      nameOfContractor: insurance.nameOfContractor,
      nameOfRecipient: insurance.nameOfRecipient,
      relationshipOfRecipient: insurance.relationshipOfRecipient,
      newOrOld,
      amount: insurance.amount,
      image: insurance.image,
      paymentStartDate
    };
  });
};

const convertEarthquakeInsurance = (insurances: EarthquakeInsuranceSchema[]): EarthquakeInsurance[] => {
  return insurances.map(insurance => {
    return {
      id: insurance.id,
      name: insurance.name,
      insuranceType: insurance.insuranceType,
      insuranceDuration: insurance.insuranceDuration,
      nameOfContractor: insurance.nameOfContractor,
      nameOfRecipient: insurance.nameOfRecipient,
      relationshipOfRecipient: insurance.relationshipOfRecipient,
      newOrOld: insurance.newOrOld,
      amount: insurance.amount,
      image: insurance.image
    };
  });
};

const convertSocialInsurance = (insurances: SocialInsuranceSchema[]): SocialInsurance[] => {
  return insurances.map(insurance => {
    return {
      id: insurance.id,
      insuranceType: insurance.insuranceType,
      name: insurance.name,
      nameOfPayer: insurance.nameOfPayer,
      relationshipOfPayer: insurance.relationshipOfPayer,
      amount: insurance.amount,
      image: insurance.image
    };
  });
};
