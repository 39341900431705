import React from 'react'
import Icon from 'react-icon-base'

const MyNumber = props => (
  <Icon viewBox="0 0 20 20" {...props}>
    <g fill="none" transform="translate(0 2)">
      <rect height="13.3" width="18.3" rx="2" stroke="currentColor" strokeWidth="1.7" x=".85" y="1.35"/>
      <path d="M4 7V5a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1zM4 12h12v-2H4z" fill="currentColor"/>
    </g>
  </Icon>
)
MyNumber.defaultProps = {
  size: 20
}

export default MyNumber
