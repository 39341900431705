import React from 'react'
import Icon from 'react-icon-base'

const Notification = props => (
  <Icon viewBox="0 0 20 20" {...props}>
    <g fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="1.7">
      <path d="M14.872 13.716v-5.82a4.872 4.872 0 0 0-9.744 0v5.82l-1.624 2.436h12.992l-1.624-2.436zM10 3.024V1.4M8.38 16.992a1.62 1.62 0 0 0 3.24 0"/>
    </g>
  </Icon>
)
Notification.defaultProps = {
  size: 20
}

export default Notification
