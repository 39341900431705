import { FC, ReactNode, forwardRef } from 'react';
import styles from './Balloon.scss';

type ContainerProps = {
  children: ReactNode;
};

const Container: FC<ContainerProps> = ({ children }) => {
  return <div className={styles.switch}>{children}</div>;
};

type ContentProps = {
  right?: boolean;
  center?: boolean;
  children: ReactNode;
};

const Content = forwardRef<HTMLDivElement, ContentProps>(({ right, center, children }, ref) => {
  return (
    <div ref={ref} className={(right && styles.right) || (center && styles.center)}>
      {children}
    </div>
  );
});

type UlProps = JSX.IntrinsicElements['ul'];

const Ul: FC<UlProps> = ({ children, ...rest }) => {
  return <ul {...rest}>{children}</ul>;
};

type LiProps = JSX.IntrinsicElements['li'];

const Li: FC<LiProps> = ({ children, ...rest }) => {
  return (
    <li className={styles.li} {...rest}>
      {children}
    </li>
  );
};

export const Balloon = {
  Container,
  Content,
  Ul,
  Li
};
