import React from 'react'
import Icon from 'react-icon-base'

const ChangeRow = props => (
  <Icon viewBox="0 0 16 11" {...props}>
    <g fill="none" stroke="currentColor">
      <rect height="2.538" width="9.882" rx=".5"/>
      <rect height="2.538" width="2.471" rx=".5" x="11.529"/>
      <rect height="2.538" width="9.882" rx=".5" y="4.231"/>
      <rect height="2.538" width="2.471" rx=".5" x="11.529" y="4.231"/>
      <rect height="2.538" width="9.882" rx=".5" x="2" y="8.462"/>
      <rect height="2.538" width="2.471" rx=".5" x="13.529" y="8.462"/>
    </g>
  </Icon>
)
ChangeRow.defaultProps = {
  size: 16
}

export default ChangeRow
