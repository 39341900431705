import React from 'react';
import { gql } from '@apollo/client';
import _ from 'lodash';
import Button from 'jbc-front/components/Button';
import { useNotify } from '../actions';
import { useYear, DownloadWithholdSlips, convertSelectedIds } from '../components';
import FloatingButton from '../components/FloatingButton';
import EmailModal from './EmailModal';
import { OpenMyPageModal } from './MyPageModal';

const WITHHOLDING_SLIP_FOR_EMPLOYEE = gql`
  query withholdingSlipForEmployee($year: Int!) {
    client {
      id
      clientYearly(year: $year) {
        id
        withholdingSlipForEmployee {
          id
          status
          generatedAt
        }
        bulkWithholdingSlipForEmployee {
          id
          status
          generatedAt
        }
      }
    }
  }
`;

const MAKE_WITHHOLDING_SLIP_FOR_EMPLOYEE = gql`
  mutation makeWithholdingSlipForEmployee($input: MakeWithholdingSlipForEmployeeInput!) {
    makeWithholdingSlipForEmployee(input: $input) {
      clientYearly {
        id
        withholdingSlipForEmployee {
          id
          status
          generatedAt
        }
        bulkWithholdingSlipForEmployee {
          id
          status
          generatedAt
        }
      }
    }
  }
`;

const makeDisabledReasonByType = type => {
  switch (type) {
    case 'publish':
      return 'マイページ公開処理中のため操作できません';
    case 'deliver':
      return 'メール発送処理中のため操作できません';
    default:
      return null;
  }
};

const makeDisabledReason = (type, isNotSelected, isPreparing, isRunning) => {
  if (isRunning) {
    return makeDisabledReasonByType(type);
  }

  if (isPreparing) {
    return '源泉徴収票交付の準備を行っているため操作できません';
  }

  if (isNotSelected) {
    return '従業員を選択してください';
  }

  return '';
};

const getWithholdingSlipForEmployee = (data, downloadFormat) => {
  if (downloadFormat === 'pdf') {
    return data.client.clientYearly.bulkWithholdingSlipForEmployee;
  } else {
    return data.client.clientYearly.withholdingSlipForEmployee;
  }
};

const MyPageButton = ({ selectedIds, allSelectMode, selectedTotalCount, isNotSelected, isPreparing, isPublishing, reset }) => (
  <OpenMyPageModal
    selectedIds={selectedIds}
    allSelectMode={allSelectMode}
    selectedTotalCount={selectedTotalCount}
    isNotSelected={isNotSelected}
    reset={reset}
  >
    {({ showModal, loading }) => {
      const isRunning = loading || isPublishing;
      return (
        <Button
          disabled={isNotSelected || isPreparing || isRunning}
          onClick={showModal}
          disabledReason={makeDisabledReason('publish', isNotSelected, isPreparing, isRunning)}
        >
          マイページ一括公開
        </Button>
      );
    }}
  </OpenMyPageModal>
);

const EmailButton = ({ selectedIds, allSelectMode, selectedTotalCount, isNotSelected, isPreparing, isDelivering, reset }) => (
  <EmailModal
    selectedIds={selectedIds}
    allSelectMode={allSelectMode}
    selectedTotalCount={selectedTotalCount}
    isNotSelected={isNotSelected}
    reset={reset}
  >
    {({ showModal, loading }) => {
      const isRunning = loading || isDelivering;
      return (
        <Button
          disabled={isNotSelected || isPreparing || isRunning}
          onClick={showModal}
          disabledReason={makeDisabledReason('deliver', isNotSelected, isPreparing, isRunning)}
        >
          PDF一括発送（メール）
        </Button>
      );
    }}
  </EmailModal>
);

const DownloadButton = ({ selectedIds, allSelectMode, isNotSelected, reset }) => {
  const year = useYear();
  const notify = useNotify();

  return (
    <DownloadWithholdSlips
      query={WITHHOLDING_SLIP_FOR_EMPLOYEE}
      mutation={MAKE_WITHHOLDING_SLIP_FOR_EMPLOYEE}
      title="受給者交付用"
      getReport={getWithholdingSlipForEmployee}
      disabled={isNotSelected}
      showDownloadFormat
      callMutation={async (makeReport, { downloadFormat }) => {
        if (isNotSelected) {
          return notify('従業員を選択してください', 'error');
        }

        const { employeeIds, exceptEmployeeIds } = convertSelectedIds(allSelectMode, selectedIds);
        await makeReport({
          variables: {
            input: { year, allSelectMode, employeeIds, exceptEmployeeIds, downloadFormat }
          }
        });
        reset();
      }}
    />
  );
};

const FloatingButtonArea = ({
  selected,
  allSelectMode,
  selectedTotalCount,
  isDisabledSubmit,
  isPreparing,
  isPublishing,
  isDelivering,
  reset
}) => {
  const selectedIds = Object.keys(_.pickBy(selected));
  const isNotSelected = isDisabledSubmit();

  return (
    <FloatingButton>
      <MyPageButton
        selectedIds={selectedIds}
        allSelectMode={allSelectMode}
        selectedTotalCount={selectedTotalCount}
        isNotSelected={isNotSelected}
        isPreparing={isPreparing}
        isPublishing={isPublishing}
        reset={reset}
      />
      <EmailButton
        selectedIds={selectedIds}
        allSelectMode={allSelectMode}
        selectedTotalCount={selectedTotalCount}
        isNotSelected={isNotSelected}
        isPreparing={isPreparing}
        isDelivering={isDelivering}
        reset={reset}
      />
      <DownloadButton
        selectedIds={selectedIds}
        allSelectMode={allSelectMode}
        isNotSelected={isNotSelected}
        isDelivering={isDelivering}
        reset={reset}
      />
    </FloatingButton>
  );
};

export default FloatingButtonArea;
