import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useSelectEmployee } from './useSelectEmployee';
import styles from './SelectList.scss';

const Hint = ({ onClick, children }) => {
  useEffect(() => {
    document.addEventListener('click', onClick, true);
    return () => {
      document.removeEventListener('click', onClick, true);
    };
  }, [onClick]);
  return (
    <div className={styles.hint}>
      <div className={styles.hintShape} />
      <div className={styles.hintMessage}>{children}</div>
    </div>
  );
};

export const Td = ({ selected, isSelecting, disabledMessage, onClick }) => {
  const [showHint, setShowHint] = useState(false);
  function hideHint() {
    setShowHint(false);
  }
  const disabled = disabledMessage || isSelecting;
  const selectedClass = selected ? 'm-table-checkbox-on' : 'm-table-checkbox-off';
  const disabledClass = disabled ? ' u-cur-notallowed' : ' u-cur-pointer';

  return (
    <td
      onClickCapture={e => {
        e.stopPropagation();
        disabledMessage ? setShowHint(true) : onClick();
      }}
      className={selectedClass + disabledClass}
    >
      {showHint && <Hint onClick={hideHint}>{disabledMessage}</Hint>}
      <input type="checkbox" checked={selected} disabled={isSelecting} className="u-cur-pointer" readOnly />
    </td>
  );
};

export const Th = ({ allSelected, selected, allSelectMode, isSelecting, onClick }) => {
  const [isAllSelected, setIsAllSelected] = useState(false);
  const allClickClass = isAllSelected ? 'm-table-checkbox-on' : 'm-table-checkbox-off';
  const disabledClass = isSelecting ? 'u-cur-notallowed' : 'u-cur-pointer';

  useEffect(() => {
    (async () => {
      const response = await allSelected();
      setIsAllSelected(response);
    })();
  }, [selected, allSelectMode]);

  return (
    <th onClick={onClick} className={classNames(allClickClass, disabledClass, 'm-table-list-check')}>
      <input type="checkbox" checked={isAllSelected} disabled={isSelecting} readOnly />
    </th>
  );
};

export const SelectedResult = ({ selectedTotalCount }) => (
  <p className={styles.employeesTotalCount}>選択した従業員：{selectedTotalCount}名</p>
);

export const SelectList = ({ mode, variables, list, totalCount, selectableCount, disabledMessage, selectedInfo, children }) => {
  const {
    selected,
    isSelected,
    allSelectMode,
    allSelected,
    isSelecting,
    isDisabledSubmit,
    selectedResultCount,
    toggle,
    selectAll,
    reset
  } = useSelectEmployee(mode, variables, list, totalCount, selectableCount, disabledMessage, selectedInfo);

  useEffect(() => {
    reset();
  }, [mode]);

  const props = {
    list: list.map(item => ({
      item,
      selected: !!selected[item.id],
      toggle: () => toggle(item),
      td: <Td selected={isSelected(item)} isSelecting={isSelecting} disabledMessage={disabledMessage(item)} onClick={() => toggle(item)} />
    })),
    selected,
    allSelectMode,
    isDisabledSubmit,
    selectedResultCount,
    reset,
    th: <Th allSelected={allSelected} selected={selected} allSelectMode={allSelectMode} isSelecting={isSelecting} onClick={selectAll} />
  };

  return children({ ...props });
};

SelectList.defaultProps = {
  disabledMessage: () => null,
  selectedInfo: () => true
};

export default SelectList;
