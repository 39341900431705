import { gql } from '@apollo/client';

export type QueryResult = {
  client: {
    clientYearly: {
      employmentTypes: string[];
      positions: string[];
    };
  };
};

export const FETCH_EMPLOYEE_OPTIONS = gql`
  query fetchEmployeeOptions($year: Int!) {
    client {
      clientYearly(year: $year) {
        employmentTypes
        positions
      }
    }
  }
`;
