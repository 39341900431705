import { FC } from 'react';
import { useQuery, gql } from '@apollo/client';

import { LifeInsurances as LifeInsurancesTemplate } from 'features/questions/templates/LifeInsurances';
import { useWizard } from '../../WizardProvider';

import { FormSection } from 'components/form/FormSection';
import { Section } from 'components/ui/Section';
import { InputWithText } from 'components/form/InputWithText';

import { calcNewTypeDeduction, calcOldTypeDeduction, calcDeduction, calcTotalDeduction } from 'utils/calcurators/lifeInsurance';
import { amountFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { useYear } from 'hooks/useYear';

import { GeneralLifeInsurance } from './GeneralLifeInsurance';
import { NursingMedicalInsurance } from './NursingMedicalInsurance';
import { IndividualAnnuityInsurance } from './IndividualAnnuityInsurance';

import { LifeInsurance as LifeInsuranceType } from '../../../../query';

import styles from './Form.scss';

const IMAGE_SETTINGS = gql`
  query imageSettings($year: Int!) {
    client {
      clientYearly(year: $year) {
        clientSetting {
          id
          requestImageUploadSettings {
            nonResidentAndStudyAbroad
          }
        }
      }
    }
  }
`;

export const LifeInsurances: FC = () => {
  const { question, request } = useWizard();
  const year = useYear();

  const { data, loading } = useQuery(IMAGE_SETTINGS, { variables: { year } });

  if (loading) return null;

  const {
    client: {
      clientYearly: {
        clientSetting: {
          requestImageUploadSettings: { lifeInsurance: lifeInsuranceImageSetting }
        }
      }
    }
  } = data;

  const calcTotalAmount = (insurances: LifeInsuranceType[], newOrOld: LifeInsuranceType['newOrOld']) => {
    return insurances.reduce((totalAmount, insurance) => {
      if (insurance.newOrOld === newOrOld) {
        return totalAmount + Number(insurance.amount.split(',').join(''));
      } else {
        return totalAmount;
      }
    }, 0);
  };

  const calcAmount = (insurances: LifeInsuranceType[], newOrOld: LifeInsuranceType['newOrOld'], index: number) => {
    const targetInsurances = insurances.slice(0, index);

    if (targetInsurances.length === 0) return 0;

    return targetInsurances.reduce((totalAmount, insurance) => {
      if (insurance.newOrOld === newOrOld) {
        return totalAmount + Number(insurance.amount.split(',').join(''));
      } else {
        return totalAmount;
      }
    }, 0);
  };

  const totalAmountNewTypeGeneralLifeInsurance = calcTotalAmount(request.profile?.generalLifeInsurances || [], 'new_type');
  const totalAmountOldTypeGeneralLifeInsurance = calcTotalAmount(request.profile?.generalLifeInsurances || [], 'old_type');
  const totalAmountNewTypeNursingMedicalInsurance = calcTotalAmount(request.profile?.nursingMedicalInsurances || [], 'new_type');
  const totalAmountNewTypeIndividualAnnuityInsurance = calcTotalAmount(request.profile?.individualAnnuityInsurances || [], 'new_type');
  const totalAmountOldTypeIndividualAnnuityInsurance = calcTotalAmount(request.profile?.individualAnnuityInsurances || [], 'old_type');

  const generalLifeInsuranceNewTypeDeduction = calcNewTypeDeduction(totalAmountNewTypeGeneralLifeInsurance, year);
  const generalLifeInsuranceOldTypeDeduction = calcOldTypeDeduction(totalAmountOldTypeGeneralLifeInsurance, year);
  const nursingMedicalInsuranceNewTypeDeduction = calcNewTypeDeduction(totalAmountNewTypeNursingMedicalInsurance, year);
  const individualAnnuityInsuranceNewTypeDecution = calcNewTypeDeduction(totalAmountNewTypeIndividualAnnuityInsurance, year);
  const individualAnnuityInsuranceOldTypeDecution = calcOldTypeDeduction(totalAmountOldTypeIndividualAnnuityInsurance, year);

  const generalLifeInsuranceDeduction = calcDeduction(generalLifeInsuranceNewTypeDeduction, generalLifeInsuranceOldTypeDeduction);
  const individualAnnuityInsuranceDeduction = calcDeduction(
    individualAnnuityInsuranceNewTypeDecution,
    individualAnnuityInsuranceOldTypeDecution
  );

  const totalDeduction = calcTotalDeduction(
    generalLifeInsuranceDeduction,
    nursingMedicalInsuranceNewTypeDeduction,
    individualAnnuityInsuranceDeduction,
    year
  );

  let globalIndex = 0;

  return (
    <LifeInsurancesTemplate question={question}>
      <FormSection>
        {request.profile?.generalLifeInsurances.map((generalLifeInsurance, index) => {
          const currentIndex = globalIndex;
          globalIndex++;
          return (
            <GeneralLifeInsurance
              key={currentIndex}
              index={currentIndex}
              year={year}
              newTypeTotalAmount={calcAmount(request.profile?.generalLifeInsurances || [], 'new_type', index)}
              oldTypeTotalAmount={calcAmount(request.profile?.generalLifeInsurances || [], 'old_type', index)}
              insurance={generalLifeInsurance}
              imageRequired={lifeInsuranceImageSetting}
            />
          );
        })}
        {request.profile?.nursingMedicalInsurances.map((nursingMedicalInsurance, index) => {
          const currentIndex = globalIndex;
          globalIndex++;
          return (
            <NursingMedicalInsurance
              key={currentIndex}
              index={currentIndex}
              year={year}
              newTypeTotalAmount={calcAmount(request.profile?.nursingMedicalInsurances || [], 'new_type', index)}
              insurance={nursingMedicalInsurance}
              imageRequired={lifeInsuranceImageSetting}
            />
          );
        })}
        {request.profile?.individualAnnuityInsurances.map((individualAnnuityInsurance, index) => {
          const currentIndex = globalIndex;
          globalIndex++;

          return (
            <IndividualAnnuityInsurance
              key={currentIndex}
              index={currentIndex}
              year={year}
              newTypeTotalAmount={calcAmount(request.profile?.individualAnnuityInsurances || [], 'new_type', index)}
              oldTypeTotalAmount={calcAmount(request.profile?.individualAnnuityInsurances || [], 'old_type', index)}
              insurance={individualAnnuityInsurance}
              imageRequired={lifeInsuranceImageSetting}
            />
          );
        })}

        <Section>
          <Section.Header>控除額</Section.Header>
          <Section.Body>
            <div className={styles.deductionContainer}>
              <InputWithText label="生命保険料控除額合計" text="円" disabled value={amountFormat(String(totalDeduction))} />

              <Section>
                <Section.Header>一般の生命保険料の控除額（イ）</Section.Header>
                <Section.Body>
                  <InputWithText label="控除額" text="円" disabled value={amountFormat(String(generalLifeInsuranceDeduction))} />
                </Section.Body>
              </Section>

              <Section>
                <Section.Header>介護医療保険料の控除額（ロ）</Section.Header>
                <Section.Body>
                  <InputWithText label="控除額" text="円" disabled value={amountFormat(String(nursingMedicalInsuranceNewTypeDeduction))} />
                </Section.Body>
              </Section>

              <Section>
                <Section.Header>個人年金保険料の控除額（ハ）</Section.Header>
                <Section.Body>
                  <InputWithText label="控除額" text="円" disabled value={amountFormat(String(individualAnnuityInsuranceDeduction))} />
                </Section.Body>
              </Section>
            </div>
          </Section.Body>
        </Section>
      </FormSection>
    </LifeInsurancesTemplate>
  );
};
