import { FC, useState, useEffect } from 'react';
import { Rnd } from 'react-rnd';
import { Visibility, Close } from 'jbc-front/components/icons';
import ActionButton from 'jbc-front/components/ActionButton';
import styles from './FilePreview.scss';
import { fileFormat } from '@jbc-year-end-adj/common/utils/formatter';
import classnames from 'classnames';
import { OldPdfPreview } from './OldPdfPreview';

// @ts-ignore
import { useMediaQuery } from 'hooks/useMediaQuery';

type FilePreviewProps = {
  file: {
    name?: string;
    url?: string;
    preview?: string;
    filename?: string;
  } | null;
};

const rndDefault = {
  x: -400,
  y: -500,
  width: 500,
  height: '70vh'
};

export const FilePreview: FC<FilePreviewProps> = ({ file }) => {
  const [open, setOpen] = useState(false);
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const mq = useMediaQuery();

  useEffect(() => {
    if (file) {
      const url = (() => {
        if (file instanceof File) {
          return URL.createObjectURL(file);
        } else {
          return fileFormat(file).url;
        }
      })();
      setFileUrl(url || null);
    } else {
      setFileUrl(null);
    }
  }, [file]);

  if (!fileUrl) {
    return null;
  }

  const name = file?.name || file?.filename;
  const fileExt = name ? name.split('.').pop() : null;

  const toggleOpen = () => {
    setOpen(!open);
  };

  const PreviewFileType = () => {
    if (fileExt === 'pdf') {
      return mq.isPc ? (
        <object className={classnames(styles.preview, 'cancel')} data={fileUrl} title="証明書画像" type="application/pdf">
          証明書画像
        </object>
      ) : (
        <OldPdfPreview pdf={fileUrl} />
      );
    } else if (fileExt === 'heic') {
      return <p className="m-title-main-note u-mb20">HEIC形式のファイルはプレビューできません。</p>;
    } else {
      return <img className={classnames(styles.preview, 'cancel')} src={fileUrl} alt="証明書画像" width="100%" />;
    }
  };

  return (
    <div className={styles.filePreview}>
      {mq.isPc ? (
        <>
          <ActionButton icon={open ? <Close size={14} /> : <Visibility className={styles.inheritMask} size={14} />} onClick={toggleOpen}>
            {open ? '証明書画像を閉じる' : '証明書画像をプレビュー'}
          </ActionButton>
          {open && (
            <Rnd
              default={rndDefault}
              draggable={false}
              className={styles.draggable}
              minWidth="30%"
              minHeight={300}
              bounds="#root"
              cancel=".cancel"
            >
              <div className={styles.draggableInner}>
                <div className={styles.header}>
                  {file?.filename || file?.name}
                  <ActionButton className={classnames(styles.closeButton, 'cancel')} icon={<Close size={20} />} onClick={toggleOpen} />
                </div>
                <PreviewFileType />
              </div>
            </Rnd>
          )}
        </>
      ) : (
        <PreviewFileType />
      )}
    </div>
  );
};
