import React from 'react';
import { SizeButton } from '../components/ToggleButton';
import { Field } from 'redux-form';
import styles from './ImageSettingToggleButton.scss';

const ToggleButton = ({ input, options = [] }) => {
  return (
    <div className={styles.toggles}>
      {options.map(option => {
        return (
          <SizeButton
            key={option.value}
            isSelected={input.value === option.value}
            disabled={option.disabled}
            onClick={() => {
              input.onChange(option.value);
              input.onBlur(option.value);
            }}
          >
            {option.label}
          </SizeButton>
        );
      })}
    </div>
  );
};
export const ImageSettingToggleButton = props => <Field component={ToggleButton} {...props} />;
