import React from 'react'
import Icon from 'react-icon-base'

const WorkflowLogo = props => (
  <Icon viewBox="0 0 423 50" {...props}>
    <g fill='none' fillRule='evenodd'>
      <path fill='#007CFF' d='M37.986 9.409h5.528V2h-5.528v7.409zm-7.059 0h5.454V2h-5.454v7.409zM1.046 39.623c21.253-.501 31.412-7.409 33.13-28.478H44C42.245 39.43 25.922 48.885 1.046 49v-9.377zM23.98 14.387c-7.097-.81-17.032-1.698-23.382-1.93V3.544c6.723.27 16.023.926 23.382 1.813v9.03zm-.598 15.126C15.95 28.587 6.612 27.815 0 27.583V18.71c6.985.231 16.51.926 23.382 1.813v8.991zM56 48.922v-8.515h26.805v-7.271H56.633v-8.438h26.172v-6.144H56.037V10H92v39zM146.438 7.467c-.673 28.538-16.9 40.852-36.942 42.533v-8.952c15.405-2.424 24.865-12.002 26.51-27.483H104V4.34h34.512V0h5.533v4.34h1.496V0H151v7.428h-4.562v.039zM178.735 48.5v-8.892h7.535c3.484 0 4.771-1.348 4.771-4.812V18.667h-11.89C177.901 35.72 168.057 45.651 157 49v-9.508c6.816-3.81 12.155-10.585 12.571-20.825H157V9.814h12.647V2h9.694v7.814H201V35.45c0 9.239-3.938 13.05-12.458 13.05h-9.807zM212.79 39.55c19.074-1.355 30.623-6.776 32.165-27.843H255c-1.542 26.45-16.177 36.75-42.21 37.293v-9.45zm22.12-23.003c-6.131-1.278-16.439-2.633-22.91-2.982V4c6.998.387 16.14 1.55 22.91 2.75v9.797z' />
      <path fill='#646464' d='M272.538 45.945c9.116-2.11 12.308-8.242 12.308-17.812v-.117h-16.731v7.812H267V27h19v1.016c0 10.547-3.654 16.757-13.462 18.984v-1.055zM292 36h21v-1h-21zM323.676 45.925c8.934-1.42 14.008-7.716 15.111-17.85h-12.17c-1.36 3.532-3.75 6.564-6.617 8.445v-1.305c2.463-1.766 4.67-4.645 5.846-8.215H340v.077c0 .115-.037.192-.037.268-1.03 11.709-6.874 18.081-16.287 19.655v-1.075zM352.293 45.867c8.597-2.5 13.207-9.101 13.61-17.851H349V27h18v.117c0 10.82-6.549 17.852-14.707 19.883v-1.133zM377.029 44.017h15.942V27.983H377.03v16.034zM376 45h18V27h-18v18zM402 36h21v-1h-21z' />
    </g>
  </Icon>
)
WorkflowLogo.defaultProps = {
  size: 160
}

export default WorkflowLogo
