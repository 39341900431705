import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { Link } from 'react-router-dom';
import { gql } from '@apollo/client';
import { TextField, TextAreaField, Section, Label } from 'jbc-front/components/Form';
import FormErrors, { onSubmitFail } from 'jbc-front/components/FormErrors';
import Button from 'jbc-front/components/Button';
import { useQuery, useMutation, useSession, useYear } from '../components';
import { useDefaultMail } from '../components/useDefaultMailSetting';
import { maxLength } from '../validators';
import { emailTypes } from './CustomMails';
import Preview from './CustomMailPreview';
import styles from './CustomMailEdit.scss';

const formName = 'customMail';
const selector = formValueSelector(formName);

const CUSTOM_MAIL = gql`
  query customMail($emailType: EmailSearchInput!) {
    client {
      id
      customMail(emailType: $emailType) {
        id
        subject
        body
      }
    }
  }
`;

const UPDATE_CUSTOM_MAIL = gql`
  mutation updateCustomMail($input: UpdateCustomMailInput!) {
    updateCustomMail(input: $input) {
      customMail {
        id
      }
    }
  }
`;

export const replaceApplicationName = (target, application) => target.replace('%application%', application);

const Form =
  (({ handleSubmit, pristine, submitting, application, defaultMail, onPreview }) => (
    <div>
      <FormErrors />
      <Section>
        <TextField
          name="subject"
          label="件名"
          placeholder={replaceApplicationName(defaultMail.subject, application)}
          validate={[maxLength(255)]}
        />
        <Label text="デフォルト本文" />
        <div className={styles.body}>
          <p dangerouslySetInnerHTML={{ __html: defaultMail.header }} />
        </div>
        <TextAreaField name="body" label="カスタマイズ文" validate={[maxLength(10000)]} />
        デフォルトの本文は編集できません。
        <div className={styles.help}>
          <ul>
            <li>
              {'<b>'}と{'</b>'}の間に文字を入れると太字になります。
            </li>
            <li>
              {'<u>'}と{'</u>'}の間に文字を入れると下線が付きます。
            </li>
          </ul>
        </div>
      </Section>
      <div className="u-ta-c">
        <Button className="u-mr20" onClick={() => onPreview(true)}>
          プレビュー
        </Button>
        <Button onClick={handleSubmit} disabled={pristine || submitting} primary>
          保存
        </Button>
      </div>
    </div>
  ))
  |> reduxForm({
    form: formName,
    enableReinitialize: true,
    onSubmitFail
  });

const CustomMailEdit = ({
  match: {
    params: { email_type: emailType }
  },
  history
}) => {
  const [showPreview, setShowPreview] = useState(false);
  const { loading, data } = useQuery(CUSTOM_MAIL, {
    fetchPolicy: 'network-only',
    variables: {
      emailType: { emailType: emailType }
    }
  });
  const application = useSession().me.application.name;
  const year = useYear();
  const defaultMail = useDefaultMail(emailType);
  const customMail = data?.client?.customMail;
  const customMailSubject = useSelector(state => selector(state, 'subject'));
  const customMailBody = useSelector(state => selector(state, 'body'));
  const [updateCustomMail] = useMutation(UPDATE_CUSTOM_MAIL);

  return (
    <div>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">メールカスタマイズ</h1>
        <p className="m-title-main-note">メールの内容をカスタマイズできます</p>
      </div>
      <div className="l-wrap-xs l-contents-wrap">
        <div className="l-breadcrumb u-mb20">
          <Link to="/settings" className="l-breadcrumb-link">
            設定
          </Link>
          <Link to="/settings/custom_mails" className="l-breadcrumb-link">
            メールカスタマイズ
          </Link>
          <span className="l-breadcrumb-here">{emailTypes[emailType]}</span>
        </div>
        {!loading && defaultMail && (
          <>
            <Form
              {...{ application, defaultMail }}
              initialValues={{ subject: customMail?.subject, body: customMail?.body }}
              onPreview={setShowPreview}
              onSubmit={async values => {
                await updateCustomMail({
                  variables: { input: { emailType: emailType, customMail: values } }
                });
                history.push(`/${year}/settings/custom_mails`);
              }}
            />
            <Preview
              title={emailTypes[emailType]}
              isOpen={showPreview}
              onHide={setShowPreview}
              application={application}
              defaultMail={defaultMail}
              customMailSubject={customMailSubject}
              customMailBody={customMailBody}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default CustomMailEdit;
