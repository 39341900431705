import React from 'react'
import Icon from 'react-icon-base'

const Question = props => (
  <Icon viewBox="0 0 16 17" {...props}>
    <g fill="none">
      <ellipse cx="8.135" cy="8" fill="currentColor" rx="8.135" ry="8"/>
      <path d="M5.391 6.012c0 .215.18.388.4.388.221 0 .4-.173.4-.388 0-1.06.89-1.922 1.982-1.922 1.093 0 1.982.862 1.982 1.922s-.89 1.924-1.982 1.924c-.22 0-.4.174-.4.388v1.615c0 .214.18.388.4.388.221 0 .4-.174.4-.388V8.68c1.345-.19 2.382-1.312 2.382-2.669 0-1.487-1.248-2.698-2.782-2.698s-2.782 1.21-2.782 2.698zM8.17 11.67a.707.707 0 0 0-.716.693c0 .384.321.695.717.695a.706.706 0 0 0 .715-.695.706.706 0 0 0-.715-.694" fill="#FFF" stroke="#FFF" strokeWidth=".7"/>
    </g>
  </Icon>
)
Question.defaultProps = {
  size: 16
}

export default Question
