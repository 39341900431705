import Cookies from 'js-cookie';
import _ from 'lodash';
import fp from 'lodash/fp';
import queryString from 'query-string';
import { formValueSelector, getFormValues, autofill as autofillForm } from 'redux-form';

export const formName = 'employeeSearchForm';
export const selector = formValueSelector(formName);
export const getValues = getFormValues(formName);
export const autofill = autofillForm.bind(null, formName);
export const defaultQuery = {
  status: 'all',
  withholding_slip_status: 'all',
  withholding_slip_deliver_status: 'all',
  _page: '1',
  sort_type: 'staff_code__asc'
};
const defaultLimit = '100';

export const convertQueryToForm = query => {
  return Object.assign(
    { sortType: `${query.sort}__${query.order}` },
    ...Object.entries(query)
      .map(([k, v]) => [k[0] === '_' ? `_${_.camelCase(k)}` : _.camelCase(k), v])
      .map(([k, v]) => convertQueryToFormField(k, v))
      .filter(_.identity)
  );
};

export const convertQueryToFormField = (fieldName, value) => {
  switch (fieldName) {
    case 'officeIds':
      return { offices: value.join(',') };
    case 'employmentTypeIds':
      return { employment_types: value };
    case '_page':
      return { page: value };
    case 'sort':
    case 'order':
      return undefined;
    case '_addedFields':
      return { [fieldName]: (value || []).map(v => _.camelCase(v)) };
    default:
      return { [fieldName]: value };
  }
};

export const convertFormToQueryString = data => queryString.stringify(convertFormToQuery(data), { arrayFormat: 'bracket' });

export const convertFormToQuery = data => {
  if (data === undefined) return {};
  return Object.assign(
    {},
    ...Object.entries(data)
      .map(([k, v]) => [k[0] === '_' ? `_${_.snakeCase(k)}` : _.snakeCase(k), v])
      .map(([k, v]) => convertFormToQueryField(k, v))
      .filter(_.identity)
  );
};

export const convertFormToQueryField = (fieldName, value) => {
  switch (fieldName) {
    case 'offices': {
      const officesIds = value.split(',');
      return !_.isEqual(officesIds, ['']) ? { office_ids: officesIds } : null;
    }
    case 'employmentTypes':
      return !_.isEmpty(value) ? { employment_type_ids: value } : null;
    case 'limit':
      return null;
    case 'page':
      return { _page: value };
    case 'sortType': {
      const [sort, order] = value.split('__');
      return { order, sort };
    }
    case '_addedFields':
      return { [fieldName]: (value || []).map(v => _.snakeCase(v)) };
    default:
      return { [fieldName]: value };
  }
};

/** @deprecated 今後は {@link addOptionsByYear} を参照。 */
export const addOptions = [
  { label: '扶養の有無', value: 'hasDependents' },
  { label: '配偶者の有無', value: 'maritalStatuses' },
  { label: '退職日', value: 'resignedOn', fields: ['resignedOnStart', 'resignedOnEnd'] },
  /*  { label: '退職区分', value: 'retireTypes' },*/
  { label: '税額表区分', value: 'taxTableClassifications' },
  { label: '障害者区分', value: 'handicapClassifications' },
  { label: '寡婦（夫）区分 ', value: 'widowClassifications' },
  { label: '勤労学生区分', value: 'workingStudentClassifications' }
];

export const addOptionsByYear = year => {
  if (year >= 2020) {
    return [
      { label: '扶養の有無', value: 'hasDependents' },
      { label: '配偶者の有無', value: 'maritalStatuses' },
      { label: '退職日', value: 'resignedOn', fields: ['resignedOnStart', 'resignedOnEnd'] },
      { label: '税額表区分', value: 'taxTableClassifications' },
      { label: '障害者区分', value: 'handicapClassifications' },
      { label: 'ひとり親・寡婦区分', value: 'widowClassifications' },
      { label: '勤労学生区分', value: 'workingStudentClassifications' }
    ];
  } else {
    return addOptions;
  }
};

const makeAddedFields = (query, user) => {
  let savedQuery = [];
  if (window.localStorage) {
    const saved = window.localStorage.getItem(addedFieldsKey(user));
    if (saved) {
      savedQuery = JSON.parse(saved);
    }
  }
  let hasQuery = addOptions.filter(opt => (opt.fields || [opt.value]).some(key => !_.isEmpty(query[key]))).map(opt => opt.value);
  return _.union(savedQuery, hasQuery);
};

export const saveAddedFields = (user, addedField) => {
  if (window.localStorage) {
    window.localStorage.setItem(addedFieldsKey(user), JSON.stringify(_.isEmpty(addedField) ? [] : addedField));
  }
};

export const getCurrentQueryFromLocation = (location, user) => {
  const query = {
    ...defaultQuery,
    ...queryString.parse(location.search, { arrayFormat: 'bracket' }),
    limit: getSavedDisplayEmployeeLimit(location.pathname, user)
  };
  return { ...query, _addedFields: makeAddedFields(query, user) };
};

export const getSavedDisplayEmployeeLimit = (pathname, user) => {
  if (window.localStorage) {
    const limit = window.localStorage.getItem(limitKey(pathname, user));
    if (!_.isEmpty(limit)) {
      return limit;
    }
  }
  const limit = Cookies.get(limitKey(pathname, user));
  return _.isEmpty(limit) ? defaultLimit : limit;
};

export const saveDisplayEmployeeLimit = (pathname, user, limit) => {
  if (window.localStorage) {
    window.localStorage.setItem(limitKey(pathname, user), limit);
  } else {
    Cookies.set(limitKey(pathname, user), limit);
  }
};

export const hasConditions = formValues => {
  return !_.isEqual(convertFormToQuery(formValues) |> fp.omit('_added_fields') |> fp.pickBy(fp.identity), defaultQuery);
};

const filtersToGraphQl = (obj, keys) =>
  Object.assign(
    {},
    ...keys.map(key => ({
      [key]: obj[key] && obj[key].map(v => (v === 'true' ? true : v === 'false' ? false : v))
    }))
  );

export const convertFormToGraphQl = formValues => {
  const [sortColumn, sortOrder] = (formValues.sortType || convertQueryToForm(defaultQuery).sortType).split('__');
  return {
    page: +formValues.page || 1,
    per: +formValues.limit || 100,
    search: {
      ...(formValues.status !== 'all' && _.pick(formValues, 'status')),
      ..._.pick(formValues, ['q', 'resignedOnStart', 'resignedOnEnd']),
      sortColumn,
      sortOrder,
      ...filtersToGraphQl(formValues, [
        'maritalStatuses',
        'handicapClassifications',
        'hasDependents',
        'widowClassifications',
        'workingStudentClassifications',
        'employmentTypes',
        'taxTableClassifications',
        'positions',
        'memoColors'
      ]),
      groups: formValues['groups']?.split(','),
      joinedOnStart: formValues.joinedOnStart,
      joinedOnEnd: formValues.joinedOnEnd,
      withholdingSlipStatus: formValues.withholdingSlipStatus,
      withholdingSlipDeliverStatus: formValues.withholdingSlipDeliverStatus
    }
  };
};

const addedFieldsKey = user => `#${user.id}__added_fields`;

const limitKey = (pathname, user) => `${pathname.replace(/\//g, '')}#${user.id}__disp_emp_limit`;
