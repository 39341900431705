import { FC } from 'react';
import { useAsyncTask } from '@jbc-year-end-adj/2024/features/AsyncTask/AsyncTaskProvider';

import { RequestModeButton } from './RequestModeButton';
import { BulkStatusUpdateModal } from './BulkStatusUpdateModal';
import { BulkDelete } from './BulkDelete';
import { DELETE_MODE, REQUEST_MODE, CHANGE_STATUS_MODE } from '../../consts';

type Mode = 'delete' | 'request' | 'change_status';

type FloatingButtonAreaProps = {
  mode: Mode;
  onChangeMode: (mode: Mode) => void;
  selected: { [key: string]: { name: string; status: unknown } };
  allSelectMode: boolean;
  isDisabledSubmit: () => boolean;
  selectedTotalCount: number;
  reset: () => void;
};

export const FloatingButtonArea: FC<FloatingButtonAreaProps> = ({
  mode,
  onChangeMode,
  selected,
  allSelectMode,
  isDisabledSubmit,
  selectedTotalCount,
  reset
}) => {
  const { taskRunningProps } = useAsyncTask();

  return (
    <>
      {mode === DELETE_MODE && (
        <BulkDelete
          selected={selected}
          allSelectMode={allSelectMode}
          isDisabledSubmit={isDisabledSubmit}
          selectedTotalCount={selectedTotalCount}
          onSuccess={() => onChangeMode(REQUEST_MODE)}
          onChangeMode={() => onChangeMode(REQUEST_MODE)}
        />
      )}
      {mode === REQUEST_MODE && (
        <RequestModeButton
          selectedTotalCount={selectedTotalCount}
          allSelectMode={allSelectMode}
          isDisabledSubmit={isDisabledSubmit}
          selected={selected}
          taskRunningProps={taskRunningProps}
          reset={reset}
        />
      )}
      {mode === CHANGE_STATUS_MODE && (
        <BulkStatusUpdateModal
          selectedTotalCount={selectedTotalCount}
          allSelectMode={allSelectMode}
          selected={selected}
          isDisabledSubmit={isDisabledSubmit}
          taskRunningProps={taskRunningProps}
          reset={reset}
          onChangeMode={() => onChangeMode(REQUEST_MODE)}
        />
      )}
    </>
  );
};
