import React from 'react';
import { Buttons, Headding, Item, Lead, Panel, Text } from '../components/PrimaryPanel';
import Button from 'jbc-front/components/Button';
import { DescriptionEdit } from '../components/DescriptionEdit';
import { CustomDescription } from '../components/QuestionDescription';
import { FormPlaceholder } from '../components/FormPlaceholder';

const Question = () => {
  return (
    <Panel>
      <Headding icon={<DescriptionEdit />}>ファイルの添付</Headding>
      <Item>
        <Lead>提出ファイルがあれば添付してください。</Lead>
        <CustomDescription field="top" as={Text} />
        <FormPlaceholder />
        <Buttons>
          <Button primary disabled>
            次ヘ
          </Button>
        </Buttons>
      </Item>
    </Panel>
  );
};

export default Question;
