import { FC, ReactNode } from 'react';
import styles from './Layout.scss';

type LayoutProps = {
  children: ReactNode;
};

const LayoutComponent: FC<LayoutProps> = ({ children }) => {
  return <div className={styles.layout}>{children}</div>;
};

type SideContentProps = {
  children?: ReactNode;
};

const SideContent: FC<SideContentProps> = ({ children }) => {
  return <div className={styles.sideContent}>{children}</div>;
};

type MainContentProps = {
  children: ReactNode;
};

const MainContent: FC<MainContentProps> = ({ children }) => {
  return <div className={styles.mainContent}>{children}</div>;
};

export const Layout = Object.assign(LayoutComponent, {
  SideContent,
  MainContent
});
