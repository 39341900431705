import { FC, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';

import Button from 'jbc-front/components/Button';
import { ButtonRow } from 'jbc-front/components/presenters/layout/ButtonRow';

import { FormSection } from 'components/form/FormSection';
import { DateField } from 'components/react-hook-form/DateField';
import { Input } from 'components/form/Input';
import { Radio } from 'components/form/Radio';
import { Option } from 'components/form/SelectField';
import { PostcodeInput } from 'components/form/PostcodeInput';
import { CreatableSelectField } from 'components/react-hook-form/CreatableSelectField';
import { SelectField } from 'components/react-hook-form/SelectField';
import { usePrefectureOptions } from 'hooks/usePrefectureOptions';
import { useAddress } from 'hooks/useAddress';
import { useYear } from 'hooks/useYear';
import { Schema, schema, generateDefaultValues } from './schema';
import { convertFormDataToQueryVariables, UPDATE_REQUEST_BASIC_INFORMATION } from './mutation';
import { useResult } from '../../ResultProvider';
import { RELATIONSHIPS } from '../../../../consts';
import { FETCH_EMPLOYEE } from '../../../../query';
import { Grid } from '../../../../components/Grid';
import { FormSeparator } from '../../../../components/FormSeparator';
import { FloatingButton } from '../../../../components/FloatingButton';

// @ts-ignore
import { useNotify } from 'actions';
// @ts-ignore
import { useMutation } from 'components/Graphql';

type FormProps = {
  onCancel: () => void;
};

export const Form: FC<FormProps> = ({ onCancel }) => {
  const { prefectureOptions } = usePrefectureOptions();
  const { employee } = useResult();
  const profile = employee.request?.profile;
  const { fetchAddress, address } = useAddress();
  const year = useYear();
  const [update, { loading }] = useMutation(UPDATE_REQUEST_BASIC_INFORMATION);
  const notify = useNotify();

  const methods = useForm<Schema>({
    defaultValues: generateDefaultValues(profile),
    resolver: yupResolver(schema),
    mode: 'onBlur'
  });

  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
    setValue,
    trigger
  } = methods;

  const onSubmit: SubmitHandler<Schema> = async (data: Schema) => {
    const variables = convertFormDataToQueryVariables(String(employee.id), data);
    await update({
      variables,
      refetchQueries: [FETCH_EMPLOYEE]
    });
    onCancel();
    notify('基本情報を保存しました。', 'success');
  };

  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  const postcode0 = watch('postcode0');
  const postcode1 = watch('postcode1');

  useEffect(() => {
    if (!isFirstRender) {
      if (postcode0 && postcode1) {
        const postcode = Number(`${postcode0}${postcode1}`);
        fetchAddress({ variables: { postcode, year } });
      }
    } else {
      setIsFirstRender(false);
    }
  }, [postcode0, postcode1]);

  useEffect(() => {
    if (address) {
      const prefecture = prefectureOptions.find(option => option.label === address.prefecture);
      if (prefecture) {
        setValue('prefectureId', prefecture.value);
      }

      setValue('city', address.city);
      setValue('street', address.street);

      trigger(['prefectureId', 'city', 'street']);
    }
  }, [address]);

  const relationshipOptions: Option[] = [
    { value: '', label: '' },
    ...RELATIONSHIPS.map(relationship => ({ value: relationship, label: relationship }))
  ];
  if (profile?.householder?.relationship) {
    const relationship = profile.householder.relationship;
    relationshipOptions.push({ value: relationship, label: relationship });
  }

  const householderRelationship = watch('householderRelationship');

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormSection>
          <Grid colGap>
            <Input label="姓" required error={errors.lastName?.message} isError={!!errors.lastName?.message} {...register('lastName')} />
            <Input label="名" required error={errors.firstName?.message} isError={!!errors.firstName?.message} {...register('firstName')} />
          </Grid>

          <Grid colGap>
            <Input
              label="姓(カナ)"
              required
              error={errors.lastNameKana?.message}
              isError={!!errors.lastNameKana?.message}
              {...register('lastNameKana')}
            />
            <Input
              label="名(カナ)"
              required
              error={errors.firstNameKana?.message}
              isError={!!errors.firstNameKana?.message}
              {...register('firstNameKana')}
            />
          </Grid>

          <FormSeparator />

          <Grid colGap>
            <DateField label="生年月日" required note="例）1981/01/23、S56.1.23" error={errors.birthday?.message} name="birthday" />
            <Radio.Container label="性別" required>
              <Radio value="male" {...register('gender')}>
                男性
              </Radio>
              <Radio value="female" {...register('gender')}>
                女性
              </Radio>
            </Radio.Container>
          </Grid>

          <FormSeparator />

          <Grid colGap>
            <CreatableSelectField
              label="世帯主の続柄"
              note="一人暮らしの場合の世帯主は「本人」です。"
              options={relationshipOptions}
              error={errors.householderRelationship?.message}
              name="householderRelationship"
            />
            {householderRelationship && householderRelationship !== '本人' && (
              <Input
                label="世帯主の氏名"
                error={errors.householderName?.message}
                isError={!!errors.householderName?.message}
                {...register('householderName')}
              />
            )}
          </Grid>

          <FormSeparator />

          <Grid colGap>
            <PostcodeInput
              required
              errors={[errors.postcode0?.message ?? '', errors.postcode1?.message ?? ''].filter(error => error)}
              firstCodeProps={{
                isError: !!errors.postcode0?.message,
                ...register('postcode0')
              }}
              lastCodeProps={{
                isError: !!errors.postcode1?.message,
                ...register('postcode1')
              }}
            />
            <SelectField
              label="都道府県"
              options={prefectureOptions}
              value={prefectureOptions.find(option => option.value === profile?.prefectureId)}
              required
              name="prefectureId"
              error={errors.prefectureId?.message}
            />
            <Input label="市区町村" required error={errors.city?.message} isError={!!errors.city?.message} {...register('city')} />
            <Input label="丁目番地号" error={errors.street?.message} isError={!!errors.street?.message} {...register('street')} />
            <Input label="建物名" error={errors.building?.message} isError={!!errors.building?.message} {...register('building')} />
          </Grid>

          <FormSeparator />

          <Grid colGap>
            <Radio.Container label="外国人区分" required>
              <Radio value="japanese" {...register('nationalType')}>
                日本人
              </Radio>
              <Radio value="foreigner" {...register('nationalType')}>
                外国人
              </Radio>
            </Radio.Container>
          </Grid>

          <FormSeparator />

          <Grid colGap>
            <Radio.Container label="退職予定" required>
              <Radio value="true" {...register('resignBeforeYearEnd')}>
                はい
              </Radio>
              <Radio value="false" {...register('resignBeforeYearEnd')}>
                いいえ
              </Radio>
            </Radio.Container>
          </Grid>

          <FormSeparator />

          <FloatingButton>
            <ButtonRow>
              <Button onClick={onCancel} disabled={loading}>
                キャンセル
              </Button>
              <Button primary onClick={handleSubmit(onSubmit)} disabled={loading}>
                保存
              </Button>
            </ButtonRow>
          </FloatingButton>
        </FormSection>
      </form>
    </FormProvider>
  );
};
