import { FC } from 'react';
import styles from './ProgressBar.scss';

type ProgressBarProps = {
  progress: number;
};

export const ProgressBar: FC<ProgressBarProps> = ({ progress }) => {
  return (
    <div className={styles.progress}>
      <div className={styles.bar}>
        <div className={styles.value} style={{ width: `${progress}%` }} />
      </div>
    </div>
  );
};
