import { FC } from 'react';
import { gql, useQuery } from '@apollo/client';

import { Checkbox } from 'jbc-front/components/presenters/form/Checkbox';
import { Note } from 'jbc-front/components/presenters/ui/Note';

import { FormField } from 'components/form/FormField';
import { Input } from 'components/form/Input';
import { InputWithText } from 'components/form/InputWithText';
import { FileField } from 'components/form/FileField';
import { FilePreview } from 'components/feature/FilePreview';

import { useYear } from 'hooks/useYear';
import { DependentYearlyInfo } from '../../../../query';
import { amountFormat } from '@jbc-year-end-adj/common/utils/formatter';

const IMAGE_SETTINGS = gql`
  query imageSettings($year: Int!) {
    client {
      clientYearly(year: $year) {
        clientSetting {
          id
          requestImageUploadSettings {
            nonResidentAndStudyAbroad
          }
        }
      }
    }
  }
`;

type DifferentAndForeignAddressProps = {
  yearlyInfo: DependentYearlyInfo;
};

export const DifferentAndForeignAddress: FC<DifferentAndForeignAddressProps> = ({ yearlyInfo }) => {
  const year = useYear();
  const { data, loading } = useQuery(IMAGE_SETTINGS, { variables: { year } });

  if (loading) return null;

  const {
    client: {
      clientYearly: {
        clientSetting: {
          requestImageUploadSettings: { nonResidentAndStudyAbroad: nonResidentAndStudyAbroadImageSetting }
        }
      }
    }
  } = data;

  return (
    <>
      <Input label="Address" value={yearlyInfo.addressForeign} disabled />

      <FormField>
        <Checkbox checked={yearlyInfo.isNonResident} disabled>
          非居住者
        </Checkbox>
        <FormField.NoteContainer>
          <Note>配偶者が国内に住所を有せず、かつ、現在まで引き続き1年以上居所を有しない場合チェックしてください。</Note>
        </FormField.NoteContainer>
      </FormField>

      {yearlyInfo.isNonResident && (
        <InputWithText
          label="国外居住親族への送金額"
          text="円"
          note="配偶者が非居住者である場合、本年中にその被扶養者に送金等をした金額の合計を記入してください。"
          value={amountFormat(yearlyInfo.remittance)}
          disabled
        />
      )}

      {yearlyInfo.isNonResident && nonResidentAndStudyAbroadImageSetting !== 'none' && (
        <>
          <h4>添付ファイル</h4>

          <p>国外居住親族に係る扶養控除等を受けようとする場合は関係書類（例：親族関係書類、送金関係書類）を添付してください。</p>

          <FileField label="親族関係書類" required={nonResidentAndStudyAbroadImageSetting === 'required'} disabled>
            {yearlyInfo.relatedToRelativesDocument && (
              <FileField.FileContainer>
                <FileField.File file={yearlyInfo.relatedToRelativesDocument} />
                <FilePreview file={yearlyInfo.relatedToRelativesDocument} />
              </FileField.FileContainer>
            )}
          </FileField>

          <FileField label="送金関係書類" required={nonResidentAndStudyAbroadImageSetting === 'required'} disabled>
            {yearlyInfo.relatedToRemittanceDocument && (
              <FileField.FileContainer>
                <FileField.File file={yearlyInfo.relatedToRemittanceDocument} />
                <FilePreview file={yearlyInfo.relatedToRemittanceDocument} />
              </FileField.FileContainer>
            )}
          </FileField>
        </>
      )}
    </>
  );
};
