import React, { useState } from 'react';
import { Rnd } from 'react-rnd';
import { Visibility, Close } from 'jbc-front/components/icons';
import ActionButton from 'jbc-front/components/ActionButton';
import styles from './FilePreview.scss';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { OldPdfPreview } from './feature/FilePreview/OldPdfPreview';

const rndDefault = {
  x: -560,
  y: -700,
  width: 500,
  height: '90vh'
};

const PreviewFileType = ({ file }) => {
  const fileExt = file.value.name ? file.value.name.split('.').pop() : null;
  const mq = useMediaQuery();

  if (!fileExt) {
    return null;
  }

  if (fileExt == 'pdf') {
    return mq.isPc ? (
      <object className={styles.preview} data={file.value.url || file.value.preview} title="証明書画像" type="application/pdf">
        証明書画像
      </object>
    ) : (
      <OldPdfPreview pdf={file.value.url || file.value.preview} />
    );
  } else if (fileExt == 'heic') {
    return <p className="m-title-main-note u-mb20">HEIC形式のファイルはプレビューできません。</p>;
  } else {
    return <img className={styles.preview} src={file.value.url || file.value.preview} alt="証明書画像" width="100%" />;
  }
};

const FilePreview = file => {
  const [open, setOpen] = useState(false);
  const mq = useMediaQuery();

  return (
    <div className={styles.filePreview}>
      {mq.isPc ? (
        <>
          <ActionButton
            icon={open ? <Close size={14} /> : <Visibility className={styles.inheritMask} size={14} />}
            onClick={() => setOpen(!open)}
          >
            {open ? '証明書画像を閉じる' : '証明書画像をプレビュー'}
          </ActionButton>
          {open && (
            <Rnd default={rndDefault} className={styles.draggable} minWidth="30%" minHeight={300} bounds="window">
              <div className={styles.draggableInner}>
                <div className={styles.header}>
                  {file.value.name}
                  <ActionButton className={styles.closeButton} icon={<Close size={20} />} onClick={() => setOpen(!open)} />
                </div>
                <PreviewFileType file={file} />
              </div>
            </Rnd>
          )}
        </>
      ) : (
        <PreviewFileType file={file} />
      )}
    </div>
  );
};

export default FilePreview;
