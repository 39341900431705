import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { AnchorTarget } from 'jbc-front/components/ScrollSpy/v2';

import { Section } from '@jbc-year-end-adj/2024/components/ui/Section';
import { FormSection } from '@jbc-year-end-adj/2024/components/form/FormSection';
import { ToggleButton } from '@jbc-year-end-adj/2024/components/form/ToggleButton';

export const AcceptedEmailStatusSection: FC = () => {
  const { register } = useFormContext();

  return (
    <Section>
      <AnchorTarget name="accepted_email_status" />
      <Section.Header>承認メール設定</Section.Header>
      <Section.Body>
        <FormSection>
          <p>
            管理者が承認時に従業員へのメール送信の有無を選択できます。
            <br />
            「送信しない」を選択した場合も承認時に「承認時にコメントを送信する」にチェックを入れて承認することによりメッセージを送信することが可能です。
          </p>
          <ToggleButton.Container>
            <ToggleButton {...register('sendAcceptedEmail')} value="true">
              送信する
            </ToggleButton>
            <ToggleButton {...register('sendAcceptedEmail')} value="false">
              送信しない
            </ToggleButton>
          </ToggleButton.Container>
        </FormSection>
      </Section.Body>
    </Section>
  );
};
