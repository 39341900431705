import React from 'react'
import Icon from 'react-icon-base'

const Custom6 = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="Custom6" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group" fill="#3498DB" transform="translate(4.000000, 3.000000)">
        <path d="M29,0 C30.1045695,0 31,0.8954305 31,2 L31,31 C31,32.1045695 30.1045695,33 29,33 L2,33 C0.8954305,33 0,32.1045695 0,31 L0,2 C0,0.8954305 0.8954305,0 2,0 L29,0 Z M29,2 L2,2 L2,31 L29,31 L29,2 Z" id="Rectangle" fillRule="nonzero"></path>
        <circle id="Oval" cx="26.5" cy="7.5" r="1.5"></circle>
        <path d="M31.9,6.75 C32.3142136,6.75 32.65,7.08578644 32.65,7.5 C32.65,7.87969577 32.3678461,8.19349096 32.0017706,8.24315338 L31.9,8.25 L27.1,8.25 C26.6857864,8.25 26.35,7.91421356 26.35,7.5 C26.35,7.12030423 26.6321539,6.80650904 26.9982294,6.75684662 L27.1,6.75 L31.9,6.75 Z" id="Line-2" fillRule="nonzero"></path>
        <circle id="Oval" cx="26.5" cy="13.5" r="1.5"></circle>
        <path d="M31.9,12.75 C32.3142136,12.75 32.65,13.0857864 32.65,13.5 C32.65,13.8796958 32.3678461,14.193491 32.0017706,14.2431534 L31.9,14.25 L27.1,14.25 C26.6857864,14.25 26.35,13.9142136 26.35,13.5 C26.35,13.1203042 26.6321539,12.806509 26.9982294,12.7568466 L27.1,12.75 L31.9,12.75 Z" id="Line-2" fillRule="nonzero"></path>
        <circle id="Oval" cx="26.5" cy="19.5" r="1.5"></circle>
        <path d="M31.9,18.75 C32.3142136,18.75 32.65,19.0857864 32.65,19.5 C32.65,19.8796958 32.3678461,20.193491 32.0017706,20.2431534 L31.9,20.25 L27.1,20.25 C26.6857864,20.25 26.35,19.9142136 26.35,19.5 C26.35,19.1203042 26.6321539,18.806509 26.9982294,18.7568466 L27.1,18.75 L31.9,18.75 Z" id="Line-2" fillRule="nonzero"></path>
        <circle id="Oval" cx="26.5" cy="25.5" r="1.5"></circle>
        <path d="M31.9,24.75 C32.3142136,24.75 32.65,25.0857864 32.65,25.5 C32.65,25.8796958 32.3678461,26.193491 32.0017706,26.2431534 L31.9,26.25 L27.1,26.25 C26.6857864,26.25 26.35,25.9142136 26.35,25.5 C26.35,25.1203042 26.6321539,24.806509 26.9982294,24.7568466 L27.1,24.75 L31.9,24.75 Z" id="Line-2" fillRule="nonzero"></path>
        <path d="M13.5,3 C14.3284271,3 15,3.67157288 15,4.5 L15,5.5 C15,6.32842712 14.3284271,7 13.5,7 L4.5,7 C3.67157288,7 3,6.32842712 3,5.5 L3,4.5 C3,3.67157288 3.67157288,3 4.5,3 L13.5,3 Z M13.5,4.5 L4.5,4.5 L4.5,5.5 L13.5,5.5 L13.5,4.5 Z" id="Rectangle" fillRule="nonzero"></path>
        <path d="M21.5,9 C22.3284271,9 23,9.67157288 23,10.5 L23,15.5 C23,16.3284271 22.3284271,17 21.5,17 L4.5,17 C3.67157288,17 3,16.3284271 3,15.5 L3,10.5 C3,9.67157288 3.67157288,9 4.5,9 L21.5,9 Z M21.5,10.5 L4.5,10.5 L4.5,15.5 L21.5,15.5 L21.5,10.5 Z" id="Rectangle-Copy" fillRule="nonzero"></path>
        <path d="M21.5,19 C22.3284271,19 23,19.6715729 23,20.5 L23,22.5 C23,23.3284271 22.3284271,24 21.5,24 L4.5,24 C3.67157288,24 3,23.3284271 3,22.5 L3,20.5 C3,19.6715729 3.67157288,19 4.5,19 L21.5,19 Z M21.5,20.5 L4.5,20.5 L4.5,22.5 L21.5,22.5 L21.5,20.5 Z" id="Rectangle-Copy-2" fillRule="nonzero"></path>
        <path d="M9.5,26 C10.3284271,26 11,26.6715729 11,27.5 L11,28.5 C11,29.3284271 10.3284271,30 9.5,30 L4.5,30 C3.67157288,30 3,29.3284271 3,28.5 L3,27.5 C3,26.6715729 3.67157288,26 4.5,26 L9.5,26 Z M9.5,27.5 L4.5,27.5 L4.5,28.5 L9.5,28.5 L9.5,27.5 Z" id="Rectangle-Copy-3" fillRule="nonzero"></path>
      </g>
    </g>
  </Icon>
)
Custom6.defaultProps = {
  size: 30
}

export default Custom6