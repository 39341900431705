import React from 'react'
import Icon from 'react-icon-base'

const EmployeeRegistrationLine = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="iconEmployeeRegistrationLine" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon" transform="translate(-0.000000, 0.000000)" fillRule="nonzero">
        <path d="M14,3.15 C10.217358,3.15 7.15,6.21735796 7.15,10 C7.15,13.782642 10.217358,16.85 14,16.85 C17.782642,16.85 20.85,13.782642 20.85,10 C20.85,6.21735796 17.782642,3.15 14,3.15 Z M14,4.85 C16.843758,4.85 19.15,7.15624204 19.15,10 C19.15,12.843758 16.843758,15.15 14,15.15 C11.156242,15.15 8.85,12.843758 8.85,10 C8.85,7.15624204 11.156242,4.85 14,4.85 Z" id="Stroke-1" fill={props.color}></path>
        <path d="M14,15.15 C15.8044231,15.15 17.5476009,15.5506931 19.1064611,16.3065355 C19.5288684,16.5113475 19.7052641,17.0198091 19.5004521,17.4422164 C19.2956401,17.8646236 18.7871785,18.0410194 18.3647712,17.8362074 C17.0369869,17.1924064 15.5473798,16.85 14,16.85 C8.92830578,16.85 4.85,20.5204752 4.85,25 C4.85,25.469442 4.46944204,25.85 4,25.85 C3.53055796,25.85 3.15,25.469442 3.15,25 C3.15,19.5393204 8.02702269,15.15 14,15.15 Z" id="Path" fill={props.color}></path>
        <path d="M32.2072845,0 C32.7595693,0 33.2072845,0.44771525 33.2072845,1 C33.2072845,1.51283584 32.8212443,1.93550716 32.3239057,1.99327227 L32.2072845,2 L3.84210526,2 C2.87560695,2 2.08295543,2.7443266 2.00610653,3.69102399 L2,3.84210526 L2,36.1578947 C2,37.124393 2.7443266,37.9170446 3.69102399,37.9938935 L3.84210526,38 L34.1578947,38 C35.124393,38 35.9170446,37.2556734 35.9938935,36.308976 L36,36.1578947 L36,19.1147953 C36,18.5625105 36.4477153,18.1147953 37,18.1147953 C37.5128358,18.1147953 37.9355072,18.5008354 37.9932723,18.9981741 L38,19.1147953 L38,36.1578947 C38,38.2135204 36.3856616,39.8920971 34.355609,39.9950007 L34.1578947,40 L3.84210526,40 C1.78647962,40 0.107902921,38.3856616 0.00499932353,36.355609 L0,36.1578947 L0,3.84210526 C0,1.78647962 1.6143384,0.107902921 3.64439104,0.00499932353 L3.84210526,0 L32.2072845,0 Z M35.139312,4.2686004 L39.3955051,8.10089388 C40.0671138,8.70561309 40.1322323,9.7281825 39.5409513,10.3848666 L24.5524257,27.0313107 L18.5317516,26.9928023 L17.8641223,21.0091352 L32.8526479,4.36269113 C33.4439289,3.70600704 34.4677033,3.66388119 35.139312,4.2686004 Z M34.0687031,5.45763212 L19.5538888,21.577409 L19.9786465,25.3853052 L23.8100819,25.4097024 L38.3248962,9.2899256 L34.0687031,5.45763212 Z" id="Combined-Shape" fill={props.color}></path>
        <path d="M27.6988355,29.5 C28.2511202,29.5 28.6988355,29.9477153 28.6988355,30.5 C28.6988355,31.0128358 28.3127953,31.4355072 27.8154566,31.4932723 L27.6988355,31.5 L6.5,31.5 C5.94771525,31.5 5.5,31.0522847 5.5,30.5 C5.5,29.9871642 5.88604019,29.5644928 6.38337887,29.5067277 L6.5,29.5 L27.6988355,29.5 Z" id="Path" fill={props.color}></path>
        <path d="M14.4185245,33.5 C14.9708093,33.5 15.4185245,33.9477153 15.4185245,34.5 C15.4185245,35.0128358 15.0324844,35.4355072 14.5351457,35.4932723 L14.4185245,35.5 L6.5,35.5 C5.94771525,35.5 5.5,35.0522847 5.5,34.5 C5.5,33.9871642 5.88604019,33.5644928 6.38337887,33.5067277 L6.5,33.5 L14.4185245,33.5 Z" id="Path" fill={props.color}></path>
        <path d="M18.2211234,33.5 C18.7734081,33.5 19.2211234,33.9477153 19.2211234,34.5 C19.2211234,35.0128358 18.8350832,35.4355072 18.3377445,35.4932723 L18.2211234,35.5 L17.5,35.5 C16.9477153,35.5 16.5,35.0522847 16.5,34.5 C16.5,33.9871642 16.8860402,33.5644928 17.3833789,33.5067277 L17.5,33.5 L18.2211234,33.5 Z" id="Path" fill={props.color}></path>
      </g>
    </g>
  </Icon>
)
EmployeeRegistrationLine.defaultProps = {
  size: 40,
  color: "#3498DB"
}

export default EmployeeRegistrationLine
