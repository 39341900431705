import React from 'react'
import Icon from 'react-icon-base'

const JobcanUser = props => (
  <Icon viewBox="0 0 15 16" {...props}>
    <g fill='none' fillRule='evenodd' transform='translate(0 .5)'>
      <circle cx='7.5' cy='7.5' r='7.5' fill='currentColor' />
      <path fill='#2F3438' fillRule='nonzero' d='M7.515 2.637c1.503 0 2.722 1.234 2.722 2.757 0 1.522-1.22 2.756-2.722 2.756-1.503 0-2.721-1.234-2.721-2.756 0-1.523 1.218-2.757 2.721-2.757zm0 .982c-.967 0-1.752.795-1.752 1.775 0 .98.785 1.774 1.752 1.774.968 0 1.752-.794 1.752-1.774s-.784-1.775-1.752-1.775z' />
      <path fill='#2F3438' fillRule='nonzero' d='M11.83 11.539c0 .271-.217.49-.484.49a.488.488 0 0 1-.485-.49c0-1.872-1.498-3.389-3.346-3.389S4.17 9.667 4.17 11.539c0 .271-.217.49-.484.49a.488.488 0 0 1-.485-.49c0-2.414 1.932-4.37 4.315-4.37 2.383 0 4.315 1.956 4.315 4.37z' />
    </g>
  </Icon>
)
JobcanUser.defaultProps = {
  size: 16
}

export default JobcanUser
