import { FC, ReactNode } from 'react';
import { ProgressBar as Bar } from '@jbc-year-end-adj/2024/components/ui/ProgressBar';
import { Question, QuestionGroup } from '../../types';
import { currentQuestionName, progress, totalQuestionsCount } from './utils';
import styles from './ProgressBar.scss';

type ProgressBarProps = {
  questionGroups: QuestionGroup[];
  currentQuestion: Question | undefined;
};

export const ProgressBar: FC<ProgressBarProps> = ({ questionGroups, currentQuestion }) => {
  const answeredCount = questionGroups.reduce((count, group) => {
    const finishedQuestionCount = group.questions.reduce((innerCount, question) => {
      const status = question.requestQuestion?.status;
      return status === 'answered' || status === 'skipped' ? innerCount + 1 : innerCount;
    }, 0);

    return count + finishedQuestionCount;
  }, 0);
  const name = currentQuestion ? currentQuestionName(questionGroups, currentQuestion) : '年末調整終了';
  const total = totalQuestionsCount(questionGroups);

  return (
    <Container>
      <Caption name={name} answeredCount={answeredCount} total={total} />
      <Progress answeredCount={answeredCount} total={total} />
    </Container>
  );
};

type ContainerProps = {
  children: ReactNode;
};

export const Container: FC<ContainerProps> = ({ children }) => {
  return <div className={styles.progress}>{children}</div>;
};

type CaptionProps = {
  name: string;
  answeredCount: number;
  total: number;
};

export const Caption: FC<CaptionProps> = ({ name, answeredCount, total }) => {
  return (
    <div className={styles.caption}>
      <span>{name}</span>
      <span>
        {answeredCount}/{total}
      </span>
    </div>
  );
};

type ProgressProps = {
  answeredCount: number;
  total: number;
};

export const Progress: FC<ProgressProps> = ({ answeredCount, total }) => {
  return <Bar progress={progress(answeredCount, total)} />;
};
