import { FC } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';

import Button from 'jbc-front/components/Button';
import { ButtonRow } from 'jbc-front/components/presenters/layout/ButtonRow';

import { FormSection } from 'components/form/FormSection';
import { FileField } from 'components/react-hook-form/FileField';
import { AmountField } from 'components/react-hook-form/AmountField';
import { FormAddButton } from 'components/form/FormAddButton';
import { Section } from 'components/ui/Section';

import { Schema, schema, generateDefaultValues, premiumObj } from './schema';
import { UPDATE_PREMIUM, convertFormDataToQueryVariables } from './mutation';
import { useEmployeeInfo } from '../../EmployeeInfoProvider';
import { FETCH_EMPLOYEE } from '../../../../query';
import { DeletableSection } from '../../../../components/DeletableSection';
import { Grid } from '../../../../components/Grid';
import { FormSeparator } from '../../../../components/FormSeparator';
import { FloatingButton } from '../../../../components/FloatingButton';

// @ts-ignore
import { useNotify } from 'actions';
// @ts-ignore
import { useMutation } from 'components/Graphql';
import { FilePreview } from 'components/feature/FilePreview';

type FormProps = {
  onCancel: () => void;
};

export const Form: FC<FormProps> = ({ onCancel }) => {
  const { employee } = useEmployeeInfo();
  const [update, { loading }] = useMutation(UPDATE_PREMIUM);
  const notify = useNotify();

  const methods = useForm<Schema>({
    defaultValues: generateDefaultValues(employee.profile.premium),
    resolver: yupResolver(schema),
    mode: 'onBlur'
  });

  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
    setValue,
    reset
  } = methods;

  const onSubmit: SubmitHandler<Schema> = async (data: Schema) => {
    const variables = convertFormDataToQueryVariables(data, String(employee.id));
    await update({
      variables,
      refetchQueries: [FETCH_EMPLOYEE]
    });
    onCancel();
    notify('共済契約掛金・個人型企業型年金掛金を保存しました。', 'success');
  };

  const handleDelete = () => {
    reset({ premium: undefined });
  };

  const handleAddPremium = () => {
    reset({ premium: premiumObj });
  };

  const premium = watch('premium');
  const smallAndMediumSizedEnterprisesImage = watch('premium.smallAndMediumSizedEnterprisesImage');
  const corporatePensionImage = watch('premium.corporatePensionImage');
  const personalPensionImage = watch('premium.personalPensionImage');
  const disabilityMutualAidImage = watch('premium.disabilityMutualAidImage');

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormSection>
          {premium ? (
            <DeletableSection>
              <DeletableSection.Header onDelete={handleDelete}>共済契約掛金・個人型企業型年金掛金情報</DeletableSection.Header>
              <DeletableSection.Body>
                <Grid colGap col2>
                  <Section>
                    <Section.Header>独立行政法人中小企業基盤整備機構の共済契約</Section.Header>
                    <Section.Body>
                      <FormSection>
                        <AmountField
                          label="掛金"
                          error={errors.premium?.smallAndMediumSizedEnterprises?.message}
                          isError={!!errors.premium?.smallAndMediumSizedEnterprises?.message}
                          {...register('premium.smallAndMediumSizedEnterprises')}
                        />
                        <FileField
                          label="証明書画像"
                          note="対応可能形式：jpg, jpeg, gif, png, pdf"
                          name={`premium.smallAndMediumSizedEnterprisesImage`}
                        >
                          {smallAndMediumSizedEnterprisesImage && (
                            <FileField.FileContainer>
                              <FileField.File
                                file={smallAndMediumSizedEnterprisesImage}
                                onRemove={() => setValue(`premium.smallAndMediumSizedEnterprisesImage`, undefined)}
                              />
                              <FilePreview file={smallAndMediumSizedEnterprisesImage} />
                            </FileField.FileContainer>
                          )}
                        </FileField>
                      </FormSection>
                    </Section.Body>
                  </Section>

                  <Section>
                    <Section.Header>確定拠出年金法に規定する企業型年金加入者</Section.Header>
                    <Section.Body>
                      <FormSection>
                        <AmountField
                          label="掛金"
                          error={errors.premium?.corporatePension?.message}
                          isError={!!errors.premium?.corporatePension?.message}
                          {...register('premium.corporatePension')}
                        />
                        <FileField label="証明書画像" note="対応可能形式：jpg, jpeg, gif, png, pdf" name={`premium.corporatePensionImage`}>
                          {corporatePensionImage && (
                            <FileField.FileContainer>
                              <FileField.File
                                file={corporatePensionImage}
                                onRemove={() => setValue(`premium.corporatePensionImage`, undefined)}
                              />
                              <FilePreview file={corporatePensionImage} />
                            </FileField.FileContainer>
                          )}
                        </FileField>
                      </FormSection>
                    </Section.Body>
                  </Section>

                  <Section>
                    <Section.Header>確定拠出年金法に規定する個人型年金加入者</Section.Header>
                    <Section.Body>
                      <FormSection>
                        <AmountField
                          label="掛金"
                          error={errors.premium?.personalPension?.message}
                          isError={!!errors.premium?.personalPension?.message}
                          {...register('premium.personalPension')}
                        />
                        <FileField label="証明書画像" note="対応可能形式：jpg, jpeg, gif, png, pdf" name={`premium.personalPensionImage`}>
                          {personalPensionImage && (
                            <FileField.FileContainer>
                              <FileField.File
                                file={personalPensionImage}
                                onRemove={() => setValue(`premium.personalPensionImage`, undefined)}
                              />
                              <FilePreview file={personalPensionImage} />
                            </FileField.FileContainer>
                          )}
                        </FileField>
                      </FormSection>
                    </Section.Body>
                  </Section>

                  <Section>
                    <Section.Header>心身障害者扶養共済制度に関する契約の掛金</Section.Header>
                    <Section.Body>
                      <FormSection>
                        <AmountField
                          label="掛金"
                          error={errors.premium?.disabilityMutualAid?.message}
                          isError={!!errors.premium?.disabilityMutualAid?.message}
                          {...register('premium.disabilityMutualAid')}
                        />
                        <FileField
                          label="証明書画像"
                          note="対応可能形式：jpg, jpeg, gif, png, pdf"
                          name={`premium.disabilityMutualAidImage`}
                        >
                          {disabilityMutualAidImage && (
                            <FileField.FileContainer>
                              <FileField.File
                                file={disabilityMutualAidImage}
                                onRemove={() => setValue(`premium.disabilityMutualAidImage`, undefined)}
                              />
                              <FilePreview file={disabilityMutualAidImage} />
                            </FileField.FileContainer>
                          )}
                        </FileField>
                      </FormSection>
                    </Section.Body>
                  </Section>
                </Grid>
              </DeletableSection.Body>
            </DeletableSection>
          ) : (
            <FormAddButton onClick={handleAddPremium}>共済契約掛金・個人型企業型年金掛金情報追加</FormAddButton>
          )}

          <FormSeparator />

          <FloatingButton>
            <ButtonRow>
              <Button onClick={onCancel} disabled={loading}>
                キャンセル
              </Button>
              <Button primary onClick={handleSubmit(onSubmit)} disabled={loading}>
                保存
              </Button>
            </ButtonRow>
          </FloatingButton>
        </FormSection>
      </form>
    </FormProvider>
  );
};
