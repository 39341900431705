import React, { useState, useRef } from 'react';
import styles from './Hint.scss';

export const HoverHint = ({ message, children }) => {
  const [isHover, setHoverState] = useState(false);
  const container = useRef(null);
  const shapeWidth = 15;
  const childWidth = container?.current?.clientWidth;
  const showHint = () => setHoverState(true);
  const hideHint = () => setHoverState(false);
  return (
    <div className={styles.note} onFocus={showHint} onMouseOver={showHint} onBlur={hideHint} onMouseOut={hideHint} ref={container}>
      {isHover && (
        <>
          <div className={styles.shape} style={{ left: childWidth ? `${childWidth / 2 - shapeWidth / 2}px` : '0' }} />
          <div className={styles.message}>{message}</div>
        </>
      )}
      {children}
    </div>
  );
};

export default HoverHint;
