import { gql } from '@apollo/client';

export const CREATE_EMPLOYEE_CSV_EXPORT_REQUEST = gql`
  mutation createEmployeeCsvExportRequest($input: CreateEmployeeCsvExportRequestInput!) {
    createEmployeeCsvExportRequest(input: $input) {
      clientYearly {
        id
        employeeCsvExportRequestRunning
      }
    }
  }
`;
