import React from 'react';
import { gql } from '@apollo/client';
import { useQuery, useYear } from '../components';
import styles from '../components/AsyncTaskError.scss';

const EMPLOYEES_WITH_NO_CITY = gql`
  query employeesWithNoCity($year: Int!) {
    client {
      id
      clientYearly(year: $year) {
        id
        employeesWithNoCity {
          id
          staffCode
          profile {
            id
            fullName
          }
        }
      }
    }
  }
`;

const EmployeesWithNoCity = () => {
  const year = useYear();
  const { data, loading } = useQuery(EMPLOYEES_WITH_NO_CITY, { fetchPolicy: 'network-only', variables: { year } });
  if (loading) return null;
  const {
    client: {
      clientYearly: { employeesWithNoCity }
    }
  } = data;
  return (
    employeesWithNoCity.length > 0 && (
      <div className={styles.errorWrap}>
        <div className={styles.inner}>
          <p className={styles.title}>市区町村の分類ができなかった従業員がいます。</p>
          <p className={styles.error}>従業員の現住所・住民票住所の郵便番号が正しくありません。従業員情報をご確認ください。</p>
          <ul className={styles.list}>
            {employeesWithNoCity.map(employee => (
              <li key={employee.id}>
                {employee.staffCode} {employee.profile.fullName}
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  );
};

export default EmployeesWithNoCity;
