import React from 'react';
import useReactRouter from 'use-react-router';
import { useApolloClient } from '@apollo/client';
import { Memo, PreviewPdf, useReportList, useSession } from '../components';
import { makeBackUrl } from '../utils/url';
import { useNotify } from '../actions';
import {
  DependentDeductionThisYearButton,
  DependentDeductionNextYearButton,
  InsuranceDeductionButton,
  BsiDeductionButton,
  SpouseDeductionButton,
  WithholdingSlipButton
} from './ReportButton';
import recordDisplay from '../utils/recordDisplay';
import styles from '../pages/Result.scss';

export const EmployeeList = ({ currentYearMaster, nextYearMaster, employees }) => (
  <div className="l-overflow-scroll">
    <table className={styles.table}>
      <TableHeader currentYearMaster={currentYearMaster} nextYearMaster={nextYearMaster} />
      <TableBody employees={employees} />
    </table>
  </div>
);

const TableHeader = ({ currentYearMaster, nextYearMaster }) => {
  const {
    clientYearly: { year }
  } = useSession();
  return (
    <thead>
      <tr>
        <th className={styles.noteColumn}>メモ</th>
        <th className={styles.staffcodeColumn}>スタッフコード</th>
        <th className={styles.nameColumn}>氏名</th>
        <th className={styles.employmentStatusColumn}>雇用形態</th>
        <th className={styles.positionColumn}>役職</th>
        <th className={styles.recordsColumn}>{`${currentYearMaster.eraCode}${currentYearMaster.eraNumber}扶養`}</th>
        <th className={styles.recordsColumn}>{`${nextYearMaster.eraCode}${nextYearMaster.eraNumber}扶養`}</th>
        <th className={styles.recordsColumn}>保険</th>
        <th className={styles.recordsColumn}>{year >= 2020 ? '基配所' : '配偶者'}</th>
        <th className={styles.withholdingSlipColumn}>源泉徴収票</th>
      </tr>
    </thead>
  );
};

const ReportColumns = ({ employee, onClickReport, onClickWithholdingSlip }) => {
  const {
    clientYearly: { year }
  } = useSession();
  const employeeId = employee.id;

  return (
    <>
      <td>
        <DependentDeductionThisYearButton employee={employee} onClick={onClickReport('dependentDeductionThisYear', employeeId)} />
      </td>
      <td>
        <DependentDeductionNextYearButton
          employee={employee}
          onClick={onClickReport('dependentDeductionNextYear', employeeId)}
          year={year}
        />
      </td>
      <td>
        <InsuranceDeductionButton employee={employee} onClick={onClickReport('insuranceDeduction', employeeId)} />
      </td>
      <td>
        {year >= 2020 ? (
          <BsiDeductionButton employee={employee} onClick={onClickReport('bsiDeduction', employeeId)} />
        ) : (
          <SpouseDeductionButton employee={employee} onClick={onClickReport('spouseDeduction', employeeId)} />
        )}
      </td>
      <td>
        <WithholdingSlipButton employee={employee} onClick={onClickWithholdingSlip(employee)} />
      </td>
    </>
  );
};

const Row = ({ employee, onClickRow, onClickReport, onClickWithholdingSlip }) => {
  const isValidEmployeeStatus = ['accepted', 'not_need_adj'].includes(employee.status);
  const isValidResidentData = employee.requiredFieldsFilled || employee.isNonResident;
  const isCompleteData = isValidEmployeeStatus && isValidResidentData;

  return (
    <tr onClick={onClickRow} className="table-hover">
      <td // eslint-disable-line
        onClick={e => e.stopPropagation()}
        className={styles.memo}
      >
        <Memo className={styles.resultMemo} employee={employee} size={30} />
      </td>
      <td>{employee.staffCode}</td>
      <td>{employee.profile.fullName}</td>
      <td>{recordDisplay(employee.employmentType)}</td>
      <td>{recordDisplay(employee.position)}</td>
      {isCompleteData ? (
        <ReportColumns employee={employee} onClickReport={onClickReport} onClickWithholdingSlip={onClickWithholdingSlip} />
      ) : (
        <td colSpan={5} className={styles.notCompleted}>
          情報入力が完了していません
        </td>
      )}
    </tr>
  );
};

const TableBody = ({ employees }) => {
  const {
    history,
    location: { search, pathname }
  } = useReactRouter();
  const {
    clientYearly: { year, fixed },
    me: { hasMyNumberPermission }
  } = useSession();
  const notify = useNotify();
  const apolloClient = useApolloClient();
  const reports = useReportList(year);

  return (
    <tbody>
      <PreviewPdf>
        {({ show, setPdf }) => {
          const handleBackEmployeeDetail = employeeId =>
            history.push(
              `/${year}/employee/${employeeId}?back_to=${makeBackUrl({
                search,
                pathname
              })}`
            );

          const handleBackWithholdingSlipDetail = employeeId =>
            history.push(
              `/${year}/employee/${employeeId}/withholding_slip?back_to=${makeBackUrl({
                pathname,
                search
              })}`
            );

          const handleClickReport = (report, employeeId, showMyNumber) => e => {
            e.stopPropagation();
            reports[report].show({
              client: apolloClient,
              employeeId,
              show,
              setPdf,
              notify,
              year,
              showMyNumber,
              hasMyNumberPermission,
              edit: !fixed,
              onEditClick: () => handleBackEmployeeDetail(employeeId)
            });
          };

          const handleClickWithholdingSlip = employee => async e => {
            const employeeId = employee.id;

            e.stopPropagation();
            if (employee.withholdingSlip && !employee.hasNoPayment) {
              reports.withholdingSlip.show({
                client: apolloClient,
                employeeId,
                show,
                setPdf,
                notify,
                year,
                onEditClick: () => handleBackWithholdingSlipDetail(employeeId),
                edit: !fixed,
                showPrint: fixed
              });
            } else {
              handleBackWithholdingSlipDetail(employeeId);
            }
          };

          return (
            <>
              {employees.length > 0 ? (
                employees.map(employee => (
                  <Row
                    key={employee.id}
                    employee={employee}
                    reports={reports}
                    onClickRow={() => handleBackEmployeeDetail(employee.id)}
                    onClickReport={handleClickReport}
                    onClickWithholdingSlip={handleClickWithholdingSlip}
                  />
                ))
              ) : (
                <tr>
                  <td colSpan={10}>該当の従業員は見つかりませんでした</td>
                </tr>
              )}
            </>
          );
        }}
      </PreviewPdf>
    </tbody>
  );
};
