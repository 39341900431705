import { compose } from 'lodash/fp';
import moment from 'moment';
import _ from 'lodash';
import recordDisplay, { numberWithSplit } from '../utils/recordDisplay';

export const parseNumber = num => +(num ? String(num).replace(/,/g, '') : 0);
export const parseNumbers = (...nums) => nums.map(parseNumber);
const invalidNums = (...nums) => nums.some(num => !_.isFinite(num) || num < 0);
const invalidNumsAllowMinus = (...nums) => nums.some(num => !_.isFinite(num));

const calcSalaryEarning = x => {
  if (x < 651000) {
    return 0;
  } else if (x < 1619000) {
    return x - 650000;
  } else if (x < 1620000) {
    return 969000;
  } else if (x < 1622000) {
    return 970000;
  } else if (x < 1624000) {
    return 972000;
  } else if (x < 1628000) {
    return 974000;
  } else if (x < 6600000) {
    const y = Math.floor(x / 4000) * 1000;
    return Math.floor(_.max([24 * y, 28 * y - 1800000, 32 * y - 5400000]) / 10);
  } else {
    return Math.floor(_.max([9 * x - 12000000, 10 * x - 22000000]) / 10);
  }
};

const calcSalaryEarningFrom2020 = x => {
  if (x < 551000) {
    return 0;
  } else if (x < 1619000) {
    return x - 550000;
  } else if (x < 1620000) {
    return 1069000;
  } else if (x < 1622000) {
    return 1070000;
  } else if (x < 1624000) {
    return 1072000;
  } else if (x < 1628000) {
    return 1074000;
  } else if (x < 6600000) {
    const y = Math.floor(x / 4000) * 1000;
    return Math.floor(_.max([24 * y + 1000000, 28 * y - 800000, 32 * y - 4400000]) / 10);
  } else {
    return Math.floor(_.max([9 * x - 11000000, 10 * x - 19500000]) / 10);
  }
};

const incomeAdjustmentDeduction = value => _.clamp(Math.ceil((value - 8500000) / 10), 0, 150000);

export const salaryEarnings = (value, year, applyIncomeAdjustmentDeduction = false) =>
  compose(numberWithSplit, income => {
    const x = parseNumber(income);
    if (invalidNums(x)) {
      return recordDisplay();
    }
    return year > 2019
      ? calcSalaryEarningFrom2020(x) - (applyIncomeAdjustmentDeduction ? incomeAdjustmentDeduction(x) : 0)
      : calcSalaryEarning(x);
  })(value);

export const generalEarnings = compose(numberWithSplit, (income, expense) => {
  const [x, y] = parseNumbers(income, expense);
  if (invalidNums(x, y)) {
    return recordDisplay();
  }
  return Math.max(x - y, 0);
});

export const generalEarningsAllowMinus = compose(numberWithSplit, (income, expense) => {
  const [x, y] = parseNumbers(income, expense);
  if (invalidNums(x, y)) {
    return recordDisplay();
  }
  return x - y;
});

const calcMiscellaneousEarnings = compose(numberWithSplit, (officalPension, other, expense, birthday, year) => {
  [officalPension, other, expense] = parseNumbers(officalPension, other, expense);
  birthday = birthday && moment(birthday);
  if (invalidNums(officalPension, other, expense) || !birthday || !birthday.isValid()) {
    return recordDisplay();
  }
  const x = officalPension;
  const y = Math.max(other - expense, 0);
  let c;
  if (birthday.isSameOrBefore(`${year - 64}-01-01`)) {
    if (x <= 3300000) {
      c = x - 1200000;
    } else if (x <= 4100000) {
      c = x * 0.75 - 375000;
    } else if (x <= 7700000) {
      c = x * 0.85 - 785000;
    } else {
      c = x * 0.95 - 1555000;
    }
  } else {
    if (x <= 1300000) {
      c = x - 700000;
    } else if (x <= 4100000) {
      c = x * 0.75 - 375000;
    } else if (x <= 7700000) {
      c = x * 0.85 - 785000;
    } else {
      c = x * 0.95 - 1555000;
    }
  }
  return Math.floor(Math.max(0, c)) + y;
});

const calcMiscellaneousEarningsFrom2020 = compose(numberWithSplit, (officalPension, other, expense, birthday, year, earnings) => {
  [officalPension, other, expense] = parseNumbers(officalPension, other, expense);
  birthday = birthday && moment(birthday);
  if (invalidNums(officalPension, other, expense) || !birthday || !birthday.isValid()) {
    return recordDisplay();
  }
  const x = officalPension;
  const y = Math.max(other - expense, 0);
  let z;
  {
    const { salary, business, dividend, realEstate, retirement, other } = earnings;
    const sum = _.sum([...parseNumbers(salary, business, dividend, realEstate, retirement, other), y]);
    if (sum <= 10000000) {
      z = 0;
    } else if (sum <= 20000000) {
      z = 100000;
    } else {
      z = 200000;
    }
  }
  let c;
  if (birthday.isSameOrBefore(`${year - 64}-01-01`)) {
    if (x <= 3300000) {
      c = x - 1100000;
    } else if (x <= 4100000) {
      c = x * 0.75 - 275000;
    } else if (x <= 7700000) {
      c = x * 0.85 - 685000;
    } else if (x <= 10000000) {
      c = x * 0.95 - 1455000;
    } else {
      c = x - 1955000;
    }
  } else {
    if (x <= 1300000) {
      c = x - 600000;
    } else if (x <= 4100000) {
      c = x * 0.75 - 275000;
    } else if (x <= 7700000) {
      c = x * 0.85 - 685000;
    } else if (x <= 10000000) {
      c = x * 0.95 - 1455000;
    } else {
      c = x - 1955000;
    }
  }
  c += z;
  return Math.floor(Math.max(0, c)) + y;
});

export const miscellaneousEarnings = (officalPension, other, expense, birthday, year, earnings) =>
  year >= 2020
    ? calcMiscellaneousEarningsFrom2020(officalPension, other, expense, birthday, year, earnings)
    : calcMiscellaneousEarnings(officalPension, other, expense, birthday, year);

export const retirementIncomeDeductionAmount = compose(numberWithSplit, (lengthOfService, retireForDisablity, retirementIncome) => {
  [lengthOfService, retirementIncome] = parseNumbers(lengthOfService, retirementIncome);
  if (invalidNums(lengthOfService, retirementIncome)) {
    return recordDisplay();
  }
  if (retirementIncome === 0) {
    return 0;
  }
  let x;
  if (lengthOfService <= 2) {
    x = 800_000;
  } else if (lengthOfService <= 20) {
    x = 400_000 * lengthOfService;
  } else {
    x = 8_000_000 + 700_000 * (lengthOfService - 20);
  }
  if (retireForDisablity) {
    x += 1_000_000;
  }
  return x;
});

// 一般退職手当等
const generalRetirementAllowance = (income, deduction) => {
  return Math.max(Math.floor((income - deduction) / 2), 0);
};

// 特定役員退職金
const specifiedOfficerRetirementAllowance = (income, deduction) => {
  return Math.max(Math.floor(income - deduction), 0);
};

// 令和4年以後 短期退職手当
const r4ShortTermSeverancePayment = (income, deduction, isOfficerRetirementAllowance, year, lengthOfService) => {
  if (!lengthOfService) {
    return 0;
  }
  if (!(lengthOfService <= 5)) {
    return generalRetirementAllowance(income, deduction);
  }
  if (income - deduction <= 3000000) {
    return generalRetirementAllowance(income, deduction);
  }
  if (income - deduction > 3000000) {
    return Math.max(Math.floor(1500000 + (income - (3000000 + deduction))), 0);
  }
};

// 退職所得
export const retirementEarnings = compose(numberWithSplit, (income, deduction, isOfficerRetirementAllowance, lengthOfService, year) => {
  [income, deduction] = parseNumbers(income, deduction);
  if (invalidNums(income, deduction)) {
    return recordDisplay();
  }
  if (isOfficerRetirementAllowance) {
    return specifiedOfficerRetirementAllowance(income, deduction);
  }
  if (year >= 2022) {
    return r4ShortTermSeverancePayment(income, deduction, isOfficerRetirementAllowance, year, lengthOfService);
  }
  return generalRetirementAllowance(income, deduction);
});

export const totalEarnings = compose(numberWithSplit, (...nums) => {
  nums = parseNumbers(...nums);
  if (invalidNumsAllowMinus(...nums)) {
    return recordDisplay();
  }
  return Math.max(_.sum(nums), 0);
});

export const calcTotalSalaryIncome = compose(numberWithSplit, (mainJobIncome, sideJobIncome, formerJobsPaymentAmountSum) => {
  return parseNumber(mainJobIncome) + parseNumber(sideJobIncome) + parseNumber(formerJobsPaymentAmountSum);
});
