import React from 'react'
import Icon from 'react-icon-base'

const Key = props => (
  <Icon viewBox="0 0 18 21" {...props}>
    <g fill="none" transform="translate(1 1)">
      <path d="M5.785 6h-4.56c-.169 0-.33.026-.476.072-.44.14-.749.466-.749.846v10.164C0 17.59.549 18 1.226 18H14.774c.677 0 1.226-.411 1.226-.918V6.918C16 6.41 15.451 6 14.774 6H5.785z" stroke="currentColor" strokeLinecap="round" strokeWidth="2"/>
      <rect height="4" width="2" fill="currentColor" rx="1" x="7" y="9"/>
      <circle cx="8" cy="13" fill="currentColor" r="2"/>
      <path d="M3 5.511C3 1.572 6.143 0 7.984 0 9.825 0 13 1.106 13 5.547" stroke="currentColor" strokeWidth="2"/>
    </g>
  </Icon>
)
Key.defaultProps = {
  size: 21
}

export default Key
