import React from 'react'
import Icon from 'react-icon-base'

const DocumentStep3 = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="E_書類作成" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Artboard" transform="translate(-751.000000, -628.000000)">
        <g id="3-copy-3" transform="translate(751.000000, 628.000000)">
          <circle id="Oval" fill={props.color} cx="20" cy="20" r="20"></circle>
          <path d="M20,2.22222222 C29.8183956,2.22222222 37.7777778,10.1816044 37.7777778,20 C37.7777778,29.8183956 29.8183956,37.7777778 20,37.7777778 C10.1816044,37.7777778 2.22222222,29.8183956 2.22222222,20 C2.22222222,10.1816044 10.1816044,2.22222222 20,2.22222222 Z M20,5.22222222 C11.8384587,5.22222222 5.22222222,11.8384587 5.22222222,20 C5.22222222,28.1615413 11.8384587,34.7777778 20,34.7777778 C28.1615413,34.7777778 34.7777778,28.1615413 34.7777778,20 C34.7777778,11.8384587 28.1615413,5.22222222 20,5.22222222 Z" id="Oval" fill="#FFFFFF" fillRule="nonzero"></path>
          <path d="M19.87,28.32 C22.79,28.32 24.75,26.48 24.75,23.78 C24.75,21.76 23.59,20.38 21.53,19.94 L21.53,19.8 C23.37,19.48 24.45,18.16 24.45,16.36 C24.45,14.14 22.65,12.52 19.87,12.52 C17.55,12.52 15.83,13.66 15.25,15.82 L17.71,16.56 C17.91,15.12 18.77,14.46 19.83,14.46 C21.11,14.46 21.93,15.2 21.93,16.6 C21.93,18.14 21.13,19 19.13,19 L18.07,19 L18.07,21.04 L19.23,21.04 C21.37,21.04 22.19,22.06 22.19,23.8 C22.19,25.42 21.29,26.38 19.85,26.38 C18.53,26.38 17.81,25.76 17.47,24.22 L15.01,24.94 C15.69,27.3 17.35,28.32 19.87,28.32 Z" id="3" fill="#FFFFFF" fillRule="nonzero"></path>
        </g>
      </g>
    </g>
  </Icon>
)

DocumentStep3.defaultProps = {
  size: 30,
  color: "#3498DB"
}

export default DocumentStep3
