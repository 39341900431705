import { FC, ComponentProps, FocusEventHandler } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { InputWithText } from '../../form/InputWithText';
import { amountFormat } from '@jbc-year-end-adj/common/utils/formatter';

type AmountFieldProps = Omit<ComponentProps<typeof InputWithText>, 'text'> & {
  name: string;
};

export const AmountField: FC<AmountFieldProps> = ({ name, ...inputProps }) => {
  const { control } = useFormContext();

  const { field } = useController({
    name,
    control
  });

  const handleBlur: FocusEventHandler = _ => {
    field.onChange(amountFormat(field.value));
    field.onBlur();
  };

  return <InputWithText {...inputProps} {...field} text="円" onBlur={handleBlur} />;
};
