import React from 'react';
import { Section } from 'jbc-front/components/Form';
import styles from './FormPlaceholder.scss';

export const FormPlaceholder = () => (
  <div className="u-mt30">
    <Section>
      <div className={`l-flex ${styles.placeholder}`}>ここに入力項目が表示されます</div>
    </Section>
  </div>
);
