import { FC } from 'react';
import { BasicInfos as BasicInfosTemplate } from 'features/questions/templates/BasicInfos';
import { FormSection } from 'components/form/FormSection';
import { Row } from 'components/form/Row';
import { Input } from 'components/form/Input';
import { DateField } from 'components/form/DateField';
import { Radio } from 'components/form/Radio';
import { SelectField } from 'components/form/SelectField';
import { PostcodeInput } from 'components/form/PostcodeInput';
import { Section } from 'components/ui/Section';
import { usePrefectureOptions } from 'hooks/usePrefectureOptions';
import { useWizard } from '../WizardProvider';
import { dateFormFormat } from '@jbc-year-end-adj/common/utils/formatter';

export const BasicInfos: FC = () => {
  const { question, request } = useWizard();
  const { prefectureOptions } = usePrefectureOptions();

  return (
    <BasicInfosTemplate question={question}>
      <Section>
        <Section.Header>基本情報</Section.Header>
        <Section.Body>
          <FormSection>
            <Row>
              <Input label="姓" value={request.profile?.lastName} required disabled />
              <Input label="名" value={request.profile?.firstName} required disabled />
            </Row>

            <Row>
              <Input label="姓(カナ)" value={request.profile?.lastNameKana} required disabled />
              <Input label="名(カナ)" value={request.profile?.firstNameKana} required disabled />
            </Row>

            <DateField
              label="生年月日"
              note="例）1981/01/23、S56.1.23"
              value={dateFormFormat(request.profile?.birthday, 'L')}
              required
              disabled
            />

            <Radio.Container label="性別" required>
              <Radio value="male" disabled defaultChecked={request.profile?.gender === 'male'}>
                男性
              </Radio>
              <Radio value="female" disabled defaultChecked={request.profile?.gender === 'female'}>
                女性
              </Radio>
            </Radio.Container>

            <PostcodeInput
              required
              note="現在の住所をご記入ください。"
              firstCodeProps={{ value: request.profile?.postcode0, disabled: true }}
              lastCodeProps={{ value: request.profile?.postcode1, disabled: true }}
            />

            <Row>
              <SelectField
                label="都道府県"
                options={prefectureOptions}
                value={prefectureOptions.find(option => option.value === request.profile?.prefectureId)}
                required
                disabled
              />
              <Input label="市区町村" value={request.profile?.city} required disabled />
            </Row>

            <Row>
              <Input label="丁目番地号" value={request.profile?.street} disabled />
              <Input label="建物名" value={request.profile?.building} disabled />
            </Row>

            <Radio.Container label="外国人区分" required>
              <Radio value="japanese" defaultChecked={request.profile?.nationalType === 'japanese'} disabled>
                日本人
              </Radio>
              <Radio value="foreigner" defaultChecked={request.profile?.nationalType === 'foreigner'} disabled>
                外国人
              </Radio>
            </Radio.Container>

            <Radio.Container
              label="（源泉徴収票）災害者"
              note="給与所得者が災害減免法により源泉所得税及び復興特別所得税の徴収猶予又は還付を受けた場合は年末調整の対象外になります。"
              required
            >
              <Radio defaultChecked={!request.profile?.isDisasterSufferer} disabled>
                対象外
              </Radio>
              <Radio defaultChecked={request.profile?.isDisasterSufferer} disabled>
                災害者
              </Radio>
            </Radio.Container>

            {request.profile?.isDisasterSufferer && (
              <Input label="徴収猶予税額" value={request.profile?.taxPostponementAmount} required disabled />
            )}
          </FormSection>
        </Section.Body>
      </Section>
      <Section>
        <Section.Header>住民票住所</Section.Header>
        <Section.Body>
          <FormSection>
            <PostcodeInput
              required
              note="住民票住所をご確認ください。"
              firstCodeProps={{ value: request.profile?.residentCard?.postcode0, disabled: true }}
              lastCodeProps={{ value: request.profile?.residentCard?.postcode1, disabled: true }}
            />

            <Row>
              <SelectField
                label="都道府県"
                options={prefectureOptions}
                value={prefectureOptions.find(option => option.value === request.profile?.residentCard?.prefectureId)}
                required
                disabled
              />
              <Input label="市区町村" value={request.profile?.residentCard?.city} required disabled />
            </Row>

            <Row>
              <Input label="丁目番地号" value={request.profile?.residentCard?.street} disabled />
              <Input label="建物名" value={request.profile?.residentCard?.building} disabled />
            </Row>
          </FormSection>
        </Section.Body>
      </Section>
    </BasicInfosTemplate>
  );
};
