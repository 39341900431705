import React, { useMemo } from 'react';
import { lifecycle, withProps } from 'recompose';
import { parse } from 'query-string';
import { gql } from '@apollo/client';
import styles from './QuestionDescriptions.scss';
import { LoadingPage, Progress, useQuery, useYear } from '../components';
import _ from 'lodash';
import { Redirect } from 'react-router';
import QuestionGroup from '../components/QuestionGroup';
import * as questionComponents from '../question_descriptions';
import { QuestionDescriptionsProvider } from '../components/QuestionDescription';

const QUESTION_DESCRIPTIONS = gql`
  query questionDescriptions {
    questionGroups {
      id
      name
      no
      questions {
        id
        no
        name
        code
        questionDescriptions {
          id
          questionId
          field
          name
          description
          defaultDescription
        }
      }
    }
  }
`;

const fillWithAnswered = questionGroup => ({
  ...questionGroup,
  questions: questionGroup.questions.map(q => ({ ...q, status: 'answered' }))
});

const makeLink = year => code => `/${year ? year + '/' : ''}settings/question_descriptions?current=${code}`;

const useMakeYearlyLink = () => {
  const year = useYear();
  return useMemo(() => makeLink(year), [year]);
};

const QuestionDescriptions = ({ current }) => {
  const makeYearlyLink = useMakeYearlyLink();
  const { loading, data } = useQuery(QUESTION_DESCRIPTIONS, { fetchPolicy: 'network-only' });
  if (loading) return <LoadingPage />;

  const questions = _.flatMap(data.questionGroups, 'questions');
  const questionMap = _.keyBy(questions, 'code');
  const currentQuestion = current && questionMap[current];
  const currentGroup = data.questionGroups.find(group => group.questions.some(question => question.id === currentQuestion?.id));
  if (!currentQuestion) {
    return <Redirect to={makeYearlyLink(_.head(questions).code)} />;
  }

  const answered = currentQuestion.no - 1;
  const currentTitle = `STEP ${currentGroup.no} - ${currentGroup.questions.findIndex(question => question.no === currentQuestion.no) +
    1}  ${currentQuestion.name}`;
  const Question = questionComponents[_.camelCase(currentQuestion.code)] || null;

  return (
    <div>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">説明文カスタマイズ</h1>
        <p className="m-title-main-note">1画面ごとに自動保存されており入力途中から再開が可能です</p>
      </div>

      <div className="l-contents-wrap">
        <div className="l-wrap-xl">
          <div className={styles.progress}>
            <div className={styles.progressCount}>
              <span className={styles.progressTitle}>{currentTitle}</span>
              {answered}/{questions.length}
            </div>
            <Progress progress={_.round((answered / questions.length) * 100)} key="progress" large />
          </div>
          <div className={styles.wrap}>
            <div className={styles.main}>
              <QuestionDescriptionsProvider descriptions={currentQuestion.questionDescriptions}>
                <Question questionMap={questionMap} />
              </QuestionDescriptionsProvider>
            </div>
            <div className={styles.aside}>
              {data.questionGroups.map(questionGroup => (
                <QuestionGroup
                  key={questionGroup.id}
                  isCurrentGroup={true}
                  questionGroup={fillWithAnswered(questionGroup)}
                  currentQuestion={currentQuestion}
                  makeLink={makeYearlyLink}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionDescriptions
  |> lifecycle({
    componentDidUpdate({ current }) {
      if (this.props.current !== current) {
        window.scrollTo(0, 0);
      }
    }
  })
  |> withProps(({ location: { search } }) => ({ current: parse(search).current }));
