import { gql } from '@apollo/client';

export type QueryResult = {
  client: {
    id: string;
    clientYearly: {
      id: string;
      fixed: boolean;
      deleteTargetEmployees: DeleteTargetEmployee[];
    };
  };
};

type DeleteTargetEmployee = {
  id: string;
  staffCode: string;
  email: string;
  employmentType: string;
  profile: {
    id: string;
    fullName: string;
  };
};

export const DELETE_TARGET_EMPLOYEES = gql`
  query deleteTargetEmployees($allSelectMode: Boolean!, $employeeIds: [ID!]!) {
    client {
      id
      clientYearly {
        id
        fixed
        deleteTargetEmployees(allSelectMode: $allSelectMode, employeeIds: $employeeIds) {
          id
          staffCode
          email
          employmentType
          profile {
            id
            fullName
          }
        }
      }
    }
  }
`;
