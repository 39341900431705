import React from 'react'
import Icon from 'react-icon-base'

const Preview = props => (
  <Icon viewBox="0 0 18 22" {...props}>
    <g fill='none' fillRule='evenodd'>
      <g stroke='currentColor'>
        <path d='M12.781 1H2.672C1.752 1 1 1.76 1 2.688v16.624C1 20.24 1.752 21 2.672 21h12.656c.92 0 1.672-.76 1.672-1.688V5L12.781 1z' />
        <path strokeLinecap='square' d='M3.393 12h11.21M3.393 14h11.21M3.393 16h11.21' />
        <path d='M13 1v4h4' />
      </g>
      <path fill='currentColor' d='M3 6h5v4H3z' />
    </g>
  </Icon>
)
Preview.defaultProps = {
  size: 22
}

export default Preview
