import React from 'react';
import Button from 'jbc-front/components/Button';
import { Panel, Headding, Item, Buttons } from '../components/PrimaryPanel';
import { DescriptionEdit } from '../components/DescriptionEdit';
import { CustomDescription } from '../components/QuestionDescription';
import { FormPlaceholder } from '../components/FormPlaceholder';
import { ToggleDivImage } from '../components/ToggleDivImage';
import image from '../../images/img-Dependent_Exemption_Form_BC.png';
import image2 from '../../images/img-Basic_Consignment_Spouse2.png';

const Question = () => {
  return (
    <Panel>
      <Headding icon={<DescriptionEdit />}>扶養親族情報</Headding>
      <Item>
        <CustomDescription field="top" />
        <p className="u-mb20">被扶養者（16歳未満も含む）の情報を入力してください。</p>
        <ToggleDivImage
          title={'主に「扶養控除等(異動)申告書」のB欄、C欄、「住民税に関する事項」、「所得金額調整控除等申告書」の判定に関する設問です。'}
        >
          <img src={image} alt="扶養控除申告書_BC住" />
          <img src={image2} alt="R3基配所_所得金額調整控除申告書" />
        </ToggleDivImage>
        <FormPlaceholder />
        <Buttons>
          <Button primary disabled>
            次ヘ
          </Button>
        </Buttons>
      </Item>
    </Panel>
  );
};

export default Question;
