import React from 'react';
import { Redirect } from 'react-router';
import { useSession } from '../components';

export const fixedGuard = WrappedComponent => props => {
  const {
    clientYearly: { fixed, year }
  } = useSession();
  return fixed ? <WrappedComponent {...props} /> : <Redirect to={`/${year}`} />;
};

export const FixRunningGuard = ({ children }) => {
  const {
    clientYearly: { year, fixing }
  } = useSession();

  return fixing ? <Redirect to={`/${year}/result`} /> : <>{children}</>;
};

export const fixRunningGuard = WrappedComponent => props => {
  const {
    clientYearly: { year, fixing }
  } = useSession();

  return fixing ? <Redirect to={`/${year}/result`} /> : <WrappedComponent {...props} />;
};
