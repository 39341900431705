import React from 'react'
import Icon from 'react-icon-base'

const Import = props => (
  <Icon viewBox="0 0 44 44" {...props}>
    <g fill='none' fillRule='evenodd' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='3'>
      <path d='M11.464 2h-6.4A3.06 3.06 0 0 0 2 5.06V38.94A3.062 3.062 0 0 0 5.064 42h33.872A3.063 3.063 0 0 0 42 38.94V5.06A3.062 3.062 0 0 0 38.936 2h-6.399M22 2v23' />
      <path d='M13 16l9.002 9L31 16' />
    </g>
  </Icon>
)
Import.defaultProps = {
  size: 44
}

export default Import
