import React from 'react'
import Icon from 'react-icon-base'

const Custom53 = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="icon/employees/Custom53" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path d="M33.1660254,31.1341416 L37.4387985,33.60133 C37.7711257,33.7638717 38,34.1052911 38,34.500167 C38,34.8552072 37.8149748,35.1670324 37.5360882,35.344479 L37.4961524,35.3661924 L33.1660254,37.8661924 C32.6877328,38.1423348 32.0761424,37.9784596 31.8,37.500167 C31.5238576,37.0218744 31.6877328,36.4102839 32.1660254,36.1341416 L32.1660254,36.1341416 L33.264,35.5 L2,35.500167 L2,33.500167 L33.264,33.5 L32.1660254,32.8661924 C31.6877328,32.59005 31.5238576,31.9784596 31.8,31.500167 C32.0761424,31.0218744 32.6877328,30.8579992 33.1660254,31.1341416 Z" id="Combined-Shape" fill={props.color}></path>
      <path d="M18.6661924,16.6339746 L22.9389655,19.101163 C23.2712927,19.2637047 23.500167,19.6051241 23.500167,20 C23.500167,20.3550402 23.3151418,20.6668654 23.0362552,20.844312 L22.9963194,20.8660254 L18.6661924,23.3660254 C18.1878998,23.6421678 17.5763094,23.4782926 17.300167,23 C17.0240246,22.5217074 17.1878998,21.910117 17.6661924,21.6339746 L17.6661924,21.6339746 L18.764167,20.999833 L-12.499833,21 L-12.499833,19 L18.764167,18.999833 L17.6661924,18.3660254 C17.1878998,18.089883 17.0240246,17.4782926 17.300167,17 C17.5763094,16.5217074 18.1878998,16.3578322 18.6661924,16.6339746 Z" id="Combined-Shape" fill={props.color} transform="translate(5.500167, 20.000000) rotate(-90.000000) translate(-5.500167, -20.000000) "></path>
      <path d="M15,10 L15,31 L13,31 L13,12 L11,12 L11,31 L9,31 L9,10 L15,10 Z" id="Combined-Shape" fill={props.color}></path>
      <path d="M24,15 L24,31 L22,31 L22,17 L20,17 L20,31 L18,31 L18,15 L24,15 Z" id="Combined-Shape-Copy" fill={props.color}></path>
      <path d="M33,4 L33,31 L31,31 L31,7 L29,7 L29,31 L27,31 L27,4 L33,4 Z" id="Combined-Shape-Copy-2" fill={props.color}></path>
    </g>
  </Icon>
)
Custom53.defaultProps = {
  size: 30,
  color: "#3498DB"
}

export default Custom53
