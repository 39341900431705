import React from 'react'
import Icon from 'react-icon-base'

const WorkHistory = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="icon/employees/WorkHistory" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group" transform="translate(2.000000, 4.000000)" fill={props.color} fillRule="nonzero">
        <path d="M28.8761528,0 C30.0491212,0 31,0.976833273 31,2.18181818 L31,33.8181818 C31,35.0231667 30.0491212,36 28.8761528,36 L7.12384717,36 C5.95087877,36 5,35.0231667 5,33.8181818 L5,2.18181818 C5,0.976833273 5.95087877,0 7.12384717,0 L28.8761528,0 Z M28,2 L7,2 L7,34 L28,34 C28.5522847,34 29,33.5522847 29,33 L29,3 C29,2.44771525 28.5522847,2 28,2 Z" id="Rectangle" transform="translate(18.000000, 18.000000) rotate(90.000000) translate(-18.000000, -18.000000) "></path>
        <path d="M34.0577986,5.62025905 C34.4946566,5.38343066 35.049734,5.50346868 35.3464732,5.91800844 C35.6679407,6.36709318 35.5644864,6.99174918 35.1154017,7.31321666 L35.1154017,7.31321666 L18.0004317,17.4242728 C18.0001127,17.4248978 17.9999672,17.4252336 18,17.4252723 L17.9965773,17.424 C17.9951895,17.4253125 17.9950533,17.424952 17.9947191,17.4242767 L1.89650355,8.27607696 C1.44278423,7.96118457 1.33024276,7.33810166 1.64513516,6.88438234 C1.93580506,6.4655645 2.48907516,6.33745443 2.92933623,6.56789479 L3.03682978,6.63301394 L18.0105773,15.098 L33.951265,5.68693696 Z" id="Combined-Shape"></path>
        <path d="M23.9959596,0 C25.1027605,0 26,0.841161985 26,1.87878788 L26,7 L10,7 L10,1.87878788 C10,0.841161985 10.8972395,0 12.0040404,0 L23.9959596,0 Z M23.8666667,2 L12.1333333,2 L12.1333333,5 L23.8666667,5 L23.8666667,2 Z" id="Rectangle"></path>
      </g>
    </g>
  </Icon>
)

WorkHistory.defaultProps = {
  size: 30,
  color: "#3498DB"
}

export default WorkHistory
