import React from 'react'
import Icon from 'react-icon-base'

const Custom42 = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="icon/employees/Custom42" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path d="M22.6794919,18 L24.221098,20.6888439 L27.3205081,20.6794919 L27.3111561,23.778902 L30,25.3205081 L28.4421959,28 L30,30.6794919 L27.3111561,32.221098 L27.3205081,35.3205081 L24.221098,35.3111561 L22.6794919,38 L20,36.4421959 L17.3205081,38 L15.778902,35.3111561 L12.6794919,35.3205081 L12.6888439,32.221098 L10,30.6794919 L11.5578041,28 L10,25.3205081 L12.6888439,23.778902 L12.6794919,20.6794919 L15.778902,20.6888439 L17.3205081,18 L20,19.5578041 L22.6794919,18 Z M22.1435935,20 L20,21.2462433 L17.8564065,20 L16.6231216,22.1510751 L14.1435935,22.1435935 L14.1510751,24.6231216 L12,25.8564065 L13.2462433,28 L12,30.1435935 L14.1510751,31.3768784 L14.1435935,33.8564065 L16.6231216,33.8489249 L17.8564065,36 L20,34.7537567 L22.1435935,36 L23.3768784,33.8489249 L25.8564065,33.8564065 L25.8489249,31.3768784 L28,30.1435935 L26.7537567,28 L28,25.8564065 L25.8489249,24.6231216 L25.8564065,22.1435935 L23.3768784,22.1510751 L22.1435935,20 Z" id="Combined-Shape" fill={props.color}></path>
      <path d="M13.4641016,2.07179677 L21.578011,16.1238007 C21.0639934,16.042318 20.5369387,16 20,16 C18.2869812,16 16.6745636,16.430725 15.2651415,17.1897806 L8.26794919,5.07179677 L13.4641016,2.07179677 Z M13.0331545,3.72696067 L10.0001612,5.48566538 L15.9844625,15.7087421 C17.1256511,15.08613 18.3242954,14.8269471 19.5803954,14.9311936 C19.5687611,14.931676 17.3863475,11.1969317 13.0331545,3.72696067 L13.0331545,3.72696067 Z" id="Combined-Shape" fill={props.color}></path>
      <path d="M23.4641016,2.07179677 L31.578011,16.1238007 C31.0639934,16.042318 30.5369387,16 30,16 C28.2869812,16 26.6745636,16.430725 25.2651415,17.1897806 L18.2679492,5.07179677 L23.4641016,2.07179677 Z M23.0331545,3.72696067 L20.0001612,5.48566538 L25.9844625,15.7087421 C27.1256511,15.08613 28.3242954,14.8269471 29.5803954,14.9311936 C29.5687611,14.931676 27.3863475,11.1969317 23.0331545,3.72696067 L23.0331545,3.72696067 Z" id="Combined-Shape" fill={props.color} transform="translate(24.922980, 9.630789) scale(-1, 1) translate(-24.922980, -9.630789) "></path>
      <path d="M25.5,2 L22.5,7 L17.5,7 L14.5,2 L25.5,2 Z M23.5,3 L16.5,3 L18.4090909,6 L21.5909091,6 L23.5,3 Z" id="Combined-Shape" fill={props.color}></path>
      <polygon id="Star" fill={props.color} points="20 30.5 17.0610737 32.045085 17.6223587 28.7725425 15.2447174 26.454915 18.5305369 25.9774575 20 23 21.4694631 25.9774575 24.7552826 26.454915 22.3776413 28.7725425 22.9389263 32.045085"></polygon>
    </g>
  </Icon>
)
Custom42.defaultProps = {
  size: 30,
  color: "#3498DB"
}

export default Custom42
