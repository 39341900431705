import { FC, MouseEvent } from 'react';
import { Pulldown } from 'jbc-front/components/icons';
import classNames from 'classnames';

import { useAdminMutation as useMutation } from '@jbc-year-end-adj/2024/hooks/graphql/useMutation';
import { useSession } from '@jbc-year-end-adj/2024/features/AdminSessionProvider';
import { EmployeeStatus } from '@jbc-year-end-adj/2024/types';

import { SET_NOT_NEED_ADJ } from './mutation';
import styles from './SetNotNeedAdj.scss';

// @ts-ignore
import DropDownMenu from '@jbc-year-end-adj/2024/components/DropDownMenu';

export const statuses = {
  not_requested: '未依頼',
  not_need_adj: '対象外',
  in_progress: '依頼中',
  applying: '入力済',
  rejected: '再依頼中',
  fixed: '修正済',
  accepted: '完了'
};

type NotNeedAdjReason = 'resign_before_year_end' | 'has_no_payment' | 'other';

type SetNotNeedAdjProps = {
  employeeId: string;
  employeeStatus: EmployeeStatus;
  disabled?: boolean;
};

export const SetNotNeedAdj: FC<SetNotNeedAdjProps> = ({ employeeId, employeeStatus, disabled = false }) => {
  const { clientYearly } = useSession();
  const showPulldown = !clientYearly?.fixed && ['not_requested', 'not_need_adj'].includes(employeeStatus);
  const [setNotNeedAdj] = useMutation(SET_NOT_NEED_ADJ);
  const disabledClass = disabled && showPulldown ? ' u-cur-notallowed' : ' u-cur-pointer';

  const handleSetNotNeedAdj = (e: MouseEvent<HTMLUListElement>, reason?: NotNeedAdjReason) => {
    e.stopPropagation();
    setNotNeedAdj({
      variables: {
        input: {
          employeeId,
          notNeedAdj: employeeStatus !== 'not_need_adj',
          reason
        }
      }
    });
  };

  const Toggle = (setIsOpen: (func: (prev: boolean) => boolean) => void) => {
    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
      if (!disabled) setIsOpen((prev: boolean) => !prev);
      e.stopPropagation();
    };

    if (showPulldown) {
      return (
        <button className={classNames(styles.statusDiv, disabledClass)} onClick={handleClick}>
          <span className={`color-${employeeStatus}`}>{statuses[employeeStatus]}</span>
          <div className={styles.pulldown}>
            <Pulldown />
          </div>
        </button>
      );
    } else {
      return (
        <div className={classNames(styles.statusDiv, disabledClass)}>
          <span className={`color-${employeeStatus}`}>{statuses[employeeStatus]}</span>
        </div>
      );
    }
  };

  return (
    <DropDownMenu as="td" className={styles.notNeedAdjDropDown} toggle={Toggle}>
      {employeeStatus === 'not_need_adj' ? (
        <DropDownMenu.MenuItem onClick={(e: MouseEvent<HTMLUListElement>) => handleSetNotNeedAdj(e)}>未依頼に戻す</DropDownMenu.MenuItem>
      ) : (
        <>
          <DropDownMenu.MenuItem onClick={(e: MouseEvent<HTMLUListElement>) => handleSetNotNeedAdj(e, 'resign_before_year_end')}>
            対象外にする(年内退職予定)
          </DropDownMenu.MenuItem>
          <DropDownMenu.MenuItem onClick={(e: MouseEvent<HTMLUListElement>) => handleSetNotNeedAdj(e, 'has_no_payment')}>
            対象外にする(今年支払った金額がない)
          </DropDownMenu.MenuItem>
          <DropDownMenu.MenuItem onClick={(e: MouseEvent<HTMLUListElement>) => handleSetNotNeedAdj(e, 'other')}>
            対象外にする(その他の理由)
          </DropDownMenu.MenuItem>
        </>
      )}
    </DropDownMenu>
  );
};
