import React from 'react'
import Icon from 'react-icon-base'

const Custom8 = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="icon/employees/Custom8" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group" transform="translate(2.000000, 6.000000)" fill={props.color}>
        <path d="M3.52637168,4 L3.52637168,24.8333333 L3.52637168,24.8333333 L8.01763574,24.8339633 C8.12751279,24.8339907 8.23885379,24.8340193 8.35159572,24.8340491 L10.9034912,24.8348759 C11.0327574,24.8349257 11.1629833,24.834977 11.2941058,24.83503 L13.3203122,24.8359561 C13.4588253,24.836027 13.5979198,24.8360998 13.7375328,24.8361746 L15.0063454,24.836902 C15.1485017,24.8369891 15.2909873,24.8370782 15.4337392,24.8371696 L16.2929419,24.8377441 C16.4365068,24.8378444 16.580212,24.8379469 16.7239944,24.8380518 L17.5871105,24.8387093 C17.7309495,24.8388236 17.8747396,24.8389405 18.0184178,24.8390598 L18.8786397,24.8398058 C19.3075749,24.8401941 19.7343682,24.8406058 20.1573177,24.8410421 L20.9975873,24.8419482 C26.4194715,24.8480569 31,24.8584972 31,24.8763407 L31,27.1617647 L31,27.1617647 C31,28.176994 30.1835781,29 29.1764706,29 L1.82352941,29 C0.816421927,29 7.32143235e-11,28.176994 7.32143235e-11,27.1617647 L7.32143235e-11,5.83823529 C7.32143235e-11,4.82300597 0.816421927,4 1.82352941,4 L3.52637168,4 L3.52637168,4 Z M3.46777931,6 C2.5908444,6 2,7.22705569 2,8.08305207 L2,8.08305207 L2.00019069,25.6084394 C2.00423462,26.9828676 2.08763877,27 3.58783111,27 L3.58783111,27 L27.4052977,27 C29.0121584,26.9861805 29,26.9713033 29,24.9153874 L29,24.9153874 L14.3387384,24.8921008 L14.3387384,24.8921008 L3.46777931,24.8921008 Z" id="Combined-Shape-Copy-2"></path>
        <path d="M18.1053719,0 C18.9691711,0 19.7144628,0.610946256 19.8905919,1.46341822 L20.5706315,4.75712516 C20.6665416,5.22165638 21.0755964,5.55489984 21.5499254,5.55492358 L34.1764706,5.55555556 C35.1835781,5.55555556 36,6.37856153 36,7.39379085 L36,23.1617647 C36,24.176994 35.1835781,25 34.1764706,25 L6.82352941,25 C5.81642193,25 5,24.176994 5,23.1617647 L5,1.83823529 C5,0.823005975 5.81642193,0 6.82352941,0 L18.1053719,0 Z M17.0512605,2.0005651 L8.58823529,2 C7.71107716,2 7,3.30107022 7,4.15386285 L7,4.15386285 L7,21.4558824 C7,22.308675 7.71107716,23 8.58823529,23 L8.58823529,23 L32.4117647,23 C33.2889228,23 34,22.308675 34,21.4558824 L34,21.4558824 L34,8.82052951 C34,7.96773689 33.2889228,7.27641187 32.4117647,7.27641187 L32.4117647,7.27641187 L20.1764539,7.27641187 C19.6931685,7.27641187 19.2790381,6.93078911 19.1925844,6.45529938 L19.1925844,6.45529938 L18.6061295,3.22983641 C18.4527268,2.51375996 17.8036017,2.0005651 17.0512605,2.0005651 L17.0512605,2.0005651 Z" id="Combined-Shape"></path>
      </g>
    </g>
  </Icon>
)
Custom8.defaultProps = {
  size: 30,
  color: "#3498DB"
}

export default Custom8