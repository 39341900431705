import { FC, ReactNode } from 'react';
import { Section } from '@jbc-year-end-adj/2024/components/ui/Section';
import { Separator } from '@jbc-year-end-adj/2024/components/ui/Separator';

import { Question } from '../types';

import { Lead } from './parts/Lead';
import { Description } from './parts/Description';
import { Content } from './parts/Content';

type FormerJobsProps = {
  children: ReactNode;
};

const MainSection: FC<FormerJobsProps> = ({ children }) => {
  return (
    <Section>
      <Section.Header>{TITLE}</Section.Header>

      <Section.Body>
        <Content>{children}</Content>
      </Section.Body>
    </Section>
  );
};

export const TITLE = '前職情報';

type CheckFormerJobProps = {
  question: Question;
  children: ReactNode;
};

export const CheckFormerJob: FC<CheckFormerJobProps> = ({ question, children }) => {
  const description = question.questionDescriptions.find(description => description.field == 'top')?.description;
  return (
    <>
      <Lead>今年給料をもらった前職は有りますか？</Lead>

      <Description>{description}</Description>

      {children}
    </>
  );
};

type DetailInputProps = {
  children: ReactNode;
};

export const DetailInput: FC<DetailInputProps> = ({ children }) => {
  return (
    <>
      <Separator />
      <Lead>以下の情報の記入が必要です。</Lead>
      {children}
    </>
  );
};

export const FormerJobs = Object.assign(MainSection, { CheckFormerJob, DetailInput });
