import React from 'react'
import Icon from 'react-icon-base'

const CustomCheckbox = props => (
  <Icon viewBox="0 0 16 15" {...props}>
    <g fill='none' fillRule='evenodd'>
      <path fill='#3498DB' d='M.5 15h15V0H.5v15zm2-2h11V2h-11v11z' />
      <path stroke='#3498DB' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2.5' d='M4.696 7.443L6.37 10.12l4.184-5.19' />
    </g>
  </Icon>
)
CustomCheckbox.defaultProps = {
  size: 16
}

export default CustomCheckbox
