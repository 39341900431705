import React from 'react';
export class Hoc extends React.Component {
  constructor(props) {
    super(props);
    this.hoc = props.makeHoc(props);
  }
  render() {
    const { makeHoc, children, ...rest } = this.props;
    const Child = this.hoc(props => children(props));
    return <Child {...rest} />;
  }
}

export default Hoc;
