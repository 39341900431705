import React from 'react'
import Icon from 'react-icon-base'

const Download = props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <g fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="1.307">
      <path d="M8 4.396v7.208M5.217 8.822l2.784 2.783 2.782-2.783"/>
      <path d="M14.964 8A6.964 6.964 0 1 1 1.036 8a6.964 6.964 0 0 1 13.928 0z"/>
    </g>
  </Icon>
)
Download.defaultProps = {
  size: 16
}

export default Download
