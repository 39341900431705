import React from 'react'
import Icon from 'react-icon-base'

const Revert = props => (
  <Icon viewBox="0 0 13 14" {...props}>
    <path fill='currentColor' stroke='currentColor' strokeWidth='.5' d='M3.212 1c.13.003.253.064.333.165.08.1.108.232.078.356l-.158 1.715c.873-.577 1.927-.962 3.035-.962 3.032 0 5.5 2.406 5.5 5.363C12 10.594 9.532 13 6.5 13S1 10.594 1 7.637c-.003-.223.205-.428.434-.428.23 0 .438.205.434.428 0 2.5 2.069 4.516 4.632 4.516 2.563 0 4.632-2.017 4.632-4.516S9.063 3.121 6.5 3.121c-.958 0-1.92.376-2.7.926l1.022.07c.163.001 1.329.09 1.403.23a.415.415 0 0 1-.033.44.44.44 0 0 1-.416.173l-3.044-.14a.437.437 0 0 1-.316-.17.415.415 0 0 1-.077-.342l.434-2.968A.432.432 0 0 1 3.212 1z' />
  </Icon>
)
Revert.defaultProps = {
  size: 14
}

export default Revert
