import styles from './Footer.scss';

export const Footer = () => {
  return (
    <div className={styles.base}>
      <div className={styles.nav}>
        <a className={styles.menu} target="_blank" rel="noopener noreferrer" href="https://lms.jobcan.ne.jp">
          ジョブカン労務HRについて
        </a>
        <a className={styles.menu} target="_blank" rel="noopener noreferrer" href="https://jobcan-lms.zendesk.com/hc/ja">
          ヘルプ
        </a>
        <a className={styles.menu} target="_blank" rel="noopener noreferrer" href="https://lms.jobcan.ne.jp/terms">
          利用規約
        </a>
        <a className={styles.menu} target="_blank" rel="noopener noreferrer" href="https://jobcan.ne.jp/privacypolicy">
          プライバシーポリシー
        </a>
        <a className={styles.menu} target="_blank" rel="noopener noreferrer" href="https://lms.jobcan.ne.jp/privacy">
          情報セキュリティ基本方針
        </a>
      </div>
      <div className={styles.copyright}>&copy; 2017 DONUTS Co. Ltd.</div>
    </div>
  );
};
