import { FC } from 'react';
import { Form } from './Form';
import { Preview } from './Preview';
import { ToggleSection, useToggleSection, ViewType } from '../../../components/ToggleSection';
import { HousingLoan as HousingLoanType } from '../../../query';
import { Tag } from '../../../components/Tag';
import { useResult } from '../ResultProvider';
import styles from './HousingLoan.scss';

type HousingLoanProps = {
  setMode: (viewType: ViewType) => void;
};

export const HousingLoan: FC<HousingLoanProps> = ({ setMode }) => {
  const { viewType, setViewType } = useToggleSection();
  const { employee } = useResult();

  const housingLoan = employee.profile.housingLoan;
  const requestHousingLoan = employee.request?.profile?.housingLoan;

  const handleSetViewType = (viewType: ViewType) => {
    setMode(viewType);
    setViewType(viewType);
  };

  const handleClose = () => {
    handleSetViewType('preview');
    window.location.hash = '';
    window.location.hash = '#request-housing-loan';
  };

  return (
    <ToggleSection viewType={viewType} setViewType={handleSetViewType}>
      <ToggleSection.Header>
        <div className={styles.header}>
          住宅ローン
          <AddOrDeleteTag housingLoan={housingLoan} requestHousingLoan={requestHousingLoan} />
        </div>
      </ToggleSection.Header>
      <ToggleSection.Body>
        {viewType === 'preview' && <Preview />}
        {viewType === 'form' && <Form onCancel={handleClose} />}
      </ToggleSection.Body>
    </ToggleSection>
  );
};

type AddOrDeleteTagProps = {
  housingLoan?: HousingLoanType;
  requestHousingLoan?: HousingLoanType;
};

const AddOrDeleteTag: FC<AddOrDeleteTagProps> = ({ housingLoan, requestHousingLoan }) => {
  if (!!housingLoan && !requestHousingLoan) {
    return <Tag color="red">削除</Tag>;
  }

  if (!housingLoan && !!requestHousingLoan) {
    return <Tag color="blue">追加</Tag>;
  }

  return null;
};
