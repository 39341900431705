import React from 'react';
import { Section } from 'jbc-front/components/Form';
import { LabelMapper } from 'jbc-front/components/FormErrors';
import { FileField, FormSection } from '../components/FieldWithDiff';
import { gql } from '@apollo/client';
import { isPlainObject } from 'lodash/fp';

export const ATTACHMENTS_FRAGMENT = gql`
  fragment Attachments on Profile {
    attachments {
      id
      compareKey
      file {
        url
        filename
      }
    }
  }
`;

const Attachments = () => (
  <Section title="添付ファイル">
    <FormSection name="attachments">
      <LabelMapper name="attachments" label="添付ファイルの" />
      <FileField name="file" label="添付ファイル" multiple />
    </FormSection>
  </Section>
);

export const makeInitialValues = profile => ({
  file: profile.attachments.map(attachment => {
    const { file, ...rest } = attachment;
    return { ...file, ...rest };
  })
});

const formatFile = file => {
  if (isPlainObject(file)) {
    const { id, compareKey, ...rest } = file;
    return { id, file: rest };
  } else {
    return { file };
  }
};

export const formatValues = values =>
  values.attachments?.file ? { ...values, attachments: values.attachments.file.map(formatFile) } : values;

export default Attachments;
