import { FC } from 'react';
import Button from 'jbc-front/components/Button';

import { useNotify } from '@jbc-year-end-adj/2024/hooks/useNotify';
import { useAdminMutation as useMutation } from '@jbc-year-end-adj/2024/hooks/graphql/useMutation';
import { TaskRunnningProps } from '@jbc-year-end-adj/2024/features/AsyncTask/AsyncTaskProvider';
import { useSession } from '@jbc-year-end-adj/2024/features/AdminSessionProvider';
import { FloatingButton } from '@jbc-year-end-adj/2024/components/ui/FloatingButton';

import { REQUEST, REQUEST_ASYNC } from './mutation';
import styles from '../../../Top.scss';

// @ts-ignore
import { convertSelectedIds } from '../../SelectList/useSelectEmployee';

const MAX_REQUEST_COUNT = 100;

type RequestModeButtonProps = {
  selectedTotalCount: number;
  allSelectMode: boolean;
  selected: { [key: string]: { name: string; status: unknown } };
  taskRunningProps: TaskRunnningProps;
  isDisabledSubmit: () => boolean;
  reset: () => void;
};

export const RequestModeButton: FC<RequestModeButtonProps> = ({
  selectedTotalCount,
  allSelectMode,
  selected,
  taskRunningProps,
  isDisabledSubmit,
  reset
}) => {
  const { isCurrentYear } = useSession();
  const [request, { loading: loadingRequest }] = useMutation(REQUEST);
  const [requestAsync, { loading: loadingRequestAsync }] = useMutation(REQUEST_ASYNC);
  const notify = useNotify();
  const handleSubmitRequest = async () => {
    const isExcessive = selectedTotalCount > MAX_REQUEST_COUNT;
    const requestMutation = isExcessive ? requestAsync : request;
    const message = isExcessive ? '一括依頼を開始しました' : '依頼しました';
    const { employeeIds, exceptEmployeeIds } = convertSelectedIds(allSelectMode, Object.keys(selected));

    await requestMutation({
      variables: {
        input: {
          allSelectMode,
          employeeIds,
          exceptEmployeeIds
        }
      }
    });
    notify(message, 'success');
    reset();
  };

  return (
    <FloatingButton className={!(!isCurrentYear || isDisabledSubmit()) ? styles.transitionOn : styles.transitionOff}>
      {!isCurrentYear && <p className={styles.errorMessage}>※過去の年末調整の依頼を送ることはできません</p>}
      <Button
        onClick={handleSubmitRequest}
        disabled={!isCurrentYear || isDisabledSubmit() || loadingRequest || loadingRequestAsync || taskRunningProps.disabled}
        disabledReason={taskRunningProps.disabledReason}
        primary
      >
        年末調整を依頼する
      </Button>
    </FloatingButton>
  );
};
