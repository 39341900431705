import React from 'react'
import Icon from 'react-icon-base'

const Custom27 = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="Custom27" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon/employees/Custom7-Copy-4" fill="#3498DB" fillRule="nonzero">
        <path d="M17.8334097,4.75477144 L13.252,12.712 L4.26832624,14.6103814 C3.83693443,14.7015254 3.43732074,14.9051388 3.11001797,15.200569 L2.9816891,15.3248572 C2.07994564,16.2621133 2.04439231,17.7511053 2.92929977,18.7314812 L9.082,25.547 L8.11070057,34.6781931 C8.06407624,35.1166362 8.13423662,35.5596113 8.31406534,35.9621877 L8.38914177,36.1161175 C9.00002093,37.2680053 10.4081893,37.7648372 11.6163174,37.2251728 L20,33.479 L28.3836826,37.2251728 C28.786259,37.4050015 29.2292342,37.4751619 29.6676772,37.4285376 L29.830569,37.4058679 C31.1183952,37.1837987 32.0294619,35.996245 31.8892994,34.6781931 L30.917,25.547 L37.0707002,18.7314812 C37.3661305,18.4041784 37.5697438,18.0045647 37.6608878,17.5731729 L37.689664,17.4112483 C37.8764238,16.1178296 37.0285282,14.8843794 35.7316738,14.6103814 L26.747,12.712 L22.1665903,4.75477144 C21.9465999,4.37265849 21.6294647,4.05552327 21.2473517,3.83553279 C20.0507769,3.14663947 18.522303,3.55819663 17.8334097,4.75477144 Z M20.2494703,5.56880507 C20.3258929,5.61280317 20.38932,5.67623021 20.4333181,5.7526528 L25.460384,14.4844263 L35.3182428,16.5671836 C35.5884208,16.6242665 35.7611685,16.8895639 35.7040856,17.1597419 C35.6858568,17.2460203 35.6451341,17.325943 35.5860481,17.3914036 L28.8350868,24.8706937 L29.9005128,34.889682 C29.9297133,35.1642762 29.7307825,35.4105504 29.4561883,35.4397509 C29.3684997,35.4490758 29.2799047,35.4350437 29.1993894,35.399078 L20.2039267,31.380853 C20.0741549,31.3228846 19.9258451,31.3228846 19.7960733,31.380853 L10.8006106,35.399078 C10.5484795,35.5117036 10.2527858,35.398612 10.1401602,35.1464809 C10.1041945,35.0659657 10.0901624,34.9773706 10.0994872,34.889682 L11.1649132,24.8706937 L4.41395193,17.3914036 C4.22892582,17.1864159 4.24510788,16.8702472 4.45009557,16.6852211 C4.51555612,16.6261351 4.59547886,16.5854124 4.68175722,16.5671836 L14.539616,14.4844263 L19.5666819,5.7526528 C19.7044606,5.51333784 20.0101554,5.43102641 20.2494703,5.56880507 Z" id="Star"></path>
      </g>
    </g>
  </Icon>
)
Custom27.defaultProps = {
  size: 30
}

export default Custom27