import { FC } from 'react';
import { Modal } from 'jbc-front/components/presenters/ui/Modal';
import Button from 'jbc-front/components/Button';
import { Spacer } from 'jbc-front/components/presenters/Spacer';
import { MOVE_NOT_REQUESTED } from './mutation';
import { Employee, FETCH_EMPLOYEE } from '../query';

// @ts-ignore
import { useNotify } from 'actions';
// @ts-ignore
import { useMutation } from 'components/Graphql';

type MoveNotRequestedProps = {
  employee: Employee;
  isOpen: boolean;
  onClose: () => void;
};

export const MoveNotRequestedModal: FC<MoveNotRequestedProps> = ({ employee, isOpen, onClose }) => {
  const [moveNotRequested, { loading }] = useMutation(MOVE_NOT_REQUESTED);
  const notify = useNotify();

  const handleSubmit = async () => {
    try {
      const variables = { input: { employeeId: employee.id } };
      await moveNotRequested({ variables, refetchQueries: [FETCH_EMPLOYEE] });
      notify('対象に戻しました', 'success');
      onClose();
    } catch {
      notify('対象に戻せませんでした', 'error');
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header onClose={onClose}>対象に戻す</Modal.Header>
      <Modal.Body>年末調整対象に戻しますか？ステータスは未依頼に変更されます。</Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} disabled={loading}>
          キャンセル
        </Button>
        <Spacer direction="x" size={20} />
        <Button primary onClick={handleSubmit} disabled={loading}>
          対象に戻す
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
