import React from 'react'
import Icon from 'react-icon-base'

const Plus = props => (
  <Icon viewBox="0 0 11 11" {...props}>
    <g fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="2.5">
      <path d="M0 5.177h10.354M5.177 10.354V0"/>
    </g>
  </Icon>
)
Plus.defaultProps = {
  size: 11
}

export default Plus
