import React from 'react';
import Button from 'jbc-front/components/Button';
import { Panel, Headding, Item, Lead, Buttons } from '../components/PrimaryPanel';
import { DescriptionEdit } from '../components/DescriptionEdit';
import { CustomDescription } from '../components/QuestionDescription';
import { FormPlaceholder } from '../components/FormPlaceholder';
import { ToggleDivImage } from '../components/ToggleDivImage';
import image from '../../images/img-Foundation_Exclusion_Form.png';

const Question = () => {
  return (
    <Panel>
      <Headding icon={<DescriptionEdit />}>給与収入等（本人の収入合計金額）</Headding>
      <Item>
        <Lead>各項目の収入の合計金額を入力してください。</Lead>
        <CustomDescription field="top" />
        <ToggleDivImage title={'「基礎控除申告書（赤枠）」に反映されます。'}>
          <img src={image} alt="基配所_基礎控除申告書" />
        </ToggleDivImage>
        <FormPlaceholder />
        <Buttons>
          <Button primary disabled>
            次ヘ
          </Button>
        </Buttons>
      </Item>
    </Panel>
  );
};

export default Question;
