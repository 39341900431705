import React from 'react'
import Icon from 'react-icon-base'

const Edditing = props => (
  <Icon viewBox="0 0 19 21" {...props}>
    <g fill='none' fillRule='evenodd' stroke='currentColor'>
      <path fill='#FFF' d='M12.638 1.56a.78.78 0 0 0-.586-.125.768.768 0 0 0-.503.318L2.737 14.338l.68 3.85 3.854-.679 8.81-12.58a.778.778 0 0 0-.193-1.089l-3.25-2.28z' />
      <path strokeLinecap='square' d='M3.5 19.5h9M15.5 19.5h2' />
      <path d='M4.914 16.953l1.076.736.216-.909-1.292.173zm-1.082.145l-.568.077.566.497.002-.574zm.004-.882l.003-.943-.782.411.779.532zm0 0l1.078.737-1.082.145.004-.882z' />
    </g>
  </Icon>
)
Edditing.defaultProps = {
  size: 21
}

export default Edditing
