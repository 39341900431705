import React from 'react'
import Icon from 'react-icon-base'

const JobcanIdLogo = props => (
  <Icon viewBox="0 0 184 35" {...props}>
    <g id='Symbols' fill='none' fillRule='evenodd'>
      <g id='logo' fill='currentColor'>
        <path d='M27.4705786,6.62666667 L31.4607577,6.62666667 L31.4607577,1.42550388 L27.4705786,1.42550388 L27.4705786,6.62666667 Z M22.3677533,6.62666667 L26.3192952,6.62666667 L26.3192952,1.42550388 L22.3677533,1.42550388 L22.3677533,6.62666667 Z M0.767342144,27.8931008 C16.1522819,27.5460853 23.4803994,22.691938 24.746514,7.85926357 L31.8441586,7.85926357 C30.5780441,27.7389922 18.7612452,34.4039147 0.767342144,34.4812403 L0.767342144,27.8931008 Z M17.3416623,10.1323643 C12.2004699,9.55445736 5.02609104,8.93802326 0.422038179,8.78391473 L0.422038179,2.50372093 C5.29466079,2.69662791 12.0086344,3.15868217 17.3416623,3.77565891 L17.3416623,10.1323643 Z M16.9196241,20.7658527 C11.5482291,20.1108915 4.79561821,19.5715116 0,19.4171318 L0,13.1757364 C5.06445815,13.3301163 11.9321703,13.8306977 16.9196241,14.4471318 L16.9196241,20.7658527 Z' id='logo-1' />
        <polygon id='logo-2' points='40.1703072 34.3269419 40.1703072 28.3940349 59.6221604 28.3940349 59.6221604 23.3084535 40.6307125 23.3084535 40.6307125 17.413531 59.6221604 17.413531 59.6221604 13.1370194 40.2086743 13.1370194 40.2086743 7.16558527 66.298037 7.16558527 66.298037 34.3657403' />
        <path d='M105.969572,5.16239147 C105.470799,24.9647946 93.7688317,33.5178178 79.2663354,34.6736318 L79.2663354,28.4707636 C90.3927965,26.7755698 97.2221416,20.1489031 98.4112517,9.40010465 L75.2761562,9.40010465 L75.2761562,3.00460078 L100.214506,3.00460078 L100.214506,2.71317829e-05 L104.204685,2.71317829e-05 L104.204685,3.00460078 L105.278964,3.00460078 L105.278964,2.71317829e-05 L109.230776,2.71317829e-05 L109.230776,5.16239147 L105.969572,5.16239147 Z' id='logo-3' />
        <path d='M128.909427,34.4811589 L128.909427,28.2012364 L134.280822,28.2012364 C136.774684,28.2012364 137.695495,27.2383295 137.695495,24.8105775 L137.695495,13.4456163 L129.216364,13.4456163 C128.333921,25.4655388 121.31274,32.4774767 113.409386,34.8276318 L113.409386,28.1241822 C118.282009,25.4275543 122.080352,20.6496473 122.387019,13.4456163 L113.409386,13.4456163 L113.409386,7.20422093 L122.425386,7.20422093 L122.425386,1.6951124 L129.331465,1.6951124 L129.331465,7.20422093 L144.79341,7.20422093 L144.79341,25.2731744 C144.79341,31.784531 141.992341,34.4811589 135.891971,34.4811589 L128.909427,34.4811589 Z' id='logo-4' />
        <path d='M153.579639,28.8158295 C167.276426,27.8529225 175.563722,24.0772636 176.676368,9.32110078 L183.889384,9.32110078 C182.776738,27.8529225 172.26415,35.0572248 153.579639,35.4424961 L153.579639,28.8158295 Z M169.463352,12.7117597 C165.051404,11.8256357 157.646553,10.8627287 153.004133,10.6312946 L153.004133,3.92784496 C158.030224,4.19753488 164.590729,5.00660465 169.463352,5.85393023 L169.463352,12.7117597 Z' id='logo-5' />
      </g>
    </g>
  </Icon>
)
JobcanIdLogo.JobcanIdLogo = {
  size: 184
}

export default JobcanIdLogo
