import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { FormSection } from 'components/form/FormSection';
import { Input } from 'components/form/Input';
import { DateField } from 'components/react-hook-form/DateField';
import { Radio } from 'components/form/Radio';
import { Option } from 'components/form/SelectField';
import { CreatableSelectField } from 'components/react-hook-form/CreatableSelectField';

import { FormSeparator } from '../../../../components/FormSeparator';
import { Grid } from '../../../../components/Grid';
import { RELATION_OTHERS } from '../../../../consts';
import { ThisYear } from './ThisYear';
import { NextYear } from './NextYear';
import { Schema } from './schema';
import { useEmployeeInfo } from '../../EmployeeInfoProvider';
import { useYear } from 'hooks/useYear';

type OtherFamilyType = {
  index: number;
};

export const OtherFamily: FC<OtherFamilyType> = ({ index }) => {
  const year = useYear();
  const {
    formState: { errors },
    register,
    watch
  } = useFormContext<Schema>();

  const { employee } = useEmployeeInfo();

  const thisYearDependentTaxLaw = watch(`otherFamilies.${index}.thisYear.dependentTaxLaw`);
  const nextYearDependentTaxLaw = watch(`otherFamilies.${index}.nextYear.dependentTaxLaw`);

  const relationshipOptions: Option[] = RELATION_OTHERS.map(val => ({ value: val, label: val }));
  const relationOther = employee.profile.otherFamilies[index]?.relationOther;
  if (relationOther && !RELATION_OTHERS.includes(relationOther)) {
    relationshipOptions.push({ label: relationOther, value: relationOther });
  }

  return (
    <FormSection>
      <Grid>
        <CreatableSelectField
          label="続柄詳細"
          required
          note="リストにない続柄は直接入力して追加できます"
          options={relationshipOptions}
          error={errors.otherFamilies && errors.otherFamilies[index]?.relationOther?.message}
          name={`otherFamilies.${index}.relationOther`}
        />
      </Grid>
      <Grid colGap>
        <Input
          label="姓"
          required
          error={errors.otherFamilies && errors.otherFamilies[index]?.lastName?.message}
          isError={errors.otherFamilies && !!errors.otherFamilies[index]?.lastName?.message}
          {...register(`otherFamilies.${index}.lastName`)}
        />
        <Input
          label="名"
          required
          error={errors.otherFamilies && errors.otherFamilies[index]?.firstName?.message}
          isError={errors.otherFamilies && !!errors.otherFamilies[index]?.firstName?.message}
          {...register(`otherFamilies.${index}.firstName`)}
        />
      </Grid>
      <Grid colGap>
        <Input
          label="姓（カナ）"
          required
          error={errors.otherFamilies && errors.otherFamilies[index]?.lastNameKana?.message}
          isError={errors.otherFamilies && !!errors.otherFamilies[index]?.lastNameKana?.message}
          {...register(`otherFamilies.${index}.lastNameKana`)}
        />
        <Input
          label="名（カナ）"
          required
          error={errors.otherFamilies && errors.otherFamilies[index]?.firstNameKana?.message}
          isError={errors.otherFamilies && !!errors.otherFamilies[index]?.firstNameKana?.message}
          {...register(`otherFamilies.${index}.firstNameKana`)}
        />
      </Grid>

      <FormSeparator />

      <Grid colGap>
        <DateField
          label="生年月日"
          required
          note="例）1981/01/23、S56.1.23"
          name={`otherFamilies.${index}.birthday`}
          error={errors.otherFamilies && errors.otherFamilies[index]?.birthday?.message}
        />

        <Radio.Container label="性別" required error={errors.otherFamilies && errors.otherFamilies[index]?.gender?.message}>
          <Radio value="male" {...register(`otherFamilies.${index}.gender`)}>
            男性
          </Radio>
          <Radio value="female" {...register(`otherFamilies.${index}.gender`)}>
            女性
          </Radio>
        </Radio.Container>
      </Grid>

      <ThisYear index={index} />
      <NextYear index={index} />

      {(thisYearDependentTaxLaw === 'true' || nextYearDependentTaxLaw === 'true') && (
        <Grid colGap>
          <DateField
            label="税法上の扶養家族になった日"
            name={`otherFamilies.${index}.dependentFrom`}
            error={errors.otherFamilies && errors.otherFamilies[index]?.dependentFrom?.message}
          />

          <Input label="税法上の扶養に追加された理由" {...register(`otherFamilies.${index}.dependentReason`)} />

          <DateField
            label="死亡日"
            note={`扶養親族が今年（${year}年）亡くなられた場合、死亡日をご記入ください。`}
            error={errors.otherFamilies && errors.otherFamilies[index]?.diedOn?.message}
            name={`otherFamilies.${index}.diedOn`}
          />
        </Grid>
      )}
    </FormSection>
  );
};
