import { yup } from '@jbc-year-end-adj/common/yup';
import { FileType } from '@jbc-year-end-adj/types';
import { Attachment } from '../../../../query';

type AttachmentSchema = {
  id?: string;
  file?: FileType | File;
};

export type Schema = {
  attachments: AttachmentSchema[];
};

const attachmentSchema = yup.object({
  id: yup.string(),
  file: yup
    .mixed<File>()
    .required()
    .label('添付ファイル')
});

export const schema = yup.object({
  attachments: yup.array().of(attachmentSchema)
});

export const generateDefaultValues = (attachments: Attachment[] | undefined) => {
  if (!attachments || attachments.length === 0) {
    return { attachments: [] };
  }

  return { attachments: attachments.map(attachment => generateAttachment(attachment)) };
};

export const generateAttachment = (attachment?: Attachment) => {
  const generateFileTypeObj = (image: FileType | undefined) => {
    if (!image) return undefined;
    if (!image.url && !image.filename && !image.file) return undefined;

    return image;
  };

  return {
    id: attachment?.id ? String(attachment.id) : undefined,
    file: generateFileTypeObj(attachment?.file)
  };
};
