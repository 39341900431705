import React from 'react'
import Icon from 'react-icon-base'

const SalaryDetail = props => (
  <Icon viewBox="0 0 24 18" {...props}>
    <g fill='none' fillRule='evenodd'>
      <path fill='currentColor' d='M16.333 8.583a2.753 2.753 0 0 1-2.75 2.75 2.753 2.753 0 0 1-2.75-2.75 2.753 2.753 0 0 1 2.75-2.75 2.753 2.753 0 0 1 2.75 2.75zm3.422 4.976l-2.267-2.268A4.652 4.652 0 0 0 13.654 4 4.652 4.652 0 0 0 9 8.654a4.652 4.652 0 0 0 7.291 3.834l2.268 2.26a.83.83 0 0 0 .595.252.852.852 0 0 0 .846-.846.851.851 0 0 0-.245-.595z' />
      <path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.5' d='M16 4.818V1.69c0-.381-.595-.69-1.328-.69H2.328c-.183 0-.358.02-.517.054-.476.105-.811.35-.811.636v8.62c0 .381.595.69 1.328.69h4.238' />
      <path fill='currentColor' d='M3 5.25v-1.5c0-.414.299-.75.667-.75h2.666c.368 0 .667.336.667.75v1.5c0 .414-.299.75-.667.75H3.667C3.299 6 3 5.664 3 5.25z' />
    </g>
  </Icon>
)
SalaryDetail.defaultProps = {
  size: 24
}

export default SalaryDetail
