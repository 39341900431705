import React from 'react'
import Icon from 'react-icon-base'

const Map = props => (
  <Icon viewBox="0 0 15 14" {...props}>
    <defs>
      <path id='a' d='M5.231 3.834V.297H.591v3.537h4.64z' />
      <path id='c' d='M3.13 4H0V.394h3.13z' />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <path fill='currentColor' d='M8.138 10.999L5.417 11c-.292 0-.548-.079-.698-.35a1.122 1.122 0 0 1-.107-.367c-.018-.119-.003-.241-.004-.36a.745.745 0 0 0-.723-.753c-.103-.005-.21 0-.311-.021A.721.721 0 0 1 3 8.436c-.002-.15 0-.3 0-.451 0-.524.29-1 .814-1.001 1.083-.003 2.165-.001 3.245-.001h1.138c.453-.003.785-.37.88-.818.129-.602 0 0 .132-.6.07-.324.326-.552.656-.556a90.01 90.01 0 0 1 2.433-.001c.479.009.781.416.682.89-.116.559-.056.246-.177.804l-.794 3.642c-.095.44-.686.653-1.135.655-.912.002-1.825 0-2.737 0'
      />
      <g transform='translate(9)'>
        <mask id='b' fill='#fff'>
          <use xlinkHref='#a' />
        </mask>
        <path fill='currentColor' d='M2.698 3.83c-.463 0-.927-.016-1.39.003-.468.02-.802-.395-.697-.87.152-.688.272-1.383.402-2.075.066-.35.328-.585.69-.587a205.94 205.94 0 0 1 2.834.001c.478.004.769.385.678.855-.138.71-.272 1.42-.407 2.13a.645.645 0 0 1-.625.538c-.495.007-.99.002-1.485.002v.002z'
          mask='url(#b)' />
      </g>
      <g transform='translate(0 9)'>
        <mask id='d' fill='#fff'>
          <use xlinkHref='#c' />
        </mask>
        <path fill='currentColor' d='M3.13 2.405v.892c0 .4-.26.681-.659.694-.339.012-.234.011-.572.001-.404-.013-.667-.297-.667-.703 0-.27.003-.295 0-.566-.004-.169-.52-.078-.689-.079-.336 0-.494-.184-.52-.503A9.325 9.325 0 0 1 .015.947C.03.658.32.402.612.4a81.157 81.157 0 0 1 1.911 0c.332.005.603.302.605.645.003.454.001.907.001 1.361'
          mask='url(#d)' />
      </g>
      <path fill='currentColor' d='M5.498 12.003c.268 0 .535-.007.801.001.4.014.698.363.7.808v.371c0 .45-.311.807-.714.812-.524.007-1.048.007-1.572 0-.4-.003-.707-.352-.711-.793-.003-.135-.003-.27 0-.406.004-.438.3-.78.694-.792.267-.008.535-.001.802-.001'
      />
    </g>
  </Icon>
)
Map.defaultProps = {
  size: 15
}

export default Map
