import React from 'react';
import Loading from './Loading';
import styles from './LoadingPage.scss';

export const LoadingPage = () => (
  <div className={styles.loading}>
    <Loading />
  </div>
);

export default LoadingPage;
