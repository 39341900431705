import {
  OperationVariables,
  DocumentNode,
  isApolloError,
  TypedDocumentNode,
  MutationHookOptions,
  MutationTuple,
  MutationFunctionOptions,
  ServerError,
  useMutation as useMutationOrigin
} from '@apollo/client';

import { useNotify } from '../useNotify';
import { logError } from '@jbc-year-end-adj/common/telemetry';
import { ADMIN_ENDPOINT, EMPLOYEE_ENDPOINT } from './consts';

interface ErrorHandlerOptions<TData, TVariables> {
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>;
  options: MutationHookOptions<TData, TVariables> | undefined;
}

class BrokenMultipartRequestError extends Error {
  constructor(e?: string) {
    super(e);
    this.name = 'BrokenMultipartRequestError';
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useAdminMutation = <TData = any, TVariables = OperationVariables>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables>
): MutationTuple<TData, TVariables> => {
  return useMutation(ADMIN_ENDPOINT, mutation, options);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useEmployeeMutation = <TData = any, TVariables = OperationVariables>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables>
): MutationTuple<TData, TVariables> => {
  return useMutation(EMPLOYEE_ENDPOINT, mutation, options);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useMutation = <TData = any, TVariables = OperationVariables>(
  uri: string,
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables>
): MutationTuple<TData, TVariables> => {
  const [mutateOrigin, value] = useMutationOrigin(mutation, options);
  const notify = useNotify();
  const mutate = (options?: MutationFunctionOptions<TData, TVariables>) => {
    const errorHandlerOptions = {
      mutation,
      options
    };

    return mutateOrigin({
      ...options,
      context: {
        ...options?.context,
        uri
      }
    })
      .catch(unwrapBrokenMultipartRequest(errorHandlerOptions))
      .catch(sendTelemetry(errorHandlerOptions))
      .catch((error: Error) => {
        notify(fetchErrorMessage(error), 'error');
        throw error;
      });
  };

  return [mutate, value];
};

const unwrapBrokenMultipartRequest = <TData, TVariables>(_options: ErrorHandlerOptions<TData, TVariables>) => {
  return (error: Error) => {
    if (isApolloError(error)) {
      const err = error.graphQLErrors[0];
      if (err && err.extensions?.code === 'BROKEN_MULTIPART_REQUEST') {
        throw new BrokenMultipartRequestError(err.message);
      }
    }
    throw error;
  };
};

const sendTelemetry = <TData, TVariables>(options: ErrorHandlerOptions<TData, TVariables>) => {
  return (error: Error) => {
    // ログする必要のないエラーは無視
    if (error instanceof BrokenMultipartRequestError) {
      throw error;
    }

    const query = options.mutation.loc?.source?.body;
    logError(error, { query });
    throw error;
  };
};

const fetchErrorMessage = (error: Error) => {
  if (isApolloError(error)) {
    const graphQLErrorMessage = error.graphQLErrors[0]?.message;
    if (graphQLErrorMessage) {
      return graphQLErrorMessage;
    }

    if (error.networkError && isServerError(error.networkError)) {
      const networkErrorMessage = error.networkError.result['error']?.message;
      if (networkErrorMessage) {
        return networkErrorMessage;
      }
    }
  }

  return error.message;
};

const isServerError = (error: Error): error is ServerError => error.name === 'ServerError';
