import { FC, ReactNode } from 'react';
import { Pulldown } from 'jbc-front/components/icons';
import classnames from 'classnames';
import styles from './SettingItem.scss';

// @ts-ignore
import { Link } from 'react-router-dom';

type ContainerProps = {
  children: ReactNode;
};

const Container: FC<ContainerProps> = ({ children }) => {
  return <ul className={classnames(styles.container)}>{children}</ul>;
};

type ItemProps = {
  to: string;
  children: ReactNode;
};

const Item: FC<ItemProps> = ({ to, children }) => {
  return (
    <li className={classnames(styles.item)}>
      <Link to={to}>
        <dl className={styles.definition}>{children}</dl>
      </Link>
    </li>
  );
};

type TitleProps = {
  children: ReactNode;
};

const Title: FC<TitleProps> = ({ children }) => {
  return (
    <dt>
      <span className={styles.icon}>
        <Pulldown size={13} />
      </span>
      {children}
    </dt>
  );
};

type DescriptionProps = JSX.IntrinsicElements['dd'] & {
  children: ReactNode;
};

const Description: FC<DescriptionProps> = ({ children }) => {
  return <dd>{children}</dd>;
};

export const SettingItem = {
  Container,
  Item,
  Title,
  Description
};
