import React from 'react';
import Button from 'jbc-front/components/Button';
import { Panel, Headding, Item, Lead, Buttons } from '../components/PrimaryPanel';
import { DescriptionEdit } from '../components/DescriptionEdit';
import { useSession } from '../components';
import { CustomDescription } from '../components/QuestionDescription';

const Question = () => {
  const { office } = useSession();
  return (
    <Panel>
      <Headding icon={<DescriptionEdit />}>今年の給与</Headding>
      <Item>
        <Lead>
          お給料は
          {office?.name || 'こちらの会社'}
          の方が高いですか？
        </Lead>
        <CustomDescription field="top" />
        <Buttons>
          <Button huge secondary disabled>
            はい
          </Button>
          <Button huge secondary disabled>
            いいえ
          </Button>
        </Buttons>
      </Item>
    </Panel>
  );
};

export default Question;
