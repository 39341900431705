import { FC, ComponentProps } from 'react';
import { Grid } from '../../../components/Grid';
import { Diff, FileDiff } from '../../../components/Diff';
import { useResult } from '../ResultProvider';
import { WORKING_STUDENT_CLASSIFICATION, HANDICAP_CLASSIFICATION, WIDOW_CLASSIFICATION } from '../../../consts';
import { booleanFormat, amountFormat } from '@jbc-year-end-adj/common/utils/formatter';

export const Preview: FC = () => {
  const { employee } = useResult();
  const profile = employee.profile;
  const requestProfile = employee.request?.profile;

  const workingStudentClassificationDiff = (() => {
    const diff: ComponentProps<typeof Diff> = {
      title: '勤労学生区分',
      source: undefined,
      input: undefined
    };

    if (profile.workingStudentClassification?.classification) {
      diff.source = WORKING_STUDENT_CLASSIFICATION[profile.workingStudentClassification.classification];
    }

    if (requestProfile?.workingStudentClassification?.classification) {
      diff.input = WORKING_STUDENT_CLASSIFICATION[requestProfile.workingStudentClassification.classification];
    }

    return diff;
  })();

  const handicapClassificationDiff = (() => {
    const diff: ComponentProps<typeof Diff> = {
      title: '障害者区分',
      source: undefined,
      input: undefined
    };

    if (profile.handicapClassification?.classification) {
      diff.source = HANDICAP_CLASSIFICATION[profile.handicapClassification.classification];
    }

    if (requestProfile?.handicapClassification?.classification) {
      diff.input = HANDICAP_CLASSIFICATION[requestProfile.handicapClassification.classification];
    }

    return diff;
  })();

  const widowClassificationDiff = (() => {
    const diff: ComponentProps<typeof Diff> = {
      title: 'ひとり親・寡婦区分',
      source: undefined,
      input: undefined
    };

    if (profile.widowClassification?.classification) {
      diff.source = WIDOW_CLASSIFICATION[profile.widowClassification.classification];
    }

    if (requestProfile?.widowClassification?.classification) {
      diff.input = WIDOW_CLASSIFICATION[requestProfile.widowClassification.classification];
    }

    return diff;
  })();

  return (
    <>
      <Grid>
        <Diff
          title="今年の税区分"
          source={!profile.workForMultiCompanies || profile.salaryMoreThanOthers ? '甲欄' : '乙欄'}
          input={!requestProfile?.workForMultiCompanies || requestProfile?.salaryMoreThanOthers ? '甲欄' : '乙欄'}
        />

        <Diff
          title="来年の税区分"
          source={profile.salaryLessThanOthersNextYear ? '乙欄' : '甲欄'}
          input={requestProfile?.salaryLessThanOthersNextYear ? '乙欄' : '甲欄'}
        />
      </Grid>

      <Grid>
        <Diff {...workingStudentClassificationDiff} />

        <Diff
          title="勤労学生詳細"
          source={profile.workingStudentClassification?.detail}
          input={requestProfile?.workingStudentClassification?.detail}
        />

        <FileDiff
          title="学生手帳画像"
          source={profile.workingStudentClassification?.image}
          input={requestProfile?.workingStudentClassification?.image}
        />
      </Grid>

      <Grid>
        <Diff {...handicapClassificationDiff} />

        <Diff title="障害者詳細" source={profile.handicapClassification?.detail} input={requestProfile?.handicapClassification?.detail} />

        <FileDiff
          title="障害者手帳画像"
          source={profile.handicapClassification?.image}
          input={requestProfile?.handicapClassification?.image}
        />
      </Grid>

      <Grid>
        <Diff {...widowClassificationDiff} />
      </Grid>

      <Grid>
        <Diff
          title="（源泉徴収票）災害者"
          source={booleanFormat({ value: profile.isDisasterSufferer, truthyString: '災害者', falsyString: '対象外' })}
          input={booleanFormat({ value: !!requestProfile?.isDisasterSufferer, truthyString: '災害者', falsyString: '対象外' })}
        />

        <Diff
          title="徴収猶予税額"
          source={amountFormat(profile.taxPostponementAmount)}
          input={amountFormat(requestProfile?.taxPostponementAmount)}
        />
      </Grid>
    </>
  );
};
