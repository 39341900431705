import { LOCATION_CHANGE } from 'connected-react-router';
import _ from 'lodash';
import { parse } from 'query-string';

const routerScroll = store => next => action => {
  if (_.isObject(action) && action.type === LOCATION_CHANGE) {
    const newPath = action.payload;
    const oldPath = store.getState().router.location;
    if (!oldPath) {
      next(action);
      return;
    }
    if (newPath.pathname !== oldPath.pathname) {
      window.scrollTo(0, 0);
      next(action);
      return;
    }
    if (newPath.search !== oldPath.search) {
      const [oldQuery, newQuery] = [oldPath.search, newPath.search].map(search => parse(search, { arrayFormat: 'bracket' }));
      if (oldQuery.page !== newQuery.page || oldQuery._page !== newQuery._page) {
        window.scrollTo(0, 0);
        next(action);
        return;
      }
    }
  }
  next(action);
};
export default routerScroll;
