import moment from 'moment';
import { QuestionGroup, Question, QuestionDescription } from './types';

const GROUP_NO_MAP = {
  check_qualified_person: 1,
  check_basic_info: 2,
  check_dependent: 3,
  check_insurance_and_loan: 4,
  check_attachment: 5
};

export const findQuestionGroup = (questionGroups: QuestionGroup[], groupName: keyof typeof GROUP_NO_MAP): QuestionGroup | undefined => {
  const no = GROUP_NO_MAP[groupName];
  return questionGroups.find(group => group.no === no);
};

export const recordDisplay = (text: string | null): string => (text ? text : '―');

type DateInput = string | Date;

recordDisplay.dateOrTime = (dateTime: DateInput): string => {
  if (!dateTime) {
    return recordDisplay(null);
  }
  if (moment().isSame(dateTime, 'day')) {
    return moment(dateTime).format('LT');
  }
  if (
    moment()
      .subtract(1, 'days')
      .isSame(dateTime, 'day')
  ) {
    return '昨日';
  }
  if (
    moment()
      .subtract(2, 'days')
      .isSame(dateTime, 'day')
  ) {
    return '一昨日';
  }
  if (moment().isSame(dateTime, 'year')) {
    return moment(dateTime).format('MMMDo');
  }
  return moment(dateTime).format('LL');
};
export const findQuestion = (questionGroups: QuestionGroup[], code: string): Question | undefined => {
  return questionGroups
    .map(questionGroup => questionGroup.questions)
    .flatMap(questions => questions)
    .find(question => question.code === code);
};

export const findDescriptions = (question: Question, fields: string[]) => {
  return fields.reduce<{ [key: string]: QuestionDescription | undefined }>((descriptions, field) => {
    descriptions[field] = question.questionDescriptions.find(description => description.field === field);
    return descriptions;
  }, {});
};
