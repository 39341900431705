import { FC } from 'react';
import Button from 'jbc-front/components/Button';

import { Section } from '@jbc-year-end-adj/2024/components/ui/Section';
import { ToggleButton } from '@jbc-year-end-adj/2024/components/form/ToggleButton';
import { Question } from '@jbc-year-end-adj/2024/features/questions/types';
import {
  TITLE,
  LifeInsurance,
  EarthquakeInsurance,
  SocialInsurance,
  Premium,
  HousingLoan
} from '@jbc-year-end-adj/2024/features/questions/templates/HasInsurances';
import { ButtonContainer } from '@jbc-year-end-adj/2024/features/questions/components/ButtonContainer';
import { Content } from '@jbc-year-end-adj/2024/features/questions/templates/parts/Content';

import { EditModal } from '../EditModal';
import { filteredDescritpions } from './utils';
import styles from './Questions.scss';

type HasInsurancesProps = {
  question: Question;
};

export const HasInsurances: FC<HasInsurancesProps> = ({ question }) => {
  const fields = ['life_insurance', 'earthquake_insurance', 'social_insurance', 'premium', 'housing_loan'];
  const descriptions = filteredDescritpions(question, fields);

  return (
    <Section>
      <Section.Header className={styles.header}>
        {TITLE}
        <EditModal questionId={String(question.id)} descriptions={descriptions} />
      </Section.Header>
      <Section.Body>
        <LifeInsurance question={question}>
          <Content>
            <ToggleButton.Container>
              <ToggleButton disabled>はい</ToggleButton>
              <ToggleButton disabled>いいえ</ToggleButton>
            </ToggleButton.Container>
          </Content>
        </LifeInsurance>
        <EarthquakeInsurance question={question}>
          <Content>
            <ToggleButton.Container>
              <ToggleButton disabled>はい</ToggleButton>
              <ToggleButton disabled>いいえ</ToggleButton>
            </ToggleButton.Container>
          </Content>
        </EarthquakeInsurance>
        <SocialInsurance question={question}>
          <Content>
            <ToggleButton.Container>
              <ToggleButton disabled>はい</ToggleButton>
              <ToggleButton disabled>いいえ</ToggleButton>
            </ToggleButton.Container>
          </Content>
        </SocialInsurance>
        <Premium question={question}>
          <Content>
            <ToggleButton.Container>
              <ToggleButton disabled>はい</ToggleButton>
              <ToggleButton disabled>いいえ</ToggleButton>
            </ToggleButton.Container>
          </Content>
        </Premium>
        <HousingLoan question={question}>
          <Content>
            <ToggleButton.Container>
              <ToggleButton disabled>はい</ToggleButton>
              <ToggleButton disabled>いいえ</ToggleButton>
            </ToggleButton.Container>
          </Content>
        </HousingLoan>
        <ButtonContainer>
          <Button primary disabled>
            次へ
          </Button>
        </ButtonContainer>
      </Section.Body>
    </Section>
  );
};
