import useReactRouter from 'use-react-router';

export const useYear = () => {
  const {
    match: { params }
  } = useReactRouter();

  const year = params.year && params.year | 0;

  return Number(year);
};
