import React from 'react';
import { useSession } from '../components';

export const Title = ({ children }) => {
  const {
    clientYearly: { year }
  } = useSession();
  return (
    <h1 className="m-title-main">
      {year ? (
        <>
          <span>{year ? `${year}年の` : ''}</span>
          {children}
        </>
      ) : (
        children
      )}
    </h1>
  );
};

export default Title;
