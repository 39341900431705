import { FC } from 'react';
import { DiffItem } from '../../../../components/Diff';
import { useResult } from '../../ResultProvider';
import { OtherFamily } from '../../../../query';
import { dateFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { GENDER } from '../../../../consts';
import { CreateOrDeleteOtherFamilyRow } from './CreateOrDeleteOtherFamilyRow';
import { UpdateOtherFamilyRow } from './UpdateOtherFamilyRow';

import styles from './Preview.scss';
import classnames from 'classnames';

export type DiffType = {
  type: 'create' | 'update' | 'delete';
  source: OtherFamily | undefined;
  input: OtherFamily | undefined;
};

export const Preview: FC = () => {
  const { employee } = useResult();
  const profile = employee.profile;
  const requestProfile = employee.request?.profile;

  const spouse = profile.spouse;
  const requestSpouse = requestProfile?.spouse;

  const handleSpouseClick = () => {
    window.location.href = '#request-spouse';
  };

  // compareKeyが一致するもの || profileにしかないもの
  const otherFamiliesDiff = profile.otherFamilies.reduce<DiffType[]>((diff, source) => {
    const input = requestProfile?.otherFamilies.find(otherFamily => otherFamily.compareKey === source.compareKey);
    const type = input ? 'update' : 'delete';
    diff.push({ type, source, input });

    return diff;
  }, []);

  // requestにしかないもの
  requestProfile?.otherFamilies.forEach(requestOtherFamily => {
    const index = profile.otherFamilies.findIndex(otherFamily => otherFamily.compareKey === requestOtherFamily.compareKey);
    if (index === -1) {
      otherFamiliesDiff.push({
        type: 'create',
        source: undefined,
        input: requestOtherFamily
      });
    }
  });

  if (otherFamiliesDiff.length === 0 && !spouse && !requestSpouse) {
    return <p>扶養親族情報がありません</p>;
  }

  return (
    <div className="l-overflow-scroll">
      <table className={classnames('m-table-list', styles.expandableTable)}>
        <thead>
          <tr>
            <th className={classnames(styles.relationType)}>続柄</th>
            <th className={styles.fullName}>氏名</th>
            <th className={styles.birthday}>生年月日</th>
            <th className={styles.gender}>性別</th>
            <th className={styles.taxLaw}>今年の税扶養</th>
            <th className={styles.taxLaw}>来年の税扶養</th>
          </tr>
        </thead>
        <tbody>
          {(spouse || requestSpouse) && (
            <tr className={styles.row} onClick={handleSpouseClick}>
              <td className={styles.spouseColumn}>配偶者</td>
              <td>
                <DiffItem
                  source={spouse ? `${spouse.lastName}${spouse.firstName}（${spouse.lastNameKana}${spouse.firstNameKana}）` : undefined}
                  input={
                    requestSpouse
                      ? `${requestSpouse.lastName}${requestSpouse.firstName}（${requestSpouse.lastNameKana}${requestSpouse.firstNameKana}）`
                      : undefined
                  }
                />
              </td>
              <td>
                <DiffItem source={dateFormat(spouse?.birthday, 'L')} input={dateFormat(requestSpouse?.birthday, 'L')} />
              </td>
              <td className={styles.genderTd}>
                <DiffItem
                  source={spouse?.gender ? GENDER[spouse.gender] : undefined}
                  input={requestSpouse?.gender ? GENDER[requestSpouse.gender] : undefined}
                />
              </td>
              <td className={styles.taxLawTd}>
                <DiffItem
                  source={spouse?.thisYear?.dependentTaxLaw ? '○' : '×'}
                  input={requestSpouse?.thisYear?.dependentTaxLaw ? '○' : '×'}
                />
              </td>
              <td className={styles.taxLawTd}>
                <DiffItem
                  source={spouse?.nextYear?.dependentTaxLaw ? '○' : '×'}
                  input={requestSpouse?.nextYear?.dependentTaxLaw ? '○' : '×'}
                />
              </td>
            </tr>
          )}
          {otherFamiliesDiff.map((diff, index) => {
            switch (diff.type) {
              case 'create':
                return <CreateOrDeleteOtherFamilyRow key={index} type="create" otherFamily={diff.input!} />;
              case 'delete':
                return <CreateOrDeleteOtherFamilyRow key={index} type="delete" otherFamily={diff.source!} />;
              case 'update':
                return <UpdateOtherFamilyRow key={index} diff={diff} />;
              default:
                return null;
            }
          })}
        </tbody>
      </table>
    </div>
  );
};
