import React from 'react';
import { gql, useApolloClient } from '@apollo/client';
import PreviewPdf from '../components/PreviewPdf';
import ListGroup from 'jbc-front/components/ListGroup';
import ActionButton from 'jbc-front/components/ActionButton';
import { Preview, Print } from 'jbc-front/components/icons';
import { useQuery, LoadingPage } from '../components';
import styles from './Finished.scss';
import { useNotify } from '../actions';
import Reports from './Reports';
import { Redirect } from 'react-router';
import { FORMER_JOBS_FRAGMENT } from '/frontend/src/questions/FormerJobs';

export const ADJ_TARGET_FIELDS_FRAGMENT = gql`
  fragment AdjTargetFields on Profile {
    resignBeforeYearEnd
    workForMultiCompanies
    salaryMoreThanOthers
    salaryLessThanOthersNextYear
    other
    otherReason
    isNotCoveredByNextYear
    needAdjThisYear
    nextYearOnly
  }
`;

export const FINISHED_QUERY = gql`
  query finishedQuery {
    request {
      id
      profile {
        id
        ...AdjTargetFields
        hasSpouseDeductionThisYear
      }
      employee {
        id
        status
        year
        profile {
          id
          ...AdjTargetFields
          hasSpouseDeductionThisYear
        }
      }
    }
    clientSetting {
      id
      requestPrint
    }
  }
  ${ADJ_TARGET_FIELDS_FRAGMENT}
`;

const FORMER_JOBS = gql`
  query formerJobs {
    request {
      id
      profile {
        id
        ...FormerJobs
      }
    }
  }
  ${FORMER_JOBS_FRAGMENT}
`;

const ReportItem = ({ name, apolloClient, setPdf, show, reports }) => {
  const notify = useNotify();
  return (
    <ListGroup.Item
      as="a"
      onClick={e => {
        e.stopPropagation();
        reports[name].show({
          client: apolloClient,
          setPdf,
          show,
          edit: null,
          notify
        });
      }}
    >
      <div className={styles.icon}>
        <Preview size={16} />
      </div>
      <p className={styles.newline}>{reports[name].title}</p>
      <div className={styles.img}>
        <ActionButton primary icon={<Print size={15} />}>
          印刷
        </ActionButton>
      </div>
    </ListGroup.Item>
  );
};

export const ReportList = ({ showFileMount }) => {
  const apolloClient = useApolloClient();
  const { loading, data } = useQuery(FINISHED_QUERY, {
    fetchPolicy: 'no-cache'
  });
  const { data: formerJobs } = useQuery(FORMER_JOBS, {
    fetchPolicy: 'no-cache'
  });
  const isWithholdingSlipUnavailable = formerJobs?.request?.profile?.formerJobs?.some(
    formerJob => formerJob.withholdingSlipStatus === 'unavailable'
  );

  if (loading) {
    return <LoadingPage />;
  }
  const {
    request,
    request: {
      employee,
      employee: { status, year }
    }
  } = data;
  if (status === 'not_requested') {
    return null;
  }
  const profile = ['in_progress', 'applying', 'rejected', 'fixed'].includes(status) ? request.profile : employee.profile;
  if (!profile || (!profile.needAdjThisYear && !profile.nextYearOnly)) {
    return null;
  }
  return (
    <Reports year={year}>
      {reports => (
        <PreviewPdf>
          {({ show, isOpen, setPdf }) =>
            profile.nextYearOnly ? (
              <ListGroup>
                <ReportItem {...{ apolloClient, show, setPdf, reports }} name="dependentDeductionNextYear" />
              </ListGroup>
            ) : (
              <ListGroup>
                {!isWithholdingSlipUnavailable && (
                  <ReportItem {...{ apolloClient, show, setPdf, reports }} name="dependentDeductionThisYear" />
                )}
                {!profile.isNotCoveredByNextYear && (
                  <ReportItem {...{ apolloClient, show, setPdf, reports }} name="dependentDeductionNextYear" />
                )}
                {!isWithholdingSlipUnavailable && <ReportItem {...{ apolloClient, show, setPdf, reports }} name="insuranceDeduction" />}
                {year >= 2020 && !isWithholdingSlipUnavailable && (
                  <ReportItem {...{ apolloClient, show, setPdf, reports }} name="bsiDeduction" />
                )}
                {year <= 2019 && profile.hasSpouseDeductionThisYear && (
                  <ReportItem {...{ apolloClient, show, setPdf, reports }} name="spouseDeduction" />
                )}
                {showFileMount && <ReportItem {...{ apolloClient, show, setPdf, reports }} name="fileMount" />}
              </ListGroup>
            )
          }
        </PreviewPdf>
      )}
    </Reports>
  );
};

const Finished = () => {
  const { loading, data } = useQuery(FINISHED_QUERY);
  if (loading) {
    return <LoadingPage />;
  }
  if (!data.request) {
    return <Redirect to="/employee_error?reason=session_expired" />;
  }
  const {
    request: {
      employee: { status }
    },
    clientSetting: { requestPrint }
  } = data;
  return (
    <div>
      <div className="l-wrap-xs u-pc-pt100 u-sp-pt20">
        <div className={styles.main}>
          <h2 className={styles.title}>提出が完了しました</h2>
          <h3 className={styles.status}>
            {status === 'accepted' || status === 'not_need_adj' ? (
              <div className={styles.confirmed}>
                管理者から承認されました
                {requestPrint && status === 'accepted' && (
                  <p className={styles.confirmed_text}>各申告書を印刷し、担当者に提出してください。</p>
                )}
              </div>
            ) : (
              <div className={styles.inprogress}>管理者の承認を待っています</div>
            )}
          </h3>
          <ReportList showFileMount />
          <ul className="u-mt20">
            {status !== 'accepted' && status !== 'not_need_adj' && (
              <>
                <li>・情報提出が完了しました。 情報の修正はできません。</li>
                <li>・情報に誤りがある場合担当者に連絡してください。</li>
              </>
            )}
            {status === 'accepted' && (
              <>
                <li>
                  <b>・保険等の証明書の原本は提出が必須です。</b>
                </li>
                <li>・添付書類台紙に貼って提出しますと便利です。</li>
              </>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Finished;
