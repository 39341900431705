import React from 'react'
import Icon from 'react-icon-base'

const Postit = props => (
  <Icon viewBox="0 0 26 26" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle cx="12.839" cy="12.838" r="12.339" fill="#FFF" fillRule="nonzero" />
      <path fill="#FFF" fillRule="nonzero" d="M11.051 8.32h-3.48a1.07 1.07 0 0 0-.362.062c-.336.126-.572.414-.572.75v9c0 .446.418.812.934.812h6.548l4.73-4.061V9.132c0-.447-.418-.812-.934-.812h-6.864z" />
      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M11.051 8.32h-3.48a1.07 1.07 0 0 0-.362.062c-.336.126-.572.414-.572.75v9c0 .446.418.812.934.812h6.548l4.73-4.061V9.132c0-.447-.418-.812-.934-.812h-6.864z" />
      <path fill="currentColor" fillRule="nonzero" d="M8.759 9.913h8.499c.291 0 .531.237.531.531 0 .293-.24.531-.531.531H8.759a.53.53 0 1 1 0-1.062zM8.759 12.037h8.499a.532.532 0 0 1 0 1.062H8.759a.531.531 0 0 1 0-1.062z" />
      <path fill="#F3F3F3" fillRule="nonzero" stroke="currentColor" d="M18.303 5.786a.402.402 0 0 0-.293-.12.406.406 0 0 0-.293.12l-5.771 5.767v2.077h2.078l5.768-5.767a.412.412 0 0 0 .002-.584l-.002-.002-1.489-1.491z" />
      <path fill="currentColor" fillRule="nonzero" d="M13.922 14.692l5.244-.048-4.543 3.802-.736.529z" />
    </g>
  </Icon>
)
Postit.defaultProps = {
  size: 26
}

export default Postit
