import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory as createHistory } from 'history';
import routerScroll from './routerScroll';
import rootReducer from './reducers';
import { createEpicMiddleware } from 'redux-observable';
import epics from './epics';
import trimFields from './trimFields';
const isDevelopmentEnv = true;

const epicMiddleware = createEpicMiddleware();
export const history = createHistory();
let defaultMiddleware = [thunk, routerMiddleware(history), routerScroll, epicMiddleware, trimFields];

export default function configureStore(initialState, middleware = []) {
  const composeEnhancers =
    isDevelopmentEnv && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;
  const store = createStore(rootReducer(history), initialState, composeEnhancers(applyMiddleware(...defaultMiddleware, ...middleware)));
  epicMiddleware.run(epics);
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const nextRootReducer = require('./reducers').default;
      store.replaceReducer(nextRootReducer);
    });
  }
  return store;
}
