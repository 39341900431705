import React from 'react'
import Icon from 'react-icon-base'

const User = props => (
  <Icon viewBox="0 0 30 30" {...props}>
    <g id="symbol" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon_user" fill="#fff">
        <circle cx="15" cy="15" r="15" fill="currentColor" />
        <path d="M 8,22 a 7 7 -180 0 1 14,0z" stroke="#fff" strokeWidth="2" fill="none" />
        <ellipse cx="15" cy="12" rx="4" ry="5" stroke="#fff" strokeWidth="2" fill="currentColor" />
      </g>
    </g>
  </Icon>
)
User.defaultProps = {
  size: 30
}

export default User
