import { FC } from 'react';
import { AllIncome as AllIncomeTemplate } from 'features/questions/templates/AllIncome';
import { Description } from 'features/questions/templates/parts/Description';
import { Section } from 'components/ui/Section';
import { FormSection } from 'components/form/FormSection';
import { Row } from 'components/form/Row';
import { InputWithText } from 'components/form/InputWithText';
import { FormItem } from 'components/form/FormItem';
import { Checkbox } from 'jbc-front/components/presenters/form/Checkbox';

import { Separator } from 'components/ui/Separator';
import { Input } from 'components/form/Input';
import { useWizard } from '../WizardProvider';
import { useYear } from 'hooks/useYear';
import { amountFormat } from '@jbc-year-end-adj/common/utils/formatter';

import {
  salaryEarnings as calcSalaryEarnings,
  calcTotalSalaryIncome,
  generalEarningsAllowMinus,
  generalEarnings,
  miscellaneousEarnings as calcMiscellaneousEarnings,
  retirementIncomeDeductionAmount as calcRetirementIncomeDeductionAmount,
  retirementEarnings as calcRetirementEarnings,
  totalEarnings as calcTotalEarnings
  // @ts-ignore
} from 'utils/income';

export const AllIncome: FC = () => {
  const { question, request } = useWizard();
  const year = useYear();
  const income = request.profile?.income;

  const {
    mainJobIncome,
    sideJobIncome,
    businessIncome,
    businessExpense,
    dividendIncome,
    dividendExpense,
    realEstateIncome,
    realEstateExpense,
    retirementIncome,
    lengthOfService,
    retireForDisablity,
    isOfficerRetirementAllowance,
    miscellaneousIncomeOfficalPension,
    miscellaneousIncomeOther,
    miscellaneousExpense,
    otherIncome,
    otherExpense,
    otherExpenseSpecialDeduction,
    otherEarings: otherEarnings
  } = income || {};

  const formerJobsPaymentAmount = request.profile?.formerJobs.reduce((totalIncome, formerJob) => {
    if (formerJob.paymentAmount) {
      return totalIncome + Number(formerJob.paymentAmount);
    } else {
      return totalIncome;
    }
  }, 0);

  const totalSalaryIncome = calcTotalSalaryIncome(mainJobIncome, sideJobIncome, String(formerJobsPaymentAmount || 0));
  const salaryEarnings = calcSalaryEarnings(totalSalaryIncome, year);
  const businessEarnings = generalEarningsAllowMinus(businessIncome, businessExpense);
  const dividendEarnings = generalEarnings(dividendIncome, dividendExpense);
  const realEstateEarnings = generalEarningsAllowMinus(realEstateIncome, realEstateExpense);
  const retirementIncomeDeductionAmount = calcRetirementIncomeDeductionAmount(lengthOfService, retireForDisablity, retirementIncome);
  const retirementEarnings = calcRetirementEarnings(
    retirementIncome,
    retirementIncomeDeductionAmount,
    isOfficerRetirementAllowance,
    lengthOfService,
    year
  );
  const miscellaneousEarnings = calcMiscellaneousEarnings(
    miscellaneousIncomeOfficalPension,
    miscellaneousIncomeOther,
    miscellaneousExpense,
    request?.profile?.birthday,
    year,
    {
      salary: salaryEarnings,
      business: businessEarnings,
      dividend: dividendEarnings,
      realEstate: realEstateEarnings,
      retirement: retirementEarnings,
      other: otherEarnings
    }
  );
  const totalEarnings = calcTotalEarnings(
    salaryEarnings,
    businessEarnings,
    miscellaneousEarnings,
    dividendEarnings,
    realEstateEarnings,
    retirementEarnings,
    otherEarnings
  );

  return (
    <AllIncomeTemplate question={question}>
      <Description caution>
        「当社」欄は今年1年間の見込み収入金額（賞与含む、前職除く）を入力してください。
        <br />
        「他社」欄は2か所以上からの給与収入がある場合にその金額を入力してください。
      </Description>

      <Section>
        <Section.Header>本人の給与収入</Section.Header>
        <Section.Body>
          <FormSection>
            <Row>
              <InputWithText
                label="給与所得 収入金額（当社）"
                text="円"
                note="前Stepで入力した前職情報は含みません"
                value={amountFormat(mainJobIncome)}
                disabled
              />
              <InputWithText
                label="給与所得 収入金額（他社）"
                text="円"
                note="前Stepで入力した前職情報は含みません"
                value={amountFormat(sideJobIncome)}
                disabled
              />
            </Row>
            <Row>
              <InputWithText
                label="給与所得 収入金額（前職）"
                text="円"
                value={amountFormat(String(formerJobsPaymentAmount || 0))}
                disabled
              />
              <FormItem />
            </Row>
            <Row>
              <InputWithText label="給与所得 収入金額（合計）" text="円" value={amountFormat(totalSalaryIncome)} disabled />
              <InputWithText label="給与所得 所得金額" text="円" value={amountFormat(salaryEarnings)} disabled />
            </Row>
          </FormSection>
        </Section.Body>
      </Section>

      <Section>
        <Section.Header>本人の給与収入以外の収入</Section.Header>
        <Section.Body>
          <FormSection>
            <Row>
              <InputWithText
                label="事業所得 収入金額"
                noteMaxLength={19}
                note="農業、林業、水産養殖業、製造業、卸売業、小売業、金融業などのサービス業のほか対価を得て継続的に行う事業による所得"
                text="円"
                value={amountFormat(businessIncome)}
                disabled
              />
              <InputWithText
                label="事業所得 必要経費"
                noteMaxLength={19}
                note="収入を得るために必要な売上原価、販売費・一般管理費その他の費用"
                text="円"
                value={amountFormat(businessExpense)}
                disabled
              />
              <InputWithText text="円" label="事業所得 所得金額" value={amountFormat(businessEarnings)} disabled />
            </Row>

            <Separator />

            <Row>
              <InputWithText
                label="雑所得 収入金額（公的年金等に係る雑所得）"
                note="年金、厚生年金、共済年金などの公的年金等"
                text="円"
                value={amountFormat(miscellaneousIncomeOfficalPension)}
                disabled
              />
              <InputWithText
                label="雑所得 収入金額（公的年金等以外の雑所得）"
                noteMaxLength={19}
                note="原稿料や印税、講演料、放送出演料、貸金の利子、生命保険契約等に基づく年金など他のいずれの所得にも該当しない所得や恩給（一時恩給を除く）等"
                text="円"
                value={amountFormat(miscellaneousIncomeOther)}
                disabled
              />
            </Row>
            <Row>
              <InputWithText text="円" label="雑所得 必要経費" value={amountFormat(miscellaneousExpense)} disabled />
              <InputWithText text="円" label="雑所得 所得金額" value={amountFormat(miscellaneousEarnings)} disabled />
            </Row>

            <Separator />

            <Row>
              <InputWithText
                label="配当所得 収入金額"
                noteMaxLength={19}
                note="株主や出資者が法人から受ける剰余金や、利益の配当、剰余金の分配、投資法人からの金銭の分配、投資信託（公社債投資信託及び公募公社債等運用投資信託以外のもの）及び特定受益証券発行信託の収益の分配などに係る所得"
                text="円"
                value={amountFormat(dividendIncome)}
                disabled
              />
              <InputWithText
                label="配当所得 必要経費"
                noteMaxLength={19}
                note="収入金額からその元本を取得するために要した負債の利子（株式等の取得のために借り入れた負債の利子のうち、その株式等の譲渡所得等に係るものを除きます。）"
                text="円"
                value={amountFormat(dividendExpense)}
                disabled
              />
              <InputWithText text="円" label="配当所得 所得金額" value={amountFormat(dividendEarnings)} disabled />
            </Row>

            <Separator />

            <Row>
              <InputWithText
                label="不動産所得 収入金額"
                noteMaxLength={19}
                note="不動産の貸付けに際して受け取る権利金や頭金、更新料、名義書換料も不動産所得になります。"
                text="円"
                value={amountFormat(realEstateIncome)}
                disabled
              />
              <InputWithText
                label="不動産所得 必要経費"
                noteMaxLength={19}
                note="貸し付けた不動産についての修繕費、損害保険料、租税公課、減価償却費、借入金利子等"
                text="円"
                value={amountFormat(realEstateExpense)}
                disabled
              />
              <InputWithText text="円" label="不動産所得 所得金額" value={amountFormat(realEstateEarnings)} disabled />
            </Row>

            <Separator />

            <InputWithText
              label="退職所得 収入金額"
              note="退職手当、一時恩給その他の退職により一時に受ける給与などの所得のほか、社会保険制度等に基づく一時金など"
              text="円"
              value={amountFormat(retirementIncome)}
              disabled
            />
            {retirementIncome && Number(retirementIncome) > 0 && (
              <>
                <Input label="勤続年数" value={lengthOfService} disabled />
                <Checkbox checked={retireForDisablity} disabled>
                  障害者になったことに直接基因して退職した
                </Checkbox>
                <Checkbox checked={isOfficerRetirementAllowance} disabled>
                  退職手当等が特定役員退職手当等に該当する
                </Checkbox>
              </>
            )}
            <Row>
              <InputWithText text="円" label="退職所得 控除額" value={amountFormat(retirementIncomeDeductionAmount)} disabled />
              <InputWithText text="円" label="退職所得 所得金額" value={amountFormat(retirementEarnings)} disabled />
            </Row>

            <Separator />

            <Row>
              <InputWithText
                label="その他所得 収入金額"
                noteMaxLength={19}
                note="⑴ 譲渡所得（土地、建物、機械、ゴルフ会員権、金地金、書画、骨とうなどの資産の譲渡による所得） ⑵ 山林所得（山林（所有期間５年超）の伐採又は譲渡による所得） ⑶ 一時所得（賞金や懸賞当せん金、競馬・競輪の払戻金（営利を目的とする継続的行為から生じたものを除きます。）、生命保険契約等に基づく一時金、損害保険契約等に基づく満期返戻金、遺失物拾得の報労金などによる所得） ⑷ 総合課税又は申告分離課税の対象となる利子所得 （注） 利子所得のうち預貯金の利子などの源泉分離課税の対象となるもの及び特定公社債の利子などの申告分離課税の対象となるもので確定申告しないことを選択したものは、収入金額に含まれません。 ⑸ 申告分離課税の適用を受けた一般株式等に係る譲渡所得等又は上場株式等に係る譲渡所得等（源泉徴収選択口座を通じて行った上場株式等の譲渡による所得等で、確定申告をしないことを選択した所得等は、収入金額に含まれません。） ⑹ 先物取引に係る雑所得等"
                text="円"
                value={amountFormat(otherIncome)}
                disabled
              />

              <InputWithText text="円" label="その他所得 必要経費" value={amountFormat(otherExpense)} disabled />
            </Row>
            <Row>
              <InputWithText
                text="円"
                label="その他所得 必要経費 うち特別控除額"
                value={amountFormat(otherExpenseSpecialDeduction)}
                disabled
              />
              <InputWithText text="円" label="その他所得 所得金額" value={amountFormat(otherEarnings)} disabled />
            </Row>

            <InputWithText text="円" label="合計所得金額" value={amountFormat(totalEarnings)} disabled />
          </FormSection>
        </Section.Body>
      </Section>
    </AllIncomeTemplate>
  );
};
