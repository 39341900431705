import React from 'react';
import { gql } from '@apollo/client';
import Button from 'jbc-front/components/Button';
import styles from '../pages/Result.scss';
import { useMutation, Progress, useYear, TODOS, useSession } from '../components';
import { Clock } from 'jbc-front/components/icons';
import { Link } from 'react-router-dom';
import fp from 'lodash/fp';

const fields = ['syncResult', 'sendWithholdingSlip', 'submitPayrollReport', 'submitWithholdintSlipAndLegalRecord'];

const SET_TODOS = gql`
  mutation setTodos($input: SetTodosInput!) {
    setTodos(input: $input) {
      clientYearly {
        id
        ...Todos
      }
    }
  }
  ${TODOS}
`;

const Todo = ({ children, link, loading, setTodos, field }) => {
  const year = useYear();
  const { clientYearly } = useSession();
  return (
    <li>
      <label className={clientYearly[field] ? 'checked_on' : 'checked_off'}>
        <input
          readOnly
          type="checkbox"
          checked={clientYearly[field]}
          onChange={() =>
            setTodos({
              variables: { input: { [field]: !clientYearly[field], year } },
              optimisticResponse: {
                setTodos: {
                  clientYearly: {
                    ...clientYearly,
                    [field]: !clientYearly[field]
                  },
                  __typename: 'SetTodosPayload'
                }
              }
            })
          }
          className={styles.check}
        />
        {children}
      </label>
      {link}
    </li>
  );
};

const Todos = () => {
  const [setTodos, { loading }] = useMutation(SET_TODOS);
  const { clientYearly } = useSession();
  const year = useYear();
  if (!clientYearly.fixed) {
    return null;
  }
  const finished = fields.map(field => clientYearly[field]).filter(fp.identity).length;
  return (
    <div className={styles.head}>
      <h2 className={styles.headTitle}>年末調整を完了しましょう</h2>
      <div className={styles.progress}>
        <Progress progress={Math.round((finished * 100) / fields.length)} key="progress" large />
        <div className={styles.progressCount}>
          <span className={styles.progressInfo}>
            進捗情報： {finished}/{fields.length}
          </span>
          <span className={styles.progressDate}>
            <Clock size={16} />
            {`${+year + 1}年1月31日`}
          </span>
        </div>
      </div>
      <div className={styles.taskWrap}>
        <ul className={styles.taskList}>
          <Todo
            field="syncResult"
            link={
              <Button as={Link} to={`/${year}/result/difference_apply`}>
                年末調整結果反映
              </Button>
            }
            {...{ setTodos, loading }}
          >
            年末調整結果を給与に反映しましょう
          </Todo>
          <Todo
            field="sendWithholdingSlip"
            link={
              <Button as={Link} to={`/${year}/result/withholding_slip_deliver`}>
                源泉徴収票交付
              </Button>
            }
            {...{ setTodos, loading }}
          >
            従業員へ源泉徴収票を交付しましょう
          </Todo>
          <Todo
            field="submitPayrollReport"
            link={
              <Button as={Link} to={`/${year}/result/city_report`}>
                市区町村提出準備
              </Button>
            }
            {...{ setTodos, loading }}
          >
            市区町村へ給与支払報告書を提出しましょう
          </Todo>
          <Todo
            field="submitWithholdintSlipAndLegalRecord"
            link={
              <Button as={Link} to={`/${year}/result/tax_office`}>
                税務署提出準備
              </Button>
            }
            {...{ setTodos, loading }}
          >
            税務署へ源泉徴収票・法定調書合計表を提出しましょう
          </Todo>
        </ul>
      </div>
    </div>
  );
};

export default Todos;
