import React from 'react'
import Icon from 'react-icon-base'

const Custom3 = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="Custom3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group-3" fill={props.color} transform="translate(3.000000, 4.000000)">
        <g id="Group" transform="translate(0.000000, 12.000000)" fillRule="nonzero">
          <g id="Page-1-Copy-3">
            <path d="M8.97971334,-0.354336432 C5.68382005,-0.354336432 3.0111976,2.31828601 3.0111976,5.61417931 C3.0111976,8.91007261 5.68382005,11.5826951 8.97971334,11.5826951 C12.2756066,11.5826951 14.9482291,8.91007261 14.9482291,5.61417931 C14.9482291,2.31828601 12.2756066,-0.354336432 8.97971334,-0.354336432 Z M8.97971334,1.50773253 C11.2472143,1.50773253 13.0861601,3.34667831 13.0861601,5.61417931 C13.0861601,7.88168032 11.2472143,9.72062609 8.97971334,9.72062609 C6.71221234,9.72062609 4.87326657,7.88168032 4.87326657,5.61417931 C4.87326657,3.34667831 6.71221234,1.50773253 8.97971334,1.50773253 Z" id="Stroke-1"></path>
            <path d="M8.97971334,9.72062609 C14.2578555,9.72062609 18.5374345,14.0002051 18.5374345,19.2783472 C18.5374345,19.7925434 18.1205961,20.2093817 17.6064,20.2093817 C17.0922039,20.2093817 16.6753655,19.7925434 16.6753655,19.2783472 C16.6753655,15.0285973 13.2294632,11.5826951 8.97971334,11.5826951 C4.72996346,11.5826951 1.28406117,15.0285973 1.28406117,19.2783472 C1.28406117,19.7925434 0.867222833,20.2093817 0.353026687,20.2093817 C-0.16116946,20.2093817 -0.578007796,19.7925434 -0.578007796,19.2783472 C-0.578007796,14.0002051 3.70157117,9.72062609 8.97971334,9.72062609 Z" id="Stroke-3"></path>
          </g>
        </g>
        <g id="Group-2-Copy" transform="translate(12.000000, 0.000000)" fillRule="nonzero">
          <path d="M18.9638009,1.53846154 C19.1828686,1.53846154 19.3945942,1.61034672 19.5673793,1.74115319 L19.6661247,1.82660392 L25.2407853,7.32660392 C25.432974,7.51621858 25.5265931,7.74739459 25.5390318,7.97774724 L25.5384615,16.5384615 C25.5384615,17.0907463 25.0907463,17.5384615 24.5384615,17.5384615 C24.0256257,17.5384615 23.6029544,17.1524213 23.5451893,16.6550827 L23.5384615,16.5384615 L23.538,9.038 L18.9638009,9.03846154 C18.4509651,9.03846154 18.0282937,8.65242135 17.9705286,8.15508266 L17.9638009,8.03846154 L17.963,3.538 L-0.945701357,3.53846154 C-1.24303261,3.53846154 -1.48530335,3.74815267 -1.53078419,4.01794031 L-1.53846154,4.10989011 L-1.53846154,22.967033 C-1.53846154,23.2483285 -1.32592392,23.4861492 -1.04257808,23.5309004 L-0.945701357,23.5384615 L7.46153846,23.5384615 C8.01382321,23.5384615 8.46153846,23.9861768 8.46153846,24.5384615 C8.46153846,25.0512974 8.07549827,25.4739687 7.57815959,25.5317338 L7.46153846,25.5384615 L-0.945701357,25.5384615 C-2.31968814,25.5384615 -3.44854518,24.476861 -3.53334781,23.1299785 L-3.53846154,22.967033 L-3.53846154,4.10989011 C-3.53846154,2.74142719 -2.46245014,1.62719347 -1.10935202,1.54350754 L-0.945701357,1.53846154 L18.9638009,1.53846154 Z M19.963,4.929 L19.963,7.038 L22.101,7.038 L19.963,4.929 Z" id="Shape" transform="translate(11.000285, 13.538462) rotate(90.000000) translate(-11.000285, -13.538462) "></path>
        </g>
        <rect id="Rectangle" x="17" y="3" width="6" height="4" rx="1"></rect>
        <g id="Group-2" transform="translate(17.000000, 9.000000)" fillRule="nonzero">
          <path d="M11.5384615,-0.25 C11.9526751,-0.25 12.2884615,0.0857864376 12.2884615,0.5 C12.2884615,0.879695766 12.0063077,1.19349096 11.6402321,1.24315338 L11.5384615,1.25 L0.461538462,1.25 C0.0473248992,1.25 -0.288461538,0.914213562 -0.288461538,0.5 C-0.288461538,0.120304234 -0.00630765684,-0.193490961 0.359767904,-0.243153384 L0.461538462,-0.25 L11.5384615,-0.25 Z" id="Line-2-Copy-11"></path>
          <path d="M11.5384615,5.75 C11.9526751,5.75 12.2884615,6.08578644 12.2884615,6.5 C12.2884615,6.87969577 12.0063077,7.19349096 11.6402321,7.24315338 L11.5384615,7.25 L0.461538462,7.25 C0.0473248992,7.25 -0.288461538,6.91421356 -0.288461538,6.5 C-0.288461538,6.12030423 -0.00630765684,5.80650904 0.359767904,5.75684662 L0.461538462,5.75 L11.5384615,5.75 Z" id="Line-2-Copy-12"></path>
          <path d="M8.59090909,8.75 C9.00512265,8.75 9.34090909,9.08578644 9.34090909,9.5 C9.34090909,9.87969577 9.05875521,10.193491 8.69267965,10.2431534 L8.59090909,10.25 L0.472027972,10.25 C0.0578144097,10.25 -0.277972028,9.91421356 -0.277972028,9.5 C-0.277972028,9.12030423 0.00418185365,8.80650904 0.370257415,8.75684662 L0.472027972,8.75 L8.59090909,8.75 Z" id="Line-2-Copy-13"></path>
          <path d="M8.59090909,11.75 C9.00512265,11.75 9.34090909,12.0857864 9.34090909,12.5 C9.34090909,12.8796958 9.05875521,13.193491 8.69267965,13.2431534 L8.59090909,13.25 L0.472027972,13.25 C0.0578144097,13.25 -0.277972028,12.9142136 -0.277972028,12.5 C-0.277972028,12.1203042 0.00418185365,11.806509 0.370257415,11.7568466 L0.472027972,11.75 L8.59090909,11.75 Z" id="Line-2-Copy-13"></path>
          <path d="M6.5,2.75 C6.91421356,2.75 7.25,3.08578644 7.25,3.5 C7.25,3.87969577 6.96784612,4.19349096 6.60177056,4.24315338 L6.5,4.25 L0.5,4.25 C0.0857864376,4.25 -0.25,3.91421356 -0.25,3.5 C-0.25,3.12030423 0.0321538816,2.80650904 0.398229443,2.75684662 L0.5,2.75 L6.5,2.75 Z" id="Line-2-Copy-15"></path>
        </g>
      </g>
    </g>
  </Icon>
)
Custom3.defaultProps = {
  size: 30,
  color: "#3498DB"
}

export default Custom3