import React from 'react'
import Icon from 'react-icon-base'

const EditSquare = props => (
  <Icon viewBox="0 0 20 20" {...props}>
    <defs>
      <rect id='a' width='20' height='20' rx='2' />
    </defs>
    <g fill='#fff' fillRule='evenodd'>
      <rect width='19' height='19' x='.5' y='.5' stroke='currentColor' rx='2' />
      <path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.2' d='M8.958 15.97H4.233v-4.724' />
      <path fill='currentColor' d='M7.429 15.97H4.233v-3.195z' />
      <path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.2' d='M8.958 15.97l7.012-7.012-4.724-4.725-7.012 7.013M12.82 5.808L6.87 11.76M14.396 7.383l-5.952 5.952' />
    </g>
  </Icon>
)
EditSquare.defaultProps = {
  size: 20
}

export default EditSquare
