import { FC, useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import Button from 'jbc-front/components/Button';
import useRouter from 'use-react-router';
import { useAdminMutation as useMutation } from '@jbc-year-end-adj/2024/hooks/graphql/useMutation';
import { useFormErrors } from '@jbc-year-end-adj/2024/hooks/useFormErrors';
import { useNotify } from '@jbc-year-end-adj/2024/hooks/useNotify';
import { useSession } from '@jbc-year-end-adj/2024/features/AdminSessionProvider';
import { useAsyncTask } from '@jbc-year-end-adj/2024/features/AsyncTask';
import { year } from '@jbc-year-end-adj/2024/consts';
import { Schema, schema, buildDefaultValues } from './schema';
import { SAVE_CLIENT_SETTING, dataToQueryVariables } from './mutation';
import { PrintSection } from './PrintSection';
import { ImageUploadSection } from './ImageUploadSection';
import { EmployeeAddressClassificationSection } from './EmployeeAddressClassificationSection';
import { AcceptedEmailStatusSection } from './AcceptedEmailStatusSection';
import { SubmissionDeadlineSettingsSection } from './SubmissionDeadlineSettingsSection';
import styles from './Form.scss';
import { CooperationSection } from './CooperationSection';
import { ClientSetting } from '../../query';

type FormProps = {
  clientSetting?: ClientSetting;
};

export const Form: FC<FormProps> = ({ clientSetting }) => {
  const { history } = useRouter();
  const notify = useNotify();
  const [saveClientSetting, { loading }] = useMutation(SAVE_CLIENT_SETTING);

  const methods = useForm<Schema>({
    resolver: yupResolver(schema),
    defaultValues: buildDefaultValues(clientSetting),
    mode: 'onBlur',
    shouldFocusError: false
  });

  const { handleSubmit } = methods;

  const { onSubmitFail, FormErrors } = useFormErrors();

  const onSubmit: SubmitHandler<Schema> = async data => {
    try {
      const variables = dataToQueryVariables(data);
      await saveClientSetting({ variables });
      history.push(`/${year}`);
    } catch (e) {
      console.log(e);
      notify('設定の保存に失敗しました。', 'error');
    }
  };

  const { taskRunningProps } = useAsyncTask();
  const { office } = useSession();
  const [isNotYetImported, setIsNotYetImported] = useState(true);

  const disabledMessage = () => {
    if (isNotYetImported) {
      return 'ジョブカン労務HRまたはジョブカン給与計算からインポートを行ってください';
    } else if (loading) {
      return '保存中';
    } else {
      return undefined;
    }
  };

  useEffect(() => {
    if (taskRunningProps?.disabled || office) {
      setIsNotYetImported(false);
    }
  }, [taskRunningProps]);

  return (
    <FormProvider {...methods}>
      <form className={styles.form}>
        <FormErrors />

        <div className={styles.setting}>
          <PrintSection />
          <ImageUploadSection />
          <EmployeeAddressClassificationSection />
          <AcceptedEmailStatusSection />
          <SubmissionDeadlineSettingsSection />
          <CooperationSection />
        </div>

        <div className="u-ta-c u-mt20">
          <Button
            primary
            onClick={handleSubmit(onSubmit, onSubmitFail)}
            disabled={loading || isNotYetImported}
            disabledReason={disabledMessage()}
          >
            保存
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
