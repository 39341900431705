import { FC, ReactNode } from 'react';
import { Section } from 'components/ui/Section';

import { Question } from '../type';

import { Lead } from './parts/Lead';
import { Description } from './parts/Description';
import { Content } from './parts/Content';

type HouseholderProps = {
  question: Question;
  children: ReactNode;
};

export const Householder: FC<HouseholderProps> = ({ question, children }) => {
  const description = question.questionDescriptions.find(description => description.field == 'top')?.description;

  return (
    <Section>
      <Section.Header>世帯主の続柄</Section.Header>

      <Section.Body>
        <Content>
          <Lead>世帯主情報を確認します</Lead>

          <Description>{description}</Description>

          {children}
        </Content>
      </Section.Body>
    </Section>
  );
};
