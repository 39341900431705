import { FC, useState, useRef } from 'react';
import { Pdf as JbcPdf } from 'jbc-front/components/presenters/ui/Pdf';
import { usePdf } from 'jbc-front/components/libs/react-pdf-js';

type PdfProps = {
  report?: string;
};

export const Pdf: FC<PdfProps> = ({ report }) => {
  const zooms = [25, 33, 50, 67, 75, 80, 90, 100, 110, 125, 150, 175, 200, 250, 300, 400, 500];
  const [page, setPage] = useState<number>(1);
  const [zoom, setZoom] = useState<number>(100);
  const [scale, setScale] = useState<number>(zoom / 90.0);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const { pdfDocument } = usePdf({
    file: `data:application/pdf;base64,${report}`,
    cMapUrl: `${window.location.origin}/cmaps/`,
    cMapPacked: true,
    // @ts-ignore
    workerSrc: new URL('@bundled-es-modules/pdfjs-dist/build/pdf.worker.js', import.meta.url).toString(),
    canvasRef,
    page,
    scale
  });

  const handleChangeZoom = (scaleType: 'in' | 'out') => {
    const currentIndex = zooms.findIndex(value => zoom === value);
    if (currentIndex !== -1) {
      const nextIndex = (() => {
        switch (scaleType) {
          case 'in':
            return currentIndex + 1;
          case 'out':
            return currentIndex - 1;
          default:
            return currentIndex;
        }
      })();

      const newZoomValue = zooms[nextIndex];

      if (newZoomValue) {
        setZoom(newZoomValue);
        setScale(newZoomValue / 90.0);
      }
    }
  };

  const handlePrevPage = () => {
    const prevPage = page - 1;

    if (prevPage > 0) {
      setPage(prevPage);
    }
  };

  const handleNextPage = () => {
    const nextPage = page + 1;

    if (pdfDocument?.numPages && nextPage <= pdfDocument.numPages) {
      setPage(nextPage);
    }
  };

  return (
    <JbcPdf.Container>
      <JbcPdf ref={canvasRef} />
      <JbcPdf.ControllerContainer>
        <JbcPdf.Controller
          page={page}
          numPages={pdfDocument?.numPages}
          zoom={zoom}
          onZoomIn={() => handleChangeZoom('in')}
          onZoomOut={() => handleChangeZoom('out')}
          onPrevious={handlePrevPage}
          onNext={handleNextPage}
        />
      </JbcPdf.ControllerContainer>
    </JbcPdf.Container>
  );
};
