import React from 'react'
import Icon from 'react-icon-base'

const TmEmployee = props => (
  <Icon viewBox="0 0 20 20" {...props}>
    <g id="Symbols" strokeWidth="1">
      <g>
        <rect fill="#FFFFFF" x="0" y="0" width="20" height="20"></rect>
        <g id="Group" transform="translate(-2.000000, -4.000000)" fillRule="nonzero">
          <g id="Group-5" transform="translate(13.465962, 14.393862) rotate(-45.000000) translate(-13.465962, -14.393862) translate(5.465962, 3.393862)">
            <path d="M7.59318456,1.11517489e-12 C11.7867846,1.11517489e-12 15.1863691,3.39958453 15.1863691,7.59318456 C15.1863691,11.7867846 11.7867846,15.1863691 7.59318456,15.1863691 C3.39958453,15.1863691 1.10736964e-12,11.7867846 1.10736964e-12,7.59318456 C1.10736964e-12,3.39958453 3.39958453,1.11517489e-12 7.59318456,1.11517489e-12 Z M7.59318456,1.7 C4.3384686,1.7 1.7,4.3384686 1.7,7.59318456 C1.7,10.8479005 4.3384686,13.4863691 7.59318456,13.4863691 C10.8479005,13.4863691 13.4863691,10.8479005 13.4863691,7.59318456 C13.4863691,4.3384686 10.8479005,1.7 7.59318456,1.7 Z" id="Oval"></path>
            <path d="M8.1447031,13.4635204 L8.1447031,20.7022036 C8.1447031,21.2544883 7.69698785,21.7022036 7.1447031,21.7022036 C6.59241835,21.7022036 6.1447031,21.2544883 6.1447031,20.7022036 L6.1447031,13.4635204 L8.1447031,13.4635204 Z" id="Rectangle"></path>
          </g>
          <g id="icon-copy-6" transform="translate(6.792797, 7.684435)">
            <path d="M3.83894578,-0.281482313 C2.3663943,-0.281482313 1.17234602,0.912565974 1.17234602,2.38511745 C1.17234602,3.85766893 2.3663943,5.05171722 3.83894578,5.05171722 C5.31149726,5.05171722 6.50554555,3.85766893 6.50554555,2.38511745 C6.50554555,0.912565974 5.31149726,-0.281482313 3.83894578,-0.281482313 Z M3.83894578,1.01851769 C4.59352709,1.01851769 5.20554555,1.63053615 5.20554555,2.38511745 C5.20554555,3.13969876 4.59352709,3.75171722 3.83894578,3.75171722 C3.08436448,3.75171722 2.47234602,3.13969876 2.47234602,2.38511745 C2.47234602,1.63053615 3.08436448,1.01851769 3.83894578,1.01851769 Z" id="Stroke-1"></path>
            <path d="M3.83894578,3.75171722 C6.10502927,3.75171722 7.94237288,5.58906083 7.94237288,7.85514432 C7.94237288,8.2141294 7.65135797,8.50514432 7.29237288,8.50514432 C6.93338779,8.50514432 6.64237288,8.2141294 6.64237288,7.85514432 C6.64237288,6.30703101 5.38705909,5.05171722 3.83894578,5.05171722 C2.29083247,5.05171722 1.03551868,6.30703101 1.03551868,7.85514432 C1.03551868,8.2141294 0.744503771,8.50514432 0.385518684,8.50514432 C0.0265335966,8.50514432 -0.264481316,8.2141294 -0.264481316,7.85514432 C-0.264481316,5.58906083 1.5728623,3.75171722 3.83894578,3.75171722 Z" id="Stroke-3"></path>
          </g>
        </g>
      </g>
    </g>
  </Icon>
)
TmEmployee.defaultProps = {
  size: 10
}

export default TmEmployee
