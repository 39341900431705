import { gql } from '@apollo/client';

export type QueryResult = {
  me: Me;
  client: Client;
  yearMasters: YearMaster[];
  defaultYear: number;
};

export type Me = {
  id: string;
  email: string;
  name?: string;
  hasMyNumberPermission: boolean;
  customerCode?: string;
  application: {
    id: string;
    name?: string;
  };
};

export type Client = {
  id: string;
  clientYearlies: ClientYearly[];
  clientYearly: CurrentClientYearly;
};

export type ClientYearly = {
  id: string;
  year: number;
  fixed: boolean;
  fixing: boolean;
};

export type CurrentClientYearly = ClientYearly & {
  syncResult: boolean;
  sendWithholdingSlip: boolean;
  submitPayrollReport: boolean;
  submitWithholdintSlipAndLegalRecord: boolean;
  clientSetting?: {
    id: string;
  };
  office?: Office;
};

export type Office = {
  id: string;
  name: string;
};

export type YearMaster = {
  id: string;
  eraCode: string;
  eraName: string;
  eraNumber: number;
  earnings: number;
  spouseEarnings: number;
  year: number;
};

export const FETCH_SESSION = gql`
  query session {
    me {
      id
      email
      name
      hasMyNumberPermission
      customerCode
      application {
        id
        name
      }
    }
    client {
      id
      clientYearlies {
        id
        year
        fixed
        fixing
      }
      clientYearly {
        id
        year
        fixed
        fixing
        syncResult
        sendWithholdingSlip
        submitPayrollReport
        submitWithholdintSlipAndLegalRecord
        clientSetting {
          id
        }
        office {
          id
          name
        }
      }
    }
    yearMasters {
      id
      eraCode
      eraName
      eraNumber
      earnings
      spouseEarnings
      year
    }
    defaultYear
  }
`;
