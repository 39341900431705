import { gql } from '@apollo/client';
import { ASYNC_TASK_FRAGMENT } from '@jbc-year-end-adj/2024/features/AsyncTask/query';
import { SearchParams } from '@jbc-year-end-adj/2024/features/SearchForm/types';

export type Variables = {
  input: BulkDeleteEmployeesInput;
  per?: number;
  page?: number;
  search?: SearchParams;
};

type BulkDeleteEmployeesInput = {
  allSelectMode: boolean;
  employeeIds: string[];
  exceptEmployeeIds: string[];
};

export const BULK_DELETE_EMPLOYEES = gql`
  mutation bulkDeleteEmployees($input: BulkDeleteEmployeesInput!) {
    bulkDeleteEmployees(input: $input) {
      clientYearly {
        id
      }
    }
  }
`;

export const BULK_DELETE_EMPLOYEES_ASYNC = gql`
  mutation bulkDeleteEmployeesAsync($input: BulkDeleteEmployeesAsyncInput!) {
    bulkDeleteEmployeesAsync(input: $input) {
      clientYearly {
        id
        asyncTask {
          ...AsyncTaskFields
        }
      }
    }
  }
  ${ASYNC_TASK_FRAGMENT}
`;
