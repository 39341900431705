import { gql } from '@apollo/client';

export type QueryResult = {
  client: {
    id: string;
    clientYearly: {
      id: string;
      allClientCities: ClientCity[];
    };
  };
};

export type ClientCity = {
  id: string;
  designationNumber?: string;
  totalCount: number;
  city: City;
};

type City = {
  id: string;
  code: string;
  name: string;
  prefecture: {
    id: string;
    name: string;
  };
};

export const FETCH_ALL_CLIENT_CITIES = gql`
  query fetchAllClientCities {
    client {
      id
      clientYearly {
        id
        allClientCities {
          id
          designationNumber
          totalCount
          city {
            id
            code
            name
            prefecture {
              id
              name
            }
          }
        }
      }
    }
  }
`;
