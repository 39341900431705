import { FC } from 'react';
import { Grid } from '../../../components/Grid';
import { Diff } from '../../../components/Diff';
import { displayFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { useResult } from '../ResultProvider';

export const Preview: FC = () => {
  const { employee } = useResult();
  const residentCard = employee.profile.residentCard;
  const residentCardAddress = residentCard ? `${residentCard.postcode0}-${residentCard.postcode1}\n${residentCard.fullAddress}` : undefined;

  const requestResidentCard = employee.request?.profile?.residentCard;
  const requestResidentCardAddress = requestResidentCard
    ? `${requestResidentCard.postcode0}-${requestResidentCard.postcode1}\n${requestResidentCard.fullAddress}`
    : undefined;
  return (
    <Grid>
      <Diff title="住民票住所" source={displayFormat(residentCardAddress)} input={displayFormat(requestResidentCardAddress)} />
    </Grid>
  );
};
