import { useState, FC } from 'react';
import { Loading } from 'jbc-front/components/presenters/ui/Loading';
import { useReport } from '../../hooks/useReport';
import { ReportName } from '../../types';
import { Viewer } from './Viewer';
import { useGenerateReportNameMap } from 'features/reports/hooks/useGenerateReportNameMap';

// @ts-ignore
import { toBlob } from 'utils/url';
// @ts-ignore
import { saveAs } from 'file-saver';

export type ViewerProps = {
  employeeId: string;
  year: number;
  reportName: ReportName;
  hasMyNumber?: boolean;
  hidePrint?: boolean;
  printDisabledReason?: string;
  onClickEdit?: () => void;
  onClose: () => void;
};

export const ReportViewer: FC<ViewerProps> = ({
  employeeId,
  year,
  reportName,
  hasMyNumber = false,
  hidePrint = false,
  printDisabledReason,
  onClickEdit,
  onClose
}) => {
  const [showMyNumber, setShowMyNumber] = useState<boolean>(false);
  const { reportMap } = useGenerateReportNameMap(year);

  const { report, url, refetch, loading } = useReport(reportName, employeeId, year, showMyNumber);
  const title = reportMap[reportName];

  const handleClickShowMyNumber = hasMyNumber
    ? async () => {
        refetch({
          employeeId,
          showMyNumber: !showMyNumber,
          year
        });
        setShowMyNumber(!showMyNumber);
      }
    : undefined;

  const handleClickDownload = () => {
    saveAs(toBlob(report, 'application/pdf'), `${title}.pdf`);
  };

  return (
    <Viewer>
      <Viewer.Header
        onClose={onClose}
        reportUrl={url}
        title={title}
        onClickEdit={onClickEdit}
        onClickShowMyNumber={handleClickShowMyNumber}
        hidePrint={hidePrint}
        printDisabledReason={printDisabledReason}
        onClickDownload={handleClickDownload}
      />
      {loading ? <Loading /> : <Viewer.Pdf report={report} />}
    </Viewer>
  );
};
