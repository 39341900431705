import { FC, useState } from 'react';
import Button from 'jbc-front/components/Button';
import { Modal } from 'jbc-front/components/presenters/ui/Modal';
import { Select } from 'jbc-front/components/presenters/form/Select';
import { ButtonRow } from 'jbc-front/components/presenters/layout/ButtonRow';

import { useNotify } from '@jbc-year-end-adj/2024/hooks/useNotify';
import { useAdminMutation as useMutation } from '@jbc-year-end-adj/2024/hooks/graphql/useMutation';
import { useSession } from '@jbc-year-end-adj/2024/features/AdminSessionProvider';
import { TaskRunnningProps } from '@jbc-year-end-adj/2024/features/AsyncTask';
import { FloatingButton } from '@jbc-year-end-adj/2024/components/ui/FloatingButton';

// @ts-ignore
import { convertSelectedIds } from '../../SelectList/useSelectEmployee';
import { STATUSES } from '../../../consts';
import { UPDATE_STATUS, UPDATE_STATUS_ASYNC, FailureEmployee } from './mutation';
import styles from './BulkStatusUpdateModal.scss';

const MAX_CHANGE_STATUS_COUNT = 100;

const STATUS_OPTIONS = [
  {
    label: '入力済（または 修正済）',
    value: 'applying'
  },
  {
    label: '完了（または 対象外）',
    value: 'accepted'
  }
];

type Status = 'applying' | 'accepted';

type BulkStatusUpdateModalProps = {
  selectedTotalCount: number;
  allSelectMode: boolean;
  selected: { [key: string]: { name: string; status: unknown } };
  taskRunningProps: TaskRunnningProps;
  isDisabledSubmit: () => boolean;
  reset: () => void;
  onChangeMode: () => void;
};

export const BulkStatusUpdateModal: FC<BulkStatusUpdateModalProps> = ({
  selectedTotalCount,
  allSelectMode,
  selected,
  isDisabledSubmit,
  taskRunningProps,
  reset,
  onChangeMode
}) => {
  const { isCurrentYear } = useSession();
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [status, setStatus] = useState<Status>();
  const [failureEmployees, setFailureEmployees] = useState<FailureEmployee[]>([]);
  const [updateStatusAsync, { loading: loadingUpdateStatusAsync }] = useMutation(UPDATE_STATUS_ASYNC, {
    refetchQueries: ['employees']
  });
  const [updateStatus, { loading: loadingUpdateStatus }] = useMutation(UPDATE_STATUS, {
    refetchQueries: ['employees']
  });
  const notify = useNotify();

  const handleSubmit = async () => {
    if (selectedTotalCount > MAX_CHANGE_STATUS_COUNT) {
      await handleAsyncSubmit();
    } else {
      await handleSyncSubmit();
    }
  };

  const handleAsyncSubmit = async () => {
    const { employeeIds, exceptEmployeeIds } = convertSelectedIds(allSelectMode, Object.keys(selected));
    await updateStatusAsync({
      variables: {
        input: {
          allSelectMode,
          employeeIds,
          exceptEmployeeIds,
          status
        }
      }
    });

    notify('ステータスの更新を開始しました', 'success');
    setIsConfirm(false);
    setStatus(undefined);
    reset();
    setIsOpen(false);
  };

  const handleSyncSubmit = async () => {
    const { employeeIds, exceptEmployeeIds } = convertSelectedIds(allSelectMode, Object.keys(selected));
    const result = await updateStatus({
      variables: {
        input: {
          allSelectMode,
          employeeIds,
          exceptEmployeeIds,
          status
        }
      }
    });

    if (result.data.updateStatus.failureEmployees.length > 0) {
      setFailureEmployees(result.data.updateStatus.failureEmployees);
    } else {
      notify('ステータスを変更しました', 'success');
      setIsConfirm(false);
      setStatus(undefined);
      reset();
      setIsOpen(false);
    }
  };

  const handleCancel = () => {
    setFailureEmployees([]);
    setIsConfirm(false);
    setStatus(undefined);
    reset();
    setIsOpen(false);
  };

  return (
    <>
      <FloatingButton>
        <Button
          disabled={!isCurrentYear || isDisabledSubmit() || loadingUpdateStatus || loadingUpdateStatusAsync || taskRunningProps.disabled}
          disabledReason={taskRunningProps.disabledReason}
          onClick={() => setIsOpen(true)}
          primary
        >
          一括変更する
        </Button>
        <Button onClick={onChangeMode}>キャンセル</Button>
      </FloatingButton>

      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <Modal.Header onClose={() => setIsOpen(false)}>ステータス一括変更</Modal.Header>
        <Modal.Body className={styles.modalBody}>
          <Body
            failureEmployees={failureEmployees}
            status={status}
            onChangeStatus={(status: Status) => setStatus(status)}
            isConfirm={isConfirm}
          />
        </Modal.Body>
        <Modal.Footer>
          <ButtonRow>
            {failureEmployees.length > 0 ? (
              <Button onClick={handleCancel}>閉じる</Button>
            ) : (
              <>
                <Button
                  primary
                  disabled={status == null || !isCurrentYear || isDisabledSubmit() || loadingUpdateStatus || loadingUpdateStatusAsync}
                  onClick={isConfirm ? handleSubmit : () => setIsConfirm(true)}
                >
                  一括変更する
                </Button>
                <Button onClick={handleCancel}>キャンセル</Button>
              </>
            )}
          </ButtonRow>
        </Modal.Footer>
      </Modal>
    </>
  );
};

type changeOptionHandler = (option: { value: string; label: string }) => void;

type BodyProps = {
  failureEmployees: FailureEmployee[];
  status?: Status;
  onChangeStatus: (status: Status) => void;
  isConfirm: boolean;
};

const Body: FC<BodyProps> = ({ failureEmployees, isConfirm, onChangeStatus, status }) => {
  const handleChange: changeOptionHandler = option => {
    onChangeStatus(option.value as Status);
  };

  if (failureEmployees.length > 0) {
    return (
      <div>
        <p>以下の従業員のステータス更新に失敗しました</p>
        <p className={styles.warning}>従業員情報の必須項目が全て入力されているかご確認ください。</p>
        <br />
        <p>
          変更予定のステータス：<span className={styles.warning}>{STATUS_OPTIONS.find(option => option.value === status)?.label}</span>
        </p>
        <br />
        <ul>
          {failureEmployees.map(failureEmployee => {
            const {
              id,
              staffCode,
              status,
              profile: { fullName }
            } = failureEmployee;
            return <li key={id}>{`${staffCode} ${fullName}（${STATUSES[status]}）`}</li>;
          })}
        </ul>
      </div>
    );
  }

  if (!isConfirm) {
    return (
      <div className={styles.modalContent}>
        <p>選択された従業員のステータスを以下に変更します。</p>
        <div className={styles.annotation}>
          <p>※「再依頼中」の従業員は「入力済」ではなく「修正済」に変更となります。</p>
          <p>※年末調整の対象外に該当する従業員は「完了」ではなく「対象外」となります。</p>
        </div>
        <Select onChange={handleChange} options={STATUS_OPTIONS} />
      </div>
    );
  } else {
    return (
      <p>
        「依頼中」「再依頼中」から「入力済（修正済）」「完了（対象外）」のステータスに変更する場合は、
        <br />
        <span className={styles.warning}>提出が完了となり従業員が入力できなくなります。</span>
        <br />
        ステータスを一括変更してもよろしいですか？
        <br />
        <br />
        変更後のステータス「{STATUS_OPTIONS.find(option => option.value === status)?.label}」
      </p>
    );
  }
};
