import React from 'react';
import { gql } from '@apollo/client';
import _ from 'lodash';
import fp from 'lodash/fp';
import Button from 'jbc-front/components/Button';
import BulkChangeMethod from './BulkChangeMethod';
import { useNotify } from '../actions';
import { useBeforeUnload, useMutation, useSession, useYear, ASYNC_TASK_FRAGMENT } from '../components';
import FloatingButton from '../components/FloatingButton';
import { initialState } from '../pages/DifferenceApply';
import styles from '../pages/DifferenceApply.scss';

export const MAX_UPDATE_COUNT = 100;

const APPLY_DIFFERENCES_TO_PAYROLL = gql`
  mutation applyDifferencesToPayroll($input: ApplyDifferencesToPayrollInput!) {
    applyDifferencesToPayroll(input: $input) {
      clientYearly {
        id
        asyncTask {
          ...AsyncTaskFields
        }
      }
    }
  }
  ${ASYNC_TASK_FRAGMENT}
`;

const BULK_UPDATE_DIFFERENCE_APPLY_METHODS = gql`
  mutation bulkUpdateDifferenceApplyMethods($input: BulkUpdateDifferenceApplyMethodsInput!) {
    bulkUpdateDifferenceApplyMethods(input: $input) {
      employees {
        id
        differenceApplyMethod
      }
    }
  }
`;

const BULK_UPDATE_DIFFERENCE_APPLY_METHODS_ASYNC = gql`
  mutation bulkUpdateDifferenceApplyMethodsAsync($input: BulkUpdateDifferenceApplyMethodsAsyncInput!) {
    bulkUpdateDifferenceApplyMethodsAsync(input: $input) {
      clientYearly {
        id
        asyncTask {
          ...AsyncTaskFields
        }
      }
    }
  }
  ${ASYNC_TASK_FRAGMENT}
`;

const convertMessage = selectedTotalCount => (selectedTotalCount > MAX_UPDATE_COUNT ? '反映方法一括変更を開始しました' : '保存しました');

const BeforeUnload = () => {
  useBeforeUnload('このサイトを再読み込みしますか？行った変更が保存されない可能性があります。');
  return null;
};

const ButtonArea = ({ onChangeState, applyDifferencesToPayrollMutation, loading, taskRunningProps }) => {
  const year = useYear();
  const { isCurrentYear } = useSession();

  return (
    <>
      {!isCurrentYear && <p className={styles.errorMessage}>※過去の年末調整結果は反映できません</p>}
      <Button disabled={loading} {...taskRunningProps} onClick={() => onChangeState(prevState => ({ ...prevState, editing: true }))}>
        反映方法を編集する
      </Button>
      <Button
        style={{ whiteSpace: 'nowrap' }}
        primary
        disabled={!isCurrentYear || loading}
        {...taskRunningProps}
        onClick={async () => {
          await applyDifferencesToPayrollMutation({ variables: { input: { year } } });
        }}
      >
        ジョブカン給与計算へ反映
      </Button>
    </>
  );
};

const EditingButtonArea = ({
  state,
  onChangeState,
  selected,
  allSelectMode,
  selectedTotalCount,
  isDisabledSubmit,
  bulkUpdateMutation,
  bulkUpdateAsyncMutation,
  taskRunningProps
}) => {
  const year = useYear();
  const notify = useNotify();
  const handleBulkUpdate = async changes => {
    const bulkUpdate = selectedTotalCount > MAX_UPDATE_COUNT ? bulkUpdateAsyncMutation : bulkUpdateMutation;
    await bulkUpdate({
      variables: {
        input: { year, changes: _.map(changes, (value, id) => ({ id, ...value })) }
      }
    });
  };
  const successMessage = convertMessage(selectedTotalCount);

  return (
    <>
      <BeforeUnload />
      <Button disabled={false} onClick={() => onChangeState(initialState)}>
        キャンセル
      </Button>
      <Button
        primary
        disabled={isDisabledSubmit() || fp.isEmpty(state.changes)}
        disabledReason={'ジョブカン給与計算反映方法を選択してください'}
        {...taskRunningProps}
        onClick={async () => {
          const { changes } = state;
          await handleBulkUpdate(changes);
          onChangeState(initialState);
          notify(successMessage);
        }}
      >
        保存する
      </Button>
      <BulkChangeMethod
        selected={selected}
        allSelectMode={allSelectMode}
        selectedTotalCount={selectedTotalCount}
        isDisabledSubmit={isDisabledSubmit}
        state={state}
        setState={onChangeState}
        handleBulkUpdate={handleBulkUpdate}
        successMessage={successMessage}
        taskRunningProps={taskRunningProps}
      />
    </>
  );
};

const FloatingButtonArea = ({ state, onChangeState, selected, allSelectMode, selectedTotalCount, isDisabledSubmit, taskRunningProps }) => {
  const [bulkUpdateDifferenceApplyMethods, { loading: loadingUpdateDifferenceApplyMethods }] = useMutation(
    BULK_UPDATE_DIFFERENCE_APPLY_METHODS
  );
  const [bulkUpdateDifferenceApplyMethodsAsync, { loading: loadingUpdateDifferenceApplyMethodsAsync }] = useMutation(
    BULK_UPDATE_DIFFERENCE_APPLY_METHODS_ASYNC
  );
  const [applyDifferencesToPayroll, { loading: loadingApplyDifferencesToPayroll }] = useMutation(APPLY_DIFFERENCES_TO_PAYROLL);
  const loading = loadingUpdateDifferenceApplyMethods || loadingUpdateDifferenceApplyMethodsAsync || loadingApplyDifferencesToPayroll;

  return (
    <FloatingButton>
      {state.editing ? (
        <EditingButtonArea
          state={state}
          onChangeState={onChangeState}
          selected={selected}
          allSelectMode={allSelectMode}
          selectedTotalCount={selectedTotalCount}
          isDisabledSubmit={isDisabledSubmit}
          bulkUpdateMutation={bulkUpdateDifferenceApplyMethods}
          bulkUpdateAsyncMutation={bulkUpdateDifferenceApplyMethodsAsync}
          taskRunningProps={taskRunningProps}
        />
      ) : (
        <ButtonArea
          onChangeState={onChangeState}
          applyDifferencesToPayrollMutation={applyDifferencesToPayroll}
          loading={loading}
          taskRunningProps={taskRunningProps}
        />
      )}
    </FloatingButton>
  );
};

export default FloatingButtonArea;
