import React from 'react';
import Button from 'jbc-front/components/Button';
import { onSubmitFail } from 'jbc-front/components/FormErrors';
import { useMutation, useQuery } from '../components/Graphql';
import { gql } from '@apollo/client';
import { TextAreaField, FileField, Section } from 'jbc-front/components/Form';
import Hint from 'jbc-front/components/Hint';
import { toFormValues } from '../utils/form';
import { reduxForm, useFormValue, useAutofill } from '../components/FormName';
import { Panel, Headding, Item, Lead, Notes, Buttons, Line } from '../components/PrimaryPanel';
import LoadingPage from '../components/LoadingPage';
import useReactRouter from 'use-react-router';
import { CustomDescription } from '../components/QuestionDescription';
import FilePreview from '../components/FilePreview';
import styles from './WorkingStudent.scss';

export const workingStudentClassifications = [
  { value: 'none', label: '対象外' },
  { value: 'working_student', label: '勤労学生' }
];

export const WORKING_STUDENT_FRAGMENT = gql`
  fragment WorkingStudent on Profile {
    id
    workingStudentClassification {
      id
      classification
      detail
      image {
        url
        filename
      }
    }
  }
`;

const STUDENT_ID_CARD = gql`
  query studentIdCard {
    clientSetting {
      id
      requestImageUploadSettings {
        studentIdCard
      }
    }
  }
`;

const WORKING_STUDENT = gql`
  query workingStudent {
    request {
      id
      profile {
        ...WorkingStudent
      }
    }
  }
  ${WORKING_STUDENT_FRAGMENT}
`;

const ANSWER_WORKING_STUDENT = gql`
  mutation answerWorkingStudent($input: AnswerWorkingStudentInput!) {
    answerWorkingStudent(input: $input) {
      questions {
        id
        status
      }
      profile {
        ...WorkingStudent
      }
    }
  }
  ${WORKING_STUDENT_FRAGMENT}
`;

const QuestionForm =
  (({ handleSubmit, submitting }) => {
    const { data } = useQuery(STUDENT_ID_CARD);
    const studentIdCardImageSetting = data?.clientSetting.requestImageUploadSettings.studentIdCard;
    const [answer, { loading }] = useMutation(ANSWER_WORKING_STUDENT);
    const classification = useFormValue('classification');
    const { autofill } = useAutofill();
    const { history } = useReactRouter();
    return (
      <Panel>
        <Headding className={styles.primaryPanelHeader}>勤労学生情報</Headding>
        <Item>
          <Lead>あなたは以下の条件を満たしている、勤労学生ですか？</Lead>
          <CustomDescription field="under_note" />
          <Notes>
            <ul>
              <li>
                ・今年の合計所得金額75万円以下　※給与収入のみの場合、給与収入が130万円以下
                <Hint
                  text={
                    <div>
                      <div>
                        「合計所得金額」とは、各種損失の繰越控除を適用する前の総所得金額、譲渡所得、配当所得、雑所得、山林所得、退職所得の合計額をいいます。
                      </div>
                      <p>「特定の学校」とは、次のいずれかの学校です。</p>
                      <ul className="m-list-notes">
                        <li>イ 学校教育法に規定する小学校、中学校、高等学校、大学、高等専門学校など</li>
                        <li>ロ 国、地方公共団体、学校法人等により設置された専修学校又は各種学校のうち一定の課程を履修させるもの</li>
                        <li>ハ 職業能力開発促進法の規定による認定職業訓練を行う職業訓練法人で一定の課程を履修させるもの</li>
                      </ul>
                    </div>
                  }
                />
              </li>
              <li>・今年の給与所得以外の所得が10万円以下</li>
              <li>・特定の学校の学生、生徒であること</li>
            </ul>
          </Notes>
          <Buttons>
            <Button
              huge
              secondary
              disabled={loading}
              primary={classification === 'working_student'}
              onClick={() => autofill('classification', 'working_student')}
            >
              はい
            </Button>
            <Button
              huge
              secondary
              disabled={loading}
              primary={classification === 'none'}
              onClick={async () => {
                await answer({ variables: { input: { classification: 'none' } } });
                history.push('/employee_input');
              }}
            >
              いいえ
            </Button>
          </Buttons>

          {classification !== 'none' && (
            <React.Fragment>
              <Line />
              <Lead>以下の情報の記入が必要です。</Lead>
              <Section title="勤労学生区分" style={{ maxWidth: '760px' }}>
                <TextAreaField name="detail" label="勤労学生詳細情報" description="学校名と入学年月日を記入してください。" />
                {studentIdCardImageSetting && studentIdCardImageSetting !== 'none' && (
                  <FileField
                    name="image"
                    required={studentIdCardImageSetting === 'required'}
                    label="学生手帳画像"
                    preview={FilePreview}
                    disablePreview={false}
                  />
                )}
              </Section>
              <Buttons>
                <Button primary onClick={handleSubmit} disabled={submitting}>
                  次ヘ
                </Button>
              </Buttons>
            </React.Fragment>
          )}
        </Item>
      </Panel>
    );
  }) |> reduxForm({ form: 'workingStudent', onSubmitFail });

const Question = () => {
  const { loading, data } = useQuery(WORKING_STUDENT);
  const [answer] = useMutation(ANSWER_WORKING_STUDENT);
  const { history } = useReactRouter();
  if (loading) return <LoadingPage />;
  return (
    <QuestionForm
      initialValues={toFormValues(data.request.profile.workingStudentClassification || { classification: 'none' }, true)}
      onSubmit={async values => {
        await answer({ variables: { input: values } });
        history.push('/employee_input');
      }}
    />
  );
};

export default Question;
