import { FC, useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler } from 'react-hook-form';

import Button from 'jbc-front/components/Button';
import ActionButton from 'jbc-front/components/ActionButton';
import { Map } from 'jbc-front/components/icons';
import { ButtonRow } from 'jbc-front/components/presenters/layout/ButtonRow';

import { FormSection } from 'components/form/FormSection';
import { Section } from 'components/ui/Section';
import { Radio } from 'components/form/Radio';

import { useAddress, Address } from 'hooks/useAddress';
import { useYear } from 'hooks/useYear';

import { Grid } from '../../../../components/Grid';
import { FormSeparator } from '../../../../components/FormSeparator';
import { FloatingButton } from '../../../../components/FloatingButton';
import { FETCH_EMPLOYEE } from '../../../../query';
import { useEmployeeInfo } from '../../EmployeeInfoProvider';
import { Schema, schema, generateDefaultValues } from './schema';
import { UPDATE_RESIDENT_CARD, convertFormDataToQueryVariables } from './mutation';
import { CitySearchModal } from './CitySearchModal';
import styles from './Form.scss';

// @ts-ignore
import { useNotify } from 'actions';

// @ts-ignore
import { useMutation } from 'components/Graphql';
import { Input } from 'components/form/Input';

type FormProps = {
  onCancel: () => void;
};

export const Form: FC<FormProps> = ({ onCancel }) => {
  const { employee, employeeAddressClassification } = useEmployeeInfo();
  const year = useYear();
  const { fetchAddress, address } = useAddress();
  const addressClass = employeeAddressClassification === 'profile' ? employee.profile : employee.profile.residentCard;

  useEffect(() => {
    if (addressClass) {
      fetchAddress({
        variables: {
          postcode: Number(`${addressClass.postcode0}${addressClass.postcode1}`),
          year
        }
      });
    }
  }, [addressClass]);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [update, { loading }] = useMutation(UPDATE_RESIDENT_CARD);
  const notify = useNotify();

  const { handleSubmit, register, watch, setValue } = useForm<Schema>({
    defaultValues: generateDefaultValues(employee),
    resolver: yupResolver(schema),
    mode: 'onBlur'
  });

  const onSubmit: SubmitHandler<Schema> = async (data: Schema) => {
    const variables = convertFormDataToQueryVariables(String(employee.id), data);
    await update({
      variables,
      refetchQueries: [FETCH_EMPLOYEE]
    });
    onCancel();
    notify('住民票住所を保存しました。', 'success');
  };

  const handleSelect = (code?: string, name?: string) => {
    setValue('code', code);
    setValue('name', name);
  };

  const setCityRcd = (cityRcd?: Address['cityRcd']) => {
    if (cityRcd) {
      handleSelect(cityRcd.code, cityRcd.name);
    } else {
      handleSelect(undefined, undefined);
    }
  };

  const code = watch('code');
  const name = watch('name');

  useEffect(() => {
    if (!name && !code) {
      setCityRcd(address?.cityRcd);
    }
  }, [address]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormSection>
        <Section>
          <Section.Header>
            <div className={styles.header}>
              給与支払報告書提出先
              <ActionButton icon={<Map size={13} />} onClick={() => setIsModalOpen(true)}>
                市区町村を選択
              </ActionButton>
            </div>
          </Section.Header>
          <Section.Body>
            <FormSection>
              <Grid>
                <ActionButton className={styles.setCityRcd} onClick={() => setCityRcd(address?.cityRcd)}>
                  自動判定に戻す
                </ActionButton>
              </Grid>

              <Grid>
                <Input label="市区町村コード" disabled value={code} />
                <Input label="市区町村名" disabled value={name} />
              </Grid>
            </FormSection>
          </Section.Body>
        </Section>

        <Radio.Container label="住民税徴収方法" required>
          <Radio value="from_salary" {...register('residentsTaxCollectionMethod')}>
            特別徴収
          </Radio>
          <Radio value="normally_for_resign" {...register('residentsTaxCollectionMethod')}>
            普通徴収（退職）
          </Radio>
          <Radio value="normally_others" {...register('residentsTaxCollectionMethod')}>
            普通徴収（その他）
          </Radio>
        </Radio.Container>

        <FormSeparator />

        <FloatingButton>
          <ButtonRow>
            <Button onClick={onCancel} disabled={loading}>
              キャンセル
            </Button>
            <Button primary onClick={handleSubmit(onSubmit)} disabled={loading}>
              保存
            </Button>
          </ButtonRow>
        </FloatingButton>
      </FormSection>

      <CitySearchModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onSelect={handleSelect} />
    </form>
  );
};
