import React from 'react'
import Icon from 'react-icon-base'

const Delete = props => (
  <Icon viewBox="0 0 14 14" {...props}>
    <path fill='currentColor' fillRule='evenodd' d='M5.2 10.6h1.2V5.8H5.2v4.8zm2.4 0h1.2V5.8H7.6v4.8zM4 11.8h6V4.6H4v7.2zm1.2-8.4h3.6V2.2H5.2v1.2zm4.8 0V1H4v2.4H1v1.2h1.8V13h8.4V4.6H13V3.4h-3z' />
  </Icon>
)

Delete.defaultProps = {
  size: 14
}

export default Delete
