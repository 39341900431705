import { FC } from 'react';
import { PreviewSubSection } from '../../../../components/PreviewSubSection';
import { Grid } from '../../../../components/Grid';
import { Diff } from '../../../../components/Diff';
import { Income as IncomeType } from '../../../../query';
import { Section } from 'components/ui/Section';
import { amountFormat } from '@jbc-year-end-adj/common/utils/formatter';
import styles from './Income.scss';

// @ts-ignore
import { salaryEarnings as calcSalaryEarnings, calcTotalSalaryIncome } from 'utils/income';

type IncomeProps = {
  income?: IncomeType;
  requestIncome?: IncomeType;
  year?: number;
};

export const Income: FC<IncomeProps> = ({ income, requestIncome, year }) => {
  const salaryEarnings = calcSalaryEarnings(income?.salaryIncome, year);
  const requestSalaryEarnings = calcSalaryEarnings(requestIncome?.salaryIncome, year);

  return (
    <div className={styles.container}>
      <Grid>
        <Diff
          className={styles.fontBolder}
          title="合計所得金額"
          source={amountFormat(income?.totalEarnings)}
          input={amountFormat(requestIncome?.totalEarnings)}
        />
      </Grid>

      <Section>
        <Section.Header>扶養親族の給与収入</Section.Header>
        <Section.Body>
          <Grid colGap>
            <PreviewSubSection>
              <PreviewSubSection.Header>給与所得</PreviewSubSection.Header>
              <PreviewSubSection.Body>
                <Diff title="収入金額" source={amountFormat(income?.salaryIncome)} input={amountFormat(requestIncome?.salaryIncome)} />
                <Diff
                  className={styles.fontBolder}
                  title="所得金額"
                  source={amountFormat(salaryEarnings)}
                  input={amountFormat(requestSalaryEarnings)}
                />
              </PreviewSubSection.Body>
            </PreviewSubSection>
          </Grid>
        </Section.Body>
      </Section>

      <Section>
        <Section.Header>扶養親族の給与収入以外の収入</Section.Header>
        <Section.Body>
          <Grid colGap>
            <PreviewSubSection>
              <PreviewSubSection.Header>事業所得</PreviewSubSection.Header>
              <PreviewSubSection.Body>
                <Diff title="収入金額" source={amountFormat(income?.businessIncome)} input={amountFormat(requestIncome?.businessIncome)} />
                <Diff
                  title="必要経費"
                  source={amountFormat(income?.businessExpense)}
                  input={amountFormat(requestIncome?.businessExpense)}
                />
                <Diff
                  className={styles.fontBolder}
                  title="所得金額"
                  source={amountFormat(income?.businessEarnings)}
                  input={amountFormat(requestIncome?.businessEarnings)}
                />
              </PreviewSubSection.Body>
            </PreviewSubSection>

            <PreviewSubSection>
              <PreviewSubSection.Header>雑所得</PreviewSubSection.Header>
              <PreviewSubSection.Body>
                <Diff
                  title="収入金額（公的年金等）"
                  source={amountFormat(income?.miscellaneousIncomeOfficalPension)}
                  input={amountFormat(requestIncome?.miscellaneousIncomeOfficalPension)}
                />
                <Diff
                  title="収入金額（公的年金等以外）"
                  source={amountFormat(income?.miscellaneousIncomeOther)}
                  input={amountFormat(requestIncome?.miscellaneousIncomeOther)}
                />
                <Diff
                  title="必要経費"
                  source={amountFormat(income?.miscellaneousExpense)}
                  input={amountFormat(requestIncome?.miscellaneousExpense)}
                />
                <Diff
                  className={styles.fontBolder}
                  title="所得金額"
                  source={amountFormat(income?.miscellaneousEarnings)}
                  input={amountFormat(requestIncome?.miscellaneousEarnings)}
                />
              </PreviewSubSection.Body>
            </PreviewSubSection>

            <PreviewSubSection>
              <PreviewSubSection.Header>配当所得</PreviewSubSection.Header>
              <PreviewSubSection.Body>
                <Diff title="収入金額" source={amountFormat(income?.dividendIncome)} input={amountFormat(requestIncome?.dividendIncome)} />
                <Diff
                  title="必要経費"
                  source={amountFormat(income?.dividendExpense)}
                  input={amountFormat(requestIncome?.dividendExpense)}
                />
                <Diff
                  className={styles.fontBolder}
                  title="所得金額"
                  source={amountFormat(income?.dividendEarnings)}
                  input={amountFormat(requestIncome?.dividendEarnings)}
                />
              </PreviewSubSection.Body>
            </PreviewSubSection>

            <PreviewSubSection>
              <PreviewSubSection.Header>不動産所得</PreviewSubSection.Header>
              <PreviewSubSection.Body>
                <Diff
                  title="収入金額"
                  source={amountFormat(income?.realEstateIncome)}
                  input={amountFormat(requestIncome?.realEstateIncome)}
                />
                <Diff
                  title="必要経費"
                  source={amountFormat(income?.realEstateExpense)}
                  input={amountFormat(requestIncome?.realEstateExpense)}
                />
                <Diff
                  className={styles.fontBolder}
                  title="所得金額"
                  source={amountFormat(income?.realEstateEarnings)}
                  input={amountFormat(requestIncome?.realEstateEarnings)}
                />
              </PreviewSubSection.Body>
            </PreviewSubSection>

            <PreviewSubSection>
              <PreviewSubSection.Header>退職所得</PreviewSubSection.Header>
              <PreviewSubSection.Body>
                <Diff
                  title="収入金額"
                  source={amountFormat(income?.retirementIncome)}
                  input={amountFormat(requestIncome?.retirementIncome)}
                />
                <Diff title="勤続年数" source={income?.lengthOfService} input={requestIncome?.lengthOfService} />
                <Diff
                  title="障害者になったことに直接基因して退職した"
                  source={income?.retireForDisablity ? 'はい' : 'いいえ'}
                  input={requestIncome?.retireForDisablity ? 'はい' : 'いいえ'}
                />
                <Diff
                  title="退職手当等が特定役員退職手当等に該当する"
                  source={income?.isOfficerRetirementAllowance ? 'はい' : 'いいえ'}
                  input={requestIncome?.isOfficerRetirementAllowance ? 'はい' : 'いいえ'}
                />
                <Diff
                  title="控除額"
                  source={amountFormat(income?.retirementIncomeDeductionAmount)}
                  input={amountFormat(requestIncome?.retirementIncomeDeductionAmount)}
                />
                <Diff
                  className={styles.fontBolder}
                  title="所得金額"
                  source={amountFormat(income?.retirementEarnings)}
                  input={amountFormat(requestIncome?.retirementEarnings)}
                />
              </PreviewSubSection.Body>
            </PreviewSubSection>

            <PreviewSubSection>
              <PreviewSubSection.Header>その他所得</PreviewSubSection.Header>
              <PreviewSubSection.Body>
                <Diff title="収入金額" source={amountFormat(income?.otherIncome)} input={amountFormat(requestIncome?.otherIncome)} />
                <Diff title="必要経費" source={amountFormat(income?.otherExpense)} input={amountFormat(requestIncome?.otherExpense)} />
                <Diff
                  title="必要経費 うち特別控除額"
                  source={amountFormat(income?.otherExpenseSpecialDeduction)}
                  input={amountFormat(requestIncome?.otherExpenseSpecialDeduction)}
                />
                <Diff
                  className={styles.fontBolder}
                  title="所得金額"
                  source={amountFormat(income?.otherEarings)}
                  input={amountFormat(requestIncome?.otherEarings)}
                />
              </PreviewSubSection.Body>
            </PreviewSubSection>
          </Grid>
        </Section.Body>
      </Section>
    </div>
  );
};
