import { gql } from '@apollo/client';
import { ASYNC_TASK_FRAGMENT } from '@jbc-year-end-adj/2024/features/AsyncTask/query';

export const REQUEST = gql`
  mutation request($input: RequestInput!) {
    request(input: $input) {
      employees {
        id
        status
      }
    }
  }
`;

export const REQUEST_ASYNC = gql`
  mutation requestAsync($input: RequestAsyncInput!) {
    requestAsync(input: $input) {
      clientYearly {
        id
        asyncTask {
          ...AsyncTaskFields
        }
      }
    }
  }
  ${ASYNC_TASK_FRAGMENT}
`;
