import React from 'react'
import Icon from 'react-icon-base'

const StressCheckLine = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="iconStressCheckLine" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path d="M36,0 C37.1045695,0 38,0.8954305 38,2 L38,38 C38,39.1045695 37.1045695,40 36,40 L4,40 C2.8954305,40 2,39.1045695 2,38 L2,2 C2,0.8954305 2.8954305,0 4,0 L36,0 Z M35.5,2 L4.5,2 C4.22385763,2 4,2.22385763 4,2.5 L4,37.5 C4,37.7761424 4.22385763,38 4.5,38 L35.5,38 C35.7761424,38 36,37.7761424 36,37.5 L36,2.5 C36,2.22385763 35.7761424,2 35.5,2 Z M9,7 L31,7 C31.5522847,7 32,7.44771525 32,8 C32,8.55228475 31.5522847,9 31,9 L9,9 C8.44771525,9 8,8.55228475 8,8 C8,7.44771525 8.44771525,7 9,7 Z M9,13 L31,13 C31.5522847,13 32,13.4477153 32,14 C32,14.5522847 31.5522847,15 31,15 L9,15 C8.44771525,15 8,14.5522847 8,14 C8,13.4477153 8.44771525,13 9,13 Z M30.2487452,24.4941412 C30.5766219,24.8845182 30.5550742,25.4520177 30.2171901,25.8163897 L30.1261479,25.9030308 L22.383467,32.4060902 C21.9436877,32.7754599 21.3101126,32.7782672 20.86871,32.4296238 L20.7704349,32.3429358 L16.7719743,28.4121377 C16.3781319,28.0249599 16.3727291,27.391818 16.7599068,26.9979756 C17.1173017,26.6344288 17.6842755,26.6018601 18.0791551,26.9035265 L18.1740689,26.9859082 L21.654,30.406 L28.8398556,24.3715439 C29.262764,24.0163441 29.8935454,24.0712328 30.2487452,24.4941412 Z M31,19 C31.5522847,19 32,19.4477153 32,20 C32,20.5522847 31.5522847,21 31,21 L9,21 C8.44771525,21 8,20.5522847 8,20 C8,19.4477153 8.44771525,19 9,19 L31,19 Z" id="Rectangle" fill={props.color} fillRule="nonzero"></path>
    </g>
  </Icon>
)

StressCheckLine.defaultProps = {
  size: 40,
  color: "#3498DB"
}

export default StressCheckLine
