import { FC, useState, useEffect, useRef } from 'react';
import styles from './SideContent.scss';
import { Employee, RequestComment } from '../../query';
import { dateFormat, fullNameFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { AdminUser, Employee as EmployeeIcon } from 'jbc-front/components/icons';
import classnames from 'classnames';

type TabType = 'comment';

type SideContentProps = {
  currentTab: TabType;
  employee: Employee;
};

export const SideContent: FC<SideContentProps> = ({ currentTab, employee }) => {
  const [current, setCurrent] = useState<TabType>(currentTab);

  return (
    <div className={styles.sideContent}>
      <div className={styles.tabContainer}>
        <div className={classnames(current === 'comment' ? styles.activeTab : styles.tab)} onClick={() => setCurrent('comment')}>
          コメント
        </div>
      </div>
      {current === 'comment' && <CommentList employee={employee} />}
    </div>
  );
};

type CommentListProps = {
  employee: Employee;
};

const CommentList: FC<CommentListProps> = ({ employee }) => {
  const comments = (employee.request?.requestComments || []).slice().reverse();
  const commentListRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (commentListRef.current) {
      commentListRef.current.scrollTop = commentListRef.current.scrollHeight;
    }
  }, [comments]);
  return (
    <div className={styles.commentList} ref={commentListRef}>
      {comments.length === 0 && <div className={classnames('u-ta-c', styles.comment)}>コメントはありません</div>}
      {comments.map(comment => (
        <Comment key={comment.id} employee={employee} comment={comment} />
      ))}
    </div>
  );
};

type CommentProps = {
  employee: Employee;
  comment: RequestComment;
};

const Comment: FC<CommentProps> = ({ employee, comment }) => {
  const { lastName, firstName } = employee.profile;
  const commentByAdmin = comment.commentBy === 'admin';
  const name = (() => {
    if (commentByAdmin) {
      const adminName = comment.actedUser?.name || comment.actedUser?.email;
      return adminName ? `管理者（${adminName}）` : `管理者`;
    } else {
      return fullNameFormat(lastName, firstName);
    }
  })();

  return (
    <div className={classnames(styles.comment, commentByAdmin ? styles.commentByAdmin : styles.commentByEmployees)}>
      <div className={styles.commentHeader}>
        <div>
          {commentByAdmin ? <AdminUser className={styles.icon} /> : <EmployeeIcon className={styles.icon} />}
          {name}
        </div>
        <div className={styles.commentDate}>{dateFormat(comment.createdAt, 'LLL')}</div>
      </div>
      <div className={styles.commentBody}>{comment.comment}</div>
    </div>
  );
};
