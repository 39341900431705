import moment from 'moment';

export const kanaValidator = (value?: string) => {
  if (!value) return true;
  return /^[\u30A0-\u30FF\u3000 ]+$/.test(value);
};

export const dateValidator = (value?: string) => {
  if (!value) return true;
  const date = moment(value, 'YYYY/MM/DD');

  return date.isValid() && /^\d{4}[/-]\d{1,2}[/-]\d{1,2}$/.test(value);
};

export const numberValidator = (value?: string) => {
  if (!value) return true;
  return /^[0-9]+$/.test(value);
};

export const positiveAmountValidator = (value?: string) => {
  if (!value) return true;
  if (value === '') return true;
  return /^([0]|[1-9]|[1-9][\d,]+)$/.test(value);
};

export const maxAmountValidator = (value: string | undefined, max: number) => {
  if (!value) return true;
  const amount = Number(value.split(',').join(''));
  return amount <= max;
};

export const maxFileSizeValidator = (value: File | undefined, max: number) => {
  if (!value) return true;
  return value.size < max;
};
