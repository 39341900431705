import { ComponentProps, FC } from 'react';
import { FormField } from '../FormField';
import { Label } from '../Label';
import { Input } from 'jbc-front/components/presenters/form/Input';
import { Required } from 'jbc-front/components/presenters/form/Label';
import { ErrorMessage } from 'jbc-front/components/presenters/form/ErrorMessage';
import { Note } from 'jbc-front/components/presenters/ui/Note';
import styles from './PostcodeInput.scss';

type PostcodeInputProps = {
  required?: boolean;
  note?: string;
  errors?: string[];
  firstCodeProps?: ComponentProps<typeof Input>;
  lastCodeProps?: ComponentProps<typeof Input>;
};

export const PostcodeInput: FC<PostcodeInputProps> = ({ errors = [], note, required = false, firstCodeProps = {}, lastCodeProps = {} }) => {
  return (
    <FormField>
      <FormField.LabelContainer>
        <Label>郵便番号{required && <Required />}</Label>
      </FormField.LabelContainer>

      <div className={styles.postcode}>
        〒<Input className={styles.firstCode} {...firstCodeProps} />-<Input className={styles.lastCode} {...lastCodeProps} />
      </div>

      {note && (
        <FormField.NoteContainer>
          <Note>{note}</Note>
        </FormField.NoteContainer>
      )}

      {errors.length > 0 && (
        <FormField.ErrorContainer>
          {errors.map((error, i) => (
            <ErrorMessage key={i}>{error}</ErrorMessage>
          ))}
        </FormField.ErrorContainer>
      )}
    </FormField>
  );
};
