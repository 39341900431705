import React from 'react'
import Icon from 'react-icon-base'

const Procedure = props => (
  <Icon viewBox="0 0 18 18" {...props}>
    <g id="symbol" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon_tetsuduki" fill="currentColor">
        <path d="M7.87368174,11.2491212 L6.75087884,11.2491212 L6.75087884,10.1228029 L14.9043825,1.96929927 L16.0307007,3.09210218 L7.87368174,11.2491212 Z M17.8017342,2.61752988 L15.3824701,0.19475041 C15.2502929,0.0632763065 15.0773377,0 14.9043825,0 C14.7314272,0 14.558472,0.0632763065 14.4291071,0.19475041 L5.06140145,9.56315913 L5.06140145,12.9350832 L8.43684087,12.9350832 L17.8017342,3.57018983 C17.9339114,3.43871573 17.9992969,3.26576049 17.9992969,3.09210218 C17.9992969,2.91914694 17.9339114,2.74900398 17.8017342,2.61752988 L17.8017342,2.61752988 Z M16.0307007,8.43684087 C15.5638622,8.43684087 15.1870166,8.81579564 15.1870166,9.27982189 L15.1870166,16.3105226 L1.68596203,16.3105226 L1.68596203,2.81228029 L8.71666276,2.81228029 C9.18350129,2.81228029 9.56245606,2.43332552 9.56245606,1.96929927 C9.56245606,1.50246075 9.18350129,1.12631826 8.71666276,1.12631826 L1.12631826,1.12631826 C0.505507382,1.12631826 0,1.62831029 0,2.24912116 L0,16.8736817 C0,17.4944926 0.505507382,18 1.12631826,18 L15.7473635,18 C16.3681744,18 16.8736817,17.4944926 16.8736817,16.8736817 L16.8736817,9.27982189 C16.8736817,8.81579564 16.4975393,8.43684087 16.0307007,8.43684087 L16.0307007,8.43684087 Z" id="Page-1"></path>
      </g>
    </g>
  </Icon>
)
Procedure.defaultProps = {
  size: 20
}

export default Procedure
