import { gql } from '@apollo/client';
import { Schema, HousingLoanSchema, HousingLoanDetailSchema } from './schema';
import { FileType } from '@jbc-year-end-adj/types';
import { DeductionClassification, SpecialDeduction } from '../../../../query';

export type QueryVariables = {
  input: {
    employeeId: string;
    attributes: {
      housingLoan?: HousingLoan;
    };
  };
};

type HousingLoan = {
  housingLoanDeductionApplicableAmount: string;
  image?: File | FileType;
  certificateImage?: File | FileType;
  firstDetail: HousingLoanDetail;
  secondDetail?: HousingLoanDetail;
};

type HousingLoanDetail = {
  residenceStartOn: string;
  deductionClassification: DeductionClassification;
  specialDeduction: SpecialDeduction;
  yearEndBalance: string;
};

export const convertFormDataToQueryVariables = (data: Schema, employeeId: string): QueryVariables => {
  const housingLoan = convertHousingLoan(data.housingLoan);

  return {
    input: {
      employeeId,
      attributes: {
        housingLoan
      }
    }
  };
};

const convertHousingLoan = (housingLoan?: HousingLoanSchema) => {
  if (housingLoan === undefined) return undefined;

  const firstDetail = convertHousingLoanDetail(housingLoan.firstDetail);
  const secondDetail = housingLoan.secondDetail ? convertHousingLoanDetail(housingLoan.secondDetail) : undefined;
  return {
    housingLoanDeductionApplicableAmount: housingLoan.housingLoanDeductionApplicableAmount,
    image: housingLoan.image,
    certificateImage: housingLoan.certificateImage,
    firstDetail,
    secondDetail
  };
};

const convertHousingLoanDetail = (housingLoanDetail: HousingLoanDetailSchema) => {
  return {
    residenceStartOn: housingLoanDetail.residenceStartOn,
    deductionClassification: housingLoanDetail.deductionClassification,
    specialDeduction: housingLoanDetail.specialDeduction,
    yearEndBalance: housingLoanDetail.yearEndBalance || '0'
  };
};

export const UPDATE_HOUSING_LOAN = gql`
  mutation UpdateHousingLoan($input: UpdateHousingLoanInput!) {
    updateHousingLoan(input: $input) {
      employee {
        id
      }
    }
  }
`;
