import { FC } from 'react';
import { Householder as HouseholderTemplate } from 'features/questions/templates/Householder';
import { CreatableSelectField, Option } from 'components/form/SelectField';
import { FormSection } from 'components/form/FormSection';
import { Input } from 'components/form/Input';
import { useWizard } from '../WizardProvider';
import { RELATIONSHIPS } from '../../../consts';

const relationshipOptions: Option[] = RELATIONSHIPS.map(val => ({ value: val, label: val }));

export const Householder: FC = () => {
  const { question, request } = useWizard();
  const profile = request.profile;
  const relationship = profile?.householder?.relationship;
  if (relationship && !RELATIONSHIPS.includes(relationship)) {
    relationshipOptions.push({ value: relationship, label: relationship });
  }

  return (
    <HouseholderTemplate question={question}>
      <FormSection>
        <CreatableSelectField
          label="世帯主の続柄"
          required
          options={relationshipOptions}
          value={relationshipOptions.find(option => option.value === profile?.householder?.relationship)}
          note="一人暮らしの場合の世帯主は「本人」です。"
          disabled
        />

        {profile?.householder && profile?.householder?.relationship !== '本人' && (
          <Input label="氏名" value={profile?.householder?.name} required disabled />
        )}
      </FormSection>
    </HouseholderTemplate>
  );
};
