import React, { useState, useCallback } from 'react';
import onClickOutside from 'react-onclickoutside';
import styles from './Balloon.scss';
import _ from 'lodash';

export const Balloon = ({ switchRender, title, children, right, center, disabled, disabledReason }) => {
  const [isShow, setIsShow] = useState();
  const hide = useCallback(() => setIsShow(false), []);
  const toggle = useCallback(() => setIsShow(!isShow), [isShow]);
  return (
    <div className={styles.switch}>
      {switchRender(toggle, title, isShow, disabled, disabledReason)}
      {isShow && (
        <BalloonContent hide={hide} right={right} center={center} closeMenu={() => setIsShow(false)}>
          {children && _.isFunction(children) ? children(hide) : children}
        </BalloonContent>
      )}
    </div>
  );
};

Balloon.Ul = ({ children, ...rest }) => <ul {...rest}>{children}</ul>;
Balloon.Li = ({ children, ...rest }) => (
  <li className={styles.li} {...rest}>
    {children}
  </li>
);

const BalloonContent = onClickOutside(
  ({ center, right, hide, closeMenu, children }) => {
    BalloonContent.handleClickOutside = closeMenu;
    return <div className={(right && styles.right) || (center && styles.center)}>{children}</div>;
  },
  { handleClickOutside: () => BalloonContent.handleClickOutside }
);

export default Balloon;
