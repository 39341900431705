import { useCallback, useRef } from 'react';

type OnClickOutsideHandler = () => void;

export const useOutsideClick = <E extends Element>(onClickOutside: OnClickOutsideHandler) => {
  const targetElementRef = useRef<E | null>(null);
  const onClickOutsideRef = useRef<OnClickOutsideHandler | null>(null);
  onClickOutsideRef.current = onClickOutside;

  const handleClickOutside = useCallback((e: MouseEvent) => {
    if (!targetElementRef.current?.contains(e.target as Node)) {
      onClickOutsideRef.current?.();
    }
  }, []);

  const callbackRef = useCallback(
    (element: E | null) => {
      targetElementRef.current = element;
      if (element) {
        document.addEventListener('click', handleClickOutside);
      } else {
        document.removeEventListener('click', handleClickOutside);
      }
    },
    [handleClickOutside]
  );

  return callbackRef;
};
