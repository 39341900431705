import { FC } from 'react';
import styles from './MemoColor.scss';
import { MemoColor } from '@jbc-year-end-adj/2024/types';

type ColorLabelProps = {
  color: MemoColor;
};

const ColorLabel: FC<ColorLabelProps> = ({ color }) => <span className={styles[`memo_${color}`]}>●</span>;

export const MEMO_COLOR_OPTIONS = [
  { label: <ColorLabel color={'blue'} />, value: 'blue' },
  { label: <ColorLabel color={'yellow'} />, value: 'yellow' },
  { label: <ColorLabel color={'pink'} />, value: 'pink' },
  { label: <ColorLabel color={'green'} />, value: 'green' },
  { label: <ColorLabel color={'purple'} />, value: 'purple' },
  { label: <ColorLabel color={'orange'} />, value: 'orange' }
];
