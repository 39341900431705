import React, { useState } from 'react';
import classNames from 'classnames';
import ActionButton from 'jbc-front/components/ActionButton';
import { Pulldown } from 'jbc-front/components/icons';
import styles from './ToggleDivImage.scss';

export const ToggleDivImage = ({ title, children, className }) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggleHandler = () => setIsOpen(v => !v);

  return (
    <div className={classNames(styles.toggleDiv, className)}>
      <div className={styles.toggleDivHeader} role="presentation">
        <span>{title}</span>
        <ActionButton primary={isOpen} as="button" type="button" className={styles.mainFormControl} onClick={toggleHandler}>
          {isOpen ? '閉じる' : '開く'}
          <Pulldown className={styles.expandIcon} size={14} transform={isOpen ? 'rotate(180)' : 'rotate(0)'} />
        </ActionButton>
      </div>
      {isOpen && <div className={styles.toggleDivBody}>{children}</div>}
    </div>
  );
};

export default ToggleDivImage;
