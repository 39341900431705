import React from 'react'
import Icon from 'react-icon-base'

const CustomCalender = props => (
  <Icon viewBox="0 0 15 15" {...props}>
    <g fill='#3498DB'>
      <path d='M3.286,11.033c0.284,0.146,0.937,0.42,1.59,0.42c0.832,0,1.253-0.4,1.253-0.916 c0-0.674-0.674-0.979-1.38-0.979H4.097V8.41h0.621C5.255,8.4,5.939,8.201,5.939,7.621c0-0.411-0.337-0.715-1.011-0.715 c-0.559,0-1.147,0.242-1.432,0.41L3.17,6.158c0.411-0.263,1.232-0.516,2.116-0.516c1.464,0,2.274,0.769,2.274,1.705 c0,0.727-0.41,1.295-1.253,1.59v0.021c0.821,0.146,1.484,0.769,1.484,1.662c0,1.211-1.063,2.096-2.801,2.096 c-0.884,0-1.632-0.229-2.031-0.483L3.286,11.033z' />
      <path d='M9.835,7.179H9.814L8.509,7.801L8.245,6.6l1.812-0.843h1.326v6.845H9.835V7.179z' />
    </g>
    <path fill='#3498DB' d='M13,0H2C0.9,0,0,0.9,0,2v11.016c0,1.101,0.9,2,2,2h11c1.1,0,2-0.899,2-2V2C15,0.9,14.1,0,13,0z M2,13.016   V4.25h11v8.766H2z' />
  </Icon>
)
CustomCalender.defaultProps = {
  size: 15
}

export default CustomCalender
