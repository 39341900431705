import React from 'react';
import { useAutofill, useFormValue, useQuery, useYear } from '../components';
import ActionButton from 'jbc-front/components/ActionButton';
import { Copy } from 'jbc-front/components/icons';
import { CheckboxField, FileField, RadioField, TextAreaField, TextField } from '../components/FieldWithDiff';
import Address from '../components/Address';
import { amount, maxLengthAmount, maxTenThousandYen } from '../validators';
import { Label } from '../components/PrimaryPanel';
import { handicapClassifications } from '../questions/Handicap';
import { BoxDouble } from 'jbc-front/components/Form';
import { salaryEarnings } from '../utils/income';
import { residenceStatuses } from '../questions/SpouseYearlyInfo';
import { gql } from '@apollo/client';

const EARNINGS = gql`
  query earnings {
    yearMasters {
      id
      year
      earnings
      spouseEarnings
    }
  }
`;

export const SpouseYearlyInfo = ({ withCopy, field, hideIncome, target, isSpouse, thisYearField, year, isNextYear }) => {
  const { data, loading } = useQuery(EARNINGS);
  const residenceStatus = useFormValue(`${field}.residenceStatus`);
  const isNonResidentStatus = useFormValue(`${field}.isNonResident`);
  const handicapType = useFormValue(`${field}.handicapType`);
  const { autofill, withSelector } = useAutofill();
  const yearForAddressLookup = useYear();
  if (loading) return null;
  const { yearMasters } = data;
  const { earnings: maxEarnings, spouseEarnings: maxSpouseEarnings } = yearMasters.find(master => master.year === year);
  return (
    <>
      {withCopy && (
        <ActionButton
          icon={<Copy size={13} />}
          className="u-mb20"
          onClick={() => {
            withSelector(selector => {
              const thisYear = selector(thisYearField || field.replace(/nextYear/, 'thisYear'));
              [
                'residenceStatus',
                'prefectureId',
                'postcode0',
                'postcode1',
                'city',
                'street',
                'building',
                'addressForeign',
                'income',
                'earnings',
                'isNonResident',
                'remittance',
                'handicapType',
                'handicapDetail'
              ].forEach(name => autofill(`${field}.${name}`, thisYear[name]));
            });
          }}
        >
          今年の情報をコピーする
        </ActionButton>
      )}
      <RadioField name="residenceStatus" options={residenceStatuses} label="同居・別居" required />
      {residenceStatus === 'different_address' && <Address withKana={false} year={yearForAddressLookup} required />}
      {residenceStatus === 'different_and_foreign_address' && (
        <>
          <TextField name="addressForeign" label="Address" />
          <CheckboxField
            name="isNonResident"
            label="非居住者"
            description={`${target}が国内に住所を有せず、かつ、現在まで引き続き1年以上居所を有しない場合チェックしてください。`}
          />
        </>
      )}
      {residenceStatus === 'different_and_foreign_address' && isNonResidentStatus && (
        <>
          <TextField
            name="remittance"
            label="国外居住親族への送金額"
            validate={amount}
            description={`${target}が非居住者である場合、本年中にその被扶養者に送金等をした金額の合計を記入してください。`}
          />
          <Label>添付ファイル</Label>
          <p className="u-mb20">
            国外居住親族に係る扶養控除等を受けようとする場合は関係書類（例：親族関係書類、送金関係書類）を添付してください。
          </p>
          <FileField name="relatedToRelativesDocument" label="親族関係書類" />
          <FileField name="relatedToRemittanceDocument" label="送金関係書類" />
        </>
      )}

      <RadioField name="handicapType" label="障害者区分" options={handicapClassifications} required />
      {handicapType && handicapType !== 'none' && (
        <>
          <TextAreaField name="handicapDetail" label="詳細" />
          <FileField name="handicapImage" label="障害者手帳画像" />
        </>
      )}
      {!hideIncome && (
        <BoxDouble>
          <TextField
            name="income"
            label="給与収入等"
            validate={[maxLengthAmount(7)]}
            description="給与収入が103万円を超える場合被扶養者に該当しません。（配偶者は150万円を超えてはいけません。また、従業員本人の給与収入が1,095万円を超える場合配偶者控除を受けることができません。）"
            onChange={(e, value) => {
              autofill(`${field}.earnings`, salaryEarnings(value, year));
            }}
          />
          <TextField
            name="earnings"
            label="所得見積額"
            validate={[amount, maxTenThousandYen(isSpouse ? maxSpouseEarnings : maxEarnings)]}
            description={`所得が${maxEarnings / 10_000}万円を超える場合被扶養者に該当しません。（配偶者は${maxSpouseEarnings /
              10_000}万円）`}
          />
        </BoxDouble>
      )}
    </>
  );
};
