import { FC, ReactNode, forwardRef } from 'react';
import styles from './ToggleButton.scss';

type ContainerProps = {
  children: ReactNode;
};

const Container: FC<ContainerProps> = ({ children }) => {
  return <div className={styles.container}>{children}</div>;
};

type ToggleButtonProps = JSX.IntrinsicElements['input'] & {
  children: ReactNode;
};

const Button = forwardRef<HTMLInputElement, ToggleButtonProps>(({ children, ...props }, ref) => {
  return (
    <label className={styles.buttonContainer}>
      <input type="radio" {...props} ref={ref} className={styles.radio} />
      <div className={styles.toggleButton}>{children}</div>
    </label>
  );
});

export const ToggleButton = Object.assign(Button, { Container });
