import { FC } from 'react';
import { Close } from 'jbc-front/components/icons';

import { useAsyncTask } from './AsyncTaskProvider';
import styles from './AsyncTaskError.scss';

// @ts-ignore
import recordDisplay from '@jbc-year-end-adj/2024/utils/recordDisplay';

type MapType = { [key: string]: string };

const titleMap: MapType = {
  employee_import: '従業員データインポートエラー',
  withholding_slip_csv_import: '給与・賞与一括登録エラー',
  employee_import_from_prev_year_job: '昨年の保険・ローン情報を取得エラー',
  employees_csv_import: '従業員データ一括更新エラー',
  employees_export_to_service: '従業員データ反映エラー',
  apply_differences_to_payroll: '過不足額反映エラー',
  bulk_update_difference_apply_methods: '過不足額反映方法変更エラー',
  send_request: '年末調整依頼エラー',
  bulk_delete_employees: '従業員一括削除エラー',
  update_status: 'ステータス一括更新エラー',
  create_publish_withholding_slip_request: 'マイページ一括公開エラー',
  create_deliver_withholding_slip_request: 'メール一括発送エラー',
  fix_data: '年末調整確定エラー'
};

const additionalNoticeMap: MapType = {
  update_status: '従業員情報の必須項目が全て入力されているかご確認ください。'
};

const partialSuccessNoticeMap: MapType = {
  employee_import: 'なお、以下にエラー表示のない従業員は、正常に登録されています。',
  employees_csv_import: 'なお、以下にエラー表示のない従業員は、正常に更新されています。',
  withholding_slip_csv_import: 'なお、以下にエラー表示のない従業員は、正常に更新されています。',
  update_status: 'なお、以下にエラー表示のない従業員は、正常に更新されています。'
};

type AsyncTaskErrorProps = {
  displayTypes?: string;
};

export const AsyncTaskError: FC<AsyncTaskErrorProps> = ({ displayTypes }) => {
  const { asyncTask, confirm } = useAsyncTask();

  if (!asyncTask || !['failed', 'partial_success'].includes(asyncTask.status)) return null;
  if (!displayTypes || !displayTypes.includes(asyncTask.taskType)) return null;

  const title = titleMap[asyncTask.taskType];
  if (!title) return null;

  const additionalNotice = additionalNoticeMap[asyncTask.taskType];
  const partialSuccessNotice = asyncTask.status === 'partial_success' ? partialSuccessNoticeMap[asyncTask.taskType] : '';

  return (
    <div className={styles.errorWrap}>
      <div onClick={confirm} className={styles.close} role="presentation">
        <Close size={20} />
      </div>
      <div className={styles.inner}>
        <p className={styles.title}>
          {title}が発生しました。
          {additionalNotice && (
            <>
              <br />
              {additionalNotice}
              <br />
            </>
          )}
          {partialSuccessNotice}
        </p>
        <div className={styles.dates}>
          <dl>
            <dt>開始日時：</dt>
            <dd>{recordDisplay.date(asyncTask.excutedAt, 'LLL')}</dd>
          </dl>
          <dl>
            <dt>エラー日時：</dt>
            <dd>{recordDisplay.date(asyncTask.endedAt, 'LLL')}</dd>
          </dl>
        </div>
        <ul className={styles.list}>
          {asyncTask.error?.split('\n').map(error => (
            <li key={error}>{error}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};
