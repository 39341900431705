import React from 'react'
import Icon from 'react-icon-base'

const ApplicableOffice = props => (
  <Icon viewBox="0 0 30 30" {...props}>
    <g id="symbol" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon_applicable_office" fill="#fff">
        <circle cx="15" cy="15" r="15" fill="currentColor"/>
        <path d="M9.953 7v15H8v1.5h14.525V22h-1.953V7H9.953zm1.5 15h7.619V8.5h-7.619V22z"/>
        <path d="M12.981 11.476h1.625V9.851h-1.625zM16.017 11.476h1.625V9.851h-1.625zM12.981 13.976h1.625v-1.625h-1.625zM16.017 13.976h1.625v-1.625h-1.625zM12.981 16.476h1.625v-1.625h-1.625zM16.017 16.476h1.625v-1.625h-1.625zM12.981 18.976h1.625v-1.625h-1.625zM16.017 18.976h1.625v-1.625h-1.625z"/>
      </g>
    </g>
  </Icon>
)
ApplicableOffice.defaultProps = {
  size: 30
}

export default ApplicableOffice
