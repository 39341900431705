import { createContext, useContext, FC, ReactNode } from 'react';
import { Request } from '../../query';
import { Question, QuestionGroup } from 'features/questions/type';

type WizardContextValue = {
  request: Request;
  question: Question;
  questionGroups: QuestionGroup[];
};

const WizardContext = createContext<WizardContextValue | null>(null);

type WizardProviderProps = {
  children: ReactNode;
  request: Request;
  question: Question;
  questionGroups: QuestionGroup[];
  className?: string;
};

export const WizardProvider: FC<WizardProviderProps> = ({ request, question, questionGroups, children, className }) => {
  return (
    <WizardContext.Provider value={{ request, question, questionGroups }}>
      <div className={className}>{children}</div>
    </WizardContext.Provider>
  );
};

export function useWizard(): WizardContextValue {
  const value = useContext(WizardContext);

  if (value === null) throw new Error();

  return value;
}
