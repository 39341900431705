import React, { useEffect } from 'react';
import { gql } from '@apollo/client';
import queryString from 'query-string';
import { useQuery, useSession, useMutation, LoadingPage, useYear } from '../components';
import { Redirect, Link } from 'react-router-dom';
import { Progress } from '../components/Progress';
import { PayrollReportModal } from '../components/PayrollReportModal';
import { DownloadClientCity } from '../components/DownloadClientCity';
import Button from 'jbc-front/components/Button';
import Hint from 'jbc-front/components/Hint';
import Paginator from 'jbc-front/components/Paginator';
import recordDisplay from '../utils/recordDisplay';
import styles from './CityReport.scss';
import ClientCity from '../city_report/ClientCity';
import EmployeesWithNoCity from '../city_report/EmployeesWithNoCity';

const CLIENT_CITIES = gql`
  query clientCities($page: Int, $per: Int, $year: Int!) {
    client {
      id
      clientYearly(year: $year) {
        id
        finishedCityCount
        clientCities(page: $page, per: $per) {
          totalCount
          list {
            id
            fromSalaryCount
            normallyForResignCount
            normallyOthersCount
            finished
            designationNumber
            status
            city {
              id
              code
              name
              prefecture {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

const CITY_REPORT_SETTING = gql`
  query cityReportSetting($year: Int!) {
    client {
      id
      clientYearly(year: $year) {
        id
        cityReportSetting {
          id
          showMyNumber
        }
      }
    }
  }
`;

const SET_CITY_FINISHED = gql`
  mutation setCityFinished($input: SetCityFinishedInput!) {
    setCityFinished(input: $input) {
      clientCity {
        id
        finished
      }
      clientYearly {
        id
        finishedCityCount
      }
    }
  }
`;

const PER_PAGE = 5;

const PollingController = ({ statuses, stopPolling, startPolling }) => {
  useEffect(() => {
    if (statuses.some(status => status !== 'success' && status !== 'failed')) {
      startPolling(3000);
      return () => {
        stopPolling();
      };
    }
  });
  return null;
};

const CityReport = ({ location: { search, pathname }, history }) => {
  const { page, id: clientCityId } = queryString.parse(search);
  const year = useYear();
  const { data: dataSetting, loading: loadingSetting } = useQuery(CITY_REPORT_SETTING, {
    fetchPolicy: 'network-only',
    variables: { year }
  });
  const { data, loading: loadingQuery, startPolling, stopPolling } = useQuery(CLIENT_CITIES, {
    variables: { page: +page || 1, per: PER_PAGE, year },
    fetchPolicy: 'network-only'
  });
  const {
    me: { hasMyNumberPermission }
  } = useSession();
  const [setCityFinished] = useMutation(SET_CITY_FINISHED);
  if (loadingQuery || loadingSetting) return <LoadingPage />;
  const {
    client: {
      clientYearly: { cityReportSetting },
      clientYearly
    }
  } = dataSetting;
  if (cityReportSetting === null) return <Redirect to={`/${year}/result/city_report_setting`} />;
  const {
    client: {
      clientYearly: {
        finishedCityCount,
        clientCities: { list, totalCount }
      }
    }
  } = data;
  const handleClickRow = id => () => {
    const { page } = queryString.parse(search);
    history.push({ pathname, search: queryString.stringify({ page, id }) });
  };
  const handleClickPaginator = page => {
    history.push({ pathname, search: queryString.stringify({ page }) });
  };
  const progress = totalCount > 0 ? Math.round((finishedCityCount / totalCount) * 100) : 100;
  const inProgress = list.some(({ status }) => status !== 'success' && status !== 'failed');

  return (
    <div>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">給与支払報告書(市区町村別)</h1>
      </div>
      <EmployeesWithNoCity />
      <div className="l-contents-wrap">
        <div className="l-wrap-xl">
          <div className={styles.submittedCities}>
            <p>
              提出済み市区町村数 / 対象市区町村数:
              <span className={styles.rate}>{`${finishedCityCount}/${totalCount}(${progress}%完了)`}</span>
            </p>
            <p>
              給与支払報告書合計: <span className={styles.rate}>{totalCount || 0}</span>
            </p>
          </div>
          <Progress progress={progress} className={styles.progress} />
          <div className={styles.buttonArea}>
            <div className={styles.buttonControl}>
              <PayrollReportModal header="提出用CSVダウンロード" hasMyNumberPermission={hasMyNumberPermission} sendTo="city" />
            </div>
            <div className={styles.buttonControl}>
              <DownloadClientCity
                hasMyNumberPermission={hasMyNumberPermission}
                inProgress={inProgress}
                showMyNumber={cityReportSetting.showMyNumber}
              />
            </div>
            <Button
              primary
              as={finishedCityCount > 0 ? 'div' : Link}
              to={finishedCityCount === 0 && `/${year}/result/city_report_setting`}
              disabled={finishedCityCount > 0}
              disabledReason={'提出済みの市区町村があるため、設定変更できません'}
            >
              給与支払報告書設定
            </Button>
          </div>
          <div className="l-overflow-scroll">
            <table className={`m-table-list ${styles.cityReportTable}`}>
              <thead>
                <tr>
                  <th width={50}>完了</th>
                  <th width={130}>団体コード</th>
                  <th width={130}>都道府県</th>
                  <th width={150}>市区町村</th>
                  <th width={125}>
                    <Link to={`/${year}/resident_tax_payment_address`} className={styles.link}>
                      指定番号
                    </Link>
                  </th>
                  <th width={125}>特別徴収(在籍)</th>
                  <th width={125}>普通徴収(退職)</th>
                  <th width={125}>普通徴収(その他)</th>
                  <th width={130}>合計</th>
                </tr>
              </thead>
              <tbody>
                {list.length > 0 ? (
                  <>
                    {list.map(
                      ({
                        id,
                        city: {
                          name,
                          code,
                          prefecture: { name: prefecture }
                        },
                        designationNumber,
                        fromSalaryCount,
                        special,
                        normallyForResignCount,
                        normallyOthersCount,
                        finished,
                        status
                      }) => {
                        const selectedClass = finished ? 'm-table-checkbox-on' : 'm-table-checkbox-off';
                        const disabled = status !== 'success' && status !== 'failed';
                        return (
                          <tr key={id} className={finished ? styles.finished : ''} onClick={handleClickRow(id)}>
                            <td // eslint-disable-line
                              className={`m-table-list-check ${selectedClass} ${disabled ? styles.disabled : ''}`}
                              onClick={async e => {
                                e.stopPropagation();
                                if (disabled) {
                                  return;
                                }
                                await setCityFinished({
                                  variables: {
                                    input: {
                                      clientCityId: id,
                                      finished: !finished
                                    }
                                  },
                                  optimisticResponse: {
                                    setCityFinished: {
                                      __typename: 'SetCityFinishedPayload',
                                      clientCity: {
                                        id: id,
                                        finished: !finished,
                                        __typename: 'ClientCity'
                                      },
                                      clientYearly: {
                                        id: clientYearly.id,
                                        finishedCityCount: finished ? finishedCityCount - 1 : finishedCityCount + 1,
                                        __typename: 'ClientYearlyType'
                                      }
                                    }
                                  }
                                });
                              }}
                            >
                              <input type="checkbox" checked={!!finished} readOnly />
                              {disabled && (
                                <div className={styles.disabledReason}>
                                  <div className={styles.content}>給与支払報告書の作成中は操作できません</div>
                                </div>
                              )}
                            </td>
                            <td>{recordDisplay(code)}</td>
                            <td>{recordDisplay(prefecture)}</td>
                            <td>{recordDisplay(name)}</td>
                            <td>{recordDisplay(designationNumber)}</td>
                            <td>{fromSalaryCount}</td>
                            <td>{normallyForResignCount}</td>
                            <td>{normallyOthersCount}</td>
                            <td>{fromSalaryCount + normallyForResignCount + normallyOthersCount}</td>
                          </tr>
                        );
                      }
                    )}
                    <PollingController statuses={list.map(({ status }) => status)} {...{ startPolling, stopPolling }} />
                  </>
                ) : null}
              </tbody>
            </table>
          </div>
          <div className={styles.paginator}>
            <Paginator totalResult={totalCount} current={page} rowsPerPage={PER_PAGE} onClick={handleClickPaginator} />
          </div>
          {clientCityId && <ClientCity clientCityId={clientCityId} showMyNumber={cityReportSetting.showMyNumber} />}
        </div>
      </div>
    </div>
  );
};

export default CityReport;
