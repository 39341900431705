import React from 'react'
import Icon from 'react-icon-base'

const FileImport = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="3">
      <path d="M11.52 3.88H6.367a2.466 2.466 0 0 0-2.466 2.466v27.308a2.466 2.466 0 0 0 2.466 2.466H33.632a2.467 2.467 0 0 0 2.466-2.466V6.346a2.466 2.466 0 0 0-2.466-2.466h-5.15M20 4.082v18.422"/>
      <path d="M12.738 15.243L20 22.505l7.261-7.262"/>
    </g>
  </Icon>
)
FileImport.defaultProps = {
  size: 40
}


export default FileImport
