import { gql } from '@apollo/client';
import { Schema } from './schema';

export type QueryVariables = {
  input: {
    employeeId: string;
    attributes: {
      postcode0: string;
      postcode1: string;
      prefectureId: string;
      city: string;
      street: string;
      building?: string;
    };
  };
};

export const convertFormDataToQueryVariables = (employeeId: string, data: Schema): QueryVariables => {
  return {
    input: {
      employeeId,
      attributes: {
        postcode0: data.postcode0,
        postcode1: data.postcode1,
        prefectureId: data.prefectureId,
        city: data.city,
        street: data.street,
        building: data.building
      }
    }
  };
};

export const UPDATE_RESIDENT_CARD = gql`
  mutation UpdateResidentCard($input: UpdateResidentCardInput!) {
    updateResidentCard(input: $input) {
      employee {
        id
      }
    }
  }
`;
