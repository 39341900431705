import { useState } from 'react';
import useReactRouter from 'use-react-router';
import { gql, useApolloClient } from '@apollo/client';
import fp from 'lodash/fp';
import { useSession } from '@jbc-year-end-adj/2024/features/AdminSessionProvider';
import { getSavedDisplayEmployeeLimit } from '@jbc-year-end-adj/2024/utils/employeeForm';
import { ADMIN_ENDPOINT } from '@jbc-year-end-adj/2024/hooks/graphql/consts';
import { DELETE_MODE, REQUEST_MODE, CHANGE_STATUS_MODE } from '../../consts';

export const EMPLOYEES_FOR_ALL_SELECTION_FRAGMENT = gql`
  fragment EmployeesForAllSelection on EmployeePager {
    list {
      id
      staffCode
      email
      profile {
        id
        fullName
      }
      status
    }
  }
`;

const EMPLOYEES_FOR_ALL_SELECTION = gql`
  query employeesForAllSelection($search: EmployeeSearchInput) {
    client {
      id
      clientYearly {
        id
        fixed
        employeesForAllSelection(search: $search) {
          ...EmployeesForAllSelection
        }
      }
    }
  }
  ${EMPLOYEES_FOR_ALL_SELECTION_FRAGMENT}
`;

export const convertSelectedIds = (allSelectMode, selectedIds) => ({
  employeeIds: allSelectMode ? [] : selectedIds,
  exceptEmployeeIds: allSelectMode ? selectedIds : []
});

const defaultCondition = { withholdingSlipStatus: 'all', withholdingSlipDeliverStatus: 'all' };
const showAllCondition = { ...defaultCondition, status: 'all' };
const differenceApplyShowAllCondition = { ...defaultCondition, status: 'accepted' };
const withholdingSlipDeliverShowAllCondition = { ...defaultCondition, hasPaymentOnly: true };

export const useSelectEmployee = (mode, variables, list, totalCount, selectableCount, disabledMessage, selectedInfo) => {
  const client = useApolloClient();
  const [allSelectMode, setAllSelectMode] = useState(false);
  const [isSelecting, setIsSelecting] = useState(false);
  const [selected, setSelected] = useState({});
  const {
    location: { pathname }
  } = useReactRouter();
  const { me } = useSession();
  const condition = fp.omit(['page', 'sortColumn', 'sortOrder'], fp.omitBy(fp.isUndefined, variables.search));
  const isShowAll =
    fp.isEqual(condition, defaultCondition) ||
    fp.isEqual(condition, showAllCondition) ||
    (![DELETE_MODE, REQUEST_MODE, CHANGE_STATUS_MODE].includes(mode) && fp.isEqual(condition, differenceApplyShowAllCondition)) ||
    fp.isEqual(condition, withholdingSlipDeliverShowAllCondition);
  const shouldSelectByAll = item => !disabledMessage(item);
  const selectedCount = fp.keys(selected).length;
  const fetchEmployeesForAllSelection = async () => {
    const { data } = await client.query({
      query: EMPLOYEES_FOR_ALL_SELECTION,
      variables,
      fetchPolicy: 'network-only',
      context: {
        uri: ADMIN_ENDPOINT
      }
    });

    return data;
  };

  // 各行のチェックボックスの選択状態を返す
  const isSelected = item => (allSelectMode ? shouldSelectByAll(item) && !fp.has(item.id, selected) : !!selected[item.id]);

  // 各行のチェックボックスの ON/OFF 切り替え
  const toggle = item => {
    if (selected[item.id]) {
      setSelected(fp.unset(item.id, selected));
    } else {
      setSelected(fp.set(item.id, selectedInfo(item), selected));
    }
  };

  const isAllSelected = list => {
    if (list.every(item => !shouldSelectByAll(item))) {
      return false;
    }

    if (allSelectMode) {
      return list.every(item => !shouldSelectByAll(item) || !selected[item.id]);
    } else {
      return list.some(item => selected[item.id]) && list.every(item => !shouldSelectByAll(item) || selected[item.id]);
    }
  };

  // フィルタされた状態での全選択チェックボックスの選択状態を返す
  const searchAllSelected = async () => {
    if (totalCount > getSavedDisplayEmployeeLimit(pathname, me)) {
      const data = await fetchEmployeesForAllSelection();
      return isAllSelected(data.client.clientYearly.employeesForAllSelection.list);
    } else {
      return isAllSelected(list);
    }
  };

  // 全選択チェックボックスの選択状態を返す
  const allSelected = async () => {
    if (isShowAll && allSelectMode) {
      return !!selectableCount && !selectedCount;
    } else {
      return await searchAllSelected();
    }
  };

  const searchSelectAllToggle = async items => {
    const targets = items.filter(item => shouldSelectByAll(item));

    if (allSelectMode) {
      if (await allSelected()) {
        setSelected(Object.assign({}, selected, ...(targets |> fp.map(item => ({ [item.id]: selectedInfo(item) })))));
      } else {
        setSelected(fp.omit(targets |> fp.map(item => item.id), selected));
      }
    } else {
      if (await allSelected()) {
        setSelected(fp.omit(targets |> fp.map(item => item.id), selected));
      } else {
        setSelected(Object.assign({}, selected, ...(targets |> fp.map(item => ({ [item.id]: selectedInfo(item) })))));
      }
    }
  };

  // フィルタされていない状態での全選択の切り替え
  const selectAllToggle = async () => {
    if (allSelectMode) {
      (await allSelected()) ? setAllSelectMode(false) : setSelected({});
    } else {
      setAllSelectMode(true);
      setSelected({});
    }
  };

  // フィルタされた状態での全選択の切り替え
  const searchSelectAll = async () => {
    setIsSelecting(true);

    if (totalCount > getSavedDisplayEmployeeLimit(pathname, me)) {
      const data = await fetchEmployeesForAllSelection();
      await searchSelectAllToggle(fp.getOr([], 'client.clientYearly.employeesForAllSelection.list', data));
    } else {
      await searchSelectAllToggle(list);
    }

    setIsSelecting(false);
  };

  // 全選択チェックボックスの ON/OFF 切り替え
  const selectAll = () => {
    isShowAll ? selectAllToggle() : searchSelectAll();
  };

  // 選択した従業員数を返す
  const selectedResultCount = () => {
    if (allSelectMode) {
      return selectableCount - selectedCount;
    } else {
      return selectedCount;
    }
  };

  // ボタンの活性・非活性を返す
  const isDisabledSubmit = () => {
    if (isSelecting) {
      return true;
    }

    return !selectedResultCount();
  };

  // チェックボックスの状態をリセット
  const reset = () => {
    setAllSelectMode(false);
    setSelected({});
  };

  return {
    selected,
    isSelected,
    allSelectMode,
    allSelected,
    isSelecting,
    isDisabledSubmit,
    selectedResultCount,
    toggle,
    selectAll,
    reset
  };
};
