import React, { useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import _ from 'lodash';
import fp from 'lodash/fp';
import { Link } from 'react-router-dom';
import { useQuery, useYear, LoadingPage, useMutation, useAsyncTask } from '../components';
import { numberWithSplit } from '../utils/recordDisplay';
import Button from 'jbc-front/components/Button';
import styles from './LegalRecords.scss';
import moment from 'moment';

const STATUTORY_TOTAL_REPORT = gql`
  query statutoryTotalReport($year: Int!) {
    client {
      id
      clientYearly(year: $year) {
        id
        statutoryTotalReport {
          totalCount
          totalAmountPaid
          totalCountWithoutTax
          totalWithholdingTaxAmount
          taxOfficeAmountPaid
          taxOfficeTotalCount
          taxOfficeWithholdingTaxAmount
          taxPostponementAmount
          disasterSuffererCount
          aggregatedAt
        }
      }
    }
  }
`;

const STATUTORY_TOTAL_REPORT_ASYNC = gql`
  mutation statutoryTotalReportAsync($year: Int!) {
    statutoryTotalReportAsync(input: { year: $year }) {
      clientYearly {
        id
      }
    }
  }
`;

const NumberBoxesTd = ({ value = 0, digit = 4, suffixText }) => {
  const valueStrArray = value |> _.toString |> _.toArray;
  const joinValueOverDigit = array => {
    if (array.length > digit) {
      const overValue = array.splice(0, array.length - digit).join('');
      array[0] = overValue.concat(array[0]);
    }
    return array;
  };
  const numberArray =
    [
      ...Array(digit)
        .fill('')
        .slice(valueStrArray.length),
      ...joinValueOverDigit(valueStrArray)
    ]
    |> fp.reverse
    |> fp.chunk(3)
    |> fp.map(array => array.reverse())
    |> fp.reverse;
  return (
    <td>
      <div className={styles.inputBoxWrap}>
        <div className={styles.numberBoxWrap}>
          {numberArray.map((value, i) => (
            <React.Fragment key={i}>
              {value.map((num, i) => (
                <p key={i} className={styles.numberBox}>
                  {num}
                </p>
              ))}
              {i + 1 !== numberArray.length && <span className={styles.numberBoxComma}>,</span>}
            </React.Fragment>
          ))}
        </div>
        <p className={styles.suffix}>{suffixText}</p>
      </div>
    </td>
  );
};

const LegalRecords = () => {
  const year = useYear();
  const { loading, data, refetch } = useQuery(STATUTORY_TOTAL_REPORT, { fetchPolicy: 'no-cache', variables: { year } });
  const [statutoryTotalReportAsync] = useMutation(STATUTORY_TOTAL_REPORT_ASYNC);
  const { task, refetch: asyncTaskRefetch } = useAsyncTask();
  const [taskStatus, setTaskStatus] = useState(false);

  useEffect(() => {
    const startAggregate = async () => {
      await statutoryTotalReportAsync({ variables: { year } });
      asyncTaskRefetch();
    };
    startAggregate();
  }, []);

  useEffect(() => {
    setTaskStatus(task?.status);
    if (task?.status === 'success') {
      refetch();
    }
  }, [task?.status]);

  if (loading) {
    return <LoadingPage />;
  }

  const statutoryTotalReport = data?.client?.clientYearly?.statutoryTotalReport || {};

  const StatusMessageTable = ({ message }) => {
    return (
      <div className={`m-table-list ${styles.loadingLegalRecordsTable}`}>
        <p className={`m-table-list ${styles.loadingMessage}`}>{message}</p>
      </div>
    );
  };

  const AggregatedOnMoment = ({ aggregatedAt }) => {
    if (!aggregatedAt) return null;
    return (
      <div className={styles.tableWidth}>
        <div className={styles.tableRightBottom}>
          <p className={styles.aggregatedAtText}>最終集計日時: {moment(aggregatedAt).format('YYYY/MM/DD HH:mm')}</p>
        </div>
      </div>
    );
  };

  const StatutoryTotalReportTable = ({ statutoryTotalReport }) => {
    return (
      <>
        <table className={`m-table-list ${styles.legalRecordsTable}`}>
          <thead>
            <tr className={styles.tableHeader}>
              <th colSpan={5}>1 給与所得の源泉徴収合計表 （375）</th>
            </tr>
            <tr className={styles.tableSubHeader}>
              <th width={150}>区分</th>
              <th width={170}>人員</th>
              <th width={180}>左のうち、源泉徴収税額のない者</th>
              <th width={280}>支払金額</th>
              <th width={280}>源泉徴収税額</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>A 俸給、給与、賞与等の総額</td>
              <NumberBoxesTd value={statutoryTotalReport?.totalCount} digit={4} suffixText="人" />
              <td className={styles.tableRightBottom}>
                <span className={styles.tableNumber}>{statutoryTotalReport?.totalCountWithoutTax}</span>人
              </td>
              <NumberBoxesTd value={statutoryTotalReport?.totalAmountPaid} digit={10} suffixText="円" />
              <NumberBoxesTd value={statutoryTotalReport?.totalWithholdingTaxAmount} digit={9} suffixText="円" />
            </tr>
            <tr>
              <td>A のうち、丙欄適用の 日雇労働者の賃金</td>
              <td className={styles.invalidCell} />
              <td className={styles.invalidCell} />
              <td className={styles.tableRightBottom}>円</td>
              <td className={styles.tableRightBottom}>円</td>
            </tr>
            <tr>
              <td>B 源泉徴収票を提出するもの</td>
              <NumberBoxesTd value={statutoryTotalReport?.taxOfficeTotalCount} digit={4} suffixText="人" />
              <td className={styles.invalidCell} />
              <td className={styles.tableRightBottom}>
                <span className={styles.tableNumber}>{numberWithSplit(statutoryTotalReport?.taxOfficeAmountPaid)}</span>円
              </td>
              <td className={styles.tableRightBottom}>
                <span className={styles.tableNumber}>{numberWithSplit(statutoryTotalReport?.taxOfficeWithholdingTaxAmount)}</span>円
              </td>
            </tr>
            <tr>
              <td rowSpan={2}>災害減免法により徴収猶予したもの</td>
              <td className={styles.tableSubHeader}>人員</td>
              <td className={styles.tableSubHeader}>猶予税額</td>
              <td rowSpan={2} colSpan={2} className={styles.tableLeftTop}>
                (概　要)
              </td>
            </tr>
            <tr className={styles.col2Tr}>
              <NumberBoxesTd value={statutoryTotalReport?.disasterSuffererCount} digit={4} suffixText="人" />
              <NumberBoxesTd value={statutoryTotalReport?.taxPostponementAmount} digit={4} suffixText="円" />
            </tr>
          </tbody>
        </table>
        <AggregatedOnMoment aggregatedAt={statutoryTotalReport?.aggregatedAt} />
      </>
    );
  };

  const asyncComponentHandler = () => {
    switch (taskStatus) {
      case 'in_progress':
      case 'waiting':
        return <StatusMessageTable message="集計中です。しばらくお待ち下さい。" />;
      case 'success':
        return <StatutoryTotalReportTable statutoryTotalReport={statutoryTotalReport} />;
      case 'failed':
        return <StatusMessageTable message="法定調書合計表の集計でエラーが発生しました。" />;
      default:
        return <StatutoryTotalReportTable statutoryTotalReport={statutoryTotalReport} />;
    }
  };

  return (
    <div>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">法定調書合計表（税務署提出用）</h1>
      </div>
      <div className="l-contents-wrap">
        <div className={styles.contentsLarge}>
          <div className={styles.linkButtonWrap}>
            <Button as={Link} to={`/${year}/result/tax_office`}>
              源泉徴収票
            </Button>
          </div>
          <div className={`l-overflow-scroll ${styles.legalRecordsTableWrap}`}>{asyncComponentHandler()}</div>
          <div className="l-wrap-s">
            <section className={styles.procedureCard}>
              <h4 className={styles.cardTitle}>作成手順</h4>
              <dl>
                <dt>
                  <div className={styles.emphasizeNumberWrap}>
                    <i className={styles.emphasizeNumber}>
                      <p>1</p>
                    </i>
                    <p className={styles.definitionTerm}>法定調書合計表の用紙を用意してください。</p>
                  </div>
                </dt>
                <dd>
                  税務署から送付された用紙、又は
                  <a
                    className="u-txt-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      year >= 2019
                        ? 'https://www.nta.go.jp/law/tsutatsu/kobetsu/hotei/000601/pdf/07-2.pdf'
                        : 'https://www.nta.go.jp/law/tsutatsu/kobetsu/hotei/000601/pdf/07-3.pdf'
                    }
                  >
                    国税庁
                  </a>
                  からダウンロードし印刷した用紙をご利用ください。
                </dd>
                <dt className={styles.notExistsDescription}>
                  <div className={styles.emphasizeNumberWrap}>
                    <i className={styles.emphasizeNumber}>
                      <p>2</p>
                    </i>
                    <p className={styles.definitionTerm}>源泉徴収票（税務署提出用）ページから税務署に提出する対象を決めてください。</p>
                  </div>
                </dt>
                <dt>
                  <div className={styles.emphasizeNumberWrap}>
                    <i className={styles.emphasizeNumber}>
                      <p>3</p>
                    </i>
                    <p className={styles.definitionTerm}>上記表示された内容を転記してください。</p>
                  </div>
                </dt>
                <dd>【給与所得の源泉徴収票合計表】以外の項目はジョブカンでは出力できません。御了承ください。</dd>
              </dl>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LegalRecords;
