import React from 'react'
import Icon from 'react-icon-base'

const Maternity = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g fill="none" stroke="currentColor" >
      <path fillRule="evenodd" fill="currentColor" strokeWidth=".3" d="M24.01 31.86l-4.012 4.013-4.013-4.012c-.659-.66-.659-1.732 0-2.391.32-.32.744-.495 1.196-.495.45 0 .875.176 1.195.496.32.319.495.742.495 1.194 0 .622.505 1.127 1.127 1.127s1.127-.505 1.127-1.127c0-.452.175-.875.495-1.195.638-.638 1.751-.638 2.39 0 .658.659.658 1.732 0 2.39M19.998 0c4.427 0 8.028 3.601 8.028 8.028 0 2.757-1.397 5.192-3.521 6.639 4.917 1.925 8.45 6.881 8.45 12.631v2.882c0 1.194-.312 2.366-.902 3.394-1.73 3.007-4.128 5.143-7.13 6.345-.137.055-.278.081-.418.081-.446 0-.869-.267-1.046-.708-.232-.578.049-1.233.627-1.464 2.52-1.011 4.543-2.82 6.013-5.377.395-.686.603-1.47.603-2.27v-2.883c0-6.091-4.711-11.133-10.503-11.24-2.905-.068-5.633 1.036-7.7 3.064-2.067 2.03-3.205 4.743-3.205 7.639v.782c1.558-1.977 3.486-3.445 5.778-4.363.581-.231 1.233.05 1.464.626.232.578-.048 1.234-.626 1.465-2.943 1.18-5.205 3.451-6.719 6.748-.054.117-.128.216-.212.304-.022.023-.047.04-.07.06-.077.068-.16.124-.25.168-.034.017-.067.033-.104.046-.123.046-.253.08-.387.08h-.001c-.167-.003-.317-.04-.454-.1-.006-.002-.012-.001-.016-.003-.052-.024-.086-.066-.132-.096-.073-.048-.149-.09-.21-.153-.05-.053-.08-.119-.12-.18-.04-.06-.085-.115-.112-.182-.032-.082-.038-.17-.051-.256-.008-.054-.032-.102-.032-.158v-4.788c0-3.506 1.378-6.79 3.88-9.247 1.317-1.292 2.856-2.254 4.528-2.879-2.098-1.45-3.478-3.869-3.478-6.607C11.97 3.601 15.57 0 19.998 0zm-5.606 27.877c1.49-1.49 4.088-1.49 5.578 0l.028.029.029-.03c1.487-1.488 4.087-1.49 5.576 0 1.537 1.539 1.537 4.04.002 5.578l-4.81 4.809c-.22.22-.509.33-.797.33-.289 0-.577-.11-.797-.33l-4.809-4.809c-1.537-1.538-1.537-4.04 0-5.577zm5.606-25.623c-3.184 0-5.775 2.59-5.775 5.774s2.59 5.775 5.775 5.775c3.184 0 5.774-2.59 5.774-5.775 0-3.184-2.59-5.774-5.774-5.774z"/>
    </g>
  </Icon>
)
Maternity.defaultProps = {
  size: 40
}

export default Maternity
