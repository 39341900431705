import { FC } from 'react';
import { Handicap as HandicapTemplate } from 'features/questions/templates/Handicap';
import { ButtonContainer } from 'features/questions/components/ButtonContainer';
import Button from 'jbc-front/components/Button';
import { Section } from 'components/ui/Section';
import { FormSection } from 'components/form/FormSection';
import { Textarea } from 'components/form/Textarea';
import { FileField } from 'components/form/FileField';
import { useWizard } from '../WizardProvider';

export const Handicap: FC = () => {
  const { question, request } = useWizard();
  const classification = request.profile?.handicapClassification?.classification;
  const isHandicap = classification !== 'none';

  const file = request.profile?.handicapClassification?.image;

  return (
    <HandicapTemplate>
      <HandicapTemplate.CheckHandicap question={question}>
        <ButtonContainer>
          <Button huge primary={classification === 'none'} secondary={classification !== 'none'} disabled>
            対象外
          </Button>
          <Button huge primary={classification == 'normal'} secondary={classification !== 'normal'} disabled>
            一般障害者
          </Button>
          <Button huge primary={classification == 'special'} secondary={classification !== 'special'} disabled>
            特別障害者
          </Button>
        </ButtonContainer>
      </HandicapTemplate.CheckHandicap>
      {isHandicap && (
        <HandicapTemplate.DetailInput>
          <Section>
            <Section.Header>障害者区分</Section.Header>
            <Section.Body>
              <FormSection>
                <Textarea
                  label="障害者詳細情報"
                  note="障害の状態又は交付を受けている手帳などの種類と交付年月日、障害の等級等を記入してください。"
                  value={request.profile?.handicapClassification?.detail}
                  disabled
                />

                <FileField label="障害者手帳画像" disabled>
                  <FileField.FileContainer>{file && <FileField.File file={file} />}</FileField.FileContainer>
                </FileField>
              </FormSection>
            </Section.Body>
          </Section>
        </HandicapTemplate.DetailInput>
      )}
    </HandicapTemplate>
  );
};
