import { FC } from 'react';
import { Attachments as AttachmentsTemplate } from 'features/questions/templates/Attachments';
import { useWizard } from '../WizardProvider';
import { FormSection } from 'components/form/FormSection';
import { FileType } from '@jbc-year-end-adj/types';
import { FileField } from 'components/form/FileField';

export const Attachments: FC = () => {
  const { question, request } = useWizard();
  const profile = request.profile;
  const spouse = profile?.spouse;
  const otherFamilies = profile?.otherFamilies;

  const attachedFiles = [
    { label: '源泉徴収票', images: profile?.formerJobs?.map(formerJob => formerJob.withholdingSlipImage) },
    { label: '学生手帳画像', images: [profile?.workingStudentClassification?.image] },
    { label: '障害者手帳画像', images: [profile?.handicapClassification?.image] },
    { label: '一般の生命保険 証明書画像', images: profile?.generalLifeInsurances?.map(insurance => insurance.image) },
    { label: '介護医療保険 証明書画像', images: profile?.nursingMedicalInsurances?.map(insurance => insurance.image) },
    { label: '個人年金保険 証明書画像', images: profile?.individualAnnuityInsurances?.map(insurance => insurance.image) },
    { label: '地震保険 証明書画像', images: profile?.earthquakeInsurances?.map(insurance => insurance.image) },
    { label: '社会保険 証明書画像', images: profile?.socialInsurances?.map(insurance => insurance.image) },
    {
      label: '独立行政法人中小企業基盤整備機構の共済契約の掛金 証明書画像',
      images: [profile?.premium?.smallAndMediumSizedEnterprisesImage]
    },
    { label: '確定拠出年金法に規定する個人型年金加入者掛金 証明書画像', images: [profile?.premium?.personalPensionImage] },
    {
      label: '確定拠出年金法に規定する企業型年金加入者掛金 証明書画像',
      images: [profile?.premium?.corporatePensionImage]
    },
    { label: '心身障害者扶養共済制度に関する契約の掛金 証明書画像', images: [profile?.premium?.disabilityMutualAidImage] },
    { label: '住宅借入金等特別控除申告書の画像', images: [profile?.housingLoan?.image] },
    { label: '住宅ローンの年末残高証明書の画像', images: [profile?.housingLoan?.certificateImage] },
    {
      label: '配偶者・障害者手帳画像',
      images: [profile?.spouse?.thisYear?.handicapImage, profile?.spouse?.nextYear?.handicapImage]
    },
    {
      label: '扶養親族・障害者手帳画像',
      images: otherFamilies?.map(otherFamily => [otherFamily.thisYear?.handicapImage, otherFamily.nextYear?.handicapImage]).flat()
    },
    {
      label: '扶養親族・親族関係書類',
      images: otherFamilies
        ?.map(otherFamily => [otherFamily.thisYear?.relatedToRelativesDocument, otherFamily.nextYear?.relatedToRelativesDocument])
        .flat()
    },
    {
      label: '扶養親族・送金関係書類',
      images: otherFamilies
        ?.map(otherFamily => [otherFamily.thisYear?.relatedToRemittanceDocument, otherFamily.nextYear?.relatedToRemittanceDocument])
        .flat()
    },
    {
      label: '扶養親族・留学証明書類',
      images: otherFamilies
        ?.map(otherFamily => [otherFamily.thisYear?.provingStudyAbroadDocument, otherFamily.nextYear?.provingStudyAbroadDocument])
        .flat()
    },
    {
      label: '配偶者・親族関係書類',
      images: [spouse?.thisYear?.relatedToRelativesDocument, spouse?.nextYear?.relatedToRelativesDocument]
    },
    {
      label: '配偶者・送金関係書類',
      images: [spouse?.thisYear?.relatedToRemittanceDocument, spouse?.nextYear?.relatedToRemittanceDocument]
    }
  ]
    .map(field => ({ ...field, images: field.images?.filter(f => f && f.file && (f.filename || f.file)) ?? [] }))
    .filter(field => field.images.length > 0);

  return (
    <AttachmentsTemplate question={question}>
      <FormSection>
        <h3>添付済みファイル</h3>
        {attachedFiles.length > 0 ? (
          attachedFiles.map(({ label, images }) => <AttachmentFiles key={label} label={label} images={images} />)
        ) : (
          <span>添付済みのファイルはありません。</span>
        )}

        <h3>追加添付ファイル</h3>
        {profile?.attachments?.length ? (
          <AttachmentFiles images={profile?.attachments?.map(attachment => attachment.file)} />
        ) : (
          <span>添付済みのファイルはありません。</span>
        )}
      </FormSection>
    </AttachmentsTemplate>
  );
};

type AttachmentFilesProps = {
  label?: string;
  images?: (FileType | undefined)[];
};

const AttachmentFiles: FC<AttachmentFilesProps> = ({ label, images = [] }) => {
  const files = images.reduce<FileType[]>((files, image) => {
    if (image && image.file && (image.filename || image?.file)) {
      files.push(image);
    }

    return files;
  }, []);

  if (files.length < 1) return null;

  return (
    <FileField label={label} disabled>
      <FileField.FileContainer>
        {files.map((file, i) => (
          <FileField.File key={i} file={file} />
        ))}
      </FileField.FileContainer>
    </FileField>
  );
};
