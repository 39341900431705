import React from 'react'
import Icon from 'react-icon-base'

const Request = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <path fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M37.5 14.976v16.018a2.361 2.361 0 0 1-2.36 2.361H4.861a2.361 2.361 0 0 1-2.362-2.36V9.006a2.362 2.362 0 0 1 2.362-2.362H37.5L20 20.833l-9.08-7.72"/>
  </Icon>
)
Request.defaultProps = {
  size: 40
}

export default Request
