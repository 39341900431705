import React from 'react'
import Icon from 'react-icon-base'

const EGov = props => (
  <Icon viewBox="0 -5.75 24.5 25.75" {...props}>
    <circle fill="currentColor" cx="16.127" cy="2.564" r="1.375" />
    <path
      fill="currentColor"
      d="M19.885,4.219c-0.501,0-0.907-0.406-0.907-0.906c0-1.842-1.498-3.34-3.34-3.34 c-0.501,0-0.906-0.407-0.906-0.908c0-0.5,0.405-0.907,0.906-0.907c2.842,0,5.154,2.313,5.154,5.155 C20.792,3.812,20.386,4.219,19.885,4.219z"
    />
    <path
      fill="currentColor"
      d="M22.66,4.169c-0.501,0-0.907-0.406-0.907-0.907c0-3.436-2.795-6.231-6.231-6.231 c-0.5,0-0.906-0.406-0.906-0.907c0-0.5,0.406-0.907,0.906-0.907c4.437,0,8.045,3.609,8.045,8.046 C23.566,3.763,23.161,4.169,22.66,4.169z"
    />
    <path
      fill="currentColor"
      d="M14.869,19.034H1.841c-0.5,0-0.907-0.406-0.907-0.907V2.059c0-0.5,0.407-0.906,0.907-0.906h9.208 c0.236,0,0.465,0.092,0.634,0.258l3.818,3.723c0.176,0.171,0.273,0.405,0.273,0.649v12.345 C15.775,18.628,15.37,19.034,14.869,19.034z M2.749,17.221h11.213V6.165l-3.281-3.198H2.749V17.221z"
    />
    <path
      fill="currentColor"
      d="M14.789,7.233l-4.013-0.108C10.275,7.111,9.88,6.693,9.894,6.193l0.109-4.04 c0.014-0.501,0.431-0.896,0.931-0.883c0.501,0.013,0.896,0.431,0.883,0.931l-0.085,3.134l3.107,0.084 c0.501,0.014,0.895,0.431,0.881,0.932C15.707,6.852,15.29,7.246,14.789,7.233z"
    />
    <g>
      <path
        fill="currentColor"
        d="M12.047,9.634H4.87c-0.334,0-0.605-0.272-0.605-0.605S4.536,8.424,4.87,8.424h7.177 c0.334,0,0.605,0.271,0.605,0.604S12.381,9.634,12.047,9.634z"
      />
    </g>
    <g>
      <path
        fill="currentColor"
        d="M12.047,12.156H4.87c-0.334,0-0.605-0.271-0.605-0.605c0-0.333,0.271-0.604,0.605-0.604h7.177 c0.334,0,0.605,0.271,0.605,0.604C12.652,11.885,12.381,12.156,12.047,12.156z"
      />
    </g>
    <g>
      <path
        fill="currentColor"
        d="M12.047,14.682H4.87c-0.334,0-0.605-0.271-0.605-0.604c0-0.334,0.271-0.605,0.605-0.605h7.177 c0.334,0,0.605,0.271,0.605,0.605C12.652,14.41,12.381,14.682,12.047,14.682z"
      />
    </g>
  </Icon>
)
EGov.defaultProps = {
  size: 20
}

export default EGov
