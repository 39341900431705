import React from 'react';
import { useNotify } from '../actions';
import { useMutation, useYear, useSession, useAsyncTask, Modal } from '../components';
import { FIX_DATA, FIX_DATA_ASYNC, UNFIX_DATA } from './query';
import { FixDataModal } from './FixDataModal';
import { UnfixDataModal } from './UnfixDataModal';

const MAX_FIXABLE_COUNT = 400;

const convertMutation = ({ fixed, isExcessive, fixMutation, fixAsyncMutation, unfixMutation }) => {
  if (fixed) {
    return {
      mutation: unfixMutation,
      message: '確定解除しました'
    };
  }

  return {
    mutation: isExcessive ? fixAsyncMutation : fixMutation,
    message: isExcessive ? '確定処理が終わりましたらメールにてご連絡します' : '確定しました'
  };
};

const FixData = ({ refetch, totalCount }) => {
  const {
    clientYearly: { fixed, fixing }
  } = useSession();
  const year = useYear();
  const notify = useNotify();
  const { taskRunningProps } = useAsyncTask();
  const [fixData, { loading: loadingFix }] = useMutation(FIX_DATA);
  const [fixDataAsync, { loading: loadingAsync }] = useMutation(FIX_DATA_ASYNC);
  const [unfixData, { loading: loadingUnfix }] = useMutation(UNFIX_DATA);
  const isExcessive = totalCount > MAX_FIXABLE_COUNT;
  const disabled = loadingFix || loadingAsync || loadingUnfix || taskRunningProps?.disabled || fixing;

  return (
    <Modal>
      {({ showModal, hideModal }) => {
        const handleSubmit = async () => {
          const { mutation, message } = convertMutation({
            fixed,
            isExcessive,
            fixMutation: fixData,
            fixAsyncMutation: fixDataAsync,
            unfixMutation: unfixData
          });

          await mutation({ variables: { year } });
          hideModal();
          window.scrollTo(0, 0);
          notify(message);
          refetch();
        };

        return (
          <>
            {fixed ? (
              <UnfixDataModal
                showModal={showModal}
                hideModal={hideModal}
                onSubmit={handleSubmit}
                disabled={disabled}
                {...taskRunningProps}
              />
            ) : (
              <FixDataModal
                showModal={showModal}
                hideModal={hideModal}
                onSubmit={handleSubmit}
                isExcessive={isExcessive}
                disabled={disabled}
                {...taskRunningProps}
              />
            )}
          </>
        );
      }}
    </Modal>
  );
};

export default FixData;
