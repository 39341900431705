import { FC } from 'react';

import { usePrefectureOptions } from 'hooks/usePrefectureOptions';

import { PostcodeInput } from 'components/form/PostcodeInput';
import { SelectField } from 'components/form/SelectField';
import { Input } from 'components/form/Input';
import { Row } from 'components/form/Row';
import { DependentYearlyInfo } from '../../../../query';

type DifferentAddressProps = {
  yearlyInfo: DependentYearlyInfo;
};

export const DifferentAddress: FC<DifferentAddressProps> = ({ yearlyInfo }) => {
  const { prefectureOptions } = usePrefectureOptions();

  return (
    <>
      <PostcodeInput
        required
        firstCodeProps={{
          value: yearlyInfo.postcode0,
          disabled: true
        }}
        lastCodeProps={{
          value: yearlyInfo.postcode1,
          disabled: true
        }}
      />

      <Row>
        <SelectField
          label="都道府県"
          options={prefectureOptions}
          value={prefectureOptions.find(option => option.value === yearlyInfo.prefectureId)}
          required
          disabled
        />
        <Input label="市区町村" required value={yearlyInfo.city} disabled />
      </Row>

      <Row>
        <Input label="丁目番地号" value={yearlyInfo.street} disabled />
        <Input label="建物名" value={yearlyInfo.building} disabled />
      </Row>
    </>
  );
};
