import { FC } from 'react';
import { Dl, Dt, Dd, FileDd, FileDl } from '../../../../components/DefinitionList';
import { Grid } from '../../../../components/Grid';
import { PreviewSubSection } from '../../../../components/PreviewSubSection';
import { useEmployeeInfo } from '../../EmployeeInfoProvider';
import { amountFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { Section } from 'components/ui/Section';

export const EarthquakeInsurance: FC = () => {
  const { employee } = useEmployeeInfo();
  const profile = employee.profile;

  const newOrOldFormat = (value?: 'new_type' | 'old_type') => {
    switch (value) {
      case 'new_type':
        return '地震';
      case 'old_type':
        return '旧長期';
      default:
        return undefined;
    }
  };

  return (
    <Section>
      <Section.Header>地震保険</Section.Header>
      <Section.Body>
        <Grid colGap>
          {profile.earthquakeInsurances.length === 0 && <p>地震保険情報はありません。</p>}
          {profile.earthquakeInsurances.map((insurance, index) => (
            <PreviewSubSection key={index}>
              <PreviewSubSection.Header>地震保険（{index + 1}）</PreviewSubSection.Header>
              <PreviewSubSection.Body>
                <Dl>
                  <Dt>保険会社等の名称</Dt>
                  <Dd>{insurance.name}</Dd>
                </Dl>

                <Dl>
                  <Dt>保険等の種類（目的）</Dt>
                  <Dd>{insurance.insuranceType}</Dd>
                </Dl>

                <Dl>
                  <Dt>保険期間</Dt>
                  <Dd>{insurance.insuranceDuration}</Dd>
                </Dl>

                <Dl>
                  <Dt>保険等の契約者の氏名</Dt>
                  <Dd>{insurance.nameOfContractor}</Dd>
                </Dl>

                <Dl>
                  <Dt>保険等の受取人 氏名</Dt>
                  <Dd>{insurance.nameOfRecipient}</Dd>
                </Dl>

                <Dl>
                  <Dt>保険等の受取人 続柄</Dt>
                  <Dd>{insurance.relationshipOfRecipient}</Dd>
                </Dl>

                <Dl>
                  <Dt>保険料区分</Dt>
                  <Dd>{newOrOldFormat(insurance.newOrOld)}</Dd>
                </Dl>

                <Dl>
                  <Dt>保険料金額</Dt>
                  <Dd>{amountFormat(insurance.amount)}</Dd>
                </Dl>

                <FileDl>
                  <Dt>証明書画像</Dt>
                  <FileDd file={insurance.image} />
                </FileDl>
              </PreviewSubSection.Body>
            </PreviewSubSection>
          ))}
        </Grid>
      </Section.Body>
    </Section>
  );
};
