import { gql } from '@apollo/client';
import { MaritalStatus, ResidenceStatus, HandicapType, Gender } from '@jbc-year-end-adj/types';
import { Schema, YearlyInfoSchema } from './schema';
import { FileType } from '@jbc-year-end-adj/types';

type Spouse = {
  lastName: string;
  firstName: string;
  lastNameKana: string;
  firstNameKana: string;
  birthday: string;
  gender: Gender;
  dependentFrom?: string;
  dependentReason?: string;
  diedOn?: string;
  thisYear?: RequestYearlyInfo;
  nextYear?: RequestYearlyInfo;
  income?: Income;
};

type Income = {
  salaryIncome?: string;
  businessIncome?: string;
  businessExpense?: string;
  miscellaneousIncomeOfficialPension?: string;
  miscellaneousIncomeOther?: string;
  miscellaneousExpense?: string;
  dividendIncome?: string;
  dividendExpense?: string;
  realEstateIncome?: string;
  realEstateExpense?: string;
  retirementIncome?: string;
  lengthOfService?: string;
  retireForDisablity?: boolean;
  isOfficerRetirementAllowance?: boolean;
  otherIncome?: string;
  otherExpense?: string;
  otherExpenseSpecialDeduction?: string;
  otherEarings?: string;
};

type RequestYearlyInfo = {
  dependentTaxLaw?: boolean;
  residenceStatus?: ResidenceStatus;
  postcode0?: string;
  postcode1?: string;
  prefectureId?: string;
  city?: string;
  street?: string;
  building?: string;
  addressForeign?: string;
  isNonResident?: boolean;
  relatedToRelativesDocument?: File | FileType;
  relatedToRemittanceDocument?: File | FileType;
  remittance?: string;
  handicapType?: HandicapType;
  handicapDetail?: string;
  handicapImage?: File | FileType;
  earnings?: string;
};

export type QueryVariables = {
  input: {
    employeeId: string;
    attributes: {
      maritalStatus: MaritalStatus;
      spouse?: Spouse;
    };
  };
};

export const UPDATE_REQUEST_SPOUSE = gql`
  mutation UpdateRequestSpouse($input: UpdateRequestSpouseInput!) {
    updateRequestSpouse(input: $input) {
      employee {
        id
      }
    }
  }
`;

export const convertFormDataToQueryVariables = (employeeId: string, data: Schema): QueryVariables => {
  const input: QueryVariables['input'] = {
    employeeId,
    attributes: {
      maritalStatus: data.maritalStatus,
      spouse: undefined
    }
  };

  if (data.maritalStatus !== 'unmarried' && data.spouse) {
    input.attributes.spouse = {
      lastName: data.spouse.lastName,
      firstName: data.spouse.firstName,
      lastNameKana: data.spouse.lastNameKana,
      firstNameKana: data.spouse.firstNameKana,
      birthday: data.spouse.birthday,
      gender: data.spouse.gender
    };

    const thisYearDependentTaxLaw = data.spouse.thisYear?.dependentTaxLaw === 'true';
    const thisYearHasRetirementIncome = data.spouse.thisYear?.hasSpouseRetirementIncome === 'true';
    const nextYearDependentTaxLaw = data.spouse.nextYear?.dependentTaxLaw === 'true';

    if (thisYearDependentTaxLaw || thisYearHasRetirementIncome || nextYearDependentTaxLaw) {
      input.attributes.spouse.dependentFrom = optionalFormatter('', data.spouse.dependentFrom);
      input.attributes.spouse.dependentReason = data.spouse.dependentReason;
      input.attributes.spouse.diedOn = optionalFormatter('', data.spouse.diedOn);
    }

    if (data.spouse.thisYear && (thisYearDependentTaxLaw || thisYearHasRetirementIncome)) {
      input.attributes.spouse.thisYear = {
        dependentTaxLaw: thisYearDependentTaxLaw,
        residenceStatus: data.spouse.thisYear.yearlyInfo.residenceStatus,
        ...convertYearlyInfoByResidenceStatus(data.spouse.thisYear.yearlyInfo),
        handicapType: data.spouse.thisYear.yearlyInfo.handicapType,
        handicapDetail: data.spouse.thisYear.yearlyInfo.handicapDetail,
        handicapImage: data.spouse.thisYear.yearlyInfo.handicapImage
      };

      input.attributes.spouse.income = {
        salaryIncome: data.spouse.thisYear.yearlyInfo.income?.salaryIncome || '0',
        businessIncome: data.spouse.thisYear.yearlyInfo.income?.businessIncome || '0',
        businessExpense: data.spouse.thisYear.yearlyInfo.income?.businessExpense || '0',
        miscellaneousIncomeOfficialPension: data.spouse.thisYear.yearlyInfo.income?.miscellaneousIncomeOfficialPension || '0',
        miscellaneousIncomeOther: data.spouse.thisYear.yearlyInfo.income?.miscellaneousIncomeOther || '0',
        miscellaneousExpense: data.spouse.thisYear.yearlyInfo.income?.miscellaneousExpense || '0',
        dividendIncome: data.spouse.thisYear.yearlyInfo.income?.dividendIncome || '0',
        dividendExpense: data.spouse.thisYear.yearlyInfo.income?.dividendExpense || '0',
        realEstateIncome: data.spouse.thisYear.yearlyInfo.income?.realEstateIncome || '0',
        realEstateExpense: data.spouse.thisYear.yearlyInfo.income?.realEstateExpense || '0',
        retirementIncome: data.spouse.thisYear.yearlyInfo.income?.retirementIncome || '0',
        lengthOfService: data.spouse.thisYear.yearlyInfo.income?.lengthOfService || '0',
        retireForDisablity: data.spouse.thisYear.yearlyInfo.income?.retireForDisablity,
        isOfficerRetirementAllowance: data.spouse.thisYear.yearlyInfo.income?.isOfficerRetirementAllowance,
        otherIncome: data.spouse.thisYear.yearlyInfo.income?.otherIncome || '0',
        otherExpense: data.spouse.thisYear.yearlyInfo.income?.otherExpense || '0',
        otherExpenseSpecialDeduction: data.spouse.thisYear.yearlyInfo.income?.otherExpenseSpecialDeduction || '0',
        otherEarings: data.spouse.thisYear.yearlyInfo.income?.otherEarings || '0'
      };
    }

    if (data.spouse.nextYear) {
      input.attributes.spouse.nextYear = {
        dependentTaxLaw: nextYearDependentTaxLaw,
        residenceStatus: data.spouse.nextYear.yearlyInfo.residenceStatus,
        ...convertYearlyInfoByResidenceStatus(data.spouse.nextYear.yearlyInfo),
        handicapType: data.spouse.nextYear.yearlyInfo.handicapType,
        handicapDetail: data.spouse.nextYear.yearlyInfo.handicapDetail,
        handicapImage: data.spouse.nextYear.yearlyInfo.handicapImage,
        earnings: optionalFormatter('', data.spouse.nextYear.yearlyInfo.earnings)
      };
    }
  }

  return { input };
};

const optionalFormatter = <T>(target: T, value: T) => {
  return target === value ? undefined : value;
};

const convertYearlyInfoByResidenceStatus = (yearlyInfo: YearlyInfoSchema['yearlyInfo']) => {
  switch (yearlyInfo.residenceStatus) {
    case 'same_address':
      return {
        relatedToRelativesDocument: undefined,
        relatedToRemittanceDocument: undefined
      };
    case 'different_address':
      return {
        postcode0: yearlyInfo.postcode0,
        postcode1: yearlyInfo.postcode1,
        prefectureId: yearlyInfo.prefectureId,
        city: yearlyInfo.city,
        street: yearlyInfo.street,
        building: yearlyInfo.building,
        relatedToRelativesDocument: undefined,
        relatedToRemittanceDocument: undefined
      };
    case 'different_and_foreign_address':
      return {
        addressForeign: yearlyInfo.addressForeign,
        isNonResident: yearlyInfo.isNonResident,
        remittance: yearlyInfo.remittance || '0',
        relatedToRelativesDocument: yearlyInfo.relatedToRelativesDocument,
        relatedToRemittanceDocument: yearlyInfo.relatedToRemittanceDocument
      };
  }
};
