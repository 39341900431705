// MEMO: 年度の更新があるたびにcaseを追加する
export const calcNewTypeDeduction = (newTypeTotalAmount: number, year?: number) => {
  switch (year) {
    default:
      return calcNewTypeDeduction2023(newTypeTotalAmount);
  }
};

const calcNewTypeDeduction2023 = (totalAmount: number) => {
  if (totalAmount < 20000) {
    return totalAmount;
  } else if (totalAmount <= 40000) {
    return Math.ceil(totalAmount / 2 + 10000);
  } else if (totalAmount <= 80000) {
    return Math.ceil(totalAmount / 4 + 20000);
  } else {
    return 40000;
  }
};

// MEMO: 年度の更新があるたびにcaseを追加する
export const calcOldTypeDeduction = (oldTypeTotalAmount: number, year?: number) => {
  switch (year) {
    default:
      return calcOldTypeDeduction2023(oldTypeTotalAmount);
  }
};

const calcOldTypeDeduction2023 = (totalAmount: number) => {
  if (totalAmount < 25000) {
    return totalAmount;
  } else if (totalAmount <= 50000) {
    return Math.ceil(totalAmount / 2 + 12500);
  } else if (totalAmount <= 100000) {
    return Math.ceil(totalAmount / 4 + 25000);
  } else {
    return 50000;
  }
};

export const calcDeduction = (newTypeDeduction: number, oldTypeDeduction: number, year?: number) => {
  switch (year) {
    default:
      return calcDeduction2023(newTypeDeduction, oldTypeDeduction);
  }
};

const calcDeduction2023 = (newTypeDeduction: number, oldTypeDeduction: number) => {
  if (oldTypeDeduction >= 50000) return 50000;
  if (oldTypeDeduction >= 40000) return oldTypeDeduction;

  const totalDeduction = oldTypeDeduction + newTypeDeduction;
  return oldTypeDeduction + newTypeDeduction >= 40000 ? 40000 : totalDeduction;
};

export const calcTotalDeduction = (
  generalLifeInsuranceDeduction: number,
  nursingMedicalInsuranceDeduction: number,
  individualAnnuityInsuranceDeduction: number,
  year?: number
) => {
  switch (year) {
    default:
      return calcTotalDeduction2023(generalLifeInsuranceDeduction, nursingMedicalInsuranceDeduction, individualAnnuityInsuranceDeduction);
  }
};

const calcTotalDeduction2023 = (
  generalLifeInsuranceDeduction: number,
  nursingMedicalInsuranceDeduction: number,
  individualAnnuityInsuranceDeduction: number
) => {
  const totalDeduction = generalLifeInsuranceDeduction + nursingMedicalInsuranceDeduction + individualAnnuityInsuranceDeduction;
  return totalDeduction <= 120000 ? totalDeduction : 120000;
};
