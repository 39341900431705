import React from 'react'
import Icon from 'react-icon-base'

const Custom9 = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="Custom9" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon/employees/Custom7-Copy-2" fill="#3498DB" fillRule="nonzero">
        <g id="Group-3" transform="translate(3.000000, 2.000000)">
          <g id="Group" transform="translate(0.000000, 24.000000)">
            <path d="M6.649202,-0.192159547 C6.93942371,-0.662042307 7.55561012,-0.807686368 8.02549288,-0.517464664 C8.46181259,-0.247973082 8.61855913,0.30257991 8.40635763,0.756069819 L8.350798,0.858826214 L1.350798,12.1921595 C1.06057629,12.6620423 0.444389878,12.8076864 -0.0254928807,12.5174647 C-0.461812586,12.2479731 -0.618559128,11.6974201 -0.406357628,11.2439302 L-0.350797997,11.1411738 L6.649202,-0.192159547 Z" id="Line-3"></path>
            <path d="M32.649202,-0.192159547 C32.9394237,-0.662042307 33.5556101,-0.807686368 34.0254929,-0.517464664 C34.4618126,-0.247973082 34.6185591,0.30257991 34.4063576,0.756069819 L34.350798,0.858826214 L27.350798,12.1921595 C27.0605763,12.6620423 26.4443899,12.8076864 25.9745071,12.5174647 C25.5381874,12.2479731 25.3814409,11.6974201 25.5936424,11.2439302 L25.649202,11.1411738 L32.649202,-0.192159547 Z" id="Line-3-Copy" transform="translate(30.000000, 6.000000) scale(-1, 1) translate(-30.000000, -6.000000) "></path>
            <polygon id="Line-5" points="28 2.5 28 4.5 6 4.5 6 2.5"></polygon>
            <polygon id="Line-5-Copy" points="30 5.5 30 7.5 4 7.5 4 5.5"></polygon>
            <polygon id="Line-5-Copy-2" points="32 8.5 32 10.5 2 10.5 2 8.5"></polygon>
          </g>
          <g id="Group-2" transform="translate(3.879080, 0.000000)">
            <path d="M20.1209201,0 C20.6732048,0 21.1209201,0.44771525 21.1209201,1 L21.1208294,2 L23.1619887,2 C24.8038609,2 26.1407196,3.31984708 26.1617439,4.96158468 L26.1981976,8.122619 L26.228008,11.9472603 L26.234052,14.4705435 L26.2251569,16.5039435 L26.2111415,17.6306736 L26.1845262,18.7816075 L26.1710503,19.1618766 L26.1392472,19.7850929 L26.1209201,20.02804 C25.9699576,21.7641089 25.3032909,23.4214289 24.1209201,25 L2.12092008,25 C0.948813513,23.5440861 0.282146847,21.8890752 0.120920077,20.0349674 L0.0896353844,19.5880487 L0.0551935558,18.8046576 L0.0348155057,18.0764897 L0.0149158672,16.9180998 L0.00172700752,15.1687741 L0.00261598741,12.741849 L0.0269822081,9.18019591 L0.0769388403,4.95864396 C0.0995562665,3.31807351 1.4359274,2 3.07665376,2 L5.12082943,2 L5.12092008,1 C5.12092008,0.44771525 5.56863533,0 6.12092008,0 L20.1209201,0 Z M19.12,2 L7.12,2 L7.12064815,4 L3.07665376,4 C2.52974471,4 2.08428794,4.43935769 2.07674878,4.98621547 L2.02187163,9.74462478 L2.00260995,12.7440251 L2.0017148,15.1617864 L2.0135809,16.7926533 L2.03644774,18.1229617 L2.06986972,19.1476629 L2.09480401,19.6136919 L2.11340125,19.8617081 C2.2030668,20.892862 2.48691982,21.8343236 2.97203035,22.7029254 L3.148,23 L23.061,23 L23.0805188,22.9697532 C23.6364622,22.0429404 23.9713408,21.10443 24.0970044,20.1436826 L24.1284389,19.8547808 L24.1451284,19.6312529 L24.1744022,19.0419398 L24.1980583,18.2648851 L24.2120096,17.5602857 L24.2314855,15.5161186 L24.234052,14.4709333 L24.2280255,11.9557474 L24.1920184,7.53914894 L24.1619079,4.98719488 C24.1554004,4.47903777 23.7707025,4.06339691 23.2775865,4.00661261 L23.1619887,4 L19.1206481,4 L19.12,2 Z" id="Combined-Shape"></path>
            <path d="M22.1209201,5 C22.6732048,5 23.1209201,5.44771525 23.1209201,6 L23.1209201,13 C23.1209201,13.5522847 22.6732048,14 22.1209201,14 L4.12092008,14 C3.56863533,14 3.12092008,13.5522847 3.12092008,13 L3.12092008,6 C3.12092008,5.44771525 3.56863533,5 4.12092008,5 L22.1209201,5 Z M21.12,7 L5.12,7 L5.12,12 L21.12,12 L21.12,7 Z" id="Rectangle"></path>
            <path d="M5.62092008,15 C7.00163195,15 8.12092008,16.1192881 8.12092008,17.5 C8.12092008,18.8807119 7.00163195,20 5.62092008,20 C4.2402082,20 3.12092008,18.8807119 3.12092008,17.5 C3.12092008,16.1192881 4.2402082,15 5.62092008,15 Z M5.62092008,16 C4.79249295,16 4.12092008,16.6715729 4.12092008,17.5 C4.12092008,18.3284271 4.79249295,19 5.62092008,19 C6.4493472,19 7.12092008,18.3284271 7.12092008,17.5 C7.12092008,16.6715729 6.4493472,16 5.62092008,16 Z" id="Oval"></path>
            <path d="M20.6209201,15 C22.001632,15 23.1209201,16.1192881 23.1209201,17.5 C23.1209201,18.8807119 22.001632,20 20.6209201,20 C19.2402082,20 18.1209201,18.8807119 18.1209201,17.5 C18.1209201,16.1192881 19.2402082,15 20.6209201,15 Z M20.6209201,16 C19.792493,16 19.1209201,16.6715729 19.1209201,17.5 C19.1209201,18.3284271 19.792493,19 20.6209201,19 C21.4493472,19 22.1209201,18.3284271 22.1209201,17.5 C22.1209201,16.6715729 21.4493472,16 20.6209201,16 Z" id="Oval-Copy"></path>
          </g>
        </g>
      </g>
    </g>
  </Icon>
)
Custom9.defaultProps = {
  size: 30
}

export default Custom9