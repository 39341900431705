import { FC, ComponentProps } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { FileField as UnControlledField } from '../../form/FileField';

type FileFieldProps = ComponentProps<typeof UnControlledField> & {
  name: string;
};

const ControlledFileField: FC<FileFieldProps> = ({ name, ...fileFieldProps }) => {
  const { control } = useFormContext();
  const { field } = useController({
    name,
    control
  });

  const handleDrop = (file: File[]) => {
    if (fileFieldProps.onDrop) {
      field.onChange(file);
    } else {
      field.onChange(file[0]);
    }
  };

  const placeholder = fileFieldProps.placeholder || 'ファイルを選択してください';

  return <UnControlledField {...fileFieldProps} placeholder={placeholder} onDrop={fileFieldProps.onDrop || handleDrop} />;
};

export const FileField = Object.assign(ControlledFileField, {
  FileContainer: UnControlledField.FileContainer,
  File: UnControlledField.File
});
