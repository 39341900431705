import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { gql } from '@apollo/client';
import Button from 'jbc-front/components/Button';
import { Checkbox } from 'jbc-front/components/presenters/form/Checkbox';
import { useForm, FormProvider } from 'react-hook-form';
import moment from 'moment';
import { DateField } from '../components/react-hook-form/DateField';
import { SelectField } from '../components/react-hook-form/SelectField';
import { useNotify } from '../actions';
import { useMutation, useYear, Modal, Loading, convertSelectedIds, ASYNC_TASK_FRAGMENT } from '../components';
import { MIN_REQUEST_COUNT, MAX_REQUEST_COUNT } from '../pages/WithholdingSlipDeliver';
import { schema } from './schema';
import styles from '../city_report/Report.scss';

const RESERVE_WITHHOLDING_SLIPS = gql`
  mutation reserveWithholdingSlips($input: ReserveWithholdingSlipsInput!) {
    reserveWithholdingSlips(input: $input) {
      withholdingSlips {
        id
        withholdingSlipPublish {
          id
          status
          generatedAt
        }
      }
    }
  }
`;

const RESERVE_WITHHOLDING_SLIPS_ASYNC = gql`
  mutation reserveWithholdingSlipsAsync($input: ReserveWithholdingSlipsAsyncInput!) {
    reserveWithholdingSlipsAsync(input: $input) {
      clientYearly {
        id
        sendWithholdingSlipForEmployeesRunning
        withholdingSlipPublishRunning
        asyncTask {
          ...AsyncTaskFields
        }
      }
    }
  }
  ${ASYNC_TASK_FRAGMENT}
`;

const CANCEL_WITHHOLDING_SLIP_PUBLISH = gql`
  mutation cancelWithholdingSlipPublish($input: CancelWithholdingSlipPublishInput!) {
    cancelWithholdingSlipPublish(input: $input) {
      withholdingSlip {
        id
        withholdingSlipPublish {
          id
          status
          generatedAt
        }
      }
    }
  }
`;

const convertPublishMutation = (selectedTotalCount, mutation, asyncMutation) => ({
  reserveMutation: selectedTotalCount > MAX_REQUEST_COUNT ? asyncMutation : mutation,
  message: selectedTotalCount > MIN_REQUEST_COUNT ? 'マイページ一括公開予約を開始しました' : 'マイページ公開予約を開始しました'
});

const makeDisabledReason = (type, isPreparing, isPublishing, loading) => {
  if (isPreparing) {
    return '源泉徴収票交付の準備を行っているため操作できません';
  }

  if (isPublishing) {
    return 'マイページ公開処理中のため操作できません';
  }

  if (loading) {
    return null;
  }

  if (type === 'publish') {
    return '従業員招待を行ってください';
  }
};

export const OpenMyPageModal = ({ children, selectedIds, allSelectMode = false, selectedTotalCount, isNotSelected, reset }) => {
  const year = useYear();
  const notify = useNotify();
  const [reserveWithholdingSlips, { loading: loadingPublish }] = useMutation(RESERVE_WITHHOLDING_SLIPS);
  const [reserveWithholdingSlipsAsync, { loading: loadingPublishAsync }] = useMutation(RESERVE_WITHHOLDING_SLIPS_ASYNC);
  const loading = loadingPublish || loadingPublishAsync;
  const methods = useForm({
    defaultValues: { reservedTime: { date: moment().format('YYYY/MM/DD'), time: '09' }, isSentMail: false },
    resolver: yupResolver(schema),
    mode: 'onSubmit'
  });
  const {
    formState: { errors },
    handleSubmit,
    register
  } = methods;
  const onSubmit = hideModal => async data => {
    if (isNotSelected) {
      return notify('従業員を選択してください', 'error');
    }
    const { reserveMutation, message } = convertPublishMutation(selectedTotalCount, reserveWithholdingSlips, reserveWithholdingSlipsAsync);
    const { employeeIds, exceptEmployeeIds } = convertSelectedIds(allSelectMode, selectedIds);
    const {
      reservedTime: { date, time },
      isSentMail
    } = data;
    const reservedTime = new Date(`${date.replace(/\//g, '-')}T${time}:00:00`);
    await reserveMutation({
      variables: {
        input: { year, allSelectMode, employeeIds, exceptEmployeeIds, reservedTime, isSentMail }
      }
    });
    notify(message);
    hideModal();
    if (reset) {
      reset();
    }
  };

  const options = [
    { label: '0時', value: '00' },
    { label: '1時', value: '01' },
    { label: '2時', value: '02' },
    { label: '3時', value: '03' },
    { label: '4時', value: '04' },
    { label: '5時', value: '05' },
    { label: '6時', value: '06' },
    { label: '7時', value: '07' },
    { label: '8時', value: '08' },
    { label: '9時', value: '09' },
    { label: '10時', value: '10' },
    { label: '11時', value: '11' },
    { label: '12時', value: '12' },
    { label: '13時', value: '13' },
    { label: '14時', value: '14' },
    { label: '15時', value: '15' },
    { label: '16時', value: '16' },
    { label: '17時', value: '17' },
    { label: '18時', value: '18' },
    { label: '19時', value: '19' },
    { label: '20時', value: '20' },
    { label: '21時', value: '21' },
    { label: '22時', value: '22' },
    { label: '23時', value: '23' }
  ];

  return (
    <Modal>
      {({ showModal, hideModal }) => (
        <>
          {children({ showModal, loading })}
          <Modal.Modal>
            <FormProvider {...methods}>
              <form>
                <Modal.Header>マイページを公開する</Modal.Header>
                <Modal.Body>
                  <div className={styles.body}>
                    <div className={styles.selectWrap}>
                      <div className="u-mb20">
                        <DateField
                          label="従業員への源泉徴収票公開日時"
                          required
                          name="reservedTime.date"
                          error={errors.reservedTime?.date?.message}
                        />
                      </div>
                      <div className="u-mb20">
                        <SelectField
                          label=" "
                          required
                          name="reservedTime.time"
                          options={options}
                          error={errors.reservedTime?.time?.message}
                        />
                      </div>
                    </div>
                    <div className="u-mb20">
                      <Checkbox {...register('isSentMail')}>公開時に通知メールも送信する</Checkbox>
                    </div>
                    <div>
                      公開をすると選択された従業員はマイページから源泉徴収票を閲覧
                      <br />
                      できます。源泉徴収票を公開しますか？
                      <br />
                      <br />
                      <p className={styles.red}>
                        ※午前8時～10時といった時間帯や20日・25日・末日は集中しやすい為、
                        <br />
                        設定された場合、通知の遅延が発生するおそれがございます。
                        <br />
                        ※選択した従業員のマイページが未発行の場合は公開されません。
                      </p>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Modal.Buttons>
                    <Button onClick={hideModal} disabled={loading}>
                      キャンセル
                    </Button>
                    <Button onClick={handleSubmit(onSubmit(hideModal))} primary disabled={loading}>
                      公開
                    </Button>
                  </Modal.Buttons>
                </Modal.Footer>
              </form>
            </FormProvider>
          </Modal.Modal>
        </>
      )}
    </Modal>
  );
};

const NotOpenMyPageModal = ({ children, employeeId }) => {
  const notify = useNotify();
  const [cancelWithholdingSlipPublish, { loading }] = useMutation(CANCEL_WITHHOLDING_SLIP_PUBLISH);

  return (
    <Modal>
      {({ showModal, hideModal }) => (
        <>
          {children({ showModal, loading })}
          <Modal.Modal>
            <Modal.Header>マイページの公開を取り消す</Modal.Header>
            <Modal.Body>
              公開を取り消すと従業員が源泉徴収票を閲覧できなくなり、交付
              <br />
              が無効になります。源泉徴収票公開を取り消しますか？
            </Modal.Body>
            <Modal.Footer>
              <Modal.Buttons>
                <Button onClick={hideModal} disabled={loading}>
                  いいえ
                </Button>
                <Button
                  primary
                  onClick={async () => {
                    await cancelWithholdingSlipPublish({
                      variables: {
                        input: { employeeId }
                      }
                    });
                    notify('マイページの公開を取り消しました');
                    hideModal();
                  }}
                  disabled={loading}
                >
                  はい
                </Button>
              </Modal.Buttons>
            </Modal.Footer>
          </Modal.Modal>
        </>
      )}
    </Modal>
  );
};

export const MyPageButton = ({ withholdingSlipPublish, accountStatuses, employeeId, isPreparing, isPublishing, buttonStyle }) => {
  if (!withholdingSlipPublish || withholdingSlipPublish.status === 'canceled' || withholdingSlipPublish.status === 'failed') {
    return (
      <OpenMyPageModal selectedIds={[employeeId]}>
        {({ showModal, loading }) => (
          <Button
            primary
            className={buttonStyle}
            widthAuto
            onClick={showModal}
            disabled={
              !accountStatuses.some(({ accountStatus }) => accountStatus === 'registered') || loading || isPreparing || isPublishing
            }
            disabledReason={makeDisabledReason('publish', isPreparing, isPublishing, loading)}
          >
            {withholdingSlipPublish?.status === 'failed' ? 'エラー' : '公開'}
          </Button>
        )}
      </OpenMyPageModal>
    );
  }

  if (withholdingSlipPublish.status === 'waiting' || withholdingSlipPublish.status === 'in_progress') {
    return (
      <Button primary className={buttonStyle} widthAuto disabled>
        <Loading className={styles.loading} />
        公開
      </Button>
    );
  }

  const reservedTime = withholdingSlipPublish?.reservedTime;
  const lastPublishedAt = withholdingSlipPublish?.lastPublishedAt;
  const status = withholdingSlipPublish?.status;
  const isReservedOrSuccess = status === 'reserved' || status === 'success';
  const displayDate = status === 'reserved' ? reservedTime : lastPublishedAt;

  return (
    <>
      {isReservedOrSuccess && displayDate && (
        <>
          <div>{status === 'reserved' ? '公開日' : '公開済'}</div>
          <div className={styles.date}>{moment(displayDate).format('YYYY/MM/DD HH:mm')}</div>
        </>
      )}
      <NotOpenMyPageModal employeeId={employeeId}>
        {({ showModal, loading }) => (
          <Button
            className={buttonStyle}
            widthAuto
            onClick={showModal}
            disabled={loading || isPreparing || isPublishing}
            disabledReason={makeDisabledReason('cancel', isPreparing, isPublishing, loading)}
          >
            {status === 'reserved' ? '予約済' : '公開済'}
          </Button>
        )}
      </NotOpenMyPageModal>
    </>
  );
};
