import { FC, ReactNode } from 'react';
import { Section } from 'components/ui/Section';

import { Question } from '../type';

import { Lead } from './parts/Lead';
import { Description } from './parts/Description';
import { Content } from './parts/Content';

type BasicInfosProps = {
  question: Question;
  children: ReactNode;
};

export const BasicInfos: FC<BasicInfosProps> = ({ question, children }) => {
  const description = question.questionDescriptions.find(description => description.field == 'top')?.description;

  return (
    <Section>
      <Section.Header>氏名・住所等</Section.Header>

      <Section.Body>
        <Content>
          <Lead>個人情報を確認します</Lead>

          <Description>{description}</Description>

          {children}
        </Content>
      </Section.Body>
    </Section>
  );
};
