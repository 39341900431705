import { ApolloClient, ApolloLink } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { onError, ErrorLink } from '@apollo/client/link/error';

// @ts-ignore
import { createUploadLink } from 'apollo-upload-client';

export default (uri: string) => {
  // エラー発生時にconsole.logに出す
  const handleError: ErrorLink.ErrorHandler = ({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      });
    }

    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  };

  return new ApolloClient({
    link: ApolloLink.from([onError(handleError), createUploadLink({ uri, credentials: 'same-origin' })]),
    cache: new InMemoryCache({
      possibleTypes: {
        InsuranceAmountSum: ['InsuranceAmountAutoSum', 'InsuranceAmountFinalSum']
      }
    })
  });
};
