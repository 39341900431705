// @ts-ignore
import { useDispatch } from 'react-redux';
// @ts-ignore
import { addNotification } from 'reapop';

type NotifyStatus = 'success' | 'none' | 'info' | 'loading' | 'success' | 'warning' | 'error';
type Notify = (message: string, status: NotifyStatus) => ReturnType<typeof addNotification>;

const notify: Notify = (message, status = 'success') => {
  return addNotification({
    message,
    position: 'tc',
    status,
    dismissAfter: 3000
  });
};

export const useNotify = () => {
  const dispatch = useDispatch();
  return (...args: Parameters<Notify>) => dispatch(notify(...args));
};
