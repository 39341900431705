import React from 'react'
import Icon from 'react-icon-base'

const Comment = (props) => (
  <Icon viewBox="0 0 30 30" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fill="#979797" fillRule="nonzero">
        <path d="M20.0505799,0 C22.5108919,0 24.5,2.02421101 24.5,4.51470588 L24.5,8.507 L26,8.50765477 C28.209139,8.50765477 30,10.2985158 30,12.5076548 L30,21.5076548 C30,23.135584 29.0275063,24.5363751 27.6317763,25.1607706 L27.6315789,28.7258558 C27.6315789,28.9793534 27.5353047,29.2233904 27.3622287,29.4086083 C26.9851549,29.812135 26.3523531,29.8335793 25.9488264,29.4565055 L21.7232373,25.5076548 L9.13123731,25.5076548 C6.92209831,25.5076548 5.13123731,23.7167938 5.13123731,21.5076548 L5.131,17.5 L4.44942008,17.5 C1.98910807,17.5 0,15.475789 0,12.9852941 L0,4.51470588 C0,2.02421101 1.98910807,0 4.44942008,0 L20.0505799,0 Z M26,10.0076548 L9.13123731,10.0076548 C7.75052543,10.0076548 6.63123731,11.1269429 6.63123731,12.5076548 L6.63123731,21.5076548 C6.63123731,22.8883666 7.75052543,24.0076548 9.13123731,24.0076548 L22.3150262,24.0076548 L22.747403,24.4117147 L26.1312373,27.5736548 L26.1318301,24.1885303 L27.0192354,23.7915395 C27.9128766,23.3917591 28.5,22.5035465 28.5,21.5076548 L28.5,12.5076548 C28.5,11.1269429 27.3807119,10.0076548 26,10.0076548 Z M12.5,15 C13.3284271,15 14,15.6715729 14,16.5 C14,17.3284271 13.3284271,18 12.5,18 C11.6715729,18 11,17.3284271 11,16.5 C11,15.6715729 11.6715729,15 12.5,15 Z M17.5,15 C18.3284271,15 19,15.6715729 19,16.5 C19,17.3284271 18.3284271,18 17.5,18 C16.6715729,18 16,17.3284271 16,16.5 C16,15.6715729 16.6715729,15 17.5,15 Z M22.5,15 C23.3284271,15 24,15.6715729 24,16.5 C24,17.3284271 23.3284271,18 22.5,18 C21.6715729,18 21,17.3284271 21,16.5 C21,15.6715729 21.6715729,15 22.5,15 Z M20.0505799,1.5 L4.44942008,1.5 C2.8234655,1.5 1.5,2.84682146 1.5,4.51470588 L1.5,12.9852941 C1.5,14.6531785 2.8234655,16 4.44942008,16 L5.131,16 L5.13123731,12.5076548 C5.13123731,10.2985158 6.92209831,8.50765477 9.13123731,8.50765477 L23,8.507 L23,4.51470588 C23,2.84682146 21.6765345,1.5 20.0505799,1.5 Z"></path>
      </g>
    </g>
  </Icon>
)
Comment.defaultProps = {
  size: 30
}

export default Comment