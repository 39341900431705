import React from 'react'
import Icon from 'react-icon-base'

const Custom33 = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="Custom33" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon/employees/Custom7-Copy-21" fill="#3498DB">
        <path d="M8.2297363,3.34116011 C14.2375722,0.819860706 16.9509509,3.82735771 16.1209361,11.3011785 L16.081,11.632 L16.1829599,11.595709 C17.2108793,11.2453982 18.3456184,11.0539951 19.5835421,11.0192176 L20,11.0134229 C20.5522847,11.0134229 21,11.4611381 21,12.0134229 C21,12.5657076 20.5522847,13.0134229 20,13.0134229 C18.1202256,13.0134229 16.5754913,13.4117467 15.3453729,14.1955717 C14.6099775,14.6641617 13.668986,14.0326932 13.823907,13.174566 L13.9401919,12.5031266 L14.0413679,11.8604056 C14.0725695,11.6509281 14.1012448,11.4462015 14.1273853,11.2461906 L14.1981943,10.660269 L14.2537452,10.1024285 C14.2617294,10.0117833 14.2690758,9.92229938 14.2757833,9.8339722 L14.3083537,9.31785487 L14.3255414,8.82928745 C14.4092804,4.63466183 12.6867896,3.63965816 9.00368154,5.18534252 L8.57363861,5.37399895 C4.97131471,7.02791801 4.61475764,9.18049309 7.75221132,12.4044789 L8.12974529,12.7812646 C8.45730212,13.0992693 8.81725131,13.4273835 9.20981356,13.7661164 L9.69655665,14.1777253 C9.86403185,14.316649 10.0367393,14.4573043 10.214693,14.5997237 C10.6682525,14.9627151 10.7181014,15.6344819 10.3230865,16.0604423 C8.25688955,18.2885052 7.71765049,21.4175765 8.76244642,25.5642416 C9.79376621,29.6574215 12.6240268,32.3830817 17.3774998,33.805029 C17.9066179,33.9633087 18.2072419,34.5205547 18.0489622,35.0496728 C17.8906825,35.578791 17.3334365,35.8794149 16.8043184,35.7211353 C11.4031275,34.1054305 8.03461729,30.8614133 6.82305936,26.0528906 C5.7619012,21.8412858 6.15746646,18.3608617 8.04941425,15.6926256 L8.181,15.513 L7.95517369,15.3181602 C2.20820715,10.3008499 2.08454358,6.03932024 7.98058329,3.44815618 L8.2297363,3.34116011 Z" id="Path-16" fillRule="nonzero"></path>
        <path d="M23.2297363,3.34116011 C29.2375722,0.819860706 31.9509509,3.82735771 31.1209361,11.3011785 L31.081,11.632 L31.1829599,11.595709 C32.2108793,11.2453982 33.3456184,11.0539951 34.5835421,11.0192176 L35,11.0134229 C35.5522847,11.0134229 36,11.4611381 36,12.0134229 C36,12.5657076 35.5522847,13.0134229 35,13.0134229 C33.1202256,13.0134229 31.5754913,13.4117467 30.3453729,14.1955717 C29.6099775,14.6641617 28.668986,14.0326932 28.823907,13.174566 L28.9401919,12.5031266 L29.0413679,11.8604056 C29.0725695,11.6509281 29.1012448,11.4462015 29.1273853,11.2461906 L29.1981943,10.660269 L29.2537452,10.1024285 C29.2617294,10.0117833 29.2690758,9.92229938 29.2757833,9.8339722 L29.3083537,9.31785487 L29.3255414,8.82928745 C29.4092804,4.63466183 27.6867896,3.63965816 24.0036815,5.18534252 L23.5736386,5.37399895 C19.9713147,7.02791801 19.6147576,9.18049309 22.7522113,12.4044789 L23.1297453,12.7812646 C23.4573021,13.0992693 23.8172513,13.4273835 24.2098136,13.7661164 L24.6965566,14.1777253 C24.8640318,14.316649 25.0367393,14.4573043 25.214693,14.5997237 C25.6682525,14.9627151 25.7181014,15.6344819 25.3230865,16.0604423 C23.2568896,18.2885052 22.7176505,21.4175765 23.7624464,25.5642416 C24.7937662,29.6574215 27.6240268,32.3830817 32.3774998,33.805029 C32.9066179,33.9633087 33.2072419,34.5205547 33.0489622,35.0496728 C32.8906825,35.578791 32.3334365,35.8794149 31.8043184,35.7211353 C26.4031275,34.1054305 23.0346173,30.8614133 21.8230594,26.0528906 C20.7619012,21.8412858 21.1574665,18.3608617 23.0494142,15.6926256 L23.181,15.513 L22.9551737,15.3181602 C17.2082071,10.3008499 17.0845436,6.03932024 22.9805833,3.44815618 L23.2297363,3.34116011 Z" id="Path-16" fillRule="nonzero" transform="translate(27.300837, 19.114567) scale(-1, 1) translate(-27.300837, -19.114567) "></path>
        <g id="Group-2-Copy" transform="translate(31.500000, 27.500000) rotate(90.000000) translate(-31.500000, -27.500000) translate(29.000000, 24.000000)" fillRule="nonzero">
          <path d="M0.5,0 C0.776142375,0 1,0.223857625 1,0.5 L1,6.5 C1,6.77614237 0.776142375,7 0.5,7 C0.223857625,7 0,6.77614237 0,6.5 L0,0.5 C0,0.223857625 0.223857625,0 0.5,0 Z" id="Rectangle"></path>
          <path d="M2.5,0 C2.77614237,0 3,0.223857625 3,0.5 L3,6.5 C3,6.77614237 2.77614237,7 2.5,7 C2.22385763,7 2,6.77614237 2,6.5 L2,0.5 C2,0.223857625 2.22385763,0 2.5,0 Z" id="Rectangle-Copy"></path>
          <path d="M4.5,0 C4.77614237,0 5,0.223857625 5,0.5 L5,6.5 C5,6.77614237 4.77614237,7 4.5,7 C4.22385763,7 4,6.77614237 4,6.5 L4,0.5 C4,0.223857625 4.22385763,0 4.5,0 Z" id="Rectangle-Copy-2"></path>
        </g>
        <g id="Group-2-Copy-2" transform="translate(8.500000, 27.500000) rotate(90.000000) translate(-8.500000, -27.500000) translate(6.000000, 24.000000)" fillRule="nonzero">
          <path d="M0.5,0 C0.776142375,0 1,0.223857625 1,0.5 L1,6.5 C1,6.77614237 0.776142375,7 0.5,7 C0.223857625,7 0,6.77614237 0,6.5 L0,0.5 C0,0.223857625 0.223857625,0 0.5,0 Z" id="Rectangle"></path>
          <path d="M2.5,0 C2.77614237,0 3,0.223857625 3,0.5 L3,6.5 C3,6.77614237 2.77614237,7 2.5,7 C2.22385763,7 2,6.77614237 2,6.5 L2,0.5 C2,0.223857625 2.22385763,0 2.5,0 Z" id="Rectangle-Copy"></path>
          <path d="M4.5,0 C4.77614237,0 5,0.223857625 5,0.5 L5,6.5 C5,6.77614237 4.77614237,7 4.5,7 C4.22385763,7 4,6.77614237 4,6.5 L4,0.5 C4,0.223857625 4.22385763,0 4.5,0 Z" id="Rectangle-Copy-2"></path>
        </g>
        <circle id="Oval" cx="15.5454545" cy="23.5454545" r="1.54545455"></circle>
        <circle id="Oval-Copy" cx="24.6363636" cy="23.5454545" r="1.54545455"></circle>
        <circle id="Oval-Copy-2" cx="19.6363636" cy="27.5454545" r="1.54545455"></circle>
      </g>
    </g>
  </Icon>
)
Custom33.defaultProps = {
  size: 30
}

export default Custom33