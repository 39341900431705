import React from 'react'
import Icon from 'react-icon-base'

const PdfFile = props => (
  <Icon viewBox="0 0 20 20" {...props}>
    <g fill="none" fillRule="evenodd">
      <g>
        <path fill="currentColor" fillRule="nonzero" d="M8.723 8.815c-.235 0-.465.043-.68.126-.664.255-1.121.87-1.121 1.578v4.047a.75.75 0 0 0 1.283.528l4.949-5.001a.75.75 0 0 0 .16-.242l-.002.005a.75.75 0 0 0-.692-1.04h-.75v.75l.217-.528-1.264 1.277h1.797a.75.75 0 0 0 .692-.46l.002-.004a.75.75 0 0 0 .056-.286V1.704C13.37.746 12.55 0 11.57 0H1.8c-.234 0-.463.042-.677.124C.457.381 0 .994 0 1.704v11.908c0 .958.822 1.704 1.801 1.704h3.354a.75.75 0 1 0 0-1.5H1.8c-.182 0-.301-.108-.301-.204V1.704c0-.065.055-.139.161-.18a.394.394 0 0 1 .14-.024h9.768c.182 0 .301.108.301.204v7.111H8.723zm-.301 3.927v-2.223c0-.064.054-.138.16-.178a.388.388 0 0 1 .14-.026h2.101l-2.401 2.427z"/>
        <path fill="currentColor" fillRule="nonzero" d="M3.44 4.711h6.49a.75.75 0 1 0 0-1.5H3.44a.75.75 0 1 0 0 1.5zM3.44 7.125h6.49a.75.75 0 1 0 0-1.5H3.44a.75.75 0 0 0 0 1.5z"/>
        <rect width="15" height="9" x="3.685" y="8.582" fill="currentColor" fillRule="nonzero" rx="1"/>
        <path fill="#3498db" d="M5.685 15.418v-4.836h1.697c1.066 0 1.943.377 1.943 1.573 0 1.15-.89 1.657-1.91 1.657h-.566v1.606H5.685zm1.164-2.522h.507c.565 0 .838-.267.838-.741 0-.488-.312-.657-.87-.657h-.475v1.398zm3.03 2.522v-4.836h1.392c1.475 0 2.45.708 2.45 2.392 0 1.683-.975 2.444-2.385 2.444H9.88zm1.164-.936h.156c.78 0 1.333-.358 1.333-1.508s-.553-1.47-1.333-1.47h-.156v2.978zm3.305.936v-4.836h3.087v.962h-1.924v1.06h1.651v.968h-1.65v1.846h-1.164z"/>
      </g>
    </g>
  </Icon>
)
PdfFile.defaultProps = {
  size: 16
}

export default PdfFile
