import React, { Component } from 'react';
import PDF from 'react-pdf-js';
import Modal from 'react-modal';
import ActionButton from 'jbc-front/components/ActionButton';
import { MyNumber, Edit, Download, Print, PlusSquare, MinusSquare, Pulldown, Close } from 'jbc-front/components/icons';
import _ from 'lodash';
import Loading from './Loading';
import styles from './PreviewPdf.scss';
import { toBlob } from '../utils/url';
import { saveAs } from 'file-saver';

const initialState = {
  page: 1,
  totalPages: 1,
  scale: 1.1,
  zoom: 100,
  isOpen: false,
  firstTime: true,
  hasMyNumber: false,
  showMyNumber: false,
  title: '',
  pdf: null,
  printUrl: null,
  showPrint: true
};

export class PreviewPdf extends Component {
  state = initialState;

  onDocumentComplete = totalPages => {
    this.setTotalPages(totalPages);
  };

  onPageComplete = () => {
    if (this.state.firstTime) {
      const container = this._pdfContainer;
      const canvas = container.getElementsByTagName('canvas')[0];
      const { clientWidth: cw, clientHeight: ch } = canvas;
      const { clientWidth: dw, clientHeight: dh } = container;
      const scale = dw > dh ? Math.max((dw / cw) * 0.5, (dh - 80) / ch) : Math.max(((dh - 80) / ch) * 0.6, dw / cw);
      this.setScale(scale);
    }
  };

  nextPage = () => {
    this.setState(state => {
      const { page, totalPages } = state;
      return page < totalPages ? { ...state, page: page + 1 } : state;
    });
  };
  prevPage = () => {
    this.setState(state => {
      const { page } = state;
      return page > 1 ? { ...state, page: page - 1 } : state;
    });
  };
  zoomIn = () => {
    this.setState(state => {
      const { zoom: current } = state;
      const index = zooms.indexOf(current) + 1;
      return index < zooms.length ? { ...state, zoom: zooms[index] } : state;
    });
  };
  zoomOut = () => {
    this.setState(state => {
      const { zoom: current } = state;
      const index = zooms.indexOf(current) - 1;
      return index >= 0 ? { ...state, zoom: zooms[index] } : state;
    });
  };
  setScale = scale => {
    this.setState({ scale, firstTime: false });
  };
  setTotalPages = totalPages => {
    this.setState({ totalPages });
  };
  show = args => {
    this.setState(() => ({ ...initialState, ...args, isOpen: true }));
  };

  hide = () => {
    this.setState(() => initialState);
  };

  setPdf = (pdf, printUrl, rest) => {
    this.setState({ pdf, printUrl, ...(rest || {}) });
  };

  handleEditClick = () => {
    const { onEditClick, edit } = this.state;
    if (edit) {
      onEditClick();
    }
  };

  handleMyNumberClick = async e => {
    const showMyNumber = !this.state.showMyNumber;
    await this.state.reloadWithMyNumber(showMyNumber);
    this.setState({ showMyNumber });
  };

  componentDidUpdate(_, prevState) {
    if (this.state.isOpen !== prevState.isOpen) {
      if (this.state.isOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    }
  }

  componentWillUnmount() {
    document.body.style.overflow = '';
  }

  printPdf = () => {
    const { printUrl } = this.state;
    const w = window.open(printUrl, '', 'toolbar=0,menubar=0,location=0');
    if (_.isFunction(w.addEventListener)) {
      w.addEventListener('load', () => {
        w.print();
      });
    }
  };

  render() {
    const {
      page,
      totalPages,
      scale,
      zoom,
      isOpen,
      showMyNumber,
      pdf,
      title,
      edit,
      hasMyNumber,
      printUrl,
      downloadUrl,
      disabledReason,
      showPrint,
      reloadWithMyNumber
    } = this.state;
    const { children } = this.props;

    const myNumberButton = hasMyNumber && (
      <ActionButton
        className={styles.button}
        onClick={this.handleMyNumberClick}
        icon={<MyNumber size={15} />}
        disabled={!reloadWithMyNumber}
      >
        {`マイナンバー${this.state.showMyNumber ? 'を隠す' : '出力'}`}
      </ActionButton>
    );

    return (
      <>
        {children({ show: this.show, isOpen, setPdf: this.setPdf, showMyNumber })}
        <Modal className={styles.modal} isOpen={isOpen} overlayClassName={styles.overlay} contentLabel="Modal">
          <div className={styles.header}>
            <div className={styles.title}>{title}</div>
            <div className={styles.tools}>
              {myNumberButton}
              {edit !== null && (
                <ActionButton className={styles.buttonEdit} onClick={this.handleEditClick} disabled={!edit} icon={<Edit size={15} />}>
                  編集
                </ActionButton>
              )}
              {showPrint && (
                <>
                  <ActionButton
                    primary
                    className={styles.button}
                    onClick={this.printPdf}
                    icon={<Print size={15} />}
                    disabled={!printUrl || !!disabledReason}
                    disabledReason={disabledReason}
                  >
                    印刷
                  </ActionButton>
                  <ActionButton
                    primary
                    className={styles.button}
                    disabled={!pdf || !!disabledReason}
                    onClick={() => {
                      if (downloadUrl) {
                        window.open(downloadUrl, '_blank');
                      } else {
                        saveAs(toBlob(pdf, 'application/pdf'), `${title}.pdf`);
                      }
                    }}
                    disabledReason={disabledReason}
                    icon={<Download size={15} />}
                  >
                    ダウンロード
                  </ActionButton>
                </>
              )}
              <Close className={styles.close} onClick={this.hide} />
            </div>
          </div>
          <div className={styles.body} ref={div => (this._pdfContainer = div)}>
            <div className={styles.pdf}>
              {pdf ? (
                <PDF
                  key={pdf}
                  file={`data:application/pdf;base64,${pdf}`}
                  onDocumentComplete={this.onDocumentComplete}
                  onPageComplete={this.onPageComplete}
                  page={page}
                  scale={(scale * zoom) / 100.0}
                />
              ) : (
                <div className={styles.loading}>
                  <Loading type="spin" color="#e3e3e3" />
                </div>
              )}
            </div>
            <div className={styles.controller}>
              <span className={styles.zoom} onClick={this.zoomOut} onKeyPress={this.zoomOut} role="button" tabIndex={0}>
                <MinusSquare size={20} />
              </span>
              <span className={styles.scale}>{zoom}％</span>
              <span className={styles.zoom} onClick={this.zoomIn} onKeyPress={this.zoomIn} role="button" tabIndex={0}>
                <PlusSquare size={20} />
              </span>
              <span className={styles.pagePrev} onClick={this.prevPage} onKeyPress={this.prevPage} role="button" tabIndex={0}>
                <Pulldown size={12} />
              </span>
              <span className={styles.pageNumber}>
                {page} / {totalPages}
              </span>
              <span className={styles.pageNext} onClick={this.nextPage} onKeyPress={this.nextPage} role="button" tabIndex={0}>
                <Pulldown size={12} />
              </span>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
const zooms = [25, 33, 50, 67, 75, 80, 90, 100, 110, 125, 150, 175, 200, 250, 300, 400, 500];

export default PreviewPdf;
