import { FC, ReactNode } from 'react';
import { BrandLogo } from 'jbc-front/components/icons';
import styles from './Header.scss';

interface HeaderProps {
  children?: ReactNode;
}

export const Header: FC<HeaderProps> = ({ children }) => {
  return (
    <div className={styles.base}>
      <div className={styles.logo}>
        <BrandLogo className={styles.brand} />
        <div className={styles.logoText}>年末調整</div>
      </div>
      {children}
    </div>
  );
};
