import { FC, ReactNode } from 'react';
import { Section } from 'components/ui/Section';

import { Question } from '../type';

import { ToggleImageContainer } from './parts/ToggleImageContainer';
import { Lead } from './parts/Lead';
import { Description } from './parts/Description';
import { Content } from './parts/Content';

// @ts-ignore
import image from '../../../../images/img-Foundation_Exclusion_Form.png';

type SalaryIncomeProps = {
  question: Question;
  children: ReactNode;
  initiallyOpen?: boolean;
};

export const SalaryIncome: FC<SalaryIncomeProps> = ({ question, children, initiallyOpen = false }) => {
  const description = question.questionDescriptions.find(description => description.field == 'top')?.description;

  return (
    <Section>
      <Section.Header>給与収入等（本人の給与収入）</Section.Header>

      <Section.Body>
        <Content>
          <Lead>本人の給与収入欄を入力してください</Lead>

          <Description>{description}</Description>

          <ToggleImageContainer initiallyOpen={initiallyOpen}>
            <ToggleImageContainer.Header>「基礎控除申告書（赤枠）」に反映されます。</ToggleImageContainer.Header>
            <ToggleImageContainer.Body>
              <img src={image} alt="基配所_基礎控除申告書" />
            </ToggleImageContainer.Body>
          </ToggleImageContainer>
          <Description caution>
            「当社」欄は今年1年間の見込み収入金額（賞与含む、前職除く）を入力してください。
            <br />
            「他社」欄は2か所以上からの給与収入がある場合にその金額を入力してください。
          </Description>

          {children}
        </Content>
      </Section.Body>
    </Section>
  );
};
