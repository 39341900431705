import { useFormValue } from '../components/FormName';

const useAdjFields = () => {
  const resignBeforeYearEnd = useFormValue('basicInformation.resignBeforeYearEnd');
  const workForMultiCompanies = useFormValue('basicInformation.workForMultiCompanies');
  const salaryMoreThanOthers = useFormValue('basicInformation.salaryMoreThanOthers');
  const salaryLessThanOthersNextYear = useFormValue('basicInformation.salaryLessThanOthersNextYear');
  return {
    needAdjThisYear: !resignBeforeYearEnd && (!workForMultiCompanies || salaryMoreThanOthers),
    nextYearOnly: !resignBeforeYearEnd && workForMultiCompanies && !salaryMoreThanOthers && !salaryLessThanOthersNextYear
  };
};

export default useAdjFields;
