import React from 'react';
import Button from 'jbc-front/components/Button';
import { useMutation, useQuery } from '../components/Graphql';
import { gql } from '@apollo/client';
import { reduxForm, FieldArray } from 'redux-form';
import { Section, BoxDouble } from 'jbc-front/components/Form';
import { FileField, RadioField, TextField, FormSection, AmountField } from '../components/FieldWithDiff';
import { toFormValues } from '../utils/form';
import { amount } from '../validators';
import FormAdd from '../components/FormAdd';
import { DeleteSquare } from 'jbc-front/components/icons';
import { Panel, Headding, Item, Buttons, Lead } from '../components/PrimaryPanel';
import { LabelMapper } from 'jbc-front/components/FormErrors';
import LoadingPage from '../components/LoadingPage';
import useReactRouter from 'use-react-router';
import { CustomDescription } from '../components/QuestionDescription';
import FilePreview from '../components/FilePreview';
import { useNotify } from '../actions';
import { ToggleDivImage } from '../components/ToggleDivImage';
import image from '../../images/img-Premium_Deduction_Earthquake_Insurance.png';
import { amountFormat } from '@jbc-year-end-adj/common/utils/formatter';
import styles from './EarthquakeInsurances.scss';

const MAX_COUNT = 10;

export const EARTHQUAKE_INSURANCES_FRAGMENT = gql`
  fragment EarthquakeInsurances on Profile {
    earthquakeInsurances {
      id
      amount
      insuranceDuration
      insuranceType
      name
      nameOfContractor
      nameOfRecipient
      newOrOld
      relationshipOfRecipient
      compareKey
      image {
        url
        filename
      }
    }
  }
`;

const EARTHQUAKE_INSURANCES = gql`
  query earthquakeInsrances {
    request {
      id
      profile {
        id
        ...EarthquakeInsurances
      }
    }
    clientSetting {
      id
      requestImageUploadSettings {
        earthquakeInsurance
      }
    }
  }
  ${EARTHQUAKE_INSURANCES_FRAGMENT}
`;

const ANSWER_EARTHQUAKE_INSURANCES = gql`
  mutation answerEarthquakeInsurances($input: AnswerEarthquakeInsurancesInput!) {
    answerEarthquakeInsurances(input: $input) {
      questions {
        id
        status
      }
      profile {
        id
        ...EarthquakeInsurances
      }
    }
  }
  ${EARTHQUAKE_INSURANCES_FRAGMENT}
`;

const formName = 'earthquakeInsurances';

const newOrOlds = [
  { value: 'new_type', label: '地震' },
  { value: 'old_type', label: '旧長期' }
];

export const renderEarthquakeInsurances = ({ fields, handleSubmit, newOnly, requestImageUpload, fixed }) => (
  <div>
    {fields.map((field, index) => (
      <Section
        key={index}
        title={`地震保険(${index + 1})`}
        style={{ maxWidth: '100%', marginTop: '30px' }}
        icon={
          fixed ? (
            undefined
          ) : (
            <DeleteSquare
              onClick={() => {
                fields.remove(index);
              }}
            />
          )
        }
      >
        <FormSection name={field}>
          <BoxDouble>
            <TextField name="name" label="保険会社等の名称" placeholder="例：××火災、××損保" required />
            <TextField
              name="insuranceType"
              label="保険等の種類（目的）"
              description="保険料控除証明書に記載された保険種類を記入してください"
              placeholder="例：地震、積立傷害　等"
              required
            />
            <TextField
              name="insuranceDuration"
              label="保険期間"
              description="保険料控除証明書に記載された保険期間を記入してください"
              placeholder="例：1年、5年　等"
              required
            />
            <TextField
              name="nameOfContractor"
              label="保険等の契約者の氏名"
              description="保険料控除証明書に記載された契約者の氏名を記入してください"
              placeholder="例：ジョブカン　太朗"
              required
            />
            <TextField
              name="nameOfRecipient"
              label="保険等の受取人 氏名"
              description="保険料控除証明書に記載された受給人の氏名を記入してください"
              placeholder="例：ジョブカン　花子"
              required
            />
            <TextField
              name="relationshipOfRecipient"
              label="保険等の受取人 続柄"
              description="保険料控除証明書に記載された受給人と本人の関係を記入してください"
              placeholder="例：本人、妻　等"
              required
            />
          </BoxDouble>
          <RadioField
            name="newOrOld"
            label="保険料区分"
            description="保険料控除証明書の表記をご確認ください"
            options={newOrOlds}
            required
          />
          <AmountField name="amount" label="保険料金額" required validate={amount} />
          {requestImageUpload !== 'none' && (
            <FileField
              name="image"
              label="証明書画像"
              description="管理者が確認できるよう保険料控除証明書の画像（対応可能形式：jpg, jpeg, gif, png, pdf）をアップロードしてください。原本は必ず会社に提出してください。"
              required={requestImageUpload === 'required'}
              preview={FilePreview}
              disablePreview={false}
            />
          )}
        </FormSection>
      </Section>
    ))}
    <div>
      {fields.length < MAX_COUNT && (
        <FormAdd
          onClick={() => {
            fields.push({ newOrOld: 'new_type' });
          }}
          name="地震保険"
        />
      )}
    </div>
  </div>
);

const QuestionForm =
  (({ handleSubmit }) => {
    const { data, loading } = useQuery(EARTHQUAKE_INSURANCES);
    if (loading) return <LoadingPage />;
    const {
      clientSetting: {
        requestImageUploadSettings: { earthquakeInsurance: earthquakeInsuranceImageSetting }
      }
    } = data;
    return (
      <form onSubmit={handleSubmit}>
        <LabelMapper name="earthquakeInsurances" label="地震保険" />
        <FieldArray
          name="earthquakeInsurances"
          component={renderEarthquakeInsurances}
          requestImageUpload={earthquakeInsuranceImageSetting}
        />
        <Buttons>
          <Button primary onClick={handleSubmit}>
            次ヘ
          </Button>
        </Buttons>
      </form>
    );
  }) |> reduxForm({ form: formName });

const Question = ({ handleSubmit }) => {
  const { data, loading } = useQuery(EARTHQUAKE_INSURANCES);
  const [answer] = useMutation(ANSWER_EARTHQUAKE_INSURANCES);
  const { history } = useReactRouter();
  const notify = useNotify();
  const formatValues = profile => {
    return (profile.earthquakeInsurances || []).map(v => {
      const formattedEarthquakeInsurances = toFormValues(v);

      return {
        ...formattedEarthquakeInsurances,
        amount: amountFormat(formattedEarthquakeInsurances.amount)
      };
    });
  };

  if (loading) return <LoadingPage />;
  return (
    <Panel>
      <Headding className={styles.primaryPanelHeader}>地震保険</Headding>
      <Item>
        <Lead>今年支払った地震保険料情報を記入してください。</Lead>
        <CustomDescription field="top" />
        <ToggleDivImage title={'※赤枠に関する設問です。'}>
          <img src={image} alt="保険料控除_地震保険" />
        </ToggleDivImage>
        <QuestionForm
          initialValues={{
            earthquakeInsurances: formatValues(data.request.profile)
          }}
          onSubmit={async values => {
            if (values.earthquakeInsurances.length < 1) {
              notify('今年支払った地震保険料情報を記入してください', 'error');
              return;
            }
            await answer({ variables: { input: values } });
            history.push('/employee_input');
          }}
        />
      </Item>
    </Panel>
  );
};

export default Question;
