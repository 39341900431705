import React, { useMemo, useEffect, useState } from 'react';
import { Field } from 'redux-form';
import { gql, useApolloClient } from '@apollo/client';
import _ from 'lodash';
import fp from 'lodash/fp';
import classnames from 'classnames';
import moment from 'moment';
import { SearchEmploymentStatusField } from '../components/SearchForm';
import { statuses } from './Top';
import { Report } from '../result/ReportButton';
import { useNotify } from '../actions';
import {
  useQuery,
  useReportList,
  useSession,
  useYear,
  AsyncTaskError,
  FormNameProvider,
  HoverHint,
  LoadingPage,
  SelectList,
  SelectedResult
} from '../components';
import PreviewPdf from '../components/PreviewPdf';
import searchForm from '../employees/SearchForm';
import PaginatorWithResult from '../employees/PaginatorWithResult';
import { getCurrentQueryFromLocation, convertQueryToForm, convertFormToGraphQl } from '../employees/utils';
import { EmailButton } from '../withholding_slip_deliver/EmailModal';
import FloatingButtonArea from '../withholding_slip_deliver/FloatingButtonArea';
import { MyPageButton } from '../withholding_slip_deliver/MyPageModal';
import recordDisplay from '../utils/recordDisplay';
import styles from './WithholdingSlipDeliver.scss';

export const MIN_REQUEST_COUNT = 1;
export const MAX_REQUEST_COUNT = 100;
const WITHHOLDING_SLIP_DELIVER_MODE = 'withholding_slip_deliver';
const searchFormName = 'employeeSearchWithholdingSlipDeliver';
const SearchForm = searchForm(searchFormName);

const withholdingSlipDeliverStatuses = [
  { id: 'all', name: 'すべて' },
  { id: 'not_sent', name: '未交付' },
  { id: 'sent', name: '交付済' }
];

const WITHHOLDING_SLIP_DELIVER_QUERY = gql`
  query me {
    me {
      id
      email
    }
  }
`;

const WITHHOLDING_SLIP_DELIVER = gql`
  query withholdingSlipDeliver($per: Int, $page: Int, $search: EmployeeSearchInput, $year: Int!, $mode: Mode!) {
    client {
      id
      clientYearly(year: $year) {
        id
        selectableEmployeesCount(mode: $mode)
        sendWithholdingSlipForEmployeesRunning
        withholdingSlipPublishRunning
        withholdingSlipDeliverRunning
        withholdingSlipDeliverCount(search: $search) {
          totalCount
          notSent
          sent
        }
        employees(per: $per, page: $page, search: $search) {
          totalCount
          list {
            id
            staffCode
            differenceApplyMethod
            employmentType
            status
            email
            profile {
              id
              fullName
            }
            withholdingSlip {
              id
              withholdingSlipDeliver {
                id
                status
                sentAt
              }
              withholdingSlipPublish {
                id
                status
                generatedAt
                reservedTime
                lastPublishedAt
              }
            }
            accountStatuses {
              id
              application
              accountStatus
            }
          }
        }
      }
    }
  }
`;

const applicationName = {
  lms: '労務HR',
  payroll: '給与計算'
};

const AccountStatus = ({ accountStatus, application }) => (
  <div className={classnames([styles.myNumberSendStatus, styles.myNumberSendStatusTd])}>
    {accountStatus === 'registered' ? (
      <p className={styles.registered}>登録済</p>
    ) : (
      <HoverHint message={`${applicationName[application]}から従業員招待を行ってください`}>未発行</HoverHint>
    )}
  </div>
);

const Row = ({ listItem, isPreparing, isPublishing, isDelivering, onClickReport }) => {
  const {
    td,
    item: {
      id,
      staffCode,
      employmentType,
      status,
      email,
      profile: { fullName },
      withholdingSlip,
      accountStatuses
    }
  } = listItem;

  return (
    <tr key={id}>
      {td}
      <td>{staffCode}</td>
      <td>{fullName}</td>
      <td>{recordDisplay(employmentType)}</td>
      <td>{statuses[status]}</td>
      <td>
        <div className={classnames([styles.myNumberSendStatus, styles.myNumberSendStatusTd])}>
          {_.map(applicationName, (applicationName, application) => (
            <AccountStatus
              key={applicationName}
              application={application}
              accountStatus={accountStatuses.find(accountStatus => accountStatus.application === application)?.accountStatus}
            />
          ))}
        </div>
      </td>
      <td>
        <MyPageButton
          withholdingSlipPublish={withholdingSlip?.withholdingSlipPublish}
          accountStatuses={accountStatuses}
          employeeId={id}
          isPreparing={isPreparing}
          isPublishing={isPublishing}
          buttonStyle={styles.buttonTd}
        />
      </td>
      <td>
        <EmailButton
          withholdingSlipDeliver={withholdingSlip?.withholdingSlipDeliver}
          employeeId={id}
          hasNoEmail={!email}
          isPreparing={isPreparing}
          isDelivering={isDelivering}
          buttonStyle={styles.buttonTd}
        />
      </td>
      <td>
        <Report onClick={onClickReport('withholdingSlipEmployee', { id })} />
      </td>
    </tr>
  );
};

const EmployeeList = ({ variables }) => {
  const apolloClient = useApolloClient();
  const notify = useNotify();
  const {
    clientYearly: { year }
  } = useSession();
  const reports = useReportList(year);
  const { data, startPolling, stopPolling } = useQuery(WITHHOLDING_SLIP_DELIVER, {
    fetchPolicy: 'network-only',
    variables
  });
  const totalCount = _.get(data, 'client.clientYearly.employees.totalCount', 0);
  const employees = _.get(data, 'client.clientYearly.employees.list', []);
  const selectableCount = _.get(data, 'client.clientYearly.selectableEmployeesCount', 0);
  const [isPreparing, setIsPreparing] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [isDelivering, setIsDelivering] = useState(false);
  const polling = useMemo(() => {
    if (!data) {
      return false;
    }

    const sendWithholdingSlipForEmployeesRunning = _.get(data, 'client.clientYearly.sendWithholdingSlipForEmployeesRunning', false);
    const withholdingSlipPublishRunning = _.get(data, 'client.clientYearly.withholdingSlipPublishRunning', false);
    const withholdingSlipDeliverRunning = _.get(data, 'client.clientYearly.withholdingSlipDeliverRunning', false);
    sendWithholdingSlipForEmployeesRunning ? setIsPreparing(true) : setIsPreparing(false);
    withholdingSlipPublishRunning ? setIsPublishing(true) : setIsPublishing(false);
    withholdingSlipDeliverRunning ? setIsDelivering(true) : setIsDelivering(false);

    return sendWithholdingSlipForEmployeesRunning || withholdingSlipPublishRunning || withholdingSlipDeliverRunning;
  }, [data]);
  const countByStatus = _.get(data, 'client.clientYearly.withholdingSlipDeliverCount');

  useEffect(() => {
    if (polling) {
      startPolling(3000);
      return () => {
        stopPolling();
      };
    }
    return undefined;
  }, [polling, startPolling, stopPolling]);

  return (
    <>
      <AsyncTaskError displayTypes={['create_publish_withholding_slip_request', 'create_deliver_withholding_slip_request']} />
      <div className={styles.noticeWrap}>
        <p>源泉徴収票を1月31日までに各従業員に交付してください。</p>
        <p>従業員のメールに暗号化したPDFファイルを送ること、PDFファイルを印刷・ダウンロードし交付することが可能です。</p>
      </div>
      <SearchForm
        query={WITHHOLDING_SLIP_DELIVER}
        variables={variables}
        statusField={handleSubmit => (
          <Field
            name="withholdingSlipDeliverStatus"
            component={SearchEmploymentStatusField}
            employmentStatuses={withholdingSlipDeliverStatuses}
            onEmploymentStatusChange={handleSubmit}
            countByStatus={countByStatus}
          />
        )}
      />
      <SelectList
        mode={WITHHOLDING_SLIP_DELIVER_MODE}
        variables={variables}
        list={employees}
        totalCount={totalCount}
        selectableCount={selectableCount}
        disabledMessage={() => null}
      >
        {({ list, selected, allSelectMode, isDisabledSubmit, selectedResultCount, reset, th }) => {
          const selectedTotalCount = selectedResultCount();

          return !data || !reports ? (
            <LoadingPage />
          ) : (
            <>
              {list.length > 0 && <SelectedResult selectedTotalCount={selectedTotalCount} />}
              <div className="l-overflow-scroll">
                <table className={`m-table-list ${styles.deliverTable}`}>
                  <thead>
                    <tr>
                      {th}
                      <th width={120}>スタッフコード</th>
                      <th width={120}>氏名</th>
                      <th width={100}>雇用形態</th>
                      <th width={80}>年末調整</th>
                      <th width={120}>
                        <p>マイページ発行状況</p>
                        <div className={styles.myNumberSendStatus}>
                          <p>労務</p>
                          <p>給与</p>
                        </div>
                      </th>
                      <th width={95}>マイページ公開</th>
                      <th width={110}>メール発送状況</th>
                      <th width={70}>PDF出力</th>
                    </tr>
                  </thead>
                  <tbody>
                    <PreviewPdf>
                      {({ show, isOpen, setPdf }) => {
                        const reportOnClick = (report, employee) => e => {
                          e.stopPropagation();
                          reports[report].show({
                            client: apolloClient,
                            setPdf,
                            show,
                            notify,
                            employeeId: employee.id,
                            edit: null,
                            year
                          });
                        };

                        return list.length > 0 ? (
                          list.map(listItem => (
                            <Row
                              key={listItem.item.id}
                              listItem={listItem}
                              isPreparing={isPreparing}
                              isPublishing={isPublishing}
                              isDelivering={isDelivering}
                              onClickReport={reportOnClick}
                            />
                          ))
                        ) : (
                          <tr>
                            <td colSpan={9}>該当の従業員は見つかりませんでした</td>
                          </tr>
                        );
                      }}
                    </PreviewPdf>
                  </tbody>
                </table>
              </div>
              <FormNameProvider formName={searchFormName}>
                <div className={styles.paginator}>
                  <PaginatorWithResult count={totalCount} />
                </div>
              </FormNameProvider>
              <FloatingButtonArea
                selected={selected}
                allSelectMode={allSelectMode}
                selectedTotalCount={selectedTotalCount}
                isDisabledSubmit={isDisabledSubmit}
                isPreparing={isPreparing}
                isPublishing={isPublishing}
                isDelivering={isDelivering}
                reset={reset}
              />
            </>
          );
        }}
      </SelectList>
    </>
  );
};

const WithholdingSlipDeliver = ({ location: { pathname, search } }) => {
  const year = useYear();
  const { data, loading } = useQuery(WITHHOLDING_SLIP_DELIVER_QUERY, { fetchPolicy: 'network-only' });
  if (loading) return <LoadingPage />;
  const { me } = data;
  const formValues = getCurrentQueryFromLocation({ search, pathname }, me) |> convertQueryToForm;
  const variables =
    formValues
    |> convertFormToGraphQl
    |> fp.set('search.hasPaymentOnly', true)
    |> fp.set('year', year)
    |> fp.set('mode', WITHHOLDING_SLIP_DELIVER_MODE);

  return (
    <div>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">源泉徴収票交付</h1>
      </div>
      <div className="l-contents-wrap">
        <div className="l-wrap-xxl">
          <EmployeeList variables={variables} />
        </div>
      </div>
    </div>
  );
};

export default WithholdingSlipDeliver;
