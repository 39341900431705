import { FormField } from 'components/form/FormField';
import { Input } from 'components/form/Input';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { Checkbox } from 'jbc-front/components/presenters/form/Checkbox';
import { Note } from 'jbc-front/components/presenters/ui/Note';
import { FileField } from 'components/react-hook-form/FileField';
import { Schema } from '../schema';
import { Grid } from '../../../../../components/Grid';
import { FormSeparator } from '../../../../../components/FormSeparator';
import { FilePreview } from 'components/feature/FilePreview';
import { AmountField } from 'components/react-hook-form/AmountField';

export const DifferentAndForeignAddress: FC = () => {
  const {
    formState: { errors },
    register,
    watch,
    setValue
  } = useFormContext<Schema>();

  const isNonResident = watch('spouse.thisYear.yearlyInfo.isNonResident');
  const relatedToRelativesDocument = watch('spouse.thisYear.yearlyInfo.relatedToRelativesDocument');
  const relatedToRemittanceDocument = watch('spouse.thisYear.yearlyInfo.relatedToRemittanceDocument');

  return (
    <>
      <Grid>
        <Input label="Address" {...register('spouse.thisYear.yearlyInfo.addressForeign')} />
      </Grid>

      <Grid colGap>
        <FormField>
          <Checkbox {...register('spouse.thisYear.yearlyInfo.isNonResident')}>非居住者</Checkbox>
          <FormField.NoteContainer>
            <Note>配偶者が国内に住所を有せず、かつ、現在まで引き続き1年以上居所を有しない場合チェックしてください。</Note>
          </FormField.NoteContainer>
        </FormField>

        {isNonResident && (
          <AmountField
            label="国外居住親族への送金額"
            note="配偶者が非居住者である場合、本年中にその扶養親族に送金等をした金額の合計を記入してください。"
            error={errors.spouse?.thisYear?.yearlyInfo?.remittance?.message}
            isError={!!errors.spouse?.thisYear?.yearlyInfo?.remittance?.message}
            {...register('spouse.thisYear.yearlyInfo.remittance')}
          />
        )}
      </Grid>

      {isNonResident && (
        <>
          <FormSeparator />

          <h3>添付ファイル</h3>

          <p>国外居住親族に係る扶養控除等を受けようとする場合は関係書類（例：親族関係書類、送金関係書類）を添付してください。</p>

          <Grid colGap>
            <FileField label="親族関係書類" name="spouse.thisYear.yearlyInfo.relatedToRelativesDocument">
              {relatedToRelativesDocument && (
                <FileField.FileContainer>
                  <FileField.File
                    file={relatedToRelativesDocument}
                    onRemove={() => setValue('spouse.thisYear.yearlyInfo.relatedToRelativesDocument', undefined)}
                  />
                  <FilePreview file={relatedToRelativesDocument} />
                </FileField.FileContainer>
              )}
            </FileField>

            <FileField label="送金関係書類" name="spouse.thisYear.yearlyInfo.relatedToRemittanceDocument">
              {relatedToRemittanceDocument && (
                <FileField.FileContainer>
                  <FileField.File
                    file={relatedToRemittanceDocument}
                    onRemove={() => setValue('spouse.thisYear.yearlyInfo.relatedToRemittanceDocument', undefined)}
                  />
                  <FilePreview file={relatedToRemittanceDocument} />
                </FileField.FileContainer>
              )}
            </FileField>
          </Grid>
        </>
      )}
    </>
  );
};
