import { FC, ReactNode } from 'react';
import styles from './Grid.scss';
import classnames from 'classnames';

type GridProps = {
  children: ReactNode;
  colGap?: boolean;
  col2?: boolean;
} & JSX.IntrinsicElements['div'];

export const Grid: FC<GridProps> = ({ children, className, colGap = false, col2 = false }) => {
  const classNames = classnames(styles.grid, [colGap && styles.colGap], [col2 && styles.col2], className);

  return <div className={classNames}>{children}</div>;
};
