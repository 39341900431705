import { FC, ReactNode, useState } from 'react';
import ActionButton from 'jbc-front/components/ActionButton';
import { Edit, Download, Print } from 'jbc-front/components/icons';
import { PdfModal } from 'jbc-front/components/presenters/ui/PdfModal';

import { Pdf } from './Pdf';
import styles from './Viewer.scss';

// @ts-ignore
import Mynumber from 'jbc-front/components/icons/Mynumber';
// @ts-ignore
import { useSession } from 'components/SessionProvider';

export type Viewer = {
  children: ReactNode;
};

const ViewerComponent: FC<Viewer> = ({ children }) => {
  return <PdfModal isOpen={true}>{children}</PdfModal>;
};

export type Header = {
  onClose: () => void;
  reportUrl?: string;
  title: string;
  onClickShowMyNumber?: () => Promise<void>;
  hidePrint?: boolean;
  printDisabledReason?: string;
  onClickEdit?: () => void;
  onClickDownload: () => void;
};

export const Header: FC<Header> = ({
  onClose,
  reportUrl,
  title,
  onClickShowMyNumber,
  hidePrint = false,
  printDisabledReason,
  onClickEdit,
  onClickDownload
}) => {
  const [showMyNumber, setShowMyNumber] = useState<boolean>(false);
  const {
    me: { hasMyNumberPermission }
  } = useSession();
  const [loading, setLoading] = useState<boolean>(false);

  const handleClickShowMyNumber = async () => {
    if (onClickShowMyNumber) {
      setLoading(true);
      await onClickShowMyNumber();
      setShowMyNumber(prev => !prev);
      setLoading(false);
    }
  };

  return (
    <PdfModal.Header>
      <PdfModal.Title>{title}</PdfModal.Title>
      <PdfModal.ToolContainer onClose={onClose}>
        {onClickEdit && (
          <ActionButton className={styles.button} onClick={onClickEdit} icon={<Edit size={15} />}>
            編集
          </ActionButton>
        )}

        {onClickShowMyNumber && hasMyNumberPermission && (
          <ActionButton className={styles.button} onClick={handleClickShowMyNumber} icon={<Mynumber size={15} />} disabled={loading}>
            {showMyNumber ? 'マイナンバーを隠す' : 'マイナンバー出力'}
          </ActionButton>
        )}

        {!hidePrint && (
          <>
            <PrintButton url={reportUrl} disabled={loading || !reportUrl || !!printDisabledReason} disabledReason={printDisabledReason} />

            <ActionButton
              primary
              className={styles.button}
              disabled={loading || !reportUrl || !!printDisabledReason}
              onClick={onClickDownload}
              disabledReason={printDisabledReason}
              icon={<Download size={15} />}
            >
              ダウンロード
            </ActionButton>
          </>
        )}
      </PdfModal.ToolContainer>
    </PdfModal.Header>
  );
};

type PrintButtonProps = {
  url?: string;
  disabled: boolean;
  disabledReason?: string;
};

const PrintButton: FC<PrintButtonProps> = ({ url, disabled, disabledReason }) => {
  const handleClickPrint = () => {
    if (url) {
      const w = window.open(url, '', 'toolbar=0,menubar=0,location=0');
      if (w?.addEventListener) {
        w.addEventListener('load', () => {
          w.print();
        });
      }
    }
  };

  return (
    <ActionButton
      primary
      className={styles.button}
      onClick={handleClickPrint}
      icon={<Print size={15} />}
      disabled={disabled}
      disabledReason={disabledReason}
    >
      印刷
    </ActionButton>
  );
};

export const Viewer = Object.assign(ViewerComponent, { Header, Pdf });
