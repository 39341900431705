import { gql } from '@apollo/client';

export const EMPLOYEE_CSV_EXPORT_REQUEST_RUNNING = gql`
  query employeeCsvExportRequestRunning {
    client {
      id
      clientYearly {
        id
        fixed
        employeeCsvExportRequestRunning
      }
    }
  }
`;

export const EMPLOYEES_CSV = gql`
  query employeeCsv($fileEncoding: FileEncoding, $search: EmployeeSearchInput, $targetData: ExportTargetData) {
    client {
      id
      clientYearly {
        id
        exportEmployeeCsv(fileEncoding: $fileEncoding, search: $search, targetData: $targetData)
      }
    }
  }
`;

export const CSV_EXPORT_REQUEST_TARGET_COUNT = gql`
  query csvExportRequestTargetCount($search: EmployeeSearchInput) {
    client {
      id
      clientYearly {
        id
        csvExportRequestTargetCount(search: $search)
      }
    }
  }
`;
