import { FC } from 'react';

// @ts-ignore
import { Link } from 'react-router-dom';
// import ResidentTaxPaymentAddressForm from '../resident_tax_payment_address/ResidentTaxPaymentAddressForm';

import { useAdminQuery as useQuery } from '@jbc-year-end-adj/2024/hooks/graphql/useQuery';
import { FETCH_ALL_CLIENT_CITIES, QueryResult } from './query';
import { Form } from './parts/Form';

export const ResidentTaxPaymentAddress: FC = () => {
  const { data, loading } = useQuery<QueryResult>(FETCH_ALL_CLIENT_CITIES, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first'
  });

  return (
    <div>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">住民税納付先</h1>
      </div>
      <div className="l-wrap-xs l-contents-wrap">
        <div className="l-breadcrumb u-mb20">
          <Link to="/settings" className="l-breadcrumb-link">
            設定
          </Link>
          <span className="l-breadcrumb-here">住民税納付先</span>
        </div>
      </div>
      {!loading && data && <Form clientCities={data.client.clientYearly.allClientCities} />}
    </div>
  );
};
