import React from 'react'
import Icon from 'react-icon-base'

const ResidenceRegistration = (props) => (
  <Icon viewBox="0 0 40 30" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(0, -5)">
        <g transform="translate(0, 5)">
          <rect fill="#FFFFFF" x="0" y="0" width="40" height="30" rx="3"></rect>
          <path
            d="M37,0 C38.6568542,0 40,1.34314575 40,3 L40,27 C40,28.6568542 38.6568542,30 37,30 L3,30 C1.34314575,30 0,28.6568542 0,27 L0,3 C0,1.34314575 1.34314575,0 3,0 L37,0 Z M37,2.5 L3,2.5 C2.72385763,2.5 2.5,2.72385763 2.5,3 L2.5,27 C2.5,27.2761424 2.72385763,27.5 3,27.5 L37,27.5 C37.2761424,27.5 37.5,27.2761424 37.5,27 L37.5,3 C37.5,2.72385763 37.2761424,2.5 37,2.5 Z"
            fill="#999999" fillRule="nonzero"></path>
          <polygon fill="#999999" points="13.0006846 6 21 13.9998716 21 24 15 24 15 17.9991626 11.0003631 17.9991626 11.0003631 24 5 24 5 14"></polygon>
          <rect fill="#999999" x="23" y="7" width="13" height="4" rx="0.5"></rect>
          <rect fill="#999999" x="25" y="13" width="11" height="4" rx="0.5"></rect>
          <rect fill="#999999" x="25" y="19" width="11" height="4" rx="0.5"></rect>
        </g>
      </g>
    </g>
  </Icon>
)
ResidenceRegistration.defaultProps = {
  size: 20,
}

export default ResidenceRegistration
