import {
  OperationVariables,
  DocumentNode,
  TypedDocumentNode,
  QueryResult,
  QueryHookOptions,
  QueryTuple,
  LazyQueryHookOptions,
  QueryLazyOptions,
  useQuery as useQueryOrigin,
  useLazyQuery as useLazyQueryOrigin
} from '@apollo/client';
import { ADMIN_ENDPOINT, EMPLOYEE_ENDPOINT } from './consts';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useAdminQuery = <TData = any, TVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> => {
  return useQuery(ADMIN_ENDPOINT, query, options);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useAdminLazyQuery = <TData = any, TVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>
): QueryTuple<TData, TVariables> => {
  return useLazyQuery(ADMIN_ENDPOINT, query, options);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useEmployeeQuery = <TData = any, TVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> => {
  return useQuery(EMPLOYEE_ENDPOINT, query, options);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useEmployeeLazyQuery = <TData = any, TVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>
): QueryTuple<TData, TVariables> => {
  return useLazyQuery(EMPLOYEE_ENDPOINT, query, options);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useQuery = <TData = any, TVariables = OperationVariables>(
  uri: string,
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> => {
  const ret = useQueryOrigin(query, {
    ...options,
    context: {
      ...options?.context,
      uri
    }
  });

  if (ret.error) {
    throw ret.error;
  }

  return ret;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useLazyQuery = <TData = any, TVariables = OperationVariables>(
  uri: string,
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: LazyQueryHookOptions<TData, TVariables>
): QueryTuple<TData, TVariables> => {
  const [originFetcher, result] = useLazyQueryOrigin(query, {
    ...options,
    context: {
      ...options?.context,
      uri
    }
  });

  const fetcher = async (options?: QueryLazyOptions<TVariables>) => {
    await originFetcher(options);

    if (result.error) {
      throw result.error;
    }
  };

  return [fetcher, result];
};
