import { gql } from '@apollo/client';

export const UPDATE_QUESTION_CUSTOM_DESCRIPTIONS = gql`
  mutation updateCustomDescriptions($input: UpdateQuestionCustomDescriptionsInput!) {
    updateQuestionCustomDescriptions(input: $input) {
      question {
        id
        no
        name
        code
        questionDescriptions {
          id
          questionId
          field
          name
          description
          defaultDescription
        }
      }
    }
  }
`;
