import React from 'react'
import Icon from 'react-icon-base'

const SelectTable = props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <path d="M7.658,2.588c0.785,1.373,2.435,2.368,4,2.368h1.33c0.271,0,0.5,0.229,0.5,0.5v7.422&#xA;&#x9;c0,0.271-0.229,0.5-0.5,0.5h-11c-0.271,0-0.5-0.229-0.5-0.5V5.456c0-0.271,0.229-0.5,0.5-0.5h1.67&#xA;&#x9;C5.223,4.956,6.873,3.96,7.658,2.588 M7.658,0C7.365,0,7.073,0.251,6.851,0.755L6.466,1.626c-0.444,1.006-1.708,1.83-2.808,1.83&#xA;&#x9;h-1.67c-1.1,0-2,0.9-2,2v7.422c0,1.1,0.9,2,2,2h11c1.1,0,2-0.9,2-2V5.456c0-1.1-0.9-2-2-2h-1.33c-1.1,0-2.363-0.823-2.808-1.83&#xA;&#x9;L8.466,0.755C8.244,0.251,7.951,0,7.658,0L7.658,0z" fill="currentColor"/>
    <g>
      <rect height="1" width="6.754" fill="currentColor" x="4.099" y="6.595"/>
      <rect height="1" width="6.754" fill="currentColor" x="4.099" y="8.719"/>
      <rect height="1" width="3.879" fill="currentColor" x="4.099" y="10.844"/>
    </g>
  </Icon>
)
SelectTable.defaultProps = {
  size: 15
}

export default SelectTable
