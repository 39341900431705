import { FC } from 'react';
import styles from './FloatingButton.scss';
import classNames from 'classnames';

type FloatingButtonProps = JSX.IntrinsicElements['div'];

export const FloatingButton: FC<FloatingButtonProps> = ({ children, className, ...args }) => {
  return (
    <div className={classNames(styles.floatingButtonSpace, className)} {...args}>
      {children}
    </div>
  );
};
