import { FC } from 'react';
import { WorkingStudent as WorkingStudentTemplate } from 'features/questions/templates/WorkingStudent';
import Button from 'jbc-front/components/Button';
import { ButtonContainer } from 'features/questions/components/ButtonContainer';
import { Section } from 'components/ui/Section';
import { Textarea } from 'components/form/Textarea';
import { FileField } from 'components/form/FileField';
import { FormSection } from 'components/form/FormSection';
import { useWizard } from '../WizardProvider';

export const WorkingStudent: FC = () => {
  const { question, request } = useWizard();
  const classification = request.profile?.workingStudentClassification?.classification;
  const file = request.profile?.workingStudentClassification?.image;

  return (
    <WorkingStudentTemplate>
      <WorkingStudentTemplate.CheckWorkingStudent question={question}>
        <ButtonContainer>
          <Button huge primary={classification === 'working_student'} secondary={classification !== 'working_student'} disabled>
            はい
          </Button>
          <Button huge primary={classification === 'none'} secondary={classification !== 'none'} disabled>
            いいえ
          </Button>
        </ButtonContainer>
      </WorkingStudentTemplate.CheckWorkingStudent>

      {classification === 'working_student' && (
        <WorkingStudentTemplate.DetailInput>
          <Section>
            <Section.Header>勤労学生区分</Section.Header>
            <Section.Body>
              <FormSection>
                <Textarea
                  label="勤労学生詳細情報"
                  note="学校名と入学年月日を記入してください"
                  value={request.profile?.workingStudentClassification?.detail}
                  disabled
                />

                <FileField label="学生手帳画像" disabled>
                  <FileField.FileContainer>{file && <FileField.File file={file} />}</FileField.FileContainer>
                </FileField>
              </FormSection>
            </Section.Body>
          </Section>
        </WorkingStudentTemplate.DetailInput>
      )}
    </WorkingStudentTemplate>
  );
};
