import React, { useContext, useEffect } from 'react';
import AsyncTaskLoading from './AsyncTaskLoading';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { useMutation } from './Graphql';
import useReactRouter from 'use-react-router';
import { useYear } from './useYear';

export const ASYNC_TASK_FRAGMENT = gql`
  fragment AsyncTaskFields on AsyncTask {
    id
    taskType
    status
    error
    excutedAt
    endedAt
  }
`;

const ASYNC_TASK = gql`
  query asyncTask($year: Int!) {
    client {
      id
      clientYearly(year: $year) {
        id
        asyncTask {
          ...AsyncTaskFields
        }
      }
    }
  }
  ${ASYNC_TASK_FRAGMENT}
`;

const CONFIRM = gql`
  mutation confirmAsyncTask($id: ID!) {
    confirmAsyncTask(input: { id: $id }) {
      result
    }
  }
`;

const percentage = task => Math.min(Math.max(Math.floor(100 * (1 - Math.exp(-moment().diff(task.excutedAt, 'second') / 7))), 0), 99);

const failedMessage = (
  <p>
    情報の一括更新でエラーが発生しました。
    <br />
    <Link to="/" className="u-txt-link">
      「依頼一覧」ページ
    </Link>
    より確認してください。
  </p>
);

const messages = {
  in_progress: '情報の一括更新を行っているため一部操作ができません',
  waiting: '情報の一括更新を行っているため一部操作ができません',
  success: '情報の一括更新が完了しました',
  failed: failedMessage,
  partial_success: failedMessage
};

const sendRequestMessage = {
  in_progress: '年末調整の依頼を行っているため一部操作ができません',
  waiting: '年末調整の依頼を行っているため一部操作ができません',
  success: '年末調整の依頼が完了しました',
  failed: failedMessage
};

const sendWithholdingSlipForEmployeesMessage = {
  in_progress: '源泉徴収票交付の準備を行っているため一部操作ができません',
  waiting: '源泉徴収票交付の準備を行っているため一部操作ができません',
  success: '源泉徴収票交付の準備が完了しました'
};

const clientYearlyCalcStatutoryTotalReportMessage = {
  in_progress: '法定調書合計表の集計を行っているため一部操作ができません',
  waiting: '法定調書合計表の集計を行っているため一部操作ができません',
  success: '法定調書合計表の集計が完了しました'
};

const fixDataMessage = {
  in_progress: '年末調整の確定処理を行っているため一部操作ができません',
  waiting: '年末調整の確定処理を行っているため一部操作ができません',
  success: '年末調整の確定処理が完了しました',
  failed: failedMessage
};

const convertMessage = task => {
  switch (task.taskType) {
    case 'send_request':
      return sendRequestMessage[task.status];
    case 'create_publish_withholding_slip_request':
    case 'create_deliver_withholding_slip_request':
      return sendWithholdingSlipForEmployeesMessage[task.status];
    case 'client_yearly_calc_statutory_total_report':
      return clientYearlyCalcStatutoryTotalReportMessage[task.status];
    case 'fix_data':
      return fixDataMessage[task.status];
    default:
      return messages[task.status];
  }
};

const FailedMessage = ({ year, task }) => {
  switch (task.taskType) {
    case 'bulk_update_difference_apply_methods':
    case 'apply_differences_to_payroll':
      return (
        <p>
          情報の一括更新でエラーが発生しました。
          <br />
          <Link to={`/${year}/result/difference_apply`} className="u-txt-link">
            「過不足額の精算」ページ
          </Link>
          より確認してください。
        </p>
      );
    case 'send_request':
      return (
        <p>
          年末調整依頼でエラーが発生しました。
          <br />
          <Link to="/" className="u-txt-link">
            「依頼一覧」ページ
          </Link>
          より確認してください。
        </p>
      );
    case 'create_publish_withholding_slip_request':
    case 'create_deliver_withholding_slip_request':
      return (
        <p>
          源泉徴収票交付の準備でエラーが発生しました。
          <br />
          <Link to={`/${year}/result/withholding_slip_deliver`} className="u-txt-link">
            「源泉徴収票交付」ページ
          </Link>
          より確認してください。
        </p>
      );
    case 'client_yearly_calc_statutory_total_report':
      return <p>法定調書合計表の集計でエラーが発生しました</p>;
    case 'fix_data':
      return (
        <p>
          年末調整の確定処理でエラーが発生しました。
          <br />
          <Link to={`/${year}/result`} className="u-txt-link">
            「年末調整結果」ページ
          </Link>
          より確認してください。
        </p>
      );
    default:
      return (
        <p>
          情報の一括更新でエラーが発生しました。
          <br />
          <Link to="/" className="u-txt-link">
            「依頼一覧」ページ
          </Link>
          より確認してください。
        </p>
      );
  }
};

const Context = React.createContext({
  task: null,
  refetch: () => {},
  taskRunningProps: {}
});

export const useAsyncTask = () => useContext(Context);

export const AsyncTask = ({ children }) => {
  const year = useYear();
  const { data, loading, error, startPolling, stopPolling, refetch } = useQuery(ASYNC_TASK, {
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only',
    variables: { year }
  });
  const [confirm] = useMutation(CONFIRM);
  if (loading) {
    return (
      <Context.Provider value={{ refetch }}>
        {children}
        {null}
        {null}
        {null}
      </Context.Provider>
    );
  }
  if (error || !data?.client?.clientYearly?.asyncTask) {
    return (
      <Context.Provider value={{ refetch }}>
        {children}
        {null}
        <Controller
          startPolling={startPolling}
          stopPolling={stopPolling}
          refetch={refetch}
          stop={error || !data?.client?.clientYearly?.asyncTask}
        />
        {null}
      </Context.Provider>
    );
  }
  const {
    client: {
      clientYearly: { asyncTask: task }
    }
  } = data;
  const text =
    task.status === 'waiting'
      ? '0%'
      : task.status === 'in_progress'
      ? `${percentage(task)}%`
      : task.status === 'success'
      ? '完了'
      : 'エラー';
  const confirmAndRefetch = async () => {
    await confirm({ variables: { id: task.id } });
    refetch();
  };
  return (
    <Context.Provider
      value={{
        refetch,
        task,
        taskRunningProps: ['waiting', 'in_progress'].includes(task.status) ? { disabled: true, disabledReason: convertMessage(task) } : {},
        confirm: confirmAndRefetch
      }}
    >
      {children}
      <AsyncTaskLoading
        error={task.status === 'failed' || task.status === 'partial_success'}
        done={task.status === 'success'}
        message={task.status === 'failed' ? <FailedMessage year={year} task={task} /> : convertMessage(task)}
        text={text}
        onCloseClick={confirmAndRefetch}
      />
      <Controller
        startPolling={startPolling}
        stop={task.status !== 'waiting' && task.status !== 'in_progress'}
        refetch={refetch}
        stopPolling={stopPolling}
      />
    </Context.Provider>
  );
};

export default AsyncTask;

const Controller = ({ stop, startPolling, stopPolling, refetch }) => {
  const { location } = useReactRouter();
  useEffect(() => {
    if (!stop) {
      startPolling(3000);
      return () => {
        stopPolling();
      };
    }
  }, [startPolling, stop, stopPolling]);
  useEffect(() => {
    refetch();
  }, [location.pathname, refetch]);
  return null;
};
