import React from 'react'
import Icon from 'react-icon-base'

const Fileup = props => (
  <Icon viewBox="0 0 20 20" {...props}>
    <g id="symbol" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon_fileup" fill="currentColor">
        <path d="M18,8 L2,8 L2,3 C2,2.448 2.337,2 2.889,2 L6.244,2 C6.626,2 6.974,2.217 7.142,2.56 L8.278,4.879 C8.614,5.565 9.31,6 10.074,6 L16.889,6 C17.441,6 18,6.448 18,7 L18,8 Z M18,17 C18,17.552 17.441,18 16.889,18 L2.889,18 C2.337,18 2,17.552 2,17 L2,10 L18,10 L18,17 Z M17.889,4 L11.125,4 C10.367,4 9.675,3.572 9.336,2.894 L8.442,1.106 C8.103,0.428 7.41,0 6.653,0 L1.889,0 C0.784,0 0,0.895 0,2 L0,18 C0,19.105 0.784,20 1.889,20 L17.889,20 C18.993,20 20,19.105 20,18 L20,6 C20,4.895 18.993,4 17.889,4 L17.889,4 Z" id="folder"></path>
      </g>
    </g>
  </Icon>
)
Fileup.defaultProps = {
  size: 20
}

export default Fileup
