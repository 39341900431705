import React from 'react';
import classnames from 'classnames';
import styles from './PrimaryPanel.scss';

export const Headding = ({ children, icon, className, ...rest }) => {
  return (
    <div className={classnames(styles.heading, className)} {...rest}>
      <div>{children}</div>
      {icon && <span className={styles.leadIcon}>{icon}</span>}
    </div>
  );
};

export const Panel = ({ children, ...rest }) => (
  <div className={styles.base} {...rest}>
    {children}
  </div>
);

export const Lead = ({ children }) => <div className={styles.lead}>{children}</div>;

export const Description = ({ children }) => <div className={styles.description}>{children}</div>;

export const Notes = ({ children }) => <div className={styles.notes}>{children}</div>;

export const Item = ({ children }) => <div className={styles.inner}>{children}</div>;

export const Buttons = ({ children }) => <div className={styles.buttons}>{children}</div>;

export const Label = ({ children }) => <div className={styles.label}>{children}</div>;

export const Text = ({ children }) => <div className={styles.text}>{children}</div>;

export const Toggles = ({ children }) => <div className={styles.toggles}>{children}</div>;

export const Toggle = ({ children, active, onClick, disabled }) => (
  <div
    className={classnames({
      [styles.toggleOn]: active,
      [styles.toggleOff]: !active,
      [styles.toggleDisabled]: disabled
    })}
    onClick={!disabled && onClick}
    role="presentation"
  >
    {children}
  </div>
);

export const Line = () => <div className={styles.line} />;
