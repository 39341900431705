import React from 'react';
import Button from 'jbc-front/components/Button';
import FormErrors, { onSubmitFail } from 'jbc-front/components/FormErrors';
import { gql } from '@apollo/client';
import { useQuery, useMutation, useYear, reduxForm, Modal, LoadingPage, Hoc, useFormValue } from '../components';
import BasicInformation, {
  EMPLOYEE_DETAIL_FRAGMENT,
  BASIC_INFORMATION_FRAGMENT,
  HOUSEHOLDER_FRAGMENT,
  ADJ_TARGET_FIELDS_FRAGMENT,
  makeInitialValues as basicInformation,
  formatValues as formatBasicInfo
} from '../employee_forms/BasicInformation';
import Dependents, { makeInitialValues as otherFamilies, OTHER_FAMILIES_FRAGMENT } from '../employee_forms/Dependents';
import TaxClassifications, {
  makeInitialValues as taxClassifications,
  TAX_CLASSIFICATIONS_FRAGMENT
} from '../employee_forms/TaxClassifications';
import Spouse, { SPOUSE_INFO_FRAGMENT, makeInitialValues as spouseInformation } from '../employee_forms/Spouse';
import Insurances, {
  formatValues as formatInsurances,
  INSURANCES_FRAGMENT,
  makeInitialValues as insurances
} from '../employee_forms/Insurances';
import FormerJobs, { FORMER_JOBS_FRAGMENT, makeInitialValues as formerJobs } from '../employee_forms/FormerJobs';
import scrollSpy, { AnchorLink, AnchorTarget } from 'jbc-front/components/ScrollSpy';
import styles from './EmployeeEdit.scss';
import ListGroup from 'jbc-front/components/ListGroup';
import _ from 'lodash';
import { DiffProvider } from '../components/FieldWithDiff';
import { TextAreaField } from 'jbc-front/components/Form';
import { parseBackUrl } from '../utils/url';
import ResidentCard, { makeInitialValues as residentCard } from '../employee_forms/ResidentCard';
import ResidentTax, {
  makeInitialValues as residentTax,
  RESIDENT_TAX_FRAGMENT,
  EMPLOYEE_ADDRESS_CLASSIFICATION
} from '../employee_forms/ResidentTax';
import useAdjFields from '../employee_forms/useAdjFields';
import { useNotify } from '../actions';
import Reports from '../employee_forms/Reports';
import Attachments, {
  makeInitialValues as attachments,
  formatValues as formatAttachments,
  ATTACHMENTS_FRAGMENT
} from '../employee_forms/Attachments';
import EmployeeIncome, { EMPLOYEE_INCOME_FRAGMENT, makeInitialValues as incomes } from '../employee_forms/EmployeeIncome';
import { AcceptInformation, makeInitialValues as acceptComment } from '../employee_forms/AcceptInformation';
import CancelInformationRequest from '../employee_forms/CancelInformationRequest';
import { applicableForOtherFamily } from '@jbc-year-end-adj/ancient/utils/incomeAdjustment';
import Memo, { MEMO_FRAGMENT } from '../components/Memo';
import { SaveRequest } from '../components/SaveRequest';
import classnames from 'classnames';
import { AdminUser, SingleEmployee } from 'jbc-front/components/icons';
import { recordDisplay } from '../utils';
import FloatingButton from '../components/FloatingButton';
import ScrollToTop from '../components/ScrollToTop';
import { maxLength } from '../validators';

export const Anchor = ({ current, name, children }) => (
  <ListGroup.Item as={AnchorLink} name={name}>
    <div>
      {current === name ? <strong className={styles.naviLink}>{children}</strong> : <span className={styles.naviLink}>{children}</span>}
    </div>
    {current === name && <ListGroup.Icon />}
  </ListGroup.Item>
);

const formName = 'employeeForm';

const getDiff = (values, tmpData) => {
  return {
    ..._.mapValues(
      _.pickBy(tmpData, (value, key) => {
        if (_.isArray(value)) {
          return true;
        }
        if (_.isObject(value)) {
          return true;
        }
        return value !== values[key];
      }),
      (value, key) => {
        if (_.isArray(value)) {
          return value.map(item => {
            const fromArr = values[key] || [];
            const compareKey = item.compareKey;
            const from = fromArr.find(i => i.compareKey === compareKey);
            return getDiff(from || {}, item);
          });
        }
        if (_.isObject(value)) {
          return getDiff(values[key] || {}, value);
        }
        return true;
      }
    ),
    ..._.pickBy(values, (value, key) => {
      if (_.isObject(value) && (!value['filename'] || tmpData[key])) {
        return false;
      }
      return value && !tmpData[key];
    })
  };
};

const EMPLOYEE_DETAIL = gql`
  query employeeDetail($id: ID!, $year: Int!) {
    client {
      id
      clientYearly(year: $year) {
        id
        fixed
        clientSetting {
          sendAcceptedEmail
        }
        employee(id: $id) {
          id
          status
          ...EmployeeDetail
          ...ResidentTaxEmployee
          salaryGreaterThan20Million
          profile {
            id
            ...BasicInformation
            ...OtherFamilies
            ...TaxClassifications
            ...SpouseInfo
            ...Insurances
            ...FormerJobs
            ...Householder
            ...AdjTargetFields
            ...ResidentTaxCity
            ...Attachments
            ...Incomes
          }
          request {
            id
            comment
            requestComments {
              id
              actedUser {
                id
                name
              }
              commentBy
              comment
              createdAt
            }
            profile {
              id
              ...BasicInformation
              ...OtherFamilies
              ...TaxClassifications
              ...SpouseInfo
              ...Insurances
              ...FormerJobs
              ...Householder
              ...AdjTargetFields
              ...ResidentTaxCity
              ...Attachments
              ...Incomes
            }
            actedUser {
              id
              name
            }
            createdAt
          }
          memos {
            ...Memo
          }
        }
      }
    }
  }
  ${EMPLOYEE_DETAIL_FRAGMENT}
  ${BASIC_INFORMATION_FRAGMENT}
  ${OTHER_FAMILIES_FRAGMENT}
  ${TAX_CLASSIFICATIONS_FRAGMENT}
  ${SPOUSE_INFO_FRAGMENT}
  ${INSURANCES_FRAGMENT}
  ${FORMER_JOBS_FRAGMENT}
  ${HOUSEHOLDER_FRAGMENT}
  ${ADJ_TARGET_FIELDS_FRAGMENT}
  ${RESIDENT_TAX_FRAGMENT}
  ${ATTACHMENTS_FRAGMENT}
  ${EMPLOYEE_INCOME_FRAGMENT}
  ${MEMO_FRAGMENT}
`;

const UPDATE_EMPLOYEE = gql`
  mutation updateEmployee($input: UpdateEmployeeInput!) {
    updateEmployee(input: $input) {
      employee {
        id
        ...EmployeeDetail
        ...ResidentTaxEmployee
        profile {
          id
          ...BasicInformation
          ...OtherFamilies
          ...TaxClassifications
          ...SpouseInfo
          ...Insurances
          ...FormerJobs
          ...Householder
          ...AdjTargetFields
          ...ResidentTaxCity
          ...Attachments
          ...Incomes
        }
        request {
          id
          profile {
            id
          }
        }
      }
    }
  }
  ${EMPLOYEE_DETAIL_FRAGMENT}
  ${BASIC_INFORMATION_FRAGMENT}
  ${OTHER_FAMILIES_FRAGMENT}
  ${TAX_CLASSIFICATIONS_FRAGMENT}
  ${SPOUSE_INFO_FRAGMENT}
  ${INSURANCES_FRAGMENT}
  ${FORMER_JOBS_FRAGMENT}
  ${HOUSEHOLDER_FRAGMENT}
  ${ADJ_TARGET_FIELDS_FRAGMENT}
  ${RESIDENT_TAX_FRAGMENT}
  ${ATTACHMENTS_FRAGMENT}
  ${EMPLOYEE_INCOME_FRAGMENT}
`;

const REJECT = gql`
  mutation reject($employeeId: ID!, $comment: String) {
    reject(input: { employeeId: $employeeId, comment: $comment }) {
      employee {
        id
        status
      }
    }
  }
`;

const Form =
  (({ handleSubmit, submitting, employee, request, current, diff, history, location, client, change }) => {
    const [reject] = useMutation(REJECT);
    const notify = useNotify();
    const { needAdjThisYear } = useAdjFields();
    const isNonResident = useFormValue('basicInformation.isNonResident');
    const requestComments = request?.requestComments;
    const onSubmit = async action => {
      await change('action', action);
      await handleSubmit();
    };

    return (
      <div>
        <div className="l-title-wrap" style={{ paddingBottom: 10 }}>
          <h1 className="m-title-main">従業員情報の入力</h1>
          {client.clientYearly?.fixed && (
            <div>
              年末調整情報が確定していますので従業員情報の修正はできません。
              <br />
              修正が必要な場合、確定を解除してください。
            </div>
          )}
        </div>
        <div className={styles.editTitle}>
          <Memo employee={employee} size={35} onClickOutside={false} />
          <div className={styles.name}>{`${employee.profile.lastName} ${employee.profile.firstName}`}</div>
          <div className={styles.staffCode}>{employee.staffCode}</div>
        </div>
        {requestComments?.length > 0 && (
          <div className="l-flex">
            <div className={styles.commentBoxList}>
              <ListGroup>
                <ListGroup.Title>コメント</ListGroup.Title>
                {requestComments
                  .filter(comment => !!comment.comment)
                  .map(comment => {
                    const CommentIcon = comment.commentBy === 'admin' ? AdminUser : SingleEmployee;
                    return (
                      <ListGroup.Item key={comment.id}>
                        <div>
                          <p>
                            <CommentIcon className={styles.commentIcon} />
                            {comment.commentBy === 'admin' ? (
                              <span>{`管理者(${comment.actedUser ? comment.actedUser.name : '在籍していない管理者'})`}</span>
                            ) : (
                              <span>{`${employee.profile.lastName} ${employee.profile.firstName}`}</span>
                            )}
                            <span className={styles.date}>{recordDisplay.dateOrTime(comment.createdAt)}</span>
                          </p>
                          <p className={styles.comment}>{comment.comment}</p>
                        </div>
                      </ListGroup.Item>
                    );
                  })}
              </ListGroup>
            </div>
          </div>
        )}
        <div className={classnames('l-flex', styles.mainFlex)}>
          <div className={styles.mainGrid}>
            <div className={styles.side}>
              <ListGroup>
                <div className={styles.navi}>
                  <Anchor name="basic_information" current={current}>
                    基本情報
                  </Anchor>
                  {!isNonResident && (
                    <Anchor name="resident_card" current={current}>
                      住民票住所
                    </Anchor>
                  )}
                  <Anchor name="resident_tax" current={current}>
                    住民税
                  </Anchor>
                  <Anchor name="tax_classifications" current={current}>
                    税区分情報
                  </Anchor>
                  <Anchor name="former_jobs" current={current}>
                    前職情報
                  </Anchor>
                  <Anchor name="employee_income" current={current}>
                    給与収入等
                  </Anchor>
                  <Anchor name="spouse" current={current}>
                    配偶者情報
                  </Anchor>
                  <Anchor name="dependents" current={current}>
                    被扶養者情報
                  </Anchor>
                  {needAdjThisYear && (
                    <Anchor name="insurances" current={current}>
                      保険・ローン
                    </Anchor>
                  )}
                  <Anchor name="attachments" current={current}>
                    添付ファイル
                  </Anchor>
                </div>
              </ListGroup>
              {['applying', 'fixed', 'accepted'].includes(employee.status) && (
                <Reports
                  employee={employee}
                  profile={['applying', 'fixed'].includes(employee.status) ? employee.request.profile : employee.profile}
                />
              )}
            </div>
            <div className={classnames(styles.formBoxList)}>
              <form onSubmit={handleSubmit}>
                <div className={styles.forArchorTarget}>
                  <DiffProvider diff={diff} fixed={client.clientYearly?.fixed}>
                    <FormErrors acceptParent />
                    <AnchorTarget name="basic_information" />
                    <BasicInformation employee={employee} />
                    {!isNonResident && (
                      <>
                        <AnchorTarget name="resident_card" />
                        <ResidentCard />
                      </>
                    )}
                    <AnchorTarget name="resident_tax" />
                    <ResidentTax />
                    <AnchorTarget name="tax_classifications" />
                    <TaxClassifications />
                    <AnchorTarget name="former_jobs" />
                    <FormerJobs />
                    <AnchorTarget name="employee_income" />
                    <EmployeeIncome />
                    <AnchorTarget name="spouse" />
                    <Spouse />
                    <AnchorTarget name="dependents" />
                    <Dependents />
                    {needAdjThisYear && (
                      <>
                        <AnchorTarget name="insurances" />
                        <Insurances />
                      </>
                    )}
                    <AnchorTarget name="attachments" />
                    <Attachments />
                  </DiffProvider>
                </div>
              </form>
            </div>
          </div>
          <FloatingButton className={styles.floatingButtonSpace}>
            <div className={`${styles.buttonContainer}`}>
              {['applying', 'fixed'].includes(employee.status) && (
                <Modal>
                  {({ showModal, hideModal, isOpen }) => (
                    <>
                      <Button onClick={showModal}>修正を依頼する</Button>
                      {isOpen && (
                        <Modal.Modal>
                          <Modal.Header>修正を依頼する</Modal.Header>
                          <Hoc
                            makeHoc={() => reduxForm({ form: 'reject' })}
                            onSubmit={async (values = {}) => {
                              await reject({
                                variables: { employeeId: employee.id, comment: values.comment || null }
                              });
                              history.push(parseBackUrl(location, '/'));
                              notify('従業員に入力を再依頼しました');
                            }}
                          >
                            {({ submitting, handleSubmit }) => (
                              <>
                                <Modal.Body>
                                  <div className="form_box_main">
                                    <TextAreaField
                                      name="comment"
                                      label="コメント"
                                      placeholder="修正して欲しい内容など入力してください"
                                      validate={[maxLength(1000)]}
                                    />
                                  </div>
                                </Modal.Body>
                                <Modal.Footer>
                                  <Modal.Buttons>
                                    <Button onClick={hideModal}>キャンセル</Button>
                                    <Button primary onClick={handleSubmit} disabled={submitting}>
                                      依頼
                                    </Button>
                                  </Modal.Buttons>
                                </Modal.Footer>
                              </>
                            )}
                          </Hoc>
                        </Modal.Modal>
                      )}
                    </>
                  )}
                </Modal>
              )}
              <SaveRequest
                status={employee.status}
                handleSubmit={async () => await onSubmit('save')}
                submitting={submitting}
                isFixed={client.clientYearly?.fixed}
              />
              {employee.status === 'applying' || employee.status === 'fixed' ? (
                <AcceptInformation client={client} employee={employee} submitting={submitting} handleSubmit={onSubmit} />
              ) : (
                <CancelInformationRequest client={client} status={employee.status} submitting={submitting} handleSubmit={onSubmit} />
              )}
            </div>
          </FloatingButton>
        </div>
        <ScrollToTop />
      </div>
    );
  })
  |> reduxForm({
    form: formName,
    onSubmitFail
  })
  |> scrollSpy;

const makeInitialValues = (clientSetting, employee, profile) => ({
  id: employee.id,
  basicInformation: basicInformation(employee, profile),
  residentCard: residentCard(profile),
  residentTax: residentTax(employee, profile),
  otherFamilies: otherFamilies(profile.otherFamilies),
  taxClassifications: taxClassifications(profile),
  spouseInformation: spouseInformation(profile),
  insurances: insurances(profile),
  formerJobs: formerJobs(profile),
  attachments: attachments(profile),
  employeeIncome: incomes(profile),
  ...acceptComment(clientSetting.sendAcceptedEmail)
});

const formatValues = values => values |> formatBasicInfo |> formatInsurances |> formatAttachments;

const EmployeeEdit = ({
  match: {
    params: { id }
  },
  history,
  location
}) => {
  const year = useYear();
  const notify = useNotify();
  const { loading, data } = useQuery(EMPLOYEE_DETAIL, { fetchPolicy: 'network-only', variables: { id, year } });
  const { loading: loadingSetting } = useQuery(EMPLOYEE_ADDRESS_CLASSIFICATION, { variables: { year } });
  const [updateEmployee] = useMutation(UPDATE_EMPLOYEE);
  if (loading || loadingSetting) return <LoadingPage />;
  const {
    client,
    client: {
      clientYearly: { employee }
    },
    client: {
      clientYearly: {
        employee: { request }
      }
    },
    client: {
      clientYearly: { clientSetting }
    }
  } = data;
  const isApplying = employee => ['applying', 'rejected', 'fixed'].includes(employee.status);

  return (
    <Form
      {...{
        history,
        location,
        employee,
        request,
        client
      }}
      onSubmit={async values => {
        const errorDependents = values.otherFamilies.filter(
          dependent => dependent.thisYear?.incomeAdjustmentDeduction && !applicableForOtherFamily(dependent, year)
        );
        if (!_.isEmpty(errorDependents)) {
          await errorDependents.forEach(dependent => {
            notify(`被扶養者${dependent.lastName} ${dependent.firstName}は所得金額調整控除の対象となる条件を満たしていません`, 'error');
          });
          return;
        }
        await updateEmployee({
          variables: {
            input: formatValues(values)
          }
        });
        history.push(parseBackUrl(location, '/'));
      }}
      initialValues={
        isApplying(employee) && employee.request.profile
          ? makeInitialValues(clientSetting, employee, employee.request.profile)
          : makeInitialValues(clientSetting, employee, employee.profile)
      }
      diff={
        isApplying(employee) && employee.request.profile
          ? getDiff(
              makeInitialValues(clientSetting, employee, employee.profile),
              makeInitialValues(clientSetting, employee, employee.request.profile)
            )
          : {}
      }
    />
  );
};

export default EmployeeEdit;
