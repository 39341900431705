import React from 'react'
import Icon from 'react-icon-base'

const CheckboxFilter = (props) => (
  <Icon viewBox="0 0 20 20" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M19.25,0 C19.8490215,0 20.2063151,0.6676091 19.8740377,1.16602515 L15.0345432,8.42526692 C14.8047789,8.76991344 14.3391269,8.86304384 13.9944803,8.6332795 C13.6498338,8.40351515 13.5567034,7.93786315 13.7864678,7.59321663 L17.848,1.5 L2.151,1.5 L7.37403772,9.33397485 C7.43563804,9.42637533 7.47569166,9.53090057 7.49186845,9.63985466 L7.5,9.75 L7.5,18.5 L8.3016921,18.5 C8.68138787,18.5 8.99518306,18.7821539 9.04484549,19.1482294 L9.0516921,19.25 C9.0516921,19.6642136 8.71590567,20 8.3016921,20 L6.75,20 C6.33578644,20 6,19.6642136 6,19.25 L6,9.978 L0.125962279,1.16602515 C-0.186769358,0.696927691 0.111329985,0.077956804 0.646890759,0.0067598742 L0.75,0 L19.25,0 Z"
        fill={props.color}
        fillRule="nonzero"
      ></path>
      <rect fill={props.color} x="9" y="9" width="11" height="11" rx="2"></rect>
      <path
        d="M17.2811721,11.4779573 C17.5694883,11.1805575 18.044305,11.1731939 18.3417047,11.4615101 C18.6391045,11.7498263 18.6464681,12.224643 18.3581519,12.5220427 L13.873443,17.1480442 C13.5824837,17.4481702 13.1023166,17.4525081 12.8059826,17.1576877 L10.4710295,14.8346613 C10.1773873,14.542519 10.1761714,14.0676468 10.4683138,13.7740046 C10.7604561,13.4803624 11.2353283,13.4791465 11.5289705,13.7712888 L13.324,15.558 L17.2811721,11.4779573 Z"
        fill="#FFFFFF"
        fillRule="nonzero"
      ></path>
    </g>
  </Icon>
)
CheckboxFilter.defaultProps = {
  size: 16,
  color: '#3498DB',
}

export default CheckboxFilter
