import React from 'react'
import Icon from 'react-icon-base'

const PlusSquare = props => (
  <Icon viewBox="0 0 20 20" {...props}>
    <g fill="#fff">
      <rect height="19" width="19" rx="2" stroke="#CCC" x=".5" y=".5"/>
      <path d="M9.254 10.746V14.9l1.492.011-.011-4.165h4.176L14.9 9.254h-4.154l-.011-4.165h-1.48v4.176l-4.144-.01v1.48z" fill="currentColor"/>
    </g>
  </Icon>
)
PlusSquare.defaultProps = {
  size: 20
}

export default PlusSquare
