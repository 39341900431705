import React, { useEffect, useMemo } from 'react';
import { gql } from '@apollo/client';
import { useQuery, useMutation, useYear, DownloadModal } from '../components';

const DIFFERENCE_TO_APPLY_CSV_EXPORT_REQUEST_RUNNING = gql`
  query differenceToApplyCsvExportRequestRunning($year: Int!) {
    client {
      id
      clientYearly(year: $year) {
        id
        differenceToApplyCsvExportRequestRunning
      }
    }
  }
`;

const DIFFERENCE_TO_APPLY_CSV = gql`
  query differenceToApplyCsv($fileEncoding: FileEncoding, $search: EmployeeSearchInput, $year: Int!) {
    client {
      id
      clientYearly(year: $year) {
        id
        differenceToApplyCsv(fileEncoding: $fileEncoding, search: $search)
      }
    }
  }
`;

const CREATE_DIFFERENCE_TO_APPLY_CSV_EXPORT_REQUEST = gql`
  mutation createDifferenceToApplyCsvExportRequest($input: CreateCsvExportRequestInput!) {
    createCsvExportRequest(input: $input) {
      clientYearly {
        id
        differenceToApplyCsvExportRequestRunning
      }
    }
  }
`;

const DownloadDifferenceToApplyCsv = ({ search, hasConditions }) => {
  const year = useYear();
  const [createCsvRequest] = useMutation(CREATE_DIFFERENCE_TO_APPLY_CSV_EXPORT_REQUEST);
  const { data, startPolling, stopPolling } = useQuery(DIFFERENCE_TO_APPLY_CSV_EXPORT_REQUEST_RUNNING, {
    variables: { year },
    fetchPolicy: 'network-only'
  });
  const exportRequest = data?.client?.clientYearly || {};
  const polling = useMemo(() => {
    return exportRequest?.differenceToApplyCsvExportRequestRunning;
  }, [exportRequest]);

  useEffect(() => {
    if (polling) {
      startPolling(3000);
      return () => {
        stopPolling();
      };
    }
    return undefined;
  });

  return (
    <DownloadModal
      button="CSV一括ダウンロード"
      header="過不足データダウンロード"
      fileName="過不足データ.csv"
      noteItem="過不足額情報"
      type="difference_to_apply"
      query={DIFFERENCE_TO_APPLY_CSV}
      getFile={data => data.client.clientYearly.differenceToApplyCsv}
      createCsvRequest={createCsvRequest}
      search={search}
      hasConditions={hasConditions}
      disabled={polling}
    />
  );
};

export default DownloadDifferenceToApplyCsv;
