import { gql } from '@apollo/client';

type ImageUploadStatus = 'none' | 'optional' | 'required';

export type QueryResult = {
  client: {
    id: string;
    clientYearly: {
      id: string;
      clientSetting: ClientSetting;
    };
  };
};

export type ClientSetting = {
  id: string;
  requestPrint: boolean;
  requestImageUploadSettings: {
    lifeInsurance: ImageUploadStatus;
    earthquakeInsurance: ImageUploadStatus;
    pensionInsurance: ImageUploadStatus;
    socialInsurance: ImageUploadStatus;
    premium: ImageUploadStatus;
    housingLoan: ImageUploadStatus;
    studentIdCard: ImageUploadStatus;
    handicap: ImageUploadStatus;
    nonResidentAndStudyAbroad: ImageUploadStatus;
    previousWithholdingSlip: ImageUploadStatus;
  };
  employeeAddressClassification: 'resident_card' | 'profile';
  sendAcceptedEmail: boolean;
  hasSubmissionDeadline: boolean;
  submissionDeadline: {
    id: string;
    submissionDeadlineDate: string;
    submissionDeadlineHour: string;
    useReminder: boolean;
    reminders: {
      id: string;
      remindDaysBefore: string;
      remindHour: string;
    }[];
  };
};

export const FETCH_CLIENT_SETTING = gql`
  query clientSetting {
    client {
      id
      clientYearly {
        id
        clientSetting {
          id
          requestPrint
          requestImageUploadSettings {
            lifeInsurance
            earthquakeInsurance
            pensionInsurance
            socialInsurance
            premium
            housingLoan
            studentIdCard
            handicap
            nonResidentAndStudyAbroad
            previousWithholdingSlip
          }
          employeeAddressClassification
          sendAcceptedEmail
          hasSubmissionDeadline
          submissionDeadline {
            id
            submissionDeadlineDate
            submissionDeadlineHour
            useReminder
            reminders {
              id
              remindDaysBefore
              remindHour
            }
          }
        }
      }
    }
  }
`;
