import React, { Component } from 'react';
import _onClickOutside from 'react-onclickoutside';

export const onClickOutside = onClickOutside => Child =>
  class extends Component {
    handleClickOutside = e => {
      onClickOutside(this.props, e);
    };
    render() {
      return <Child {...this.props} />;
    }
  } |> _onClickOutside;
