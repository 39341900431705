// 所得金額調整控除に関する判定
import moment from 'moment';
import { HandicapType } from '@jbc-year-end-adj/types';

const under23Threshold = (year: number) => moment([year, 0, 1]).subtract(22, 'years');
const parseNumber = (num: string | undefined) => Number(num ? num.replace(/,/g, '') : 0);

export const applicableForEmployee = (handicapClassification: HandicapType | undefined) => handicapClassification === 'special';

export const applicableForSpouse = (spouseHandicapType: HandicapType | undefined, spouseTotalEarnings: string | undefined) =>
  spouseHandicapType === 'special' && parseNumber(spouseTotalEarnings) <= 480000;

type OtherFamily = Partial<{
  birthday: string;
  thisYear: Partial<{
    dependentTaxLaw: boolean;
    incomeAdjustmentDeduction: boolean;
    earnings: string;
    handicapType: HandicapType;
  }>;
}>;

export const applicableForOtherFamily = (otherFamily: OtherFamily, year: number): boolean => {
  const yearlyInfo = otherFamily.thisYear;
  const birthday = otherFamily.birthday && moment(otherFamily.birthday);
  const earnings = yearlyInfo?.earnings ? String(yearlyInfo.earnings) : '0';

  return !!(
    (yearlyInfo?.dependentTaxLaw || yearlyInfo?.incomeAdjustmentDeduction) &&
    parseNumber(earnings) <= 480000 &&
    (yearlyInfo.handicapType === 'special' || (birthday && birthday.isValid() && birthday.isAfter(under23Threshold(year))))
  );
};
