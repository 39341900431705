import Rollbar from 'rollbar';
import { isIgnorableNetworkError, isSentToRollbar } from './utils';

const rollbarConfig = {
  accessToken: '67dfba7504e04a619e1c9e45007ea03f',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.SERVER_ENV
  },
  enabled: process.env.ENABLE_ROLLBAR,
  scrubFields: ['password', 'value', 'token', 'variables'],
  checkIgnore: (isUncaught, args, payload) => {
    // 明示的に送信したイベントは必ず送る
    if (!isUncaught) {
      return false;
    }

    for (const arg of args) {
      if (isSentToRollbar(arg)) {
        return true;
      }
      if (arg instanceof Error) {
        if (arg.graphQLErrors) {
          const err = arg.graphQLErrors[0];
          // extensions.codeの付いているGraphQLErrorは無視
          if (err?.extensions?.code) {
            return true;
          }
        }
        if (isIgnorableNetworkError(arg)) {
          return true;
        }
      }
    }

    return false;
  }
};

Rollbar.init(rollbarConfig);

window.rollbar = Rollbar;
