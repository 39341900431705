import { FC, useState, useRef, useEffect } from 'react';
import { Pdf as JbcPdf } from 'jbc-front/components/presenters/ui/Pdf';
import styles from './OldPdfPreview.scss';

// @ts-ignore
import PDF from 'react-pdf-js';

type PdfProps = {
  pdf: string;
  className?: string;
};

export const OldPdfPreview: FC<PdfProps> = ({ pdf }) => {
  const zooms = [25, 33, 50, 67, 75, 80, 90, 100, 110, 125, 150, 175, 200, 250, 300, 400, 500];
  const [page, setPage] = useState<number>(1);
  const [zoom, setZoom] = useState<number>(50);
  const [scale, setScale] = useState<number>(zoom / 100.0);
  const [totalPage, setTotalPage] = useState<number>();
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const handleChangeZoom = (scaleType: 'in' | 'out') => {
    const currentIndex = zooms.findIndex(value => zoom === value);
    if (currentIndex !== -1) {
      const nextIndex = (() => {
        switch (scaleType) {
          case 'in':
            return currentIndex + 1;
          case 'out':
            return currentIndex - 1;
          default:
            return currentIndex;
        }
      })();

      const newZoomValue = zooms[nextIndex];

      if (newZoomValue) {
        setZoom(newZoomValue);
        setScale(newZoomValue / 100.0);
      }
    }
  };

  const handlePrevPage = () => {
    const prevPage = page - 1;

    if (prevPage > 0) {
      setPage(prevPage);
    }
  };

  const handleNextPage = () => {
    const nextPage = page + 1;

    if (totalPage && nextPage <= totalPage) {
      setPage(nextPage);
    }
  };

  const adjustCanvasSize = () => {
    if (canvasRef.current) {
      const parentWidth = canvasRef.current.parentElement?.offsetWidth || 0;
      const canvas = canvasRef.current;
      canvas.style.width = parentWidth + 'px';
      canvas.style.height = 'auto';
    }
  };

  useEffect(() => {
    adjustCanvasSize();
    window.addEventListener('resize', adjustCanvasSize);
    return () => {
      window.removeEventListener('resize', adjustCanvasSize);
    };
  }, []);

  useEffect(() => {
    adjustCanvasSize();
  }, []);

  return (
    <JbcPdf.Container className={styles.pdfContainer}>
      <PDF file={`${pdf}`} onDocumentComplete={setTotalPage} page={page} scale={scale} className={styles.canvas} />
      <JbcPdf.ControllerContainer className={styles.pdfControllerContainer}>
        <JbcPdf.Controller
          className={styles.pdfController}
          page={page}
          numPages={totalPage}
          zoom={zoom}
          onZoomIn={() => handleChangeZoom('in')}
          onZoomOut={() => handleChangeZoom('out')}
          onPrevious={handlePrevPage}
          onNext={handleNextPage}
        />
      </JbcPdf.ControllerContainer>
    </JbcPdf.Container>
  );
};
