import { yup } from '@jbc-year-end-adj/common/yup';
import { Profile, LifeInsurance } from '../../../../query';
import { dateFormFormat, amountFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { FileType } from '@jbc-year-end-adj/types';

export type LifeInsuranceType = 'GeneralLifeInsurance' | 'NursingMedicalInsurance' | 'IndividualAnnuityInsurance';
export type NewOrOldType = 'new_type' | 'old_type';
export type SocialInsuranceType =
  | 'national_pension'
  | 'national_insurance'
  | 'national_health_insurance'
  | 'national_nursing_medical_insurance'
  | 'voluntary_continued_insurance'
  | 'elderly_medical_insurance'
  | 'farmers_pension';

export type Schema = {
  lifeInsurances: LifeInsuranceSchema[];
  earthquakeInsurances: EarthquakeInsuranceSchema[];
  socialInsurances: SocialInsuranceSchema[];
};

export type LifeInsuranceSchema = {
  id?: string;
  lifeInsuranceType: LifeInsuranceType;
  amount: string;
  insuranceDuration: string;
  insuranceType: string;
  name: string;
  nameOfContractor: string;
  nameOfRecipient: string;
  newOrOld: NewOrOldType;
  relationshipOfRecipient: string;
  image: FileType | File | null;
  paymentStartDate?: string;
};

export type EarthquakeInsuranceSchema = {
  id?: string;
  amount: string;
  insuranceDuration: string;
  insuranceType: string;
  name: string;
  nameOfContractor: string;
  nameOfRecipient: string;
  newOrOld: NewOrOldType;
  relationshipOfRecipient: string;
  image: FileType | File | null;
};

export type SocialInsuranceSchema = {
  id?: string;
  insuranceType: SocialInsuranceType;
  name: string;
  nameOfPayer: string;
  relationshipOfPayer: string;
  amount: string;
  image: FileType | File | null;
};

const generalLifeInsuranceSchema = yup.object({
  id: yup.string(),
  lifeInsuranceType: yup.mixed<LifeInsuranceType>().oneOf(['GeneralLifeInsurance']),
  name: yup
    .string()
    .required()
    .label('保険会社等の名称'),
  insuranceType: yup
    .string()
    .required()
    .label('保険等の種類'),
  insuranceDuration: yup
    .string()
    .required()
    .label('保険期間'),
  nameOfContractor: yup
    .string()
    .required()
    .label('契約者氏名'),
  nameOfRecipient: yup
    .string()
    .required()
    .label('受取人・氏名'),
  relationshipOfRecipient: yup
    .string()
    .required()
    .label('受取人・続柄'),
  newOrOld: yup
    .mixed<NewOrOldType>()
    .required()
    .label('新・旧の区分'),
  amount: yup
    .string()
    .required()
    .positiveAmountFormat()
    .maxAmount(999999999)
    .label('保険料金額'),
  image: yup
    .mixed<FileType | File>()
    .nullable()
    .label('証明書画像')
});

const nursingMedicalInsuranceSchema = yup.object({
  id: yup.string(),
  lifeInsuranceType: yup.mixed<LifeInsuranceType>().oneOf(['NursingMedicalInsurance']),
  name: yup
    .string()
    .required()
    .label('保険会社等の名称'),
  insuranceType: yup
    .string()
    .required()
    .label('保険等の種類'),
  insuranceDuration: yup
    .string()
    .required()
    .label('保険期間'),
  nameOfContractor: yup
    .string()
    .required()
    .label('契約者氏名'),
  nameOfRecipient: yup
    .string()
    .required()
    .label('受取人・氏名'),
  relationshipOfRecipient: yup
    .string()
    .required()
    .label('受取人・続柄'),
  amount: yup
    .string()
    .required()
    .positiveAmountFormat()
    .maxAmount(999999999)
    .label('保険料金額'),
  image: yup
    .mixed<FileType | File>()
    .nullable()
    .label('証明書画像')
});

const individualAnnuityInsuranceSchema = yup.object({
  id: yup.string(),
  lifeInsuranceType: yup.mixed<LifeInsuranceType>().oneOf(['IndividualAnnuityInsurance']),
  name: yup
    .string()
    .required()
    .label('保険会社等の名称'),
  insuranceType: yup
    .string()
    .required()
    .label('保険等の種類'),
  insuranceDuration: yup
    .string()
    .required()
    .label('年金支払期間'),
  nameOfContractor: yup
    .string()
    .required()
    .label('契約者氏名'),
  nameOfRecipient: yup
    .string()
    .required()
    .label('受取人・氏名'),
  relationshipOfRecipient: yup
    .string()
    .required()
    .label('受取人・続柄'),
  paymentStartDate: yup
    .string()
    .required()
    .dateFormat()
    .label('支払開始日'),
  newOrOld: yup
    .mixed<NewOrOldType>()
    .required()
    .label('新・旧の区分'),
  amount: yup
    .string()
    .required()
    .positiveAmountFormat()
    .maxAmount(999999999)
    .label('保険料金額'),
  image: yup
    .mixed<FileType | File>()
    .nullable()
    .label('証明書画像')
});

const lifeInsuranceSchema = yup.lazy((value: LifeInsuranceSchema) => {
  switch (value.lifeInsuranceType) {
    case 'GeneralLifeInsurance':
      return generalLifeInsuranceSchema;
    case 'NursingMedicalInsurance':
      return nursingMedicalInsuranceSchema;
    case 'IndividualAnnuityInsurance':
      return individualAnnuityInsuranceSchema;
  }
});

const earthquakeInsuranceSchema = yup.object({
  id: yup.string(),
  name: yup
    .string()
    .required()
    .label('保険会社等の名称'),
  insuranceType: yup
    .string()
    .required()
    .label('保険等の種類（目的）'),
  insuranceDuration: yup
    .string()
    .required()
    .label('保険期間'),
  nameOfContractor: yup
    .string()
    .required()
    .label('保険等の契約者の氏名'),
  nameOfRecipient: yup
    .string()
    .required()
    .label('保険等の受取人 氏名'),
  relationshipOfRecipient: yup
    .string()
    .required()
    .label('保険等の受取人 続柄'),
  newOrOld: yup
    .mixed<NewOrOldType>()
    .required()
    .label('保険料区分'),
  amount: yup
    .string()
    .required()
    .positiveAmountFormat()
    .maxAmount(999999999)
    .label('保険料金額'),
  image: yup
    .mixed<FileType | File>()
    .nullable()
    .label('証明書画像')
});

const socialInsuranceSchema = yup.object({
  id: yup.string(),
  insuranceType: yup
    .mixed<SocialInsuranceType>()
    .required()
    .label('社会保険の種類'),
  name: yup
    .string()
    .required()
    .label('保険料支払先の名称'),
  nameOfPayer: yup
    .string()
    .required()
    .label('保険料を負担することになっている人 氏名'),
  relationshipOfPayer: yup
    .string()
    .required()
    .label('保険料を負担することになっている人 続柄'),
  amount: yup
    .string()
    .required()
    .positiveAmountFormat()
    .maxAmount(999999999)
    .label('本年中に支払った金額'),
  image: yup
    .mixed<FileType | File>()
    .nullable()
    .label('社会保険の証明書画像')
});

export const schema = yup.object({
  lifeInsurances: yup.array().of(lifeInsuranceSchema),
  earthquakeInsurances: yup.array().of(earthquakeInsuranceSchema),
  socialInsurances: yup.array().of(socialInsuranceSchema)
});

const generateDefaultLifeInsurance = (type: LifeInsuranceType, lifeInsurances?: LifeInsurance[]) => {
  if (!lifeInsurances) return [];

  return lifeInsurances.map(lifeInsurance => ({
    id: String(lifeInsurance.id),
    lifeInsuranceType: type,
    amount: amountFormat(lifeInsurance.amount),
    insuranceDuration: lifeInsurance.insuranceDuration,
    insuranceType: lifeInsurance.insuranceType,
    name: lifeInsurance.name,
    nameOfContractor: lifeInsurance.nameOfContractor,
    nameOfRecipient: lifeInsurance.nameOfRecipient,
    newOrOld: lifeInsurance.newOrOld,
    relationshipOfRecipient: lifeInsurance.relationshipOfRecipient,
    image: lifeInsurance.image,
    paymentStartDate: dateFormFormat(lifeInsurance.paymentStartDate, 'L')
  }));
};

export const generateDefaultValues = (profile?: Profile): Schema => {
  const defaultGeneralLifeInsurances = generateDefaultLifeInsurance('GeneralLifeInsurance', profile?.generalLifeInsurances);
  const nursingMedicalInsurances = generateDefaultLifeInsurance('NursingMedicalInsurance', profile?.nursingMedicalInsurances);
  const individualAnnuityInsurances = generateDefaultLifeInsurance('IndividualAnnuityInsurance', profile?.individualAnnuityInsurances);

  return {
    lifeInsurances: [...defaultGeneralLifeInsurances, ...nursingMedicalInsurances, ...individualAnnuityInsurances],
    earthquakeInsurances:
      profile?.earthquakeInsurances.map(earthquakeInsurance => ({
        id: String(earthquakeInsurance.id),
        amount: amountFormat(earthquakeInsurance.amount),
        insuranceDuration: earthquakeInsurance.insuranceDuration,
        insuranceType: earthquakeInsurance.insuranceType,
        name: earthquakeInsurance.name,
        nameOfContractor: earthquakeInsurance.nameOfContractor,
        nameOfRecipient: earthquakeInsurance.nameOfRecipient,
        newOrOld: earthquakeInsurance.newOrOld,
        relationshipOfRecipient: earthquakeInsurance.relationshipOfRecipient,
        image: earthquakeInsurance.image
      })) || [],
    socialInsurances:
      profile?.socialInsurances.map(socialInsurance => ({
        id: String(socialInsurance.id),
        insuranceType: socialInsurance.insuranceType,
        name: socialInsurance.name,
        nameOfPayer: socialInsurance.nameOfPayer,
        relationshipOfPayer: socialInsurance.relationshipOfPayer,
        amount: amountFormat(socialInsurance.amount),
        image: socialInsurance.image
      })) || []
  };
};

export const lifeInsuranceObj: LifeInsuranceSchema = {
  lifeInsuranceType: 'GeneralLifeInsurance',
  amount: '',
  insuranceDuration: '',
  insuranceType: '',
  name: '',
  nameOfContractor: '',
  nameOfRecipient: '',
  newOrOld: 'new_type',
  relationshipOfRecipient: '',
  image: null
};

export const earthquakeInsuranceObj: EarthquakeInsuranceSchema = {
  amount: '',
  insuranceDuration: '',
  insuranceType: '',
  name: '',
  nameOfContractor: '',
  nameOfRecipient: '',
  newOrOld: 'new_type',
  relationshipOfRecipient: '',
  image: null
};

export const socialInsuranceObj: SocialInsuranceSchema = {
  insuranceType: 'national_pension',
  name: '',
  nameOfPayer: '',
  relationshipOfPayer: '',
  amount: '',
  image: null
};
