import { FC, ReactNode, useState, useEffect, createContext, useContext } from 'react';
import styles from './Anchor.scss';
import classnames from 'classnames';
import { Pulldown } from 'jbc-front/components/icons';

type TargetProps = {
  id?: string;
  children: ReactNode;
};

type AnchorContextType = {
  activeAnchor: string | null;
  setActiveAnchor: ((anchorId: string | null) => void) | null;
};

const AnchorContext = createContext<AnchorContextType>({
  activeAnchor: null,
  setActiveAnchor: null
});

export const Target: FC<TargetProps> = ({ id, children }) => {
  return (
    <div className={styles.target} id={id}>
      {children}
    </div>
  );
};

type AnchorProps = {
  id?: string;
  activeAnchor?: boolean;
  children: ReactNode;
};

export const Anchor: FC<AnchorProps> = ({ id, children }) => {
  const { activeAnchor, setActiveAnchor } = useContext(AnchorContext);

  useEffect(() => {
    const handleScroll = () => {
      const target = document.getElementById(id || '');
      if (target) {
        const rect = target.getBoundingClientRect();

        if (rect.top <= 100 && setActiveAnchor) {
          setActiveAnchor(id || null);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [id, setActiveAnchor]);

  return (
    <a className={classnames(styles.anchor, activeAnchor === id ? styles.activeScroll : '')} href={`#${id}`}>
      {children}
      {activeAnchor === id && (
        <div className={styles.arrow}>
          <Pulldown size={10} color="#ccc" />
        </div>
      )}
    </a>
  );
};

type AnchorProviderProps = {
  children: ReactNode;
  initialAnchor: string | null;
};

export const AnchorProvider: FC<AnchorProviderProps> = ({ children, initialAnchor = null }) => {
  const [activeAnchor, setActiveAnchor] = useState<string | null>(initialAnchor);

  useEffect(() => {
    const activateFirstAnchor = () => {
      if (initialAnchor === null) {
        const firstAnchorId = 'basic-info';
        setActiveAnchor(firstAnchorId);
      }
    };

    activateFirstAnchor();
  }, [initialAnchor]);

  return <AnchorContext.Provider value={{ activeAnchor, setActiveAnchor }}>{children}</AnchorContext.Provider>;
};
