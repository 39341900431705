import { FC, ReactNode } from 'react';
import classnames from 'classnames';

import { Footer } from './Footer';

// @ts-ignore
import Notifications from 'reapop';
// @ts-ignore
import theme from 'reapop-theme-wybo';
// @ts-ignore
import Sidebar from './Sidebar';
// @ts-ignore
import { Header } from './Header';

import styles from './Layout.scss';

type AdminLayoutProps = {
  children: ReactNode;
};

export const AdminLayout: FC<AdminLayoutProps> = ({ children }) => {
  return (
    <div>
      <Notifications theme={theme} />
      <Header showNavi />
      <div className={styles.main}>
        <Sidebar offset={56} />
        <div className={classnames(styles.marginSidebar)}>
          <div className="l-main-contents">{children}</div>
          <Footer />
        </div>
      </div>
    </div>
  );
};
