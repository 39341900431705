import { FC, useEffect, useMemo, useState } from 'react';
import { Download } from 'jbc-front/components/icons';

import { useAdminQuery as useQuery } from '@jbc-year-end-adj/2024/hooks/graphql/useQuery';
import { SearchParams } from '@jbc-year-end-adj/2024/features/SearchForm/types';
import { EMPLOYEE_CSV_EXPORT_REQUEST_RUNNING } from './query';

// @ts-ignore
import { DownloadModal } from '../DownloadModal';
import Button from 'jbc-front/components/Button';
import { CsvDownloadModal } from './DownloadModal';

type DownloadEmployeesCsvProps = {
  search: SearchParams;
  hasConditions: boolean;
};

export const DownloadEmployeesCsv: FC<DownloadEmployeesCsvProps> = ({ search, hasConditions }) => {
  const { data, startPolling, stopPolling } = useQuery(EMPLOYEE_CSV_EXPORT_REQUEST_RUNNING, {
    fetchPolicy: 'network-only'
  });
  const exportRequest = data?.client?.clientYearly || {};
  const polling = useMemo(() => exportRequest?.employeeCsvExportRequestRunning, [exportRequest]);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (polling) {
      startPolling(3000);
      return () => {
        stopPolling();
      };
    }
    return undefined;
  }, [polling]);

  return (
    <>
      <Button
        icon={<Download size={16} />}
        onClick={() => setIsOpen(true)}
        disabled={polling}
        disabledReason="作成に時間がかかるため、完了しましたらメールにてお送りいたします。"
      >
        従業員データダウンロード
      </Button>
      <CsvDownloadModal isOpen={isOpen} onClose={() => setIsOpen(false)} search={search} hasConditions={hasConditions} />
    </>
  );
};
