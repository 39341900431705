import React, { useState } from 'react';
import { gql, useApolloClient } from '@apollo/client';
import Button from 'jbc-front/components/Button';
import fp from 'lodash/fp';
import { useNotify } from '../actions';
import { useAsyncTask, useMutation, useYear, convertSelectedIds, Modal, ASYNC_TASK_FRAGMENT } from '../components';
import styles from './BulkDelete.scss';

const MAX_DELETE_COUNT = 100;

export const EMPLOYEES_FRAGMENT = gql`
  fragment Employees on EmployeePager {
    totalCount
    list {
      id
      staffCode
      email
      employmentType
      position
      profile {
        id
        fullName
      }
      status
      memos {
        id
        color
      }
    }
  }
`;

const DELETE_TARGET_EMPLOYEE_FRAGMENT = gql`
  fragment DeleteTargetEmployee on Employee {
    id
    staffCode
    email
    employmentType
    profile {
      id
      fullName
    }
  }
`;

const DELETE_TARGET_EMPLOYEES = gql`
  query deleteTargetEmployees($year: Int!, $allSelectMode: Boolean!, $employeeIds: [ID!]!) {
    client {
      id
      clientYearly(year: $year) {
        id
        fixed
        deleteTargetEmployees(allSelectMode: $allSelectMode, employeeIds: $employeeIds) {
          ...DeleteTargetEmployee
        }
      }
    }
  }
  ${DELETE_TARGET_EMPLOYEE_FRAGMENT}
`;

const BULK_DELETE_EMPLOYEES = gql`
  mutation bulkDeleteEmployees($input: BulkDeleteEmployeesInput!, $per: Int, $page: Int, $search: EmployeeSearchInput) {
    bulkDeleteEmployees(input: $input) {
      clientYearly {
        id
        employees(per: $per, page: $page, search: $search) {
          ...Employees
        }
      }
    }
  }
  ${EMPLOYEES_FRAGMENT}
`;

const BULK_DELETE_EMPLOYEES_ASYNC = gql`
  mutation bulkDeleteEmployeesAsync($input: BulkDeleteEmployeesAsyncInput!) {
    bulkDeleteEmployeesAsync(input: $input) {
      clientYearly {
        id
        asyncTask {
          ...AsyncTaskFields
        }
      }
    }
  }
  ${ASYNC_TASK_FRAGMENT}
`;

const convertBulkDeleteMutation = (selectedTotalCount, mutation, asyncMutation) => {
  const isExcessive = selectedTotalCount > MAX_DELETE_COUNT;

  return {
    bulkDeleteMutation: isExcessive ? asyncMutation : mutation,
    message: isExcessive ? '従業員一括削除を開始しました' : '従業員を削除しました'
  };
};

export const BulkDelete = ({ variables, selected, allSelectMode, isDisabledSubmit, selectedTotalCount, selectedInfo, onSuccess }) => {
  const [bulkDeleteEmployees, { loading }] = useMutation(BULK_DELETE_EMPLOYEES, { variables });
  const [bulkDeleteEmployeesAsync, { loading: loadingAsync }] = useMutation(BULK_DELETE_EMPLOYEES_ASYNC, { variables });
  const client = useApolloClient();
  const year = useYear();
  const notify = useNotify();
  const { taskRunningProps } = useAsyncTask();
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const remainingCount = selectedTotalCount - selectedEmployees.length;

  const fetchDeleteEmployees = async () => {
    const { data } = await client.query({
      query: DELETE_TARGET_EMPLOYEES,
      variables: { year, allSelectMode, employeeIds: fp.keys(selected) },
      fetchPolicy: 'network-only'
    });

    const targets = fp.get('client.clientYearly.deleteTargetEmployees', data) |> fp.map(item => selectedInfo(item));
    setSelectedEmployees(targets.map((item, index) => [index, item]));
  };

  return (
    <Modal>
      {({ hideModal, showModal, isOpen }) => (
        <>
          <Button
            primary
            disabled={isDisabledSubmit() || loading || loadingAsync}
            {...taskRunningProps}
            onClick={async () => {
              if (selectedTotalCount) {
                await fetchDeleteEmployees();
                showModal();
              } else {
                notify('従業員を選択してください', 'error');
              }
            }}
          >
            削除
          </Button>
          {isOpen && (
            <Modal.Modal>
              <Modal.Header>従業員削除</Modal.Header>
              <Modal.Body>
                <p>【注意】</p>
                <p className="u-mt15">
                  従業員を削除すると、当該従業員に関する年末調整によって登録された情報が消去され、
                  <span className={styles.warning}>復旧できなくなります</span>。
                </p>
                <p>ジョブカン労務HR、給与計算に登録されている情報が消去されることはありません。</p>
                <p className={`${styles.importantWarning} u-mt15`}>本当に削除しますか？</p>
                <p className="u-mt15">削除される従業員</p>
                <div className="u-mt5">
                  <ul>
                    {selectedEmployees.map(([id, { name }]) => (
                      <li key={id}>・{name}</li>
                    ))}
                  </ul>
                  {remainingCount > 0 && <p>他{remainingCount}人</p>}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Modal.Buttons>
                  <Button
                    primary
                    disabled={isDisabledSubmit() || loading || loadingAsync}
                    {...taskRunningProps}
                    onClick={async () => {
                      const { bulkDeleteMutation, message } = convertBulkDeleteMutation(
                        selectedTotalCount,
                        bulkDeleteEmployees,
                        bulkDeleteEmployeesAsync
                      );
                      const { employeeIds, exceptEmployeeIds } = convertSelectedIds(
                        allSelectMode,
                        Object.entries(selected)
                          .filter(([, v]) => v)
                          .map(([id]) => id)
                      );

                      await bulkDeleteMutation({
                        variables: {
                          input: {
                            year: +year,
                            allSelectMode,
                            employeeIds,
                            exceptEmployeeIds
                          }
                        }
                      });
                      hideModal();
                      notify(message);
                      onSuccess();
                    }}
                  >
                    削除する
                  </Button>
                  <Button onClick={hideModal} disabled={loading}>
                    キャンセル
                  </Button>
                </Modal.Buttons>
              </Modal.Footer>
            </Modal.Modal>
          )}
        </>
      )}
    </Modal>
  );
};

export default BulkDelete;
