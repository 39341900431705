import React from 'react';
import Modal from 'react-modal';
import _ from 'lodash';
import { Close } from 'jbc-front/components/icons';
import Button from 'jbc-front/components/Button';
import { replaceApplicationName } from './CustomMailEdit';
import styles from './CustomMailPreview.scss';

const parseHtml = html => {
  if (!html) {
    return { __html: '' };
  }
  let escaped = _.escape(html);
  for (const tag of ['b', 'u']) {
    escaped = escaped.replace(new RegExp(`&lt;${tag}&gt;`, 'g'), `<${tag}>`);
    escaped = escaped.replace(new RegExp(`&lt;/${tag}&gt;`, 'g'), `</${tag}>`);
  }
  escaped = escaped.replace(/&lt;a href=(&quot;|&#39;)(https?:\/\/.+?)(&quot;|&#39;)&gt;(.+?)&lt;\/a&gt;/g, '<a href="$2">$4</a>');
  escaped = escaped.replace(/(^|[^"'>])(https?:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+)/g, '$1<a href="$2">$2</a>');
  escaped = escaped.replace(/\r\n|\r|\n/g, '<br />');
  return { __html: escaped };
};

const Preview = ({ title, isOpen, onHide, application, defaultMail, customMailSubject, customMailBody }) => {
  return (
    <Modal className={styles.modal} isOpen={isOpen} overlayClassName={styles.overlay} contentLabel="Modal">
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
        <div className={styles.tools}>
          <Close className={styles.close} onClick={() => onHide(false)} />
        </div>
      </div>
      {isOpen && (
        <div className={styles.body}>
          <div className={styles.mailTitle}>
            <h3 className={styles.mailHeading}>件名</h3>
            <p className={styles.mailPreview}>{customMailSubject || replaceApplicationName(defaultMail.subject, application)}</p>
          </div>
          <div>
            <h3 className={styles.mailHeading}>本文</h3>
            <div className={styles.mailPreview}>
              <p dangerouslySetInnerHTML={{ __html: defaultMail.header }} />
              <br />
              <p dangerouslySetInnerHTML={parseHtml(customMailBody)} />
              <br />
              <p dangerouslySetInnerHTML={{ __html: replaceApplicationName(defaultMail.footer, application) }} />
            </div>
          </div>
        </div>
      )}
      <div className={styles.closeBottom}>
        <Button onClick={() => onHide(false)}>閉じる</Button>
      </div>
    </Modal>
  );
};

export default Preview;
