import React from 'react';
import { gql } from '@apollo/client';
import { Panel, Headding, Item } from '../components/PrimaryPanel';
import { useQuery, LoadingPage, useYear } from '../components';
import { recordDisplay } from '../utils';
import styles from './Office.scss';

const OFFICE = gql`
  query Office($year: Int!) {
    client {
      id
      clientYearly(year: $year) {
        id
        office {
          id
          name
          nameKana
          addressKana
          corporateNumber
          officeCode
          phoneNumber0
          phoneNumber1
          phoneNumber2
          fullAddress
          repName
          repNameKana
          taxOffice
        }
      }
    }
  }
`;

export const Dl = ({ children }) => <dl className={styles.itemWrap}>{children}</dl>;
export const Dt = ({ children }) => <dt className={styles.itemName}>{children}</dt>;
export const Dd = ({ children }) => <dd className={styles.itemValue}>{children}</dd>;

const Office = () => {
  const year = useYear();
  const { loading, data } = useQuery(OFFICE, { variables: { year }, fetchPolicy: 'network-only' });
  if (loading) return <LoadingPage />;
  const {
    client: {
      clientYearly: { office }
    }
  } = data;
  return (
    <div className="l-wrap-s">
      <div className="l-title-wrap">
        <h1 className="m-title-main">事業所情報</h1>
      </div>
      <Panel>
        <Headding>事業所情報</Headding>
        <Item>
          <Dl>
            <Dt>事業所名：</Dt>
            <Dd>{recordDisplay(office?.name && office.name + (office.nameKana ? ` ${office.nameKana}` : ''))}</Dd>
            <Dt>住所：</Dt>
            <Dd>{recordDisplay(office?.fullAddress)}</Dd>
            <Dt>事業主（代表者）氏名：</Dt>
            <Dd>{recordDisplay(office?.repName)}</Dd>
            <Dt>電話番号：</Dt>
            <Dd>{recordDisplay.numbers(office, 'phoneNumber', 3)}</Dd>
            <Dt>法人番号：</Dt>
            <Dd>{recordDisplay(office?.corporateNumber)}</Dd>
            <Dt>管轄の税務署：</Dt>
            <Dd>{recordDisplay(office?.taxOffice)}</Dd>
          </Dl>
        </Item>
      </Panel>
    </div>
  );
};

export default Office;
