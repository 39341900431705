import React from 'react'
import Icon from 'react-icon-base'

const Drugging = props => (
  <Icon viewBox="0 0 12 10" {...props}>
    <g fill="currentColor">
      <rect height="1.419" width="11.606" rx=".709"/>
      <rect height="1.419" width="11.606" rx=".709" y="4.257"/>
      <rect height="1.419" width="11.606" rx=".709" y="8.513"/>
    </g>
  </Icon>
)
Drugging.defaultProps = {
  size: 12
}


export default Drugging
