import { parse } from 'query-string';

export const makeBackUrl = ({ pathname, search }) => encodeURIComponent(pathname + search);

export const parseBackUrl = (location, fallback) => {
  const { back_to: backTo } = parse(location.search);
  if (backTo && backTo.match(/^\/.*/)) {
    return backTo;
  } else {
    return fallback;
  }
};

export const toBlob = (base64, mimeCtype) => {
  const bin = atob(base64);
  const buffer = new Uint8Array(bin.length).map((_, i) => bin.charCodeAt(i));
  return new Blob([buffer.buffer], { type: mimeCtype });
};
