import React from 'react'
import Icon from 'react-icon-base'

const Calculator = props => (
  <Icon viewBox="0 0 20 20" {...props}>
    <defs>
      <path id='a' d='M.5 0h16.729v20H.5z' />
      <path id='c' d='M.5 20h16.729V0H.5z' />
    </defs>
    <g fill='none' fillRule='evenodd' transform='translate(1)'>
      <mask id='b' fill='#fff'>
        <use xlinkHref='#a' />
      </mask>
      <path fill='currentColor' d='M15.229 0H2.498C1.4 0 .5.9.5 2v16c0 1.1.9 2 2 2h12.73c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2m0 1.7c.16 0 .3.14.3.3v16c0 .16-.14.3-.3.3H2.498A.307.307 0 0 1 2.2 18V2c0-.16.14-.3.3-.3h12.729' mask='url(#b)' />
      <mask id='d' fill='#fff'>
        <use xlinkHref='#c' />
      </mask>
      <path fill='currentColor' d='M3.542 6.202h10.362V3.69H3.542zM7.669 10.583h2.43V8.152h-2.43zM7.669 13.871h2.43v-2.43h-2.43zM7.669 17.01h2.43v-2.431h-2.43zM3.635 10.583h2.43V8.152h-2.43zM3.635 13.871h2.43v-2.43h-2.43zM3.635 17.01h2.43v-2.431h-2.43zM11.702 10.583h2.43V8.152h-2.43zM11.702 13.871h2.43v-2.43h-2.43zM11.702 17.01h2.43v-2.431h-2.43z' mask='url(#d)' />
    </g>
  </Icon>
)
Calculator.defaultProps = {
  size: 20
}

export default Calculator
