import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import moment from 'moment';
import Modal from 'react-modal';
import ReactGA from 'react-ga';

const TRACKING_ID = 'UA-2266197-80';

moment.locale('ja');
window.moment = moment;
Modal.setAppElement('#root');
ReactGA.initialize(TRACKING_ID);

ReactDOM.render(<App />, document.getElementById('root'));
