import { gql } from '@apollo/client';

export type QueryVariables = {
  input: {
    employeeId: string;
  };
};

export const REFLECT = gql`
  mutation Reflect($input: ReflectInput!) {
    reflect(input: $input) {
      employee {
        id
      }
    }
  }
`;
