import React from 'react';

export const DisabledMessage = () => {
  return (
    <p className="u-ta-l">
      年末調整情報が確定されています。
      <br />
      確定を解除後行ってください。
    </p>
  );
};

export default DisabledMessage;
