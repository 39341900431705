import React, { useState } from 'react';
import styles from './MemoAlertList.scss';
import { Link } from 'react-router-dom';
import { Pulldown } from 'jbc-front/components/icons';
import { gql } from '@apollo/client';
import { useQuery } from './Graphql';
import moment from 'moment';
import _ from 'lodash';

const makeLink = (year, id) => `/${year}/employee/${id}`;

export const MEMOS_IN_ALERT_FRAGMENT = gql`
  fragment MemosInAlert on Memo {
    id
    body
    color
    alertAt
    updatedAt
    lastUpdatedUser {
      id
      name
    }
    employee {
      id
      staffCode
      profile {
        id
        fullName
      }
    }
  }
`;

const MEMOS_IN_ALERT = gql`
  query memosInAlert($year: Int!) {
    client {
      id
      clientYearly(year: $year) {
        id
        memosInAlert {
          ...MemosInAlert
        }
      }
    }
  }
  ${MEMOS_IN_ALERT_FRAGMENT}
`;

const MemoAlert = ({ year, memo }) => {
  return (
    <div key={memo.id} className={styles.row}>
      <Link to={makeLink(year, memo.employee.id)}>
        <div className={styles.left}>
          <div className={styles.employeeInfo}>
            <div className={styles[`memo_${memo.color}`]}>●</div>
            <div>{memo.employee.staffCode}</div>
            <div>{memo.employee.profile.fullName}</div>
          </div>
          <div className={styles.date}>(アラート予定日: {memo.alertAt ? moment(memo.alertAt).format('YYYY/MM/DD') : '未設定'})</div>
        </div>
        <div className={styles.right}>
          <div style={{ marginLeft: 'auto' }}>{memo.lastUpdatedUser?.name}</div>
          <div className={styles.date}>(最終更新日時: {moment(memo.updatedAt).format('YYYY/MM/DD HH:mm')})</div>
        </div>
        <div className={styles.main}>
          <div className={styles.row_body}>{memo.body}</div>
        </div>
      </Link>
    </div>
  );
};

const MemoAlertList = ({ year }) => {
  const { data } = useQuery(MEMOS_IN_ALERT, {
    variables: { year },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first'
  });
  const [isListOpen, setIsListOpen] = useState(!(window.sessionStorage.getItem('isMemoAlertOpen') === 'false'));
  const memos = _.get(data, 'client.clientYearly.memosInAlert', []);

  return (
    memos.length > 0 && (
      <div className="l-overflow-scroll">
        <div className={styles.base}>
          <div
            className={isListOpen ? styles.title_opened : styles.title_closed}
            onClick={() => {
              window.sessionStorage.setItem('isMemoAlertOpen', !isListOpen);
              setIsListOpen(!isListOpen);
            }}
            role="presentation"
          >
            <span>従業員のメモアラート</span>
            <span className={isListOpen ? styles.pulldown_opened : styles.pulldown_closed}>
              <Pulldown />
            </span>
          </div>
          {isListOpen && (
            <div className={styles.body}>
              {memos.map(memo => (
                <MemoAlert key={memo.id} year={year} memo={memo} />
              ))}
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default MemoAlertList;
