import React from 'react';
import { gql } from '@apollo/client';
import { useMutation, useSession } from '../components';
import DropDownMenu from '../components/DropDownMenu';
import { Pulldown } from 'jbc-front/components/icons';
import styles from '../pages/Top.scss';
import classNames from 'classnames';

export const statuses = {
  not_requested: '未依頼',
  not_need_adj: '対象外',
  in_progress: '依頼中',
  applying: '入力済',
  rejected: '再依頼中',
  fixed: '修正済',
  accepted: '完了'
};

const SET_NOT_NEED_ADJ = gql`
  mutation setNotNeedAdj($input: SetNotNeedAdjInput!) {
    setNotNeedAdj(input: $input) {
      employee {
        id
        status
      }
    }
  }
`;

const SetNotNeedAdj = ({ employee, disabled = false }) => {
  const clientYearly = useSession();
  const showPulldown = !clientYearly.fixed && (employee.status === 'not_requested' || employee.status === 'not_need_adj');
  const Toggle = showPulldown ? 'button' : 'div';
  const [setNotNeedAdj] = useMutation(SET_NOT_NEED_ADJ);
  const set = reason => e => {
    e.stopPropagation();
    setNotNeedAdj({
      variables: {
        input: {
          employeeId: employee.id,
          notNeedAdj: employee.status !== 'not_need_adj',
          reason
        }
      }
    });
  };

  const disabledClass = disabled && showPulldown ? ' u-cur-notallowed' : ' u-cur-pointer';

  return (
    <DropDownMenu
      as="td"
      className={styles.notNeedAdjDropDown}
      toggle={setIsOpen => (
        <Toggle
          className={classNames(styles.statusDiv, disabledClass)}
          onClick={e => {
            if (!disabled && showPulldown && !clientYearly.clientYearly.fixed) {
              setIsOpen(isOpen => !isOpen);
            }
            e.stopPropagation();
          }}
        >
          <span className={`color-${employee.status}`}>{statuses[employee.status]}</span>
          {showPulldown && !clientYearly.clientYearly.fixed && (
            <div className={styles.pulldown}>
              <Pulldown />
            </div>
          )}
        </Toggle>
      )}
    >
      {employee.status === 'not_need_adj' ? (
        <DropDownMenu.MenuItem onClick={set()}>未依頼に戻す</DropDownMenu.MenuItem>
      ) : (
        <>
          <DropDownMenu.MenuItem onClick={set('resign_before_year_end')}>対象外にする(年内退職予定)</DropDownMenu.MenuItem>
          <DropDownMenu.MenuItem onClick={set('has_no_payment')}>対象外にする(今年支払った金額がない)</DropDownMenu.MenuItem>
          <DropDownMenu.MenuItem onClick={set('other')}>対象外にする(その他の理由)</DropDownMenu.MenuItem>
        </>
      )}
    </DropDownMenu>
  );
};

export default SetNotNeedAdj;
