import { gql } from '@apollo/client';
import { FileType } from '@jbc-year-end-adj/types';
import { requireNonNull } from '@jbc-year-end-adj/common/utils/assert';
import { Schema } from './schema';

export type QueryVariables = {
  input: {
    employeeId: string;
    attributes: {
      formerJobs: FormerJob[];
    };
  };
};

type WithholdingSlipStatusType = 'submitted' | 'not_submitted' | 'unavailable' | 'in_order';

type FormerJob = {
  id?: string;
  companyName: string;
  address?: string;
  resignedOn: string;
  lastSalaryOn?: string;
  withholdingSlipStatus: WithholdingSlipStatusType;
  withholdingSlipImage: FileType | null;
  deductionAmount: string | null;
  paymentAmount: string | null;
  taxAmount: string | null;
};

export const convertFormDataToVariables = (employeeId: string, data: Schema): QueryVariables => {
  const formerJobs = data.formerJobs.map(formerJob => {
    return {
      ...formerJob,
      withholdingSlipStatus: requireNonNull(formerJob.withholdingSlipStatus),
      paymentAmount: formerJob.paymentAmount || '0',
      deductionAmount: formerJob.deductionAmount || '0',
      taxAmount: formerJob.taxAmount || '0'
    };
  });

  return {
    input: {
      employeeId,
      attributes: {
        formerJobs
      }
    }
  };
};

export const UPDATE_REQUEST_FORMER_JOBS = gql`
  mutation UpdateRequestFormerJobs($input: UpdateRequestFormerJobsInput!) {
    updateRequestFormerJobs(input: $input) {
      employee {
        id
      }
    }
  }
`;
