import React from 'react'
import Icon from 'react-icon-base'

const Employee = props => (
  <Icon viewBox="0 0 20 20" {...props}>
    <g fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="1.7">
      <path d="M11.894 6.566a3.924 3.924 0 1 0-7.849 0 3.924 3.924 0 0 0 7.849 0zM14.69 17.21a6.72 6.72 0 0 0-13.44 0M18.784 17.21c0-3.524-1.927-6.52-5.077-7.62.986-.885 1.934-2.036 1.934-3.466 0-1.962-.796-3.254-3.144-4.172"/>
    </g>
  </Icon>
)
Employee.defaultProps = {
  size: 20
}

export default Employee
