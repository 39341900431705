import React, { useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import useReactRouter from 'use-react-router';
import classnames from 'classnames';
import _ from 'lodash';
import fp from 'lodash/fp';
import { useAsyncTask, useQuery, useYear, AsyncTaskError, FormNameProvider, LoadingPage, SelectList, SelectedResult } from '../components';
import searchForm from '../employees/SearchForm';
import PaginatorWithResult from '../employees/PaginatorWithResult';
import { getCurrentQueryFromLocation, convertQueryToForm, convertFormToGraphQl, hasConditions } from '../employees/utils';
import ApplyMethod, { methods } from '../difference_apply/ApplyMethod';
import DownloadDifferenceToApplyCsv from '../difference_apply/DownloadDifferenceToApplyCsv';
import FloatingButtonArea from '../difference_apply/FloatingButtonArea';
import { numberWithSplit } from '../utils';
import { makeBackUrl } from '../utils/url';
import styles from './DifferenceApply.scss';

const DIFFERENCE_APPLY_MODE = 'update_difference_apply_methods';
const searchFormName = 'employeeSearchDifferenceApply';
const SearchForm = searchForm(searchFormName);

const DIFFERENCE_APPLY_QUERY = gql`
  query me {
    me {
      id
      email
    }
    client {
      id
      applications {
        id
        name
        connected
      }
    }
  }
`;

const DIFFERENCE_APPLY_EMPLOYEES_FRAGMENT = gql`
  fragment DifferenceApplyEmployees on EmployeePager {
    totalCount
    list {
      id
      staffCode
      differenceApplyMethod
      profile {
        id
        fullName
      }
      withholdingSlip {
        id
        totalAmountPaid
        incomeDeductionAmount
        withholdingTax
        paidIncomeTax
        differenceToApply
      }
    }
  }
`;

const DIFFERENCE_APPLY = gql`
  query differenceApply($per: Int, $page: Int, $search: EmployeeSearchInput, $year: Int!, $mode: Mode!) {
    client {
      id
      clientYearly(year: $year) {
        id
        selectableEmployeesCount(mode: $mode)
        employees(per: $per, page: $page, search: $search) {
          ...DifferenceApplyEmployees
        }
      }
    }
  }
  ${DIFFERENCE_APPLY_EMPLOYEES_FRAGMENT}
`;

export const initialState = { editing: false, changes: {} };

const Tr = ({ id, state, variables, children }) => {
  const year = useYear();
  const {
    history,
    location: { pathname, search }
  } = useReactRouter();
  const handleClick = () => {
    history.push(
      `/${year}/result/difference_apply/${id}?back_to=${makeBackUrl({
        pathname,
        search
      })}&search=${encodeURIComponent(JSON.stringify(variables.search))}`
    );
  };

  return (
    <tr key={id} {...(state.editing ? {} : { onClick: () => handleClick(), className: 'table-hover' })}>
      {children}
    </tr>
  );
};

const Row = ({ listItem, state, variables, onChangeState }) => {
  const {
    td,
    item: {
      id,
      staffCode,
      differenceApplyMethod,
      profile: { fullName },
      withholdingSlip: { totalAmountPaid, incomeDeductionAmount, withholdingTax, paidIncomeTax, differenceToApply }
    }
  } = listItem;
  const methodName = methods[(state.editing && state.changes[id]?.differenceApplyMethod) || differenceApplyMethod] || '未設定';

  return (
    <Tr id={id} state={state} variables={variables}>
      {state.editing ? td : <td />}
      <td>{staffCode}</td>
      <td>{fullName}</td>
      <td>{numberWithSplit(totalAmountPaid)}</td>
      <td>{numberWithSplit(incomeDeductionAmount)}</td>
      <td>{numberWithSplit(withholdingTax)}</td>
      <td>{numberWithSplit(paidIncomeTax)}</td>
      <td>{numberWithSplit(differenceToApply)}</td>
      <td>
        <div className={styles.method}>
          <div className={classnames(styles.pulldown, state.changes[id] && styles.pulldownChanged)}>
            {state.editing ? (
              <ApplyMethod
                methodName={methodName}
                onChange={value => {
                  if (value === differenceApplyMethod) {
                    onChangeState(prevState => {
                      const {
                        changes: { [id]: _, ...restChanges },
                        ...restState
                      } = prevState;
                      return { changes: restChanges, ...restState };
                    });
                  } else {
                    onChangeState(fp.set(`changes[${id}].differenceApplyMethod`, value));
                  }
                }}
              />
            ) : (
              methodName
            )}
          </div>
        </div>
      </td>
    </Tr>
  );
};

const EmployeeList = ({ variables, formValues }) => {
  const [state, setState] = useState(initialState);
  const { taskRunningProps } = useAsyncTask();
  const { data, loading: loadingQuery, refetch: refetchDifferenceApply } = useQuery(DIFFERENCE_APPLY, {
    fetchPolicy: 'network-only',
    variables
  });
  const totalCount = _.get(data, 'client.clientYearly.employees.totalCount', 0);
  const employees = _.get(data, 'client.clientYearly.employees.list', []);
  const selectableCount = _.get(data, 'client.clientYearly.selectableEmployeesCount', 0);

  useEffect(() => {
    (async () => {
      if (_.isUndefined(taskRunningProps)) {
        return;
      }

      if (!taskRunningProps.disabled) {
        await refetchDifferenceApply();
      }
    })();
  }, [taskRunningProps]);

  return (
    <>
      <AsyncTaskError displayTypes={['apply_differences_to_payroll', 'bulk_update_difference_apply_methods']} />
      <div className={styles.csvDownloadBtn}>
        <DownloadDifferenceToApplyCsv search={variables.search} hasConditions={hasConditions(formValues)} />
      </div>
      <SearchForm query={DIFFERENCE_APPLY} variables={variables} />
      <SelectList
        mode={DIFFERENCE_APPLY_MODE}
        variables={variables}
        list={employees}
        totalCount={totalCount}
        selectableCount={selectableCount}
        key={state.editing}
      >
        {({ list, selected, allSelectMode, isDisabledSubmit, selectedResultCount, th }) => {
          const selectedTotalCount = selectedResultCount();

          return loadingQuery ? (
            <LoadingPage />
          ) : (
            <>
              {state.editing && list.length > 0 && <SelectedResult selectedTotalCount={selectedTotalCount} />}
              <div className="l-overflow-scroll">
                <table className="m-table-list m-table-fixed">
                  <thead>
                    <tr>
                      {state.editing ? th : <th width={50} />}
                      <th width={120}>スタッフコード</th>
                      <th width={180}>氏名</th>
                      <th width={110}>支払金額</th>
                      <th width={110}>所得控除額</th>
                      <th width={110}>年調年税額</th>
                      <th width={110}>源泉徴収した額</th>
                      <th width={110}>超過又は不足額</th>
                      <th width={130}>ジョブカン給与計算反映方法</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list.length > 0 ? (
                      list.map(listItem => (
                        <Row key={listItem.item.id} listItem={listItem} state={state} variables={variables} onChangeState={setState} />
                      ))
                    ) : (
                      <tr>
                        <td colSpan={9}>該当の従業員は見つかりませんでした</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className={styles.paginator}>
                <FormNameProvider formName={searchFormName}>
                  <PaginatorWithResult count={totalCount} />
                </FormNameProvider>
              </div>
              <FloatingButtonArea
                state={state}
                onChangeState={setState}
                selected={selected}
                allSelectMode={allSelectMode}
                selectedTotalCount={selectedTotalCount}
                isDisabledSubmit={isDisabledSubmit}
                taskRunningProps={taskRunningProps}
              />
            </>
          );
        }}
      </SelectList>
    </>
  );
};

const DifferenceApply = ({ location: { pathname, search } }) => {
  const { data, loading } = useQuery(DIFFERENCE_APPLY_QUERY);
  const year = useYear();
  if (loading) return <LoadingPage />;
  const { me } = data;
  const formValues = getCurrentQueryFromLocation({ search, pathname }, me) |> convertQueryToForm;
  const variables =
    formValues
    |> convertFormToGraphQl
    |> fp.set('search.status', 'accepted')
    |> fp.set('year', year)
    |> fp.set('mode', DIFFERENCE_APPLY_MODE);

  return (
    <>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">過不足額の精算</h1>
      </div>
      <div className="l-contents-wrap">
        <div className="l-wrap-xxl">
          <p className={styles.lead}>
            年末調整で精算された過不足額をジョブカン給与計算に反映またはCSVダウンロードできます。
            <br />
            反映方法は「12月給与」「12月賞与」「1月給与」「反映しない」から選べます。「反映しない」を選んだ場合該当従業員の過不足額は手動で反映してください。
          </p>
          <EmployeeList variables={variables} formValues={formValues} />
        </div>
      </div>
    </>
  );
};

export default DifferenceApply;
