import React from 'react';
import { gql, useApolloClient } from '@apollo/client';
import { FormSection } from 'redux-form';
import { Section } from 'jbc-front/components/Form';
import { useQuery, useYear, useFormValue, CitySearchField, useAutofill } from '../components';
import { toFormValues } from '../utils/form';
import { LabelMapper } from 'jbc-front/components/FormErrors';
import { useDiff, RadioField } from '../components/FieldWithDiff';
import styles from './ResidentTax.scss';

export const EMPLOYEE_ADDRESS_CLASSIFICATION = gql`
  query employeeAddressClassification($year: Int!) {
    client {
      id
      clientYearly(year: $year) {
        id
        clientSetting {
          id
          employeeAddressClassification
        }
      }
    }
  }
`;
const POSTCODE_CITY = gql`
  query postcode($postcode: Int!, $year: Int!) {
    postcode(postcode: $postcode, year: $year) {
      id
      cityRcd {
        id
        code
        name
      }
    }
  }
`;

const residentsTaxMethods = [
  { value: 'from_salary', label: '特別徴収' },
  { value: 'normally_for_resign', label: '普通徴収（退職）' },
  { value: 'normally_others', label: '普通徴収（その他）' }
];

const taxSchedules = [
  { value: 'first', label: '甲欄' },
  { value: 'second', label: '乙欄' }
];

const ListPanel = ({ title, children }) => {
  const year = useYear();
  const { data, loading } = useQuery(EMPLOYEE_ADDRESS_CLASSIFICATION, { variables: { year } });
  const { fixed } = useDiff();
  const client = useApolloClient();
  const { autofill, withSelector } = useAutofill();
  if (loading) return null;
  const {
    client: {
      clientYearly: {
        clientSetting: { employeeAddressClassification }
      }
    }
  } = data;
  const handleBackToAuto = () => {
    const prefix = employeeAddressClassification === 'profile' ? 'basicInformation' : 'residentCard';
    withSelector(async selector => {
      const postcode0 = selector(`${prefix}.postcode0`);
      const postcode1 = selector(`${prefix}.postcode1`);
      if (postcode0?.match(/\d{3}/) && postcode1?.match(/\d{4}/)) {
        const postcode = `${postcode0}${postcode1}`;
        const { data } = await client.query({ query: POSTCODE_CITY, variables: { postcode: +postcode, year: year } });
        if (data.postcode?.cityRcd) {
          autofill('residentTax.code', data.postcode.cityRcd.code);
          autofill('residentTax.name', data.postcode.cityRcd.name);
        } else {
          autofill('residentTax.code', '');
          autofill('residentTax.name', '');
        }
      }
    });
  };
  return (
    <div className={styles.panelWrap}>
      <div className={styles.panelHeader}>
        <h3 className={styles.panelTitle}>{title}</h3>
        <CitySearchField handleBackToAuto={handleBackToAuto} disabled={fixed} name="code" />
      </div>
      <div className={styles.panelContents}>{children}</div>
    </div>
  );
};

const Dl = ({ children }) => <dl className={styles.dl}>{children}</dl>;
const Dt = ({ children }) => <dt className={styles.dt}>{children}</dt>;
const Dd = ({ children }) => <dd className={styles.dd}>{children}</dd>;

const ResidentTax = () => {
  const code = useFormValue('residentTax.code');
  const name = useFormValue('residentTax.name');
  const isNonResident = useFormValue('basicInformation.isNonResident');
  return (
    <Section title="住民税">
      <FormSection name="residentTax">
        <LabelMapper name="residentTax" label="住民税の" />
        <ListPanel title="給与支払報告書提出先">
          <Dl>
            <Dt>市区町村コード</Dt>
            <Dd>{code}</Dd>
          </Dl>
          <Dl>
            <Dt>市区町村名</Dt>
            <Dd>{name}</Dd>
          </Dl>
        </ListPanel>
        {!isNonResident && <RadioField name="taxSchedule" label="税額表区分" options={taxSchedules} />}
        <RadioField name="residentsTaxCollectionMethod" label="住民税徴収方法" options={residentsTaxMethods} />
      </FormSection>
    </Section>
  );
};

export const makeInitialValues = (employee, profile) => {
  return {
    ...toFormValues(profile.residentTaxCity, true),
    taxSchedule: employee.taxSchedule,
    residentsTaxCollectionMethod: employee.residentsTaxCollectionMethod
  };
};

export const RESIDENT_TAX_FRAGMENT = gql`
  fragment ResidentTaxCity on Profile {
    residentTaxCity {
      id
      code
      name
    }
  }
  fragment ResidentTaxEmployee on Employee {
    taxSchedule
    residentsTaxCollectionMethod
  }
`;

export default ResidentTax;
