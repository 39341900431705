import React from 'react'
import Icon from 'react-icon-base'

const AlertTriangle = props => (
  <Icon viewBox="0 0 37 34" {...props}>
    <g fill='none' fillRule='evenodd'>
      <path fill='currentColor' d='M22.173 2.259L36.516 27.62a4 4 0 0 1-3.482 5.97H4.348a4 4 0 0 1-3.482-5.97L15.209 2.26a4 4 0 0 1 6.964 0z' />
      <path fill='#FFF' d='M17.553 19.842l-.39-5.912c-.072-1.152-.109-1.98-.109-2.481 0-.683.176-1.216.529-1.599.352-.382.816-.574 1.391-.574.697 0 1.163.245 1.398.735.235.49.352 1.195.352 2.117 0 .543-.028 1.094-.085 1.654l-.522 6.085c-.057.724-.179 1.28-.365 1.666-.186.387-.494.58-.924.58-.437 0-.74-.187-.91-.561-.171-.375-.293-.945-.365-1.71zm1.348 8.122c-.494 0-.925-.162-1.294-.487-.368-.326-.553-.78-.553-1.364 0-.51.176-.945.529-1.303a1.746 1.746 0 0 1 1.294-.537c.51 0 .946.18 1.306.537.36.358.541.792.541 1.303 0 .576-.182 1.028-.547 1.357-.364.33-.79.494-1.276.494z' />
    </g>
  </Icon>
)
AlertTriangle.defaultProps = {
  size: 16
}

export default AlertTriangle
