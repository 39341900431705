import React, { useCallback } from 'react';
import useReactRouter from 'use-react-router';
import { gql } from '@apollo/client';
import _ from 'lodash';
import Button from 'jbc-front/components/Button';
import { Required } from 'jbc-front/components/Form';
import { Section, Table, Tr, Th, Td, Total, InputField, Note, FormSection } from './parts';
import { useQuery, useYear } from '../components';
import { useFormValue, useAutofill } from '../components/FormName';
import { toFormValues } from '../utils/form';

export const SECTION_NAME = 'paymentDeduction';

const PROFILE_FORMER_JOBS = gql`
  query withholdingSlip($id: ID!, $year: Int!) {
    client {
      id
      clientYearly(year: $year) {
        id
        employee(id: $id) {
          id
          profile {
            id
            formerJobsTaxAmount
            formerJobsPaymentAmount
            formerJobsDeductionAmount
            formerJobs {
              id
            }
          }
        }
      }
    }
  }
`;

const paymentDeductionCol = [
  { label: '課税支払額', name: 'paymentAmount' },
  { label: '社会保険料控除額', name: 'deductionAmount' },
  { label: '源泉徴収税額 (所得税)', name: 'incomeTax' }
];

const paymentDeductionRows = [
  { label: '給与分', prefix: 'pay', required: true },
  { label: '賞与分', prefix: 'bonus', required: true },
  { label: '前職分', prefix: 'formerJobs', required: true },
  { label: '調整額※', prefix: 'adjustment' },
  { label: '合計', prefix: 'total' }
];

const PaymentDeductionTotal = ({ sectionName }) => {
  const name = sectionName === 'deductionAmount' && sectionName;
  const makeFormName = prefix => {
    const filedName = prefix === 'formerJobs' && sectionName === 'incomeTax' ? 'taxAmount' : sectionName;
    return `${SECTION_NAME}.${prefix}${_.upperFirst(filedName)}`;
  };
  const formValues = [
    useFormValue(makeFormName('pay')),
    useFormValue(makeFormName('bonus')),
    useFormValue(makeFormName('formerJobs')),
    useFormValue(makeFormName('adjustment'))
  ];
  const handleCalc = useCallback(_.sum, []);
  return <Total {...{ name, formValues, handleCalc }} />;
};

const PaymentDeduction = () => {
  const {
    match: {
      params: { id }
    }
  } = useReactRouter();
  const year = useYear();
  const { autofill } = useAutofill();
  const { data, loading } = useQuery(PROFILE_FORMER_JOBS, {
    variables: { id, year }
  });
  if (loading) return null;
  const {
    client: {
      clientYearly: {
        employee: { profile }
      }
    }
  } = data;
  const { formerJobs, ...formerJobsAmount } = profile;
  const handleRecalculate = () => _.forEach(toFormValues(formerJobsAmount, true), (v, k) => autofill(`${SECTION_NAME}.${k}`, v));
  return (
    <>
      <FormSection name={SECTION_NAME} label="年間支給控除額の">
        <Section
          title="年間支給控除額"
          button={
            <Button onClick={handleRecalculate} disabled={_.isEmpty(formerJobs)}>
              前職分を従業員情報から再計算する
            </Button>
          }
        >
          <Table>
            <thead>
              <tr>
                <th />
                <th>課税支払額</th>
                <th>社会保険料控除額</th>
                <th>
                  源泉徴収税額 <br />
                  （所得税）
                </th>
              </tr>
            </thead>
            <colgroup>
              <col style={{ width: '128px' }}></col>
            </colgroup>
            <tbody>
              {paymentDeductionRows.map(({ prefix, label, required }) => {
                const isTotal = prefix === 'total';
                return (
                  <Tr key={prefix} bold={isTotal} readOnly={isTotal}>
                    <Th width={128}>
                      <label>{label}</label>
                      {required && <Required />}
                    </Th>
                    {paymentDeductionCol.map((col, i) => {
                      const colName = prefix === 'formerJobs' && col.name === 'incomeTax' ? 'taxAmount' : col.name;
                      const name = `${prefix}${_.upperFirst(colName)}`;
                      return (
                        <Td key={i}>
                          {isTotal ? (
                            <PaymentDeductionTotal sectionName={colName} />
                          ) : (
                            <InputField label={`${col.label}の${label}`} {...{ name, required }} />
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </tbody>
          </Table>
        </Section>
        <Note>※調整額は給与分に合算されて源泉徴収簿、過不足額の精算画面に表示されます。</Note>
        <Section>
          <Table>
            <tbody>
              <Tr>
                <Th>天引きされた小規模共済掛金</Th>
                <Td>
                  <InputField name="smallMutualAidPremium" label="天引きされた小規模共済掛金" />
                </Td>
              </Tr>
            </tbody>
          </Table>
        </Section>
      </FormSection>
    </>
  );
};

export default PaymentDeduction;
