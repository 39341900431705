import React from 'react'
import Icon from 'react-icon-base'

const Custom14 = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="icon/employees/Custom14" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group" transform="translate(2.830363, 2.000000)" fill={props.color} fillRule="nonzero">
        <path d="M26.1442832,23.4825353 C26.6141659,23.1923136 27.2303523,23.3379577 27.520574,23.8078405 L27.520574,23.8078405 L34.1322398,34.4653065 L34.1877994,34.5680629 C34.4000009,35.0215528 34.2432544,35.5721058 33.8069347,35.8415974 C33.3370519,36.1318191 32.7208655,35.986175 32.4306438,35.5162923 L32.4306438,35.5162923 L31.799,34.4991944 L2.495,34.4991944 L1.85093689,35.5324109 C1.56071519,36.0022937 0.944528774,36.1479377 0.474646015,35.857716 C0.0383263097,35.5882245 -0.118420233,35.0376715 0.0937812672,34.5841816 L0.149340898,34.4814252 L6.81883915,23.8078405 C7.10906085,23.3379577 7.72524726,23.1923136 8.19513002,23.4825353 C8.63144973,23.7520269 8.78819627,24.3025799 8.57599477,24.7560698 L8.52043514,24.8588262 L7.495,26.4991944 L26.836,26.4991944 L25.818978,24.8588262 L25.7634184,24.7560698 C25.5512169,24.3025799 25.7079635,23.7520269 26.1442832,23.4825353 Z M29.938,31.4991944 L4.37,31.4991944 L3.745,32.4991944 L30.558,32.4991944 L29.938,31.4991944 Z M28.077,28.4991944 L6.245,28.4991944 L5.62,29.4991944 L28.697,29.4991944 L28.077,28.4991944 Z" id="Combined-Shape"></path>
        <path d="M24.1696371,0 C24.7219219,0 25.1696371,0.44771525 25.1696371,1 L25.1695465,2 L27.2107058,2 C28.852578,2 30.1894367,3.31984708 30.210461,4.96158468 L30.2469147,8.122619 L30.2767251,11.9472603 L30.282769,14.4705435 L30.2738739,16.5039435 L30.2598585,17.6306736 L30.2332433,18.7816075 L30.2197674,19.1618766 L30.1879643,19.7850929 L30.1696371,20.02804 C30.0186746,21.7641089 29.352008,23.4214289 28.1696371,25 L6.16963714,25 C4.99753058,23.5440861 4.33086391,21.8890752 4.16963714,20.0349674 L4.13835245,19.5880487 L4.10391062,18.8046576 L4.08353257,18.0764897 L4.06363293,16.9180998 L4.05044407,15.1687741 L4.05133305,12.741849 L4.07569927,9.18019591 L4.12565591,4.95864396 C4.14827333,3.31807351 5.48464447,2 7.12537082,2 L9.1695465,2 L9.16963714,1 C9.16963714,0.44771525 9.61735239,0 10.1696371,0 L24.1696371,0 Z M23.1687171,2 L11.1687171,2 L11.1693652,4 L7.12537082,4 C6.57846178,4 6.13300501,4.43935769 6.12546585,4.98621547 L6.0705887,9.74462478 L6.05132702,12.7440251 L6.05043187,15.1617864 L6.06229797,16.7926533 L6.08516481,18.1229617 L6.11858679,19.1476629 L6.14352108,19.6136919 L6.16211832,19.8617081 C6.25178387,20.892862 6.53563688,21.8343236 7.02074741,22.7029254 L7.19671707,23 L27.1097171,23 L27.1292358,22.9697532 C27.6851792,22.0429404 28.0200578,21.10443 28.1457215,20.1436826 L28.177156,19.8547808 L28.1938455,19.6312529 L28.2231193,19.0419398 L28.2467753,18.2648851 L28.2607267,17.5602857 L28.2802025,15.5161186 L28.2827691,14.4709333 L28.2767426,11.9557474 L28.2407355,7.53914894 L28.210625,4.98719488 C28.2041174,4.47903777 27.8194196,4.06339691 27.3263036,4.00661261 L27.2107058,4 L23.1693652,4 L23.1687171,2 Z" id="Combined-Shape"></path>
        <path d="M26.1696371,5 C26.7219219,5 27.1696371,5.44771525 27.1696371,6 L27.1696371,13 C27.1696371,13.5522847 26.7219219,14 26.1696371,14 L8.16963714,14 C7.61735239,14 7.16963714,13.5522847 7.16963714,13 L7.16963714,6 C7.16963714,5.44771525 7.61735239,5 8.16963714,5 L26.1696371,5 Z M25.1687171,7 L9.16871707,7 L9.16871707,12 L25.1687171,12 L25.1687171,7 Z" id="Rectangle"></path>
        <path d="M9.66963714,15 C11.050349,15 12.1696371,16.1192881 12.1696371,17.5 C12.1696371,18.8807119 11.050349,20 9.66963714,20 C8.28892527,20 7.16963714,18.8807119 7.16963714,17.5 C7.16963714,16.1192881 8.28892527,15 9.66963714,15 Z M9.66963714,16 C8.84121002,16 8.16963714,16.6715729 8.16963714,17.5 C8.16963714,18.3284271 8.84121002,19 9.66963714,19 C10.4980643,19 11.1696371,18.3284271 11.1696371,17.5 C11.1696371,16.6715729 10.4980643,16 9.66963714,16 Z" id="Oval"></path>
        <path d="M24.6696371,15 C26.050349,15 27.1696371,16.1192881 27.1696371,17.5 C27.1696371,18.8807119 26.050349,20 24.6696371,20 C23.2889253,20 22.1696371,18.8807119 22.1696371,17.5 C22.1696371,16.1192881 23.2889253,15 24.6696371,15 Z M24.6696371,16 C23.84121,16 23.1696371,16.6715729 23.1696371,17.5 C23.1696371,18.3284271 23.84121,19 24.6696371,19 C25.4980643,19 26.1696371,18.3284271 26.1696371,17.5 C26.1696371,16.6715729 25.4980643,16 24.6696371,16 Z" id="Oval-Copy"></path>
      </g>
    </g>
  </Icon>
)
Custom14.defaultProps = {
  size: 30,
  color: "#3498DB"
}

export default Custom14