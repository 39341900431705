import React from 'react'
import Icon from 'react-icon-base'

const Maintenance = props => (
  <Icon {...props}>
    <defs>
      <path id="a" d="M27 27.67H0V0h27z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FFF"
        d="M65.323 56.936H2.655a1.59 1.59 0 0 1-1.59-1.588V2.652a1.59 1.59 0 0 1 1.59-1.588h62.668c.878 0 1.59.711 1.59 1.588v52.696a1.59 1.59 0 0 1-1.59 1.588"
      />
      <path
        stroke="#000"
        strokeWidth="2"
        d="M65.323 56.936H2.655a1.59 1.59 0 0 1-1.59-1.588V2.652a1.59 1.59 0 0 1 1.59-1.588h62.668c.878 0 1.59.711 1.59 1.588v52.696a1.59 1.59 0 0 1-1.59 1.588z"
      />
      <path
        fill="#000"
        d="M66.914 11.656H1.064V2.652a1.59 1.59 0 0 1 1.591-1.588h62.668c.879 0 1.59.711 1.59 1.588v9.004z"
      />
      <path
        fill="#FFF"
        d="M6.298 4.397a1.986 1.986 0 1 1 0 3.969A1.986 1.986 0 0 1 4.31 6.38c0-1.095.89-1.984 1.988-1.984m6.889.001c1.098 0 1.988.889 1.988 1.984 0 1.096-.89 1.985-1.988 1.985A1.986 1.986 0 0 1 11.2 6.38c0-1.095.89-1.984 1.987-1.984m6.757.001c1.098 0 1.988.889 1.988 1.984 0 1.096-.89 1.985-1.988 1.985a1.986 1.986 0 0 1-1.987-1.985c0-1.095.89-1.984 1.987-1.984"
      />
      <path
        fill="#000"
        d="M23.548 23.96a.479.479 0 0 1 .223.13l6.875 7.046 1.244-1.275-6.875-7.045a.498.498 0 0 1-.126-.23l-.433-1.767-2.868-1.68-1.405 1.439 1.64 2.941 1.725.442z"
      />
      <g transform="translate(20 19.016)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <path
          fill="#000"
          d="M7.901 18.876L18.483 8.032l.68.697L8.58 19.573l-.68-.697zm-1.856 5.77H3.704l-1.155-1.97 1.155-1.973h2.341l1.154 1.972-1.154 1.972zm3.616-3.63l10.91-11.18a.473.473 0 0 1 .456-.13 4.694 4.694 0 0 0 4.13-.885 4.948 4.948 0 0 0 1.834-4.17L24.454 7.25a.473.473 0 0 1-.493.12l-2.885-.987a.486.486 0 0 1-.305-.311l-.962-2.958a.501.501 0 0 1 .117-.504l2.537-2.6a4.735 4.735 0 0 0-3.985 1.769 4.998 4.998 0 0 0-.949 4.343.5.5 0 0 1-.126.468L6.493 17.77a.477.477 0 0 1-.456.13c-.38-.1-.77-.153-1.163-.154C2.803 17.732.952 19.07.27 21.075c-.682 2.005-.043 4.232 1.588 5.54a4.747 4.747 0 0 0 5.63.25c1.735-1.16 2.558-3.324 2.046-5.381a.502.502 0 0 1 .127-.469z"
          mask="url(#b)"
        />
      </g>
      <path
        fill="#000"
        d="M43.056 46.43a2.866 2.866 0 0 0 3.088-.685c.81-.83 1.07-2.068.668-3.165l-3.756 3.85zm.236-2.912l-4.81-4.929.68-.697 4.81 4.93-.68.696zm2.852-2.006l-5.402-5.539a1.474 1.474 0 0 1-2.027-.09 1.566 1.566 0 0 1-.09-2.076l-.764-.784-4.13 4.232.77.789a1.474 1.474 0 0 1 2.026.089c.55.563.59 1.465.09 2.076l5.397 5.535c.06.06.121.116.184.17l4.113-4.214a2.912 2.912 0 0 0-.167-.188z"
      />
    </g>
  </Icon>
)
Maintenance.defaultProps = {
  width: 68,
  height: 58
}

export default Maintenance
