import React from 'react'
import Icon from 'react-icon-base'

const Edit = props => (
  <Icon viewBox="0 0 20 20" {...props}>
    <g fill="none">
      <path className="stroke-color" d="M8.697 17.463H2.792v-5.906" stroke="currentColor" strokeLinecap="round" strokeWidth="1.4"/>
      <path className="fill-color" d="M6.786 17.463H2.792v-3.994z" fill="currentColor"/>
      <path className="stroke-color" d="M8.697 17.463l8.766-8.766-5.905-5.905-8.766 8.765M13.526 4.76l-7.44 7.44M15.494 6.729l-7.439 7.44" stroke="currentColor" strokeLinecap="round" strokeWidth="1.5"/>
    </g>
  </Icon>
)
Edit.defaultProps = {
  size: 16
}


export default Edit
