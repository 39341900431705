import React from 'react'
import Icon from 'react-icon-base'

const Any = props => (
  <Icon viewBox="0 0 20 20" {...props}>
    <g fill="none">
      <rect height="20" width="20" fill="#aaa" rx="3"/>
      <text fill="#fff" fontFamily="YuGo-Bold, YuGothic" fontSize="12">
        <tspan x="4" y="14">任</tspan>
      </text>
    </g>
  </Icon>
)
Any.defaultProps = {
  size: 20
}

export default Any
