import { FC, ReactNode } from 'react';
import { Section } from '@jbc-year-end-adj/2024/components/ui/Section';

import { Question } from '../types';
import { findDescriptions } from '../utils';

import { ToggleImageContainer } from './parts/ToggleImageContainer';
import { Lead } from './parts/Lead';
import { Description } from './parts/Description';
import { Content as ContentContainer } from './parts/Content';

// @ts-ignore
import image from '@jbc-year-end-adj/2024/images/img-Foundation_Exclusion_Form.png';

type SalaryIncomeProps = {
  question: Question;
  children: ReactNode;
  initiallyOpen?: boolean;
};

export const SalaryIncome: FC<SalaryIncomeProps> = ({ question, children, initiallyOpen = false }) => {
  return (
    <Section>
      <Section.Header>{TITLE}</Section.Header>

      <Section.Body>
        <Content question={question} initiallyOpen={initiallyOpen}>
          {children}
        </Content>
      </Section.Body>
    </Section>
  );
};

export const TITLE = '給与収入等（本人の給与収入）';

type ContentProps = {
  question: Question;
  children: ReactNode;
  initiallyOpen?: boolean;
};

export const Content: FC<ContentProps> = ({ question, children, initiallyOpen = false }) => {
  const questionDescriptions = findDescriptions(question, ['top']);

  return (
    <ContentContainer>
      <Lead>本人の給与収入欄を入力してください</Lead>

      <Description>{questionDescriptions['top']?.description}</Description>

      <ToggleImageContainer initiallyOpen={initiallyOpen}>
        <ToggleImageContainer.Header>「基礎控除申告書（赤枠）」に反映されます。</ToggleImageContainer.Header>
        <ToggleImageContainer.Body>
          <img src={image} alt="基配所_基礎控除申告書" />
        </ToggleImageContainer.Body>
      </ToggleImageContainer>
      <Description caution>
        「当社」欄は今年1年間の見込み収入金額（賞与含む、前職除く）を入力してください。
        <br />
        「他社」欄は2か所以上からの給与収入がある場合にその金額を入力してください。
      </Description>

      {children}
    </ContentContainer>
  );
};
