import { Loading } from 'jbc-front/components/presenters/ui/Loading';
import { useQuery, gql } from '@apollo/client';
import { Routes as Routes2024 } from 'years/2024/AdminRoutes';
import { RightExpansionContainerProvider } from 'components/feature/RightExpansionContainer';
import { Show as EmployeeShow } from 'adminPages/employees/show';

// @ts-ignore
import { Route, Switch, Redirect } from 'react-router';
// @ts-ignore
import { AsyncTask, withTracker, SessionProvider, fixedGuard, fixRunningGuard, FixRunningGuard } from 'components';
// @ts-ignore
import Layout from 'Layout';
// @ts-ignore
import * as _pages from 'pages';

const pages = withTracker(_pages);

export const Routes = () => {
  const year = window.location.pathname.split('/')[1];

  if (!year) {
    // default yearをfetchしてhistoryにpushする
    return <NoYearRoute />;
  } else {
    switch (Number(year)) {
      case 2024:
        // 全てのページを置き換えたらSessionProvider, AsyncTask, RightExpansionContainerProviderは削除
        return (
          <SessionProvider>
            <AsyncTask>
              <RightExpansionContainerProvider>
                <Routes2024 />
              </RightExpansionContainerProvider>
            </AsyncTask>
          </SessionProvider>
        );
      default:
        return (
          <SessionProvider>
            <AsyncTask>
              <RightExpansionContainerProvider>
                <Layout sidebar showHeader showFooter>
                  <Switch>
                    <Route path="/:year(\d+)?" exact component={fixRunningGuard(pages.Top)} />
                    <Route path="/:year(\d+)?/settings" exact component={fixRunningGuard(pages.Settings)} />
                    <Route
                      path="/:year(\d+)?/settings/question_descriptions"
                      exact
                      component={fixRunningGuard(pages.QuestionDescriptions)}
                    />
                    <Route
                      path="/:year(\d+)?/resident_tax_payment_address"
                      exact
                      component={fixRunningGuard(pages.ResidentTaxPaymentAddress)}
                    />
                    <Route path="/:year(\d+)?/settings/custom_mails" exact component={fixRunningGuard(pages.CustomMails)} />
                    <Route path="/:year(\d+)?/settings/custom_mails/:email_type" exact component={fixRunningGuard(pages.CustomMailEdit)} />
                    <Route path="/:year(\d+)?/client_setting" exact component={fixRunningGuard(pages.ClientSetting)} />
                    <Route path="/:year(\d+)?/office" exact component={fixRunningGuard(pages.Office)} />
                    <Route path="/:year(\d+)?/employee/import_update" exact component={fixRunningGuard(pages.ImportUpdate)} />
                    <Route path="/:year(\d+)?/employee/:id" exact>
                      {({
                        match: {
                          params: { year, id }
                        }
                      }: {
                        match: { params: { year: number; id: number } };
                      }) => {
                        return year > 2022 ? (
                          <FixRunningGuard>
                            <EmployeeShow />
                          </FixRunningGuard>
                        ) : (
                          <Redirect to={`/${year}/employee/${id}/edit`} />
                        );
                      }}
                    </Route>
                    <Route path="/:year(\d+)?/employee/:id/edit" exact component={fixRunningGuard(pages.EmployeeEdit)} />
                    <Route path="/:year(\d+)?/employee/:id/withholding_slip" exact component={fixRunningGuard(pages.WithholdingSlip)} />
                    <Route path="/:year(\d+)?/csv_export_request" exact component={pages.DownloadFile} />
                    <Route path="/:year(\d+)?/result" exact component={pages.Result} />
                    <Route
                      path="/:year(\d+)/result/import_withholding_slip"
                      exact
                      component={fixRunningGuard(pages.ImportWithholdingSlips)}
                    />
                    <Route path="/:year(\d+)/result/difference_apply" exact component={fixedGuard(pages.DifferenceApply)} />
                    <Route path="/:year(\d+)/result/difference_apply/:id" exact component={fixedGuard(pages.DifferenceApplyDetail)} />
                    <Route path="/:year(\d+)/result/city_report" exact component={fixedGuard(pages.CityReport)} />
                    <Route path="/:year(\d+)/result/city_report_setting" exact component={fixedGuard(pages.CityReportSetting)} />
                    <Route path="/:year(\d+)/result/tax_office" exact component={fixedGuard(pages.TaxOffice)} />
                    <Route path="/:year(\d+)/result/tax_office/legal_records" exact component={fixedGuard(pages.LegalRecords)} />
                    <Route path="/:year(\d+)/result/withholding_slip_deliver" exact component={fixedGuard(pages.WithholdingSlipDeliver)} />
                    <Route component={pages.NotFound} />
                  </Switch>
                </Layout>
              </RightExpansionContainerProvider>
            </AsyncTask>
          </SessionProvider>
        );
    }
  }
};

const FETCH_DEFAULT_YEAR = gql`
  query fetchDefaultYear {
    defaultYear
  }
`;

const NoYearRoute = () => {
  const { loading, data } = useQuery(FETCH_DEFAULT_YEAR);

  if (loading) {
    return <Loading />;
  } else {
    const { pathname, search } = window.location;

    window.location.href = `/${data.defaultYear}${pathname}${search}`;
    return null;
  }
};
