import { FC, MouseEvent, useState, useMemo, useEffect } from 'react';
import { QuestionGroup, Question } from '../../type';
import { Navigator } from 'components/ui/Navigator';
import { Tag } from 'components/ui/Tag';
import { Pulldown } from 'jbc-front/components/icons';
import styles from './Sidebar.scss';
import classnames from 'classnames';

type OnChangeCode = (code: Question['code']) => void;

type SidebarProps = {
  questionGroups: QuestionGroup[];
  currentQuestionCode?: Question['code'];
  answeringQuestionCode?: Question['code'];
  onChangeCode: OnChangeCode;
  allActive?: boolean;
};

export const Sidebar: FC<SidebarProps> = ({
  questionGroups,
  currentQuestionCode,
  answeringQuestionCode,
  onChangeCode,
  allActive = false
}) => {
  return (
    <div className={styles.sidebar}>
      {questionGroups.map(questionGroup => (
        <QuestionGroupNav
          key={`side-questionGroup-${questionGroup.id}`}
          questionGroup={questionGroup}
          currentQuestionCode={currentQuestionCode}
          answeringQuestionCode={answeringQuestionCode}
          onChangeCode={onChangeCode}
          allActive={allActive}
        />
      ))}
    </div>
  );
};

type QuestionGroupNavProps = {
  questionGroup: QuestionGroup;
  currentQuestionCode?: Question['code'];
  answeringQuestionCode?: Question['code'];
  onChangeCode: OnChangeCode;
  allActive?: boolean;
};

const QuestionGroupNav: FC<QuestionGroupNavProps> = ({
  questionGroup,
  currentQuestionCode,
  answeringQuestionCode,
  onChangeCode,
  allActive = false
}) => {
  const questions = questionGroup.questions;
  const isIncludeCurrentQuestion = questions.some(question => question.code === currentQuestionCode);
  const requestQuestionStatuses = questions.map(question => question.requestQuestion?.status);
  const defaultOpen = requestQuestionStatuses.some(status => status === 'not_answered');
  const [isOpen, setIsOpen] = useState<boolean>(defaultOpen || isIncludeCurrentQuestion);

  const toggle = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsOpen(prev => !prev);
  };

  useEffect(() => {
    if (isIncludeCurrentQuestion) {
      setIsOpen(isIncludeCurrentQuestion);
    }
  }, [isIncludeCurrentQuestion]);

  return (
    <Navigator>
      <Navigator.Header className={styles.header} onClick={toggle}>
        <div className={styles.headerContent}>
          <Tag>STEP {questionGroup.no}</Tag>
          {questionGroup.name}
        </div>
        <span className={classnames(isOpen ? styles.open : styles.close)}>
          <Pulldown />
        </span>
      </Navigator.Header>
      {isOpen && (
        <Navigator.Body>
          {questionGroup.questions.map((question, index) => (
            <QuestionItem
              key={`side-question-${question.id}`}
              question={question}
              questionGroupNo={questionGroup.no}
              index={index}
              currentQuestionCode={currentQuestionCode}
              onChangeCode={onChangeCode}
              answeringQuestionCode={answeringQuestionCode}
              active={allActive}
            />
          ))}
        </Navigator.Body>
      )}
    </Navigator>
  );
};

type QuestionItemProps = {
  question: Question;
  questionGroupNo: number;
  index: number;
  currentQuestionCode?: Question['code'];
  answeringQuestionCode?: Question['code'];
  onChangeCode: OnChangeCode;
  active?: boolean;
};

const QuestionItem: FC<QuestionItemProps> = ({
  question,
  questionGroupNo,
  index,
  currentQuestionCode,
  answeringQuestionCode,
  onChangeCode,
  active = false
}) => {
  const isCurrent = currentQuestionCode === question.code;
  const status = question.requestQuestion?.status;
  const disabledStatus = status === 'not_answered' || status === 'skipped' || status === undefined;
  const isDisabled = disabledStatus && question.code !== answeringQuestionCode;

  const handleChangeCode = () => {
    onChangeCode(question.code);
  };

  return useMemo(() => {
    return (
      <Navigator.Item
        className={classnames(styles.item, { [styles.currentItem]: isCurrent }, { [styles.disableItem]: isDisabled })}
        onClick={isDisabled && !active ? undefined : handleChangeCode}
      >
        <span className={styles.questionStep}>
          STEP{questionGroupNo} - {index + 1}
        </span>
        <span>{question.name}</span>
        {isCurrent && (
          <span className={styles.currentIndicator}>
            <Pulldown />
          </span>
        )}
      </Navigator.Item>
    );
  }, [isCurrent]);
};
