import React from 'react';
import Address, { addressFields } from '../components/Address';
import { CopyAddress } from '../questions/BasicInfos';
import { Section } from 'jbc-front/components/Form';
import { toFormValues } from '../utils/form';
import { LabelMapper } from 'jbc-front/components/FormErrors';
import { FormSection, useDiff } from '../components/FieldWithDiff';
import { EMPLOYEE_ADDRESS_CLASSIFICATION } from './ResidentTax';
import { useYear, useQuery, useAutofill } from '../components';

const ResidentCard = () => {
  const { fixed } = useDiff();
  const { autofill, withSelector } = useAutofill();
  const year = useYear();
  const { data, loading } = useQuery(EMPLOYEE_ADDRESS_CLASSIFICATION, { variables: { year } });
  if (loading) {
    return null;
  }
  const {
    client: {
      clientYearly: {
        clientSetting: { employeeAddressClassification }
      }
    }
  } = data;
  return (
    <Section title="住民票住所">
      <FormSection name="residentCard">
        <LabelMapper name="residentCard" label="住民票住所の" />
        {!fixed && (
          <CopyAddress
            onClick={() => {
              withSelector(selector => {
                addressFields.forEach(field => autofill(`residentCard.${field}`, selector(`basicInformation.${field}`)));
              });
            }}
          />
        )}
        <Address
          withKana={false}
          required
          autofillCityCode={({ name, code }) => {
            if (employeeAddressClassification === 'resident_card') {
              autofill('residentTax.name', name);
              autofill('residentTax.code', code);
            }
          }}
          year={year}
        />
      </FormSection>
    </Section>
  );
};

export const makeInitialValues = profile => toFormValues(profile.residentCard, true);

export default ResidentCard;
