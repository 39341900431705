import { FC } from 'react';
import { SalaryIncome as SalaryIncomeTemplate } from 'features/questions/templates/SalaryIncome';
import { Section } from 'components/ui/Section';
import { FormSection } from 'components/form/FormSection';
import { Row } from 'components/form/Row';
import { InputWithText } from 'components/form/InputWithText';
import { FormItem } from 'components/form/FormItem';
import { useWizard } from '../WizardProvider';
import { useYear } from 'hooks/useYear';
import { amountFormat } from '@jbc-year-end-adj/common/utils/formatter';

// @ts-ignore
import { salaryEarnings as calcSalaryEarnings, calcTotalSalaryIncome } from 'utils/income';

export const SalaryIncome: FC = () => {
  const year = useYear();
  const { question, request } = useWizard();
  const formerJobsPaymentAmount = request.profile?.formerJobs.reduce((totalIncome, formerJob) => {
    if (formerJob.paymentAmount) {
      return totalIncome + Number(formerJob.paymentAmount);
    } else {
      return totalIncome;
    }
  }, 0);

  const income = request.profile?.income;
  const totalSalaryIncome = calcTotalSalaryIncome(income?.mainJobIncome, income?.sideJobIncome, String(formerJobsPaymentAmount));
  const salaryEarnings = calcSalaryEarnings(totalSalaryIncome, year);

  return (
    <SalaryIncomeTemplate question={question}>
      <Section>
        <Section.Header>本人の給与収入</Section.Header>
        <Section.Body>
          <FormSection>
            <Row>
              <InputWithText
                label="給与所得 収入金額（当社）"
                text="円"
                note="前Stepで入力した前職情報は含みません"
                value={amountFormat(income?.mainJobIncome)}
                disabled
              />
              <InputWithText
                label="給与所得 収入金額（他社）"
                text="円"
                note="前Stepで入力した前職情報は含みません"
                value={amountFormat(income?.sideJobIncome)}
                disabled
              />
            </Row>
            <Row>
              <InputWithText label="給与所得 収入金額（前職）" text="円" value={amountFormat(String(formerJobsPaymentAmount))} disabled />
              <FormItem />
            </Row>
            <Row>
              <InputWithText label="給与所得 収入金額（合計）" text="円" value={totalSalaryIncome} disabled />
              <InputWithText label="給与所得 所得金額" text="円" value={salaryEarnings} disabled />
            </Row>
          </FormSection>
        </Section.Body>
      </Section>
    </SalaryIncomeTemplate>
  );
};
