import React from 'react'
import Icon from 'react-icon-base'

const DocumentCreationLine = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="iconDocumentCreationLine" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon" fill={props.color} fillRule="nonzero">
        <path d="M36.2,4 C38.2356813,4 39.893441,5.61312255 39.995063,7.63630206 L40,7.83333333 L40,36.1666667 C40,38.2150496 38.4058554,39.8921841 36.3957821,39.9950046 L36.2,40 L13.8,40 C11.7643187,40 10.106559,38.3868775 10.004937,36.3636979 L10,36.1666667 L10,35.0035481 C10,34.4512634 10.4477153,34.0035481 11,34.0035481 C11.5128358,34.0035481 11.9355072,34.3895883 11.9932723,34.886927 L12,35.0035481 L12,36.1666667 C12,37.1310628 12.7297199,37.9176896 13.652733,37.9939413 L13.8,38 L36.2,38 C37.1418738,38 37.9186874,37.261593 37.9940141,36.3173848 L38,36.1666667 L38,7.83333333 C38,6.86893722 37.2702801,6.08231039 36.347267,6.0060587 L36.2,6 L32.0090272,6 C31.4567425,6 31.0090272,5.55228475 31.0090272,5 C31.0090272,4.48716416 31.3950674,4.06449284 31.8924061,4.00672773 L32.0090272,4 L36.2,4 Z" id="Path"></path>
        <path d="M26.1034483,0 C28.1823477,0 29.890202,1.59843822 29.9949127,3.62115041 L30,3.81818182 L30,29.1818182 C30,31.2306811 28.3571113,32.8931463 26.3034324,32.9950495 L26.1034483,33 L3.89655172,33 C1.81765232,33 0.109798044,31.4015618 0.00508732664,29.3788496 L0,29.1818182 L0,10.8978679 L10.7809458,0.44642728 C11.2171366,0.023568074 11.9135342,0.0343754625 12.3363934,0.470566258 C12.5069222,0.646471302 12.6130229,0.87335052 12.6398572,1.11457971 L12.6466001,1.23622058 L12.6466001,10.721201 C12.6466001,11.5557646 12.0076386,12.2410908 11.1922327,12.3146623 L11.0466001,12.321201 L2,12.321 L2,29.1818182 C2,30.1322205 2.76468908,30.9214772 3.74755398,30.9944894 L3.89655172,31 L26.1034483,31 C27.1067645,31 27.9192305,30.2560006 27.9943331,29.3231006 L28,29.1818182 L28,3.81818182 C28,2.8677795 27.2353109,2.07852281 26.252446,2.00551061 L26.1034483,2 L16.4996925,2 C15.9474077,2 15.4996925,1.55228475 15.4996925,1 C15.4996925,0.487164161 15.8857327,0.0644928393 16.3830713,0.00672773133 L16.4996925,0 L26.1034483,0 Z M10.646,3.362 L3.468,10.321 L10.646,10.321 L10.646,3.362 Z" id="Path"></path>
        <path d="M22,17 C22.5522847,17 23,17.4477153 23,18 C23,18.5128358 22.6139598,18.9355072 22.1166211,18.9932723 L22,19 L7,19 C6.44771525,19 6,18.5522847 6,18 C6,17.4871642 6.38604019,17.0644928 6.88337887,17.0067277 L7,17 L22,17 Z" id="Path"></path>
        <path d="M22,23 C22.5522847,23 23,23.4477153 23,24 C23,24.5128358 22.6139598,24.9355072 22.1166211,24.9932723 L22,25 L7,25 C6.44771525,25 6,24.5522847 6,24 C6,23.4871642 6.38604019,23.0644928 6.88337887,23.0067277 L7,23 L22,23 Z" id="Path"></path>
      </g>
    </g>
  </Icon>
)
DocumentCreationLine.defaultProps = {
  size: 40,
  color: "#3498DB"
}

export default DocumentCreationLine
