import { gql } from '@apollo/client';
import { ASYNC_TASK_FRAGMENT } from '@jbc-year-end-adj/2024/features/AsyncTask/query';
import { EmployeeStatus } from '@jbc-year-end-adj/2024/types';

export type FailureEmployee = {
  id: string;
  staffCode: string;
  status: EmployeeStatus;
  profile: {
    fullName: string;
  };
};

export const UPDATE_STATUS = gql`
  mutation updateStatus($input: UpdateStatusInput!) {
    updateStatus(input: $input) {
      employees {
        id
      }
      failureEmployees {
        id
        staffCode
        status
        profile {
          fullName
        }
      }
    }
  }
`;

export const UPDATE_STATUS_ASYNC = gql`
  mutation updateStatusAsync($input: UpdateStatusAsyncInput!) {
    updateStatusAsync(input: $input) {
      clientYearly {
        id
        asyncTask {
          ...AsyncTaskFields
        }
      }
    }
  }
  ${ASYNC_TASK_FRAGMENT}
`;
