import React from 'react'
import Icon from 'react-icon-base'

const Yen = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="symbol" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
      <g id="icon_yen" stroke="currentColor" strokeWidth="2.5">
        <g id="Group-2" transform="translate(9.000000, -2.766297)">
          <polyline id="Rectangle-3" transform="translate(11.000000, 11.770657) rotate(45.000000) translate(-11.000000, -11.770657) " points="16.4484886 4.07776365 18.6928932 19.4635501 3.30710678 17.2191455"></polyline>
          <path d="M2,24.1500508 L20,24.1500508" id="Line"></path>
          <path d="M2,29.5500508 L20,29.5500508" id="Line"></path>
          <path d="M11,24.1500508 L11,37.3500508" id="Line"></path>
        </g>
      </g>
    </g>
  </Icon>
)
Yen.defaultProps = {
  size: 40
}

export default Yen
