import { FC, useState } from 'react';
import { Anchor, Target, AnchorProvider } from '../../components/Anchor';
import { Employee } from '../../query';
import { Navigator } from 'components/ui/Navigator';
import { ResidentCardAddress } from './ResidentCardAddress';
import { TaxClassification } from './TaxClassification';
import { FormerJobs } from './FormerJobs';
import { EmployeeIncome } from './EmployeeIncome';
import { Spouse } from './Spouse';
import { OtherFamilies } from './OtherFamilies';
import { AttachedFiles } from './AttachedFiles';
import { BasicInformation } from './BasicInformation';
import { ResultProvider } from './ResultProvider';
import { Insurances } from './Insurances';
import { Premium } from './Premium';
import { HousingLoan } from './HousingLoan';
import { Spacer } from 'jbc-front/components/presenters/Spacer';
import Button from 'jbc-front/components/Button';
import { FloatingContainer } from 'components/ui/FloatingContainer';
import { ReflectModal } from './ReflectModal';
import { RejectModal } from './RejectModal';
import { ViewType } from '../../components/ToggleSection';

import styles from './Result.scss';

type SectionMode = {
  basicInformation: ViewType;
  residentCardAddress: ViewType;
  taxClassification: ViewType;
  formerJobs: ViewType;
  employeeIncome: ViewType;
  spouse: ViewType;
  otherFamilies: ViewType;
  insurances: ViewType;
  premium: ViewType;
  housingLoan: ViewType;
  attachedFiles: ViewType;
};

type MainContentProps = {
  employee: Employee;
  onChangeContent: () => void;
  sendAcceptedEmail: boolean;
};

const MainContent: FC<MainContentProps> = ({ employee, onChangeContent, sendAcceptedEmail }) => {
  const [isReflectModalOpen, setIsReflectModalOpen] = useState(false);
  const handleCloseReflectModal = () => {
    setIsReflectModalOpen(false);
  };

  const handleAfterReflect = () => {
    onChangeContent();
    handleCloseReflectModal();
  };

  const handleOpenReflectModal = () => {
    setIsReflectModalOpen(true);
  };

  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const handleCloseRejectModal = () => {
    setIsRejectModalOpen(false);
  };
  const handleOpenRejectModal = () => {
    setIsRejectModalOpen(true);
  };

  const [sectionModeManager, setSectionModeManager] = useState<SectionMode>({
    basicInformation: 'preview',
    residentCardAddress: 'preview',
    taxClassification: 'preview',
    formerJobs: 'preview',
    employeeIncome: 'preview',
    spouse: 'preview',
    otherFamilies: 'preview',
    insurances: 'preview',
    premium: 'preview',
    housingLoan: 'preview',
    attachedFiles: 'preview'
  });

  const handleSetMode = (key: string, viewType: ViewType) => {
    setSectionModeManager(prev => ({ ...prev, [key]: viewType }));
  };

  return (
    <ResultProvider employee={employee}>
      <Target id="request-basic-info">
        <BasicInformation setMode={viewType => handleSetMode('basicInfo', viewType)} />
      </Target>
      <Target id="request-resident-card-address">
        <ResidentCardAddress setMode={viewType => handleSetMode('residentCardAddress', viewType)} />
      </Target>
      <Target id="request-tax-classification">
        <TaxClassification setMode={viewType => handleSetMode('taxClassification', viewType)} />
      </Target>
      <Target id="request-former-jobs">
        <FormerJobs setMode={viewType => handleSetMode('formerJobs', viewType)} />
      </Target>
      <Target id="request-employee-income">
        <EmployeeIncome setMode={viewType => handleSetMode('employeeIncome', viewType)} />
      </Target>
      <Target id="request-spouse">
        <Spouse setMode={viewType => handleSetMode('spouse', viewType)} />
      </Target>
      <Target id="request-other-families">
        <OtherFamilies setMode={viewType => handleSetMode('otherFamilies', viewType)} />
      </Target>
      <Target id="request-insurances">
        <Insurances setMode={viewType => handleSetMode('insurances', viewType)} />
      </Target>
      <Target id="request-premium">
        <Premium setMode={viewType => handleSetMode('premium', viewType)} />
      </Target>
      <Target id="request-housing-loan">
        <HousingLoan setMode={viewType => handleSetMode('housingLoan', viewType)} />
      </Target>
      <Target id="request-attached-files">
        <AttachedFiles setMode={viewType => handleSetMode('attachedFiles', viewType)} />
      </Target>

      {['applying', 'fixed'].includes(employee.status) && (
        <>
          {Object.values(sectionModeManager).every((mode: ViewType) => mode === 'preview') && (
            <>
              <Spacer direction="y" size={20} />
              <FloatingContainer>
                <Button onClick={handleOpenRejectModal}>修正依頼</Button>
                <Button primary onClick={handleOpenReflectModal}>
                  承認する
                </Button>
              </FloatingContainer>
            </>
          )}

          <ReflectModal
            onClose={handleCloseReflectModal}
            onAfterReflect={handleAfterReflect}
            isOpen={isReflectModalOpen}
            sendAcceptedEmail={sendAcceptedEmail}
          />
          <RejectModal onClose={handleCloseRejectModal} isOpen={isRejectModalOpen} />
        </>
      )}
    </ResultProvider>
  );
};

type SideContentProps = {
  employee: Employee;
};

const SideContent: FC<SideContentProps> = ({ employee: _ }) => {
  return (
    <AnchorProvider initialAnchor="request-basic-info">
      <Navigator className={styles.resultNavigator}>
        <Navigator.Body>
          <Navigator.Item className={styles.item}>
            <Anchor id="request-basic-info">基本情報</Anchor>
          </Navigator.Item>
          <Navigator.Item className={styles.item}>
            <Anchor id="request-resident-card-address">住民票住所</Anchor>
          </Navigator.Item>
          <Navigator.Item className={styles.item}>
            <Anchor id="request-tax-classification">税区分情報</Anchor>
          </Navigator.Item>
          <Navigator.Item className={styles.item}>
            <Anchor id="request-former-jobs">前職情報</Anchor>
          </Navigator.Item>
          <Navigator.Item className={styles.item}>
            <Anchor id="request-employee-income">給与収入等</Anchor>
          </Navigator.Item>
          <Navigator.Item className={styles.item}>
            <Anchor id="request-spouse">配偶者情報</Anchor>
          </Navigator.Item>
          <Navigator.Item className={styles.item}>
            <Anchor id="request-other-families">扶養親族情報</Anchor>
          </Navigator.Item>
          <Navigator.Item className={styles.item}>
            <Anchor id="request-insurances">生命保険・地震保険・社会保険</Anchor>
          </Navigator.Item>
          <Navigator.Item className={styles.item}>
            <Anchor id="request-premium">小規模企業共済等掛金</Anchor>
          </Navigator.Item>
          <Navigator.Item className={styles.item}>
            <Anchor id="request-housing-loan">住宅ローン</Anchor>
          </Navigator.Item>
          <Navigator.Item className={styles.item}>
            <Anchor id="request-attached-files">添付ファイル</Anchor>
          </Navigator.Item>
        </Navigator.Body>
      </Navigator>
    </AnchorProvider>
  );
};

export const Result = {
  MainContent,
  SideContent
};
