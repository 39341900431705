import { FC, ReactNode } from 'react';
import styles from './Description.scss';
import classnames from 'classnames';

// @ts-ignore
import { renderTextWithAnchor } from 'jbc-front/components/Form';

type DescriptionProps = {
  caution?: boolean;
  children: ReactNode;
};

export const Description: FC<DescriptionProps> = ({ caution = false, children }) => {
  if (typeof children === 'string') {
    return <div className={classnames(styles.description, { [styles.caution]: caution })}>{renderTextWithAnchor(children)}</div>;
  } else {
    return <div className={classnames(styles.description, { [styles.caution]: caution })}>{children}</div>;
  }
};
