import React from 'react'
import Icon from 'react-icon-base'

const Visibility = props => (
  <Icon viewBox="0 0 16 11" {...props}>
    <defs>
      <path id="a" d="M0 .037h15.956v10.85H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(0 .075)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <path
          fill="currentColor"
          d="M7.978.037c4.406 0 7.978 2.43 7.978 5.426s-3.572 5.425-7.978 5.425S0 8.46 0 5.463C0 2.467 3.572.037 7.978.037"
          mask="url(#b)"
        />
      </g>
      <path
        fill="#FEFEFE"
        d="M7.978 1.592c2.203 0 3.989 1.767 3.989 3.946 0 2.179-1.786 3.945-3.989 3.945S3.989 7.717 3.989 5.538c0-2.18 1.786-3.946 3.989-3.946"
      />
      <path
        fill="currentColor"
        d="M7.978 3.072c1.377 0 2.493 1.104 2.493 2.466S9.355 8.003 7.978 8.003c-1.377 0-2.493-1.103-2.493-2.465 0-1.362 1.116-2.466 2.493-2.466"
      />
    </g>
  </Icon>
)

Visibility.defaultProps = {
  size: 16
}

export default Visibility
