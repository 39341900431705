import React from 'react'
import Icon from 'react-icon-base'

const Affiliation = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="icon/employees/Affiliation" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group" transform="translate(2.000000, 4.000000)" fill={props.color}>
        <path d="M34,4 C35.1045695,4 36,4.8954305 36,6 L36,27 C36,28.1045695 35.1045695,29 34,29 L2,29 C0.8954305,29 0,28.1045695 0,27 L0,6 C0,4.8954305 0.8954305,4 2,4 L34,4 Z M32.9999999,6 L2.99999988,6 C2.44771513,6 1.99999988,6.44771525 1.99999988,7 L1.99999988,7 L1.99999988,26 C1.99999988,26.5522847 2.44771513,27 2.99999988,27 L2.99999988,27 L32.9999999,27 C33.5522846,27 33.9999999,26.5522847 33.9999999,26 L33.9999999,26 L33.9999999,7 C33.9999999,6.44771525 33.5522846,6 32.9999999,6 L32.9999999,6 Z" id="Combined-Shape"></path>
        <path d="M10.5,11 C12.4327627,11 14,12.5672373 14,14.5 C14,15.5303112 13.5546366,16.4567507 12.8459511,17.0972771 C14.7571259,17.9714851 16.0852074,19.9006966 16.0852074,22.1398051 C16.0852074,22.5540187 15.749421,22.8898051 15.3352074,22.8898051 C14.9209938,22.8898051 14.5852074,22.5540187 14.5852074,22.1398051 C14.5852074,19.907382 12.7750268,18.0972014 10.5426037,18.0972014 C8.31018058,18.0972014 6.5,19.907382 6.5,22.1398051 C6.5,22.5540187 6.16421356,22.8898051 5.75,22.8898051 C5.33578644,22.8898051 5,22.5540187 5,22.1398051 C5,19.9223629 6.30250409,18.0088493 8.18409125,17.1226854 C7.45775345,16.4823277 7,15.5445445 7,14.5 C7,12.5672373 8.56723731,11 10.5,11 Z M10.5,12.4794564 C9.38431851,12.4794564 8.4794564,13.3843185 8.4794564,14.5 C8.4794564,15.6156815 9.38431851,16.5205436 10.5,16.5205436 C11.6156815,16.5205436 12.5205436,15.6156815 12.5205436,14.5 C12.5205436,13.3843185 11.6156815,12.4794564 10.5,12.4794564 Z" id="Stroke-3" fillRule="nonzero"></path>
        <path d="M30.2777778,22 C30.6766501,22 31,22.3357864 31,22.75 C31,23.1296958 30.7282963,23.443491 30.3757791,23.4931534 L30.2777778,23.5 L18.7222222,23.5 C18.3233499,23.5 18,23.1642136 18,22.75 C18,22.3703042 18.2717037,22.056509 18.6242209,22.0068466 L18.7222222,22 L30.2777778,22 Z M30.2777778,19 C30.6766501,19 31,19.3357864 31,19.75 C31,20.1296958 30.7282963,20.443491 30.3757791,20.4931534 L30.2777778,20.5 L18.7222222,20.5 C18.3233499,20.5 18,20.1642136 18,19.75 C18,19.3703042 18.2717037,19.056509 18.6242209,19.0068466 L18.7222222,19 L30.2777778,19 Z M30.2777778,16 C30.6766501,16 31,16.3357864 31,16.75 C31,17.1296958 30.7282963,17.443491 30.3757791,17.4931534 L30.2777778,17.5 L18.7222222,17.5 C18.3233499,17.5 18,17.1642136 18,16.75 C18,16.3703042 18.2717037,16.056509 18.6242209,16.0068466 L18.7222222,16 L30.2777778,16 Z M30.2777778,13 C30.6766501,13 31,13.3357864 31,13.75 C31,14.1296958 30.7282963,14.443491 30.3757791,14.4931534 L30.2777778,14.5 L18.7222222,14.5 C18.3233499,14.5 18,14.1642136 18,13.75 C18,13.3703042 18.2717037,13.056509 18.6242209,13.0068466 L18.7222222,13 L30.2777778,13 Z M24.5,10 C24.8988723,10 25.2222222,10.3357864 25.2222222,10.75 C25.2222222,11.1296958 24.9505185,11.443491 24.5980013,11.4931534 L24.5,11.5 L18.7222222,11.5 C18.3233499,11.5 18,11.1642136 18,10.75 C18,10.3703042 18.2717037,10.056509 18.6242209,10.0068466 L18.7222222,10 L24.5,10 Z" id="Combined-Shape" fillRule="nonzero"></path>
        <path d="M18,0 C20.209139,0 22,1.49238417 22,3.33333333 C22,5.1742825 20.209139,6.66666667 18,6.66666667 C15.790861,6.66666667 14,5.1742825 14,3.33333333 C14,1.49238417 15.790861,0 18,0 Z M18,1.33333333 C16.6745166,1.33333333 15.6,2.22876383 15.6,3.33333333 C15.6,4.43790283 16.6745166,5.33333333 18,5.33333333 C19.3254834,5.33333333 20.4,4.43790283 20.4,3.33333333 C20.4,2.22876383 19.3254834,1.33333333 18,1.33333333 Z M14,3 L22,3 C23.1045695,3 24,3.8954305 24,5 C24,6.1045695 23.1045695,7 22,7 L14,7 C12.8954305,7 12,6.1045695 12,5 C12,3.8954305 12.8954305,3 14,3 Z" id="Oval" fillRule="nonzero"></path>
      </g>
    </g>
  </Icon>
)
Affiliation.defaultProps = {
  size: 30,
  color: "#3498DB"
}

export default Affiliation
