export const NATIONAL_TYPE = {
  japanese: '日本人',
  foreigner: '外国人'
} as const;

export const GENDER = {
  male: '男性',
  female: '女性'
};

export const RELATIONSHIPS = [
  '本人',
  '夫',
  '妻',
  '実父',
  '実母',
  '義父',
  '義母',
  '長男',
  '長女',
  '兄',
  '姉',
  '弟',
  '妹',
  '祖父',
  '祖母',
  '叔父',
  '叔母'
];

export const RELATION_OTHERS = [
  '実父',
  '実母',
  '義父',
  '義母',
  '長男',
  '長女',
  '次男',
  '次女',
  '三男',
  '三女',
  '兄',
  '姉',
  '弟',
  '妹',
  '祖父',
  '祖母',
  '曽祖父母',
  '孫息子',
  '孫娘',
  '叔父',
  '叔母',
  '甥',
  '姪'
];

export const MARITAL_STATUS = {
  married: '有',
  unmarried: '無（未婚）',
  divorced_or_bereavement: '無（離婚・死別等）'
};

export const RESIDENT_STATUS = {
  same_address: '同居',
  different_address: '別居（国内）',
  different_and_foreign_address: '別居（国外）'
};

export const WORKING_STUDENT_CLASSIFICATION = {
  none: '対象外',
  working_student: '勤労学生'
};

export const HANDICAP_CLASSIFICATION = {
  none: '対象外',
  normal: '一般障害者',
  special: '特別障害者'
};

export const WIDOW_CLASSIFICATION = {
  none: '対象外',
  normal: '寡婦',
  special: '特別寡婦',
  single_parent: 'ひとり親'
};

export const WITHHOLDING_SLIP_STATUS = {
  submitted: '提出済み',
  not_submitted: '手元にある',
  in_order: '取り寄せ中',
  unavailable: '入手できない'
};

export const STATUS = {
  not_requested: '未依頼',
  not_need_adj: '対象外',
  in_progress: '依頼中',
  applying: '入力済',
  rejected: '再依頼中',
  fixed: '修正済',
  accepted: '完了'
};

export const STATUS_COLORS = {
  not_requested: 'red',
  not_need_adj: 'grey',
  in_progress: 'blue',
  applying: 'yellow',
  rejected: 'blue',
  fixed: 'yellow',
  accepted: 'green'
};
