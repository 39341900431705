import { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { usePrefectureOptions } from 'hooks/usePrefectureOptions';
import { useAddress } from 'hooks/useAddress';
import { useYear } from 'hooks/useYear';

import { PostcodeInput } from 'components/form/PostcodeInput';
import { SelectField } from 'components/react-hook-form/SelectField';
import { Input } from 'components/form/Input';
import { Schema } from '../schema';

import { Grid } from '../../../../../components/Grid';

type DifferentAddressType = {
  index: number;
};

export const DifferentAddress: FC<DifferentAddressType> = ({ index }) => {
  const {
    formState: { errors },
    register,
    watch,
    setValue,
    trigger
  } = useFormContext<Schema>();

  const { prefectureOptions } = usePrefectureOptions();
  const { fetchAddress, address } = useAddress();
  const year = useYear();

  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  const postcode0 = watch(`otherFamilies.${index}.thisYear.yearlyInfo.postcode0`);
  const postcode1 = watch(`otherFamilies.${index}.thisYear.yearlyInfo.postcode1`);
  const prefectureId = watch(`otherFamilies.${index}.thisYear.yearlyInfo.prefectureId`);

  useEffect(() => {
    if (!isFirstRender) {
      if (postcode0 && postcode1) {
        const postcode = Number(`${postcode0}${postcode1}`);
        fetchAddress({ variables: { postcode, year } });
      }
    } else {
      setIsFirstRender(false);
    }
  }, [postcode0, postcode1]);

  useEffect(() => {
    if (address) {
      const prefecture = prefectureOptions.find(option => option.label === address.prefecture);
      setValue(`otherFamilies.${index}.thisYear.yearlyInfo.prefectureId`, prefecture?.value);
      setValue(`otherFamilies.${index}.thisYear.yearlyInfo.city`, address.city);
      setValue(`otherFamilies.${index}.thisYear.yearlyInfo.street`, address.street);

      trigger([
        `otherFamilies.${index}.thisYear.yearlyInfo.prefectureId`,
        `otherFamilies.${index}.thisYear.yearlyInfo.city`,
        `otherFamilies.${index}.thisYear.yearlyInfo.street`
      ]);
    }
  }, [address]);

  const otherFamilyErrors = errors.otherFamilies ? errors.otherFamilies[index] : undefined;

  const postcodeErrors = [
    otherFamilyErrors?.thisYear?.yearlyInfo?.postcode0?.message,
    otherFamilyErrors?.thisYear?.yearlyInfo?.postcode1?.message
  ].reduce<string[]>((errors, message) => {
    if (message) {
      errors.push(message);
    }

    return errors;
  }, []);

  return (
    <Grid colGap>
      <PostcodeInput
        required
        errors={postcodeErrors}
        firstCodeProps={{
          isError: !!otherFamilyErrors?.thisYear?.yearlyInfo?.postcode0?.message,
          ...register(`otherFamilies.${index}.thisYear.yearlyInfo.postcode0`)
        }}
        lastCodeProps={{
          isError: !!otherFamilyErrors?.thisYear?.yearlyInfo?.postcode1?.message,
          ...register(`otherFamilies.${index}.thisYear.yearlyInfo.postcode1`)
        }}
      />
      <SelectField
        label="都道府県"
        options={prefectureOptions}
        value={prefectureOptions.find(option => option.value === prefectureId)}
        required
        name={`otherFamilies.${index}.thisYear.yearlyInfo.prefectureId`}
        error={otherFamilyErrors?.thisYear?.yearlyInfo?.prefectureId?.message}
      />
      <Input
        label="市区町村"
        required
        error={otherFamilyErrors?.thisYear?.yearlyInfo?.city?.message}
        isError={!!otherFamilyErrors?.thisYear?.yearlyInfo?.city?.message}
        {...register(`otherFamilies.${index}.thisYear.yearlyInfo.city`)}
      />
      <Input
        label="丁目番地号"
        error={otherFamilyErrors?.thisYear?.yearlyInfo?.street?.message}
        isError={!!otherFamilyErrors?.thisYear?.yearlyInfo?.street?.message}
        {...register(`otherFamilies.${index}.thisYear.yearlyInfo.street`)}
      />
      <Input
        label="建物名"
        error={otherFamilyErrors?.thisYear?.yearlyInfo?.building?.message}
        isError={!!otherFamilyErrors?.thisYear?.yearlyInfo?.building?.message}
        {...register(`otherFamilies.${index}.thisYear.yearlyInfo.building`)}
      />
    </Grid>
  );
};
