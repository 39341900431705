import React from 'react'
import Icon from 'react-icon-base'

const DocumentStep2 = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="E_書類作成" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Artboard" transform="translate(-546.000000, -628.000000)">
        <g id="2" transform="translate(546.000000, 628.000000)">
          <circle id="Oval" fill={props.color} cx="20" cy="20" r="20"></circle>
          <path d="M20,2.22222222 C29.8183956,2.22222222 37.7777778,10.1816044 37.7777778,20 C37.7777778,29.8183956 29.8183956,37.7777778 20,37.7777778 C10.1816044,37.7777778 2.22222222,29.8183956 2.22222222,20 C2.22222222,10.1816044 10.1816044,2.22222222 20,2.22222222 Z M20,5.22222222 C11.8384587,5.22222222 5.22222222,11.8384587 5.22222222,20 C5.22222222,28.1615413 11.8384587,34.7777778 20,34.7777778 C28.1615413,34.7777778 34.7777778,28.1615413 34.7777778,20 C34.7777778,11.8384587 28.1615413,5.22222222 20,5.22222222 Z" id="Oval" fill="#FFFFFF" fillRule="nonzero"></path>
          <path d="M24.65,28.04 L24.65,26.04 L17.79,26.04 C18.41,24.8 19.41,23.68 21.51,21.8 C23.85,19.62 24.59,18.34 24.59,16.64 C24.59,14 22.77,12.52 20.03,12.52 C17.53,12.52 15.67,14 15.33,16.74 L17.83,17.26 C17.95,15.44 18.73,14.5 19.99,14.5 C21.21,14.5 21.99,15.28 21.99,16.62 C21.99,17.88 21.55,18.68 19.55,20.6 C17.37,22.7 16.09,24.38 15.19,26.26 L15.19,28.04 L24.65,28.04 Z" fill="#FFFFFF" fillRule="nonzero"></path>
        </g>
      </g>
    </g>
  </Icon>
)

DocumentStep2.defaultProps = {
  size: 30,
  color: "#3498DB"
}

export default DocumentStep2
