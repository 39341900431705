import React from 'react';
import Button from 'jbc-front/components/Button';
import { onSubmitFail } from 'jbc-front/components/FormErrors';
import { useMutation, useQuery } from '../components/Graphql';
import { gql } from '@apollo/client';
import { TextAreaField, FileField, Section } from 'jbc-front/components/Form';
import { toFormValues } from '../utils/form';
import { reduxForm, useFormValue, useAutofill } from '../components/FormName';
import { Panel, Headding, Item, Lead, Notes, Buttons, Line } from '../components/PrimaryPanel';
import LoadingPage from '../components/LoadingPage';
import useReactRouter from 'use-react-router';
import { CustomDescription } from '../components/QuestionDescription';
import FilePreview from '../components/FilePreview';
import styles from './Handicap.scss';

export const handicapClassifications = [
  { value: 'none', label: '対象外' },
  { value: 'normal', label: '一般障害者' },
  { value: 'special', label: '特別障害者' }
];

export const HANDICAP_FRAGMENT = gql`
  fragment Handicap on Profile {
    id
    handicapClassification {
      id
      classification
      detail
      image {
        url
        filename
      }
    }
  }
`;

const HANDICAP = gql`
  query handicap {
    request {
      id
      profile {
        ...Handicap
      }
    }
  }
  ${HANDICAP_FRAGMENT}
`;

const HANDICAP_IMAGE_SETTING = gql`
  query handicapImageSetting {
    clientSetting {
      id
      requestImageUploadSettings {
        handicap
      }
    }
  }
`;

const ANSWER_HANDICAP = gql`
  mutation answerHandicap($input: AnswerHandicapInput!) {
    answerHandicap(input: $input) {
      questions {
        id
        status
      }
      profile {
        ...Handicap
      }
    }
  }
  ${HANDICAP_FRAGMENT}
`;

const QuestionForm =
  (({ handleSubmit, submitting }) => {
    const { data } = useQuery(HANDICAP_IMAGE_SETTING);
    const handicapImageSetting = data?.clientSetting.requestImageUploadSettings.handicap;
    const [answer, { loading }] = useMutation(ANSWER_HANDICAP);
    const classification = useFormValue('classification');
    const { autofill } = useAutofill();
    const { history } = useReactRouter();
    return (
      <Panel>
        <Headding className={styles.primaryPanelHeader}>障害者情報</Headding>
        <Item>
          <Lead>あなたは以下のいずれかに該当しますか？</Lead>
          <CustomDescription field="under_note" />
          <Notes>
            <div>一般障害者とは、以下に該当する方です。</div>
            <ul className="u-mb20">
              <li>・身体障害者手帳 3〜6級</li>
              <li>・精神障害者保険福祉手帳 2〜3級</li>
              <li>・愛護手帳（療育手帳） B</li>
            </ul>
            <div>特別障害者とは、以下に該当する方です。</div>
            <ul>
              <li>・身体障害者手帳 1~2級</li>
              <li>・精神障害者保険福祉手帳 1級</li>
              <li>・愛護手帳（療育手帳）A</li>
              <li>・常に寝たきりである方</li>
            </ul>
          </Notes>
          <Buttons>
            <Button
              huge
              secondary
              disabled={loading}
              primary={classification === 'none'}
              onClick={async () => {
                await answer({ variables: { input: { classification: 'none' } } });
                history.push('/employee_input');
              }}
            >
              対象外
            </Button>
            <Button
              huge
              secondary
              disabled={loading}
              primary={classification === 'normal'}
              onClick={() => autofill('classification', 'normal')}
            >
              一般障害者
            </Button>
            <Button
              huge
              secondary
              disabled={loading}
              primary={classification === 'special'}
              onClick={() => autofill('classification', 'special')}
            >
              特別障害者
            </Button>
          </Buttons>

          {classification !== 'none' && (
            <React.Fragment>
              <Line />
              <Lead>以下の情報の記入が必要です。</Lead>
              <Section title="障害者区分" style={{ maxWidth: '760px' }}>
                <TextAreaField
                  name="detail"
                  label="障害者詳細情報"
                  description="障害の状態又は交付を受けている手帳などの種類と交付年月日、障害の等級等を記入してください。"
                />
                {handicapImageSetting && handicapImageSetting !== 'none' && (
                  <FileField
                    name="image"
                    required={handicapImageSetting === 'required'}
                    label="障害者手帳画像"
                    preview={FilePreview}
                    disablePreview={false}
                  />
                )}
              </Section>
              <Buttons>
                <Button primary onClick={handleSubmit} disabled={submitting}>
                  次ヘ
                </Button>
              </Buttons>
            </React.Fragment>
          )}
        </Item>
      </Panel>
    );
  }) |> reduxForm({ form: 'handicap', onSubmitFail });

const Question = () => {
  const { loading, data } = useQuery(HANDICAP);
  const [answer] = useMutation(ANSWER_HANDICAP);
  const { history } = useReactRouter();
  if (loading) return <LoadingPage />;
  return (
    <QuestionForm
      initialValues={toFormValues(data.request.profile.handicapClassification || { classification: 'none' }, true)}
      onSubmit={async values => {
        await answer({ variables: { input: values } });
        history.push('/employee_input');
      }}
    />
  );
};

export default Question;
