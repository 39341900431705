import React from 'react';
import { BoxDouble } from 'jbc-front/components/Form';
import { TextField, TextFieldWithKana } from './FieldWithDiff';
import japanese from 'japanese';
import { zenkakuKatakanaWithSpace } from '../validators';
import { useDispatch } from 'react-redux';
import { FormName, autofill } from 'redux-form';

export const withPrefix = (prefix, name) => (prefix ? `${prefix}.${name}` : name);

export const Name = ({ required, isCommonName, isKanji, noHankaku, diff }) => {
  const labelKanji = isKanji ? '（漢字）' : '';
  const labelCommonName = isCommonName ? '通称' : '';
  const dispatch = useDispatch();
  return (
    <FormName>
      {({ form, sectionPrefix }) => (
        <BoxDouble>
          <TextFieldWithKana
            name="lastName"
            label={`${labelCommonName}姓${labelKanji}`}
            type="text"
            onUpdateKana={kana => {
              dispatch(autofill(form, withPrefix(sectionPrefix, 'lastNameKana'), japanese.katakanize(kana)));
            }}
            required={required}
            diff={diff['lastName']}
          />
          <TextFieldWithKana
            name="firstName"
            label={`${labelCommonName}名${labelKanji}`}
            type="text"
            onUpdateKana={kana => {
              dispatch(autofill(form, withPrefix(sectionPrefix, 'firstNameKana'), japanese.katakanize(kana)));
            }}
            required={required}
            diff={diff['firstName']}
          />

          <TextField
            name="lastNameKana"
            label={`${labelCommonName}姓(カナ)`}
            validate={zenkakuKatakanaWithSpace}
            required={required}
            diff={diff['lastNameKana']}
          />
          <TextField
            name={'firstNameKana'}
            label={`${labelCommonName}名(カナ)`}
            validate={zenkakuKatakanaWithSpace}
            required={required}
            diff={diff['firstNameKana']}
          />
        </BoxDouble>
      )}
    </FormName>
  );
};
Name.defaultProps = {
  diff: {}
};

export default Name;
