import { FC, ReactNode } from 'react';
import { Section } from 'components/ui/Section';

import { Question } from '../type';

import { Lead } from './parts/Lead';
import { Description } from './parts/Description';
import { Content } from './parts/Content';
import { Note } from './parts/Note';

import { Hint } from 'jbc-front/components/presenters/ui/Hint';

type HasDependentProps = {
  question: Question;
  children: ReactNode;
};

export const HasDependent: FC<HasDependentProps> = ({ question, children }) => {
  const description = question.questionDescriptions.find(description => description.field == 'under_note')?.description;

  return (
    <Section>
      <Section.Header>扶養親族有無</Section.Header>

      <Section.Body>
        <Content>
          <Lead>配偶者以外の税の扶養親族はいますか？</Lead>

          <Description>税の扶養親族は今年の12月31日または出国・死亡時点に以下の4つの要件すべてに当てはまる人です。</Description>

          <Note>
            <ul>
              <li>
                (1)
                配偶者以外の親族（6親等内の血族及び3親等内の姻族をいいます。）又は都道府県知事から養育を委託された児童(いわゆる里子)や市町村長から養護を委託された老人であること。
              </li>
              <li>
                (2) 納税者と生計を一にしていること。
                <Hint>
                  「生計を一にする」とは、必ずしも同居を要件とするものではありません。例えば、別居している場合であっても、余暇には起居を共にすることを常例としている場合や、常に生活費等の送金が行われている場合には、「生計を一にする」ものとして取り扱われます。なお、親族が同一の家屋に起居している場合には、明らかに互いに独立した生活を営んでいると認められる場合を除き、「生計を一にする」ものとして取り扱われます。
                </Hint>
              </li>
              <li>(3) 年間の合計所得金額が48万円以下であること。(給与のみの場合は給与収入が103万円以下)</li>
              <li>
                (4) 青色申告者の事業専従者としてその年を通じて一度も給与の支払を受けていないこと又は白色申告者の事業専従者でないこと。
                <Hint>
                  「青色申告者の事業専従者」に関しては
                  <a href="https://www.nta.go.jp/taxes/shiraberu/taxanswer/shotoku/2075.htm#a-1" target="_blank" rel="noopener noreferrer">
                    こちら
                  </a>
                  、「白色申告者の事業専従者」に関しては
                  <a href="https://www.nta.go.jp/taxes/shiraberu/taxanswer/shotoku/2075.htm#a-2" target="_blank" rel="noopener noreferrer">
                    こちら
                  </a>
                  をご覧ください。
                </Hint>
              </li>
            </ul>
          </Note>

          <Description>
            <>{description}</>
          </Description>

          <Description caution>
            ※16歳未満の扶養親族がいる場合、扶養控除の対象となりませんが、住民税の「非課税基準額」の判定に必要となりますので、「はい」を選択してください。
          </Description>

          {children}
        </Content>
      </Section.Body>
    </Section>
  );
};
