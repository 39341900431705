import React from 'react'
import Icon from 'react-icon-base'

const ArrowDownSquare = props => (
  <Icon viewBox="0 0 20 20" {...props}>
    <g>
      <path d="M2,19.5c-0.827,0-1.5-0.673-1.5-1.5V2c0-0.827,0.673-1.5,1.5-1.5h16c0.827,0,1.5,0.673,1.5,1.5v16&#xA;&#x9;&#x9;c0,0.827-0.673,1.5-1.5,1.5H2z" fill="#FFFFFF"/>
      <path d="M18,1c0.552,0,1,0.449,1,1v16c0,0.552-0.448,1-1,1H2c-0.551,0-1-0.448-1-1V2c0-0.551,0.449-1,1-1H18 M18,0&#xA;&#x9;&#x9;H2C0.9,0,0,0.9,0,2v16c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V2C20,0.9,19.1,0,18,0L18,0z" fill="currenrColor"/>
    </g>
    <path d="M6.175,7.111L10,10.93l3.825-3.816L15,8.287l-5,5l-5-5L6.175,7.111z" fill="currenrColor"/>
  </Icon>
)
ArrowDownSquare.defaultProps = {
  size: 20
}


export default ArrowDownSquare
