import React from 'react'
import Icon from 'react-icon-base'

const Custom1 = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="Custom1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon/employees/Custom1" fill="#3498DB" fillRule="nonzero">
        <g id="Group" transform="translate(7.000000, 4.000000)">
          <path d="M22.4117647,2 C22.6308324,2 22.842558,2.07188518 23.0153431,2.20269165 L23.1140885,2.28814238 L29.7071068,8.79289322 L29.7792677,8.87251278 C29.9211003,9.04591803 29.9912236,9.24579137 30.0008524,9.44479774 L30,27.1428571 C30,28.66739 28.7972718,29.9084579 27.2867087,29.995161 L27.1176471,30 L-1.11764706,30 C-2.64978513,30 -3.90723255,28.8133798 -3.99509597,27.3110389 L-4,27.1428571 L-4,4.85714286 C-4,3.33260996 -2.79727179,2.09154211 -1.28670874,2.00483896 L-1.11764706,2 L22.4117647,2 Z M21.411,4 L-1.11764706,4 C-1.57023696,4 -1.93910363,4.32957815 -1.9931743,4.75022257 L-2,4.85714286 L-2,27.1428571 C-2,27.5770229 -1.66594793,27.9399985 -1.22891658,27.9932718 L-1.11764706,28 L27.1176471,28 C27.570237,28 27.9391036,27.6704218 27.9931743,27.2497774 L28,27.1428571 L28,10.5 L22.4119909,10.5 L22.2951436,10.4932723 C21.8360617,10.4399506 21.4718141,10.075703 21.4184924,9.61662113 L21.4117647,9.5 L21.411,4 Z M23.411,5.39 L23.411,8.5 L26.563,8.5 L23.411,5.39 Z" id="Path" transform="translate(13.000426, 16.000000) rotate(90.000000) translate(-13.000426, -16.000000) "></path>
          <path d="M13.5,3 C14.3284271,3 15,3.67157288 15,4.5 L15,5.5 C15,6.32842712 14.3284271,7 13.5,7 L4.5,7 C3.67157288,7 3,6.32842712 3,5.5 L3,4.5 C3,3.67157288 3.67157288,3 4.5,3 L13.5,3 Z M13.5,4.5 L4.5,4.5 L4.5,5.5 L13.5,5.5 L13.5,4.5 Z" id="Rectangle"></path>
          <path d="M21.5,9 C22.3284271,9 23,9.67157288 23,10.5 L23,14.5 C23,15.3284271 22.3284271,16 21.5,16 L4.5,16 C3.67157288,16 3,15.3284271 3,14.5 L3,10.5 C3,9.67157288 3.67157288,9 4.5,9 L21.5,9 Z M21.5,10.5 L4.5,10.5 L4.5,14.5 L21.5,14.5 L21.5,10.5 Z" id="Rectangle-Copy"></path>
          <path d="M21.5,18 C22.3284271,18 23,18.6715729 23,19.5 L23,21.5 C23,22.3284271 22.3284271,23 21.5,23 L4.5,23 C3.67157288,23 3,22.3284271 3,21.5 L3,19.5 C3,18.6715729 3.67157288,18 4.5,18 L21.5,18 Z M21.5,19.5 L4.5,19.5 L4.5,21.5 L21.5,21.5 L21.5,19.5 Z" id="Rectangle-Copy-2"></path>
          <path d="M9.5,25 C10.3284271,25 11,25.6715729 11,26.5 L11,27.5 C11,28.3284271 10.3284271,29 9.5,29 L4.5,29 C3.67157288,29 3,28.3284271 3,27.5 L3,26.5 C3,25.6715729 3.67157288,25 4.5,25 L9.5,25 Z M9.5,26.5 L4.5,26.5 L4.5,27.5 L9.5,27.5 L9.5,26.5 Z" id="Rectangle-Copy-3"></path>
        </g>
      </g>
    </g>
  </Icon>
)
Custom1.defaultProps = {
  size: 30
}

export default Custom1