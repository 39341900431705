import { FC, ReactNode } from 'react';
import { Section } from 'components/ui/Section';
import { Separator } from 'components/ui/Separator';

import { Question } from '../type';

import { Lead } from './parts/Lead';
import { Description } from './parts/Description';
import { Content } from './parts/Content';

type HasInsurancesProps = {
  children: ReactNode;
};

const MainSection: FC<HasInsurancesProps> = ({ children }) => {
  return (
    <Section>
      <Section.Header>保険・ローンの有無</Section.Header>

      <Section.Body>
        <Content>{children}</Content>
      </Section.Body>
    </Section>
  );
};

type LifeInsuranceProps = {
  question: Question;
  children: ReactNode;
};

const LifeInsurance: FC<LifeInsuranceProps> = ({ question, children }) => {
  const description = question.questionDescriptions.find(description => description.field == 'life_insurance')?.description;

  return (
    <>
      <Lead>今年生命保険料を支払いましたか？</Lead>
      <Description>{description}</Description>
      {children}
    </>
  );
};

type EarthquakeInsuranceProps = {
  question: Question;
  children: ReactNode;
};

const EarthquakeInsurance: FC<EarthquakeInsuranceProps> = ({ question, children }) => {
  const description = question.questionDescriptions.find(description => description.field == 'earthquake_insurance')?.description;

  return (
    <>
      <Separator />
      <Lead>今年地震保険料を支払いましたか？</Lead>
      <Description>{description}</Description>
      {children}
    </>
  );
};

type SocialInsuranceProps = {
  question: Question;
  children: ReactNode;
};

const SocialInsurance: FC<SocialInsuranceProps> = ({ question, children }) => {
  const description = question.questionDescriptions.find(description => description.field == 'social_insurance')?.description;

  return (
    <>
      <Separator />
      <Lead>今年国民年金・国民健康保険料を支払いましたか？</Lead>
      <Description>{description}</Description>
      {children}
    </>
  );
};

type PremiumProps = {
  question: Question;
  children: ReactNode;
};

const Premium: FC<PremiumProps> = ({ question, children }) => {
  const description = question.questionDescriptions.find(description => description.field == 'premium')?.description;

  return (
    <>
      <Separator />
      <Lead>今年共済契約掛金・個人型企業型年金掛金を支払いましたか？</Lead>
      <Description>{description}</Description>
      {children}
    </>
  );
};

type HousingLoanProps = {
  question: Question;
  children: ReactNode;
};

const HousingLoan: FC<HousingLoanProps> = ({ question, children }) => {
  const description = question.questionDescriptions.find(description => description.field == 'housing_loan')?.description;

  return (
    <>
      <Separator />
      <Lead>住宅ローン控除の申告書がありますか？</Lead>
      <Description>{description}</Description>
      {children}
    </>
  );
};

export const HasInsurances = Object.assign(MainSection, {
  LifeInsurance,
  EarthquakeInsurance,
  SocialInsurance,
  Premium,
  HousingLoan
});
