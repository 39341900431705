import React from 'react'
import Icon from 'react-icon-base'

const Scale = props => (
  <Icon viewBox="0 0 20 20" {...props}>
    <defs>
      <path id='a' d='M0 .158h6.848v10.037H0z' />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <path fill='currentColor' d='M14.424 19.368h-9.5a.85.85 0 1 1 0-1.699h9.5a.85.85 0 1 1 0 1.7' />
      <path fill='currentColor' d='M9.674 19.368a.85.85 0 0 1-.85-.85V6.852a.85.85 0 0 1 1.7 0v11.666c0 .47-.38.85-.85.85M1.448 5.87a.85.85 0 0 1-.133-1.69l16.667-2.666a.848.848 0 1 1 .268 1.677L1.583 5.857a.77.77 0 0 1-.135.012' />
      <g transform='translate(0 6.368)'>
        <mask id='b' fill='#fff'>
          <use xlinkHref='#a' />
        </mask>
        <path fill='currentColor' d='M3.424.158C1.533.158 0 5.685 0 7.29c0 1.603 1.533 2.905 3.424 2.905 1.89 0 3.424-1.302 3.424-2.905 0-1.605-1.533-7.132-3.424-7.132m0 1.922c.754 1.01 1.724 3.991 1.724 5.21 0 .641-.806 1.205-1.724 1.205-.918 0-1.724-.564-1.724-1.205 0-1.219.97-4.2 1.724-5.21' mask='url(#b)' />
      </g>
      <path fill='currentColor' d='M5.858 13.678H1.065a.85.85 0 1 1 0-1.7h4.793a.85.85 0 1 1 0 1.7M16.467 3.901c-1.891 0-3.424 5.527-3.424 7.132 0 1.603 1.533 2.905 3.424 2.905 1.89 0 3.424-1.302 3.424-2.905 0-1.605-1.533-7.132-3.424-7.132m0 1.922c.754 1.01 1.724 3.991 1.724 5.21 0 .641-.806 1.205-1.724 1.205-.918 0-1.724-.564-1.724-1.205 0-1.219.97-4.2 1.724-5.21' />
      <path fill='currentColor' d='M18.901 11.053h-4.793a.85.85 0 1 1 0-1.7h4.793a.85.85 0 1 1 0 1.7' />
      <path fill='#FFF' d='M7.426 3.183a2.334 2.334 0 1 0 4.667-.002 2.334 2.334 0 0 0-4.667.002' />
      <path fill='currentColor' d='M9.759 1.7c-.817 0-1.483.665-1.483 1.482 0 .818.666 1.484 1.483 1.484.817 0 1.483-.666 1.483-1.484 0-.817-.666-1.483-1.483-1.483m0 4.666a3.187 3.187 0 0 1-3.183-3.183A3.187 3.187 0 0 1 9.759 0a3.187 3.187 0 0 1 3.183 3.182 3.187 3.187 0 0 1-3.183 3.183' />
    </g>
  </Icon>
)
Scale.defaultProps = {
  size: 20
}

export default Scale
