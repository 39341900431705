import { FC } from 'react';
import { Premium as PremiumTemplate } from 'features/questions/templates/Premium';
import { useWizard } from '../WizardProvider';
import { FormSection } from 'components/form/FormSection';
import { InputWithText } from 'components/form/InputWithText';
import { FileField } from 'components/form/FileField';
import { FilePreview } from 'components/feature/FilePreview';
import { amountFormat } from '@jbc-year-end-adj/common/utils/formatter';

export const Premium: FC = () => {
  const { question, request } = useWizard();
  const premium = request.profile?.premium;

  const smallAndMediumSizedEnterprisesImage = premium?.smallAndMediumSizedEnterprisesImage;
  const corporatePensionImage = premium?.corporatePensionImage;
  const personalPensionImage = premium?.personalPensionImage;
  const disabilityMutualAidImage = premium?.disabilityMutualAidImage;

  return (
    <PremiumTemplate question={question}>
      {premium && (
        <FormSection>
          <InputWithText
            label="独立行政法人中小企業基盤整備機構の共済契約の掛金"
            text="円"
            value={amountFormat(premium.smallAndMediumSizedEnterprises)}
            disabled
          />

          <FileField
            label="独立行政法人中小企業基盤整備機構の共済契約の掛金 証明書画像"
            note="対応可能形式：jpg, jpeg, gif, png, pdf"
            disabled
          >
            {smallAndMediumSizedEnterprisesImage && (
              <FileField.FileContainer>
                <FileField.File file={smallAndMediumSizedEnterprisesImage} />
                <FilePreview file={smallAndMediumSizedEnterprisesImage} />
              </FileField.FileContainer>
            )}
          </FileField>

          <InputWithText
            label="確定拠出年金法に規定する企業型年金加入者掛金"
            text="円"
            value={amountFormat(premium.corporatePension)}
            disabled
          />

          <FileField label="確定拠出年金法に規定する企業型年金加入者掛金 証明書画像" note="対応可能形式：jpg, jpeg, gif, png, pdf" disabled>
            {corporatePensionImage && (
              <FileField.FileContainer>
                <FileField.File file={corporatePensionImage} />
                <FilePreview file={corporatePensionImage} />
              </FileField.FileContainer>
            )}
          </FileField>

          <InputWithText
            label="確定拠出年金法に規定する個人型年金加入者掛金"
            text="円"
            value={amountFormat(premium.personalPension)}
            disabled
          />

          <FileField label="確定拠出年金法に規定する個人型年金加入者掛金 証明書画像" note="対応可能形式：jpg, jpeg, gif, png, pdf" disabled>
            {personalPensionImage && (
              <FileField.FileContainer>
                <FileField.File file={personalPensionImage} />
                <FilePreview file={personalPensionImage} />
              </FileField.FileContainer>
            )}
          </FileField>

          <InputWithText
            label="心身障害者扶養共済制度に関する契約の掛金"
            text="円"
            value={amountFormat(premium.disabilityMutualAid)}
            disabled
          />

          <FileField label="心身障害者扶養共済制度に関する契約の掛金 証明書画像" note="対応可能形式：jpg, jpeg, gif, png, pdf" disabled>
            {disabilityMutualAidImage && (
              <FileField.FileContainer>
                <FileField.File file={disabilityMutualAidImage} />
                <FilePreview file={disabilityMutualAidImage} />
              </FileField.FileContainer>
            )}
          </FileField>
        </FormSection>
      )}
    </PremiumTemplate>
  );
};
