import React from 'react';
import styles from '../pages/EmployeeEdit.scss';
import Modal from '../components/Modal';
import Button from 'jbc-front/components/Button';

export const SaveRequest = ({ handleSubmit, submitting, isFixed, widthWide = null, status }) => {
  const isOpenModal = ['in_progress', 'rejected', 'accepted'].includes(status);

  return (
    <Modal>
      {({ isOpen, showModal, hideModal }) => (
        <>
          {isOpenModal ? (
            <>
              <Button onClick={showModal} widthWide={widthWide} hideModal={hideModal} disabled={submitting || isFixed}>
                {status == 'accepted' ? '入力済に戻す' : '一時保存'}
              </Button>

              <Modal.Modal isOpen={isOpen}>
                <Modal.Header>{status == 'accepted' ? 'ステータスの確認' : '入力依頼の取消'}</Modal.Header>
                <Modal.Body>{status == 'accepted' ? <IsAcceptedBody /> : <IsNotAcceptedBody />}</Modal.Body>
                <Modal.Footer>
                  <Modal.Buttons>
                    <Button onClick={hideModal}>キャンセル</Button>
                    <Button primary onClick={handleSubmit} disabled={submitting || isFixed}>
                      はい
                    </Button>
                  </Modal.Buttons>
                </Modal.Footer>
              </Modal.Modal>
            </>
          ) : (
            <Button onClick={handleSubmit} widthWide={widthWide} disabled={submitting || isFixed}>
              一時保存
            </Button>
          )}
        </>
      )}
    </Modal>
  );
};

const IsAcceptedBody = () => <>ステータスが「入力済」になりますがよろしいですか？</>;
const IsNotAcceptedBody = () => (
  <>
    従業員に入力依頼をしている場合、
    <br />
    一時保存すると入力依頼が取り消されてステータスが「入力済（再依頼中は「修正済」）」になります。
    <br />
    <span className={styles.alertText}>本当に保存しますか？</span>
  </>
);
