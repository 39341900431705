import React, { useState } from 'react';
import _ from 'lodash';
import { gql } from '@apollo/client';
import Button from 'jbc-front/components/Button';
import Modal from 'jbc-front/components/CommonModal';
import { Link } from 'jbc-front/components/icons';
import { Balloon, useQuery, ASYNC_TASK_FRAGMENT, useMutation, useYear } from '../components';
import { APPLICATIONS } from '../pages/ClientSetting';
import { BaloonItem } from '../top/Cooperation';
import { useNotify } from '../actions';
import styles from '../top/Cooperation.scss';

const EXPORT_EMPLOYEES_TO_SERVICE = gql`
  mutation exportEmployeesToService($service: String!, $year: Int!) {
    exportEmployeesToService(input: { service: $service, year: $year }) {
      clientYearly {
        id
        asyncTask {
          ...AsyncTaskFields
        }
      }
    }
  }
  ${ASYNC_TASK_FRAGMENT}
`;

export const renderCooperationButton = (toggle, title, isOpen, disabled = false, disabledReason) => (
  <Button
    {...(isOpen ? { className: 'ignore-react-onclickoutside' } : {})}
    onClick={toggle}
    icon={<Link size={17} />}
    widthAuto
    disabled={disabled}
    disabledReason={disabledReason}
  >
    {title}
    <span className={styles.triangle} />
  </Button>
);

const ExportModal = ({ isOpen, hideModal, onSubmit, submitting, serviceName }) => (
  <Modal isOpen={isOpen} hideModal={hideModal}>
    <Modal.Header hideModal={hideModal}>{`${serviceName}に従業員情報を反映`}</Modal.Header>
    <Modal.Body>
      <>
        年末調整時に登録された従業員情報で{serviceName}の従業員情報が上書き更新されます。
        <br />
        依頼ステータスが「完了」「対象外」の従業員のうち、スタッフコードが一致する全従業員について反映されますのでご注意ください。
        <br />
        ※扶養情報は来年の情報で反映されます
      </>
    </Modal.Body>
    <Modal.Footer>
      <Modal.Buttons>
        <Button onClick={hideModal} disabled={submitting}>
          キャンセル
        </Button>
        <Button primary onClick={onSubmit} disabled={submitting}>
          反映
        </Button>
      </Modal.Buttons>
    </Modal.Footer>
  </Modal>
);

export const Cooperation = ({ refetchEmployees, disabledNotTarget, disabledFixing }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [serviceName, setServiceName] = useState();
  const { data, loading: loadingQuery } = useQuery(APPLICATIONS);
  const [exportEmployeesToService, { loading }] = useMutation(EXPORT_EMPLOYEES_TO_SERVICE);
  const year = useYear();
  const notify = useNotify();
  if (loadingQuery) return null;
  const {
    client: { applications }
  } = data;
  const handleSubmit = async () => {
    try {
      await exportEmployeesToService({
        variables: {
          service: serviceName,
          year
        }
      });
    } catch (err) {
      const msg = _.get(err, 'graphQLErrors[0].message');
      notify(msg || err.message, 'error');
    } finally {
      setIsOpenModal(false);
    }
  };
  return (
    <span className="u-ml10">
      <Balloon
        center
        switchRender={renderCooperationButton}
        title="連携（反映）"
        disabled={disabledNotTarget || disabledFixing}
        disabledReason={disabledNotTarget && '反映させる従業員が存在していません'}
      >
        {hide => (
          <>
            <Balloon.Ul>
              {applications.map(application => (
                <BaloonItem
                  key={application.id}
                  name={`${application.name}に反映`}
                  onClick={() => {
                    setIsOpenModal(true);
                    setServiceName(application.name);
                    hide();
                  }}
                  disabled={loading || !application.connected}
                />
              ))}
              <BaloonItem
                name="連携項目を確認する"
                href="https://jobcan-payroll.zendesk.com/hc/ja/articles/900003088906"
                target="_blank"
                rel="noopener noreferrer"
              />
            </Balloon.Ul>
          </>
        )}
      </Balloon>
      <ExportModal isOpen={isOpenModal} hideModal={() => setIsOpenModal(false)} onSubmit={handleSubmit} serviceName={serviceName} />
    </span>
  );
};

export default Cooperation;
