import React from 'react'
import Icon from 'react-icon-base'

const Custom57 = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="icon/employees/Custom57" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path d="M24.4556646,4 C26.2357198,4 27.6787397,5.4430199 27.6787397,7.22307515 L27.6751142,7.3759078 L27.6751142,7.3759078 L27.6642456,7.52839661 L26,9.189 L26,8 C26,6.9456382 25.1841222,6.08183488 24.1492623,6.00548574 L24,6 L6,6 C4.8954305,6 4,6.8954305 4,8 L4,8 L4,34 C4,35.1045695 4.8954305,36 6,36 L6,36 L24,36 C25.1045695,36 26,35.1045695 26,34 L26,34 L26,21.924 L28,19.9267516 L28,34 C28,36.209139 26.209139,38 24,38 L6,38 C3.790861,38 2,36.209139 2,34 L2,8 C2,5.790861 3.790861,4 6,4 L24.4556646,4 Z" id="Combined-Shape" fill={props.color}></path>
      <path d="M31.0818012,2.70266087 C31.6340859,2.70266087 32.0818012,3.15037612 32.0818012,3.70266087 L32.0818012,20.1491561 C32.0818012,20.4666536 31.9310296,20.7652857 31.6755567,20.9538016 L27.1874923,24.265587 C26.8351952,24.5255504 26.3547514,24.5261083 26.0018515,24.2669638 L21.489916,20.9537203 C21.2333465,20.765314 21.0818012,20.4660137 21.0818012,20.1476981 L21.0818012,3.70266087 C21.0818012,3.15037612 21.5295164,2.70266087 22.0818012,2.70266087 L31.0818012,2.70266087 Z M30.0818012,4.70266087 L23.0818012,4.70266087 L23.0818012,20.0994427 L25.3016038,21.7087642 L27.873079,21.700731 L30.0818012,20.0994427 L30.0818012,4.70266087 Z" id="Combined-Shape" fill={props.color} transform="translate(26.581801, 13.581801) rotate(45.000000) translate(-26.581801, -13.581801) "></path>
      <rect id="Rectangle" fill={props.color} x="8" y="8" width="15" height="2" rx="1"></rect>
      <rect id="Rectangle-Copy-3" fill={props.color} x="8" y="12" width="12" height="2" rx="1"></rect>
      <path d="M9,16 L19,16 L19,16 L19,18 L9,18 C8.44771525,18 8,17.5522847 8,17 C8,16.4477153 8.44771525,16 9,16 Z" id="Rectangle-Copy-4" fill={props.color}></path>
      <rect id="Rectangle-Copy-5" fill={props.color} x="8" y="24" width="14" height="2" rx="1"></rect>
      <rect id="Rectangle-Copy-6" fill={props.color} x="8" y="28" width="14" height="2" rx="1"></rect>
      <rect id="Rectangle-Copy-7" fill={props.color} x="8" y="32" width="15" height="2" rx="1"></rect>
    </g>
  </Icon>
)
Custom57.defaultProps = {
  size: 30,
  color: "#3498DB"
}

export default Custom57
