import React from 'react'
import Icon from 'react-icon-base'

const ArrowDoublePrev = props => (
  <Icon viewBox="0 0 13 15" {...props}>
    <path fill='currentColor' fillRule='evenodd' d='M2.416 7.012l3.858 5.905-1.577 1.107L0 7.012 4.697 0l1.577 1.107-3.858 5.905zm6.106 0l3.858 5.905-1.577 1.107-4.697-7.012L10.803 0l1.577 1.107-3.858 5.905z' />
  </Icon>
)
ArrowDoublePrev.defaultProps = {
  size: 15
}

export default ArrowDoublePrev
