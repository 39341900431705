import React from 'react'
import Icon from 'react-icon-base'

const PlusCircle = props => (
  <Icon viewBox="0 0 17 17" {...props}>
    <g fill="none" fillRule="evenodd" transform="translate(1 1)">
      <circle cx="7.5" cy="7.5" r="7.5" stroke="currentColor" />
      <rect width="10" height="1.435" x="2.5" y="6.667" fill="currentColor" rx=".718" />
      <path
        fill="currentColor"
        d="M7.928 3.333v8.334c0 .46-.282.833-.63.833-.349 0-.631-.373-.631-.833V3.333c0-.46.282-.833.63-.833.349 0 .631.373.631.833z"
      />
    </g>
  </Icon>
)

PlusCircle.defaultProps = {
  size: 15
}

export default PlusCircle
