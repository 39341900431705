import { FC, ReactNode } from 'react';
import { Section } from 'components/ui/Section';

import { Question } from '../type';

import { Description } from './parts/Description';
import { Content } from './parts/Content';
import { ToggleImageContainer } from './parts/ToggleImageContainer';

import styles from './templates.scss';

// @ts-ignore
import image from '../../../../images/img-Dependent_Exemption_Form_BC.png';
// @ts-ignore
import image2 from '../../../../images/img-Basic_Consignment_Spouse2.png';

type DependentsProps = {
  question: Question;
  children: ReactNode;
  initiallyOpen?: boolean;
};

export const Dependents: FC<DependentsProps> = ({ question, children, initiallyOpen = false }) => {
  const description = question.questionDescriptions.find(description => description.field == 'top')?.description;

  return (
    <Section>
      <Section.Header className={styles.sectionHeader}>扶養親族情報</Section.Header>

      <Section.Body>
        <Content>
          <Description>{description}</Description>

          <Description>被扶養者（16歳未満も含む）の情報を入力してください。</Description>

          <ToggleImageContainer initiallyOpen={initiallyOpen}>
            <ToggleImageContainer.Header>
              主に「扶養控除等(異動)申告書」のB欄、C欄、「住民税に関する事項」、「所得金額調整控除等申告書」の判定に関する設問です。
            </ToggleImageContainer.Header>
            <ToggleImageContainer.Body>
              <img src={image} alt="扶養控除申告書_BC住" />
              <img src={image2} alt="R3基配所_所得金額調整控除申告書" />
            </ToggleImageContainer.Body>
          </ToggleImageContainer>

          {children}
        </Content>
      </Section.Body>
    </Section>
  );
};
