import { FC, useState } from 'react';
import useRouter from 'use-react-router';
import { useQuery } from '@apollo/client';

import { Loading } from 'jbc-front/components/presenters/ui/Loading';

import { Layout } from './components/Layout';
import { Profile } from './parts/Profile';
import { TabBar } from './parts/TabBar';
import { Result } from './parts/Result';
import { Progress } from './parts/Progress';
import { EmployeeInfo } from './parts/EmployeeInfo';
import { Question } from 'features/questions/type';
import { FETCH_EMPLOYEE, Employee, EmployeeAddressClassification } from './query';
import { DiffExplainColors } from './components/Diff';
import { Reports } from './parts/Reports';
import ActionButton from 'jbc-front/components/ActionButton';
import { MoveNotNeedAdjModal } from './MoveNotNeedAdjModal';
import { MoveNotRequestedModal } from './MoveNotRequestedModal';
// @ts-ignore
import { useSession } from 'components/SessionProvider';

export const Show: FC = () => {
  const {
    match: {
      params: { year, id }
    }
  } = useRouter();

  const { data } = useQuery(FETCH_EMPLOYEE, {
    fetchPolicy: 'network-only',
    variables: { id: String(id), year: Number(year) }
  });
  const employee = data?.client?.clientYearly?.employee;
  const employeeAddressClassification = data?.client?.clientYearly?.clientSetting?.employeeAddressClassification;
  const sendAcceptedEmail = data?.client?.clientYearly?.clientSetting?.sendAcceptedEmail;

  if (!employee || !employeeAddressClassification)
    return (
      <Layout>
        <Loading />
      </Layout>
    );

  return (
    <Content employee={employee} employeeAddressClassification={employeeAddressClassification} sendAcceptedEmail={sendAcceptedEmail} />
  );
};

type ContentProps = {
  employee: Employee;
  employeeAddressClassification: EmployeeAddressClassification;
  sendAcceptedEmail: boolean;
};

const Content: FC<ContentProps> = ({ employee, employeeAddressClassification, sendAcceptedEmail }) => {
  const defaultContent = ['applying', 'fixed'].includes(employee.status) ? 'result' : 'employee';
  const [content, setContent] = useState<'employee' | 'result' | 'progress'>(defaultContent);
  const { clientYearly } = useSession();

  // 進捗確認用
  const [question, setQuestion] = useState<Question>();
  const [answeringQuestionCode, setAnsweringQuestionCode] = useState<Question['code']>();
  const [isMoveNotNeedAdjModalOpen, setIsMoveNotNeedAdjModalOpen] = useState(false);
  const [isMoveRequestedModalOpen, setIsMoveRequestedModalOpen] = useState(false);

  return (
    <Layout>
      <Layout.MainContent>
        <TabBar content={content} setContent={setContent}>
          <TabBar.TabContainer>
            <TabBar.Tab content="employee">従業員情報</TabBar.Tab>
            <TabBar.Tab
              content="result"
              disabled={!['applying', 'fixed', 'rejected'].includes(employee.status)}
              disabledReason="ステータスが入力済、修正済、再依頼中のみ表示されます。"
            >
              結果承認
            </TabBar.Tab>
            <TabBar.Tab
              content="progress"
              disabled={!['in_progress', 'applying', 'rejected', 'fixed'].includes(employee.status)}
              disabledReason="ステータスが依頼中、入力済、修正済、再依頼中のみ表示されます。"
            >
              進捗状況
            </TabBar.Tab>
          </TabBar.TabContainer>
          <TabBar.ToolContainer>
            {content === 'result' && <DiffExplainColors />}
            {!clientYearly.fixed && content === 'employee' && (
              <>
                {employee.status === 'not_need_adj' ? (
                  <ActionButton dark onClick={() => setIsMoveRequestedModalOpen(true)}>
                    対象に戻す
                  </ActionButton>
                ) : (
                  <ActionButton dark onClick={() => setIsMoveNotNeedAdjModalOpen(true)}>
                    年末調整対象外にする
                  </ActionButton>
                )}
              </>
            )}
          </TabBar.ToolContainer>
        </TabBar>

        {content === 'employee' && (
          <EmployeeInfo.MainContent employee={employee} employeeAddressClassification={employeeAddressClassification} />
        )}
        {content === 'result' && (
          <Result.MainContent employee={employee} onChangeContent={() => setContent('employee')} sendAcceptedEmail={sendAcceptedEmail} />
        )}
        {content === 'progress' && employee.request && question && <Progress.MainContent request={employee.request} question={question} />}

        <MoveNotNeedAdjModal employee={employee} onClose={() => setIsMoveNotNeedAdjModalOpen(false)} isOpen={isMoveNotNeedAdjModalOpen} />
        {employee.status === 'not_need_adj' && (
          <MoveNotRequestedModal employee={employee} onClose={() => setIsMoveRequestedModalOpen(false)} isOpen={isMoveRequestedModalOpen} />
        )}
      </Layout.MainContent>
      <Layout.SideContent>
        <Profile employee={employee} />
        {content === 'result' && (
          <>
            <Result.SideContent employee={employee} />
            {['applying', 'fixed'].includes(employee.status) && <Reports employee={employee} />}
          </>
        )}
        {content === 'employee' && (
          <>
            <EmployeeInfo.SideContent />
            {employee.status === 'accepted' && <Reports employee={employee} />}
          </>
        )}
        {content === 'progress' && employee.request && (
          <Progress.SideContent
            request={employee.request}
            question={question}
            setQuestion={setQuestion}
            answeringQuestionCode={answeringQuestionCode}
            setAnsweringQuestionCode={setAnsweringQuestionCode}
          />
        )}
      </Layout.SideContent>
    </Layout>
  );
};
