import React from 'react';
import Button from 'jbc-front/components/Button';
import { Panel, Headding, Item, Buttons, Lead } from '../components/PrimaryPanel';
import { DescriptionEdit } from '../components/DescriptionEdit';
import { CustomDescription } from '../components/QuestionDescription';
import { FormPlaceholder } from '../components/FormPlaceholder';
import { ToggleDivImage } from '../components/ToggleDivImage';
import image from '../../images/img-Premium_Deduction_Life_Insurance.png';

const Question = () => {
  return (
    <Panel>
      <Headding icon={<DescriptionEdit />}>生命保険</Headding>
      <Item>
        <Lead>今年支払った生命保険料情報を記入して下さい</Lead>
        <CustomDescription field="top" />
        <ToggleDivImage title={'※赤枠に関する設問です。'}>
          <img src={image} alt="保険料控除_生命保険" />
        </ToggleDivImage>
        <FormPlaceholder />
        <Buttons>
          <Button primary disabled>
            次ヘ
          </Button>
        </Buttons>
      </Item>
    </Panel>
  );
};

export default Question;
