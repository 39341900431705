import React from 'react'
import Icon from 'react-icon-base'

const PhysicianIntroduction = props => (
  <Icon viewBox="0 0 20 20" {...props}>
    <g fill="currentColor" fillRule="evenodd">
      <path
        d="M10.535 0c3.455 0 6.256 2.66 6.256 5.94 0 2.069-1.115 3.891-2.807 4.954C17.52 12.275 20 15.44 20 19.137c0 .477-.407.863-.91.863-.501 0-.908-.386-.908-.863 0-3.996-3.652-7.259-8.182-7.259s-8.182 3.263-8.182 7.26c0 .476-.407.862-.909.862S0 19.614 0 19.137c0-3.938 2.814-7.272 6.721-8.49-1.486-1.085-2.443-2.79-2.443-4.708C4.278 2.66 7.08 0 10.535 0zm3.243 14.06c.276 0 .5.225.5.5v1.277h1.372c.276 0 .5.224.5.5v.523c0 .277-.224.5-.5.5h-1.373l.001 1.277c0 .276-.224.5-.5.5h-.604c-.276 0-.5-.224-.5-.5V17.36h-1.372c-.276 0-.5-.223-.5-.5v-.522c0-.277.224-.5.5-.5l1.371-.001V14.56c0-.276.225-.5.5-.5h.605zM10.535 1.727c-2.451 0-4.439 1.887-4.439 4.213 0 2.327 1.988 4.213 4.439 4.213 2.45 0 4.438-1.886 4.438-4.213 0-2.326-1.987-4.213-4.438-4.213z"
        transform="translate(-15 -317) translate(15 317)"
      />
    </g>
  </Icon>
)
PhysicianIntroduction.defaultProps = {
  size: 20
}

export default PhysicianIntroduction
