import { FC, ReactNode } from 'react';
import styles from './Section.scss';
import classnames from 'classnames';

type SectionProps = JSX.IntrinsicElements['div'] & {
  children: ReactNode;
};

export const Section: FC<SectionProps> = ({ children, className, ...divProps }) => {
  return (
    <div {...divProps} className={classnames(styles.section, className)}>
      {children}
    </div>
  );
};

type HeaderProps = JSX.IntrinsicElements['div'] & {
  children: ReactNode;
};

export const Header: FC<HeaderProps> = ({ children, className, ...divProps }) => {
  return (
    <div {...divProps} className={classnames(styles.header, className)}>
      {children}
    </div>
  );
};

type BodyProps = JSX.IntrinsicElements['div'] & {
  children: ReactNode;
};

export const Body: FC<BodyProps> = ({ children, className, ...divProps }) => {
  return (
    <div {...divProps} className={classnames(styles.body, className)}>
      {children}
    </div>
  );
};
