import { datadogRum } from '@datadog/browser-rum';

if (process.env.ENABLE_DATADOG) {
  datadogRum.init({
    applicationId: 'dc153b96-987e-47f0-accd-87cfa48f5620',
    clientToken: 'pub8f80a8930e108fe47214362311ca997e',
    site: 'ap1.datadoghq.com',
    service: 'yea',
    env: process.env.SERVER_ENV,
    version: process.env.COMMIT_REF,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [`${process.env.DATADOG_TRACING_ORIGIN}/graphql`]
  });
}
