import { yup } from '@jbc-year-end-adj/common/yup';

export type Schema = {
  clientCities: ClientCity[];
};

type ClientCity = {
  clientCityId: string;
  designationNumber: string;
};

export const schema = yup.object({
  clientCities: yup.array().of(
    yup.object({
      clientCityId: yup.string().required(),
      designationNumber: yup
        .string()
        .matches(/^[\uFF61-\uFF9F0-9a-zA-Z-]*$/, '半角カタカナ、半角英数字、半角ハイフンで入力してください')
        .max(15, '15文字以内で入力してください')
        .nullable()
    })
  )
});
