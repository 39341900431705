import { gql } from '@apollo/client';

export const SET_NOT_NEED_ADJ = gql`
  mutation setNotNeedAdj($input: SetNotNeedAdjInput!) {
    setNotNeedAdj(input: $input) {
      employee {
        id
        status
      }
    }
  }
`;
