import { FC } from 'react';
import { Dl, Dt, Dd, FileDd, FileDl } from '../../../../components/DefinitionList';
import { Grid } from '../../../../components/Grid';
import { PreviewSubSection } from '../../../../components/PreviewSubSection';
import { useEmployeeInfo } from '../../EmployeeInfoProvider';
import { dateFormat, amountFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { Section } from 'components/ui/Section';

export const LifeInsurance: FC = () => {
  const { employee } = useEmployeeInfo();
  const profile = employee.profile;

  const newOrOldFormat = (value?: 'new_type' | 'old_type') => {
    switch (value) {
      case 'new_type':
        return '新';
      case 'old_type':
        return '旧';
      default:
        return undefined;
    }
  };

  return (
    <Section>
      <Section.Header>生命保険</Section.Header>
      <Section.Body>
        <Grid colGap>
          {profile.generalLifeInsurances.length === 0 &&
            profile.nursingMedicalInsurances.length === 0 &&
            profile.individualAnnuityInsurances.length === 0 && <p>生命保険情報はありません。</p>}
          {profile.generalLifeInsurances.map((insurance, index) => (
            <PreviewSubSection key={index}>
              <PreviewSubSection.Header>一般の生命保険（{index + 1}）</PreviewSubSection.Header>
              <PreviewSubSection.Body>
                <Dl>
                  <Dt>保険会社等の名称</Dt>
                  <Dd>{insurance.name}</Dd>
                </Dl>

                <Dl>
                  <Dt>保険等の種類</Dt>
                  <Dd>{insurance.insuranceType}</Dd>
                </Dl>

                <Dl>
                  <Dt>保険期間</Dt>
                  <Dd>{insurance.insuranceDuration}</Dd>
                </Dl>

                <Dl>
                  <Dt>契約者氏名</Dt>
                  <Dd>{insurance.nameOfContractor}</Dd>
                </Dl>

                <Dl>
                  <Dt>受取人・氏名</Dt>
                  <Dd>{insurance.nameOfRecipient}</Dd>
                </Dl>

                <Dl>
                  <Dt>受取人・続柄</Dt>
                  <Dd>{insurance.relationshipOfRecipient}</Dd>
                </Dl>

                <Dl>
                  <Dt>新・旧の区分</Dt>
                  <Dd>{newOrOldFormat(insurance.newOrOld)}</Dd>
                </Dl>

                <Dl>
                  <Dt>保険料金額</Dt>
                  <Dd>{amountFormat(insurance.amount)}</Dd>
                </Dl>

                <FileDl>
                  <Dt>証明書画像</Dt>
                  <FileDd file={insurance.image} />
                </FileDl>
              </PreviewSubSection.Body>
            </PreviewSubSection>
          ))}

          {profile.nursingMedicalInsurances.map((insurance, index) => (
            <PreviewSubSection key={index}>
              <PreviewSubSection.Header>介護医療保険（{index + 1}）</PreviewSubSection.Header>
              <PreviewSubSection.Body>
                <Dl>
                  <Dt>保険会社等の名称</Dt>
                  <Dd>{insurance.name}</Dd>
                </Dl>

                <Dl>
                  <Dt>保険等の種類</Dt>
                  <Dd>{insurance.insuranceType}</Dd>
                </Dl>

                <Dl>
                  <Dt>保険期間</Dt>
                  <Dd>{insurance.insuranceDuration}</Dd>
                </Dl>

                <Dl>
                  <Dt>契約者氏名</Dt>
                  <Dd>{insurance.nameOfContractor}</Dd>
                </Dl>

                <Dl>
                  <Dt>受取人・氏名</Dt>
                  <Dd>{insurance.nameOfRecipient}</Dd>
                </Dl>

                <Dl>
                  <Dt>受取人・続柄</Dt>
                  <Dd>{insurance.relationshipOfRecipient}</Dd>
                </Dl>

                <Dl>
                  <Dt>保険料金額</Dt>
                  <Dd>{amountFormat(insurance.amount)}</Dd>
                </Dl>

                <FileDl>
                  <Dt>証明書画像</Dt>
                  <FileDd file={insurance.image} />
                </FileDl>
              </PreviewSubSection.Body>
            </PreviewSubSection>
          ))}

          {profile.individualAnnuityInsurances.map((insurance, index) => (
            <PreviewSubSection key={index}>
              <PreviewSubSection.Header>個人年金保険（{index + 1}）</PreviewSubSection.Header>
              <PreviewSubSection.Body>
                <Dl>
                  <Dt>保険会社等の名称</Dt>
                  <Dd>{insurance.name}</Dd>
                </Dl>

                <Dl>
                  <Dt>保険等の種類</Dt>
                  <Dd>{insurance.insuranceType}</Dd>
                </Dl>

                <Dl>
                  <Dt>年金支払期間</Dt>
                  <Dd>{insurance.insuranceDuration}</Dd>
                </Dl>

                <Dl>
                  <Dt>契約者氏名</Dt>
                  <Dd>{insurance.nameOfContractor}</Dd>
                </Dl>

                <Dl>
                  <Dt>受取人・氏名</Dt>
                  <Dd>{insurance.nameOfRecipient}</Dd>
                </Dl>

                <Dl>
                  <Dt>受取人・続柄</Dt>
                  <Dd>{insurance.relationshipOfRecipient}</Dd>
                </Dl>

                <Dl>
                  <Dt>支払開始日</Dt>
                  <Dd>{dateFormat(insurance.paymentStartDate)}</Dd>
                </Dl>

                <Dl>
                  <Dt>新・旧の区分</Dt>
                  <Dd>{newOrOldFormat(insurance.newOrOld)}</Dd>
                </Dl>

                <Dl>
                  <Dt>保険料金額</Dt>
                  <Dd>{amountFormat(insurance.amount)}</Dd>
                </Dl>

                <FileDl>
                  <Dt>証明書画像</Dt>
                  <FileDd file={insurance.image} />
                </FileDl>
              </PreviewSubSection.Body>
            </PreviewSubSection>
          ))}
        </Grid>
      </Section.Body>
    </Section>
  );
};
