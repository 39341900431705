import { FC, useEffect } from 'react';
import { Request } from '../../query';
import { Question } from 'features/questions/type';
import { useFetchQuestionGroups } from 'features/questions/hooks/useFetchQuestionGroups';
import { Sidebar } from 'features/questions/components/Sidebar';
import { ProgressBar } from 'features/questions/components/ProgressBar';
import { Wizard } from './wizards';
import { WizardProvider } from './WizardProvider';
import { Loading } from 'jbc-front/components/presenters/ui/Loading';
import styles from './Progress.scss';

type MainContentProps = {
  question: Question;
  request: Request;
};

const MainContent: FC<MainContentProps> = ({ request, question }) => {
  const { data } = useFetchQuestionGroups(request.id);
  const questionGroups = data?.questionGroups;

  if (!questionGroups) return <Loading />;

  return (
    <WizardProvider questionGroups={questionGroups} request={request} question={question} className={styles.wizardProvider}>
      <ProgressBar questionGroups={questionGroups} currentQuestion={question} />
      <Wizard currentQuestionCode={question?.code} />
    </WizardProvider>
  );
};

type SideContentProps = {
  request: Request;
  question?: Question;
  setQuestion: (question?: Question) => void;
  answeringQuestionCode?: Question['code'];
  setAnsweringQuestionCode: (code?: Question['code']) => void;
};

const SideContent: FC<SideContentProps> = ({ request, question, answeringQuestionCode, setQuestion, setAnsweringQuestionCode }) => {
  const { data } = useFetchQuestionGroups(request.id);
  const questionGroups = data?.questionGroups;

  useEffect(() => {
    if (questionGroups) {
      const questions = questionGroups.flatMap(questionGroup => questionGroup.questions);
      const answeringQuestion = questions.find(question => {
        return question.requestQuestion?.status === undefined || question.requestQuestion?.status === 'not_answered';
      });

      if (answeringQuestion) {
        setQuestion(answeringQuestion);
        setAnsweringQuestionCode(answeringQuestion?.code);
      } else {
        setQuestion(questions[questions.length - 1]);
        setAnsweringQuestionCode(questions[questions.length - 1]?.code);
      }
    }
  }, [questionGroups]);

  const handleChangeCode = (code: string) => {
    const questions = questionGroups?.flatMap(questionGroup => questionGroup.questions);
    const currentQuestion = questions?.find(question => question.code === code);
    setQuestion(currentQuestion);
  };

  if (!questionGroups) return null;

  return (
    <Sidebar
      questionGroups={questionGroups}
      currentQuestionCode={question?.code}
      onChangeCode={handleChangeCode}
      answeringQuestionCode={answeringQuestionCode}
      allActive={true}
    />
  );
};

export const Progress = {
  MainContent,
  SideContent
};
