import { FC } from 'react';
import { LifeInsurance } from './LifeInsurance';
import { EarthquakeInsurance } from './EarthquakeInsurance';
import { SocialInsurance } from './SocialInsurance';
import styles from './Preview.scss';

export const Preview: FC = () => {
  return (
    <div className={styles.preview}>
      <LifeInsurance />
      <EarthquakeInsurance />
      <SocialInsurance />
    </div>
  );
};
