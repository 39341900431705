import React from 'react'
import Icon from 'react-icon-base'

const EmployeeRegistration = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="iconNewDocumentCreation" fill="currentColor" strokeWidth="2.5" fillRule="evenodd">
      <g id="icon" transform="translate(16.868548, 23.057859) rotate(-45.000000) translate(-16.868548, -23.057859) translate(-3.911761, 11.557859)" fill="currentColor" fillRule="nonzero">
        <path d="M39.3106188,0 C40.5532595,0 41.5606188,1.00735931 41.5606188,2.25 L41.5606188,2.25 L41.5606188,20.75 C41.5606188,21.9926407 40.5532595,23 39.3106188,23 L39.3106188,23 L10.8895133,23 L0.683612929,13.1254762 C-0.209445515,12.2614145 -0.232952082,10.8369873 0.645203007,9.92959486 L0.645203007,9.92959486 L10.2353685,0.634287029 C10.6551199,0.227482628 11.2167109,0 11.8012458,0 L11.8012458,0 Z M39.0606188,2.5 L11.9016188,2.5 L7.81,6.464 L7.81,16.542 L11.9006188,20.5 L39.0606188,20.5 L39.06,16.25 L15.8181122,16.25 C15.1277563,16.25 14.5681122,15.6903559 14.5681122,15 C14.5681122,14.3527913 15.0599869,13.8204661 15.690307,13.7564536 L15.8181122,13.75 L39.06,13.75 L39.06,9.25 L15.8181122,9.25 C15.1277563,9.25 14.5681122,8.69035594 14.5681122,8 C14.5681122,7.35279131 15.0599869,6.8204661 15.690307,6.75645361 L15.8181122,6.75 L39.06,6.75 L39.0606188,2.5 Z" id="Combined-Shape"></path>
      </g>
    </g>
  </Icon>
)
EmployeeRegistration.defaultProps = {
  size: 40
}

export default EmployeeRegistration
