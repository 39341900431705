import { FC, useState } from 'react';
import Button from 'jbc-front/components/Button';

import { Section } from '@jbc-year-end-adj/2024/components/ui/Section';
import { Question } from '@jbc-year-end-adj/2024/features/questions/types';
import { TITLE, CheckHandicap, DetailInput } from '@jbc-year-end-adj/2024/features/questions/templates/Handicap';
import { ButtonContainer } from '@jbc-year-end-adj/2024/features/questions/components/ButtonContainer';
import { Content } from '@jbc-year-end-adj/2024/features/questions/templates/parts/Content';

import { EditModal } from '../EditModal';
import { EmptyBody } from '../EmptyBody';
import { filteredDescritpions } from './utils';
import styles from './Questions.scss';

type HandicapProps = {
  question: Question;
};

export const Handicap: FC<HandicapProps> = ({ question }) => {
  const [handicapType, setHandicapType] = useState<'none' | 'normal' | 'special'>('none');
  const descriptions = filteredDescritpions(question, ['under_note']);

  return (
    <Section>
      <Section.Header className={styles.header}>
        {TITLE}
        <EditModal questionId={String(question.id)} descriptions={descriptions} />
      </Section.Header>
      <Section.Body>
        <Content>
          <CheckHandicap question={question}>
            <ButtonContainer>
              <Button huge primary={handicapType === 'none'} onClick={() => setHandicapType('none')}>
                対象外
              </Button>
              <Button huge primary={handicapType === 'normal'} onClick={() => setHandicapType('normal')}>
                一般障害者
              </Button>
              <Button huge primary={handicapType === 'special'} onClick={() => setHandicapType('special')}>
                特別障害者
              </Button>
            </ButtonContainer>
          </CheckHandicap>

          {['normal', 'special'].includes(handicapType) && (
            <DetailInput>
              <EmptyBody />
              <ButtonContainer>
                <Button primary disabled>
                  次へ
                </Button>
              </ButtonContainer>
            </DetailInput>
          )}
        </Content>
      </Section.Body>
    </Section>
  );
};
