import React, { useState } from 'react';
import Button from 'jbc-front/components/Button';
import { useMutation, useQuery } from '../components/Graphql';
import { gql } from '@apollo/client';
import { FormSection, TextField, CheckboxField, DateField, AmountField } from '../components/FieldWithDiff';
import { Section, BoxDouble, BoxTriple } from 'jbc-front/components/Form';
import { toFormValues } from '../utils/form';
import { calcTotalSalaryIncome } from '../utils/income';
import {
  salaryEarnings,
  generalEarnings,
  generalEarningsAllowMinus,
  miscellaneousEarnings,
  retirementIncomeDeductionAmount,
  retirementEarnings,
  totalEarnings,
  parseNumber
} from '../utils/income';
import { amount, amountAllowMinus, maxLength } from '../validators';
import { YEARLY_INFO_FRAGMENT, DEPENDENT_FRAGMENT, DependentBasicInfo } from './Dependents';
import { SpouseYearlyInfo } from './SpouseYearlyInfo';
import { reduxForm, useFormValue, useAutofill } from '../components/FormName';
import AutoField from '../components/AutoField';
import { Panel, Headding, Item, Lead, Buttons, Text, Notes, Label } from '../components/PrimaryPanel';
import { LabelMapper } from 'jbc-front/components/FormErrors';
import Hint from 'jbc-front/components/Hint';
import LoadingPage from '../components/LoadingPage';
import useReactRouter from 'use-react-router';
import { CustomDescription } from '../components/QuestionDescription';
import styles from './Spouse.scss';
import { ToggleDivImage } from '../components/ToggleDivImage';
import image from '../../images/img-Dependent_Exemption_Form_AC.png';
import image2 from '../../images/img-Basic_Consignment_Spouse.png';
import { numberWithSplit } from '../utils/recordDisplay';
import classNames from 'classnames';

export const INCOME_FRAGMENT = gql`
  fragment Income on Income {
    id
    salaryIncome
    businessIncome
    businessExpense
    miscellaneousIncomeOfficalPension
    miscellaneousIncomeOther
    miscellaneousExpense
    dividendIncome
    dividendExpense
    realEstateIncome
    realEstateExpense
    retirementIncome
    lengthOfService
    retireForDisablity
    isOfficerRetirementAllowance
    otherIncome
    otherExpense
    otherExpenseSpecialDeduction
    otherEarings
    mainJobIncome
    sideJobIncome
    hasSideJob
  }
`;

export const SPOUSE_FRAGMENT = gql`
  fragment Spouse on Profile {
    id
    birthday
    hasSpouseDeductionThisYear
    hasSpouseDeduction
    income {
      ...Income
    }
    spouse {
      ...Dependent
      thisYear {
        ...YearlyInfo
      }
      nextYear {
        ...YearlyInfo
      }
      income {
        ...Income
      }
    }
  }
  ${INCOME_FRAGMENT}
  ${DEPENDENT_FRAGMENT}
  ${YEARLY_INFO_FRAGMENT}
`;

const SPOUSE = gql`
  query workingStudent {
    request {
      id
      employee {
        id
        year
      }
      profile {
        ...Spouse
        nextYearOnly
      }
    }
  }
  ${SPOUSE_FRAGMENT}
`;

const ANSWER_SPOUSE = gql`
  mutation answerSpouse($input: AnswerSpouseInput!) {
    answerSpouse(input: $input) {
      questions {
        id
        status
      }
      profile {
        ...Spouse
      }
    }
  }
  ${SPOUSE_FRAGMENT}
`;

const OtherSalaryIncomeCheckbox = (props, className) => (
  <div className={styles.OtherSalaryIncome}>
    <label>
      <input className={className} type="checkbox" {...props} />
      <div className="m-checkbox-parts">給与収入以外の収入を入力</div>
    </label>
  </div>
);

export const Income = ({
  title,
  field,
  birthday,
  year,
  diff = {},
  applyIncomeAdjustmentDeduction = false,
  needDisplayControl = false,
  withSideJobIncome = false,
  formerJobs = [],
  spouse = false
}) => {
  const {
    retirementIncome,
    salaryIncome,
    businessIncome,
    businessExpense,
    miscellaneousIncomeOfficalPension,
    miscellaneousIncomeOther,
    miscellaneousExpense,
    dividendIncome,
    dividendExpense,
    realEstateIncome,
    realEstateExpense,
    lengthOfService,
    isOfficerRetirementAllowance,
    retireForDisablity,
    otherEarings,
    mainJobIncome,
    sideJobIncome,
    hasBusinessIncome,
    hasMiscellaneousIncome,
    hasDividendIncome,
    hasRealEstateIncome,
    hasRetirementIncome,
    hasOtherIncome,
    hasSideJob
  } = useFormValue(field) || {};

  const hasSpouseDeduction = useFormValue('hasSpouseDeduction');
  const [otherSalaryIncome, setOtherSalaryIncome] = useState(false);
  const formerJobsPaymentAmount = numberWithSplit(
    formerJobs
      .map(v => v.paymentAmount)
      .map(v => parseNumber(v))
      .reduce((previous, current) => previous + current, 0)
  );
  const totalSalaryIncome = calcTotalSalaryIncome(mainJobIncome, sideJobIncome, formerJobsPaymentAmount);

  const calculatedMiscellaneousEarnings = miscellaneousEarnings(
    miscellaneousIncomeOfficalPension,
    miscellaneousIncomeOther,
    miscellaneousExpense,
    birthday,
    year,
    {
      salary: salaryEarnings(withSideJobIncome || hasSideJob ? totalSalaryIncome : salaryIncome, year, applyIncomeAdjustmentDeduction),
      business: generalEarningsAllowMinus(businessIncome, businessExpense),
      dividend: generalEarnings(dividendIncome, dividendExpense),
      realEstate: generalEarningsAllowMinus(realEstateIncome, realEstateExpense),
      retirement: retirementEarnings(
        retirementIncome,
        retirementIncomeDeductionAmount(lengthOfService, retireForDisablity, retirementIncome),
        isOfficerRetirementAllowance,
        lengthOfService,
        year
      ),
      other: otherEarings
    }
  );

  return (
    <>
      <Section title={`${title}給与収入`} style={{ maxWidth: '100%' }}>
        {!spouse ? (
          <>
            <BoxDouble>
              <AmountField
                name="mainJobIncome"
                label="給与所得 収入金額（当社）"
                validate={amount}
                description="前Stepで入力した前職情報は含みません"
                yen
                changed={diff.mainJobIncome}
              />
              <AmountField
                name="sideJobIncome"
                label=" 給与所得 収入金額（他社）"
                validate={amount}
                description="前Stepで入力した前職情報は含みません"
                yen
                changed={diff.sideJobIncome}
              />
              <AutoField label="給与所得 収入金額（前職）" validate={amount} yen value={formerJobsPaymentAmount} />
            </BoxDouble>
            <BoxDouble>
              <AutoField label="給与所得 収入金額（合計)" value={totalSalaryIncome} yen />
              <AutoField label="給与所得 所得金額" value={salaryEarnings(totalSalaryIncome, year, applyIncomeAdjustmentDeduction)} yen />
            </BoxDouble>
          </>
        ) : (
          <BoxDouble>
            <AmountField
              name="salaryIncome"
              label="給与所得 収入金額"
              description="2か所以上から給与支給がある場合は合算した金額を入力"
              validate={amount}
              yen
              changed={diff.salaryIncome}
            />
            <AutoField label="給与所得 所得金額" value={salaryEarnings(salaryIncome, year, applyIncomeAdjustmentDeduction)} yen />
          </BoxDouble>
        )}
      </Section>
      {hasSpouseDeduction && (
        <OtherSalaryIncomeCheckbox
          onChange={() => setOtherSalaryIncome(!otherSalaryIncome)}
          className={classNames('m-checkbox-input', otherSalaryIncome ? styles.open : styles.close)}
        />
      )}
      {(otherSalaryIncome || !hasSpouseDeduction) && (
        <Section title={`${title}給与収入以外の収入`} style={{ maxWidth: '100%' }} className={styles.spouse}>
          {(!needDisplayControl || hasBusinessIncome) && (
            <BoxTriple>
              <AmountField
                name="businessIncome"
                label="事業所得 収入金額"
                validate={amount}
                yen
                changed={diff.businessIncome}
                note="農業、林業、水産養殖業、製造業、卸売業、小売業、金融業などのサービス業のほか対価を得て継続的に行う事業による所得"
              />
              <AmountField
                name="businessExpense"
                label="事業所得 必要経費"
                validate={amount}
                yen
                changed={diff.businessExpense}
                note="収入を得るために必要な売上原価、販売費・一般管理費その他の費用"
              />
              <AutoField label="事業所得 所得金額" value={generalEarningsAllowMinus(businessIncome, businessExpense)} yen />
            </BoxTriple>
          )}
          {(!needDisplayControl || hasMiscellaneousIncome) && (
            <BoxDouble>
              <AmountField
                name="miscellaneousIncomeOfficalPension"
                label="雑所得 収入金額（公的年金等に係る雑所得）"
                validate={amount}
                yen
                changed={diff.miscellaneousIncomeOfficalPension}
                note="国民年金、厚生年金、共済年金などの公的年金等"
              />
              <AmountField
                name="miscellaneousIncomeOther"
                label="雑所得 収入金額（公的年金等以外の雑所得）"
                validate={amount}
                yen
                changed={diff.miscellaneousIncomeOther}
                note="原稿料や印税、講演料、放送出演料、貸金の利子、生命保険契約等に基づく年金など他のいずれの所得にも該当しない所得や恩給（一時恩給を除く）等"
              />
              <AmountField name="miscellaneousExpense" label="雑所得 必要経費" validate={amount} yen changed={diff.miscellaneousExpense} />
              <AutoField label="雑所得 所得金額" value={calculatedMiscellaneousEarnings} yen />
            </BoxDouble>
          )}
          {(!needDisplayControl || hasDividendIncome) && (
            <BoxTriple>
              <AmountField
                name="dividendIncome"
                label="配当所得 収入金額"
                note="株主や出資者が法人から受ける剰余金や、利益の配当、剰余金の分配、投資法人からの金銭の分配、投資信託（公社債投資信託及び公募公社債等運用投資信託以外のもの）及び特定受益証券発行信託の収益の分配などに係る所得"
                validate={amount}
                yen
                changed={diff.dividendIncome}
              />
              <AmountField
                name="dividendExpense"
                label="配当所得 必要経費"
                note="収入金額からその元本を取得するために要した負債の利子（株式等の取得のために借り入れた負債の利子のうち、その株式等の譲渡所得等に係るものを除きます。）"
                validate={amount}
                yen
                changed={diff.dividendExpense}
              />
              <AutoField label="配当所得 所得金額" value={generalEarnings(dividendIncome, dividendExpense)} yen />
            </BoxTriple>
          )}
          {(!needDisplayControl || hasRealEstateIncome) && (
            <BoxTriple>
              <AmountField
                name="realEstateIncome"
                label="不動産所得 収入金額"
                note="不動産の貸付けに際して受け取る権利金や頭金、更新料、名義書換料も不動産所得になります。"
                validate={amount}
                yen
                changed={diff.realEstateIncome}
              />
              <AmountField
                name="realEstateExpense"
                label="不動産所得 必要経費"
                note="貸し付けた不動産についての修繕費、損害保険料、租税公課、減価償却費、借入金利子等"
                validate={amount}
                yen
                changed={diff.realEstateExpense}
              />
              <AutoField label="不動産所得 所得金額" value={generalEarningsAllowMinus(realEstateIncome, realEstateExpense)} yen />
            </BoxTriple>
          )}
          {(!needDisplayControl || hasRetirementIncome) && (
            <div>
              <AmountField
                name="retirementIncome"
                label="退職所得 収入金額"
                note="退職手当、一時恩給その他の退職により一時に受ける給与などの所得のほか、社会保険制度等に基づく一時金など"
                validate={amount}
                yen
                changed={diff.retirementIncome}
              />
              {retirementIncome && +retirementIncome.replace(/,/g, '') > 0 && (
                <>
                  <TextField name="lengthOfService" label="勤続年数" validate={amount} changed={diff.lengthOfService} />
                  <CheckboxField
                    name="retireForDisablity"
                    label="障害者になったことに直接基因して退職した"
                    changed={diff.retireForDisablity}
                  />
                  <CheckboxField
                    name="isOfficerRetirementAllowance"
                    label="退職手当等が特定役員退職手当等に該当する"
                    changed={diff.isOfficerRetirementAllowance}
                  />
                </>
              )}
              <BoxDouble>
                <AutoField
                  label="退職所得控除額"
                  value={retirementIncomeDeductionAmount(lengthOfService, retireForDisablity, retirementIncome)}
                  yen
                />
                <AutoField
                  label="退職所得 所得金額"
                  value={retirementEarnings(
                    retirementIncome,
                    retirementIncomeDeductionAmount(lengthOfService, retireForDisablity, retirementIncome),
                    isOfficerRetirementAllowance,
                    lengthOfService,
                    year
                  )}
                  yen
                />
              </BoxDouble>
            </div>
          )}
          {(!needDisplayControl || hasOtherIncome) && (
            <BoxDouble>
              <AmountField
                name="otherIncome"
                label="その他所得 収入金額"
                note="⑴ 譲渡所得（土地、建物、機械、ゴルフ会員権、金地金、書画、骨とうなどの資産の譲渡による所得）
    ⑵ 山林所得（山林（所有期間５年超）の伐採又は譲渡による所得）
    ⑶ 一時所得（賞金や懸賞当せん金、競馬・競輪の払戻金（営利を目的とする継続的行為から生じたものを除きます。）、生命保険契約等に基づく一時金、損害保険契約等に基づく満期返戻金、遺失物拾得の報労金などによる所得）
    ⑷ 総合課税又は申告分離課税の対象となる利子所得
    （注） 利子所得のうち預貯金の利子などの源泉分離課税の対象となるもの及び特定公社債の利子などの申告分離課税の対象となるもので確定申告しないことを選択したものは、収入金額に含まれません。
    ⑸ 申告分離課税の適用を受けた一般株式等に係る譲渡所得等又は上場株式等に係る譲渡所得等（源泉徴収選択口座を通じて行った上場株式等の譲渡による所得等で、確定申告をしないことを選択した所得等は、収入金額に含まれません。）
    ⑹ 先物取引に係る雑所得等 "
                validate={amount}
                yen
                changed={diff.otherIncome}
              />
              <AmountField name="otherExpense" label="その他所得 必要経費" validate={amount} yen changed={diff.otherExpense} />
              <AmountField
                name="otherExpenseSpecialDeduction"
                label="その他所得 必要経費 うち特別控除額"
                validate={amount}
                yen
                changed={diff.otherExpenseSpecialDeduction}
              />
              <AmountField name="otherEarings" label="その他所得 所得金額" validate={amountAllowMinus} yen changed={diff.otherEarings} />
            </BoxDouble>
          )}
          <AutoField
            label="合計所得金額"
            value={totalEarnings(
              salaryEarnings(withSideJobIncome || hasSideJob ? totalSalaryIncome : salaryIncome, year, applyIncomeAdjustmentDeduction),
              generalEarningsAllowMinus(businessIncome, businessExpense),
              calculatedMiscellaneousEarnings,
              generalEarnings(dividendIncome, dividendExpense),
              generalEarningsAllowMinus(realEstateIncome, realEstateExpense),
              retirementEarnings(
                retirementIncome,
                retirementIncomeDeductionAmount(lengthOfService, retireForDisablity, retirementIncome),
                isOfficerRetirementAllowance,
                lengthOfService,
                year
              ),
              otherEarings
            )}
            yen
          />
        </Section>
      )}
    </>
  );
};

const QuestionForm =
  (({ handleSubmit, classification, gender, maritalStatus, submitting, nextYearOnly, year }) => {
    const [answerSpouse, { loading }] = useMutation(ANSWER_SPOUSE);
    const { history } = useReactRouter();
    const spouseBirthday = useFormValue('spouse.birthday');
    const hasSpouseDeduction = useFormValue('hasSpouseDeduction');
    const dependentThisYear = useFormValue('yearlyInfo.dependentTaxLawOrSpecial');
    const dependentNextYear = useFormValue('yearlyInfoNextYear.dependentTaxLaw');
    const { autofill } = useAutofill();
    return (
      <form onSubmit={handleSubmit}>
        <Lead>配偶者控除・配偶者特別控除等の対象ですか？</Lead>
        <Text>
          <div>以下のいずれか1つ以上のすべての条件に該当する方です。</div>
          <Notes>
            <Label>今年の配偶者控除または配偶者特別控除対象</Label>
            <ul>
              <li>
                ・従業員本人の合計所得金額が1,000万円以下（給与収入だけの場合、1,195万円以下）
                <Hint
                  text={
                    <div>
                      「合計所得金額」とは、各種損失の繰越控除を適用する前の総所得金額、譲渡所得、配当所得、雑所得、山林所得、退職所得の合計額をいいます。
                      <br />
                      「生計を一にする」とは、必ずしも同居を要件とするものではありません。例えば、別居している場合であっても、余暇には起居を共にすることを常例としている場合や、常に生活費等の送金が行われている場合には、「生計を一にする」ものとして取り扱われます。なお、親族が同一の家屋に起居している場合には、明らかに互いに独立した生活を営んでいると認められる場合を除き、「生計を一にする」ものとして取り扱われます。
                    </div>
                  }
                />
              </li>
              <li>・配偶者の合計所得金額が133万円以下</li>
              <li>・民法上の配偶者であり、生計を一にしている</li>
              <li>・配偶者が他の人の扶養親族ではない</li>
            </ul>
            <Label>今年の障害者控除対象</Label>
            <ul>
              <li>・配偶者が所得税法上の障害者に当てはまる</li>
              <li>・配偶者の合計所得金額が48万円以下</li>
              <li>・民法上の配偶者であり、生計を一にしている</li>
              <li>・配偶者が他の人の扶養親族ではない</li>
            </ul>
            <Label>来年の源泉控除対象配偶者</Label>
            <ul>
              <li>・従業員本人の来年の合計所得金額が900万円以下（予定）</li>
              <li>・配偶者の来年の合計所得金額が95万円以下（予定）</li>
              <li>・民法上の配偶者であり、生計を一にしている</li>
              <li>・配偶者が他の人の扶養親族ではない</li>
            </ul>
          </Notes>
          <CustomDescription field="under_note" as={Text} />
        </Text>
        <Buttons>
          <Button huge onClick={() => autofill('hasSpouseDeduction', true)} disabled={submitting || loading} primary={hasSpouseDeduction}>
            はい
          </Button>
          <Button
            huge
            onClick={async () => {
              await answerSpouse({ variables: { input: { hasSpouseDeduction: false } } });
              history.push('/employee_input');
            }}
            disabled={loading || submitting}
          >
            いいえ
          </Button>
        </Buttons>
        {hasSpouseDeduction && (
          <>
            <ToggleDivImage title={'主に「扶養控除等(異動)申告書」のA欄、C欄や「配偶者控除等申告書」欄等に反映されます。'}>
              <img src={image} alt="扶養控除申告書_AC" />
              <img src={image2} alt="基配所_配偶者" />
            </ToggleDivImage>
            <Section title="配偶者の基本情報" style={{ maxWidth: '100%' }}>
              <FormSection name="spouse">
                <LabelMapper name="spouse" label="配偶者情報の" />
                <DependentBasicInfo field="spouse" noRelation />
              </FormSection>
            </Section>
            <Section title="今年の扶養情報" style={{ maxWidth: '100%' }}>
              <FormSection name="yearlyInfo">
                <LabelMapper name="yearlyInfo" label="今年の扶養情報の" />
                <CheckboxField
                  name="dependentTaxLawOrSpecial"
                  label="今年の源泉控除対象、配偶者特別控除対象、または障害者控除対象"
                  disabled={nextYearOnly}
                />
                {dependentThisYear && <SpouseYearlyInfo field="yearlyInfo" isSpouse target="配偶者" hideIncome year={year} />}
              </FormSection>
            </Section>
            {dependentThisYear && (
              <FormSection name="spouseIncome">
                <LabelMapper name="spouseIncome" label="配偶者の収入" />
                <Income field="spouseIncome" birthday={spouseBirthday} title="配偶者の" year={year} spouse />
              </FormSection>
            )}
            <Section title="来年の扶養情報" style={{ maxWidth: '100%' }}>
              <FormSection name="yearlyInfoNextYear">
                <LabelMapper name="yearlyInfoNextYear" label="来年の扶養情報の" />
                <CheckboxField name="dependentTaxLaw" label="来年の源泉控除対象" />
                {dependentNextYear && (
                  <SpouseYearlyInfo
                    field="yearlyInfoNextYear"
                    isSpouse
                    target="配偶者"
                    withCopy={dependentThisYear}
                    thisYearField="yearlyInfo"
                    year={year + 1}
                    isNextYear
                  />
                )}
              </FormSection>
            </Section>
            {(dependentThisYear || dependentNextYear) && (
              <FormSection name="spouse">
                <DateField name="dependentFrom" label="源泉控除対象配偶者になった日" note="源泉控除対象配偶者の場合のみ記入してください" />
                <TextField
                  name="dependentReason"
                  label="源泉控除対象配偶者になった理由"
                  note="源泉控除対象配偶者の場合のみ記入してください"
                  validate={maxLength(24)}
                />
                <DateField name="diedOn" label="死亡日" />
              </FormSection>
            )}
            <Buttons>
              <Button primary onClick={handleSubmit} disabled={submitting}>
                次ヘ
              </Button>
            </Buttons>
          </>
        )}
      </form>
    );
  }) |> reduxForm({ form: 'spouse' });

const Question = ({ dispatch, handleSubmit }) => {
  const { loading, data } = useQuery(SPOUSE);
  const [answer] = useMutation(ANSWER_SPOUSE);
  const { history } = useReactRouter();
  if (loading) return <LoadingPage />;
  const {
    request: {
      profile,
      employee: { year }
    }
  } = data;
  return (
    <Panel>
      <Headding>配偶者控除情報</Headding>
      <Item>
        <QuestionForm
          initialValues={{
            spouse: { ...toFormValues(profile.spouse, true) },
            yearlyInfo: {
              dependentTaxLaw: false,
              ...toFormValues(profile.spouse?.thisYear, true),
              dependentTaxLawOrSpecial:
                profile.hasSpouseDeductionThisYear || ['normal', 'special'].includes(profile.spouse?.thisYear?.handicapType)
            },
            yearlyInfoNextYear: {
              dependentTaxLaw: false,
              ...toFormValues(profile.spouse?.nextYear, true)
            },
            spouseIncome: toFormValues(profile.spouse?.income, true),
            hasSpouseDeduction: profile.hasSpouseDeduction
          }}
          nextYearOnly={profile.nextYearOnly}
          year={year}
          onSubmit={async values => {
            await answer({
              variables: {
                input: values
              }
            });
            history.push('/employee_input');
          }}
        />
      </Item>
    </Panel>
  );
};

export default Question;
