import React from 'react'
import Icon from 'react-icon-base'

const Join = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g fill="none" stroke="currentColor" strokeWidth="2.5">
      <path d="M14.501 13.01a8.422 8.422 0 0 1 16.842 0 8.422 8.422 0 0 1-16.842 0z" strokeLinecap="round"/>
      <path d="M14.434 24.194a14.353 14.353 0 0 1 8.488-2.762c7.964 0 14.421 6.458 14.421 14.421" strokeLinecap="round"/>
      <path d="M22.922 22.125l-4.364 7.364 4.364 6.364 4.364-6.364z"/>
      <path d="M2.933 31.646h10.354M13.287 31.646l-4-4.353M13.287 31.646l-4 4.354" strokeLinecap="round"/>
    </g>
  </Icon>
)
Join.defaultProps = {
  size: 40
}

export default Join
