import React from 'react';
import Button from 'jbc-front/components/Button';
import { Modal } from '../components';

export const UnfixDataModal = ({ showModal, hideModal, onSubmit, disabled, ...taskRunningProps }) => (
  <>
    <Button onClick={showModal} disabled={disabled} {...taskRunningProps}>
      確定解除
    </Button>
    <Modal.Modal>
      <Modal.Header>確定解除</Modal.Header>
      <Modal.Body>
        情報が修正可能になり、一部帳票の出力ができなくなります。
        <br />
        年末調整の確定を解除しますか？
      </Modal.Body>
      <Modal.Footer>
        <Modal.Buttons>
          <Button primary as="button" onClick={onSubmit} disabled={disabled} {...taskRunningProps}>
            はい
          </Button>
          <Button onClick={hideModal}>いいえ</Button>
        </Modal.Buttons>
      </Modal.Footer>
    </Modal.Modal>
  </>
);
