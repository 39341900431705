import React from 'react'
import Icon from 'react-icon-base'

const UserSelect = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3">
      <path d="M23.917 13.045a8.117 8.117 0 1 0-16.234.002 8.117 8.117 0 0 0 16.234-.002z"/>
      <path d="M29.7 35.06c0-7.676-6.224-13.899-13.9-13.899-7.675 0-13.898 6.223-13.898 13.899M38.168 35.06c0-7.29-3.985-13.484-10.5-15.758 2.039-1.833 3.99-4.006 3.99-6.963 0-4.057-1.7-6.267-5.205-7.754"/>
    </g>
  </Icon>
)
UserSelect.defaultProps = {
  size: 40
}

export default UserSelect
