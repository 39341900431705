import React from 'react'
import Icon from 'react-icon-base'

const Expand = props => (
  <Icon viewBox="0 0 13 14" {...props}>
    <path d="M12.002 1.503A.503.503 0 0 0 11.5.999H.505A.503.503 0 0 0 0 1.503v10.994c0 .278.225.503.504.503H5.96a.503.503 0 1 0 0-1.007H1.008V2.006h9.987v4.952a.503.503 0 1 0 1.007 0V1.503zm.998 9.01a.503.503 0 1 0-1.007 0v1.768L8.127 8.415a.503.503 0 1 0-.712.712l3.867 3.866H9.513a.504.504 0 0 0 0 1.007h2.984a.503.503 0 0 0 .503-.503v-2.983z" fill="currentColor"/>
  </Icon>
)
Expand.defaultProps = {
  size: 14
}

export default Expand
