import { gql } from '@apollo/client';
import { useQuery, ApolloQueryResult } from '@apollo/client';
import { ReportName } from '../types';

const generateQuery = (reportName: ReportName) => gql`
  query report($employeeId: ID!, $showMyNumber: Boolean, $year: Int!) {
    client {
      id
      clientYearly(year: $year) {
        id
        employee(id: $employeeId) {
          reports(showMyNumber: $showMyNumber) {
            ${reportName}
            ${reportName}Url
          }
        }
      }
    }
  }
`;

type UseReport = (
  reportName: ReportName,
  employeeId: string,
  year: number,
  showMyNumber?: boolean
) => {
  report: string | undefined;
  url: string | undefined;
  loading: boolean;
  refetch: (
    variables?:
      | Partial<{
          employeeId: string;
          showMyNumber: boolean;
          year: number;
        }>
      | undefined
  ) => Promise<ApolloQueryResult<unknown>>;
};

export const useReport: UseReport = (reportName, employeeId, year, showMyNumber = false) => {
  const query = generateQuery(reportName);
  const { data, loading, refetch } = useQuery(query, {
    variables: {
      employeeId,
      showMyNumber,
      year
    },
    fetchPolicy: 'network-only'
  });

  return {
    report: data?.client?.clientYearly?.employee?.reports[reportName] as string | undefined,
    url: data?.client?.clientYearly?.employee?.reports[`${reportName}Url`] as string | undefined,
    loading,
    refetch
  };
};
