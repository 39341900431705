import { FC } from 'react';
import { fullNameFormat } from '@jbc-year-end-adj/common/utils/formatter';
import styles from './Profile.scss';
import { Employee } from '../../query';
import { STATUS, STATUS_COLORS } from '../../consts';
import { useRightExpansionContainer } from 'components/feature/RightExpansionContainer';
import { SideContent } from '../SideContent';
import { Comment } from 'jbc-front/components/icons';
import classnames from 'classnames';

// @ts-ignore
import Memo from 'components/Memo';

type ProfileProps = {
  employee: Employee;
};

export const Profile: FC<ProfileProps> = ({ employee }) => {
  const { lastName, firstName } = employee.profile;
  const { status } = employee;
  const { setContent } = useRightExpansionContainer();

  const hasComments = employee.request?.requestComments && employee.request.requestComments.length > 0;

  const handleClick = () => {
    setContent(<SideContent currentTab="comment" employee={employee} />, `comment-${employee.id}`);
  };

  const notNeedAdjReason = (() => {
    if (employee.taxSchedule === 'second') {
      return '乙欄に相当';
    } else if (employee.profile.resignBeforeYearEnd) {
      return '退職済（退職予定）';
    } else if (employee.hasNoPayment) {
      return '今年支払いなし';
    } else if (employee.profile.other) {
      return employee.profile.otherReason || 'その他';
    } else if (employee.salaryGreaterThan20Million) {
      return '2000万超';
    } else if (employee.profile.isDisasterSufferer) {
      return '災害者';
    } else {
      return '';
    }
  })();

  return (
    <div className={styles.container}>
      <div className={styles.mainContent}>
        <div className={styles.left}>
          <div className={styles.fullname}>{fullNameFormat(lastName, firstName)}</div>
          <div>{employee.staffCode}</div>
          <div>{employee.email}</div>
        </div>
        <div className={styles.right}>
          <Memo employee={employee} size={40} onClickOutside={false} />
          <i className={classnames(styles.commentIcon, hasComments && styles.hasComments)} onClick={handleClick}>
            {hasComments ? <span className={styles.redPoint}>●</span> : null}
            <Comment size={25} />
          </i>
        </div>
      </div>
      <div className={styles.subContent}>
        <span className={styles[`${STATUS_COLORS[status]}`]}>{STATUS[status]}</span>
        {STATUS[status] === '対象外' && <span className="u-ml5">{notNeedAdjReason}</span>}
      </div>
    </div>
  );
};
