import React from 'react'
import Icon from 'react-icon-base'

const Color = props => (
  <Icon width="20" height="20" viewBox="0 0 20 20" {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="#E61345" d="M10 10H0V3c0-1.65 1.35-3 3-3h7v10z" />
      <path fill="#3498DB" d="M10 10h10V3c0-1.65-1.35-3-3-3h-7v10z" />
      <path fill="#00C4AE" d="M10 10H0v7c0 1.65 1.35 3 3 3h7V10z" />
      <path fill="#333" d="M10 10h10v7c0 1.65-1.35 3-3 3h-7V10z" />
    </g>
  </Icon>
)

Color.defaultProps = {
  size: 20
}

export default Color
