import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { Note } from 'jbc-front/components/presenters/ui/Note';
import { ReadMoreReadLess } from 'jbc-front/components/presenters/ui/ReadMoreReadLess';

import { FormSection } from 'components/form/FormSection';
import { Radio } from 'components/form/Radio';
import { Section } from 'components/ui/Section';
import { FileField } from 'components/react-hook-form/FileField';
import { Textarea } from 'components/form/Textarea';

import { DifferentAddress } from './DifferentAddress';
import { DifferentAndForeignAddress } from './DifferentAndForeignAddress';
import { Income } from './Income';
import { Schema } from '../schema';
import { Grid } from '../../../../../components/Grid';
import { FormSeparator } from '../../../../../components/FormSeparator';
import { FilePreview } from 'components/feature/FilePreview';

type ThisYearType = {
  index: number;
};

export const ThisYear: FC<ThisYearType> = ({ index }) => {
  const {
    formState: { errors },
    register,
    setValue,
    watch
  } = useFormContext<Schema>();

  const dependentTaxLaw = watch(`otherFamilies.${index}.thisYear.dependentTaxLaw`);
  const incomeAdjustmentDeduction = watch(`otherFamilies.${index}.thisYear.incomeAdjustmentDeduction`);
  const residenceStatus = watch(`otherFamilies.${index}.thisYear.yearlyInfo.residenceStatus`);
  const handicapType = watch(`otherFamilies.${index}.thisYear.yearlyInfo.handicapType`);
  const handicapImage = watch(`otherFamilies.${index}.thisYear.yearlyInfo.handicapImage`);

  const otherFamilyErrors = errors.otherFamilies ? errors.otherFamilies[index] : undefined;

  return (
    <Section>
      <Section.Header>今年の税の扶養</Section.Header>
      <Section.Body>
        <FormSection>
          <Grid>
            <Radio.Container
              label="今年の税の扶養対象"
              required
              error={otherFamilyErrors?.thisYear && otherFamilyErrors?.thisYear.dependentTaxLaw?.message}
            >
              <Radio value="true" {...register(`otherFamilies.${index}.thisYear.dependentTaxLaw`)}>
                対象
              </Radio>
              <Radio value="false" {...register(`otherFamilies.${index}.thisYear.dependentTaxLaw`)}>
                対象外
              </Radio>
            </Radio.Container>
          </Grid>

          {dependentTaxLaw === 'false' && (
            <>
              <Grid>
                <Radio.Container
                  label="今年の税の扶養対象ではないが、所得金額調整控除の対象、退職手当等を有する扶養親族"
                  required
                  error={otherFamilyErrors?.thisYear && otherFamilyErrors?.thisYear.incomeAdjustmentDeduction?.message}
                >
                  <Radio value="true" {...register(`otherFamilies.${index}.thisYear.incomeAdjustmentDeduction`)}>
                    対象
                  </Radio>
                  <Radio value="false" {...register(`otherFamilies.${index}.thisYear.incomeAdjustmentDeduction`)}>
                    対象外
                  </Radio>
                </Radio.Container>
              </Grid>
              <Note>
                <ReadMoreReadLess maxLength={19}>
                  同一生計内の他の所得者に扶養される年齢23歳未満、もしくは特別障害者に該当する扶養親族は、
                  本人の収入が850万円を超えている場合に所得金額調整控除の対象になります。詳しくは
                  <a
                    className="u-txt-link"
                    href="https://www.nta.go.jp/taxes/shiraberu/taxanswer/shotoku/1411.htm"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    国税庁HP
                  </a>
                  をご覧ください。
                  <br />
                  扶養控除等(異動)申告書の「退職手当等を有する配偶者・扶養親族」に反映されます。
                  所得税の計算上では扶養対象外でも住民税の計算上は扶養対象となる場合があります。
                </ReadMoreReadLess>
              </Note>
            </>
          )}

          {(dependentTaxLaw === 'true' || incomeAdjustmentDeduction === 'true') && (
            <>
              <FormSeparator />

              <Radio.Container label="同居・別居" required error={otherFamilyErrors?.thisYear?.yearlyInfo?.residenceStatus?.message}>
                <Radio value="same_address" {...register(`otherFamilies.${index}.thisYear.yearlyInfo.residenceStatus`)}>
                  同居
                </Radio>
                <Radio value="different_address" {...register(`otherFamilies.${index}.thisYear.yearlyInfo.residenceStatus`)}>
                  別居（国内）
                </Radio>
                <Radio value="different_and_foreign_address" {...register(`otherFamilies.${index}.thisYear.yearlyInfo.residenceStatus`)}>
                  別居（国外）
                </Radio>
              </Radio.Container>

              {residenceStatus === 'different_address' && <DifferentAddress index={index} />}
              {residenceStatus === 'different_and_foreign_address' && <DifferentAndForeignAddress index={index} />}

              <FormSeparator />

              <Grid>
                <Radio.Container label="障害者区分" required error={otherFamilyErrors?.thisYear?.yearlyInfo?.handicapType?.message}>
                  <Radio value="none" {...register(`otherFamilies.${index}.thisYear.yearlyInfo.handicapType`)}>
                    対象外
                  </Radio>
                  <Radio value="normal" {...register(`otherFamilies.${index}.thisYear.yearlyInfo.handicapType`)}>
                    一般障害者
                  </Radio>
                  <Radio value="special" {...register(`otherFamilies.${index}.thisYear.yearlyInfo.handicapType`)}>
                    特別障害者
                  </Radio>
                </Radio.Container>
                {['normal', 'special'].includes(handicapType) && (
                  <>
                    <Textarea label="詳細" rows={3} {...register(`otherFamilies.${index}.thisYear.yearlyInfo.handicapDetail`)} />
                    <FileField label="障害者手帳画像" name={`otherFamilies.${index}.thisYear.yearlyInfo.handicapImage`}>
                      {handicapImage && (
                        <FileField.FileContainer>
                          <FileField.File
                            file={handicapImage}
                            onRemove={() => setValue(`otherFamilies.${index}.thisYear.yearlyInfo.handicapImage`, null)}
                          />
                          <FilePreview file={handicapImage} />
                        </FileField.FileContainer>
                      )}
                    </FileField>
                  </>
                )}
              </Grid>

              <FormSeparator />
              <Income index={index} />
            </>
          )}
        </FormSection>
      </Section.Body>
    </Section>
  );
};
