import React, { useState, createContext, useContext } from 'react';
import styles from './DropDownMenu.scss';
import onClickOutside from 'react-onclickoutside';
import classnames from 'classnames';

const MenuItem = ({ onClick, children }) => {
  const { setIsOpen } = useContext(Context);
  return (
    <li
      role="menuitem"
      onClick={e => {
        onClick(e);
        setIsOpen(false);
      }}
      onKeyPress={e => {
        onClick(e);
        setIsOpen(false);
      }}
    >
      {children}
    </li>
  );
};

const MenuItems = onClickOutside(
  ({ menuItems, closeMenu, children }) => {
    MenuItems.handleClickOutside = closeMenu;
    return (
      <div className="dd-menu-items">
        <ul key="items" className="dd-items-right">
          {children}
        </ul>
      </div>
    );
  },
  {
    handleClickOutside: () => MenuItems.handleClickOutside
  }
);

const Context = createContext({ isOpen: false, setIsOpen: () => null });

export const DropDownMenu = ({ children, toggle, as = 'div', ...rest }) => {
  const [isOpen, setIsOpen] = useState(false);
  const As = as;
  return (
    <Context.Provider value={{ isOpen, setIsOpen }}>
      <As className={styles.dropdown} {...rest}>
        <div className={classnames('dd-menu dd-menu-right', { 'ignore-react-onclickoutside': isOpen })}>
          {toggle(setIsOpen)}
          {isOpen && <MenuItems closeMenu={() => setIsOpen(false)}>{children}</MenuItems>}
        </div>
      </As>
    </Context.Provider>
  );
};

DropDownMenu.MenuItem = MenuItem;
DropDownMenu.MenuItems = MenuItems;

export default DropDownMenu;
