import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { reducer as notificationsReducer } from 'reapop';
import { reducer as formErrors } from 'jbc-front/components/FormErrors';
import { connectRouter } from 'connected-react-router';

const rootReducer = history =>
  combineReducers({
    notifications: notificationsReducer(),
    router: connectRouter(history),
    form,
    formErrors
  });

export default rootReducer;
