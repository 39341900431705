import { yup } from '@jbc-year-end-adj/common/yup';
import { HousingLoan, HousingLoanDetail } from '../../../../query';
import { FileType } from '@jbc-year-end-adj/types';
import { dateFormFormat, amountFormat } from '@jbc-year-end-adj/common/utils/formatter';

type DeductionClassificationType =
  | 'normal'
  | 'ceritified'
  | 'extension'
  | 'earthquake'
  | 'special_case_house_normal'
  | 'special_case_house_certified'
  | 'special_case_house_earthquake';

type SpecialDeductionType = 'none' | 'applied' | 'special_applied' | 'special_exception_applied';

export type Schema = {
  housingLoan?: HousingLoanSchema;
};

export type HousingLoanSchema = {
  housingLoanDeductionApplicableAmount: string;
  image?: File | FileType;
  certificateImage?: File | FileType;
  firstDetail: HousingLoanDetailSchema;
  secondDetail?: HousingLoanDetailSchema;
};

export type HousingLoanDetailSchema = {
  residenceStartOn: string;
  deductionClassification: DeductionClassificationType;
  specialDeduction: SpecialDeductionType;
  yearEndBalance: string;
};

const housingLoandDetailSchema = yup.object({
  residenceStartOn: yup
    .string()
    .required()
    .dateFormat()
    .label('居住開始年月日'),
  deductionClassification: yup
    .mixed<DeductionClassificationType>()
    .required()
    .label('住宅借入金等特別控除区分'),
  specialDeduction: yup
    .mixed<SpecialDeductionType>()
    .required()
    .label('住宅借入金等特別控除区分'),
  yearEndBalance: yup
    .string()
    .required()
    .positiveAmountFormat()
    .maxAmount(999999999)
    .label('住宅借入金等 年末残高')
});

const housingLoanSchema = yup.object({
  housingLoanDeductionApplicableAmount: yup
    .string()
    .required()
    .positiveAmountFormat()
    .maxAmount(999999999)
    .label('住宅借入金等特別控除額'),
  image: yup.mixed<File | FileType>().nullable(),
  certificateImage: yup.mixed<File | FileType>().nullable(),
  firstDetail: housingLoandDetailSchema.required(),
  secondDetail: housingLoandDetailSchema.default(undefined)
});

export const schema = yup.object({
  housingLoan: housingLoanSchema.default(undefined)
});

export const generateDefaultValues = (housingLoan: HousingLoan | undefined) => {
  const firstDetail = housingLoan?.housingLoanDetails?.find(detail => detail.no === 1);
  const secondDetail = housingLoan?.housingLoanDetails?.find(detail => detail.no === 2);

  if (housingLoan) {
    return {
      housingLoan: {
        housingLoanDeductionApplicableAmount: amountFormat(housingLoan.housingLoanDeductionApplicableAmount),
        image: housingLoan.image,
        certificateImage: housingLoan.certificateImage,
        firstDetail: housingLoanDetailDefaultValues(firstDetail),
        secondDetail: housingLoanDetailDefaultValues(secondDetail)
      }
    };
  } else {
    return { housingLoan: undefined };
  }
};

const housingLoanDetailDefaultValues = (housingLoanDetail: HousingLoanDetail | undefined) => {
  if (housingLoanDetail === undefined) return undefined;

  return {
    residenceStartOn: dateFormFormat(housingLoanDetail.residenceStartOn, 'L'),
    deductionClassification: housingLoanDetail.deductionClassification,
    specialDeduction: housingLoanDetail.specialDeduction,
    yearEndBalance: amountFormat(housingLoanDetail.yearEndBalance)
  };
};

export const housingLoanDetailObj: HousingLoanDetailSchema = {
  residenceStartOn: '',
  deductionClassification: 'normal',
  specialDeduction: 'none',
  yearEndBalance: ''
};

export const housingLoanObj: HousingLoanSchema = {
  housingLoanDeductionApplicableAmount: '',
  image: undefined,
  certificateImage: undefined,
  firstDetail: housingLoanDetailObj,
  secondDetail: undefined
};
