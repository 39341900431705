import React from 'react'
import Icon from 'react-icon-base'

const Adress = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="symbol" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <g id="icon_adress" stroke="currentColor" strokeWidth="2.5">
        <g id="Page-1" transform="translate(6.764706, 5.000000)">
          <path d="M26.0157814,12.1865016 C26.0701875,20.9840908 13.3746738,30 13.3746738,30 C13.3746738,30 0.800287453,21.6132169 0.733566256,12.1865016 C0.685934493,5.45684011 6.39317754,0 13.3746738,0 C20.3561701,0 25.9741634,5.45678396 26.0157814,12.1865016 Z" id="Stroke-1"></path>
          <path d="M17.5136777,12.5753171 C17.5136777,14.6600486 15.7604041,16.6308414 13.599023,16.6308414 C11.4369514,16.6308414 9.68367775,14.6600486 9.68367775,12.5753171 C9.68367775,10.4905857 11.4369514,8.80153197 13.599023,8.80153197 C15.7604041,8.80153197 17.5136777,10.4905857 17.5136777,12.5753171 Z" id="Stroke-3"></path>
        </g>
      </g>
    </g>
  </Icon>
)
Adress.defaultProps = {
  size: 40
}

export default Adress
