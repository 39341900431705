import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { autofill, getFormValues, reset, getFormMeta } from 'redux-form';
import { MainFormControls } from 'jbc-front/components/SearchForm';
import compose from 'lodash/fp/compose';
import moment from 'moment';
import useReactRouter from 'use-react-router';
import _ from 'lodash';

import { useFormName, reduxForm } from '../FormName/FormNameProvider';
import { useSession } from '../AdminSessionProvider';
import { useAdminQuery as useQuery } from '@jbc-year-end-adj/2024/hooks/graphql/useQuery';

import AdditionalSearchFields from './AdditionalSearchFields';
import SortingFieldsWithResult from './SortingFieldsWithResult';
import styles from './SearchForm.scss';
import {
  convertFormToQueryString,
  convertQueryToForm,
  getCurrentQueryFromLocation,
  saveDisplayEmployeeLimit,
  getSavedDisplayEmployeeLimit,
  hasConditions
} from './utils';

const SearchForm = ({ handleSubmit, query, variables, statusField }) => {
  const [isExpand, setIsExpand] = useState(false);
  const dispatch = useDispatch();
  const { formName } = useFormName();
  const { location, history } = useReactRouter();
  const clearForm = () => {
    dispatch(reset(formName));
    history.push({ ...location, search: '' });
  };
  const { data } = useQuery(query, { variables });
  const formValues = useSelector(getFormValues(formName));
  const {
    location: { pathname }
  } = useReactRouter();
  const { me } = useSession();
  const totalCount = _.get(data, 'client.clientYearly.employees.totalCount', 0);

  return (
    <div className={styles.searchForm}>
      <form onSubmit={handleSubmit}>
        <div className={styles.mainSearchFields}>
          {statusField ? statusField(handleSubmit) : <div />}
          <MainFormControls
            isExpand={isExpand}
            hasConditions={hasConditions(formValues)}
            handleClear={clearForm}
            onToggleClick={() => {
              setIsExpand(isExpand => !isExpand);
            }}
          />
        </div>
        {isExpand && <AdditionalSearchFields />}
        <SortingFieldsWithResult
          limit={getSavedDisplayEmployeeLimit(pathname, me)}
          onChangeLimit={newLimit => {
            saveDisplayEmployeeLimit(pathname, me, newLimit);
            handleSubmit();
          }}
          onChangeSortType={handleSubmit}
          count={totalCount}
          sortName="sortType"
        />
      </form>
    </div>
  );
};

const validate = values => {
  const errors = {};
  if (values.age_from && values.age_to && +values.age_from > +values.age_to) {
    errors.age_to = errors.age_from = 'の範囲が正しくありません';
  }
  ['joined_at', 'retired_at', 'birthday'].forEach(prefix => {
    if (
      [values[`${prefix}_start`], values[`${prefix}_end`]].every(date => date && moment(date, 'YYYY/MM/DD').isValid()) &&
      moment(values[`${prefix}_start`], 'YYYY/MM/DD').diff(moment(values[`${prefix}_end`], 'YYYY/MM/DD'), 'days') > 0
    ) {
      errors[`${prefix}_start`] = errors[`${prefix}_end`] = 'の範囲が正しくありません';
    }
  });
  return errors;
};

export const searchDetector = ({ convertQueryToForm, getCurrentQueryFromLocation, convertFormToQueryString }) => SearchForm => props => {
  const { location, history } = useReactRouter();
  const { me } = useSession();
  const handleSearch = values => {
    const search = convertFormToQueryString(values);
    history.push({ ...location, search });
  };
  const query = getCurrentQueryFromLocation(location, me);
  return <SearchForm initialValues={convertQueryToForm(query)} {...props} handleSearch={handleSearch} />;
};

export default formName =>
  compose(
    searchDetector({ convertFormToQueryString, convertQueryToForm, getCurrentQueryFromLocation }),
    reduxForm({
      form: formName,
      enableReinitialize: true,
      validate,
      onSubmit(values, dispatch, { handleSearch }) {
        dispatch((dispatch, getState) => {
          const autofilled = getFormMeta(formName)(getState())?.page?.autofilled;
          if (!autofilled) {
            dispatch(autofill(formName, 'page', 1));
          }
          handleSearch(getFormValues(formName)(getState()));
        });
      }
    })
  )(SearchForm);
