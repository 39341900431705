import { FormField } from 'components/form/FormField';
import { Input } from 'components/form/Input';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { Checkbox } from 'jbc-front/components/presenters/form/Checkbox';
import { Note } from 'jbc-front/components/presenters/ui/Note';
import { FileField } from 'components/react-hook-form/FileField';
import { Schema } from '../schema';
import { Grid } from '../../../../../components/Grid';
import { FilePreview } from 'components/feature/FilePreview';
import { AmountField } from 'components/react-hook-form/AmountField';

type DifferentAndForeignAddressType = {
  index: number;
};

export const DifferentAndForeignAddress: FC<DifferentAndForeignAddressType> = ({ index }) => {
  const {
    formState: { errors },
    register,
    watch,
    setValue
  } = useFormContext<Schema>();

  const isNonResident = watch(`otherFamilies.${index}.nextYear.yearlyInfo.isNonResident`);
  const relatedToRelativesDocument = watch(`otherFamilies.${index}.nextYear.yearlyInfo.relatedToRelativesDocument`);
  const relatedToRemittanceDocument = watch(`otherFamilies.${index}.nextYear.yearlyInfo.relatedToRemittanceDocument`);
  const otherFamilyErrors = errors.otherFamilies ? errors.otherFamilies[index] : undefined;
  const isStudyAbroad = watch(`otherFamilies.${index}.nextYear.yearlyInfo.isStudyAbroad`);
  const provingStudyAbroadDocument = watch(`otherFamilies.${index}.nextYear.yearlyInfo.provingStudyAbroadDocument`);

  return (
    <>
      <Grid>
        <Input label="Address" {...register(`otherFamilies.${index}.nextYear.yearlyInfo.addressForeign`)} />
      </Grid>

      <Grid colGap>
        <FormField>
          <Checkbox {...register(`otherFamilies.${index}.nextYear.yearlyInfo.isNonResident`)}>非居住者</Checkbox>
          <FormField.NoteContainer>
            <Note>扶養親族が国内に住所を有せず、かつ、現在まで引き続き1年以上居所を有しない場合チェックしてください。</Note>
          </FormField.NoteContainer>
        </FormField>

        {isNonResident && (
          <>
            <FormField>
              <Checkbox {...register(`otherFamilies.${index}.nextYear.yearlyInfo.isStudyAbroad`)}>留学</Checkbox>
              <FormField.NoteContainer>
                <Note>扶養親族が非居住者のうち留学の場合はチェックしてください。</Note>
              </FormField.NoteContainer>
            </FormField>

            <AmountField
              label="国外居住親族への送金額"
              note="扶養親族が非居住者である場合、本年中にその扶養親族に送金等をした金額の合計を記入してください。"
              error={otherFamilyErrors?.nextYear?.yearlyInfo?.remittance?.message}
              isError={!!otherFamilyErrors?.nextYear?.yearlyInfo?.remittance?.message}
              {...register(`otherFamilies.${index}.nextYear.yearlyInfo.remittance`)}
            />
          </>
        )}
      </Grid>

      {isNonResident && (
        <>
          <Grid>
            <div>
              <h3 className="u-mb10">添付ファイル</h3>
              <p>
                国外居住親族に係る扶養控除等を受けようとする場合は関係書類（例：親族関係書類、送金関係書類、留学証明書類）を添付してください。
              </p>
            </div>
          </Grid>

          <Grid colGap>
            <FileField label="親族関係書類" name={`otherFamilies.${index}.nextYear.yearlyInfo.relatedToRelativesDocument`}>
              {relatedToRelativesDocument && (
                <FileField.FileContainer>
                  <FileField.File
                    file={relatedToRelativesDocument}
                    onRemove={() => setValue(`otherFamilies.${index}.nextYear.yearlyInfo.relatedToRelativesDocument`, null)}
                  />
                  <FilePreview file={relatedToRelativesDocument} />
                </FileField.FileContainer>
              )}
            </FileField>

            <FileField label="送金関係書類" name={`otherFamilies.${index}.nextYear.yearlyInfo.relatedToRemittanceDocument`}>
              {relatedToRemittanceDocument && (
                <FileField.FileContainer>
                  <FileField.File
                    file={relatedToRemittanceDocument}
                    onRemove={() => setValue(`otherFamilies.${index}.nextYear.yearlyInfo.relatedToRemittanceDocument`, null)}
                  />
                  <FilePreview file={relatedToRemittanceDocument} />
                </FileField.FileContainer>
              )}
            </FileField>

            {isStudyAbroad && (
              <FileField label="留学関係書類" name={`otherFamilies.${index}.nextYear.yearlyInfo.provingStudyAbroadDocument`}>
                {provingStudyAbroadDocument && (
                  <FileField.FileContainer>
                    <FileField.File
                      file={provingStudyAbroadDocument}
                      onRemove={() => setValue(`otherFamilies.${index}.nextYear.yearlyInfo.provingStudyAbroadDocument`, null)}
                    />
                    <FilePreview file={provingStudyAbroadDocument} />
                  </FileField.FileContainer>
                )}
              </FileField>
            )}
          </Grid>
        </>
      )}
    </>
  );
};
