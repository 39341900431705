import React from 'react'
import Icon from 'react-icon-base'

const Custom4 = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="Custom4" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon/employees/Custom4" fill="#3498DB" fillRule="nonzero">
        <g id="Group-2" transform="translate(1.277460, 2.821310)">
          <path d="M19.1532149,13.7553878 C15.819233,13.7553878 13.1157337,16.4588872 13.1157337,19.7928691 C13.1157337,23.126851 15.819233,25.8303503 19.1532149,25.8303503 C22.4871968,25.8303503 25.1906962,23.126851 25.1906962,19.7928691 C25.1906962,16.4588872 22.4871968,13.7553878 19.1532149,13.7553878 Z M19.1532149,15.7553878 C21.3826273,15.7553878 23.1906962,17.5634567 23.1906962,19.7928691 C23.1906962,22.0222815 21.3826273,23.8303503 19.1532149,23.8303503 C16.9238025,23.8303503 15.1157337,22.0222815 15.1157337,19.7928691 C15.1157337,17.5634567 16.9238025,15.7553878 19.1532149,15.7553878 Z" id="Stroke-1"></path>
          <path d="M19.1532149,23.8303503 C24.4694457,23.8303503 28.7799016,28.1408062 28.7799016,33.457037 C28.7799016,34.0093217 28.3321863,34.457037 27.7799016,34.457037 C27.2276168,34.457037 26.7799016,34.0093217 26.7799016,33.457037 C26.7799016,29.2453757 23.3648762,25.8303503 19.1532149,25.8303503 C14.9415536,25.8303503 11.5265283,29.2453757 11.5265283,33.457037 C11.5265283,34.0093217 11.078813,34.457037 10.5265283,34.457037 C9.97424351,34.457037 9.52652826,34.0093217 9.52652826,33.457037 C9.52652826,28.1408062 13.8369841,23.8303503 19.1532149,23.8303503 Z" id="Stroke-3"></path>
          <path d="M30.8685171,15.7761975 L26.3685171,24.7761975 C26.0693116,25.3746085 26.5044577,26.0786898 27.1735016,26.0786898 L36.1735016,26.0786898 C36.8425454,26.0786898 37.2776915,25.3746085 36.978486,24.7761975 L32.478486,15.7761975 C32.1468142,15.1128538 31.2001889,15.1128538 30.8685171,15.7761975 Z M31.6725397,18.1906898 L34.7165397,24.2786898 L28.6285397,24.2786898 L31.6725397,18.1906898 Z" id="Triangle"></path>
          <path d="M9.72253967,16.9617025 L2.72253967,16.9617025 C2.2254834,16.9617025 1.82253967,17.3646462 1.82253967,17.8617025 L1.82253967,24.8617025 C1.82253967,25.3587587 2.2254834,25.7617025 2.72253967,25.7617025 L9.72253967,25.7617025 C10.2195959,25.7617025 10.6225397,25.3587587 10.6225397,24.8617025 L10.6225397,17.8617025 C10.6225397,17.3646462 10.2195959,16.9617025 9.72253967,16.9617025 Z M8.82253967,18.7616898 L8.82253967,23.9616898 L3.62253967,23.9616898 L3.62253967,18.7616898 L8.82253967,18.7616898 Z" id="Rectangle" transform="translate(6.222540, 21.361702) rotate(45.000000) translate(-6.222540, -21.361702) "></path>
          <path d="M9.45853967,5.63068976 L6.31327224,6.08844998 L6.20045591,6.11186127 C5.54634285,6.29013797 5.30842885,7.13029587 5.81448021,7.62357402 L8.09053967,9.84268976 L7.55363519,12.9761427 L7.54132755,13.0850242 C7.50442622,13.7651552 8.23223828,14.2546671 8.8594897,13.9249015 L11.6725397,12.4446898 L14.4875134,13.9249015 L14.5872627,13.970253 C15.2227025,14.2155203 15.9131623,13.6745974 15.7933679,12.9761427 L15.2555397,9.84168976 L17.5325229,7.62357402 L17.6100325,7.53832212 C18.0344326,7.00961523 17.7330767,6.19007087 17.0337309,6.08844998 L13.8875397,5.63068976 L12.480564,2.78038062 C12.1504317,2.11145948 11.1965714,2.11145948 10.8664391,2.78038062 L9.45853967,5.63068976 Z M11.6735397,5.21168976 L12.4828485,6.85220217 L12.5375816,6.94787481 C12.6779174,7.1616482 12.9039958,7.30726818 13.1604925,7.34453934 L14.9695397,7.60668976 L13.6606965,8.88400877 L13.5769416,8.97791844 C13.423921,9.17576521 13.3589388,9.4303802 13.4018595,9.68062705 L13.7105397,11.4826898 L12.0923086,10.6320715 L11.9771135,10.5814355 C11.7416641,10.4970423 11.4794302,10.5139209 11.2546946,10.6320715 L9.63553967,11.4826898 L9.94514364,9.68062705 L9.95770405,9.55542265 C9.96520891,9.30541799 9.86812165,9.06123458 9.68630667,8.88400877 L8.37653967,7.60668976 L10.1865106,7.34453934 C10.4796498,7.30194373 10.7330588,7.1178313 10.8641546,6.85220217 L11.6735397,5.21168976 Z" id="Star" transform="translate(11.673502, 8.154486) rotate(30.000000) translate(-11.673502, -8.154486) "></path>
          <path d="M25.6444948,1.45057447 L20.8892123,4.9054895 C20.5737736,5.1346691 20.4417813,5.54089969 20.5622681,5.91172009 L22.3786244,11.50189 C22.4991113,11.8727104 22.8446717,12.1237747 23.2345753,12.1237747 L29.1124278,12.1237747 C29.5023315,12.1237747 29.8478918,11.8727104 29.9683787,11.50189 L31.784735,5.91172009 C31.9052219,5.54089969 31.7732295,5.1346691 31.4577909,4.9054895 L26.7025083,1.45057447 C26.3870696,1.22139486 25.9599335,1.22139486 25.6444948,1.45057447 Z M26.1725397,3.29068976 L29.8705397,5.97668976 L28.4575397,10.3236898 L23.8875397,10.3236898 L22.4755397,5.97668976 L26.1725397,3.29068976 Z" id="Polygon"></path>
        </g>
      </g>
    </g>
  </Icon>
)
Custom4.defaultProps = {
  size: 30
}

export default Custom4