import React from 'react'
import Icon from 'react-icon-base'

const Custom49 = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="icon/employees/Custom49" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path d="M20,2 C29.9411255,2 38,10.0588745 38,20 C38,29.9411255 29.9411255,38 20,38 C10.0588745,38 2,29.9411255 2,20 C2,10.0588745 10.0588745,2 20,2 Z M20,5 C11.7157288,5 5,11.7157288 5,20 C5,28.2842712 11.7157288,35 20,35 C28.2842712,35 35,28.2842712 35,20 C35,11.7157288 28.2842712,5 20,5 Z" id="Combined-Shape" fill={props.color}></path>
      <rect id="Rectangle" fill={props.color} transform="translate(20.000000, 20.000000) rotate(-45.000000) translate(-20.000000, -20.000000) " x="18.5" y="3" width="3" height="34"></rect>
    </g>
  </Icon>
)
Custom49.defaultProps = {
  size: 30,
  color: "#3498DB"
}

export default Custom49
