import { QuestionGroup } from './type';

const GROUP_NO_MAP = {
  check_qualified_person: 1,
  check_basic_info: 2,
  check_dependent: 3,
  check_insurance_and_loan: 4,
  check_attachment: 5
};

export const findQuestionGroup = (questionGroups: QuestionGroup[], groupName: keyof typeof GROUP_NO_MAP): QuestionGroup | undefined => {
  const no = GROUP_NO_MAP[groupName];
  return questionGroups.find(group => group.no === no);
};
