import { yup } from '@jbc-year-end-adj/common/yup';
import { ClientSetting } from '../../query';

export type Schema = {
  requestPrint: 'true' | 'false';
  requestImageUploadSettings: RequestImageUploadSettings;
  employeeAddressClassification: ClientSettingEmployeeAddressClassification;
  sendAcceptedEmail: 'true' | 'false';
  hasSubmissionDeadline: 'true' | 'false';
  submissionDeadlineDate?: string;
  submissionDeadlineHour?: string;
  useReminder?: boolean;
  reminders?: Reminder[];
};

const IMAGE_UPLOAD_STATUS = ['none', 'optional', 'required'] as const;
type ImageUploadStatus = typeof IMAGE_UPLOAD_STATUS[number];

export type RequestImageUploadSettings = {
  lifeInsurance: ImageUploadStatus;
  earthquakeInsurance: ImageUploadStatus;
  pensionInsurance: ImageUploadStatus;
  socialInsurance: ImageUploadStatus;
  premium: ImageUploadStatus;
  housingLoan: ImageUploadStatus;
  studentIdCard: ImageUploadStatus;
  handicap: ImageUploadStatus;
  nonResidentAndStudyAbroad: ImageUploadStatus;
  previousWithholdingSlip: ImageUploadStatus;
};

export type ClientSettingEmployeeAddressClassification = 'resident_card' | 'profile';

export type Reminder = {
  remindDaysBefore: string;
  remindHour: string;
};

const reminderSchema = yup.object({
  remindDaysBefore: yup
    .string()
    .required()
    .label('日数'),
  remindHour: yup
    .string()
    .required()
    .label('時間')
});

export const schema = yup.object({
  requestPrint: yup.mixed<'true' | 'false'>().required(),
  requestImageUploadSettings: yup
    .object({
      lifeInsurance: yup
        .mixed<ImageUploadStatus>()
        .oneOf(IMAGE_UPLOAD_STATUS)
        .required(),
      earthquakeInsurance: yup
        .mixed<ImageUploadStatus>()
        .oneOf(IMAGE_UPLOAD_STATUS)
        .required(),
      pensionInsurance: yup
        .mixed<ImageUploadStatus>()
        .oneOf(IMAGE_UPLOAD_STATUS)
        .required(),
      socialInsurance: yup
        .mixed<ImageUploadStatus>()
        .oneOf(IMAGE_UPLOAD_STATUS)
        .required(),
      premium: yup
        .mixed<ImageUploadStatus>()
        .oneOf(IMAGE_UPLOAD_STATUS)
        .required(),
      housingLoan: yup
        .mixed<ImageUploadStatus>()
        .oneOf(IMAGE_UPLOAD_STATUS)
        .required(),
      studentIdCard: yup
        .mixed<ImageUploadStatus>()
        .oneOf(IMAGE_UPLOAD_STATUS)
        .required(),
      handicap: yup
        .mixed<ImageUploadStatus>()
        .oneOf(IMAGE_UPLOAD_STATUS)
        .required(),
      nonResidentAndStudyAbroad: yup
        .mixed<ImageUploadStatus>()
        .oneOf(IMAGE_UPLOAD_STATUS)
        .required(),
      previousWithholdingSlip: yup
        .mixed<ImageUploadStatus>()
        .oneOf(IMAGE_UPLOAD_STATUS)
        .required()
    })
    .required(),
  employeeAddressClassification: yup
    .mixed<ClientSettingEmployeeAddressClassification>()
    .oneOf(['resident_card', 'profile'])
    .required(),
  sendAcceptedEmail: yup.mixed<'true' | 'false'>().required(),
  hasSubmissionDeadline: yup.mixed<'true' | 'false'>().required(),
  submissionDeadlineDate: yup
    .string()
    .label('年末調整 提出期限')
    .when('hasSubmissionDeadline', {
      is: (value: 'true' | 'false') => value === 'true',
      then: schema => schema.dateFormat().required(),
      otherwise: schema => schema.nullable()
    }),
  submissionDeadlineHour: yup
    .string()
    .label('年末調整 提出期限(時間)')
    .when('hasSubmissionDeadline', {
      is: (value: 'true' | 'false') => value === 'true',
      then: schema => schema.required(),
      otherwise: schema => schema.nullable()
    }),
  useReminder: yup.boolean().nullable(),
  reminders: yup
    .array()
    .of(reminderSchema)
    .test('unique', '提出期日設定のリマインド日時が重複しています', function(reminders) {
      if (!reminders) return true;

      const targetReminders = reminders.slice(0, reminders.length - 1);
      return targetReminders.every((targetReminder, index) => {
        return !reminders
          .slice(index + 1)
          .some(
            reminder => reminder.remindDaysBefore === targetReminder.remindDaysBefore && reminder.remindHour === targetReminder.remindHour
          );
      });
    })
});

export const buildDefaultValues = (clientSetting?: ClientSetting): Schema => {
  const reminders =
    clientSetting?.submissionDeadline?.reminders.map(reminder => ({
      remindDaysBefore: String(reminder.remindDaysBefore),
      remindHour: String(reminder.remindHour)
    })) || [];

  if (reminders?.length === 0) {
    reminders.push({ remindDaysBefore: '1', remindHour: '9' });
  }

  if (clientSetting) {
    return {
      requestPrint: clientSetting.requestPrint ? 'true' : 'false',
      requestImageUploadSettings: clientSetting.requestImageUploadSettings,
      employeeAddressClassification: clientSetting.employeeAddressClassification,
      sendAcceptedEmail: clientSetting.sendAcceptedEmail ? 'true' : 'false',
      hasSubmissionDeadline: clientSetting.hasSubmissionDeadline ? 'true' : 'false',
      submissionDeadlineDate: clientSetting.submissionDeadline?.submissionDeadlineDate,
      submissionDeadlineHour: clientSetting.submissionDeadline?.submissionDeadlineHour,
      useReminder: clientSetting.submissionDeadline?.useReminder,
      reminders
    };
  } else {
    return {
      requestPrint: 'true',
      requestImageUploadSettings: {
        lifeInsurance: 'optional',
        earthquakeInsurance: 'optional',
        pensionInsurance: 'optional',
        socialInsurance: 'optional',
        premium: 'optional',
        housingLoan: 'optional',
        studentIdCard: 'optional',
        handicap: 'optional',
        nonResidentAndStudyAbroad: 'optional',
        previousWithholdingSlip: 'optional'
      },
      employeeAddressClassification: 'resident_card',
      sendAcceptedEmail: 'true',
      hasSubmissionDeadline: 'false',
      submissionDeadlineDate: undefined,
      submissionDeadlineHour: undefined,
      useReminder: undefined,
      reminders
    };
  }
};
