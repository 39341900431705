import { FC, ReactNode, useContext, createContext } from 'react';
import { FETCH_SESSION, Me, Client, Office, ClientYearly, QueryResult, YearMaster } from './query';
import { useAdminQuery as useQuery } from '@jbc-year-end-adj/2024/hooks/graphql/useQuery';
import { Loading } from 'jbc-front/components/presenters/ui/Loading';
import { SimpleLayout } from '@jbc-year-end-adj/2024/components/layout/SimpleLayout';
import { setTelemetryUser } from '@jbc-year-end-adj/common/telemetry';

type SessionContextType = {
  me: Me | undefined;
  client: Client | undefined;
  office: Office | undefined;
  clientYearly: ClientYearly | undefined;
  clientYearlies: ClientYearly[];
  defaultYear: number | undefined;
  isCurrentYear: boolean | undefined;
  yearMasters: YearMaster[];
};

const Context = createContext<SessionContextType>({
  me: undefined,
  client: undefined,
  office: undefined,
  clientYearly: undefined,
  clientYearlies: [],
  defaultYear: undefined,
  isCurrentYear: undefined,
  yearMasters: []
});

type AdminSessionProvider = {
  children: ReactNode;
};

export const AdminSessionProvider: FC<AdminSessionProvider> = ({ children }) => {
  const { loading, data } = useQuery<QueryResult>(FETCH_SESSION);

  if (loading) {
    return <Loading />;
  }

  // TODO: dataがなかった場合のエラーハンドリング
  if (!data) {
    return null;
  }

  const { me, client, defaultYear, yearMasters } = data;

  const { clientYearlies, clientYearly } = client;

  if (me.application.name) {
    setTelemetryUser({
      id: `yea-user${me.id}`,
      customer_code: me.customerCode,
      application: { id: me.application.id, name: me.application.name }
    });
  }

  if (!clientYearly) {
    return (
      <SimpleLayout>
        <div className="l-title-wrap" />
        <div className="l-wrap-xs">
          <div className="l-box-message">
            <p className="m-title-sub u-pt0 u-ta-c">年末調整が開始されていません</p>
          </div>
        </div>
      </SimpleLayout>
    );
  }

  const { office } = clientYearly;
  const isCurrentYear = clientYearly.year === defaultYear;

  return (
    <Context.Provider value={{ me, client, office, clientYearly, clientYearlies, defaultYear, isCurrentYear, yearMasters }}>
      {children}
    </Context.Provider>
  );
};

export const useSession = () => useContext(Context);
