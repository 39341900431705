import { FC, ReactNode } from 'react';
import styles from './Anchor.scss';
import classnames from 'classnames';
import { Pulldown } from 'jbc-front/components/icons';
import { useCurrentAnchor, AnchorLink } from 'jbc-front/components/ScrollSpy/v2';

type AnchorProps = {
  name: string;
  children: ReactNode;
};

export const Anchor: FC<AnchorProps> = ({ name, children }) => {
  const current = useCurrentAnchor();

  return (
    <AnchorLink name={name} className={classnames([styles.anchor, { [styles.activeScroll]: current === name }])}>
      {children}
      {current === name && (
        <div className={styles.arrow}>
          <Pulldown size={10} color="#ccc" />
        </div>
      )}
    </AnchorLink>
  );
};
