import React from 'react'
import Icon from 'react-icon-base'

const MinusCircle = props => (
  <Icon viewBox="0 0 13 13" {...props}>
    <g>
      <g>
        <path d="M6.572,12.854c-3.545,0-6.43-2.881-6.43-6.423C0.142,2.885,3.027,0,6.572,0C10.117,0,13,2.885,13,6.431&#xA;&#x9;&#x9;&#x9;C13,9.973,10.117,12.854,6.572,12.854z M6.572,1.2c-2.884,0-5.23,2.346-5.23,5.23c0,2.88,2.346,5.224,5.23,5.224&#xA;&#x9;&#x9;&#x9;c2.883,0,5.229-2.344,5.229-5.224C11.801,3.546,9.455,1.2,6.572,1.2z" fill="currentColor"/>
      </g>
      <g>
        <path id="a" d="M4.213,5.885H8.93c0.354,0,0.643,0.288,0.643,0.644l0,0c0,0.356-0.289,0.643-0.643,0.643H4.213&#xA;&#x9;&#x9;&#x9;c-0.354,0-0.643-0.287-0.643-0.643l0,0C3.57,6.173,3.858,5.885,4.213,5.885z" fill="currentColor"/>
      </g>
    </g>
  </Icon>
)
MinusCircle.defaultProps = {
  size: 13
}

export default MinusCircle
