import { gql } from '@apollo/client';
import { FileType } from '@jbc-year-end-adj/types';
import { Schema } from './schema';

export type QueryVariables = {
  input: {
    employeeId: string;
    attributes: {
      attachments: RequestAttachment[];
    };
  };
};

type RequestAttachment = {
  id?: number;
  file?: File | FileType;
};

export const convertFormDataToQueryVariables = (employeeId: string, data: Schema): QueryVariables => {
  return {
    input: {
      employeeId,
      attributes: {
        attachments: data.attachments
      }
    }
  };
};

export const UPDATE_REQUEST_ATTACHMENTS = gql`
  mutation UpdateRequestAttachments($input: UpdateRequestAttachmentsInput!) {
    updateRequestAttachments(input: $input) {
      employee {
        id
      }
    }
  }
`;
