import React from 'react';
import { renderInputField } from 'jbc-front/components/Form';
import styles from 'jbc-front/components/Form.scss';

const AutoField = ({ value, InputField = renderInputField, ...props }) => (
  <div className={styles.boxWrap}>
    <InputField meta={{}} input={{ value }} disabled type="text" {...props} />
  </div>
);

export default AutoField;
