import { gql } from '@apollo/client';
import { FileType } from '@jbc-year-end-adj/types';
import { Schema } from './schema';

export type QueryVariables = {
  input: {
    employeeId: string;
    attributes: {
      workingStudentClassification: 'none' | 'working_student';
      workingStudentDetail?: string;
      workingStudentImage?: File | FileType;
      handicapClassification: 'none' | 'normal' | 'special';
      handicapDetail?: string;
      handicapImage?: File | FileType;
      widowClassification: 'none' | 'normal' | 'special' | 'single_parent';
      isDisasterSufferer: boolean;
      taxPostponementAmount?: string;
    };
  };
};

export const convertFormDataToQueryVariables = (employeeId: string, data: Schema): QueryVariables => {
  return {
    input: {
      employeeId,
      attributes: {
        workingStudentClassification: data.workingStudentClassification.classification,
        workingStudentDetail: data.workingStudentClassification.detail,
        workingStudentImage: data.workingStudentClassification.image,
        handicapClassification: data.handicapClassification.classification,
        handicapDetail: data.handicapClassification.detail,
        handicapImage: data.handicapClassification.image,
        widowClassification: data.widowClassification.classification,
        isDisasterSufferer: data.isDisasterSufferer === 'disasterSufferer',
        taxPostponementAmount: data.taxPostponementAmount || '0'
      }
    }
  };
};

export const UPDATE_TAX_CLASSIFICATIONS = gql`
  mutation UpdateTaxClassifications($input: UpdateTaxClassificationsInput!) {
    updateTaxClassifications(input: $input) {
      employee {
        id
      }
    }
  }
`;
