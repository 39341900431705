import { FC } from 'react';
import { FormerJobs as FormerJobsTemplate } from 'features/questions/templates/FormerJobs';
import { ButtonContainer } from 'features/questions/components/ButtonContainer';
import { Section } from 'components/ui/Section';
import { FormSection } from 'components/form/FormSection';
import Button from 'jbc-front/components/Button';
import { useWizard } from '../WizardProvider';
import { Input } from 'components/form/Input';
import { InputWithText } from 'components/form/InputWithText';
import { DateField } from 'components/form/DateField';
import { SelectField } from 'components/form/SelectField';
import { FileField } from 'components/form/FileField';
import { dateFormFormat, amountFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { useYearMasters } from '@jbc-year-end-adj/common/hooks/useYearMaster';
import { useYear } from 'hooks/useYear';
import { gql, useQuery } from '@apollo/client';

const IMAGE_SETTINGS = gql`
  query imageSettings($year: Int!) {
    client {
      clientYearly(year: $year) {
        clientSetting {
          id
          requestImageUploadSettings {
            previousWithholdingSlip
          }
        }
      }
    }
  }
`;

const withholdingSlipStatus = {
  submitted: '提出済み',
  not_submitted: '手元にある',
  in_order: '取り寄せ中',
  unavailable: '入手できない'
};

export const FormerJobs: FC = () => {
  const { question, request } = useWizard();
  const formerJobs = request.profile?.formerJobs;
  const hasFormerJobs = formerJobs && formerJobs.length > 0;

  const year = useYear();
  const [yearMaster] = useYearMasters({
    year: year,
    toJpFirstYear: true
  });

  const { data, loading } = useQuery(IMAGE_SETTINGS, { variables: { year } });
  if (loading) return null;
  const {
    client: {
      clientYearly: {
        clientSetting: {
          requestImageUploadSettings: { previousWithholdingSlip: previousWithholdingSlipImageSetting }
        }
      }
    }
  } = data;

  return (
    <FormerJobsTemplate>
      <FormerJobsTemplate.CheckFormerJob question={question}>
        <ButtonContainer>
          <Button huge primary={hasFormerJobs} secondary={!hasFormerJobs} disabled>
            はい
          </Button>
          <Button huge primary={!hasFormerJobs} secondary={hasFormerJobs} disabled>
            いいえ
          </Button>
        </ButtonContainer>
      </FormerJobsTemplate.CheckFormerJob>
      {hasFormerJobs && (
        <FormerJobsTemplate.DetailInput>
          {formerJobs.map((formerJob, index) => (
            <Section key={formerJob.id}>
              <Section.Header>前職情報({`${index + 1}`})</Section.Header>
              <Section.Body>
                <FormSection>
                  <Input label="会社名" required value={formerJob.companyName} disabled />

                  <Input label="住所" note="前職の住所を全角文字で入力してください。" value={formerJob.address || ''} disabled />

                  <DateField
                    label="退職日"
                    required
                    placeholder="令和4年5月31日"
                    value={dateFormFormat(formerJob.resignedOn, 'L')}
                    disabled
                  />

                  <DateField
                    label="最後の給与支給日"
                    placeholder="令和4年6月31日"
                    value={dateFormFormat(formerJob.lastSalaryOn, 'L')}
                    disabled
                  />

                  <SelectField
                    label="源泉徴収票"
                    required
                    note={`前職からもらった${yearMaster?.eraName}${yearMaster?.eraNumber}年の源泉徴収票の原本は会社に提出する必要があります。年度が正しいか改めてご確認ください。退職所得の源泉徴収票は不要です。`}
                    options={[{ label: withholdingSlipStatus[formerJob.withholdingSlipStatus], value: formerJob.withholdingSlipStatus }]}
                    value={{ label: withholdingSlipStatus[formerJob.withholdingSlipStatus], value: formerJob.withholdingSlipStatus }}
                    disabled
                  />

                  {formerJob.withholdingSlipStatus !== 'submitted' && (
                    <>
                      <FileField
                        label="源泉徴収票画像"
                        note="提出済みの場合アップロードは不要です。"
                        disabled
                        required={previousWithholdingSlipImageSetting === 'required'}
                      >
                        <FileField.FileContainer>
                          {formerJob.withholdingSlipImage && <FileField.File file={formerJob.withholdingSlipImage} />}
                        </FileField.FileContainer>
                      </FileField>

                      <InputWithText
                        label="支払金額"
                        text="円"
                        note="源泉徴収票の「支払金額」欄に記載されている金額です。提出済みの場合記載は不要です。"
                        value={amountFormat(formerJob.paymentAmount)}
                        disabled
                        required
                      />

                      <InputWithText
                        label="社会保険料等の金額"
                        text="円"
                        note="源泉徴収票の「社会保険料等の金額」欄に記載されている金額です。提出済みの場合記載は不要です。"
                        value={amountFormat(formerJob.deductionAmount)}
                        disabled
                        required
                      />

                      <InputWithText
                        label="源泉徴収税額"
                        text="円"
                        note="源泉徴収票の「源泉徴収税額」欄に記載されている金額です。提出済みの場合記載は不要です。"
                        value={amountFormat(formerJob.taxAmount)}
                        disabled
                        required
                      />
                    </>
                  )}
                </FormSection>
              </Section.Body>
            </Section>
          ))}
        </FormerJobsTemplate.DetailInput>
      )}
    </FormerJobsTemplate>
  );
};
