import { FC, ReactNode } from 'react';
import { Section } from 'components/ui/Section';
import { Separator } from 'components/ui/Separator';

import { Lead } from '../parts/Lead';
import { Note } from '../parts/Note';
import { Content } from '../parts/Content';

import { Hint } from 'jbc-front/components/presenters/ui/Hint';
import { Spacer } from 'jbc-front/components/presenters/Spacer';

type QuestionUntil2019Props = {
  children: ReactNode;
};

export const MainSection: FC<QuestionUntil2019Props> = ({ children }) => {
  return (
    <Section>
      <Section.Header>配偶者・寡婦（寡夫）情報</Section.Header>

      <Section.Body>
        <Content>{children}</Content>
      </Section.Body>
    </Section>
  );
};

type CheckSpouseProps = {
  children: ReactNode;
};

const CheckSpouse: FC<CheckSpouseProps> = ({ children }) => {
  return (
    <>
      <Lead>配偶者について、該当するものを選んでください。</Lead>
      {children}
    </>
  );
};

type CheckWidowProps = {
  gender: 'male' | 'female';
  children: ReactNode;
};

const CheckWidow: FC<CheckWidowProps> = ({ gender, children }) => {
  return (
    <>
      <Separator />
      <Lead>あなたは以下のいずれかに該当しますか？</Lead>
      <Note>
        {gender === 'male' && (
          <>
            寡夫とは、以下のすべてに該当する方です。
            <ul className="m-list-notes">
              <li>・合計所得金額が500万円以下であること</li>
              <li>・妻と死別し、若しくは離婚した後婚姻をしていないこと又は妻の生死が明らかでない一定の人であること。</li>
              <li>
                ・生計を一にする子がいること。
                <Hint>
                  「生計を一にする子」とは、総所得金額等が38万円以下で、他の人の控除対象配偶者や扶養親族になっていない人のことです。
                  「合計所得金額」とは、各種損失の繰越控除を適用する前の総所得金額、譲渡所得、配当所得、雑所得、山林所得、退職所得の合計額をいいます。
                  詳しくは
                  <a href="https://www.nta.go.jp/m/taxanswer/1172.htm" target="_blank" rel="noopener noreferrer">
                    こちら
                  </a>
                </Hint>
              </li>
            </ul>
          </>
        )}

        {gender === 'female' && (
          <>
            <p>寡婦とは、以下のいずれかに該当する方です。</p>
            <ul>
              <li>
                ・夫と死別し、若しくは離婚した後婚姻をしていない人、又は夫の生死が明らかでない一定の人で、扶養親族がいる人又は生計を一にする子がいる人
                <Hint>
                  「生計を一にする子」とは、総所得金額等が38万円以下で、他の人の控除対象配偶者や扶養親族になっていない人のことです。
                  「合計所得金額」とは、各種損失の繰越控除を適用する前の総所得金額、譲渡所得、配当所得、雑所得、山林所得、退職所得の合計額をいいます。
                  詳しくは
                  <a href="https://www.nta.go.jp/m/taxanswer/1170.htm" target="_blank" rel="noopener noreferrer">
                    こちら
                  </a>
                </Hint>
              </li>
              <li>・夫と死別した後婚姻をしていない人又は夫の生死が明らかでない一定の人で、合計所得金額が500万円以下の人</li>
            </ul>
            <Spacer direction="y" size={20} />
            <p>特別寡婦とは、以下のすべてに該当する方です。</p>
            <ul>
              <li>・夫と死別し又は離婚した後婚姻をしていない人や夫の生死が明らかでない一定の人</li>
              <li>・扶養親族である子がいる人</li>
              <li>
                ・合計所得金額が500万円以下であること。
                <Hint>
                  「合計所得金額」とは、各種損失の繰越控除を適用する前の総所得金額、譲渡所得、配当所得、雑所得、山林所得、退職所得の合計額をいいます。詳しくは
                  <a href="https://www.nta.go.jp/m/taxanswer/1170.htm" target="_blank" rel="noopener noreferrer">
                    こちら
                  </a>
                </Hint>
              </li>
            </ul>
          </>
        )}
      </Note>
      {children}
    </>
  );
};

type DetailInputProps = {
  children: ReactNode;
};

const DetailInput: FC<DetailInputProps> = ({ children }) => {
  return (
    <>
      <Separator />
      <Lead>以下の情報の記入が必要です。</Lead>
      {children}
    </>
  );
};

export const WidowUntil2019 = Object.assign(MainSection, { CheckSpouse, CheckWidow, DetailInput });
