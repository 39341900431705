import { createContext, useContext, FC, ReactNode } from 'react';
import { Employee } from '../../query';
import { EmployeeAddressClassification } from '../../query';

type EmployeeInfoValue = {
  employee: Employee;
  employeeAddressClassification: EmployeeAddressClassification;
};

const EmployeeInfo = createContext<EmployeeInfoValue | null>(null);

type ResultProviderType = {
  employee: Employee;
  children: ReactNode;
  employeeAddressClassification: EmployeeAddressClassification;
};

export const EmployeeInfoProvider: FC<ResultProviderType> = ({ employeeAddressClassification, employee, children }) => {
  return <EmployeeInfo.Provider value={{ employee, employeeAddressClassification }}>{children}</EmployeeInfo.Provider>;
};

export function useEmployeeInfo(): EmployeeInfoValue {
  const value = useContext(EmployeeInfo);

  if (value === null) throw new Error();

  return value;
}
