import React from 'react'
import Icon from 'react-icon-base'

const Adjustment = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g fill="currentColor">
      <path d="M24.692 23.268L19.32 28.9l-4.011-4.172 1.604-1.668 2.405 2.503 3.771-3.963z" stroke="currentColor" strokeWidth="0.5"/>
      <path d="M35.066 14H4.934v-3.787c0-.67.543-1.213 1.212-1.213h4.284v2.5h2.495V9h14.15v2.5h2.495V9h4.285c.669 0 1.212.543 1.212 1.213V14zm0 18.25c0 .69-.559 1.25-1.248 1.25H6.181a1.25 1.25 0 0 1-1.248-1.25V16.5H35.067v15.75zm0-25.75H29.57V4h-2.495v2.5h-14.15V4H10.43v2.5H4.934A2.505 2.505 0 0 0 2.437 9l.08 24.202c0 1.38 1.198 2.798 2.575 2.798H34.907c1.378 0 2.575-1.418 2.575-2.798L37.563 9c0-1.375-1.123-2.5-2.497-2.5z" stroke="currentColor" strokeWidth="0.5"/>
    </g>
  </Icon>
)
Adjustment.defaultProps = {
  size: 40
}

export default Adjustment
