import React from 'react'
import Icon from 'react-icon-base'

const CustomRadio = props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <g fill="currentColor">
      <path d="M7.563 0A7.562 7.562 0 0 0 0 7.562a7.562 7.562 0 1 0 15.124 0A7.562 7.562 0 0 0 7.562 0m0 2a5.568 5.568 0 0 1 5.562 5.562 5.568 5.568 0 0 1-5.562 5.563 5.568 5.568 0 0 1-5.561-5.563A5.568 5.568 0 0 1 7.563 2"/>
      <path d="M10.658 7.563a3.096 3.096 0 1 1-6.191 0 3.096 3.096 0 0 1 6.191 0"/>
    </g>
  </Icon>
)
CustomRadio.defaultProps = {
  size: 16
}

export default CustomRadio
