import { FC } from 'react';
import { Start as StartTemplate } from 'features/questions/templates/Start';
import { ButtonContainer } from 'features/questions/components/ButtonContainer';
import Button from 'jbc-front/components/Button';
import { useWizard } from '../WizardProvider';

export const Start: FC = () => {
  const { question } = useWizard();
  const isStarted = question.requestQuestion?.status === 'answered';

  return (
    <StartTemplate question={question}>
      <ButtonContainer>
        <Button huge primary={isStarted} secondary={!isStarted} disabled>
          年末調整を始める
        </Button>
      </ButtonContainer>
    </StartTemplate>
  );
};
