import { FC, ComponentProps } from 'react';
import { FormField, FieldBaseProps } from '../FormField';
import { Label } from '../Label';
import { Select } from 'jbc-front/components/presenters/form/Select';
import { Required } from 'jbc-front/components/presenters/form/Label';
import { ErrorMessage } from 'jbc-front/components/presenters/form/ErrorMessage';
import { Note } from 'jbc-front/components/presenters/ui/Note';
import { Hint } from 'jbc-front/components/presenters/ui/Hint';

type SelectFieldProps = ComponentProps<typeof Select> & FieldBaseProps;

export const SelectField: FC<SelectFieldProps> = ({ label, required = false, hint, note, error = undefined, ...selectProps }) => {
  return (
    <FormField>
      {label && (
        <FormField.LabelContainer>
          <Label>
            {label}
            {required && <Required />}
            {hint && <Hint>{hint}</Hint>}
          </Label>
        </FormField.LabelContainer>
      )}

      <Select {...selectProps} />

      {note && (
        <FormField.NoteContainer>
          <Note>{note}</Note>
        </FormField.NoteContainer>
      )}

      {error && (
        <FormField.ErrorContainer>
          <ErrorMessage>{error}</ErrorMessage>
        </FormField.ErrorContainer>
      )}
    </FormField>
  );
};
