import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { AnchorTarget } from 'jbc-front/components/ScrollSpy/v2';

import { Section } from '@jbc-year-end-adj/2024/components/ui/Section';
import { FormSection } from '@jbc-year-end-adj/2024/components/form/FormSection';
import { ToggleButton } from '@jbc-year-end-adj/2024/components/form/ToggleButton';

export const PrintSection: FC = () => {
  const { register } = useFormContext();

  return (
    <Section>
      <AnchorTarget name="request_print" />
      <Section.Header>印刷依頼設定</Section.Header>
      <Section.Body>
        <FormSection>
          <p>
            給与所得者の扶養控除等（異動）申告書、給与所得者の保険料控除申告書、給与所得者の基礎控除申告書 兼 給与所得者の配偶者控除等申告書
            兼 所得金額調整控除申告書を印刷するように依頼できます。
          </p>

          <ToggleButton.Container>
            <ToggleButton {...register('requestPrint')} value={'true'}>
              紙の提出を依頼する
            </ToggleButton>
            <ToggleButton {...register('requestPrint')} value={'false'}>
              紙の提出を依頼しない
            </ToggleButton>
          </ToggleButton.Container>
        </FormSection>
      </Section.Body>
    </Section>
  );
};
