import { FC } from 'react';
import { Spouse as Template } from 'features/questions/templates/Spouse';
import { ButtonContainer } from 'features/questions/components/ButtonContainer';
import Button from 'jbc-front/components/Button';
import { useWizard } from '../../WizardProvider';
import { Section } from 'components/ui/Section';
import { Input } from 'components/form/Input';
import { FormSection } from 'components/form/FormSection';
import { Row } from 'components/form/Row';
import { Radio } from 'components/form/Radio';
import { DateField } from 'components/form/DateField';
import { ThisYear } from './ThisYear';
import { NextYear } from './NextYear';
import { dateFormFormat } from '@jbc-year-end-adj/common/utils/formatter';

export const Spouse: FC = () => {
  const { question, request } = useWizard();
  const hasSpouseDeduction = request.profile?.hasSpouseDeduction;
  const spouse = request.profile?.spouse;

  return (
    <Template>
      <Template.CheckHasSpouseDeduction question={question}>
        <ButtonContainer>
          <Button huge primary={hasSpouseDeduction} secondary={!hasSpouseDeduction} disabled>
            はい
          </Button>
          <Button huge primary={!hasSpouseDeduction} secondary={hasSpouseDeduction} disabled>
            いいえ
          </Button>
        </ButtonContainer>
      </Template.CheckHasSpouseDeduction>

      {hasSpouseDeduction && spouse && (
        <Template.DetailInput>
          <Section>
            <Section.Header>配偶者の基本情報</Section.Header>
            <Section.Body>
              <FormSection>
                <Row>
                  <Input label="姓" required value={spouse.lastName} disabled />
                  <Input label="名" required value={spouse.firstName} disabled />
                </Row>

                <Row>
                  <Input label="姓（カナ）" required value={spouse.lastNameKana} disabled />
                  <Input label="名（カナ）" required value={spouse.firstNameKana} disabled />
                </Row>

                <Radio.Container label="性別" required>
                  <Radio checked={spouse.gender === 'male'} disabled>
                    男性
                  </Radio>
                  <Radio checked={spouse.gender === 'female'} disabled>
                    女性
                  </Radio>
                </Radio.Container>

                <DateField
                  label="生年月日"
                  note="例）1981/01/23、S56.1.23"
                  required
                  value={dateFormFormat(spouse.birthday, 'L')}
                  disabled
                />
              </FormSection>
            </Section.Body>
          </Section>

          <ThisYear spouse={spouse} />
          <NextYear spouse={spouse} />

          {(spouse.thisYear?.dependentTaxLaw || spouse.nextYear?.dependentTaxLaw) && (
            <>
              <DateField
                label="源泉控除対象配偶者になった日"
                note="源泉控除対象配偶者の場合のみ記入してください"
                value={dateFormFormat(spouse.dependentFrom, 'L')}
                disabled
              />

              <Input
                label="源泉控除対象配偶者になった理由"
                note="源泉控除対象配偶者の場合のみ記入してください"
                value={spouse.dependentReason}
                disabled
              />

              <DateField label="死亡日" value={dateFormFormat(spouse.diedOn, 'L')} disabled />
            </>
          )}

          <ButtonContainer>
            <Button primary disabled={true}>
              次へ
            </Button>
          </ButtonContainer>
        </Template.DetailInput>
      )}
    </Template>
  );
};
