import React from 'react';
import DropDownMenu from '../components/DropDownMenu';
import { Pulldown } from 'jbc-front/components/icons';
import _ from 'lodash';

export const methods = {
  pay_dec: '12月給与',
  bonus_dec: '12月賞与',
  pay_jan: '1月給与',
  none: '反映しない'
};

const ApplyMethod = ({ from, employeeId, methodName, onChange }) => (
  <DropDownMenu
    toggle={setIsOpen => (
      <button
        onClick={e => {
          setIsOpen(x => !x);
          e.stopPropagation();
        }}
      >
        {methodName}
        <Pulldown style={{ marginLeft: 3 }} />
      </button>
    )}
  >
    {_.map(methods, (name, method) => {
      const set = () => onChange(method);
      return (
        <DropDownMenu.MenuItem key={method} role="menuitem" onClick={set} onKeyPress={set}>
          {name}
        </DropDownMenu.MenuItem>
      );
    })}
  </DropDownMenu>
);

export default ApplyMethod;
