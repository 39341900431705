import { FC, useState } from 'react';
import Button from 'jbc-front/components/Button';
import classnames from 'classnames';

import { Modal } from 'jbc-front/components/presenters/ui/Modal';
import { ButtonRow } from 'jbc-front/components/presenters/layout/ButtonRow';

import { year } from '@jbc-year-end-adj/2024/consts';
import { useNotify } from '@jbc-year-end-adj/2024/hooks/useNotify';
import { useAdminMutation as useMutation } from '@jbc-year-end-adj/2024/hooks/graphql/useMutation';
import { useAdminLazyQuery as useLazyQuery } from '@jbc-year-end-adj/2024/hooks/graphql/useQuery';
import { useAsyncTask } from '@jbc-year-end-adj/2024/features/AsyncTask/AsyncTaskProvider';
import { FloatingButton } from '@jbc-year-end-adj/2024/components/ui/FloatingButton';

// @ts-ignore
import { convertSelectedIds } from '../../SelectList/useSelectEmployee';

import styles from './BulkDelete.scss';
import { BULK_DELETE_EMPLOYEES, BULK_DELETE_EMPLOYEES_ASYNC } from './mutation';
import { DELETE_TARGET_EMPLOYEES, QueryResult } from './query';

const MAX_DELETE_COUNT = 100;

type BulkDeleteProps = {
  selected: { [key: string]: { name: string; status: unknown } };
  allSelectMode: boolean;
  isDisabledSubmit: () => boolean;
  selectedTotalCount: number;
  onSuccess: () => void;
  onChangeMode: () => void;
};

export const BulkDelete: FC<BulkDeleteProps> = ({
  selected,
  allSelectMode,
  isDisabledSubmit,
  selectedTotalCount,
  onSuccess,
  onChangeMode
}) => {
  const notify = useNotify();
  const [isOpen, setIsOpen] = useState(false);
  const [bulkDeleteEmployees, { loading }] = useMutation(BULK_DELETE_EMPLOYEES, {
    refetchQueries: ['employees']
  });
  const [bulkDeleteEmployeesAsync, { loading: loadingAsync }] = useMutation(BULK_DELETE_EMPLOYEES_ASYNC);
  const [fetchDeleteTargetEmployees, { data }] = useLazyQuery<QueryResult>(DELETE_TARGET_EMPLOYEES, {
    variables: { year, allSelectMode, employeeIds: Object.keys(selected) },
    fetchPolicy: 'network-only'
  });

  const { taskRunningProps } = useAsyncTask();
  const [selectedEmployees, setSelectedEmployees] = useState<{ index: number; name: string }[]>([]);
  const remainingCount = selectedTotalCount - selectedEmployees.length;

  const fetchDeleteEmployees = async () => {
    await fetchDeleteTargetEmployees();

    if (data) {
      const deleteTargetEmployees = data.client.clientYearly.deleteTargetEmployees;
      const targets = deleteTargetEmployees.map(employee => {
        const { staffCode, profile } = employee;
        const prefix = staffCode ? staffCode + ' ' : '';
        return `${prefix}${profile.fullName}`;
      });

      setSelectedEmployees(targets.map((name: string, index: number) => ({ index, name })));
    }
  };

  const handleClick = async () => {
    if (selectedTotalCount) {
      await fetchDeleteEmployees();
      setIsOpen(true);
    } else {
      notify('従業員を選択してください', 'error');
    }
  };

  const handleSubmit = async () => {
    const isExcessive = selectedTotalCount > MAX_DELETE_COUNT;
    const bulkDeleteMutation = isExcessive ? bulkDeleteEmployeesAsync : bulkDeleteEmployees;
    const message = isExcessive ? '従業員一括削除を開始しました' : '従業員を削除しました';

    const { employeeIds, exceptEmployeeIds } = convertSelectedIds(
      allSelectMode,
      Object.entries(selected)
        .filter(([, v]) => v)
        .map(([id]) => id)
    );

    await bulkDeleteMutation({
      variables: {
        input: {
          allSelectMode,
          employeeIds,
          exceptEmployeeIds
        }
      }
    });
    setIsOpen(false);
    notify(message, 'success');
    onSuccess();
  };

  return (
    <FloatingButton>
      <Button
        primary
        disabled={isDisabledSubmit() || loading || loadingAsync || taskRunningProps.disabled}
        disabledReason={taskRunningProps.disabledReason}
        onClick={handleClick}
      >
        削除
      </Button>
      <Button onClick={onChangeMode}>キャンセル</Button>

      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <Modal.Header onClose={() => setIsOpen(false)}>従業員削除</Modal.Header>
        <Modal.Body>
          <p>【注意】</p>
          <p className="u-mt15">
            従業員を削除すると、当該従業員に関する年末調整によって登録された情報が消去され、
            <span className={styles.warning}>復旧できなくなります</span>。
          </p>
          <p>ジョブカン労務HR、給与計算に登録されている情報が消去されることはありません。</p>
          <p className={classnames(styles.importantWarning, 'u-mt15')}>本当に削除しますか？</p>
          <p className="u-mt15">削除される従業員</p>
          <div className="u-mt5">
            <ul>
              {selectedEmployees.map(({ index: id, name }) => (
                <li key={id}>・{name}</li>
              ))}
            </ul>
            {remainingCount > 0 && <p>他{remainingCount}人</p>}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ButtonRow>
            <Button
              primary
              disabled={isDisabledSubmit() || loading || loadingAsync || taskRunningProps.disabled}
              disabledReason={taskRunningProps.disabledReason as JSX.Element}
              onClick={handleSubmit}
            >
              削除する
            </Button>
            <Button onClick={() => setIsOpen(false)} disabled={loading}>
              キャンセル
            </Button>
          </ButtonRow>
        </Modal.Footer>
      </Modal>
    </FloatingButton>
  );
};
