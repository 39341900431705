import { FC } from 'react';
import { Grid } from '../../../../../components/Grid';
import { useResult } from '../../../ResultProvider';
import { Section } from 'components/ui/Section';
import { LifeInsurance } from '../../../../../query';
import { UpdateGeneralLifeInsuranceDiff, CreateOrDeleteGeneralLifeInsuranceDiff } from './GeneralLifeInsuranceDiff';
import { UpdateNursingMedicalInsuranceDiff, CreateOrDeleteNursingMedicalInsuranceDiff } from './NursingMedicalInsuranceDiff';
import { UpdateIndividualAnnuityInsuranceDiff, CreateOrDeleteIndividualAnnuityInsuranceDiff } from './IndividualAnnuityInsuranceDiff';

type DiffType = {
  type: 'add' | 'update' | 'delete';
  source: LifeInsurance | undefined;
  input: LifeInsurance | undefined;
};

export const LifeInsuranceDiff: FC = () => {
  const { employee } = useResult();
  const profile = employee.profile;
  const requestProfile = employee.request?.profile;

  const generalLifeInsuranceDiff =
    requestProfile?.generalLifeInsurances.reduce<DiffType[]>((diff, input) => {
      const source = profile.generalLifeInsurances.find(insurance => insurance.compareKey === input.compareKey);
      const type = source ? 'update' : 'add';
      diff.push({ type, source, input });

      return diff;
    }, []) || [];

  profile.generalLifeInsurances.forEach(source => {
    const index = requestProfile?.generalLifeInsurances?.findIndex(input => input.compareKey === source.compareKey);
    if (index === undefined || index === -1) {
      generalLifeInsuranceDiff?.push({
        type: 'delete',
        source,
        input: undefined
      });
    }
  });

  const nursingMedicalInsuranceDiff =
    requestProfile?.nursingMedicalInsurances.reduce<DiffType[]>((diff, input) => {
      const source = profile.nursingMedicalInsurances.find(insurance => insurance.compareKey === input.compareKey);
      const type = source ? 'update' : 'add';
      diff.push({ type, source, input });

      return diff;
    }, []) || [];

  profile.nursingMedicalInsurances.forEach(source => {
    const index = requestProfile?.nursingMedicalInsurances?.findIndex(input => input.compareKey === source.compareKey);
    if (index === undefined || index === -1) {
      nursingMedicalInsuranceDiff?.push({
        type: 'delete',
        source,
        input: undefined
      });
    }
  });

  const individualAnnuityInsuranceDiff =
    requestProfile?.individualAnnuityInsurances.reduce<DiffType[]>((diff, input) => {
      const source = profile.individualAnnuityInsurances.find(insurance => insurance.compareKey === input.compareKey);
      const type = source ? 'update' : 'add';
      diff.push({ type, source, input });

      return diff;
    }, []) || [];

  profile.individualAnnuityInsurances.forEach(source => {
    const index = requestProfile?.individualAnnuityInsurances?.findIndex(input => input.compareKey === source.compareKey);
    if (index === undefined || index === -1) {
      individualAnnuityInsuranceDiff?.push({
        type: 'delete',
        source,
        input: undefined
      });
    }
  });

  return (
    <Section>
      <Section.Header>生命保険</Section.Header>
      <Section.Body>
        <Grid colGap>
          {generalLifeInsuranceDiff.length === 0 &&
            nursingMedicalInsuranceDiff.length === 0 &&
            individualAnnuityInsuranceDiff.length === 0 && <p>生命保険情報はありません。</p>}
          {generalLifeInsuranceDiff.map((diff, index) => (
            <>
              {diff.type === 'update' && <UpdateGeneralLifeInsuranceDiff key={index} index={index + 1} diff={diff} />}
              {diff.type === 'add' && (
                <CreateOrDeleteGeneralLifeInsuranceDiff key={index} index={index + 1} lifeInsurance={diff.input} type="add" />
              )}
              {diff.type === 'delete' && (
                <CreateOrDeleteGeneralLifeInsuranceDiff key={index} index={index + 1} lifeInsurance={diff.source} type="delete" />
              )}
            </>
          ))}

          {nursingMedicalInsuranceDiff.map((diff, index) => (
            <>
              {diff.type === 'update' && <UpdateNursingMedicalInsuranceDiff key={index} index={index + 1} diff={diff} />}
              {diff.type === 'add' && (
                <CreateOrDeleteNursingMedicalInsuranceDiff key={index} index={index + 1} lifeInsurance={diff.input} type="add" />
              )}
              {diff.type === 'delete' && (
                <CreateOrDeleteNursingMedicalInsuranceDiff key={index} index={index + 1} lifeInsurance={diff.source} type="delete" />
              )}
            </>
          ))}

          {individualAnnuityInsuranceDiff.map((diff, index) => (
            <>
              {diff.type === 'update' && <UpdateIndividualAnnuityInsuranceDiff key={index} index={index + 1} diff={diff} />}
              {diff.type === 'add' && (
                <CreateOrDeleteIndividualAnnuityInsuranceDiff key={index} index={index + 1} lifeInsurance={diff.input} type="add" />
              )}
              {diff.type === 'delete' && (
                <CreateOrDeleteIndividualAnnuityInsuranceDiff key={index} index={index + 1} lifeInsurance={diff.source} type="delete" />
              )}
            </>
          ))}
        </Grid>
      </Section.Body>
    </Section>
  );
};
