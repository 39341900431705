import React from 'react'
import Icon from 'react-icon-base'

const ZipFile = props => (
  <Icon viewBox="0 0 19 20" {...props}>
    <g fill='none' fillRule='evenodd'>
      <path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.5' d='M17.93 12.909h-4.222c-.158 0-.308.032-.444.091a1.158 1.158 0 0 0-.695 1.067v4.914l5.36-6.072V3.363c0-.64-.509-1.159-1.138-1.159H6.209c-.157 0-.307.033-.443.091a1.16 1.16 0 0 0-.696 1.068v14.46c0 .64.51 1.158 1.139 1.158h3.633' />
      <path fill='currentColor' d='M0 0h13v9H0z' />
      <path fill='#3498db' d='M1.301 7h3.71v-.868H2.575l2.408-3.696V1.82H1.532v.861h2.184L1.301 6.377V7zm4.627 0h1.036V1.82H5.928V7zm2.31 0V1.82h1.701c1.148 0 2.03.399 2.03 1.624 0 1.183-.889 1.715-2.002 1.715h-.693V7H8.238zm1.036-2.66h.623c.714 0 1.057-.301 1.057-.896 0-.602-.378-.805-1.092-.805h-.588V4.34z' />
    </g>
  </Icon>
)
ZipFile.defaultProps = {
  size: 16
}

export default ZipFile
