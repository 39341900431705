import { FC, ComponentProps } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { SelectField as UnControlledField } from 'components/form/SelectField';
import { Option } from 'jbc-front/components/presenters/form/Select';

type SelectFieldProps = ComponentProps<typeof UnControlledField> & {
  name: string;
};

export const SelectField: FC<SelectFieldProps> = ({ options, name, ...selectProps }) => {
  const { control } = useFormContext();

  const { field } = useController({
    name,
    control
  });

  const handleChange = (option: Option) => {
    if (selectProps.onChange) {
      selectProps.onChange(option);
    }

    field.onChange(option.value);
  };

  const handleBlur = () => {
    field.onBlur();
  };

  return (
    <UnControlledField
      {...selectProps}
      options={options}
      onChange={handleChange}
      onBlur={handleBlur}
      value={options.find((option: Option) => option.value === field.value)}
    />
  );
};
