import React from 'react';
import { useSession } from '../components';
import useReactRouter from 'use-react-router';

export const searchDetector = ({ convertQueryToForm, getCurrentQueryFromLocation, convertFormToQueryString }) => SearchForm => props => {
  const { location, history } = useReactRouter();
  const { me } = useSession();
  const handleSearch = values => {
    const search = convertFormToQueryString(values);
    history.push({ ...location, search });
  };
  const query = getCurrentQueryFromLocation(location, me);
  return <SearchForm initialValues={convertQueryToForm(query)} {...props} handleSearch={handleSearch} />;
};

export default searchDetector;
