import { FC } from 'react';
import { LifeInsuranceDiff } from './LifeInsuranceDiff';
import { EarthquakeInsuranceDiff } from './EarthquakeInsuranceDiff';
import { SocialInsuranceDiff } from './SocialInsuranceDiff';

import styles from './Preview.scss';

export const Preview: FC = () => {
  return (
    <div className={styles.preview}>
      <LifeInsuranceDiff />
      <EarthquakeInsuranceDiff />
      <SocialInsuranceDiff />
    </div>
  );
};
