import { FC, useState } from 'react';
import { Pulldown } from 'jbc-front/components/icons';
import moment from 'moment';
// @ts-ignore
import { Link } from 'react-router-dom';

import { year } from '@jbc-year-end-adj/2024/consts';
import { useAdminQuery as useQuery } from '@jbc-year-end-adj/2024/hooks/graphql/useQuery';
import { FETCH_MEMOS_IN_ALERT, QueryResult, MemoInAlert } from './query';
import styles from './MemoAlertList.scss';

export const MemoAlertList: FC = () => {
  const { loading, data } = useQuery<QueryResult>(FETCH_MEMOS_IN_ALERT, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first'
  });
  const defaultListOpen = !(window.sessionStorage.getItem('isMemoAlertOpen') === 'false');
  const [isListOpen, setIsListOpen] = useState(defaultListOpen);

  if (loading || !data) return null;

  const memos = data.client.clientYearly.memosInAlert;

  if (memos.length < 1) return null;

  return (
    <div className="l-overflow-scroll">
      <div className={styles.base}>
        <div
          className={isListOpen ? styles.title_opened : styles.title_closed}
          onClick={() => {
            window.sessionStorage.setItem('isMemoAlertOpen', String(!isListOpen));
            setIsListOpen(!isListOpen);
          }}
          role="presentation"
        >
          <span>従業員のメモアラート</span>
          <span className={isListOpen ? styles.pulldown_opened : styles.pulldown_closed}>
            <Pulldown />
          </span>
        </div>
        {isListOpen && (
          <div className={styles.body}>
            {memos.map(memo => (
              <MemoAlert key={memo.id} memo={memo} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

type MemoAlertProps = {
  memo: MemoInAlert;
};

const MemoAlert: FC<MemoAlertProps> = ({ memo }) => {
  return (
    <div className={styles.row}>
      <Link to={`/${year}/employee/${memo.employee.id}`}>
        <div className={styles.left}>
          <div className={styles.employeeInfo}>
            <div className={styles[`memo_${memo.color}`]}>●</div>
            <div>{memo.employee.staffCode}</div>
            <div>{memo.employee.profile.fullName}</div>
          </div>
          <div className={styles.date}>(アラート予定日: {memo.alertAt ? moment(memo.alertAt).format('YYYY/MM/DD') : '未設定'})</div>
        </div>
        <div className={styles.right}>
          <div style={{ marginLeft: 'auto' }}>{memo.lastUpdatedUser?.name}</div>
          <div className={styles.date}>(最終更新日時: {moment(memo.updatedAt).format('YYYY/MM/DD HH:mm')})</div>
        </div>
        <div className={styles.main}>
          <div className={styles.row_body}>{memo.body}</div>
        </div>
      </Link>
    </div>
  );
};
