import { gql } from '@apollo/client';

export type FetchMemosInAlertQueryResult = {
  client: {
    id: string;
    clientYearly: {
      id: string;
      memosInAlert: MemoInAlert[];
    };
  };
};

export type MemoInAlert = {
  id: string;
  body: string;
  color: string;
  alertAt: string;
  updatedAt: string;
  lastUpdatedUser: User;
  employee: Employee;
};

type User = {
  id: string;
  name?: string;
};

type Employee = {
  id: string;
  staffCode: string;
  profile: Profile;
};

type Profile = {
  id: string;
  fullName: string;
};

const MEMOS_IN_ALERT_FRAGMENT = gql`
  fragment MemosInAlert on Memo {
    id
    body
    color
    alertAt
    updatedAt
    lastUpdatedUser {
      id
      name
    }
    employee {
      id
      staffCode
      profile {
        id
        fullName
      }
    }
  }
`;

export const FETCH_MEMOS_IN_ALERT = gql`
  query memosInAlert {
    client {
      id
      clientYearly {
        id
        memosInAlert {
          ...MemosInAlert
        }
      }
    }
  }
  ${MEMOS_IN_ALERT_FRAGMENT}
`;

export const MEMO_FRAGMENT = gql`
  fragment Memo on Memo {
    id
    body
    alertAt
    color
    updatedAt
    lastUpdatedUser {
      id
      email
      name
    }
    createdUser {
      id
      name
    }
  }
`;

export const FETCH_MEMOS = gql`
  query memos($employeeId: ID!) {
    client {
      id
      clientYearly {
        id
        employee(id: $employeeId) {
          id
          memos {
            ...Memo
          }
        }
      }
    }
  }
  ${MEMO_FRAGMENT}
`;
