import { FC } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';

import { ButtonRow } from 'jbc-front/components/presenters/layout/ButtonRow';
import Button from 'jbc-front/components/Button';

import { FileField } from 'components/react-hook-form/FileField';
import { FormSection } from 'components/form/FormSection';

import { UPDATE_ATTACHMENTS, convertFormDataToQueryVariables } from './mutation';
import { schema, Schema, generateDefaultValues } from './schema';
import { useEmployeeInfo } from '../../EmployeeInfoProvider';
import { FETCH_EMPLOYEE } from '../../../../query';
import { Grid } from '../../../../components/Grid';
import { FloatingButton } from '../../../../components/FloatingButton';
import { FormSeparator } from '../../../../components/FormSeparator';

// @ts-ignore
import { useNotify } from 'actions';
// @ts-ignore
import { useMutation } from 'components/Graphql';

type FormProps = {
  onCancel: () => void;
};

export const Form: FC<FormProps> = ({ onCancel }) => {
  const { employee } = useEmployeeInfo();
  const [update, { loading }] = useMutation(UPDATE_ATTACHMENTS);
  const notify = useNotify();

  const methods = useForm<Schema>({
    defaultValues: generateDefaultValues(employee.profile.attachments),
    resolver: yupResolver(schema),
    mode: 'onBlur'
  });

  const { handleSubmit, watch, setValue } = methods;

  const onSubmit: SubmitHandler<Schema> = async (data: Schema) => {
    const variables = convertFormDataToQueryVariables(String(employee.id), data);
    await update({
      variables,
      refetchQueries: [FETCH_EMPLOYEE]
    });
    onCancel();
    notify('添付ファイルを保存しました。', 'success');
  };

  const attachments = watch('attachments');

  const handleDropFile = (files: File[]) => {
    setValue('attachments', [...attachments, ...files.map(file => ({ file }))]);
  };

  const handleRemoveFile = (index: number) => {
    const newAttachments = [...attachments];
    newAttachments.splice(index, 1);
    setValue('attachments', newAttachments);
  };

  return (
    <FormProvider {...methods}>
      <form>
        <FormSection>
          <Grid colGap>
            <FileField multiple label="添付ファイル" name="attachments" onDrop={handleDropFile}>
              <FileField.FileContainer>
                {attachments.map((attachment, index) => (
                  <FileField.File key={index} file={attachment.file} onRemove={() => handleRemoveFile(index)} />
                ))}
              </FileField.FileContainer>
            </FileField>
          </Grid>

          <FormSeparator />

          <FloatingButton>
            <ButtonRow>
              <Button onClick={onCancel} disabled={loading}>
                キャンセル
              </Button>
              <Button primary onClick={handleSubmit(onSubmit)} disabled={loading}>
                保存
              </Button>
            </ButtonRow>
          </FloatingButton>
        </FormSection>
      </form>
    </FormProvider>
  );
};
