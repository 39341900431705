import { ChangeEvent, FC, useState } from 'react';
import { useQuery } from '@apollo/client';

import { Modal } from 'jbc-front/components/presenters/ui/Modal';
import Paginator from 'jbc-front/components/Paginator';
import { Input } from 'components/form/Input';
import { useKeywordInput } from 'hooks/useKeywordInput';

import { SEARCH_CITIES, QueryResult } from './query';
import styles from './CitySearchModal.scss';

const PER_PAGE = 9;
const FIRST = 1;

type CitySearchModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (code: string, name: string) => void;
};

export const CitySearchModal: FC<CitySearchModalProps> = ({ isOpen, onClose, onSelect }) => {
  const [page, setPage] = useState<number>(FIRST);
  const [query, setQuery] = useState<string>('');
  const { data } = useQuery<QueryResult>(SEARCH_CITIES, {
    variables: {
      per: PER_PAGE,
      page,
      query
    }
  });

  const subject = useKeywordInput(setQuery);

  const handleQueryChange = (e: ChangeEvent<HTMLInputElement>) => {
    subject.next(e.target.value);
  };

  const handleSelect = (code: string, name: string) => {
    onSelect(code, name);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header onClose={onClose}>市区町村選択</Modal.Header>
      <Modal.Body>
        <div className={styles.body}>
          <Input placeholder="市区町村コード・市区町村名で検索" onChange={handleQueryChange} />
          <p>{data?.cities?.totalCount || 0} 件の検索結果</p>
          <List cities={data?.cities.list} onSelect={handleSelect} />
          <Paginator onClick={page => setPage(page)} totalResult={data?.cities.totalCount} current={page} rowsPerPage={PER_PAGE} />
        </div>
      </Modal.Body>
    </Modal>
  );
};

type ListProps = {
  cities?: QueryResult['cities']['list'];
  onSelect: (code: string, name: string) => void;
};

const List: FC<ListProps> = ({ cities = [], onSelect }) => {
  return (
    <ul className={styles.list}>
      {cities.map(city => (
        <li key={city.id} className={styles.item}>
          <div className={styles.itemDetail} onClick={() => onSelect(city.code, city.name)}>{`${city.code}：${city.name}`}</div>
        </li>
      ))}
    </ul>
  );
};
