import { gql } from '@apollo/client';

export type QueryResult = {
  client: {
    id: string;
    applications: {
      id: string;
      name: string;
      connected: boolean;
    }[];
  };
};

export const FETCH_APPLICATIONS = gql`
  query applications {
    client {
      id
      applications {
        id
        name
        connected
      }
    }
  }
`;
