import { FC } from 'react';
import { Dependents as DependentsTemplate } from 'features/questions/templates/Dependents';
import { useWizard } from '../../WizardProvider';
import { OtherFamily } from './OtherFamily';

export const Dependents: FC = () => {
  const { question, request } = useWizard();

  return (
    <DependentsTemplate question={question}>
      {request.profile?.otherFamilies.map((otherFamily, index) => (
        <OtherFamily key={otherFamily.id} otherFamily={otherFamily} index={index} />
      ))}
    </DependentsTemplate>
  );
};
