import React from 'react'
import Icon from 'react-icon-base'

const DeleteSquare = props => (
  <Icon viewBox="0 0 20 20" {...props}>
    <defs>
      <rect height="20" id="a" width="20" rx="2"/>
    </defs>
    <g fill='#fff' fillRule='evenodd'>
      <rect width='19' height='19' x='.5' y='.5' stroke='currentColor' rx='2' />
      <path fill='currentColor' fillRule='evenodd' d="M8.2 13.6h1.2V8.8H8.2v4.8zm2.4 0h1.2V8.8h-1.2v4.8zM7 14.8h6V7.6H7v7.2zm1.2-8.4h3.6V5.2H8.2v1.2zm4.8 0V4H7v2.4H4v1.2h1.8V16h8.4V7.6H16V6.4h-3z" />
    </g>
  </Icon>
)
DeleteSquare.defaultProps = {
  size: 20
}

export default DeleteSquare
