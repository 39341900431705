import React from 'react'
import Icon from 'react-icon-base'

const Dependent = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="2.5">
      <path d="M5.933 14.407a6.334 6.334 0 1 1 12.668.001 6.334 6.334 0 0 1-12.668-.001z"/>
      <path d="M1.42 31.586c0-5.99 4.857-10.846 10.846-10.846 5.99 0 10.846 4.857 10.846 10.846M26.282 19.518a4.449 4.449 0 1 1 8.898 0 4.449 4.449 0 0 1-8.898 0zM23.112 31.586a7.62 7.62 0 0 1 15.238 0"/>
    </g>
  </Icon>
)
Dependent.defaultProps = {
  size: 40
}

export default Dependent
