import { FC } from 'react';
import { HasDependent as HasDependentTemplate } from 'features/questions/templates/HasDependent';
import { findQuestionGroup } from 'features/questions/utils';
import { useWizard } from '../WizardProvider';
import { ButtonContainer } from 'features/questions/components/ButtonContainer';
import Button from 'jbc-front/components/Button';

export const HasDependent: FC = () => {
  const { question, questionGroups } = useWizard();
  const questions = findQuestionGroup(questionGroups, 'check_dependent')?.questions;
  const targetQuestion = questions?.find(q => q.code === 'dependents');
  const hasDependent = targetQuestion?.requestQuestion?.status !== 'skipped';

  return (
    <HasDependentTemplate question={question}>
      <ButtonContainer>
        <Button huge primary={hasDependent} secondary={!hasDependent} disabled>
          はい
        </Button>
        <Button huge primary={!hasDependent} secondary={hasDependent} disabled>
          いいえ
        </Button>
      </ButtonContainer>
    </HasDependentTemplate>
  );
};
