import { FC, ReactNode } from 'react';
import { Dl, Dt, Dd, FileDl } from '../DefinitionList';
import { FileType } from '@jbc-year-end-adj/types';
import { displayFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { FileLink } from 'components/ui/FileLink';
import { FilePreview } from 'components/feature/FilePreview';

import styles from './Diff.scss';
import classnames from 'classnames';

type DiffProps = {
  title: string;
  source?: string;
  input?: string;
  className?: string;
  inputClassName?: string;
};

const DiffComponent: FC<DiffProps> = ({ title, source, input, className, inputClassName }) => {
  const isDiff = displayFormat(source) !== displayFormat(input);

  return (
    <Dl className={styles.dl}>
      <Dt className={className}>{title}</Dt>
      <Dd className={className}>
        {isDiff ? (
          <>
            <span className={classnames({ [styles.input]: isDiff }, inputClassName)}>{displayFormat(input)}</span>
            <span className={classnames({ [styles.source]: isDiff })}>{displayFormat(source)}</span>
          </>
        ) : (
          <span>{displayFormat(source)}</span>
        )}
      </Dd>
    </Dl>
  );
};

type ContainerProps = {
  children: ReactNode;
};

const Container: FC<ContainerProps> = ({ children }) => {
  return <Dl className={styles.dl}>{children}</Dl>;
};

type HeaderProps = {
  children: ReactNode;
};

const Header: FC<HeaderProps> = ({ children }) => {
  return <Dt>{children}</Dt>;
};

type BodyProps = {
  source?: string;
  input?: string;
};

const Body: FC<BodyProps> = ({ source, input }) => {
  const isDiff = displayFormat(source) !== displayFormat(input);

  return (
    <Dd>
      {isDiff ? (
        <>
          <span className={classnames({ [styles.input]: isDiff })}>{displayFormat(input)}</span>
          <span className={classnames({ [styles.source]: isDiff })}>{displayFormat(source)}</span>
        </>
      ) : (
        <span>{displayFormat(source)}</span>
      )}
    </Dd>
  );
};

export const Diff = Object.assign(DiffComponent, {
  Container,
  Header,
  Body
});

type DiffItemProps = {
  source?: string;
  input?: string;
};

export const DiffItem: FC<DiffItemProps> = ({ source, input }) => {
  const isDiff = displayFormat(source) !== displayFormat(input);

  return (
    <>
      {isDiff ? (
        <>
          <div className={classnames({ [styles.input]: isDiff })}>{displayFormat(input)}</div>
          <div className={classnames({ [styles.source]: isDiff })}>{displayFormat(source)}</div>
        </>
      ) : (
        <div>{displayFormat(source)}</div>
      )}
    </>
  );
};

type DiffExplainColorsProps = {
  className?: string;
};

export const DiffExplainColors: FC<DiffExplainColorsProps> = ({ className }) => {
  return (
    <div className={classnames(styles.explainColors, className)}>
      <span className={styles.input}>変更後</span>
      <span className={styles.source}>変更前</span>
    </div>
  );
};

type FileDiff = {
  title: string;
  source?: FileType | undefined;
  input?: FileType | undefined;
};

export const FileDiff: FC<FileDiff> = ({ title, source, input }) => {
  const isDiff = displayFormat(source?.filename) !== displayFormat(input?.filename);
  return (
    <FileDl>
      <Dt>{title}</Dt>
      <Dd>
        <span className={isDiff ? styles.input : ''}>{input?.url ? <FileLink file={input} /> : displayFormat()}</span>

        <span className={isDiff ? styles.source : ''}>{source?.url ? <FileLink file={source} /> : displayFormat()}</span>
        {input && <FilePreview file={input} />}
      </Dd>
    </FileDl>
  );
};

type FileProps = {
  file: FileType | undefined;
};

export const File: FC<FileProps> = ({ file }) => {
  if (!file) return <Dd>{displayFormat()}</Dd>;

  if ((file.url && file.filename) || file.file) {
    return (
      <Dd>
        <FileLink file={file} />
        {file && <FilePreview file={file} />}
      </Dd>
    );
  }

  return <Dd>{displayFormat(file.filename || file.file)}</Dd>;
};
