import { FC, ReactNode } from 'react';
import { Section } from 'components/ui/Section';

import { Question } from '../type';

import { ToggleImageContainer } from './parts/ToggleImageContainer';
import { Lead } from './parts/Lead';
import { Description } from './parts/Description';
import { Content } from './parts/Content';

import styles from './templates.scss';

// @ts-ignore
import image from '../../../../images/img-Premium_Deduction_Life_Insurance.png';

type LifeInsurancesProps = {
  question: Question;
  children: ReactNode;
  initiallyOpen?: boolean;
};

export const LifeInsurances: FC<LifeInsurancesProps> = ({ question, children, initiallyOpen = false }) => {
  const description = question.questionDescriptions.find(description => description.field == 'top')?.description;

  return (
    <Section>
      <Section.Header className={styles.sectionHeader}>生命保険</Section.Header>

      <Section.Body>
        <Content>
          <Lead>今年支払った生命保険料情報を記入して下さい</Lead>

          <Description>{description}</Description>

          <ToggleImageContainer initiallyOpen={initiallyOpen}>
            <ToggleImageContainer.Header>※赤枠に関する設問です。</ToggleImageContainer.Header>
            <ToggleImageContainer.Body>
              <img src={image} alt="保険料控除_生命保険" />
            </ToggleImageContainer.Body>
          </ToggleImageContainer>

          {children}
        </Content>
      </Section.Body>
    </Section>
  );
};
