import React from 'react'
import Icon from 'react-icon-base'

const FbData = props => (
  <Icon viewBox="0 0 20 20" {...props}>
    <g fill="none" fillRule="evenodd">
      <g>
        <rect width="12.5" height="14.5" x=".75" y=".75" stroke="currentColor" strokeWidth="1.5" rx="2"/>
        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M4 7h6M4 4h6"/>
        <circle cx="10.75" cy="14.75" r="5.75" fill="#FFF"/>
        <g>
          <path fill="#3498db" d="M10.405 13.571L8.81 12.022a.583.583 0 0 1 .005-.849.643.643 0 0 1 .884.005l1.151 1.119L12 11.178a.643.643 0 0 1 .884-.005.583.583 0 0 1 .004.85l-1.594 1.548a.635.635 0 0 1-.445.178.635.635 0 0 1-.444-.178z"/>
          <path fill="#3498db" d="M10.795 18.62a.613.613 0 0 1-.625-.6V13.2c0-.331.28-.6.625-.6s.625.269.625.6v4.82c0 .33-.28.6-.625.6"/>
          <path fill="#3498db" d="M12.462 15.4H9.121a.611.611 0 0 1-.621-.6c0-.331.278-.6.621-.6h3.341c.343 0 .621.269.621.6 0 .331-.278.6-.62.6M12.462 17.4H9.121a.611.611 0 0 1-.621-.6c0-.331.278-.6.621-.6h3.341c.343 0 .621.269.621.6 0 .331-.278.6-.62.6"/>
        </g>
      </g>
    </g>
  </Icon>
)
FbData.defaultProps = {
  size: 16
}

export default FbData
