import React from 'react'
import Icon from 'react-icon-base'

const Select = props => (
  <Icon viewBox="0 0 15 15" {...props}>
    <g fill="none">
      <path d="M15 13c0 1.1-.9 2-2 2H2c-1.1 0-2-.9-2-2V2C0 .9.9 0 2 0h11c1.1 0 2 .9 2 2v11z" fill="currentColor"/>
      <path d="M9.875 6.673l-2.5 2.5-2.5-2.5" stroke="#FFF" strokeLinecap="round" strokeWidth="2.5"/>
    </g>
  </Icon>
)
Select.defaultProps = {
  size: 15
}

export default Select
