import React from 'react'
import Icon from 'react-icon-base'

const StressCheckGroupResult = props => (
  <Icon viewBox="0 0 15 17" {...props}>
    <g fill="currentColor" stroke="currentColor">
      <rect width="2" height="8" x=".5" y="8.5" rx="1"/>
      <rect width="2" height="12" x="6.5" y="4.5" rx="1"/>
      <rect width="2" height="16" x="12.5" y=".5" rx="1"/>
    </g>
  </Icon>
)

StressCheckGroupResult.defaultProps = {
  size: 17
}

export default StressCheckGroupResult
