import { gql } from '@apollo/client';
import { FileType } from '@jbc-year-end-adj/types';
import { Schema } from './schema';

export type QueryVariables = {
  input: {
    employeeId: string;
    attributes: {
      attachments: Attachment[];
    };
  };
};

type Attachment = {
  id?: string;
  file?: FileType | File;
};

export const convertFormDataToQueryVariables = (employeeId: string, data: Schema): QueryVariables => {
  return {
    input: {
      employeeId: employeeId,
      attributes: {
        attachments: data.attachments
      }
    }
  };
};

export const UPDATE_ATTACHMENTS = gql`
  mutation UpdateAttachments($input: UpdateAttachmentsInput!) {
    updateAttachments(input: $input) {
      employee {
        id
      }
    }
  }
`;
