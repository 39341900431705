import { FC, ReactNode, useState } from 'react';
import styles from './FileImportDescription.scss';
import classnames from 'classnames';

const MAX_LIST_ITEM = 4;

type DescriptionProps = {
  descriptions: DescriptionType[];
};

type DescriptionType = {
  name: string;
  description?: ReactNode;
  example?: string | number;
  notice?: string;
  required?: boolean;
};

export const Description: FC<DescriptionProps> = ({ descriptions }) => {
  return (
    <div className="l-wrap-xl">
      <div className="l-overflow-scroll">
        <table className={classnames('m-table-description', styles.spTableWidth)}>
          <thead>
            <tr>
              <th className="m-table-description-item-name">データ項目名</th>
              <th>説明文</th>
              <th className="m-table-description-example">例</th>
            </tr>
          </thead>
          <tbody>
            {descriptions.map(({ name, description = '-', example = '-', notice, required }, i) => (
              <tr key={i}>
                <th>
                  {name}
                  {required && <span className={styles.required}>必須</span>}
                </th>
                <td>
                  {description}
                  {notice && <p>{notice}</p>}
                </td>
                <td>{example}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

type ToggleListProps = {
  items: string[];
  notice?: string;
};

export const ToggleList: FC<ToggleListProps> = ({ items, notice }) => {
  const [isShowMore, setIsShowMore] = useState(false);
  const listItems = isShowMore ? items : items.slice(0, MAX_LIST_ITEM);

  return (
    <>
      <p>下記より一つ選択してください</p>
      <ul>
        {listItems.map((item, i) => (
          <li key={i}>{`・${item}`}</li>
        ))}
      </ul>
      <button className={styles.more} onClick={() => setIsShowMore(!isShowMore)}>
        {isShowMore ? '隠す' : 'もっと見る'}
      </button>
      {notice && <p>{notice}</p>}
    </>
  );
};

type ListProps = {
  items: string[];
  notice?: string;
};

export const List: FC<ListProps> = ({ items = [], notice }) => {
  return (
    <>
      <p>下記より一つ選択してください</p>
      <ul>
        {items.map((item, i) => (
          <li key={i}>{`・${item}`}</li>
        ))}
      </ul>
      {notice && <p>{notice}</p>}
    </>
  );
};
