import React from 'react'
import Icon from 'react-icon-base'

const Radiobutton = props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <g id="symbol" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="radio_on">
        <g id="radio">
          <circle id="Background" fill="currentColor" cx="8" cy="8" r="8"></circle>
          <path d="M8,11 C9.65685425,11 11,9.65685425 11,8 C11,6.34314575 9.65685425,5 8,5 C6.34314575,5 5,6.34314575 5,8 C5,9.65685425 6.34314575,11 8,11 Z" id="Check" fill="#FFFFFF"></path>
        </g>
      </g>
    </g>
  </Icon>
)
Radiobutton.defaultProps = {
  size: 16
}

export default Radiobutton
