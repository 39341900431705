import { FC, ReactNode, MouseEventHandler } from 'react';
import { PlusSquare } from 'jbc-front/components/icons';
import styles from './FormAddButton.scss';

type FormAddButtonProps = {
  children: ReactNode;
  onClick: () => void;
};

export const FormAddButton: FC<FormAddButtonProps> = ({ children, onClick }) => {
  const handleClick: MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation();
    onClick();
  };

  return (
    <button onClick={handleClick} className={styles.button} type="button">
      <div className={styles.buttonContent}>
        <PlusSquare size={30} className={styles.icon} />
        {children}
      </div>
    </button>
  );
};
