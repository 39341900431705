import React from 'react'
import Icon from 'react-icon-base'

const AlertNotification = props => (
  <Icon viewBox="0 0 20 20" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(3, 0)" fill="currentColor" fillRule="nonzero">
        <path d="M5.50000027,17 L5.50000027,17.5 C5.50000027,18.3284271 6.17157314,19 7.00000027,19 C7.77969639,19 8.42044895,18.4051119 8.49313369,17.64446 L8.50000027,17.5 L8.50000027,17 L5.50000027,17 Z M7.00000024,3.875 C4.64783376,3.875 2.72930611,5.73201837 2.6299599,8.06022158 L2.62591867,8.25 L2.62398524,13.5 L0.876286048,16.125 L13.1237144,16.125 L11.3740818,13.5 L11.3740818,8.25 L11.3700406,8.06022158 C11.2706944,5.73201837 9.35216671,3.875 7.00000024,3.875 Z M9.50000027,17.5 C9.50000027,18.8807119 8.38071214,20 7.00000027,20 C5.67451687,20 4.5899616,18.9684641 4.50531795,17.6643757 L4.50000027,17.5 L4.50000027,17 L0.876286048,17 C0.177776443,17 -0.239019447,16.2215236 0.148147394,15.6400088 L1.75110236,13.231375 L1.75130072,8.23136767 L1.75593926,8.02291079 C1.87555165,5.21976476 4.18581105,3 7,3 C9.81418943,3 12.1244488,5.21976476 12.2446585,8.04158925 L12.2488981,8.25 L12.2488981,13.231375 L13.8519503,15.6401548 C14.2205277,16.1939894 13.8600183,16.9263206 13.221518,16.9948106 L13.1237144,17 L9.50000027,17 L9.50000027,17.5 Z M7.00000027,0 C7.24546016,0 7.44960864,0.176875161 7.4919446,0.410124368 L7.50000027,0.5 L7.50000027,2.12406015 C7.50000027,2.40020253 7.27614264,2.62406015 7.00000027,2.62406015 C6.75454038,2.62406015 6.5503919,2.44718499 6.50805594,2.21393578 L6.50000027,2.12406015 L6.50000027,0.5 C6.50000027,0.223857625 6.72385789,0 7.00000027,0 Z"></path>
      </g>
      <g transform="translate(2.2986, 7.0009) rotate(14) translate(-2.2986, -7.0009)translate(0.5579, 1.5875)" stroke="currentColor" strokeLinecap="round" strokeWidth="0.75">
        <path d="M3.48139141,1.86012893 C2.56662823,2.76624998 2,4.02320744 2,5.41250801 C2,6.79334201 2.55974307,8.04344102 3.46470621,8.94828201"></path>
        <path d="M2.56067301,0 C0.997282928,1.28379091 0,3.23168551 0,5.41250801 C0,7.59437294 0.998236555,9.54308723 2.56291529,10.8268565"></path>
      </g>
      <g transform="translate(17.6986, 7.0037) scale(-1, 1) rotate(14) translate(-17.6986, -7.0037)translate(15.9579, 1.5903)" stroke="currentColor" strokeLinecap="round" strokeWidth="0.75">
        <path d="M3.48139141,1.86012893 C2.56662823,2.76624998 2,4.02320744 2,5.41250801 C2,6.79334201 2.55974307,8.04344102 3.46470621,8.94828201"></path>
        <path d="M2.56067301,0 C0.997282928,1.28379091 0,3.23168551 0,5.41250801 C0,7.59437294 0.998236555,9.54308723 2.56291529,10.8268565"></path>
      </g>
    </g>
  </Icon>
)
AlertNotification.defaultProps = {
  size: 20,
}

export default AlertNotification
