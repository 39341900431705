import React, { useState } from 'react';
import styles from '../employee_pages/EmployeeInput.scss';
import { Link } from 'react-router-dom';
import { Pulldown } from 'jbc-front/components/icons';

const _makeLink = code => `/employee_input?current=${code}`;

const QuestionGroup = ({ questionGroup, firstNotAnswered, currentQuestion, isCurrentGroup, makeLink = _makeLink }) => {
  const [isGroupOpen, setIsGroupOpen] = useState(isCurrentGroup);
  return (
    <div key={questionGroup.id} className={styles.group}>
      <div
        className={isGroupOpen ? styles.groupTitle_opened : styles.groupTitle_closed}
        onClick={() => setIsGroupOpen(!isGroupOpen)}
        role="presentation"
      >
        <span className={styles.step}>STEP {questionGroup.id}</span>
        <span>{questionGroup.name}</span>
        <span className={isGroupOpen ? styles.is_opened : styles.is_closed}>
          <Pulldown />
        </span>
      </div>
      {isGroupOpen && (
        <div className={styles.groupQuestions}>
          {questionGroup.questions.map((question, index) => (
            <div
              key={question.id}
              className={question.status === 'answered' || question.id === firstNotAnswered?.id ? styles.answered : styles.notanswered}
            >
              {((question.status === 'answered' && (!firstNotAnswered || question.no < firstNotAnswered.no)) ||
                (firstNotAnswered && question.id === firstNotAnswered.id)) &&
              (!currentQuestion || currentQuestion.id !== question.id) ? (
                <Link to={makeLink(question.code)}>
                  <span className={styles.steps}>
                    STEP {questionGroup.id} - {index + 1}
                  </span>
                  <span className={styles.name}>{question.name}</span>
                </Link>
              ) : (
                <div className={currentQuestion?.id === question.id && styles.is_active}>
                  <span className={styles.steps}>
                    STEP {questionGroup.id} - {index + 1}
                  </span>
                  <span className={styles.name}>{question.name}</span>
                  {currentQuestion?.id === question.id && (
                    <span className={styles.currentArrow}>
                      <Pulldown />
                    </span>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default QuestionGroup;
