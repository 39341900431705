import React from 'react'
import Icon from 'react-icon-base'

const DependentAdd = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="symbol" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <g id="icon_fuyoutsuika" stroke="currentColor" strokeWidth="2.5">
        <g id="Page-1" transform="translate(20.000000, 20.000000) scale(-1, 1) translate(-20.000000, -20.000000) translate(1.000000, 4.000000)">
          <path d="M33.0673145,13.90679 C33.0673145,10.4095233 30.2312478,7.57345667 26.7339811,7.57345667 C23.2367145,7.57345667 20.4006478,10.4095233 20.4006478,13.90679 C20.4006478,17.4040567 23.2367145,20.2401233 26.7339811,20.2401233 C30.2312478,20.2401233 33.0673145,17.4040567 33.0673145,13.90679 Z" id="Stroke-1"></path>
          <path d="M37.5798145,31.0859567 C37.5798145,25.0965233 32.7234145,20.2401233 26.7339811,20.2401233 C20.7445478,20.2401233 15.8881478,25.0965233 15.8881478,31.0859567" id="Stroke-3"></path>
          <path d="M12.7180611,19.0180433 C12.7180611,16.56071 10.7262278,14.5688767 8.26889447,14.5688767 C5.81219447,14.5688767 3.82036114,16.56071 3.82036114,19.0180433 C3.82036114,21.4747433 5.81219447,23.4665767 8.26889447,23.4665767 C10.7262278,23.4665767 12.7180611,21.4747433 12.7180611,19.0180433 Z" id="Stroke-5"></path>
          <path d="M15.8881478,31.0859567 C15.8881478,26.87809 12.4763811,23.4669567 8.26914781,23.4669567 C4.06191447,23.4669567 0.650147806,26.87809 0.650147806,31.0859567" id="Stroke-7"></path>
          <path d="M8.26914781,5.40560884 L17.2691478,5.40560884" id="Stroke-5"></path>
          <path d="M12.7691478,9.90560884 L12.7691478,0.905608844" id="Stroke-7"></path>
        </g>
      </g>
    </g>
  </Icon>
)
DependentAdd.defaultProps = {
  size: 40
}

export default DependentAdd
