import React from 'react'
import Icon from 'react-icon-base'

const Print = props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <path d="M14.272 10.741H13V7.985H3v2.756H1.728v-5.98h12.544v5.98zm-2.5 3.531H4.228V9.213h7.544v5.059zM4.228 3.532h7.544V1.728H4.228v1.804zm10.395 0H13V1.455A.957.957 0 0 0 12.045.5h-8.09A.957.957 0 0 0 3 1.455v2.077H1.377a.88.88 0 0 0-.877.876v6.684a.88.88 0 0 0 .877.877H3v2.576c0 .525.43.955.955.955h8.09c.525 0 .955-.43.955-.955V11.97h1.623a.88.88 0 0 0 .877-.877V4.408a.88.88 0 0 0-.877-.876z" fill="currentColor"/>
  </Icon>
)
Print.defaultProps = {
  size: 16
}

export default Print
