import React from 'react'
import Icon from 'react-icon-base'

const Custom21 = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="Custom21" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group-2" fill="#3498DB" transform="translate(4.280487, 3.263149)">
        <path d="M4.22199946,11.2413794 C4.7348353,11.2413794 5.15750662,11.6274195 5.21527173,12.1247582 L5.22199946,12.2413794 L5.22199946,16.9643017 L4.03900277,16.7440988 C1.78913359,16.3253083 1,16.8326025 1,18.4750963 C1,20.1042499 1.79444572,20.7341753 3.89604026,20.5604135 L4.11813045,20.5395793 L4.98507212,20.4490412 L5.19310243,21.2955094 C6.27090434,25.6810483 9.68092605,27.8494543 15.7259697,27.8494543 C16.2782545,27.8494543 16.7259697,28.2971695 16.7259697,28.8494543 C16.7259697,29.401739 16.2782545,29.8494543 15.7259697,29.8494543 C9.21262304,29.8494543 5.05158733,27.4335837 3.49794953,22.6428913 L3.478,22.579 L3.33629299,22.5810103 C0.660869124,22.5717129 -0.908633024,21.2306825 -0.996134841,18.7013527 L-1,18.4750963 C-1,15.8934924 0.476072325,14.566804 3.01042073,14.6251192 L3.221,14.633 L3.22199946,12.2413794 C3.22199946,11.7285435 3.60803965,11.3058722 4.10537834,11.2481071 L4.22199946,11.2413794 Z" id="Path-13" fillRule="nonzero"></path>
        <path d="M19.2219995,11.2413794 C19.7348353,11.2413794 20.1575066,11.6274195 20.2152717,12.1247582 L20.2219995,12.2413794 L20.2219995,16.9643017 L19.0390028,16.7440988 C16.7891336,16.3253083 16,16.8326025 16,18.4750963 C16,20.1042499 16.7944457,20.7341753 18.8960403,20.5604135 L19.1181304,20.5395793 L19.9850721,20.4490412 L20.1931024,21.2955094 C21.2709043,25.6810483 24.6809261,27.8494543 30.7259697,27.8494543 C31.2782545,27.8494543 31.7259697,28.2971695 31.7259697,28.8494543 C31.7259697,29.401739 31.2782545,29.8494543 30.7259697,29.8494543 C24.212623,29.8494543 20.0515873,27.4335837 18.4979495,22.6428913 L18.478,22.579 L18.336293,22.5810103 C15.6608691,22.5717129 14.091367,21.2306825 14.0038652,18.7013527 L14,18.4750963 C14,15.8934924 15.4760723,14.566804 18.0104207,14.6251192 L18.221,14.633 L18.2219995,12.2413794 C18.2219995,11.7285435 18.6080397,11.3058722 19.1053783,11.2481071 L19.2219995,11.2413794 Z" id="Path-13" fillRule="nonzero" transform="translate(22.862985, 20.545417) scale(-1, 1) translate(-22.862985, -20.545417) "></path>
        <g id="Group-2-Copy-2" transform="translate(3.719513, 8.736851)">
          <g id="Group" transform="translate(5.000000, 7.000000)">
            <circle id="Oval" cx="1.54545455" cy="1.54545455" r="1.54545455"></circle>
            <circle id="Oval-Copy" cx="11.6363636" cy="1.54545455" r="1.54545455"></circle>
          </g>
          <path d="M5.55491986,0.850024425 C5.69055932,-0.128796791 6.98979614,-0.321618612 7.42634943,0.512225058 L7.47627887,0.621820142 L8.772,3.923 L12.0064571,3.9234754 C12.519293,3.9234754 12.9419643,4.30951559 12.9997294,4.80685428 L13.0064571,4.9234754 C13.0064571,5.43631124 12.6204169,5.85898256 12.1230783,5.91674767 L12.0064571,5.9234754 L8.09090909,5.9234754 C7.72080255,5.9234754 7.38465561,5.71955737 7.21156531,5.39972539 L7.16008477,5.28894234 L7.011,4.909 L6.99053469,5.06073805 C6.92727824,5.51721841 6.56219,5.86607928 6.11372645,5.91704912 L6,5.9234754 L0.708545969,5.9234754 C0.15626122,5.9234754 -0.291454031,5.47576015 -0.291454031,4.9234754 C-0.291454031,4.41063956 0.0945861597,3.98796824 0.591924844,3.93020313 L0.708545969,3.9234754 L5.128,3.923 L5.55491986,0.850024425 Z" id="Path-14" fillRule="nonzero"></path>
          <path d="M16.0976773,0.850024425 C16.2333168,-0.128796791 17.5325536,-0.321618612 17.9691069,0.512225058 L18.0190363,0.621820142 L19.315,3.923 L22.5492146,3.9234754 C23.0620504,3.9234754 23.4847217,4.30951559 23.5424868,4.80685428 L23.5492146,4.9234754 C23.5492146,5.43631124 23.1631744,5.85898256 22.6658357,5.91674767 L22.5492146,5.9234754 L18.6336665,5.9234754 C18.26356,5.9234754 17.9274131,5.71955737 17.7543228,5.39972539 L17.7028422,5.28894234 L17.554,4.91 L17.5332921,5.06073805 C17.4700357,5.51721841 17.1049475,5.86607928 16.6564839,5.91704912 L16.5427574,5.9234754 L11.3745426,5.9234754 C10.8222578,5.9234754 10.3745426,5.47576015 10.3745426,4.9234754 C10.3745426,4.41063956 10.7605828,3.98796824 11.2579215,3.93020313 L11.3745426,3.9234754 L15.671,3.923 L16.0976773,0.850024425 Z" id="Path-14-Copy" fillRule="nonzero" transform="translate(16.961879, 2.955191) scale(-1, 1) translate(-16.961879, -2.955191) "></path>
        </g>
        <path d="M4.90952753,2.41795515 C7.60558441,-0.132697915 11.2537946,-1.24984676 15.784831,-0.953253269 C16.3359363,-0.917178906 16.7534514,-0.441175715 16.7173771,0.109929616 C16.6813027,0.661034946 16.2052995,1.07855004 15.6541942,1.04247568 C11.6475962,0.780210981 8.54731405,1.72957421 6.2840208,3.87080348 C2.97956694,6.99704145 1.10589876,11.7541971 1.00435582,16.9979582 L1,17.4485884 L1,29.494 L1.383,29.494 L1.98850121,27.3475862 C2.25239969,26.4117347 3.53580629,26.3797533 3.8766117,27.2382092 L3.91705832,27.3607893 L4.487,29.494 L5.469,29.494 L5.76530582,28.5503064 C6.01715232,27.746979 7.07092042,27.6082966 7.53412449,28.268497 L7.59900181,28.3735354 L8.205,29.494 L8.553,29.494 L8.98840118,28.46134 C9.18744005,27.9887047 9.7072638,27.7489938 10.1880362,27.888781 L10.2981267,27.927843 C10.770762,28.1268818 11.0104729,28.6467056 10.8706857,29.127478 L10.8316237,29.2375685 L10.1388071,30.8827224 C9.99827533,31.2164275 9.69007553,31.4450909 9.33654898,31.4874865 L9.21719585,31.4946081 L7.6097563,31.4946081 C7.36499559,31.4946081 7.13335589,31.4051928 6.9543534,31.2499095 L6.907,31.203 L6.84045251,31.265723 C6.69463002,31.3860924 6.5152387,31.4647941 6.32153537,31.4876845 L6.20374997,31.4946081 L3.7195126,31.4946081 C3.43133926,31.4946081 3.16605443,31.3713747 2.98109404,31.1690091 L2.929,31.105 L2.87264536,31.1753571 C2.71425147,31.3456865 2.49851932,31.4585744 2.26067811,31.487352 L2.14007818,31.4946081 L3.73034936e-14,31.4946081 C-0.512835839,31.4946081 -0.935507161,31.108568 -0.993272269,30.6112293 L-1,30.4946081 L-1,17.4485884 C-1,11.5048309 1.0872755,6.03406539 4.90952753,2.41795515 Z" id="Path-15" fillRule="nonzero"></path>
        <path d="M20.9095275,2.41795515 C23.5999893,-0.127404562 27.0120646,-1.25219328 31.073545,-0.952680698 C31.6243341,-0.912062929 32.0379097,-0.432632928 31.9972919,0.11815618 C31.9566741,0.668945288 31.4772441,1.08252087 30.926455,1.0419031 C27.409651,0.782557505 24.5529092,1.72428086 22.2840208,3.87080348 C18.9795669,6.99704145 17.1058988,11.7541971 17.0043558,16.9979582 L17,17.4485884 L17,29.494 L17.383,29.494 L17.9885012,27.3475862 C18.2523997,26.4117347 19.5358063,26.3797533 19.8766117,27.2382092 L19.9170583,27.3607893 L20.487,29.494 L21.469,29.494 L21.7653058,28.5503064 C22.0171523,27.746979 23.0709204,27.6082966 23.5341245,28.268497 L23.5990018,28.3735354 L24.205,29.494 L24.553,29.494 L24.9884012,28.46134 C25.1874401,27.9887047 25.7072638,27.7489938 26.1880362,27.888781 L26.2981267,27.927843 C26.770762,28.1268818 27.0104729,28.6467056 26.8706857,29.127478 L26.8316237,29.2375685 L26.1388071,30.8827224 C25.9982753,31.2164275 25.6900755,31.4450909 25.336549,31.4874865 L25.2171958,31.4946081 L23.6097563,31.4946081 C23.3649956,31.4946081 23.1333559,31.4051928 22.9543534,31.2499095 L22.907,31.203 L22.8404525,31.265723 C22.69463,31.3860924 22.5152387,31.4647941 22.3215354,31.4876845 L22.20375,31.4946081 L19.7195126,31.4946081 C19.4313393,31.4946081 19.1660544,31.3713747 18.981094,31.1690091 L18.929,31.105 L18.8726454,31.1753571 C18.7142515,31.3456865 18.4985193,31.4585744 18.2606781,31.487352 L18.1400782,31.4946081 L16,31.4946081 C15.4871642,31.4946081 15.0644928,31.108568 15.0067277,30.6112293 L15,30.4946081 L15,17.4485884 C15,11.5048309 17.0872755,6.03406539 20.9095275,2.41795515 Z" id="Path-15" fillRule="nonzero" transform="translate(23.500023, 15.247343) scale(-1, 1) translate(-23.500023, -15.247343) "></path>
      </g>
    </g>
  </Icon>
)
Custom21.defaultProps = {
  size: 30
}

export default Custom21