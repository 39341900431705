import React from 'react';
import moment from 'moment';
import { Field, FormSection, FieldArray, formValueSelector, change } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';
import { DateField, CheckboxField, SelectField } from 'jbc-front/components/Form';
import { BoxDouble } from 'jbc-front/components/Form';
import { LabelMapper } from 'jbc-front/components/FormErrors';
import { PlusSquare, DeleteSquare } from 'jbc-front/components/icons';
import { formName } from '../pages/ClientSetting';
import ToggleButton from '../components/ToggleButton';
import styles from './SubmissionDeadline.scss';

const submissionDeadlineSettings = [
  { value: false, label: '提出期日を設定しない' },
  { value: true, label: '提出期日を設定する' }
];
const SUBMISSION_DEADLINE_DAYS = [...Array(31)].map((_, i) => ({ label: `${i}`, value: `${i}` }));
const hours = (labelSuffix = '') => {
  return [...Array(24)].map((_, i) => {
    return { label: `${i}${labelSuffix}`, value: `${i}` };
  });
};

export const SubmissionDeadline = () => {
  const selector = formValueSelector('clientSetting');
  const hasSubmissionDeadline = useSelector(state => selector(state, 'hasSubmissionDeadline'));
  const useReminder = useSelector(state => selector(state, 'useReminder'));
  const dispatch = useDispatch();

  return (
    <>
      <p>年末調整の回答の提出期日を設定できます</p>
      <p>提出期日を設定した場合、提出期日時点で回答ができなくなります。提出期日を未来日で再設定すると再度入力が可能になります。</p>
      <ToggleButton options={submissionDeadlineSettings} name="hasSubmissionDeadline" />
      {hasSubmissionDeadline && (
        <>
          <BoxDouble style={{ width: '100px' }}>
            <DateField name="submissionDeadlineDate" label="年末調整 提出期限" required />
            <SelectField
              name="submissionDeadlineHour"
              label="年末調整 提出期限(時間)"
              noLabel
              className={styles.boxDoubleWrapper}
              options={hours('時')}
              placeholder=""
              required
            />
          </BoxDouble>
          <CheckboxField label="リマインドする" name="useReminder" />
          {useReminder && <FieldArray name="reminders" component={renderReminders} dispatch={dispatch} />}
        </>
      )}
    </>
  );
};

const renderReminders = ({ fields, dispatch }) => (
  <div>
    {fields.map((_, index) => (
      <div key={index}>{remindField(index, fields, dispatch)}</div>
    ))}
  </div>
);

const remindField = (index, fields, dispatch) => (
  <>
    <FormSection name={`reminders[${index}]`} className={styles.reminderForm}>
      <LabelMapper name="reminders" label="提出期日リマインド設定" />
      <table>
        <tbody>
          <tr>
            <td width="11%" className={styles.reminderText}>
              提出期日
            </td>
            <td width="20%">
              <SelectField
                name="remindDaysBefore"
                options={SUBMISSION_DEADLINE_DAYS}
                className={styles.reminderSelect}
                label="日数"
                noLabel
                placeholder=""
                required
              />
            </td>
            <td width="15%" className={styles.reminderText}>
              日前
              <Field component={ShowRemindDateField} name={`${index}に反映`} type="text" index={index} />
            </td>
            <td width="20%">
              <SelectField
                name="remindHour"
                options={hours()}
                className={styles.reminderSelect}
                label="時間"
                noLabel
                placeholder=""
                required
              />
            </td>
            <td width="15%" className={styles.reminderText}>
              時 に送信
            </td>
            <td width="20%" className={styles.reminderText}>
              {index === 0 ? (
                <>
                  <DeleteSquare
                    size={20}
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      if (fields.length > 1) {
                        fields.remove(index);
                      } else {
                        dispatch(change(formName, `reminders[${index}].remindDaysBefore`, ''));
                        dispatch(change(formName, `reminders[${index}].remindHour`, ''));
                      }
                    }}
                    onKeyPress={event => {
                      if (event.key === 'Enter' || event.key === ' ') {
                        if (fields.length > 1) {
                          fields.remove(index);
                        } else {
                          dispatch(change(formName, `reminders[${index}].remindDaysBefore`, ''));
                          dispatch(change(formName, `reminders[${index}].remindHour`, ''));
                        }
                      }
                    }}
                  />
                  <span
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      return fields.length < 3 ? fields.push({}) : fields;
                    }}
                    onKeyPress={event => {
                      if (event.key === 'Enter' || event.key === ' ') {
                        return fields.length < 3 ? fields.push({}) : fields;
                      }
                    }}
                  >
                    <PlusSquare className={styles.plusSquare} size={20} />
                    追加
                  </span>
                </>
              ) : (
                <DeleteSquare
                  size={20}
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    fields.remove(index);
                  }}
                  onKeyPress={event => {
                    if (event.key === 'Enter' || event.key === ' ') {
                      fields.remove(index);
                    }
                  }}
                />
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </FormSection>
  </>
);

const ShowRemindDateField = props => {
  const selector = formValueSelector('clientSetting');
  const remindDaysBefore = useSelector(state => selector(state, `reminders[${props.index}].remindDaysBefore`));
  const submissionDeadlineDate = useSelector(state => selector(state, 'submissionDeadlineDate'));
  const canDisplayDate = remindDaysBefore && submissionDeadlineDate;

  return (
    <>
      {canDisplayDate &&
        `(${moment(submissionDeadlineDate)
          .subtract(remindDaysBefore, 'days')
          .format('MM/DD')})`}
    </>
  );
};

export default SubmissionDeadline;
