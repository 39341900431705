import { FC } from 'react';
import { Grid } from '../../../components/Grid';
import { useResult } from '../ResultProvider';
import { Diff, FileDiff } from '../../../components/Diff';
import { dateFormat, amountFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { DeductionClassification, SpecialDeduction } from '../../../query';
import { PreviewSubSection } from '../../../components/PreviewSubSection';

import styles from './Preview.scss';

const DEDUCTION_CLASSIFICATIONS = {
  normal: '住 [一般の住宅借入金等特別控除（増改築等を含む）]',
  ceritified: '認 [認定住宅の新築等に係る住宅借入金等特別控除]',
  extension: '増 [特定増改築等住宅借入金等特別控除]',
  earthquake: '震 [震災特例法第13条の2第1項「住宅の再取得等に係る住宅借入金等特別控除」の規定の適用を選択した場合]',
  special_case_house_normal: '住(特家) [一般の住宅借入金等特別控除の場合（増改築等を含む。）で住宅が特例居住用家屋に該当するとき]',
  special_case_house_certified: '認（特家）[認定住宅等の新築等に係る住宅借入金等特別控除の場合で住宅が特例認定住宅等に該当するとき]',
  special_case_house_earthquake: '震（特家）[震災再取得等の適用を選択した場合で住宅が特例居住用家屋に該当するとき]'
};

const SPECIAL_DEDUCTIONS = {
  none: '対象外',
  applied: '特定取得',
  special_applied: '特別特定取得',
  special_exception_applied: '特例特別特例取得'
};

export const Preview: FC = () => {
  const { employee } = useResult();
  const housingLoan = employee.profile.housingLoan;
  const requestHousingLoan = employee.request?.profile?.housingLoan;

  const firstDetail = housingLoan?.housingLoanDetails?.find(housingLoan => housingLoan.no === 1);
  const requestFirstDetail = requestHousingLoan?.housingLoanDetails?.find(housingLoan => housingLoan.no === 1);

  const secondDetail = housingLoan?.housingLoanDetails.find(housingLoan => housingLoan.no === 2);
  const requestSecondDetail = requestHousingLoan?.housingLoanDetails?.find(housingLoan => housingLoan.no === 2);

  const deductionClassificationFormat = (deductionClassification?: DeductionClassification) => {
    if (deductionClassification === undefined) return undefined;
    return DEDUCTION_CLASSIFICATIONS[deductionClassification];
  };

  const specialDeductionFormat = (specialDeduction?: SpecialDeduction) => {
    if (specialDeduction === undefined) return undefined;
    return SPECIAL_DEDUCTIONS[specialDeduction];
  };

  return (
    <div className={styles.housingLoans}>
      <Grid>
        <Diff
          title="住宅借入金等 特別控除適用数"
          source={housingLoan?.housingLoanDeductionApplicableCount}
          input={requestHousingLoan?.housingLoanDeductionApplicableCount}
        />
        <Diff
          title="住宅借入金等特別控除額"
          source={amountFormat(housingLoan?.housingLoanDeductionApplicableAmount)}
          input={amountFormat(requestHousingLoan?.housingLoanDeductionApplicableAmount)}
        />
      </Grid>

      <Grid colGap col2>
        {(firstDetail || requestFirstDetail) && (
          <PreviewSubSection>
            <PreviewSubSection.Header>1回目</PreviewSubSection.Header>
            <PreviewSubSection.Body>
              <Diff
                title="居住開始年月日"
                source={dateFormat(firstDetail?.residenceStartOn, 'L')}
                input={dateFormat(requestFirstDetail?.residenceStartOn, 'L')}
              />
              <Diff
                title="住宅借入金等特別控除区分"
                source={deductionClassificationFormat(firstDetail?.deductionClassification)}
                input={deductionClassificationFormat(requestFirstDetail?.deductionClassification)}
              />
              <Diff
                title="住宅借入金等特別控除区分"
                source={specialDeductionFormat(firstDetail?.specialDeduction)}
                input={specialDeductionFormat(requestFirstDetail?.specialDeduction)}
              />
              <Diff
                title="住宅借入金等 年末残高"
                source={amountFormat(firstDetail?.yearEndBalance)}
                input={amountFormat(requestFirstDetail?.yearEndBalance)}
              />
            </PreviewSubSection.Body>
          </PreviewSubSection>
        )}

        {(secondDetail || requestSecondDetail) && (
          <PreviewSubSection>
            <PreviewSubSection.Header>2回目</PreviewSubSection.Header>
            <PreviewSubSection.Body>
              <Diff
                title="居住開始年月日"
                source={dateFormat(secondDetail?.residenceStartOn, 'L')}
                input={dateFormat(requestSecondDetail?.residenceStartOn, 'L')}
              />
              <Diff
                title="住宅借入金等特別控除区分"
                source={deductionClassificationFormat(secondDetail?.deductionClassification)}
                input={deductionClassificationFormat(requestSecondDetail?.deductionClassification)}
              />
              <Diff
                title="住宅借入金等特別控除区分"
                source={specialDeductionFormat(secondDetail?.specialDeduction)}
                input={specialDeductionFormat(requestSecondDetail?.specialDeduction)}
              />
              <Diff
                title="住宅借入金等 年末残高"
                source={amountFormat(secondDetail?.yearEndBalance)}
                input={amountFormat(requestSecondDetail?.yearEndBalance)}
              />
            </PreviewSubSection.Body>
          </PreviewSubSection>
        )}
      </Grid>

      <Grid>
        <FileDiff title="住宅借入金等特別控除申告書の画像" source={housingLoan?.image} input={requestHousingLoan?.image} />
        <FileDiff
          title="住宅ローンの年末残高証明書の画像 証明書画像"
          source={housingLoan?.certificateImage}
          input={requestHousingLoan?.certificateImage}
        />
      </Grid>
    </div>
  );
};
