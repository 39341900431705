import { gql } from '@apollo/client';
import { Schema } from './schema';

export type QueryVariables = {
  input: {
    employeeId: string;
    attributes: {
      mainJobIncome?: string;
      sideJobIncome?: string;
      businessIncome?: string;
      businessExpense?: string;
      miscellaneousIncomeOfficalPension?: string;
      miscellaneousIncomeOther?: string;
      miscellaneousExpense?: string;
      dividendIncome?: string;
      dividendExpense?: string;
      realEstateIncome?: string;
      realEstateExpense?: string;
      retirementIncome?: string;
      lengthOfService?: string;
      retireForDisablity?: boolean;
      isOfficerRetirementAllowance?: boolean;
      otherIncome?: string;
      otherExpense?: string;
      otherExpenseSpecialDeduction?: string;
      otherEarings?: string;
    };
  };
};

export const convertFormDataToQueryVariables = (employeeId: string, data: Schema): QueryVariables => {
  return {
    input: {
      employeeId,
      attributes: {
        mainJobIncome: data.mainJobIncome || '0',
        sideJobIncome: data.sideJobIncome || '0',
        businessIncome: data.businessIncome || '0',
        businessExpense: data.businessExpense || '0',
        miscellaneousIncomeOfficalPension: data.miscellaneousIncomeOfficalPension || '0',
        miscellaneousIncomeOther: data.miscellaneousIncomeOther || '0',
        miscellaneousExpense: data.miscellaneousExpense || '0',
        dividendIncome: data.dividendIncome || '0',
        dividendExpense: data.dividendExpense || '0',
        realEstateIncome: data.realEstateIncome || '0',
        realEstateExpense: data.realEstateExpense || '0',
        retirementIncome: data.retirementIncome || '0',
        lengthOfService: data.lengthOfService || '0',
        retireForDisablity: data.retireForDisablity,
        isOfficerRetirementAllowance: data.isOfficerRetirementAllowance,
        otherIncome: data.otherIncome || '0',
        otherExpense: data.otherExpense || '0',
        otherExpenseSpecialDeduction: data.otherExpenseSpecialDeduction || '0',
        otherEarings: data.otherEarings || '0'
      }
    }
  };
};

export const UPDATE_REQUEST_INCOME = gql`
  mutation UpdateIncome($input: UpdateIncomeInput!) {
    updateIncome(input: $input) {
      employee {
        id
      }
    }
  }
`;
