import React from 'react'
import Icon from 'react-icon-base'

const Custom10 = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="Custom10" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon/employees/Custom7-Copy-10" fill="#3498DB" fillRule="nonzero">
        <g id="Group-3" transform="translate(2.000000, 3.000000)">
          <path d="M18.027027,7.03067933 C23.5478574,7.03067933 28.0233747,11.5061966 28.0233747,17.027027 C28.0233747,22.5478574 23.5478574,27.0233747 18.027027,27.0233747 C12.5061966,27.0233747 8.03067933,22.5478574 8.03067933,17.027027 C8.03067933,11.5061966 12.5061966,7.03067933 18.027027,7.03067933 Z M18.027027,9.03067933 C13.6107661,9.03067933 10.0306793,12.6107661 10.0306793,17.027027 C10.0306793,21.4432879 13.6107661,25.0233747 18.027027,25.0233747 C22.4432879,25.0233747 26.0233747,21.4432879 26.0233747,17.027027 C26.0233747,12.6107661 22.4432879,9.03067933 18.027027,9.03067933 Z" id="Oval"></path>
          <g id="Group-2" transform="translate(17.592403, 0.000000)">
            <path d="M0.434623813,-0.565376187 C0.947459652,-0.565376187 1.37013097,-0.179335997 1.42789608,0.318002688 L1.43462381,0.434623813 L1.43462381,4.78086194 C1.43462381,5.33314669 0.986908563,5.78086194 0.434623813,5.78086194 C-0.0782120261,5.78086194 -0.500883348,5.39482175 -0.558648456,4.89748307 L-0.565376187,4.78086194 L-0.565376187,0.434623813 C-0.565376187,-0.117660937 -0.117660937,-0.565376187 0.434623813,-0.565376187 Z" id="Line-7"></path>
            <path d="M0.434623813,28.2731921 C0.947459652,28.2731921 1.37013097,28.6592323 1.42789608,29.156571 L1.43462381,29.2731921 L1.43462381,33.6194302 C1.43462381,34.171715 0.986908563,34.6194302 0.434623813,34.6194302 C-0.0782120261,34.6194302 -0.500883348,34.2333901 -0.558648456,33.7360514 L-0.565376187,33.6194302 L-0.565376187,29.2731921 C-0.565376187,28.7209074 -0.117660937,28.2731921 0.434623813,28.2731921 Z" id="Line-7-Copy"></path>
          </g>
          <g id="Group-2" transform="translate(17.750045, 17.380580) rotate(45.000000) translate(-17.750045, -17.380580) translate(17.250045, -0.119420)">
            <path d="M0.434623813,-0.565376187 C0.947459652,-0.565376187 1.37013097,-0.179335997 1.42789608,0.318002688 L1.43462381,0.434623813 L1.43462381,4.78086194 C1.43462381,5.33314669 0.986908563,5.78086194 0.434623813,5.78086194 C-0.0782120261,5.78086194 -0.500883348,5.39482175 -0.558648456,4.89748307 L-0.565376187,4.78086194 L-0.565376187,0.434623813 C-0.565376187,-0.117660937 -0.117660937,-0.565376187 0.434623813,-0.565376187 Z" id="Line-7"></path>
            <path d="M0.434623813,28.2731921 C0.947459652,28.2731921 1.37013097,28.6592323 1.42789608,29.156571 L1.43462381,29.2731921 L1.43462381,33.6194302 C1.43462381,34.171715 0.986908563,34.6194302 0.434623813,34.6194302 C-0.0782120261,34.6194302 -0.500883348,34.2333901 -0.558648456,33.7360514 L-0.565376187,33.6194302 L-0.565376187,29.2731921 C-0.565376187,28.7209074 -0.117660937,28.2731921 0.434623813,28.2731921 Z" id="Line-7-Copy"></path>
          </g>
          <g id="Group-2" transform="translate(17.592403, 17.054054) rotate(90.000000) translate(-17.592403, -17.054054) translate(17.092403, -0.445946)">
            <path d="M0.434623813,-0.565376187 C0.947459652,-0.565376187 1.37013097,-0.179335997 1.42789608,0.318002688 L1.43462381,0.434623813 L1.43462381,4.78086194 C1.43462381,5.33314669 0.986908563,5.78086194 0.434623813,5.78086194 C-0.0782120261,5.78086194 -0.500883348,5.39482175 -0.558648456,4.89748307 L-0.565376187,4.78086194 L-0.565376187,0.434623813 C-0.565376187,-0.117660937 -0.117660937,-0.565376187 0.434623813,-0.565376187 Z" id="Line-7"></path>
            <path d="M0.434623813,28.2731921 C0.947459652,28.2731921 1.37013097,28.6592323 1.42789608,29.156571 L1.43462381,29.2731921 L1.43462381,33.6194302 C1.43462381,34.171715 0.986908563,34.6194302 0.434623813,34.6194302 C-0.0782120261,34.6194302 -0.500883348,34.2333901 -0.558648456,33.7360514 L-0.565376187,33.6194302 L-0.565376187,29.2731921 C-0.565376187,28.7209074 -0.117660937,28.2731921 0.434623813,28.2731921 Z" id="Line-7-Copy"></path>
          </g>
          <g id="Group-2" transform="translate(17.711823, 16.711696) rotate(135.000000) translate(-17.711823, -16.711696) translate(17.211823, -0.788304)">
            <path d="M0.434623813,-0.565376187 C0.947459652,-0.565376187 1.37013097,-0.179335997 1.42789608,0.318002688 L1.43462381,0.434623813 L1.43462381,4.78086194 C1.43462381,5.33314669 0.986908563,5.78086194 0.434623813,5.78086194 C-0.0782120261,5.78086194 -0.500883348,5.39482175 -0.558648456,4.89748307 L-0.565376187,4.78086194 L-0.565376187,0.434623813 C-0.565376187,-0.117660937 -0.117660937,-0.565376187 0.434623813,-0.565376187 Z" id="Line-7"></path>
            <path d="M0.434623813,28.2731921 C0.947459652,28.2731921 1.37013097,28.6592323 1.42789608,29.156571 L1.43462381,29.2731921 L1.43462381,33.6194302 C1.43462381,34.171715 0.986908563,34.6194302 0.434623813,34.6194302 C-0.0782120261,34.6194302 -0.500883348,34.2333901 -0.558648456,33.7360514 L-0.565376187,33.6194302 L-0.565376187,29.2731921 C-0.565376187,28.7209074 -0.117660937,28.2731921 0.434623813,28.2731921 Z" id="Line-7-Copy"></path>
          </g>
        </g>
      </g>
    </g>
  </Icon>
)
Custom10.defaultProps = {
  size: 30
}

export default Custom10