import { FC, ReactNode } from 'react';

import styles from './PreviewSubSection.scss';
import classNames from 'classnames';

type Section = {
  children: ReactNode;
} & JSX.IntrinsicElements['div'];

const Section: FC<Section> = ({ children, className, ...rest }) => {
  return (
    <div className={classNames(className)} {...rest}>
      {children}
    </div>
  );
};

type HeaderProps = {
  children: ReactNode;
} & JSX.IntrinsicElements['div'];

const Header: FC<HeaderProps> = ({ children, className, ...rest }) => {
  return (
    <div className={classNames(styles.header, className)} {...rest}>
      {children}
    </div>
  );
};

type BodyProps = {
  children: ReactNode;
} & JSX.IntrinsicElements['div'];

const Body: FC<BodyProps> = ({ children, className, ...rest }) => {
  return (
    <div className={classNames(styles.body, className)} {...rest}>
      {children}
    </div>
  );
};

export const PreviewSubSection = Object.assign(Section, {
  Header,
  Body
});
