import { gql } from '@apollo/client';

export const UPDATE_CLIENT_CITY = gql`
  mutation updateClientCity($input: UpdateClientCityInput!) {
    updateClientCity(input: $input) {
      client {
        id
      }
    }
  }
`;
