export const DELETE_MODE = 'delete';
export const REQUEST_MODE = 'request';
export const CHANGE_STATUS_MODE = 'change_status';

export const STATUSES = {
  not_requested: '未依頼',
  not_need_adj: '対象外',
  in_progress: '依頼中',
  applying: '入力済',
  rejected: '再依頼中',
  fixed: '修正済',
  accepted: '完了'
};
