import { FC, ReactNode } from 'react';
import { Section } from 'components/ui/Section';
import { Separator } from 'components/ui/Separator';

import { Question } from '../type';

import { Lead } from './parts/Lead';
import { Description } from './parts/Description';
import { Note } from './parts/Note';
import { Content } from './parts/Content';

import { Hint } from 'jbc-front/components/presenters/ui/Hint';
import { Spacer } from 'jbc-front/components/presenters/Spacer';

type WorkingStudentProps = {
  children: ReactNode;
};

const MainSection: FC<WorkingStudentProps> = ({ children }) => {
  return (
    <Section>
      <Section.Header>勤労学生情報</Section.Header>

      <Section.Body>
        <Content>{children}</Content>
      </Section.Body>
    </Section>
  );
};

type CheckWorkingStudentProps = {
  question: Question;
  children: ReactNode;
};

const CheckWorkingStudent: FC<CheckWorkingStudentProps> = ({ question, children }) => {
  const description = question.questionDescriptions.find(description => description.field == 'under_note')?.description;

  return (
    <>
      <Lead>あなたは以下の条件を満たしている、勤労学生ですか？</Lead>

      <Description>{description}</Description>

      <Note>
        <ul>
          <li>
            ・今年の合計所得金額75万円以下&emsp;※給与収入のみの場合、給与収入が130万円以下
            <Spacer direction="x" size={6} />
            <Hint>
              <p>
                「合計所得金額」とは、各種損失の繰越控除を適用する前の総所得金額、譲渡所得、配当所得、雑所得、山林所得、退職所得の合計額をいいます。
                <br />
                「特定の学校」とは、次のいずれかの学校です。
              </p>
              <ul className="m-list-notes">
                <li>イ 学校教育法に規定する小学校、中学校、高等学校、大学、高等専門学校など</li>
                <li>ロ 国、地方公共団体、学校法人等により設置された専修学校又は各種学校のうち一定の課程を履修させるもの</li>
                <li>ハ 職業能力開発促進法の規定による認定職業訓練を行う職業訓練法人で一定の課程を履修させるもの</li>
              </ul>
            </Hint>
          </li>
          <li>・今年の給与所得以外の所得が10万円以下</li>
          <li>・特定の学校の学生、生徒であること</li>
        </ul>
      </Note>

      {children}
    </>
  );
};

type DetailInputProps = {
  children: ReactNode;
};

const DetailInput: FC<DetailInputProps> = ({ children }) => {
  return (
    <>
      <Separator />
      <Lead>以下の情報の記入が必要です。</Lead>
      {children}
    </>
  );
};

export const WorkingStudent = Object.assign(MainSection, { CheckWorkingStudent, DetailInput });
