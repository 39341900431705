import React from 'react';
import _ from 'lodash';
import Button from 'jbc-front/components/Button';
import { useMutation, useQuery } from '../components/Graphql';
import { gql } from '@apollo/client';
import { FieldArray, FormSection } from 'redux-form';
import { SelectField, TextField, DateField, Section, FileField } from 'jbc-front/components/Form';
import { AmountField } from '../components/FieldWithDiff';
import { toFormValues } from '../utils/form';
import FormAdd from '../components/FormAdd';
import { DeleteSquare } from 'jbc-front/components/icons';
import { reduxForm, useFormValue } from '../components/FormName';
import { amount } from '../validators';
import { Panel, Headding, Item, Lead, Buttons, Line } from '../components/PrimaryPanel';
import { LabelMapper } from 'jbc-front/components/FormErrors';
import LoadingPage from '../components/LoadingPage';
import useReactRouter from 'use-react-router';
import { useYearMasters } from '../components/useYearMaster';
import { CustomDescription } from '../components/QuestionDescription';
import styles from './FormerJobs.scss';
import FilePreview from '../components/FilePreview';
import { amountFormat } from '@jbc-year-end-adj/common/utils/formatter';

export const FORMER_JOBS_FRAGMENT = gql`
  fragment FormerJobs on Profile {
    formerJobs {
      id
      companyName
      address
      lastSalaryOn
      resignedOn
      withholdingSlipStatus
      deductionAmount
      paymentAmount
      taxAmount
      compareKey
      withholdingSlipImage {
        url
        filename
      }
    }
  }
`;

const YEAR = gql`
  query employeeYear {
    request {
      id
      employee {
        id
        year
      }
    }
    clientSetting {
      id
      requestImageUploadSettings {
        previousWithholdingSlip
      }
    }
  }
`;

const FORMER_JOBS = gql`
  query formerJobs {
    request {
      id
      profile {
        id
        ...FormerJobs
      }
    }
  }
  ${FORMER_JOBS_FRAGMENT}
`;

const ANSWER_FORMER_JOBS = gql`
  mutation answerFormerJobs($formerJobs: [FormerJobInput!]!) {
    answerFormerJobs(input: { formerJobs: $formerJobs }) {
      questions {
        id
        status
      }
      request {
        id
        profile {
          id
          ...FormerJobs
        }
      }
    }
  }
  ${FORMER_JOBS_FRAGMENT}
`;

const EMPLOYEE_OFFICE = gql`
  query employeeOffice {
    request {
      id
      employee {
        id
        office {
          id
          name
        }
      }
    }
  }
`;

export const withholdingSlipStatuses = [
  { value: 'submitted', label: '提出済み' },
  { value: 'not_submitted', label: '手元にある' },
  { value: 'in_order', label: '取り寄せ中' },
  { value: 'unavailable', label: '入手できない' }
];

const FormerJob = ({ field }) => {
  const withholdingSlipStatus = useFormValue(`${field}.withholdingSlipStatus`);
  const { loading, data } = useQuery(YEAR);
  const [yearMaster] = useYearMasters({ year: _.get(data, 'request.employee.year'), toHeiseiEra: true });
  if (loading || !yearMaster) return null;
  const {
    clientSetting: {
      requestImageUploadSettings: { previousWithholdingSlip: previousWithholdingSlipImageSetting }
    }
  } = data;
  return (
    <FormSection name={field}>
      <TextField name="companyName" label="会社名" required />
      <TextField name="address" label="住所" description="前職の住所を全角文字で入力してください。" />
      <DateField name="resignedOn" label="退職日" required />
      <DateField name="lastSalaryOn" label="最後の給与支給日" />
      <SelectField
        name="withholdingSlipStatus"
        label="源泉徴収票"
        required
        options={withholdingSlipStatuses}
        description={`前職からもらった${yearMaster.eraName}${yearMaster.eraNumber}年の源泉徴収票の原本は会社に提出する必要があります。年度が正しいか改めてご確認ください。退職所得の源泉徴収票は不要です。`}
      />
      {withholdingSlipStatus === 'not_submitted' && (
        <>
          {previousWithholdingSlipImageSetting !== 'none' && (
            <FileField
              name="withholdingSlipImage"
              label="源泉徴収票画像"
              description="提出済みの場合アップロードは不要です。"
              required={previousWithholdingSlipImageSetting === 'required'}
              preview={FilePreview}
              disablePreview={false}
            />
          )}
          <AmountField
            name="paymentAmount"
            label="支払金額"
            required
            validate={amount}
            description="源泉徴収票の「支払金額」欄に記載されている金額です。提出済みの場合記載は不要です。"
          />
          <AmountField
            name="deductionAmount"
            label="社会保険料等の金額"
            validate={amount}
            required
            description="源泉徴収票の「社会保険料等の金額」欄に記載されている金額です。提出済みの場合記載は不要です。"
          />
          <AmountField
            name="taxAmount"
            label="源泉徴収税額"
            validate={amount}
            required
            description="源泉徴収票の「源泉徴収税額」欄に記載されている金額です。提出済みの場合記載は不要です。"
          />
        </>
      )}
    </FormSection>
  );
};

const FormerJobsFields = ({ fields, handleSubmit, submitting }) => {
  const { history } = useReactRouter();
  const [answer, { loading }] = useMutation(ANSWER_FORMER_JOBS);
  const { data: dataOffice } = useQuery(EMPLOYEE_OFFICE);
  const formerJobs = useFormValue('formerJobs');

  const officeName = dataOffice?.request?.employee?.office?.name || '';
  const isUnavailable = formerJobs?.some(formerJob => formerJob.withholdingSlipStatus === 'unavailable');
  return (
    <Panel>
      <Headding className={styles.primaryPanelHeader}>前職情報</Headding>
      <Item>
        <Lead>今年給料をもらった前職は有りますか？</Lead>
        <CustomDescription field="top" />
        <Buttons>
          <Button
            huge
            secondary
            disabled={fields.length > 0}
            onClick={() => {
              fields.push({});
            }}
          >
            はい
          </Button>
          <Button
            huge
            secondary
            disabled={loading || submitting}
            onClick={async () => {
              await answer({ variables: { formerJobs: [] } });
              history.push('/employee_input');
            }}
          >
            いいえ
          </Button>
        </Buttons>
        {fields.length > 0 && (
          <>
            <Line />
            <Lead>以下の情報の記入が必要です。</Lead>
            {fields.map((field, index) => (
              <div key={index}>
                <Section title={`前職情報(${index + 1})`} icon={<DeleteSquare onClick={() => fields.remove(index)} />}>
                  <FormerJob field={field} />
                </Section>
              </div>
            ))}
            <div>
              {fields.length < 10 && (
                <FormAdd
                  onClick={() => {
                    fields.push({});
                  }}
                  name="前職情報"
                />
              )}
              {isUnavailable && (
                <div className={styles.alert}>
                  <h1>
                    源泉徴収票が提出できない場合、<span className={styles.officeName}>{officeName}</span>で<br />
                    年末調整ができません。
                  </h1>
                  <p>
                    自身で確定申告をしてください。 会社への原本（保険料控除や住宅ローン控除などの証明書）提出は不要です。
                    「扶養控除等（異動）申告書」はジョブカン年末調整で作成するので、「次へ」を押して最後まで回答してください
                  </p>
                </div>
              )}
              <Buttons>
                <Button primary onClick={handleSubmit} disabled={loading || submitting}>
                  次ヘ
                </Button>
              </Buttons>
            </div>
          </>
        )}
      </Item>
    </Panel>
  );
};

const QuestionForm =
  (({ dispatch, handleSubmit, submitting }) => (
    <>
      <LabelMapper name="formerJobs" label="前職情報" />
      <FieldArray name="formerJobs" component={FormerJobsFields} handleSubmit={handleSubmit} submitting={submitting} />
    </>
  )) |> reduxForm({ form: 'formerJobs' });

const formatValues = profile => {
  return (profile.formerJobs || []).map(v => {
    const formattedJob = toFormValues(v);

    return {
      ...formattedJob,
      paymentAmount: amountFormat(formattedJob.paymentAmount),
      deductionAmount: amountFormat(formattedJob.deductionAmount),
      taxAmount: amountFormat(formattedJob.taxAmount)
    };
  });
};

export const makeInitialValues = profile => formatValues(profile);

const Question = ({ handleSubmit }) => {
  const { loading, data } = useQuery(FORMER_JOBS);
  const [answer] = useMutation(ANSWER_FORMER_JOBS);
  const { history } = useReactRouter();
  if (loading) return <LoadingPage />;
  return (
    <QuestionForm
      initialValues={{
        formerJobs: makeInitialValues(data.request.profile || {})
      }}
      onSubmit={async values => {
        await answer({ variables: { formerJobs: values.formerJobs || [] } });
        history.push('/employee_input');
      }}
    />
  );
};

export default Question;
