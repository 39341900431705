import { FC } from 'react';
import { MultiCompanies as MultiCompaniesTemplate } from 'features/questions/templates/MultiCompanies';
import { ButtonContainer } from 'features/questions/components/ButtonContainer';
import Button from 'jbc-front/components/Button';
import { useWizard } from '../WizardProvider';

export const MultiCompanies: FC = () => {
  const { question, request } = useWizard();

  return (
    <MultiCompaniesTemplate question={question}>
      <ButtonContainer>
        <Button huge primary={request.profile?.workForMultiCompanies} secondary={!request.profile?.workForMultiCompanies} disabled>
          はい
        </Button>
        <Button huge primary={!request.profile?.workForMultiCompanies} secondary={request.profile?.workForMultiCompanies} disabled>
          いいえ
        </Button>
      </ButtonContainer>
    </MultiCompaniesTemplate>
  );
};
