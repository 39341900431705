import { FC, ReactNode } from 'react';
import classnames from 'classnames';

import styles from './Tag.scss';

type TagProps = {
  color: 'blue' | 'red' | 'green';
  children: ReactNode;
};

export const Tag: FC<TagProps> = ({ color, children }) => {
  return <div className={classnames(styles.tag, styles[color])}>{children}</div>;
};
