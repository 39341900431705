import { FC } from 'react';
import Button from 'jbc-front/components/Button';

import { Section } from '@jbc-year-end-adj/2024/components/ui/Section';
import { Question } from '@jbc-year-end-adj/2024/features/questions/types';
import { TITLE, Content } from '@jbc-year-end-adj/2024/features/questions/templates/SalaryIncome';
import { ButtonContainer } from '@jbc-year-end-adj/2024/features/questions/components/ButtonContainer';

import { EditModal } from '../EditModal';
import { EmptyBody } from '../EmptyBody';
import { filteredDescritpions } from './utils';
import styles from './Questions.scss';

type SalaryIncomeProps = {
  question: Question;
};

export const SalaryIncome: FC<SalaryIncomeProps> = ({ question }) => {
  const descriptions = filteredDescritpions(question, ['top']);

  return (
    <Section>
      <Section.Header className={styles.header}>
        {TITLE}
        <EditModal questionId={String(question.id)} descriptions={descriptions} />
      </Section.Header>
      <Section.Body>
        <Content question={question} initiallyOpen={true}>
          <EmptyBody />
          <ButtonContainer>
            <Button primary disabled>
              次へ
            </Button>
          </ButtonContainer>
        </Content>
      </Section.Body>
    </Section>
  );
};
