import React from 'react'
import Icon from 'react-icon-base'

const DocumentPreparation = props => (
  <Icon viewBox="0 0 20 20" {...props}>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="sidemenu/open-subMenuOpen" transform="translate(-15.000000, -672.000000)" fill="currentColor" fillRule="nonzero">
        <g id="書類作成" transform="translate(0.000000, 656.000000)">
          <g id="Group-7" transform="translate(15.000000, 16.000000)">
            <g id="icon" transform="translate(10.000000, 10.000000) rotate(-180.000000) translate(-10.000000, -10.000000) translate(-0.000000, -0.000000)">
              <path d="M12.9579071,0 C14.0780057,0 15,0.881691114 15,1.987801 C15,2.46519968 14.6116755,2.85220778 14.1326531,2.85220778 C13.6536306,2.85220778 13.2653061,2.46519968 13.2653061,1.987801 C13.2653061,1.87210964 13.1700288,1.76446984 13.0308922,1.73607666 L12.9579071,1.72881356 L2.04209291,1.72881356 C1.99451674,1.72881356 1.95034776,1.73742718 1.91135001,1.75310894 C1.82281677,1.78898881 1.76287679,1.85597746 1.74244213,1.9306868 L1.73469388,1.987801 L1.73469388,16.012199 C1.73469388,16.1278904 1.82997121,16.2355302 1.96910775,16.2639233 L2.04209291,16.2711864 L3.8987111,16.2711864 C4.37773359,16.2711864 4.76605804,16.6581945 4.76605804,17.1355932 C4.76605804,17.5762689 4.43517804,17.9399254 4.00750926,17.993265 L3.8987111,18 L2.04209291,18 C0.972907887,18 0.0842269713,17.1966409 0.00564685713,16.1615794 L0,16.012199 L0,1.987801 C0,1.17335328 0.506363419,0.456219459 1.26002656,0.150785603 C1.44501584,0.0763823543 1.64004372,0.0287670769 1.84028818,0.00960964133 L2.04209291,0 L12.9579071,0 Z" id="Stroke-1"></path>
              <path d="M17.9987489,3.29829562 C19.0335024,3.29829562 19.9124906,4.04468026 19.9938574,5.03379926 L20,5.18392466 L20,13.7191884 C20,13.7914103 19.9909927,13.8615284 19.9740402,13.9284806 L19.9354399,14.0441256 L19.9004092,14.1187777 L19.8477083,14.2048117 L19.8252067,14.2355232 L19.7854794,14.2837072 L14.3319559,19.74881 C13.8221895,20.2594696 12.9667929,19.9399509 12.8864825,19.2538951 L12.8803892,19.1482956 L12.8803892,14.7548175 C12.8803892,13.9674671 13.3906906,13.2875307 14.1299186,13.0066442 C14.3078301,12.9386611 14.4954661,12.8953338 14.6878534,12.877919 L14.8816403,12.8691884 L18.3,12.8682956 L18.3,5.18392466 C18.3,5.11416735 18.2148608,5.02761348 18.0738027,5.00430742 L17.9987489,4.99829562 L7.30125105,4.99829562 C7.24812048,4.99829562 7.19877243,5.00734844 7.15572384,5.0236329 C7.07172634,5.05565552 7.02246158,5.10531169 7.00611558,5.15086685 L7,5.18392466 L7,18.1126666 C7,18.1824239 7.08513921,18.2689778 7.22619735,18.2922838 L7.30125105,18.2982956 L10.973884,18.2982956 C11.4433261,18.2982956 11.823884,18.6788536 11.823884,19.1482956 C11.823884,19.5816267 11.4996216,19.9392223 11.0805062,19.9916729 L10.973884,19.9982956 L7.30125105,19.9982956 C6.26649756,19.9982956 5.3875094,19.251911 5.30614262,18.262792 L5.3,18.1126666 L5.3,5.18392466 C5.3,4.39693361 5.81017897,3.71725062 6.55214611,3.43439115 C6.72987183,3.367149 6.91669808,3.32421634 7.10826517,3.30695264 L7.30125105,3.29829562 L17.9987489,3.29829562 Z M17.1,14.5682956 L14.8816403,14.5691884 C14.828701,14.5691884 14.7798746,14.5781692 14.73524,14.5952233 C14.6516275,14.6269939 14.6026905,14.6762714 14.5864602,14.7217519 L14.5803892,14.7548175 L14.58,17.0932956 L17.1,14.5682956 Z" id="Stroke-1"></path>
              <path d="M16,7.15 C16.469442,7.15 16.85,7.53055796 16.85,8 C16.85,8.43333111 16.5257376,8.79092668 16.1066222,8.84337729 L16,8.85 L9,8.85 C8.53055796,8.85 8.15,8.46944204 8.15,8 C8.15,7.56666889 8.47426241,7.20907332 8.89337781,7.15662271 L9,7.15 L16,7.15 Z" id="Stroke-3"></path>
              <path d="M16,10.15 C16.469442,10.15 16.85,10.530558 16.85,11 C16.85,11.4333311 16.5257376,11.7909267 16.1066222,11.8433773 L16,11.85 L9,11.85 C8.53055796,11.85 8.15,11.469442 8.15,11 C8.15,10.5666689 8.47426241,10.2090733 8.89337781,10.1566227 L9,10.15 L16,10.15 Z" id="Stroke-5"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </Icon>
)

DocumentPreparation.defaultProps = {
  size: 30
}

export default DocumentPreparation
