import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { Note } from 'jbc-front/components/presenters/ui/Note';
import { ReadMoreReadLess } from 'jbc-front/components/presenters/ui/ReadMoreReadLess';

import { Grid } from '../../../../../../components/Grid';
import { FormSeparator } from '../../../../../../components/FormSeparator';
import { Section } from 'components/ui/Section';
import { FormSection } from 'components/form/FormSection';
import { Radio } from 'components/form/Radio';
import { Textarea } from 'components/form/Textarea';
import { FileField } from 'components/react-hook-form/FileField';
import { FormField } from 'components/form/FormField';

import { Schema } from '../../schema';
import { DifferentAddress } from './ThisYearDifferentAddress';
import { DifferentAndForeignAddress } from './ThisYearDifferentAndForeignAddress';
import { Income } from './Income';

export const ThisYear: FC = () => {
  const {
    formState: { errors },
    register,
    watch,
    setValue
  } = useFormContext<Schema>();

  const residenceStatus = watch('spouse.thisYear.yearlyInfo.residenceStatus');
  const handicapType = watch('spouse.thisYear.yearlyInfo.handicapType');
  const handicapImage = watch('spouse.thisYear.yearlyInfo.handicapImage');
  const dependentTaxLaw = watch('spouse.thisYear.dependentTaxLaw');
  const hasSpouseRetirementIncome = watch('spouse.thisYear.hasSpouseRetirementIncome');

  return (
    <Section>
      <Section.Header>今年の扶養情報</Section.Header>
      <Section.Body>
        <FormSection>
          <Grid>
            <FormField>
              <Radio.Container
                required
                label="今年の源泉控除対象、配偶者特別控除対象、または障害者控除対象"
                error={errors.spouse?.thisYear?.dependentTaxLaw?.message}
              >
                <Radio value="true" {...register('spouse.thisYear.dependentTaxLaw')}>
                  対象
                </Radio>
                <Radio value="false" {...register('spouse.thisYear.dependentTaxLaw')}>
                  対象外
                </Radio>
              </Radio.Container>
              <FormField.NoteContainer>
                <Note>
                  税法上の扶養対象については
                  <a href="https://jobcan-lms.zendesk.com/hc/ja/articles/360018787951" rel="noopener noreferrer" target="_blank">
                    こちら
                  </a>
                  をご確認ください。
                </Note>
              </FormField.NoteContainer>
            </FormField>
          </Grid>

          {dependentTaxLaw === 'false' && (
            <>
              <Grid colGap>
                <Radio.Container label="今年の税の扶養対象ではないが、退職手当を有する配偶者" required>
                  <Radio value="true" {...register('spouse.thisYear.hasSpouseRetirementIncome')}>
                    対象
                  </Radio>
                  <Radio value="false" {...register('spouse.thisYear.hasSpouseRetirementIncome')}>
                    対象外
                  </Radio>
                </Radio.Container>
              </Grid>
              <Note>
                <ReadMoreReadLess maxLength={19}>
                  扶養控除等(異動)申告書の「退職手当を有する配偶者・扶養親族」に反映されます。
                  <br />
                  所得税の計算上では扶養対象外でも住民税の計算上は扶養対象となる場合があります。
                </ReadMoreReadLess>
              </Note>
            </>
          )}
          {(dependentTaxLaw === 'true' || hasSpouseRetirementIncome === 'true') && (
            <>
              <FormSeparator />

              <Grid colGap>
                <Radio.Container label="同居・別居" required error={errors.spouse?.thisYear?.yearlyInfo?.residenceStatus?.message}>
                  <Radio value="same_address" {...register('spouse.thisYear.yearlyInfo.residenceStatus')}>
                    同居
                  </Radio>
                  <Radio value="different_address" {...register('spouse.thisYear.yearlyInfo.residenceStatus')}>
                    別居（国内）
                  </Radio>
                  <Radio value="different_and_foreign_address" {...register('spouse.thisYear.yearlyInfo.residenceStatus')}>
                    別居（国外）
                  </Radio>
                </Radio.Container>
              </Grid>

              {residenceStatus === 'different_address' && <DifferentAddress />}
              {residenceStatus === 'different_and_foreign_address' && <DifferentAndForeignAddress />}

              <FormSeparator />

              <Grid>
                <Radio.Container label="障害者区分" required error={errors.spouse?.thisYear?.yearlyInfo?.handicapType?.message}>
                  <Radio value="none" {...register('spouse.thisYear.yearlyInfo.handicapType')}>
                    対象外
                  </Radio>
                  <Radio value="normal" {...register('spouse.thisYear.yearlyInfo.handicapType')}>
                    一般障害者
                  </Radio>
                  <Radio value="special" {...register('spouse.thisYear.yearlyInfo.handicapType')}>
                    特別障害者
                  </Radio>
                </Radio.Container>
                {['normal', 'special'].includes(handicapType) && (
                  <>
                    <Textarea label="詳細" rows={3} {...register('spouse.thisYear.yearlyInfo.handicapDetail')} />

                    <FileField label="障害者手帳画像" name={'spouse.thisYear.yearlyInfo.handicapImage'}>
                      <FileField.FileContainer>
                        {handicapImage && (
                          <FileField.File
                            file={handicapImage}
                            onRemove={() => setValue('spouse.thisYear.yearlyInfo.handicapImage', undefined)}
                          />
                        )}
                      </FileField.FileContainer>
                    </FileField>
                  </>
                )}
              </Grid>

              <FormSeparator />
              <Income />
            </>
          )}
        </FormSection>
      </Section.Body>
    </Section>
  );
};
