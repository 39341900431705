import { FC, ReactNode } from 'react';
import classnames from 'classnames';

import { FileType } from '@jbc-year-end-adj/types';
import { displayFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { FileLink } from 'components/ui/FileLink';
import styles from './DefinitionList.scss';
import { FilePreview } from 'components/feature/FilePreview';

type DlProps = {
  children: ReactNode;
} & JSX.IntrinsicElements['dl'];

export const Dl: FC<DlProps> = ({ children, className }) => {
  return <dl className={classnames(styles.dl, className)}>{children}</dl>;
};

type FileDlProps = {
  children: ReactNode;
};

export const FileDl: FC<FileDlProps> = ({ children }) => {
  return <Dl className={styles.fileDl}>{children}</Dl>;
};

type DtProps = {
  children: ReactNode;
} & JSX.IntrinsicElements['dt'];

export const Dt: FC<DtProps> = ({ children, className }) => {
  return <dt className={classnames(styles.dt, className)}>{children}</dt>;
};

type DdProps = {
  children: ReactNode;
  className?: string;
} & JSX.IntrinsicElements['dd'];

export const Dd: FC<DdProps> = ({ children, className }) => {
  return <dd className={classnames(styles.dd, className)}>{children}</dd>;
};

type FileProps = {
  file: FileType | undefined;
};

export const FileDd: FC<FileProps> = ({ file }) => {
  if (!file) return <Dd>{displayFormat()}</Dd>;

  if ((file.url && file.filename) || file.file) {
    return (
      <Dd>
        <FileLink file={file} />
        <FilePreview file={file} />
      </Dd>
    );
  }

  return <Dd>{displayFormat(file.filename || file.file)}</Dd>;
};
