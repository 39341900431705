import { FC } from 'react';

// @ts-ignore
import styles from './Preparing.scss';

export const Preparing: FC = () => {
  return (
    <div className={styles.container}>
      <p className={styles.preparing}>準備中です</p>
    </div>
  );
};
