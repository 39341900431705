import { FC } from 'react';
import { Navigator } from '@jbc-year-end-adj/2024/components/ui/Navigator';
import { Anchor } from '@jbc-year-end-adj/2024/components/ui/Anchor';
import styles from './SideMenu.scss';

export const SideMenu: FC = () => {
  return (
    <Navigator>
      <Navigator.Body>
        <Navigator.Item className={styles.naviItem}>
          <Anchor name="request_print">印刷依頼設定</Anchor>
        </Navigator.Item>

        <Navigator.Item className={styles.naviItem}>
          <Anchor name="request_image_upload">添付データ設定</Anchor>
        </Navigator.Item>

        <Navigator.Item className={styles.naviItem}>
          <Anchor name="employee_address_classification">出力住所設定</Anchor>
        </Navigator.Item>

        <Navigator.Item className={styles.naviItem}>
          <Anchor name="accepted_email_status">承認メール設定</Anchor>
        </Navigator.Item>

        <Navigator.Item className={styles.naviItem}>
          <Anchor name="submission_deadline_settings">提出期日設定</Anchor>
        </Navigator.Item>

        <Navigator.Item className={styles.naviItem}>
          <Anchor name="request_data">データ取得</Anchor>
        </Navigator.Item>
      </Navigator.Body>
    </Navigator>
  );
};
