import React, { useState } from 'react';
import { useQuery, useMutation, Modal, useYear } from '../components';
import Button from 'jbc-front/components/Button';
import Download from 'jbc-front/components/icons/Download';
import Loading from '../components/Loading';
import { PollingController } from '../city_report/Report';
import styles from './DownloadWithholdSlips.scss';
import moment from 'moment';
import { renderCheckBoxField as CheckboxField, renderRadioField as RadioField } from 'jbc-front/components/Form';
import _ from 'lodash';
import useReactRouter from 'use-react-router';
import { parse, stringify } from 'query-string';
import { useNotify } from '../actions';

const ShowMyNumber = ({ showMyNumber, setShowMyNumber }) => (
  <div className="u-mt10">
    <CheckboxField
      meta={{}}
      input={{
        value: showMyNumber,
        onChange(e) {
          setShowMyNumber(e.target.value === 'false' ? true : false);
        }
      }}
      label="マイナンバーを出力する"
    />
  </div>
);

const DownloadFormat = ({ downloadFormat, setDownloadFormat }) => (
  <div className="u-mb10">
    <RadioField
      meta={{}}
      input={{
        value: downloadFormat,
        onChange(e) {
          setDownloadFormat(e.target.value);
        }
      }}
      name="downloadFormat"
      label="作成形式"
      required
      options={[
        { value: 'pdf', label: 'PDF形式(複数人1ファイル)' },
        { value: 'zip', label: 'ZIP形式(1人1ファイル)' }
      ]}
    />
  </div>
);

const reportTypeName = (reportType, downloadFormat) => {
  if (reportType) {
    return reportType;
  }

  if (downloadFormat === 'pdf') {
    return 'bulk_withholding_slip_for_employee';
  } else {
    return 'withholding_slip_for_employee';
  }
};

export const DownloadWithholdSlips = ({
  query,
  mutation,
  getReport,
  title,
  callMutation,
  reportType,
  showMyNumber: myNumberShown,
  hasMyNumber,
  hasMyNumberPermission,
  disabled,
  showDownloadFormat
}) => {
  const [showMyNumber, setShowMyNumber] = useState(!!myNumberShown);
  const year = useYear();
  const notify = useNotify();
  const { loading: loadingQuery, data, startPolling, stopPolling } = useQuery(query, {
    fetchPolicy: 'network-only',
    variables: { year }
  });
  const {
    history,
    location: { pathname, search: querySearch }
  } = useReactRouter();
  const { bulk_report: bulkReport } = parse(querySearch);
  const [downloadFormat, setDownloadFormat] = useState(bulkReport === 'withholding_slip' ? 'zip' : 'pdf');
  const [isOpen, setIsOpen] = useState(!!bulkReport);
  const hideModal = () => {
    setIsOpen(false);
    if (bulkReport) {
      history.replace({ pathname, search: stringify(_.omit(parse(querySearch), 'bulk_report')) });
    }
  };
  const [makeReport, { loading }] = useMutation(mutation);
  if (loadingQuery) return null;
  const report = getReport(data, downloadFormat);

  const makingReport = report && (report.status === 'in_process' || report.status === 'waiting');
  const hasReport = report && (report.status === 'success' || report.status === 'failed');
  return (
    <Modal>
      {() => (
        <>
          {!makingReport && (
            <Modal.Modal isOpen={isOpen}>
              <Modal.Header hideModal={hideModal}>
                源泉徴収票(
                {title}
                )一括作成
              </Modal.Header>
              <Modal.Body style={{ maxWidth: '480px' }}>
                {showDownloadFormat && <DownloadFormat downloadFormat={downloadFormat} setDownloadFormat={setDownloadFormat} />}
                {hasReport ? (
                  <>
                    作成済みの源泉徴収票が存在します。
                    <div className={styles.file}>
                      {report.status === 'failed' ? (
                        <>作成が失敗しました</>
                      ) : (
                        <>
                          {myNumberShown && !hasMyNumberPermission ? (
                            <>源泉徴収票ダウンロード</>
                          ) : (
                            <a
                              href={`/report?report_type=${reportTypeName(reportType, downloadFormat)}&disposition=attachment${
                                year ? '&year=' + year : ''
                              }`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="u-txt-link"
                            >
                              源泉徴収票ダウンロード
                            </a>
                          )}
                          （{moment(report.generatedAt).format('YYYY/MM/DD HH:mm')}
                          作成）
                          {myNumberShown && !hasMyNumberPermission && (
                            <>
                              <br />
                              ※マイナンバーが出力された帳票を閲覧する権限がありません。
                            </>
                          )}
                        </>
                      )}
                    </div>
                    再作成を行うと既存の源泉徴収票は削除されます。再作成が完了しましたらメールにてお送りいたします。
                    <br />
                    <br />
                    ※従業員数によって時間がかかる場合がございます。予めご了承ください。
                  </>
                ) : (
                  <>
                    源泉徴収票(
                    {title}
                    )を作成します。作成が完了しましたらメールにてお送りいたします。
                    <br />
                    ※従業員数によって時間がかかる場合がございます。予めご了承ください。
                  </>
                )}
                {hasMyNumber && <ShowMyNumber {...{ showMyNumber, setShowMyNumber }} />}
              </Modal.Body>
              <Modal.Footer>
                <Modal.Buttons>
                  <Button onClick={hideModal}>キャンセル</Button>
                  <Button
                    primary
                    disabled={loading}
                    onClick={async () => {
                      await callMutation(makeReport, { showMyNumber, downloadFormat });
                      notify(`源泉徴収票(${title})はメールにてお送りします`);
                      hideModal();
                    }}
                  >
                    {hasReport ? '再作成' : '作成'}
                  </Button>
                </Modal.Buttons>
              </Modal.Footer>
            </Modal.Modal>
          )}
          <Button
            onClick={() => setIsOpen(true)}
            disabled={disabled || makingReport}
            disabledReason={makingReport ? '源泉徴収票作成中です' : ''}
            icon={<Download />}
          >
            {makingReport && <Loading className={styles.loading} />}
            PDF一括ダウンロード
          </Button>
          {report && <PollingController reportStatus={report.status} {...{ startPolling, stopPolling }} />}
        </>
      )}
    </Modal>
  );
};

export default DownloadWithholdSlips;
