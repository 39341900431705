import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { DeletableSection } from '../../../../../components/DeletableSection';
import { SelectField } from 'components/react-hook-form/SelectField';
import { FormSection } from 'components/form/FormSection';
import { GeneralLifeInsurance } from './GeneralLifeInsurance';
import { NursingMedicalInsurance } from './NursingMedicalInsurance';
import { IndividualAnnuityInsurance } from './IndividualAnnuityInsurance';
import { Schema } from '../schema';

type LifeInsuranceType = {
  index: number;
  onDelete: () => void;
  generalLifeInsuranceNewTypeAmount: number;
  generalLifeInsuranceOldTypeAmount: number;
  individualAnnuityInsuranceNewTypeAmount: number;
  individualAnnuityInsuranceOldTypeAmount: number;
  nursingMedicalInsuranceNewTypeAmount: number;
};

const lifeInsuranceTypes = [
  { label: '一般の生命保険', value: 'GeneralLifeInsurance' },
  { label: '介護医療保険', value: 'NursingMedicalInsurance' },
  { label: '個人年金保険', value: 'IndividualAnnuityInsurance' }
];

export const LifeInsurance: FC<LifeInsuranceType> = ({
  index,
  onDelete,
  generalLifeInsuranceNewTypeAmount,
  generalLifeInsuranceOldTypeAmount,
  individualAnnuityInsuranceNewTypeAmount,
  individualAnnuityInsuranceOldTypeAmount,
  nursingMedicalInsuranceNewTypeAmount
}) => {
  const {
    formState: { errors },
    watch
  } = useFormContext<Schema>();

  const lifeInsuranceType = watch(`lifeInsurances.${index}.lifeInsuranceType`);

  return (
    <DeletableSection>
      <DeletableSection.Header onDelete={onDelete}>生命保険（{index + 1}）</DeletableSection.Header>
      <DeletableSection.Body>
        <FormSection>
          <SelectField
            label="生命保険の種別"
            required
            placeholder="選択して下さい"
            options={lifeInsuranceTypes}
            error={errors.lifeInsurances && errors.lifeInsurances[index]?.lifeInsuranceType?.message}
            name={`lifeInsurances.${index}.lifeInsuranceType`}
          />
          {lifeInsuranceType === 'GeneralLifeInsurance' && (
            <GeneralLifeInsurance
              index={index}
              newTypeTotalAmount={generalLifeInsuranceNewTypeAmount}
              oldTypeTotalAmount={generalLifeInsuranceOldTypeAmount}
            />
          )}
          {lifeInsuranceType === 'NursingMedicalInsurance' && (
            <NursingMedicalInsurance index={index} newTypeTotalAmount={nursingMedicalInsuranceNewTypeAmount} />
          )}
          {lifeInsuranceType === 'IndividualAnnuityInsurance' && (
            <IndividualAnnuityInsurance
              index={index}
              newTypeTotalAmount={individualAnnuityInsuranceNewTypeAmount}
              oldTypeTotalAmount={individualAnnuityInsuranceOldTypeAmount}
            />
          )}
        </FormSection>
      </DeletableSection.Body>
    </DeletableSection>
  );
};
