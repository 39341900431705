import React from 'react';
import { gql } from '@apollo/client';
import { useQuery, useMutation, LoadingPage, useYear } from '../components';
import { reduxForm } from 'redux-form';
import { toFormValues, toBooleanProps } from '../utils/form';
import * as validators from '../validators';
import { TextField, DateField, TextAreaField, CheckboxField, RadioField } from 'jbc-front/components/Form';
import Button from 'jbc-front/components/Button';
import styles from './CityReportSetting.scss';

const CITY_REPORT_SETTING_FRAGMENT = gql`
  fragment CityReportSetting on CityReportSetting {
    id
    addressText
    contactGroup
    contactName
    contactNameKana
    contactPhoneNumber
    businessCategory
    paymentDate
    bankName
    bankLocation
    taxAccountantName
    taxAccountantTel
    deliverySlip
    showMyNumber
    submitOn
  }
`;

const CITY_REPORT_SETTING = gql`
  query cityReportSetting($year: Int!) {
    client {
      id
      clientYearly(year: $year) {
        id
        cityReportSetting {
          ...CityReportSetting
        }
      }
    }
  }
  ${CITY_REPORT_SETTING_FRAGMENT}
`;

const SAVE_CITY_REPORT_SETTING = gql`
  mutation saveCityReportSetting($input: SaveCityReportSettingInput!) {
    saveCityReportSetting(input: $input) {
      clientYearly {
        id
        cityReportSetting {
          ...CityReportSetting
        }
      }
    }
  }
  ${CITY_REPORT_SETTING_FRAGMENT}
`;

const formName = 'cityReportSettingForm';

const Form = reduxForm({ form: formName })(({ submitting, handleSubmit, year }) => (
  <section className={styles.cardSection}>
    <div className={styles.destinationLabelWrap}>
      <div className={styles.destinationLabel}>
        <p>宛先ラベルテキスト</p>
        <p>(ラベルサイズ99mm×38mm)</p>
      </div>
      <div className={styles.previewWrap}>
        <p className={styles.previewLabel}>ラベルプレビュー</p>
        <div className={styles.preview}>
          <div className={styles.previewAddress}>
            <p>〒102-8688　東京都千代田区九段南1-2-1</p>
            <p>税務課課税係</p>
          </div>
          <TextAreaField name="addressText" placeholder="ここに記入してください" rows="3" />
        </div>
      </div>
    </div>
    <div className={styles.cityReportSummary}>
      <h2 className={styles.cityReportSummaryTitle}>給与支払報告書(総括表)</h2>
      <form onSubmit={handleSubmit} className={styles.form}>
        <TextField name="contactGroup" label="連絡者の所属課係" />
        <TextField name="contactName" label="連絡者の氏名" />
        <TextField name="contactNameKana" label="連絡者の氏名（カナ）" validate={validators.zenkakuKatakanaWithSpace} />
        <TextField name="contactPhoneNumber" label="連絡者の電話番号" />
        <TextField name="businessCategory" label="業務種目" />
        <TextField name="paymentDate" label="給与支払方法及び期日" />
        {year >= 2021 ? (
          <>
            <TextField name="taxAccountantName" label="関与税理士等の氏名" />
            <TextField name="taxAccountantTel" label="関与税理士等の電話番号" />
            <RadioField
              name="deliverySlip"
              label="納入書の送付"
              {...toBooleanProps}
              options={[
                { value: 'true', label: '必要' },
                { value: 'false', label: '不要' }
              ]}
            />
          </>
        ) : (
          <>
            <TextField name="bankName" label="払込希望金融機関名称" />
            <TextField name="bankLocation" label="払込希望金融機関所在地" />
          </>
        )}
        <DateField name="submitOn" label="提出日" />
        <CheckboxField name="showMyNumber" label="マイナンバーを出力する" />
      </form>
    </div>
    <div className="u-ta-c">
      <Button primary onClick={handleSubmit} disabled={submitting}>
        反映して給与支払報告書を作成
      </Button>
    </div>
  </section>
));

const CityReportSetting = ({ history }) => {
  const year = useYear();
  const { loading, data } = useQuery(CITY_REPORT_SETTING, { variables: { year } });
  const [saveCityReportSetting] = useMutation(SAVE_CITY_REPORT_SETTING);
  if (loading) return <LoadingPage />;
  const {
    client: {
      clientYearly: { cityReportSetting }
    }
  } = data;
  return (
    <div>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">給与支払報告書(市区町村別)</h1>
      </div>
      <div className="l-contents-wrap">
        <div className="l-wrap-xl">
          <Form
            initialValues={toFormValues(cityReportSetting || { deliverySlip: true }, true)}
            onSubmit={async values => {
              await saveCityReportSetting({
                variables: { input: { ...values, year } }
              });
              history.push(`/${year}/result/city_report`);
            }}
            year={year}
          />
        </div>
      </div>
    </div>
  );
};

export default CityReportSetting;
