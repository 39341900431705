import React from 'react'
import Icon from 'react-icon-base'

const DocumentStep1 = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="E_書類作成" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Artboard" transform="translate(-342.000000, -628.000000)">
        <g id="1" transform="translate(342.000000, 628.000000)">
          <circle id="Oval" fill={props.color} cx="20" cy="20" r="20"></circle>
          <path d="M20,2.22222222 C29.8183956,2.22222222 37.7777778,10.1816044 37.7777778,20 C37.7777778,29.8183956 29.8183956,37.7777778 20,37.7777778 C10.1816044,37.7777778 2.22222222,29.8183956 2.22222222,20 C2.22222222,10.1816044 10.1816044,2.22222222 20,2.22222222 Z M20,5.22222222 C11.8384587,5.22222222 5.22222222,11.8384587 5.22222222,20 C5.22222222,28.1615413 11.8384587,34.7777778 20,34.7777778 C28.1615413,34.7777778 34.7777778,28.1615413 34.7777778,20 C34.7777778,11.8384587 28.1615413,5.22222222 20,5.22222222 Z" id="Oval" fill="#FFFFFF" fillRule="nonzero"></path>
          <path d="M24.31,28.04 L24.31,26.1 L21.45,26.1 L21.45,12.68 L19.41,12.68 C18.35,13.46 17.17,14.1 15.85,14.48 L16.25,16.58 C17.35,16.22 18.31,15.74 19.07,15.22 L19.07,26.1 L16.07,26.1 L16.07,28.04 L24.31,28.04 Z" fill="#FFFFFF" fillRule="nonzero"></path>
        </g>
      </g>
    </g>
  </Icon>
)

DocumentStep1.defaultProps = {
  size: 30,
  color: "#3498DB"
}

export default DocumentStep1
