import React from 'react'
import Icon from 'react-icon-base'

const Required = props => (
  <Icon viewBox="0 0 20 20" {...props}>
    <g fill="none">
      <rect height="20" width="20" fill="#E61345" rx="3"/>
      <text fill="#FFF" fontFamily="YuGo-Bold, YuGothic" fontSize="12">
        <tspan x="4" y="14">必</tspan>
      </text>
    </g>
  </Icon>
)
Required.defaultProps = {
  size: 20
}

export default Required
