import React from 'react'
import Icon from 'react-icon-base'

const Dashboard = props => (
  <Icon viewBox="0 0 18 18" {...props}>
    <svg width="18px" height="18px" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <rect id="path-1" x="0" y="0" width="8" height="8" rx="1"></rect>
        <mask id="mask-2" maskContentUnits="userSpaceOnUse" maskUnits="objectBoundingBox" x="0" y="0" width="8" height="8" fill="white">
          <use xlinkHref="#path-1"></use>
        </mask>
        <rect id="path-3" x="10" y="0" width="8" height="8" rx="1"></rect>
        <mask id="mask-4" maskContentUnits="userSpaceOnUse" maskUnits="objectBoundingBox" x="0" y="0" width="8" height="8" fill="white">
          <use xlinkHref="#path-3"></use>
        </mask>
        <rect id="path-5" x="10" y="10" width="8" height="8" rx="1"></rect>
        <mask id="mask-6" maskContentUnits="userSpaceOnUse" maskUnits="objectBoundingBox" x="0" y="0" width="8" height="8" fill="white">
          <use xlinkHref="#path-5"></use>
        </mask>
        <rect id="path-7" x="0" y="10" width="8" height="8" rx="1"></rect>
        <mask id="mask-8" maskContentUnits="userSpaceOnUse" maskUnits="objectBoundingBox" x="0" y="0" width="8" height="8" fill="white">
          <use xlinkHref="#path-7"></use>
        </mask>
      </defs>
      <g id="symbol" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="icon_dashboard" strokeWidth="3.4" stroke="currentColor">
          <g id="icon">
            <use id="Rectangle" mask="url(#mask-2)" xlinkHref="#path-1"></use>
            <use id="Rectangle" mask="url(#mask-4)" xlinkHref="#path-3"></use>
            <use id="Rectangle" mask="url(#mask-6)" xlinkHref="#path-5"></use>
            <use id="Rectangle" mask="url(#mask-8)" xlinkHref="#path-7"></use>
          </g>
        </g>
      </g>
    </svg>
  </Icon>
)
Dashboard.defaultProps = {
  size: 20
}

export default Dashboard
