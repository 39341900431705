import { FC, ReactNode } from 'react';
import styles from './FormSection.scss';
import classnames from 'classnames';

type FormSectionProps = JSX.IntrinsicElements['div'] & {
  children: ReactNode;
};

export const FormSection: FC<FormSectionProps> = ({ children, className, ...props }) => {
  return (
    <div className={classnames(styles.formSection, className)} {...props}>
      {children}
    </div>
  );
};
