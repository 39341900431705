import { FC, ReactNode } from 'react';
import styles from './FormSection.scss';

type FormSectionProps = {
  children: ReactNode;
};

export const FormSection: FC<FormSectionProps> = ({ children }) => {
  return <div className={styles.formSection}>{children}</div>;
};
