import { gql } from '@apollo/client';

export const UPDATE_CUSTOM_MAIL = gql`
  mutation updateCustomMail($input: UpdateCustomMailInput!) {
    updateCustomMail(input: $input) {
      customMail {
        id
      }
    }
  }
`;
