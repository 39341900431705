import React from 'react'
import Icon from 'react-icon-base'

const List = props => (
  <Icon viewBox="0 0 13 13" {...props}>
    <defs>
      <path d="M0 0L3 0L3 3L0 3L0 0Z" id="c9eeDRqdN" />
      <path d="M0.5 0.5L2.5 0.5L2.5 2.5L0.5 2.5L0.5 0.5Z" id="a1W4AK1wDp" />
      <path d="M4 0L13 0L13 3L4 3L4 0Z" id="bpKdO3ZEw" />
      <path d="M4.5 0.5L12.5 0.5L12.5 2.5L4.5 2.5L4.5 0.5Z" id="a2BJjryBph" />
      <path d="M0 5L3 5L3 8L0 8L0 5Z" id="a11lLyGA0W" />
      <path d="M0.5 5.5L2.5 5.5L2.5 7.5L0.5 7.5L0.5 5.5Z" id="gdPjV3SaT" />
      <path d="M4 5L13 5L13 8L4 8L4 5Z" id="cgLGLnBqQ" />
      <path d="M4.5 5.5L12.5 5.5L12.5 7.5L4.5 7.5L4.5 5.5Z" id="deoUReTa4" />
      <path d="M0 10L3 10L3 13L0 13L0 10Z" id="eEGi43YI8" />
      <path
        d="M0.5 10.5L2.5 10.5L2.5 12.5L0.5 12.5L0.5 10.5Z"
        id="a2hMQxV24V"
      />
      <path d="M4 10L13 10L13 13L4 13L4 10Z" id="d2StkVpTrU" />
      <path
        d="M4.5 10.5L12.5 10.5L12.5 12.5L4.5 12.5L4.5 10.5Z"
        id="aOtI31yCZ"
      />
    </defs>
    <use xlinkHref="#c9eeDRqdN" fill="currentColor" />
    <use xlinkHref="#c9eeDRqdN" />
    <use xlinkHref="#a1W4AK1wDp" />
    <use xlinkHref="#a1W4AK1wDp" stroke="currentColor" />
    <use xlinkHref="#bpKdO3ZEw" fill="currentColor" />
    <use xlinkHref="#bpKdO3ZEw" />
    <use xlinkHref="#a2BJjryBph" />
    <use xlinkHref="#a2BJjryBph" stroke="currentColor" />
    <use xlinkHref="#a11lLyGA0W" fill="currentColor" />
    <use xlinkHref="#a11lLyGA0W" />
    <use xlinkHref="#gdPjV3SaT" />
    <use xlinkHref="#gdPjV3SaT" stroke="currentColor" />
    <g>
      <use xlinkHref="#cgLGLnBqQ" fill="currentColor" />
      <use xlinkHref="#cgLGLnBqQ" />
    </g>
    <g>
      <use xlinkHref="#deoUReTa4" />
      <use xlinkHref="#deoUReTa4" stroke="currentColor" />
    </g>
    <g>
      <use xlinkHref="#eEGi43YI8" fill="currentColor" />
      <use xlinkHref="#eEGi43YI8" />
    </g>
    <g>
      <use xlinkHref="#a2hMQxV24V" />
      <use xlinkHref="#a2hMQxV24V" stroke="currentColor" />
    </g>
    <g>
      <use xlinkHref="#d2StkVpTrU" fill="currentColor" />
      <use xlinkHref="#d2StkVpTrU" />
    </g>
    <g>
      <use xlinkHref="#aOtI31yCZ" />
      <use xlinkHref="#aOtI31yCZ" stroke="currentColor" />
    </g>
  </Icon>
)

List.defaultProps = {
  size: 13
}

export default List
