import { FC } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';

import Button from 'jbc-front/components/Button';
import { ButtonRow } from 'jbc-front/components/presenters/layout/ButtonRow';

import { FormSection } from 'components/form/FormSection';
import { Radio } from 'components/form/Radio';
import { Input } from 'components/form/Input';
import { DateField } from 'components/react-hook-form/DateField';

import { Spouse } from './formParts/Spouse';
import { NextYear } from './formParts/NextYear';
import { ThisYear } from './formParts/ThisYear';

import { schema, Schema, generateDefaultValues } from './schema';
import { UPDATE_REQUEST_SPOUSE, QueryVariables, convertFormDataToQueryVariables } from './mutation';
import { FETCH_EMPLOYEE } from '../../../../query';
import { Grid } from '../../../../components/Grid';
import { FormSeparator } from '../../../../components/FormSeparator';
import { FloatingButton } from '../../../../components/FloatingButton';
import { useResult } from '../../ResultProvider';

// @ts-ignore
import { useNotify } from 'actions';
// @ts-ignore
import { useMutation } from 'components/Graphql';

type FormProps = {
  onCancel: () => void;
};

export const Form: FC<FormProps> = ({ onCancel }) => {
  const { employee } = useResult();
  const request = employee.request;
  const requestProfile = request?.profile;

  const [update, { loading }] = useMutation(UPDATE_REQUEST_SPOUSE);
  const notify = useNotify();

  const methods = useForm<Schema>({
    defaultValues: generateDefaultValues(requestProfile),
    resolver: yupResolver(schema),
    mode: 'onBlur'
  });

  const {
    handleSubmit,
    formState: { errors },
    register,
    watch
  } = methods;

  const onSubmit: SubmitHandler<Schema> = async (data: Schema) => {
    const variables: QueryVariables = convertFormDataToQueryVariables(String(employee.id), data);

    await update({
      variables,
      refetchQueries: [FETCH_EMPLOYEE]
    });
    onCancel();
    notify('配偶者情報を保存しました。', 'success');
  };

  const maritalStatus = watch('maritalStatus');
  const hasSpouse = ['married', 'divorced_or_bereavement'].includes(maritalStatus);

  const thisYearDependentTaxLaw = watch('spouse.thisYear.dependentTaxLaw');
  const nextYearDependentTaxLaw = watch('spouse.nextYear.dependentTaxLaw');

  return (
    <FormProvider {...methods}>
      <form>
        <FormSection>
          <Radio.Container label="配偶者の有無" required error={errors.maritalStatus?.message}>
            <Radio value="married" {...register('maritalStatus')}>
              有
            </Radio>
            <Radio value="unmarried" {...register('maritalStatus')}>
              無（未婚）
            </Radio>
            <Radio value="divorced_or_bereavement" {...register('maritalStatus')}>
              無（離婚・死別等）
            </Radio>
          </Radio.Container>

          {hasSpouse && (
            <>
              <Spouse />
              <ThisYear />
              <NextYear />

              {(thisYearDependentTaxLaw === 'true' || nextYearDependentTaxLaw === 'true') && (
                <Grid colGap>
                  <DateField label="源泉控除対象配偶者になった日" name="spouse.dependentFrom" />
                  <Input label="源泉控除対象配偶者になった理由" {...register('spouse.dependentReason')} />
                  <DateField label="死亡日" name="spouse.diedOn" />
                </Grid>
              )}
            </>
          )}

          <FormSeparator />

          <FloatingButton>
            <ButtonRow>
              <Button onClick={onCancel} disabled={loading}>
                キャンセル
              </Button>
              <Button primary disabled={loading} onClick={handleSubmit(onSubmit)}>
                保存
              </Button>
            </ButtonRow>
          </FloatingButton>
        </FormSection>
      </form>
    </FormProvider>
  );
};
