import { FC, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';

import Button from 'jbc-front/components/Button';
import ActionButton from 'jbc-front/components/ActionButton';
import { Copy } from 'jbc-front/components/icons';
import { ButtonRow } from 'jbc-front/components/presenters/layout/ButtonRow';

import { PostcodeInput } from 'components/form/PostcodeInput';
import { SelectField } from 'components/react-hook-form/SelectField';
import { Input } from 'components/form/Input';
import { FormSection } from 'components/form/FormSection';

import { usePrefectureOptions } from 'hooks/usePrefectureOptions';
import { useAddress } from 'hooks/useAddress';
import { useYear } from 'hooks/useYear';

import { FETCH_EMPLOYEE } from '../../../../query';
import { Grid } from '../../../../components/Grid';
import { FormSeparator } from '../../../../components/FormSeparator';
import { FloatingButton } from '../../../../components/FloatingButton';
import { useResult } from '../../ResultProvider';
import { Schema, schema, generateDefaultValues } from './schema';
import { UPDATE_REQUEST_RESIDENT_CARD, convertFormDataToQueryVariables } from './mutation';

import styles from './Form.scss';

// @ts-ignore
import { useNotify } from 'actions';

// @ts-ignore
import { useMutation } from 'components/Graphql';

type FormProps = {
  onCancel: () => void;
};

export const ResidentCardAddressForm: FC<FormProps> = ({ onCancel }) => {
  const { prefectureOptions } = usePrefectureOptions();
  const { employee } = useResult();
  const requestProfile = employee.request?.profile;
  const residentCard = requestProfile?.residentCard;
  const { fetchAddress, address } = useAddress();
  const year = useYear();
  const [update, { loading }] = useMutation(UPDATE_REQUEST_RESIDENT_CARD);
  const notify = useNotify();

  const methods = useForm<Schema>({
    defaultValues: generateDefaultValues(residentCard),
    resolver: yupResolver(schema),
    mode: 'onBlur'
  });

  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
    setValue,
    trigger
  } = methods;

  const onSubmit: SubmitHandler<Schema> = async (data: Schema) => {
    const variables = convertFormDataToQueryVariables(String(employee.id), data);
    await update({
      variables,
      refetchQueries: [FETCH_EMPLOYEE]
    });
    onCancel();
    notify('住民票住所を保存しました。', 'success');
  };

  const copyAddress = () => {
    setShouldFetchAddress(false);
    const prefecture = prefectureOptions.find(option => option.label === requestProfile?.prefecture);
    setValue('postcode0', requestProfile?.postcode0 ?? '');
    setValue('postcode1', requestProfile?.postcode1 ?? '');
    setValue('prefectureId', prefecture?.value ?? '');
    setValue('city', requestProfile?.city ?? '');
    setValue('street', requestProfile?.street ?? '');
    setValue('building', requestProfile?.building ?? '');
  };

  const [shouldFetchAddress, setShouldFetchAddress] = useState<boolean>(false);
  const postcode0 = watch('postcode0');
  const postcode1 = watch('postcode1');

  useEffect(() => {
    if (shouldFetchAddress) {
      if (postcode0 && postcode1) {
        const postcode = Number(`${postcode0}${postcode1}`);
        fetchAddress({ variables: { postcode, year } });
      }
    } else {
      setShouldFetchAddress(true);
    }
  }, [postcode0, postcode1]);

  useEffect(() => {
    if (address) {
      const prefecture = prefectureOptions.find(option => option.label === address.prefecture);
      if (prefecture) {
        setValue('prefectureId', prefecture.value);
      }
      setValue('city', address.city);
      setValue('street', address.street);

      trigger(['prefectureId', 'city', 'street']);
    }
  }, [address]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormSection>
          <Grid>
            <ActionButton className={styles.copyAddress} icon={<Copy size={13} />} onClick={copyAddress}>
              現住所をコピーする
            </ActionButton>
          </Grid>

          <Grid colGap>
            <PostcodeInput
              required
              errors={[errors.postcode0?.message ?? '', errors.postcode1?.message ?? ''].filter(error => error)}
              firstCodeProps={{
                isError: !!errors.postcode0?.message,
                ...register('postcode0')
              }}
              lastCodeProps={{
                isError: !!errors.postcode1?.message,
                ...register('postcode1')
              }}
            />
            <SelectField
              label="都道府県"
              options={prefectureOptions}
              value={prefectureOptions.find(option => option.value === residentCard?.prefectureId)}
              required
              name="prefectureId"
              error={errors.prefectureId?.message}
            />
            <Input label="市区町村" required error={errors.city?.message} isError={!!errors.city?.message} {...register('city')} />
            <Input label="丁目番地号" error={errors.street?.message} isError={!!errors.street?.message} {...register('street')} />
            <Input label="建物名" error={errors.building?.message} isError={!!errors.building?.message} {...register('building')} />
          </Grid>

          <FormSeparator />

          <FloatingButton>
            <ButtonRow>
              <Button onClick={onCancel} disabled={loading}>
                キャンセル
              </Button>
              <Button primary onClick={handleSubmit(onSubmit)} disabled={loading}>
                保存
              </Button>
            </ButtonRow>
          </FloatingButton>
        </FormSection>
      </form>
    </FormProvider>
  );
};
