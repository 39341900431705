import React from 'react'
import Icon from 'react-icon-base'

const HeadOffice = props => (
  <Icon viewBox="0 0 20 20" {...props}>
    <g fill="currentColor">
      <path d="M2.299 14.916h7.636V1.5H2.299v13.416zm8.386 1.5H1.549a.75.75 0 0 1-.75-.75V.75a.75.75 0 0 1 .75-.75h9.136a.75.75 0 0 1 .75.75v14.916a.75.75 0 0 1-.75.75z"/>
      <path d="M15.75 16.416h-15a.75.75 0 0 1 0-1.5h15a.75.75 0 0 1 0 1.5"/>
      <path d="M11.435 14.916h2.975V4.794h-2.975v10.122zm3.724 1.5h-4.474a.75.75 0 0 1-.75-.75V4.044a.75.75 0 0 1 .75-.75h4.474a.75.75 0 0 1 .75.75v11.622a.75.75 0 0 1-.75.75zM2.799 4.7h1.354V2.409H2.799zM5.299 4.7h1.354V2.409H5.299zM7.799 4.7h1.354V2.409H7.799zM2.799 8.242h1.354V5.95H2.799zM5.299 8.242h1.354V5.95H5.299zM7.799 8.242h1.354V5.95H7.799zM2.799 11.784h1.354V9.492H2.799zM5.299 11.784h1.354V9.492H5.299zM7.799 11.784h1.354V9.492H7.799z"/>
    </g>
  </Icon>
)
HeadOffice.defaultProps = {
  size: 20
}

export default HeadOffice
