import React from 'react';
import { Link } from 'react-router-dom';
import { Pulldown } from 'jbc-front/components/icons';
import styles from './Settings.scss';
import { useYear } from '../components';

const Item = ({ title, text, to, disabled = false }) => {
  const Wrap = disabled ? 'div' : Link;
  return (
    <li>
      <Wrap to={!disabled && to} className={disabled ? styles.itemDisabled : styles.itemWrap}>
        <dl className={styles.definition}>
          <dt>
            <span className={styles.icon}>
              <Pulldown size={13} />
            </span>
            {title}
          </dt>
          <dd>{text}</dd>
        </dl>
      </Wrap>
    </li>
  );
};

const Settings = () => {
  const year = useYear();
  return (
    <div>
      <div className="l-main-title-wrap">
        <h2 className="m-title-main">設定</h2>
      </div>
      <div className="l-wrap-l l-contents-wrap">
        <div className={styles.main}>
          <section className={styles.section}>
            <h3 className={styles.category}>共通設定</h3>
            <ul className={styles.list}>
              <Item
                to={`/${year ? year + '/' : ''}settings/question_descriptions`}
                title="従業員フォームの説明文カスタマイズ"
                text="従業員入力フォームの各STEPの説明文をカスタマイズすることができます"
              />
              <Item
                to={`/${year ? year + '/' : ''}settings/custom_mails`}
                title="メールカスタマイズ"
                text="メールの内容をカスタマイズできます"
              />
            </ul>
          </section>
          <section className={styles.section}>
            <h3 className={styles.category}>{year}年設定</h3>
            <ul className={styles.list}>
              <Item
                to={`/${year ? year + '/' : ''}client_setting`}
                title="基本設定"
                text="紙の提出の依頼有無、証明書の画像データの添付有無、帳票に出力する住所等を設定できます"
              />
              <Item
                to={`/${year ? year + '/' : ''}resident_tax_payment_address`}
                title="住民税納付先"
                text="住民税納付先の設定ができます"
              />
            </ul>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Settings;
