import { ComponentProps, forwardRef } from 'react';
import { FormField, FieldBaseProps } from '../FormField';
import { Label } from '../Label';
import { InputWithText as InputWithTextField } from 'jbc-front/components/presenters/form/InputWithText';
import { Required } from 'jbc-front/components/presenters/form/Label';
import { ErrorMessage } from 'jbc-front/components/presenters/form/ErrorMessage';
import { Note } from 'jbc-front/components/presenters/ui/Note';
import { ReadMoreReadLess } from 'jbc-front/components/presenters/ui/ReadMoreReadLess';
import { Hint } from 'jbc-front/components/presenters/ui/Hint';

type InputProps = ComponentProps<typeof InputWithTextField> &
  FieldBaseProps & {
    noteMaxLength?: number;
  };

export const InputWithText = forwardRef<HTMLInputElement, InputProps>(
  ({ label, required = false, hint, note, noteMaxLength, error = undefined, ...inputWithTextProps }, ref) => {
    return (
      <FormField>
        {label && (
          <FormField.LabelContainer>
            <Label>
              {label}
              {required && <Required />}
              {hint && <Hint>{hint}</Hint>}
            </Label>
          </FormField.LabelContainer>
        )}

        <InputWithTextField {...inputWithTextProps} ref={ref} />

        {note && (
          <FormField.NoteContainer>
            <Note>{noteMaxLength === undefined ? note : <ReadMoreReadLess maxLength={noteMaxLength}>{note}</ReadMoreReadLess>}</Note>
          </FormField.NoteContainer>
        )}

        {error && (
          <FormField.ErrorContainer>
            <ErrorMessage>{error}</ErrorMessage>
          </FormField.ErrorContainer>
        )}
      </FormField>
    );
  }
);
