import React, { useEffect } from 'react';
import useReactRouter from 'use-react-router';
import { Link } from 'react-router-dom';
import { Field } from 'redux-form';
import Button from 'jbc-front/components/Button';
import styles from './Result.scss';
import { useQuery, FormNameProvider, LoadingPage, useSession, Title, useYearMasters, useAsyncTask, AsyncTaskError } from '../components';
import DownloadModalForResultCsv from '../result/DownloadModalForResultCsv';
import searchForm from '../employees/SearchForm';
import { getCurrentQueryFromLocation, convertQueryToForm, convertFormToGraphQl } from '../employees/utils';
import PaginatorWithResult from '../employees/PaginatorWithResult';
import FixData from '../result/FixData';
import Todos from '../result/Todos';
import BulkDownload from '../result/BulkDownload';
import Cooperation from '../result/Cooperation';
import { EmployeeList } from '../result/EmployeeList';
import { SearchEmploymentStatusField } from '../components/SearchForm';
import EmployeesWithWarning from '../result/EmployeesWithWarning';
import { FINISHED_EMPLOYEES } from '../result/query';
import FloatingButton from '../components/FloatingButton';
import MemoAlertList from '../components/MemoAlertList';

const searchFormName = 'employeeSearchResult';

const SearchForm = searchForm(searchFormName);

const withholdingSlipStatuses = [
  { id: 'all', name: 'すべて' },
  { id: 'not_finished', name: '未入力' },
  { id: 'finished', name: '入力済み' },
  { id: 'not_covered', name: '対象外' }
];

const ImportWithholdingSlipButton = ({ fixing, to, children }) => {
  if (fixing) {
    return (
      <Button primary disabled>
        {children}
      </Button>
    );
  }

  return (
    <Button primary as={Link} to={to}>
      {children}
    </Button>
  );
};

const Employees = ({ totalCount, currentYearMaster, nextYearMaster, employees }) => {
  return (
    <>
      <EmployeeList currentYearMaster={currentYearMaster} nextYearMaster={nextYearMaster} employees={employees} />
      <FormNameProvider formName={searchFormName}>
        <div className={styles.paginator}>
          <PaginatorWithResult count={totalCount} />
        </div>
      </FormNameProvider>
    </>
  );
};

const ActionContents = ({ variables, formValues, totalCount, countByStatus }) => {
  const {
    clientYearly: { year, fixed, fixing },
    isCurrentYear
  } = useSession();

  return (
    <div className={styles.actions}>
      <div className={styles.actionsDataDownload}>
        <DownloadModalForResultCsv search={variables.search} formValues={formValues} disabled={fixing} />
        <Cooperation disabledNotTarget={totalCount === 0} disabledFixing={fixing} />
      </div>
      {isCurrentYear && (
        <div className={styles.actionData}>
          <ImportWithholdingSlipButton fixing={fixing} to={`/${year}/result/import_withholding_slip`}>
            給与・賞与データ一括登録
          </ImportWithholdingSlipButton>
        </div>
      )}
      <div className={styles.actionsSearchForm}>
        <SearchForm
          query={FINISHED_EMPLOYEES}
          variables={variables}
          statusField={handleSubmit => (
            <Field
              name="withholdingSlipStatus"
              component={SearchEmploymentStatusField}
              employmentStatuses={withholdingSlipStatuses}
              onEmploymentStatusChange={handleSubmit}
              countByStatus={countByStatus}
            />
          )}
        />
      </div>
      <div className={styles.actionsConfirmIcon}>{fixed && <span className={styles.confirmICon}>確定済</span>}</div>
    </div>
  );
};

const Result = () => {
  const {
    location: { search, pathname }
  } = useReactRouter();
  const {
    clientYearly: { year, fixed },
    me
  } = useSession();
  const formValues = convertQueryToForm(getCurrentQueryFromLocation({ search, pathname }, me));
  const variables = { ...convertFormToGraphQl(formValues), year };
  const { loading: employeeLoading, data, refetch } = useQuery(FINISHED_EMPLOYEES, {
    variables,
    fetchPolicy: 'network-only'
  });
  const [currentYearMaster, nextYearMaster] = useYearMasters({ year, toHeiseiEra: true });
  const loading = employeeLoading || !currentYearMaster;
  const clientYearly = data?.client?.clientYearly;
  const totalCount = clientYearly?.employees?.totalCount || 0;
  const employees = clientYearly?.employees?.list || [];
  const countByStatus = clientYearly?.withholdingSlipCountByStatus;
  const { task } = useAsyncTask();

  useEffect(() => {
    if (!task) {
      return;
    }

    if (task.taskType === 'fix_data' && ['success', 'failed'].includes(task.status)) {
      refetch();
    }
  }, [task]);

  return (
    <div>
      <div className="l-main-title-wrap">{fixed ? <h1 className="m-title-main">年末調整結果</h1> : <Title>年末調整結果一覧</Title>}</div>
      <Todos />
      <EmployeesWithWarning />
      <div className="l-contents-wrap">
        <div className={styles.yeaContentsWrap}>
          <MemoAlertList year={year} />
          <ActionContents variables={variables} formValues={formValues} totalCount={totalCount} countByStatus={countByStatus} />
          {loading ? (
            <LoadingPage />
          ) : (
            <>
              <AsyncTaskError displayTypes={['fix_data']} />
              <Employees
                totalCount={totalCount}
                currentYearMaster={currentYearMaster}
                nextYearMaster={nextYearMaster}
                employees={employees}
              />
            </>
          )}
          <FloatingButton>
            <FixData refetch={refetch} totalCount={totalCount} />
            <BulkDownload search={variables.search} />
          </FloatingButton>
        </div>
      </div>
    </div>
  );
};

export default Result;
