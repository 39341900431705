import { FC, ReactNode } from 'react';
import { FormField, FieldBaseProps } from '../FormField';
import { Label } from '../Label';
import { Radio as JbcRadio, RadioContainer } from 'jbc-front/components/presenters/form/Radio';
import { Required } from 'jbc-front/components/presenters/form/Label';
import { ErrorMessage } from 'jbc-front/components/presenters/form/ErrorMessage';
import { Note } from 'jbc-front/components/presenters/ui/Note';
import styles from './Radio.scss';
import { ReadMoreReadLess } from 'jbc-front/components/presenters/ui/ReadMoreReadLess';
import { Hint } from 'jbc-front/components/presenters/ui/Hint';

type DateFieldProps = FieldBaseProps & {
  children: ReactNode;
  noteMaxLength?: number;
};

export const Container: FC<DateFieldProps> = ({ label, required = false, hint, note, noteMaxLength, error = undefined, children }) => {
  return (
    <FormField>
      {label && (
        <FormField.LabelContainer>
          <Label>
            {label}
            {required && <Required />}
            {hint && <Hint>{hint}</Hint>}
          </Label>
        </FormField.LabelContainer>
      )}

      <RadioContainer className={styles.radioContainer}>{children}</RadioContainer>

      {note && (
        <FormField.NoteContainer>
          <Note>{noteMaxLength === undefined ? note : <ReadMoreReadLess maxLength={noteMaxLength}>{note}</ReadMoreReadLess>}</Note>
        </FormField.NoteContainer>
      )}

      {error && (
        <FormField.ErrorContainer>
          <ErrorMessage>{error}</ErrorMessage>
        </FormField.ErrorContainer>
      )}
    </FormField>
  );
};

export const Radio = Object.assign(JbcRadio, { Container });
