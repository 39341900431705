import { gql } from '@apollo/client';
import { MEMOS_IN_ALERT_FRAGMENT } from '../MemoAlertList/query';
import { MEMO_FRAGMENT } from './query';

export const CREATE_MEMO = gql`
  mutation createMemo($input: CreateMemoInput!) {
    createMemo(input: $input) {
      employee {
        id
        memos {
          ...Memo
        }
      }
      client {
        id
        clientYearly {
          id
          memosInAlert {
            ...MemosInAlert
          }
        }
      }
    }
  }
  ${MEMO_FRAGMENT}
  ${MEMOS_IN_ALERT_FRAGMENT}
`;

export const UPDATE_MEMO = gql`
  mutation updateMemo($input: UpdateMemoInput!) {
    updateMemo(input: $input) {
      memo {
        id
        body
        alertAt
        color
        lastUpdatedUser {
          id
          name
        }
        createdUser {
          id
          name
        }
      }
      client {
        id
        clientYearly {
          id
          memosInAlert {
            ...MemosInAlert
          }
        }
      }
    }
  }
  ${MEMOS_IN_ALERT_FRAGMENT}
`;

export const DELETE_MEMO = gql`
  mutation deleteMemo($input: DeleteMemoInput!) {
    deleteMemo(input: $input) {
      employee {
        id
        memos {
          id
        }
      }
      client {
        id
        clientYearly {
          id
          memosInAlert {
            ...MemosInAlert
          }
        }
      }
    }
  }
  ${MEMOS_IN_ALERT_FRAGMENT}
`;
