import React from 'react';
import { gql } from '@apollo/client';
import { useQuery, LoadingPage, useYear } from '../components';
import _ from 'lodash';
import styles from './ClientCity.scss';
import recordDisplay from '../utils/recordDisplay';
import Report from './Report';

const CLIENT_CITY = gql`
  query clientCity($id: ID!, $year: Int!) {
    client {
      id
      clientYearly(year: $year) {
        id
        clientSetting {
          id
          employeeAddressClassification
        }
        clientCity(id: $id) {
          id
          fromSalaryCount
          normallyForResignCount
          normallyOthersCount
          city {
            id
            name
            address
            code
            phoneNumber
            postcode
            recipient
            prefecture {
              id
              name
            }
          }
          employees {
            id
            profile {
              id
              fullName
              fullAddress
              residentCard {
                id
                fullAddress
              }
            }
            staffCode
            residentsTaxCollectionMethod
          }
        }
      }
    }
  }
`;

const InhabitantsTaxList = ({ residentsTaxCollectionName, count, employees, employeeAddressClassification }) =>
  count && !_.isEmpty(employees) ? (
    <div className={styles.taxTable}>
      <div className={styles.taxTitleWrap}>
        <p className={styles.taxTitle}>
          {residentsTaxCollectionName}
          <span className={styles.taxPeople}>{count}</span>
        </p>
      </div>
      <div className="l-overflow-scroll">
        <table className={`m-table-list ${styles.tableBodyScroll}`}>
          <thead>
            <tr>
              <th width={130}>受給者番号</th>
              <th width={220}>氏名</th>
              <th width={530}>住所</th>
            </tr>
          </thead>
          <tbody>
            {employees.map(({ id, staffCode, profile: { fullName, fullAddress, residentCard } }) => (
              <tr key={id}>
                <td width={130}>{recordDisplay(staffCode)}</td>
                <td width={220}>{recordDisplay(fullName)}</td>
                <td width={530}>{recordDisplay(employeeAddressClassification === 'profile' ? fullAddress : residentCard?.fullAddress)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  ) : null;

const ClientCity = ({ clientCityId, showMyNumber }) => {
  const year = useYear();
  const { data, loading } = useQuery(CLIENT_CITY, {
    variables: { id: clientCityId, year },
    fetchPolicy: 'network-only'
  });
  if (loading) return <LoadingPage />;
  const {
    client: {
      clientYearly: {
        clientSetting: { employeeAddressClassification },
        clientCity: {
          city: {
            name,
            address,
            phoneNumber,
            postcode,
            recipient,
            prefecture: { name: prefectureName }
          },
          employees,
          fromSalaryCount,
          normallyForResignCount,
          normallyOthersCount
        }
      }
    }
  } = data;
  const {
    from_salary: fromSalaryEmployees,
    normally_for_resign: normallyForResignEmployees,
    normally_others: normallyOthersEmplpyees
  } = _.groupBy(employees, 'residentsTaxCollectionMethod');
  return (
    <section className={styles.cardSection}>
      <div className={styles.cityAndAddressWrap}>
        <p className={styles.cityAndAddress}>{`${prefectureName} ${name}`}</p>
        <Report clientCityId={clientCityId} showMyNumber={showMyNumber} />
      </div>
      <div className={styles.destination}>
        <p className={styles.destinationAddress}>
          送付先：
          {`〒${postcode} ${prefectureName}${address} ${recipient} (☎ ${phoneNumber})`}
        </p>
        <div className={styles.destinationWarning}>送付先は市区町村によって異なる場合がございます。ご確認の上送付をお願いいたします。</div>
      </div>
      <InhabitantsTaxList
        residentsTaxCollectionName="特別徴収(在籍)"
        count={fromSalaryCount}
        employees={fromSalaryEmployees}
        employeeAddressClassification={employeeAddressClassification}
      />
      <InhabitantsTaxList
        residentsTaxCollectionName="普通徴収(退職)"
        count={normallyForResignCount}
        employees={normallyForResignEmployees}
        employeeAddressClassification={employeeAddressClassification}
      />
      <InhabitantsTaxList
        residentsTaxCollectionName="普通徴収(その他)"
        count={normallyOthersCount}
        employees={normallyOthersEmplpyees}
        employeeAddressClassification={employeeAddressClassification}
      />
    </section>
  );
};

export default ClientCity;
