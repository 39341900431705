import { FC, ReactNode } from 'react';
import { Section } from '@jbc-year-end-adj/2024/components/ui/Section';

import { Question } from '../types';

import { Lead } from './parts/Lead';
import { Description } from './parts/Description';
import { Content as ContentContainer } from './parts/Content';
import { findDescriptions } from '../utils';

type StartProps = {
  question: Question;
  children: ReactNode;
};

export const Start: FC<StartProps> = ({ question, children }) => {
  return (
    <Section>
      <Section.Header>{TITLE}</Section.Header>
      <Section.Body>
        <Content question={question}>{children}</Content>
      </Section.Body>
    </Section>
  );
};

export const TITLE = '年末調整開始';

type ContentProps = {
  question: Question;
  children: ReactNode;
};

export const Content: FC<ContentProps> = ({ question, children }) => {
  const questionDescriptions = findDescriptions(question, ['top']);

  return (
    <ContentContainer>
      <Lead>年末調整を始める前に</Lead>

      <Description>{questionDescriptions['top']?.description}</Description>

      {children}
    </ContentContainer>
  );
};
