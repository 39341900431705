import { FC } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler, useFieldArray, FormProvider } from 'react-hook-form';

import Button from 'jbc-front/components/Button';
import { ButtonRow } from 'jbc-front/components/presenters/layout/ButtonRow';

import { FormSection } from 'components/form/FormSection';
import { FormAddButton } from 'components/form/FormAddButton';

import { DeletableSection } from '../../../../components/DeletableSection';
import { FETCH_EMPLOYEE } from '../../../../query';
import { FormSeparator } from '../../../../components/FormSeparator';
import { FloatingButton } from '../../../../components/FloatingButton';
import { useResult } from '../../ResultProvider';
import { schema, Schema, generateDefaultValues, otherFamilyObj } from './schema';
import { QueryVariables, UPDATE_REQUEST_OTHER_FAMILIES, convertFormDataToQueryVariables } from './mutation';
import { OtherFamily } from './OtherFamily';

// @ts-ignore
import { useNotify } from 'actions';
// @ts-ignore
import { useMutation } from 'components/Graphql';

type FormProps = {
  onCancel: () => void;
};

export const Form: FC<FormProps> = ({ onCancel }) => {
  const { employee } = useResult();
  const requestProfile = employee.request?.profile;
  const [update, { loading }] = useMutation(UPDATE_REQUEST_OTHER_FAMILIES);
  const notify = useNotify();

  const methods = useForm<Schema>({
    defaultValues: generateDefaultValues(requestProfile?.otherFamilies),
    resolver: yupResolver(schema),
    mode: 'onSubmit'
  });

  const { handleSubmit, control } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'otherFamilies'
  });

  const onSubmit: SubmitHandler<Schema> = async (data: Schema) => {
    const input = convertFormDataToQueryVariables(data, String(employee.id));
    const variables: QueryVariables = { input };

    await update({
      variables,
      refetchQueries: [FETCH_EMPLOYEE]
    });
    onCancel();
    notify('扶養親族情報を保存しました。', 'success');
  };

  return (
    <FormProvider {...methods}>
      <form>
        <FormSection>
          {fields.map((field, index) => (
            <DeletableSection key={field.id}>
              <DeletableSection.Header onDelete={() => remove(index)}>扶養親族（{index + 1}）</DeletableSection.Header>
              <DeletableSection.Body>
                <OtherFamily index={index} />
              </DeletableSection.Body>
            </DeletableSection>
          ))}

          <FormAddButton onClick={() => append(otherFamilyObj)}>扶養親族情報を追加</FormAddButton>

          <FormSeparator />

          <FloatingButton>
            <ButtonRow>
              <Button onClick={onCancel} disabled={loading}>
                キャンセル
              </Button>
              <Button primary onClick={handleSubmit(onSubmit)} disabled={loading}>
                保存
              </Button>
            </ButtonRow>
          </FloatingButton>
        </FormSection>
      </form>
    </FormProvider>
  );
};
