import React from 'react'
import Icon from 'react-icon-base'

const CheckForm = props => (
  <Icon viewBox="0 0 8 7" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.856 1.59l-4.44 4.56a.471.471 0 0 1-.35.15.471.471 0 0 1-.351-.15L.145 3.51A.498.498 0 0 1 0 3.15c0-.141.048-.261.145-.36l.702-.722a.471.471 0 0 1 .35-.148c.138 0 .255.05.351.148l1.518 1.564L6.452.15A.471.471 0 0 1 6.802 0c.138 0 .255.05.351.149l.702.72A.497.497 0 0 1 8 1.23a.495.495 0 0 1-.144.36z"
    />
  </Icon>
)
CheckForm.defaultProps = {
  size: 8
}

export default CheckForm
