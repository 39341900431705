import React from 'react'
import Icon from 'react-icon-base'

const AffiliationHistory = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="icon/employees/AffiliationHistory" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group" transform="translate(5.000000, 2.000000)" fill={props.color}>
        <path d="M27,0 C28.6568542,1.39730022e-16 30,1.34314575 30,3 L30,28.1658037 C30,28.4329319 29.8931232,28.6889561 29.7031962,28.8767996 L22.7931972,35.7109958 C22.6059878,35.8961515 22.3533069,36 22.090001,36 L3,36 C1.34314575,36 6.46995335e-16,34.6568542 0,33 L0,3 C-2.02906125e-16,1.34314575 1.34314575,-5.83819232e-16 3,0 L27,0 Z M27,2 L3,2 C2.44771525,2 2,2.44771525 2,3 L2,3 L2,33 C2,33.5522847 2.44771525,34 3,34 L3,34 L21.5494635,34 L28,27.7747179 L28,3 C28,2.44771525 27.5522847,2 27,2 L27,2 Z" id="Combined-Shape"></path>
        <path d="M21.5,27.5 C21.5,26.9477153 21.9477153,26.5 22.5,26.5 L28.5,26.5 L28.5,28.5 L23.5,28.499 L23.5,34 L21.5,34 L21.5,27.5 Z" id="Combined-Shape"></path>
        <path d="M5.5,14 C6.32842712,14 7,14.6715729 7,15.5 C7,16.1528467 6.58293192,16.7082819 6.00070296,16.9143985 L6.00070296,23.0856015 C6.58293192,23.2917181 7,23.8471533 7,24.5 C7,25.1528467 6.58293192,25.7082819 6.00070296,25.9143985 L6,30.7 C6,30.9761424 5.77614237,31.2 5.5,31.2 C5.25454011,31.2 5.05039163,31.0231248 5.00805567,30.7898756 L5,30.7 L5.00029246,25.9147505 C4.41754351,25.7089149 4,25.1532187 4,24.5 C4,23.8467813 4.41754351,23.2910851 5.00029246,23.0852495 L5.00029246,16.9147505 C4.41754351,16.7089149 4,16.1532187 4,15.5 C4,14.6715729 4.67157288,14 5.5,14 Z" id="Combined-Shape" fillRule="nonzero"></path>
        <path d="M25.16,8.75 C25.6239192,8.75 26,9.08578644 26,9.5 C26,9.87969577 25.6839877,10.193491 25.273983,10.2431534 L25.16,10.25 L12.84,10.25 C12.3760808,10.25 12,9.91421356 12,9.5 C12,9.12030423 12.3160123,8.80650904 12.726017,8.75684662 L12.84,8.75 L25.16,8.75 Z M25.16,5.75 C25.6239192,5.75 26,6.08578644 26,6.5 C26,6.87969577 25.6839877,7.19349096 25.273983,7.24315338 L25.16,7.25 L12.84,7.25 C12.3760808,7.25 12,6.91421356 12,6.5 C12,6.12030423 12.3160123,5.80650904 12.726017,5.75684662 L12.84,5.75 L25.16,5.75 Z" id="Combined-Shape" fillRule="nonzero"></path>
        <path d="M18.6785714,28 C19.092785,28 19.4285714,28.3357864 19.4285714,28.75 C19.4285714,29.1296958 19.1464175,29.443491 18.780342,29.4931534 L18.6785714,29.5 L10.75,29.5 C10.3357864,29.5 10,29.1642136 10,28.75 C10,28.3703042 10.2821539,28.056509 10.6482294,28.0068466 L10.75,28 L18.6785714,28 Z M14.6785714,25 C15.092785,25 15.4285714,25.3357864 15.4285714,25.75 C15.4285714,26.1296958 15.1464175,26.443491 14.780342,26.4931534 L14.6785714,26.5 L10.75,26.5 C10.3357864,26.5 10,26.1642136 10,25.75 C10,25.3703042 10.2821539,25.056509 10.6482294,25.0068466 L10.75,25 L14.6785714,25 Z M25.2180451,21 C25.6499069,21 26,21.3357864 26,21.75 C26,22.1296958 25.7058245,22.443491 25.3241518,22.4931534 L25.2180451,22.5 L10.7819549,22.5 C10.3500931,22.5 10,22.1642136 10,21.75 C10,21.3703042 10.2941755,21.056509 10.6758482,21.0068466 L10.7819549,21 L25.2180451,21 Z M19.1025641,18 C19.5982043,18 20,18.3357864 20,18.75 C20,19.1296958 19.66238,19.443491 19.2243408,19.4931534 L19.1025641,19.5 L10.8974359,19.5 C10.4017957,19.5 10,19.1642136 10,18.75 C10,18.3703042 10.33762,18.056509 10.7756592,18.0068466 L10.8974359,18 L19.1025641,18 Z M25.2180451,15 C25.6499069,15 26,15.3357864 26,15.75 C26,16.1296958 25.7058245,16.443491 25.3241518,16.4931534 L25.2180451,16.5 L10.7819549,16.5 C10.3500931,16.5 10,16.1642136 10,15.75 C10,15.3703042 10.2941755,15.056509 10.6758482,15.0068466 L10.7819549,15 L25.2180451,15 Z" id="Combined-Shape" fillRule="nonzero"></path>
        <path d="M7.37556222,4 C8.57893698,4 9.55472264,4.97578566 9.55472264,6.17916042 C9.55472264,6.78465755 9.30767721,7.33253461 8.90889873,7.72747921 C10.0024112,8.28456938 10.7511244,9.42187195 10.7511244,10.7338831 C10.7511244,11.0100254 10.5272668,11.2338831 10.2511244,11.2338831 C9.97498206,11.2338831 9.75112444,11.0100254 9.75112444,10.7338831 C9.75112444,9.42204342 8.68740185,8.35832084 7.37556222,8.35832084 C6.06372258,8.35832084 5,9.42204342 5,10.7338831 C5,11.0100254 4.77614237,11.2338831 4.5,11.2338831 C4.22385763,11.2338831 4,11.0100254 4,10.7338831 C4,9.42147617 4.74916507,8.28388324 5.84313252,7.72546697 C5.44344723,7.33253461 5.1964018,6.78465755 5.1964018,6.17916042 C5.1964018,4.97578566 6.17218746,4 7.37556222,4 Z M7.37556222,5 C6.72447221,5 6.1964018,5.52807041 6.1964018,6.17916042 C6.1964018,6.83025043 6.72447221,7.35832084 7.37556222,7.35832084 C8.02665223,7.35832084 8.55472264,6.83025043 8.55472264,6.17916042 C8.55472264,5.52807041 8.02665223,5 7.37556222,5 Z" id="Stroke-3" fillRule="nonzero"></path>
      </g>
    </g>
  </Icon>
)

AffiliationHistory.defaultProps = {
  size: 30,
  color: "#3498DB"
}

export default AffiliationHistory
