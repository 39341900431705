import React from 'react';
import { Section } from 'jbc-front/components/Form';
import { maritalStatuses } from '../questions/Widow';
import { useFormValue } from '../components/FormName';
import { DependentBasicInfo } from '../questions/Dependents';
import { SpouseYearlyInfo } from './SpouseYearlyInfo';
import { gql } from '@apollo/client';
import { INCOME_FRAGMENT, Income } from '../questions/Spouse';
import { toFormValues } from '../utils/form';
import { RadioField, CheckboxField, FormSection, DateField, TextField, useDiff } from '../components/FieldWithDiff';
import { LabelMapper } from 'jbc-front/components/FormErrors';
import useAdjFields from './useAdjFields';
import { useYear } from '../components';
import { maxLength } from '../validators';

export const SPOUSE_INFO_FRAGMENT = gql`
  fragment SpouseInfo on Profile {
    maritalStatus
    hasSpouseDeduction
    hasSpouseDeductionThisYear
    spouse {
      ...Dependent
      thisYear {
        ...YearlyInfo
      }
      nextYear {
        ...YearlyInfo
      }
      income {
        ...Income
      }
    }
  }
  ${INCOME_FRAGMENT}
`;

const Spouse = () => {
  const { fixed } = useDiff();
  const year = useYear();
  const { needAdjThisYear } = useAdjFields();
  const maritalStatus = useFormValue('spouseInformation.maritalStatus');
  const spouseBirthday = useFormValue('spouseInformation.spouse.birthday');
  const hasSpouseDeduction = useFormValue('spouseInformation.hasSpouseDeduction');
  const dependentThisYear = useFormValue('spouseInformation.yearlyInfo.dependentTaxLawOrSpecial');
  const dependentNextYear = useFormValue('spouseInformation.yearlyInfoNextYear.dependentTaxLaw');
  return (
    <Section title="配偶者情報">
      <FormSection name="spouseInformation">
        <LabelMapper name="spouseInformation" label="配偶者情報の" />
        <RadioField name="maritalStatus" label="配偶者の有無" options={maritalStatuses} required />
        {(maritalStatus === 'married' || maritalStatus === 'divorced_or_bereavement') && (
          <CheckboxField label="配偶者に関わる控除を受ける" name="hasSpouseDeduction" />
        )}
        {(maritalStatus === 'married' || maritalStatus === 'divorced_or_bereavement') && hasSpouseDeduction && (
          <>
            <Section title="配偶者の基本情報">
              <FormSection name="spouse">
                <LabelMapper name="spouse" label="基本情報の" />
                <DependentBasicInfo field="spouse" noRelation />
              </FormSection>
            </Section>
            <Section title="今年の扶養情報">
              <FormSection name="yearlyInfo">
                <LabelMapper name="yearlyInfo" label="今年の扶養情報の" />
                <CheckboxField name="dependentTaxLawOrSpecial" label="今年の源泉控除対象、配偶者特別控除対象、または障害者控除対象" />
                {dependentThisYear && (
                  <SpouseYearlyInfo field="spouseInformation.yearlyInfo" isSpouse hideIncome target="配偶者" year={year} />
                )}
              </FormSection>
            </Section>
            {dependentThisYear && needAdjThisYear && (
              <FormSection name="spouseIncome">
                <LabelMapper name="spouseIncome" label="配偶者の" />
                <Income field="spouseInformation.spouseIncome" title="配偶者の" birthday={spouseBirthday} year={year} spouse />
              </FormSection>
            )}
            <Section title="来年の扶養情報">
              <FormSection name="yearlyInfoNextYear">
                <LabelMapper name="yearlyInfoNextYear" label="来年の扶養情報の" />
                <CheckboxField name="dependentTaxLaw" label="来年の源泉控除対象" />
                {dependentNextYear && (
                  <SpouseYearlyInfo
                    field="spouseInformation.yearlyInfoNextYear"
                    isSpouse
                    target="配偶者"
                    withCopy={dependentThisYear && !fixed}
                    thisYearField="spouseInformation.yearlyInfo"
                    year={year + 1}
                    isNextYear
                  />
                )}
              </FormSection>
            </Section>
            {(dependentThisYear || dependentNextYear) && (
              <FormSection name="spouse">
                <DateField name="dependentFrom" label="源泉控除対象配偶者になった日" />
                <TextField name="dependentReason" label="源泉控除対象配偶者になった理由" validate={maxLength(24)} />
                <LabelMapper name="spouse.dependentReason" label="源泉控除対象配偶者になった理由" />
                <DateField name="diedOn" label="死亡日" />
              </FormSection>
            )}
          </>
        )}
      </FormSection>
    </Section>
  );
};

export const makeInitialValues = profile => ({
  maritalStatus: profile.maritalStatus,
  hasSpouseDeduction: profile.hasSpouseDeduction,

  spouse: profile.spouse && toFormValues(profile.spouse, true),
  yearlyInfo: {
    dependentTaxLaw: false,
    ...toFormValues(profile.spouse?.thisYear, true),
    dependentTaxLawOrSpecial: profile.hasSpouseDeductionThisYear || ['normal', 'special'].includes(profile.spouse?.thisYear?.handicapType)
  },
  yearlyInfoNextYear: {
    dependentTaxLaw: false,
    ...toFormValues(profile.spouse?.nextYear, true)
  },
  spouseIncome: toFormValues(profile.spouse?.income, true)
});

export default Spouse;
