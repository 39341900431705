import React from 'react'
import Icon from 'react-icon-base'

const Custom31 = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="Custom31" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinejoin="round">
      <g id="icon/employees/Custom9-Copy" stroke="#3498DB" strokeWidth="1.80061281">
        <g id="Group" transform="translate(20.000000, 20.000000) rotate(30.000000) translate(-20.000000, -20.000000) translate(10.000000, 1.000000)">
          <path d="M4.39010007,4.52777764 L10.0705201,10.9432838 L10.0705201,10.9432838 L10.0705201,37.7834647 L1.29853497,27.8763233 C0.964244223,27.4987729 0.779688219,27.0119321 0.779688219,26.5076559 L0.779688219,5.89644504 C0.779688219,4.75618176 1.70405424,3.83181574 2.84431752,3.83181574 C3.43545092,3.83181574 3.99823068,4.08519761 4.39010007,4.52777764 Z" id="Rectangle"></path>
          <path d="M13.6809319,4.52777764 L19.3613519,10.9432838 L19.3613519,10.9432838 L19.3613519,37.7834647 L10.5893668,27.8763233 C10.2550761,27.4987729 10.0705201,27.0119321 10.0705201,26.5076559 L10.0705201,5.89644504 C10.0705201,4.75618176 10.9948861,3.83181574 12.1351494,3.83181574 C12.7262828,3.83181574 13.2890625,4.08519761 13.6809319,4.52777764 Z" id="Rectangle-Copy" fill="#3498DB" transform="translate(14.715936, 19.116809) scale(-1, 1) translate(-14.715936, -19.116809) "></path>
        </g>
      </g>
    </g>
  </Icon>
)
Custom31.defaultProps = {
  size: 30
}

export default Custom31