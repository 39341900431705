import { gql } from '@apollo/client';
import { Schema, PremiumSchema } from './schema';
import { FileType } from '@jbc-year-end-adj/types';

export type QueryVariables = {
  input: {
    employeeId: string;
    attributes: {
      premium?: Premium;
    };
  };
};

type Premium = {
  smallAndMediumSizedEnterprises?: string;
  smallAndMediumSizedEnterprisesImage?: File | FileType;
  corporatePension?: string;
  corporatePensionImage?: File | FileType;
  personalPension?: string;
  personalPensionImage?: File | FileType;
  disabilityMutualAid?: string;
  disabilityMutualAidImage?: File | FileType;
};

export const convertFormDataToQueryVariables = (data: Schema, employeeId: string): QueryVariables => {
  const premium = data.premium ? convertPremiumInput(data.premium) : undefined;

  return {
    input: {
      employeeId,
      attributes: {
        premium
      }
    }
  };
};

const convertPremiumInput = (premium: PremiumSchema) => {
  return {
    smallAndMediumSizedEnterprises: premium.smallAndMediumSizedEnterprises || undefined,
    smallAndMediumSizedEnterprisesImage: premium.smallAndMediumSizedEnterprisesImage,
    corporatePension: premium.corporatePension || undefined,
    corporatePensionImage: premium.corporatePensionImage,
    personalPension: premium.personalPension || undefined,
    personalPensionImage: premium.personalPensionImage,
    disabilityMutualAid: premium.disabilityMutualAid || undefined,
    disabilityMutualAidImage: premium.disabilityMutualAidImage
  };
};

export const UPDATE_REQUEST_PREMIUM = gql`
  mutation UpdateRequestPremium($input: UpdateRequestPremiumInput!) {
    updateRequestPremium(input: $input) {
      employee {
        id
      }
    }
  }
`;
