import _ from 'lodash';
export const toFormValues = (data, removeId = false, acceptObject = false) => {
  if (!data) {
    return {};
  }

  // 全階層から __typename キーを削除
  data = removeTypename(_.cloneDeep(data));

  return _.fromPairs(
    _.toPairs(data)
      .filter(([key]) => removeId !== true || key !== 'id')
      .filter(
        ([key, value]) =>
          (_.isString(value) && value !== '') ||
          (_.isNumber(value) && !_.isNaN(value)) ||
          _.isBoolean(value) ||
          (_.isObject(value) && 'url' in value && 'filename' in value && (value.url || value.filename)) ||
          (_.isObject(value) && acceptObject)
      )
      .map(([key, value]) =>
        _.isNumber(value)
          ? [key, `${value}`]
          : _.isObject(value) && value.filename
          ? [key, { ...value, name: value.filename }]
          : [key, value]
      )
  );
};

const removeTypename = obj => {
  if (_.isObject(obj)) {
    delete obj['__typename'];
    Object.values(obj).forEach(val => {
      if (_.isObject(val) || _.isArray(val)) {
        removeTypename(val);
      }
    });
  }
  return obj;
};

export const toBooleanProps = {
  options: [
    { value: 'true', label: '有' },
    { value: 'false', label: '無' }
  ],
  normalize: value => {
    switch (value) {
      case 'true':
        return true;
      case 'false':
        return false;
      default:
        return null;
    }
  },
  format: value => {
    switch (value) {
      case true:
        return 'true';
      case false:
        return 'false';
      default:
        return null;
    }
  }
};
