import React from 'react'
import Icon from 'react-icon-base'

const BulkRecreate = props => (
  <Icon viewBox="0 0 44 47" {...props}>
    <defs>
      <rect height="28" id="a" width="23" rx="3" x="5"/>
      <rect height="28" id="b" width="23" rx="3" y="5"/>
    </defs>
    <g fill="none">
      <g transform="translate(16)">
        <rect height="28" width="23" rx="3" stroke="#fff" x="5"/>
        <rect height="27" width="22" rx="3" fill="currentColor" x="5.5" y=".5"/>
      </g>
      <g transform="translate(16)">
        <rect height="28" width="23" rx="3" stroke="#fff" y="5"/>
        <rect height="27" width="22" rx="3" fill="currentColor" x=".5" y="5.5"/>
      </g>
      <g>
        <g transform="translate(1 16)">
          <ellipse cx="15" cy="14.516" fill="#FFF" rx="15" ry="14.516"/>
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.307" d="M29.857 14.903c0 8.14-6.652 14.738-14.857 14.738C6.796 29.64.143 23.043.143 14.903S6.796.166 15 .166c8.205 0 14.857 6.598 14.857 14.737z"/>
          <path fill="currentColor" fillRule="nonzero" stroke="currentColor" d="M10.765 7c.163.004.316.08.416.206.1.126.136.29.098.445l-.198 2.144c1.092-.72 2.409-1.202 3.794-1.202 3.79 0 6.875 3.008 6.875 6.704 0 3.695-3.084 6.703-6.875 6.703C11.085 22 8 18.992 8 15.297c-.004-.28.256-.535.543-.535.287 0 .547.255.543.535 0 3.123 2.585 5.645 5.789 5.645 3.204 0 5.79-2.522 5.79-5.645 0-3.124-2.586-5.646-5.79-5.646-1.198 0-2.4.47-3.375 1.158l1.277.088c.204 0 1.662.111 1.755.288a.518.518 0 0 1-.042.55.549.549 0 0 1-.52.215l-3.805-.176a.547.547 0 0 1-.395-.21.519.519 0 0 1-.096-.43l.542-3.71A.54.54 0 0 1 10.765 7z"/>
        </g>
      </g>
    </g>
  </Icon>
)
BulkRecreate.defaultProps = {
  size: 16
}

export default BulkRecreate
