import { FC, useState } from 'react';
import { createPortal } from 'react-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Modal } from 'jbc-front/components/presenters/ui/Modal';
import Button from 'jbc-front/components/Button';
import ActionButton from 'jbc-front/components/ActionButton';
import { Edit } from 'jbc-front/components/icons';
import { ButtonRow } from 'jbc-front/components/presenters/layout/ButtonRow';

import { FormSection } from '@jbc-year-end-adj/2024/components/form/FormSection';
import { Textarea } from '@jbc-year-end-adj/2024/components/form/Textarea';
import { QuestionDescription } from '@jbc-year-end-adj/2024/features/questions/types';
import { useAdminMutation as useMutation } from '@jbc-year-end-adj/2024/hooks/graphql/useMutation';

import { UPDATE_QUESTION_CUSTOM_DESCRIPTIONS } from './mutation';
import { Schema, schema, buildDefaultValues } from './schema';
import styles from './EditModal.scss';

type EditModalProps = {
  questionId: string;
  descriptions: QuestionDescription[];
};

export const EditModal: FC<EditModalProps> = ({ questionId, descriptions }) => {
  const [updateCustomDescription, { loading }] = useMutation(UPDATE_QUESTION_CUSTOM_DESCRIPTIONS);

  const { register, reset, setValue, handleSubmit } = useForm<Schema>({
    resolver: yupResolver(schema),
    defaultValues: buildDefaultValues(descriptions)
  });

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleClose = () => setIsOpen(false);

  const handleResetDescription = (descirption: QuestionDescription, index: number) => {
    setValue(`descriptions.${index}.description`, descirption.defaultDescription);
  };

  const onSubmit: SubmitHandler<Schema> = async data => {
    const variables = {
      input: {
        attributes: {
          questionId,
          customDescriptions: data.descriptions.map(description => ({
            field: description.field,
            description: description.description
          }))
        }
      }
    };

    await updateCustomDescription({ variables });
    handleClose();
  };

  const handleCancel = () => {
    reset();
    handleClose();
  };

  return (
    <>
      <ActionButton icon={<Edit />} onClick={() => setIsOpen(true)}>
        説明文の編集
      </ActionButton>

      {createPortal(
        <Modal isOpen={isOpen} onClose={handleClose}>
          <Modal.Header onClose={handleClose}>説明文を編集</Modal.Header>
          <Modal.Body>
            <FormSection>
              {descriptions.map((description, index) => {
                return (
                  <div key={description.id} className={styles.formSection}>
                    <div className={styles.labelContainer}>
                      <span>{description.name}</span>
                      <ActionButton onClick={() => handleResetDescription(description, index)}>初期説明に戻す</ActionButton>
                    </div>
                    <div>
                      <Textarea {...register(`descriptions.${index}.description`)} />
                    </div>
                  </div>
                );
              })}
            </FormSection>
          </Modal.Body>
          <Modal.Footer>
            <ButtonRow>
              <Button onClick={handleCancel}>キャンセル</Button>
              <Button onClick={handleSubmit(onSubmit)} primary disabled={loading}>
                保存
              </Button>
            </ButtonRow>
          </Modal.Footer>
        </Modal>,
        document.body
      )}
    </>
  );
};
