import React from 'react';
import Button from 'jbc-front/components/Button';
import { useMutation, useQuery } from '../components/Graphql';
import { gql } from '@apollo/client';
import { TextAreaField } from 'jbc-front/components/Form';
import Hint from 'jbc-front/components/Hint';
import { toFormValues } from '../utils/form';
import _ from 'lodash';
import { reduxForm, useFormValue, useAutofill } from '../components/FormName';
import { Panel, Headding, Item, Lead, Notes, Buttons, Line } from '../components/PrimaryPanel';
import LoadingPage from '../components/LoadingPage';
import useReactRouter from 'use-react-router';
import { CustomDescription } from '../components/QuestionDescription';
import styles from './Widow.scss';

export const widowClassifications = (maritalStatus, gender, year) => {
  if (year >= 2020) {
    return [
      { value: 'none', label: '対象外' },
      { value: 'single_parent', label: 'ひとり親', disabled: maritalStatus === 'married' },
      {
        value: 'normal',
        label: '寡婦',
        disabled: gender === 'male' || maritalStatus !== 'divorced_or_bereavement'
      }
    ];
  } else {
    return [
      { value: 'none', label: '対象外' },
      {
        value: 'normal',
        label: gender === 'male' ? '寡夫' : gender === 'female' ? '寡婦' : '寡婦（夫）',
        disabled: maritalStatus !== 'divorced_or_bereavement'
      },
      {
        value: 'special',
        label: '特別寡婦',
        disabled: gender === 'male' || maritalStatus !== 'divorced_or_bereavement'
      }
    ];
  }
};

export const maritalStatuses = [
  { value: 'married', label: '有' },
  { value: 'unmarried', label: '無（未婚）' },
  { value: 'divorced_or_bereavement', label: '無（離婚・死別等）' }
];

export const WIDOW_FRAGMENT = gql`
  fragment Widow on Profile {
    id
    widowClassification {
      id
      classification
      detail
    }
  }
`;

const WIDOW = gql`
  query widow {
    request {
      id
      employee {
        id
        year
      }
      profile {
        gender
        maritalStatus
        ...Widow
      }
    }
  }
  ${WIDOW_FRAGMENT}
`;

const ANSWER_WIDOW = gql`
  mutation answerWidow($input: AnswerWidowInput!) {
    answerWidow(input: $input) {
      questions {
        id
        status
      }
      profile {
        maritalStatus
        ...Widow
      }
    }
  }
  ${WIDOW_FRAGMENT}
`;

const QuestionForm =
  (({ handleSubmit, gender, submitting }) => {
    const [answer, { loading }] = useMutation(ANSWER_WIDOW);
    const classification = useFormValue('classification');
    const maritalStatus = useFormValue('maritalStatus');
    const { autofill } = useAutofill();
    const { history } = useReactRouter();
    return (
      <Panel>
        <Headding>配偶者・寡婦（寡夫）情報</Headding>
        <Item>
          <Lead>配偶者について、該当するものを選んでください。</Lead>
          <Buttons>
            <Button
              huge
              secondary
              disabled={loading}
              primary={maritalStatus === 'married'}
              onClick={async () => {
                await answer({ variables: { input: { maritalStatus: 'married', classification: 'none' } } });
                history.push('/employee_input');
              }}
            >
              配偶者あり
            </Button>
            <Button
              huge
              secondary
              disabled={loading}
              primary={maritalStatus === 'unmarried'}
              onClick={async () => {
                await answer({ variables: { input: { maritalStatus: 'unmarried', classification: 'none' } } });
                history.push('/employee_input');
              }}
            >
              配偶者なし
              <br />
              （未婚）
            </Button>
            <Button
              huge
              secondary
              disabled={loading}
              primary={maritalStatus === 'divorced_or_bereavement'}
              onClick={() => autofill('maritalStatus', 'divorced_or_bereavement')}
            >
              配偶者なし
              <br />
              （離婚・死別等）
            </Button>
          </Buttons>

          {maritalStatus === 'divorced_or_bereavement' && (
            <div>
              <Line />
              <Lead>あなたは以下のいずれかに該当しますか？</Lead>
              <Notes>
                {gender === 'male' && (
                  <div>
                    <p>寡夫とは、以下のすべてに該当する方です。</p>
                    <ul className="m-list-notes">
                      <li>・合計所得金額が500万円以下であること</li>
                      <li>・妻と死別し、若しくは離婚した後婚姻をしていないこと又は妻の生死が明らかでない一定の人であること。</li>
                      <li>
                        ・生計を一にする子がいること。
                        <Hint
                          text={
                            <div>
                              「生計を一にする子」とは、総所得金額等が38万円以下で、他の人の控除対象配偶者や扶養親族になっていない人のことです。
                              「合計所得金額」とは、各種損失の繰越控除を適用する前の総所得金額、譲渡所得、配当所得、雑所得、山林所得、退職所得の合計額をいいます。
                              詳しくは
                              <a href="https://www.nta.go.jp/m/taxanswer/1172.htm" target="_blank" rel="noopener noreferrer">
                                こちら
                              </a>
                            </div>
                          }
                        />
                      </li>
                    </ul>
                  </div>
                )}
                {gender === 'female' && (
                  <React.Fragment>
                    <div className="u-mb20">
                      <p>寡婦とは、以下のいずれかに該当する方です。</p>
                      <ul>
                        <li>
                          ・夫と死別し、若しくは離婚した後婚姻をしていない人、又は夫の生死が明らかでない一定の人で、扶養親族がいる人又は生計を一にする子がいる人
                          <Hint
                            text={
                              <div>
                                「生計を一にする子」とは、総所得金額等が38万円以下で、他の人の控除対象配偶者や扶養親族になっていない人のことです。
                                「合計所得金額」とは、各種損失の繰越控除を適用する前の総所得金額、譲渡所得、配当所得、雑所得、山林所得、退職所得の合計額をいいます。
                                詳しくは
                                <a href="https://www.nta.go.jp/m/taxanswer/1170.htm" target="_blank" rel="noopener noreferrer">
                                  こちら
                                </a>
                              </div>
                            }
                          />
                        </li>
                        <li>・夫と死別した後婚姻をしていない人又は夫の生死が明らかでない一定の人で、合計所得金額が500万円以下の人</li>
                      </ul>
                    </div>
                    <div>
                      <p>特別寡婦とは、以下のすべてに該当する方です。</p>
                      <ul>
                        <li>・夫と死別し又は離婚した後婚姻をしていない人や夫の生死が明らかでない一定の人</li>
                        <li>・扶養親族である子がいる人</li>
                        <li>
                          ・合計所得金額が500万円以下であること。
                          <Hint
                            text={
                              <div>
                                「合計所得金額」とは、各種損失の繰越控除を適用する前の総所得金額、譲渡所得、配当所得、雑所得、山林所得、退職所得の合計額をいいます。詳しくは
                                <a href="https://www.nta.go.jp/m/taxanswer/1170.htm" target="_blank" rel="noopener noreferrer">
                                  こちら
                                </a>
                              </div>
                            }
                          />
                        </li>
                      </ul>
                    </div>
                  </React.Fragment>
                )}
              </Notes>

              <Buttons>
                <Button
                  huge
                  secondary
                  disabled={loading}
                  primary={classification === 'none'}
                  onClick={async () => {
                    await answer({
                      variables: { input: { maritalStatus: 'divorced_or_bereavement', classification: 'none' } }
                    });
                    history.push('/employee_input');
                  }}
                >
                  対象外
                </Button>
                <Button
                  huge
                  secondary
                  disabled={loading}
                  primary={classification === 'normal'}
                  onClick={() => autofill('classification', 'normal')}
                >
                  {gender === 'female' ? '寡婦' : '寡夫'}
                </Button>
                {gender === 'female' && (
                  <Button
                    huge
                    secondary
                    disabled={loading}
                    primary={classification === 'special'}
                    onClick={() => autofill('classification', 'special')}
                  >
                    特別寡婦
                  </Button>
                )}
              </Buttons>

              {classification !== 'none' && (
                <React.Fragment>
                  <Lead>以下の情報の記入が必要です。</Lead>
                  <TextAreaField name="detail" label={gender === 'male' ? '寡夫詳細情報' : '寡婦詳細情報'} />
                  <Buttons>
                    <Button primary onClick={handleSubmit} disabled={submitting}>
                      次ヘ
                    </Button>
                  </Buttons>
                </React.Fragment>
              )}
            </div>
          )}
        </Item>
      </Panel>
    );
  }) |> reduxForm({ form: 'widow' });

const QuestionForm2020 =
  (({ handleSubmit, gender, submitting }) => {
    const [answer, { loading }] = useMutation(ANSWER_WIDOW);
    const classification = useFormValue('classification');
    const maritalStatus = useFormValue('maritalStatus');
    const { autofill } = useAutofill();
    const { history } = useReactRouter();
    return (
      <Panel>
        <Headding className={styles.primaryPanelHeader}>配偶者情報</Headding>
        <Item>
          <Lead>配偶者について、該当するものを選んでください。</Lead>
          <CustomDescription field="top" />
          <Buttons>
            <Button
              huge
              secondary
              disabled={loading}
              primary={maritalStatus === 'married'}
              onClick={async () => {
                await answer({ variables: { input: { maritalStatus: 'married', classification: 'none' } } });
                history.push('/employee_input');
              }}
            >
              配偶者あり
            </Button>
            <Button
              huge
              secondary
              disabled={loading}
              primary={maritalStatus === 'unmarried'}
              onClick={() => {
                autofill('maritalStatus', 'unmarried');
                if (classification === 'normal') {
                  autofill('classification', 'none');
                }
              }}
            >
              配偶者なし
              <br />
              （未婚）
            </Button>
            <Button
              huge
              secondary
              disabled={loading}
              primary={maritalStatus === 'divorced_or_bereavement'}
              onClick={() => autofill('maritalStatus', 'divorced_or_bereavement')}
            >
              配偶者なし
              <br />
              （離婚・死別等）
            </Button>
          </Buttons>

          {maritalStatus && maritalStatus !== 'married' && (
            <div>
              <Line />
              <Lead>あなたは以下のいずれかに該当しますか？</Lead>
              <Notes>
                <div>
                  <p>
                    ひとり親とは、婚姻をしていないこと又は配偶者の生死の明らかでない一定の人のうち、次の三つの要件の全てに当てはまる人です。
                  </p>
                  <ul>
                    <li>・その人と事実上婚姻関係と同様の事情にあると認められる一定の人がいないこと。</li>
                    <li>
                      ・生計を一にする子がいること。
                      <Hint
                        text={
                          <div>
                            「生計を一にする子」とは、総所得金額等が48万円以下で、他の人の控除対象配偶者や扶養親族になっていない人のことです。
                            「合計所得金額」とは、各種損失の繰越控除を適用する前の総所得金額、譲渡所得、配当所得、雑所得、山林所得、退職所得の合計額をいいます。
                            詳しくは
                            <a href="https://www.nta.go.jp/m/taxanswer/1180_qa.htm#q1" target="_blank" rel="noopener noreferrer">
                              こちら
                            </a>
                          </div>
                        }
                      />
                    </li>
                    <li>
                      ・合計所得金額が500万円以下であること。
                      <Hint
                        text={
                          <div>
                            「合計所得金額」とは、各種損失の繰越控除を適用する前の総所得金額、譲渡所得、配当所得、雑所得、山林所得、退職所得の合計額をいいます。詳しくは
                            <a href="https://www.nta.go.jp/m/taxanswer/1170.htm#aa1" target="_blank" rel="noopener noreferrer">
                              こちら
                            </a>
                          </div>
                        }
                      />
                    </li>
                  </ul>
                </div>
                {gender === 'female' && maritalStatus === 'divorced_or_bereavement' && (
                  <div className="u-mt20">
                    <p>
                      寡婦とは、「ひとり親」に該当せず、次のいずれかに当てはまる人です。納税者と事実上婚姻関係と同様の事情にあると認められる一定の人がいる場合は対象となりません。
                    </p>
                    <ul className="m-list-notes">
                      <li>・夫と離婚した後婚姻をしておらず、扶養親族がいる人で、合計所得金額が500万円以下の人</li>
                      <li>・夫と死別した後婚姻をしていない人又は夫の生死が明らかでない一定の人で、合計所得金額が500万円以下の人</li>
                    </ul>
                  </div>
                )}
              </Notes>

              <Buttons>
                <Button
                  huge
                  secondary
                  disabled={loading}
                  primary={classification === 'none'}
                  onClick={async () => {
                    await answer({
                      variables: { input: { maritalStatus, classification: 'none' } }
                    });
                    history.push('/employee_input');
                  }}
                >
                  対象外
                </Button>
                <Button
                  huge
                  secondary
                  disabled={loading}
                  primary={classification === 'single_parent'}
                  onClick={async () => {
                    await answer({
                      variables: { input: { maritalStatus, classification: 'single_parent' } }
                    });
                    history.push('/employee_input');
                  }}
                >
                  ひとり親
                </Button>
                {gender === 'female' && maritalStatus === 'divorced_or_bereavement' && (
                  <Button
                    huge
                    secondary
                    disabled={loading}
                    primary={classification === 'normal'}
                    onClick={async () => {
                      await answer({
                        variables: { input: { maritalStatus, classification: 'normal' } }
                      });
                      history.push('/employee_input');
                    }}
                  >
                    寡婦
                  </Button>
                )}
              </Buttons>
            </div>
          )}
        </Item>
      </Panel>
    );
  }) |> reduxForm({ form: 'widow' });

const Question = ({ handleSubmit }) => {
  const { loading, data } = useQuery(WIDOW);
  const [anwser] = useMutation(ANSWER_WIDOW);
  const { history } = useReactRouter();
  if (loading) return <LoadingPage />;
  const {
    request: {
      profile,
      employee: { year }
    }
  } = data;
  if (year >= 2020) {
    return (
      <QuestionForm2020
        gender={profile.gender}
        initialValues={toFormValues({
          ..._.omit(profile.widowClassification || { classification: 'none' }, 'id'),
          maritalStatus: profile.maritalStatus
        })}
        onSubmit={async values => {
          await anwser({ variables: { input: values } });
          history.push('/employee_input');
        }}
      />
    );
  } else {
    return (
      <QuestionForm
        gender={profile.gender}
        initialValues={toFormValues({
          ..._.omit(profile.widowClassification || { classification: 'none' }, 'id'),
          maritalStatus: profile.maritalStatus
        })}
        onSubmit={async values => {
          await anwser({ variables: { input: values } });
          history.push('/employee_input');
        }}
      />
    );
  }
};

export default Question;
