import React from 'react'
import Icon from 'react-icon-base'

const Custom30 = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="Custom30" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon/employees/Custom9-Copy-3" fill="#3498DB">
        <path d="M6.01684939,8.09035025 C9.92935204,4.20939117 14.7759371,2.93667776 19.4274388,5.21340378 L19.8015314,5.40440227 C20.2891547,5.66371281 20.4742388,6.26922234 20.2149283,6.75684564 C19.9556177,7.24446894 19.3501082,7.429553 18.8624849,7.17024246 L18.54819,7.0097672 C14.7375722,5.14462057 10.7783144,6.18432181 7.42532747,9.51027612 C4.36160976,12.5492934 2.33200816,17.0833249 2.33200816,20.7873224 C2.33200816,28.1897958 9.90135151,34.2873224 19.3320082,34.2873224 C22.5772344,34.2873224 25.7278701,33.5920861 28.4374937,32.4397183 L28.9734356,32.2032202 C34.4486191,29.6978819 37.9415505,25.307882 36.3943798,21.1349617 C35.7754705,19.4656829 34.2950805,18.5143668 31.5818662,17.7660265 L31.0775112,17.632645 L30.6841077,17.5348312 L28.9192023,17.1198849 L28.3592461,16.9811055 L27.8615551,16.8480027 L27.4182659,16.7171426 C26.7902015,16.5208178 26.3192037,16.3204737 25.8858459,16.0639608 C25.6160258,15.9042491 25.3682308,15.7271212 25.1404109,15.5320402 C24.7209093,15.1728232 24.672039,14.541547 25.031256,14.1220454 C25.390473,13.7025438 26.0217491,13.6536735 26.4412507,14.0128905 C26.5807098,14.1323086 26.734078,14.2419387 26.904593,14.3428698 C27.1894648,14.511491 27.5399571,14.6583058 28.0292992,14.8112232 L28.4234844,14.9274915 C28.4939024,14.9472057 28.5667759,14.9671242 28.6422481,14.9873015 L29.1274087,15.1119084 L31.1598951,15.5922441 L31.5724471,15.6948507 L31.768678,15.745453 C35.2581096,16.6567476 37.3375995,17.9258583 38.2696365,20.4396831 C40.3253719,25.9842686 35.9279518,31.4223872 29.2780199,34.261186 L28.6941898,34.5014258 C25.8458925,35.6303325 22.6212113,36.2873224 19.3320082,36.2873224 C8.88041382,36.2873224 0.332008161,29.4011067 0.332008161,20.7873224 C0.332008161,16.5301238 2.58702744,11.4925201 6.01684939,8.09035025 Z" id="Path" fillRule="nonzero" transform="translate(19.555628, 20.186118) rotate(40.000000) translate(-19.555628, -20.186118) "></path>
        <path d="M25.5,27 C27.4329966,27 29,28.1192881 29,29.5 C29,30.8807119 27.4329966,32 25.5,32 C23.5670034,32 22,30.8807119 22,29.5 C22,28.1192881 23.5670034,27 25.5,27 Z M25.5,29 C24.9648977,29 24.4905508,29.1433084 24.1876027,29.3597 L24.0813959,29.4407869 L24.011,29.5 L24.1275203,29.5958423 L24.1876027,29.6403 C24.4905508,29.8566916 24.9648977,30 25.5,30 C26.0351023,30 26.5094492,29.8566916 26.8123973,29.6403 L26.9186041,29.5592131 L26.988,29.5 L26.9186041,29.4407869 L26.8123973,29.3597 C26.5094492,29.1433084 26.0351023,29 25.5,29 Z" id="Oval" fillRule="nonzero"></path>
        <ellipse id="Oval" cx="13.5" cy="26.5" rx="3.5" ry="2.5"></ellipse>
        <ellipse id="Oval-Copy" cx="10.5" cy="19.5" rx="3.5" ry="2.5"></ellipse>
        <ellipse id="Oval-Copy-2" cx="11.5" cy="12.5" rx="3.5" ry="2.5"></ellipse>
        <ellipse id="Oval-Copy-3" cx="18.5" cy="8.5" rx="3.5" ry="2.5"></ellipse>
        <g id="Group" transform="translate(28.000000, 14.500000) rotate(40.000000) translate(-28.000000, -14.500000) translate(26.000000, 0.000000)">
          <path d="M3,0 C3.55228475,0 4,0.44771525 4,1 L4,22 C4,22.5522847 3.55228475,23 3,23 L1,23 C0.44771525,23 0,22.5522847 0,22 L0,1 C0,0.44771525 0.44771525,0 1,0 L3,0 Z M2.7,1.3 L1.3,1.3 L1.3,21.7 L2.7,21.7 L2.7,1.3 Z" id="Rectangle" fillRule="nonzero"></path>
          <path d="M2.51794422,21.6202429 L1.1951224,21.6202429 C1.00161492,21.6202429 0.825480726,21.7319001 0.74291771,21.90691 C-0.306762622,24.131932 -0.576450753,25.8287895 0.0022474683,27.0165658 C0.384374284,27.80088 0.409849406,28.291739 0.170486917,28.5731159 L0.115174778,28.6302352 C-0.222691696,28.9377631 -0.00512993289,29.5 0.45173675,29.5 C3.07653275,29.5 4.5,28.2964811 4.5,26 C4.5,23.8418642 3.94347914,22.3849921 2.77316779,21.6902884 C2.69592922,21.6444391 2.607766,21.6202429 2.51794422,21.6202429 Z M1.515,22.62 L2.369,22.62 L2.26272065,22.5501975 C3.06848367,23.0285032 3.5,24.1581358 3.5,26 L3.49638513,26.1790541 C3.44329282,27.4667388 2.8037363,28.1969042 1.46996543,28.4231765 L1.296,28.448 L1.31279543,28.3387082 C1.36806989,27.8345543 1.22442563,27.2419394 0.901226031,26.5785732 L0.846650079,26.4537198 C0.545339519,25.6811655 0.715439265,24.4870203 1.40001969,22.8833016 L1.515,22.62 Z" id="Path-5" fillRule="nonzero"></path>
          <path d="M0.203941534,24.9648307 C0.987484567,25.0558782 1.4277927,25.362319 1.52486595,25.8841529 C1.62193919,26.4059869 1.71450185,26.831305 1.80255394,27.1601071 C1.92185488,26.6061959 2.11399859,26.2742242 2.37898509,26.164192 C2.64397159,26.0541598 3.05312817,26.3571175 3.60645483,27.0730651 C3.65295385,27.5558923 3.32571915,27.9977549 2.62475072,28.398653 C1.92378229,28.799551 1.24928522,29 0.601259497,29 L1.05360618,28.0414987 L0.203941534,24.9648307 Z" id="Path"></path>
        </g>
      </g>
    </g>
  </Icon>
)
Custom30.defaultProps = {
  size: 30
}

export default Custom30