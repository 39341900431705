import { yup } from '@jbc-year-end-adj/common/yup';
import { ResidentsTaxCollectionMethod, Employee } from '../../../../query';

export type Schema = {
  name?: string;
  code?: string;
  residentsTaxCollectionMethod: ResidentsTaxCollectionMethod;
};

export const schema = yup.object({
  name: yup.string(),
  code: yup
    .string()
    .numberFormat()
    .label('市区町村コード'),
  residentsTaxCollectionMethod: yup
    .mixed<ResidentsTaxCollectionMethod>()
    .required()
    .label('住民税徴収方法')
});

export const generateDefaultValues = (employee: Employee) => ({
  name: employee.profile.residentTaxCity?.name ?? '',
  code: employee.profile.residentTaxCity?.code ?? '',
  residentsTaxCollectionMethod: employee.residentsTaxCollectionMethod
});
