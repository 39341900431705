import React from 'react'
import Icon from 'react-icon-base'

const Income = (props) => (
  <Icon viewBox="0 0 30 30" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <path d="M27,0 C28.6568542,0 30,1.34314575 30,3 L30,27 C30,28.6568542 28.6568542,30 27,30 L3,30 C1.34314575,30 0,28.6568542 0,27 L0,3 C0,1.34314575 1.34314575,0 3,0 L27,0 Z M27,1 L3,1 C1.8954305,1 1,1.8954305 1,3 L1,27 C1,28.1045695 1.8954305,29 3,29 L27,29 C28.1045695,29 29,28.1045695 29,27 L29,3 C29,1.8954305 28.1045695,1 27,1 Z" fill="#666666" fillRule="nonzero"></path>
        <path d="M18.0529104,24.5 L18.0529104,24.5 L7,24.5 C6.17157288,24.5 5.5,23.8284271 5.5,23 L5.5,7 C5.5,6.17157288 6.17157288,5.5 7,5.5 L19,5.5 C19.8284271,5.5 20.5,6.17157288 20.5,7 L20.5,11.9497864 L20.5,11.9497864 M8.5,8.5 L17.5,8.5 M8.5,11.5 L15.5,11.5 M8.5,14.5 L12.5,14.5 M8.5,20 L11.5,20" stroke="#666666" strokeLinecap="round" strokeLinejoin="round"></path>
        <path d="M18,11 C21.8659932,11 25,14.1340068 25,18 C25,21.8659932 21.8659932,25 18,25 C14.1340068,25 11,21.8659932 11,18 C11,14.1340068 14.1340068,11 18,11 Z M18,12 C14.6862915,12 12,14.6862915 12,18 C12,21.3137085 14.6862915,24 18,24 C21.3137085,24 24,21.3137085 24,18 C24,14.6862915 21.3137085,12 18,12 Z M20.4435397,13.8216755 C20.7271166,14.0331731 20.7766991,14.4222957 20.5540129,14.6916247 L18.8810345,16.7138322 L18.8810345,16.8998322 L20.0886336,16.9007835 C20.4487589,16.9007835 20.7410345,17.1783741 20.7410345,17.5204055 C20.7410345,17.8616106 20.4487589,18.1392012 20.0886336,18.1392012 L18.8810345,18.1388322 L18.8810345,18.7108322 L20.0886336,18.7109058 C20.4487589,18.7109058 20.7410345,18.9884964 20.7410345,19.3305277 C20.7410345,19.672559 20.4487589,19.9501497 20.0886336,19.9501497 L18.8810345,19.9498322 L18.8812572,21.4025435 C18.8812572,21.7445749 18.5889816,22.0221655 18.2288563,22.0221655 C17.8687311,22.0221655 17.5764555,21.7445749 17.5764555,21.4025435 L17.5760345,19.9498322 L16.3934353,19.9501497 C16.0333101,19.9501497 15.7410345,19.672559 15.7410345,19.3305277 C15.7410345,18.9884964 16.0333101,18.7109058 16.3934353,18.7109058 L17.5760345,18.7108322 L17.5760345,18.1388322 L16.3934353,18.1392012 C16.0333101,18.1392012 15.7410345,17.8616106 15.7410345,17.5204055 C15.7410345,17.1783741 16.0333101,16.9007835 16.3934353,16.9007835 L17.5760345,16.8998322 L17.5760345,16.5698322 L16.0211319,14.6916247 C15.7984458,14.4222957 15.8480282,14.0331731 16.1316051,13.8216755 C16.415182,13.6101778 16.8248897,13.6564429 17.0475759,13.9257719 L18.2880073,15.4252571 L19.5275689,13.9257719 C19.7485153,13.6580953 20.1590929,13.6085255 20.4435397,13.8216755 Z" fill="#666666" fillRule="nonzero"></path>
      </g>
    </g>
  </Icon>
)
Income.defaultProps = {
  size: 30
}

export default Income