import { FC } from 'react';
import { Start } from './Start';
import { ResignInYear } from './ResignInYear';
import { MultiCompanies } from './MultiCompanies';
import { SalaryMore } from './SalaryMore';
import { SalaryMoreNext } from './SalaryMoreNext';
import { BasicInfos } from './BasicInfos';
import { Householder } from './Householder';
import { FormerJobs } from './FormerJobs';
import { Income } from './Income';
import { SalaryIncome } from './SalaryIncome';
import { OtherIncomeType } from './OtherIncomeType';
import { AllIncome } from './AllIncome';
import { WorkingStudent } from './WorkingStudent';
import { Handicap } from './Handicap';
import { Widow } from './Widow';
import { Spouse } from './Spouse';
import { HasDependent } from './HasDependent';
import { Dependents } from './Dependents';
import { HasInsurances } from './HasInsurances';
import { LifeInsurances } from './LifeInsurances';
import { EarthquakeInsurances } from './EarthquakeInsurances';
import { SocialInsurances } from './SocialInsurances';
import { Premium } from './Premium';
import { HousingLoan } from './HousingLoan';
import { Attachments } from './Attachments';
import { Question } from 'features/questions/type';

type WizardProps = {
  currentQuestionCode?: Question['code'];
};

export const Wizard: FC<WizardProps> = ({ currentQuestionCode }) => {
  switch (currentQuestionCode) {
    case 'start':
      return <Start />;
    case 'resign_in_year':
      return <ResignInYear />;
    case 'multi_companies':
      return <MultiCompanies />;
    case 'salary_more':
      return <SalaryMore />;
    case 'salary_more_next':
      return <SalaryMoreNext />;
    case 'basic_infos':
      return <BasicInfos />;
    case 'householder':
      return <Householder />;
    case 'former_jobs':
      return <FormerJobs />;
    case 'income':
      return <Income />;
    case 'salary_income':
      return <SalaryIncome />;
    case 'other_income_type':
      return <OtherIncomeType />;
    case 'all_income':
      return <AllIncome />;
    case 'working_student':
      return <WorkingStudent />;
    case 'handicap':
      return <Handicap />;
    case 'widow':
      return <Widow />;
    case 'spouse':
      return <Spouse />;
    case 'has_dependent':
      return <HasDependent />;
    case 'dependents':
      return <Dependents />;
    case 'has_insurances':
      return <HasInsurances />;
    case 'life_insurances':
      return <LifeInsurances />;
    case 'earthquake_insurances':
      return <EarthquakeInsurances />;
    case 'social_insurances':
      return <SocialInsurances />;
    case 'premium':
      return <Premium />;
    case 'housing_loan':
      return <HousingLoan />;
    case 'attachments':
      return <Attachments />;
    default:
      return null;
  }
};
