import { FC } from 'react';
import { dateFormat, amountFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { Dl, Dt, Dd, FileDd, FileDl } from '../../../components/DefinitionList';
import { Grid } from '../../../components/Grid';
import { PreviewSubSection } from '../../../components/PreviewSubSection';
import { WITHHOLDING_SLIP_STATUS } from '../../../consts';
import { useEmployeeInfo } from '../EmployeeInfoProvider';

export const Preview: FC = () => {
  const { employee } = useEmployeeInfo();
  const profile = employee.profile;

  return (
    <Grid colGap>
      {profile.formerJobs.length > 0 ? (
        profile.formerJobs.map((formerJob, index) => (
          <PreviewSubSection key={index}>
            <PreviewSubSection.Header>前職（{index + 1}）</PreviewSubSection.Header>
            <PreviewSubSection.Body>
              <Dl>
                <Dt>会社名</Dt>
                <Dd>{formerJob.companyName}</Dd>
              </Dl>

              <Dl>
                <Dt>住所</Dt>
                <Dd>{formerJob.address}</Dd>
              </Dl>

              <Dl>
                <Dt>退職日</Dt>
                <Dd>{dateFormat(formerJob.resignedOn, 'L')}</Dd>
              </Dl>

              <Dl>
                <Dt>最終給与支払日</Dt>
                <Dd>{dateFormat(formerJob.lastSalaryOn, 'L')}</Dd>
              </Dl>

              <Dl>
                <Dt>源泉徴収票</Dt>
                <Dd>{WITHHOLDING_SLIP_STATUS[formerJob.withholdingSlipStatus]}</Dd>
              </Dl>

              <FileDl>
                <Dt>源泉徴収票画像</Dt>
                <FileDd file={formerJob.withholdingSlipImage} />
              </FileDl>

              <Dl>
                <Dt>支払金額</Dt>
                <Dd>{amountFormat(formerJob.paymentAmount)}</Dd>
              </Dl>

              <Dl>
                <Dt>社会保険料等の金額</Dt>
                <Dd>{amountFormat(formerJob.deductionAmount)}</Dd>
              </Dl>

              <Dl>
                <Dt>源泉徴収税額</Dt>
                <Dd>{amountFormat(formerJob.taxAmount)}</Dd>
              </Dl>
            </PreviewSubSection.Body>
          </PreviewSubSection>
        ))
      ) : (
        <p>前職情報が存在しません。</p>
      )}
    </Grid>
  );
};
