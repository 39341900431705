import React from 'react';
import { gql } from '@apollo/client';
import { useQuery, useYear } from '../components';
import styles from '../components/AsyncTaskError.scss';

const EMPLOYEES_WITH_WARNING = gql`
  query employeesWithWarning($year: Int!) {
    client {
      id
      clientYearly(year: $year) {
        id
        employeesWithWarning {
          id
          staffCode
          profile {
            id
            fullName
          }
        }
      }
    }
  }
`;

const EmployeesWithWarning = () => {
  const year = useYear();
  const { loading, data } = useQuery(EMPLOYEES_WITH_WARNING, { fetchPolicy: 'network-only', variables: { year } });
  if (loading) return null;
  const {
    client: {
      clientYearly: { employeesWithWarning }
    }
  } = data;

  return (
    employeesWithWarning.length > 0 && (
      <div className={styles.errorWrap}>
        <div className={styles.inner}>
          <p className={styles.title}>従業員情報と源泉徴収票の前職の支払金額、控除額、源泉徴収税額が一致しない従業員がいます。</p>
          <ul className={styles.list}>
            {employeesWithWarning.map(employee => (
              <li key={employee.id}>
                {employee.staffCode} {employee.profile.fullName}
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  );
};

export default EmployeesWithWarning;
