import React from 'react';
import Button from 'jbc-front/components/Button';
import { reduxForm } from 'redux-form';
import { Panel, Headding, Item, Lead, Buttons, Text, Line } from '../components/PrimaryPanel';
import ToggleButton from '../components/ToggleButton';
import { DescriptionEdit } from '../components/DescriptionEdit';
import { CustomDescription } from '../components/QuestionDescription';

const options = [
  { value: true, label: 'はい', disabled: true },
  { value: false, label: 'いいえ', disabled: true }
];

const formName = 'hasInsurances';

const QuestionForm =
  (({ handleSubmit, submitting }) => (
    <form onSubmit={handleSubmit}>
      <Lead>今年生命保険料を支払いましたか？</Lead>
      <CustomDescription field="life_insurance" as={Text} />
      <div className="u-ta-c">
        <ToggleButton options={options} name="hasLifeInsurance" />
      </div>

      <Line />

      <Lead>今年地震保険料を支払いましたか？</Lead>
      <CustomDescription field="earthquake_insurance" as={Text} />
      <div className="u-ta-c">
        <ToggleButton options={options} name="hasEarthquakeInsurance" />
      </div>

      <Line />

      <Lead>今年国民年金・国民健康保険料を支払いましたか？</Lead>
      <CustomDescription field="social_insurance" as={Text} />
      <div className="u-ta-c">
        <ToggleButton options={options} name="hasSocialInsurance" />
      </div>

      <Line />

      <Lead>今年共済契約掛金・個人型企業型年金掛金を支払いましたか？</Lead>
      <CustomDescription field="premium" as={Text} />
      <div className="u-ta-c">
        <ToggleButton options={options} name="hasPremium" />
      </div>

      <Line />

      <Lead>住宅ローン控除の申告書がありますか？</Lead>
      <CustomDescription field="housing_loan" as={Text} />
      <div className="u-ta-c">
        <ToggleButton options={options} name="hasHousingLoan" />
      </div>

      <Buttons>
        <Button primary disabled>
          次ヘ
        </Button>
      </Buttons>
    </form>
  )) |> reduxForm({ form: formName });

const Question = () => {
  return (
    <Panel>
      <Headding icon={<DescriptionEdit />}>保険・ローンの有無</Headding>
      <Item>
        <QuestionForm />
      </Item>
    </Panel>
  );
};

export default Question;
