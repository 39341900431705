import { FC, ReactNode } from 'react';
import styles from './Navigator.scss';
import classnames from 'classnames';

type NavigatorProps = JSX.IntrinsicElements['div'] & {
  isOpen?: boolean;
  children: ReactNode;
};

export const Navigator: FC<NavigatorProps> = ({ className, children, ...divProps }) => {
  return (
    <div {...divProps} className={classnames(styles.navigator, className)}>
      {children}
    </div>
  );
};

type HeaderProps = JSX.IntrinsicElements['div'] & {
  children: ReactNode;
};

export const Header: FC<HeaderProps> = ({ className, children, ...divProps }) => {
  return (
    <div {...divProps} className={classnames(styles.header, className)}>
      {children}
    </div>
  );
};

type BodyProps = JSX.IntrinsicElements['div'] & {
  children: ReactNode;
};

export const Body: FC<BodyProps> = ({ className, children, ...divProps }) => {
  return (
    <div {...divProps} className={classnames(styles.body, className)}>
      {children}
    </div>
  );
};

type ItemProps = JSX.IntrinsicElements['div'] & {
  children: ReactNode;
};

export const Item: FC<ItemProps> = ({ className, children, ...divProps }) => {
  return (
    <div {...divProps} className={classnames(styles.item, className)}>
      {children}
    </div>
  );
};
