import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';
import 'font-awesome/scss/font-awesome.scss';
import 'jbc-front/styles/default.scss';
import 'normalize.css';
import 'nprogress/nprogress.css';
import '@jbc-year-end-adj/common/telemetry/init';
import React from 'react';
import { Provider } from 'react-redux';
import configureStore, { history } from './configureStore';
import { ConnectedRouter } from 'connected-react-router';
import * as _pages from './pages';
import { ErrorBoundary } from './components';
import { Routes } from 'routes/AdminRoutes';
import { ApolloProvider } from '@apollo/client';
import configureApollo from './configureApollo';

const store = configureStore();
const client = configureApollo('/graphql');

const App = () => (
  <ApolloProvider client={client}>
    <Provider store={store}>
      <ErrorBoundary>
        <ConnectedRouter history={history}>
          <Routes />
        </ConnectedRouter>
      </ErrorBoundary>
    </Provider>
  </ApolloProvider>
);

export default App;
