import React from 'react';
import { Link } from 'react-router-dom';
import ResidentTaxPaymentAddressForm from '../resident_tax_payment_address/ResidentTaxPaymentAddressForm';

const ResidentTaxPaymentAddress = () => {
  return (
    <div>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">住民税納付先</h1>
      </div>
      <div className="l-wrap-xs l-contents-wrap">
        <div className="l-breadcrumb u-mb20">
          <Link to="/settings" className="l-breadcrumb-link">
            設定
          </Link>
          <span className="l-breadcrumb-here">住民税納付先</span>
        </div>
      </div>
      <ResidentTaxPaymentAddressForm />
    </div>
  );
};

export default ResidentTaxPaymentAddress;
