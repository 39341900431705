import React from 'react'
import Icon from 'react-icon-base'

const Custom13 = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="Custom13" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon/employees/Custom7-Copy-7" fill="#3498DB" fillRule="nonzero">
        <g id="Group-2" transform="translate(2.414214, 1.414214)">
          <path d="M18.2928932,0.292893219 L34.8786797,16.8786797 C35.2692039,17.2692039 35.2692039,17.9023689 34.8786797,18.2928932 C34.6911433,18.4804296 34.4367894,18.5857864 34.1715729,18.5857864 L31.585,18.585 L31.5857864,36.5857864 L3.58578644,36.5857864 L3.585,18.585 L1,18.5857864 C0.44771525,18.5857864 -1.42108547e-14,18.1380712 -1.42108547e-14,17.5857864 C-1.42108547e-14,17.3205699 0.10535684,17.066216 0.292893219,16.8786797 L16.8786797,0.292893219 C17.2692039,-0.0976310729 17.9023689,-0.0976310729 18.2928932,0.292893219 Z M17.5857864,2.41478644 L3.41478644,16.5847864 L5.5849126,16.5843915 L5.58478644,34.5847864 L29.5857864,34.5847864 L29.5849126,16.5843918 L31.7557864,16.5847864 L17.5857864,2.41478644 Z" id="Combined-Shape"></path>
          <path d="M19.5857864,23.5857864 C21.7949254,23.5857864 23.5857864,25.3766474 23.5857864,27.5857864 L23.5857864,36.5857864 L11.5857864,36.5857864 L11.5857864,27.5857864 C11.5857864,25.3766474 13.3766474,23.5857864 15.5857864,23.5857864 L19.5857864,23.5857864 Z M19.5857864,25.5857864 L15.5857864,25.5857864 C14.5314246,25.5857864 13.6676213,26.4016642 13.5912722,27.4365241 L13.5857864,27.5857864 L13.5857864,34.5857864 L21.5857864,34.5857864 L21.5857864,27.5857864 C21.5857864,26.5314246 20.7699086,25.6676213 19.7350488,25.5912722 L19.5857864,25.5857864 Z" id="Rectangle"></path>
          <g id="Group" transform="translate(12.585786, 12.585786)">
            <path d="M7.57575758,0 C8.9146297,0 10,1.0853703 10,2.42424242 L10,8.18181818 L0,8.18181818 L0,2.42424242 C0,1.0853703 1.0853703,0 2.42424242,0 L7.57575758,0 Z M7.57575758,1.5 L2.42424242,1.5 C1.95025779,1.5 1.55960702,1.85679472 1.50621805,2.31645623 L1.5,2.42424242 L1.5,6.681 L8.5,6.681 L8.5,2.42424242 C8.5,1.95025779 8.14320528,1.55960702 7.68354377,1.50621805 L7.57575758,1.5 Z" id="Rectangle-Copy"></path>
            <polygon id="Line" points="5.45454545 0.909090909 5.45454545 7.27272727 4.54545455 7.27272727 4.54545455 0.909090909"></polygon>
            <polygon id="Line" points="9.09090909 3.63636364 9.09090909 4.54545455 0.974025974 4.54545455 0.974025974 3.63636364"></polygon>
          </g>
        </g>
      </g>
    </g>
  </Icon>
)
Custom13.defaultProps = {
  size: 30
}

export default Custom13