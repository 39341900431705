import { FC } from 'react';
import { OtherIncomeType as OtherIncomeTypeTemplate } from 'features/questions/templates/OtherIncomeType';
import { Section } from 'components/ui/Section';
import { Row } from 'components/form/Row';
import { Checkbox } from 'jbc-front/components/presenters/form/Checkbox';
import { Hint } from 'jbc-front/components/presenters/ui/Hint';
import { FormItem } from 'components/form/FormItem';
import { useWizard } from '../WizardProvider';

export const OtherIncomeType: FC = () => {
  const { question, request } = useWizard();

  return (
    <OtherIncomeTypeTemplate question={question}>
      <Section>
        <Section.Body>
          <Row>
            <FormItem>
              <Row>
                <Checkbox disabled checked={request.profile?.income?.hasBusinessIncome}>
                  事業所得
                </Checkbox>
                <Hint>
                  農業、林業、水産養殖業、製造業、卸売業、小売業、金融業などのサービス業のほか対価を得て継続的に行う事業による所得
                </Hint>
              </Row>
            </FormItem>
            <FormItem>
              <Row>
                <Checkbox disabled checked={request.profile?.income?.hasMiscellaneousIncome}>
                  雑所得
                </Checkbox>
                <Hint>雑所得 収入金額（公的年金等に係る雑所得）</Hint>
              </Row>
            </FormItem>
          </Row>
          <Row>
            <FormItem>
              <Row>
                <Checkbox disabled checked={request.profile?.income?.hasDividendIncome}>
                  配当所得
                </Checkbox>
                <Hint>
                  株主や出資者が法人から受ける剰余金や、利益の配当、剰余金の分配、投資法人からの金銭の分配、投資信託（公社債投資信託及び公募公社債等運用投資信託以外のもの）及び特定受益証券発行信託の収益の分配などに係る所得
                </Hint>
              </Row>
            </FormItem>
            <FormItem>
              <Row>
                <Checkbox disabled checked={request.profile?.income?.hasRealEstateIncome}>
                  不動産所得
                </Checkbox>
                <Hint>不動産の貸付けに際して受け取る権利金や頭金、更新料、名義書換料も不動産所得になります。</Hint>
              </Row>
            </FormItem>
          </Row>
          <Row>
            <FormItem>
              <Row>
                <Checkbox disabled checked={request.profile?.income?.hasRetirementIncome}>
                  退職所得
                </Checkbox>
                <Hint>退職手当、一時恩給その他の退職により一時に受ける給与などの所得のほか、社会保険制度等に基づく一時金など</Hint>
              </Row>
            </FormItem>
            <FormItem>
              <Row>
                <Checkbox disabled checked={request.profile?.income?.hasOtherIncome}>
                  その他所得
                </Checkbox>
                <Hint>
                  ⑴ 譲渡所得（土地、建物、機械、ゴルフ会員権、金地金、書画、骨とうなどの資産の譲渡による所得） ⑵
                  山林所得（山林（所有期間５年超）の伐採又は譲渡による所得） ⑶
                  一時所得（賞金や懸賞当せん金、競馬・競輪の払戻金（営利を目的とする継続的行為から生じたものを除きます。）、生命保険契約等に基づく一時金、損害保険契約等に基づく満期返戻金、遺失物拾得の報労金などによる所得）
                  ⑷ 総合課税又は申告分離課税の対象となる利子所得 （注）
                  利子所得のうち預貯金の利子などの源泉分離課税の対象となるもの及び特定公社債の利子などの申告分離課税の対象となるもので確定申告しないことを選択したものは、収入金額に含まれません。
                  ⑸
                  申告分離課税の適用を受けた一般株式等に係る譲渡所得等又は上場株式等に係る譲渡所得等（源泉徴収選択口座を通じて行った上場株式等の譲渡による所得等で、確定申告をしないことを選択した所得等は、収入金額に含まれません。）
                  ⑹ 先物取引に係る雑所得等
                </Hint>
              </Row>
            </FormItem>
          </Row>
        </Section.Body>
      </Section>
    </OtherIncomeTypeTemplate>
  );
};
