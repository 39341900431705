import { FC, ReactNode } from 'react';
import styles from './Layout.scss';

// @ts-ignore
import theme from 'reapop-theme-wybo';
// @ts-ignore
import Notifications from 'reapop';

type SimpleLayoutProps = {
  children: ReactNode;
};

export const SimpleLayout: FC<SimpleLayoutProps> = ({ children }) => {
  return (
    <div>
      <Notifications theme={theme} />
      <div className={styles.main}>
        <div className="l-main-contents">{children}</div>
      </div>
    </div>
  );
};
