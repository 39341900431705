import { gql } from '@apollo/client';
import { Schema } from './schema';

export type QueryVariables = {
  input: {
    employeeId: string;
    attributes: {
      lastName: string;
      firstName: string;
      lastNameKana: string;
      firstNameKana: string;
      birthday: string;
      postcode0: string;
      postcode1: string;
      prefectureId: string;
      city: string;
      street: string;
      building?: string;
      gender: 'male' | 'female';
      nationalType: 'japanese' | 'foreigner';
      householderRelationship: string;
      householderName?: string;
      resignBeforeYearEnd: boolean;
    };
  };
};

export const convertFormDataToQueryVariables = (employeeId: string, data: Schema): QueryVariables => {
  return {
    input: {
      employeeId: employeeId,
      attributes: {
        lastName: data.lastName,
        firstName: data.firstName,
        lastNameKana: data.lastNameKana,
        firstNameKana: data.firstNameKana,
        birthday: data.birthday,
        postcode0: data.postcode0,
        postcode1: data.postcode1,
        prefectureId: data.prefectureId,
        city: data.city,
        street: data.street,
        building: data.building,
        gender: data.gender,
        nationalType: data.nationalType,
        resignBeforeYearEnd: data.resignBeforeYearEnd === 'true',
        householderRelationship: data.householderRelationship,
        householderName: data.householderName
      }
    }
  };
};

export const UPDATE_REQUEST_BASIC_INFORMATION = gql`
  mutation UpdateRequestBasicInfos($input: UpdateRequestBasicInfosInput!) {
    updateRequestBasicInfos(input: $input) {
      employee {
        id
      }
    }
  }
`;
