import React from 'react'
import Icon from 'react-icon-base'

const CustomFileupload = props => (
  <Icon viewBox="0 0 14 14" {...props}>
    <path fill='#3498DB' d='M11.573,0H3.964c-0.247,0-0.485,0.099-0.66,0.273l-3.03,3.032C0.099,3.48,0,3.718,0,3.966v7.655  C0,13.252,0.794,14,2.426,14h1.858h2.03h5.258C13.206,14,14,13.252,14,11.621V2.424C14,0.793,13.206,0,11.573,0z M4.351,1.867h0.006 v2.44H1.912L4.351,1.867z M12.133,8.104L8.546,4.039l-3.71,4.21h2.626v2.604h2.173V8.249h2.498v3.372 c0,0.372-0.054,0.445-0.047,0.448c-0.02,0.01-0.139,0.063-0.514,0.063H6.314h-2.03H2.426c-0.37,0-0.482-0.039-0.495-0.047 c-0.011-0.02-0.063-0.094-0.063-0.465V6.175h3.423c0.516,0,0.934-0.418,0.934-0.934V1.867h5.349c0.376,0,0.492,0.054,0.496,0.047  c0.01,0.019,0.063,0.138,0.063,0.51V8.104z' />
  </Icon>
)
CustomFileupload.defaultProps = {
  size: 14
}

export default CustomFileupload
