import React, { useState } from 'react';
import { FieldArray } from 'redux-form';
import { gql } from '@apollo/client';
import { pick } from 'lodash/fp';
import ActionButton from 'jbc-front/components/ActionButton';
import Button from 'jbc-front/components/Button';
import { TextAreaField } from 'jbc-front/components/Form';
import Edit from 'jbc-front/components/icons/Edit';
import { reduxForm, useAutofill, useFormValue } from './FormName';
import { Modal } from './Modal';
import { useQuestionDescriptions } from './QuestionDescription';
import styles from './DescriptionEdit.scss';
import { useMutation } from './Graphql';

const UPDATE_QUESTION_CUSTOM_DESCRIPTIONS = gql`
  mutation updateCustomDescriptions($input: UpdateQuestionCustomDescriptionsInput!) {
    updateQuestionCustomDescriptions(input: $input) {
      question {
        id
        no
        name
        code
        questionDescriptions {
          id
          questionId
          field
          name
          description
          defaultDescription
        }
      }
    }
  }
`;

const Description = ({ field }) => {
  const { autofill } = useAutofill();
  const name = useFormValue(`${field}.name`);
  const description = useFormValue(`${field}.description`);
  const defaultDescription = useFormValue(`${field}.defaultDescription`);
  return (
    <div>
      <div className={styles.labelWithButton}>
        <span>{name}</span>
        <ActionButton onClick={() => autofill(`${field}.description`, defaultDescription)} disabled={description === defaultDescription}>
          初期説明に戻す
        </ActionButton>
      </div>
      <TextAreaField name={`${field}.description`} rows={5} />
    </div>
  );
};

const Descriptions = ({ fields }) => fields.map((field, index) => <Description key={index} field={field} />);

const Form =
  (({ handleSubmit, hideModal, submitting }) => (
    <>
      <Modal.Header hideModal={hideModal}>説明文を編集</Modal.Header>
      <Modal.Body>
        <form className={styles.container} onSubmit={handleSubmit}>
          <FieldArray name="descriptions" component={Descriptions} />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Buttons>
          <Button onClick={hideModal}>キャンセル</Button>
          <Button primary onClick={handleSubmit} disabled={submitting}>
            保存
          </Button>
        </Modal.Buttons>
      </Modal.Footer>
    </>
  )) |> reduxForm({ form: 'questionDescriptionEdit' });

export const DescriptionEdit = () => {
  const [isOpen, setIsOpen] = useState(false);
  const descriptions = useQuestionDescriptions();
  const [updateQuestionCustomDescriptions] = useMutation(UPDATE_QUESTION_CUSTOM_DESCRIPTIONS);
  const hideModal = () => setIsOpen(false);
  return (
    <>
      <ActionButton icon={<Edit size={15} />} onClick={() => setIsOpen(true)}>
        説明文の編集
      </ActionButton>
      <Modal.Modal isOpen={isOpen}>
        <Form
          hideModal={hideModal}
          initialValues={{ descriptions }}
          onSubmit={async values => {
            await updateQuestionCustomDescriptions({
              variables: {
                input: {
                  questionId: descriptions[0].questionId,
                  customDescriptions: values.descriptions.map(pick(['field', 'description']))
                }
              }
            });
            hideModal();
          }}
        />
      </Modal.Modal>
    </>
  );
};
