import { FC } from 'react';
import { amountFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { Dl, Dt, Dd, FileDd, FileDl } from '../../../components/DefinitionList';
import { Grid } from '../../../components/Grid';
import { useEmployeeInfo } from '../EmployeeInfoProvider';
import { PreviewSubSection } from '../../../components/PreviewSubSection';
import { dateFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { DeductionClassification, SpecialDeduction } from '../../../query';
import styles from './Preview.scss';

const DEDUCTION_CLASSIFICATIONS = {
  normal: '住 [一般の住宅借入金等特別控除（増改築等を含む）]',
  ceritified: '認 [認定住宅の新築等に係る住宅借入金等特別控除]',
  extension: '増 [特定増改築等住宅借入金等特別控除]',
  earthquake: '震 [震災特例法第13条の2第１項「住宅の再取得等に係る住宅借入金等特別控除」の規定の適用を選択した場合]',
  special_case_house_normal: '住(特家) [一般の住宅借入金等特別控除の場合（増改築等を含む。）で住宅が特例居住用家屋に該当するとき]',
  special_case_house_certified: '認（特家）[認定住宅等の新築等に係る住宅借入金等特別控除の場合で住宅が特例認定住宅等に該当するとき]',
  special_case_house_earthquake: '震（特家）[震災再取得等の適用を選択した場合で住宅が特例居住用家屋に該当するとき]'
};

const SPECIAL_DEDUCTIONS = {
  none: '対象外',
  applied: '特定取得',
  special_applied: '特別特定取得',
  special_exception_applied: '特例特別特例取得'
};

export const Preview: FC = () => {
  const { employee } = useEmployeeInfo();
  const housingLoan = employee.profile.housingLoan;
  const firstDetail = housingLoan?.housingLoanDetails?.find(housingLoan => housingLoan.no === 1);
  const secondDetail = housingLoan?.housingLoanDetails.find(housingLoan => housingLoan.no === 2);

  const deductionClassificationFormat = (deductionClassification?: DeductionClassification) => {
    if (deductionClassification === undefined) return undefined;
    return DEDUCTION_CLASSIFICATIONS[deductionClassification];
  };

  const specialDeductionFormat = (specialDeduction?: SpecialDeduction) => {
    if (specialDeduction === undefined) return undefined;
    return SPECIAL_DEDUCTIONS[specialDeduction];
  };

  if (!housingLoan) {
    return <p>住宅ローン情報は存在しません。</p>;
  }

  return (
    <div className={styles.housingLoans}>
      <Grid>
        <Dl>
          <Dt>住宅借入金等 特別控除適用数</Dt>
          <Dd>{housingLoan?.housingLoanDeductionApplicableCount}</Dd>
        </Dl>

        <Dl>
          <Dt>住宅借入金等特別控除額</Dt>
          <Dd>{amountFormat(housingLoan?.housingLoanDeductionApplicableAmount)}</Dd>
        </Dl>
      </Grid>

      <Grid colGap col2>
        {firstDetail && (
          <PreviewSubSection>
            <PreviewSubSection.Header>1回目</PreviewSubSection.Header>
            <PreviewSubSection.Body>
              <Dl>
                <Dt>居住開始年月日</Dt>
                <Dd>{dateFormat(firstDetail?.residenceStartOn, 'L')}</Dd>
              </Dl>

              <Dl>
                <Dt>住宅借入金等特別控除区分</Dt>
                <Dd>{deductionClassificationFormat(firstDetail?.deductionClassification)}</Dd>
              </Dl>

              <Dl>
                <Dt>住宅借入金等特別控除</Dt>
                <Dd>{specialDeductionFormat(firstDetail?.specialDeduction)}</Dd>
              </Dl>

              <Dl>
                <Dt>住宅借入金等 年末残高</Dt>
                <Dd>{amountFormat(firstDetail?.yearEndBalance)}</Dd>
              </Dl>
            </PreviewSubSection.Body>
          </PreviewSubSection>
        )}

        {secondDetail && (
          <PreviewSubSection>
            <PreviewSubSection.Header>2回目</PreviewSubSection.Header>
            <PreviewSubSection.Body>
              <Dl>
                <Dt>居住開始年月日</Dt>
                <Dd>{dateFormat(secondDetail?.residenceStartOn, 'L')}</Dd>
              </Dl>

              <Dl>
                <Dt>住宅借入金等特別控除区分</Dt>
                <Dd>{deductionClassificationFormat(secondDetail?.deductionClassification)}</Dd>
              </Dl>

              <Dl>
                <Dt>住宅借入金等特別控除</Dt>
                <Dd>{specialDeductionFormat(secondDetail?.specialDeduction)}</Dd>
              </Dl>

              <Dl>
                <Dt>住宅借入金等 年末残高</Dt>
                <Dd>{amountFormat(secondDetail?.yearEndBalance)}</Dd>
              </Dl>
            </PreviewSubSection.Body>
          </PreviewSubSection>
        )}
      </Grid>

      <Grid>
        <FileDl>
          <Dt>住宅借入金等特別控除申告書の画像</Dt>
          <FileDd file={housingLoan?.image} />
        </FileDl>

        <FileDl>
          <Dt>住宅ローンの年末残高証明書の画像 証明書画像</Dt>
          <FileDd file={housingLoan?.certificateImage} />
        </FileDl>
      </Grid>
    </div>
  );
};
