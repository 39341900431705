import React from 'react'
import Icon from 'react-icon-base'

const Custom19 = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="icon/employees/Custom19" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group" transform="translate(18.979759, 21.848427) rotate(40.000000) translate(-18.979759, -21.848427) translate(8.502245, 2.683191)" fill={props.color} fillRule="nonzero">
        <path d="M17.3223858,4.77895242 L17.3223858,29.2081186 L10.4775143,34.2645525 L3.63264286,29.2081186 L3.63264286,4.77895242 L17.3223858,4.77895242 Z M15.3214714,6.77894285 L5.63247143,6.77894285 L5.63247143,28.1989429 L10.4774714,31.7769429 L15.3214714,28.1989429 L15.3214714,6.77894285 Z" id="Rectangle"></path>
        <path d="M19.9550286,4.73875716 C20.5073134,4.73875716 20.9550286,5.18647241 20.9550286,5.73875716 C20.9550286,6.25159299 20.5689884,6.67426432 20.0716497,6.73202942 L19.9550286,6.73875716 L1,6.73875716 C0.44771525,6.73875716 -4.54747351e-13,6.29104191 -4.54747351e-13,5.73875716 C-4.54747351e-13,5.22592132 0.38604019,4.80324999 0.883378875,4.74548489 L1,4.73875716 L19.9550286,4.73875716 Z" id="Line-2"></path>
        <path d="M14.1632143,3.41060513e-13 C14.7154991,3.41060513e-13 15.1632143,0.44771525 15.1632143,1 C15.1632143,1.51283584 14.7771741,1.93550716 14.2798354,1.99327227 L14.1632143,2 L6.7918143,2 C6.23952955,2 5.7918143,1.55228475 5.7918143,1 C5.7918143,0.487164161 6.17785449,0.0644928393 6.67519318,0.00672773133 L6.7918143,3.41060513e-13 L14.1632143,3.41060513e-13 Z" id="Line-2"></path>
        <polygon id="Line-3" points="11.5305715 1.52652857 11.5305715 6.7918143 9.42445717 6.7918143 9.42445717 1.52652857"></polygon>
        <path d="M10.4775143,32.1182429 C10.9903502,32.1182429 11.4130215,32.5042831 11.4707866,33.0016218 L11.4775143,33.1182429 L11.4775143,37.3304715 C11.4775143,37.8827563 11.0297991,38.3304715 10.4775143,38.3304715 C9.96467847,38.3304715 9.54200715,37.9444313 9.48424204,37.4470927 L9.47751431,37.3304715 L9.47751431,33.1182429 C9.47751431,32.5659582 9.92522956,32.1182429 10.4775143,32.1182429 Z" id="Line"></path>
        <path d="M15.7428,22.5876715 C16.3243874,22.5876715 16.7958572,23.0591412 16.7958572,23.6407286 C16.7958572,24.1835435 16.3851547,24.6304337 15.8575422,24.6876066 L15.7428,24.6937858 L10.4775143,24.6937858 C9.89592691,24.6937858 9.42445717,24.222316 9.42445717,23.6407286 C9.42445717,23.0979137 9.8351597,22.6510235 10.3627721,22.5938507 L10.4775143,22.5876715 L15.7428,22.5876715 Z" id="Line-6"></path>
        <path d="M15.7428,17.3223858 C16.3243874,17.3223858 16.7958572,17.7938555 16.7958572,18.3754429 C16.7958572,18.9182578 16.3851547,19.365148 15.8575422,19.4223209 L15.7428,19.4285 L10.4775143,19.4285 C9.89592691,19.4285 9.42445717,18.9570303 9.42445717,18.3754429 C9.42445717,17.832628 9.8351597,17.3857378 10.3627721,17.328565 L10.4775143,17.3223858 L15.7428,17.3223858 Z" id="Line-6-Copy"></path>
        <path d="M15.7428,12.0571 C16.3243874,12.0571 16.7958572,12.5285698 16.7958572,13.1101572 C16.7958572,13.6529721 16.3851547,14.0998623 15.8575422,14.1570351 L15.7428,14.1632143 L10.4775143,14.1632143 C9.89592691,14.1632143 9.42445717,13.6917446 9.42445717,13.1101572 C9.42445717,12.5673423 9.8351597,12.1204521 10.3627721,12.0632792 L10.4775143,12.0571 L15.7428,12.0571 Z" id="Line-6-Copy-2"></path>
      </g>
    </g>
  </Icon>
)
Custom19.defaultProps = {
  size: 30,
  color: "#3498DB"
}

export default Custom19