import { FC, useState } from 'react';
import Button from 'jbc-front/components/Button';

import { Section } from '@jbc-year-end-adj/2024/components/ui/Section';
import { Question } from '@jbc-year-end-adj/2024/features/questions/types';
import { TITLE, CheckFormerJob, DetailInput } from '@jbc-year-end-adj/2024/features/questions/templates/FormerJobs';
import { ButtonContainer } from '@jbc-year-end-adj/2024/features/questions/components/ButtonContainer';

import { EditModal } from '../EditModal';
import { EmptyBody } from '../EmptyBody';
import { filteredDescritpions } from './utils';
import styles from './Questions.scss';

type FormerJobsProps = {
  question: Question;
};

export const FormerJobs: FC<FormerJobsProps> = ({ question }) => {
  const [isShow, setIsShow] = useState(true);
  const descriptions = filteredDescritpions(question, ['top']);

  return (
    <Section>
      <Section.Header className={styles.header}>
        {TITLE}
        <EditModal questionId={String(question.id)} descriptions={descriptions} />
      </Section.Header>
      <Section.Body>
        <CheckFormerJob question={question}>
          <ButtonContainer>
            <Button huge primary={isShow} onClick={() => setIsShow(true)}>
              はい
            </Button>
            <Button huge primary={!isShow} onClick={() => setIsShow(false)}>
              いいえ
            </Button>
          </ButtonContainer>
        </CheckFormerJob>

        {isShow && (
          <DetailInput>
            <EmptyBody />
            <ButtonContainer>
              <Button primary disabled>
                次へ
              </Button>
            </ButtonContainer>
          </DetailInput>
        )}
      </Section.Body>
    </Section>
  );
};
