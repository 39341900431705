import { gql } from '@apollo/client';

export type QueryVariables = {
  input: {
    employeeId: string;
    comment?: string;
  };
};

export const REJECT = gql`
  mutation Reject($input: RejectInput!) {
    reject(input: $input) {
      employee {
        id
      }
    }
  }
`;
