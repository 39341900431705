import { yup } from '@jbc-year-end-adj/common/yup';
import { Premium } from '../../../../query';
import { FileType } from '@jbc-year-end-adj/types';
import { amountFormat } from '@jbc-year-end-adj/common/utils/formatter';

export type Schema = {
  premium?: PremiumSchema;
};

export type PremiumSchema = {
  corporatePension?: string;
  corporatePensionImage?: File | FileType;
  disabilityMutualAid?: string;
  disabilityMutualAidImage?: File | FileType;
  personalPension?: string;
  personalPensionImage?: File | FileType;
  smallAndMediumSizedEnterprises?: string;
  smallAndMediumSizedEnterprisesImage?: File | FileType;
};

export const premiumSchema = yup.object({
  smallAndMediumSizedEnterprises: yup
    .string()
    .positiveAmountFormat()
    .maxAmount(999999999)
    .label('独立行政法人中小企業基盤整備機構の共済契約の掛金'),
  smallAndMediumSizedEnterprisesImage: yup.mixed<File | FileType>(),
  corporatePension: yup
    .string()
    .positiveAmountFormat()
    .maxAmount(999999999)
    .label('確定拠出年金法に規定する企業型年金加入者掛金'),
  corporatePensionImage: yup.mixed<File | FileType>(),
  personalPension: yup
    .string()
    .positiveAmountFormat()
    .maxAmount(999999999)
    .label('確定拠出年金法に規定する個人型年金加入者掛金'),
  personalPensionImage: yup.mixed<File | FileType>(),
  disabilityMutualAid: yup
    .string()
    .positiveAmountFormat()
    .maxAmount(999999999)
    .label('心身障害者扶養共済制度に関する契約の掛金'),
  disabilityMutualAidImage: yup.mixed<File | FileType>()
});

export const schema = yup.object({
  premium: premiumSchema.default(undefined)
});

export const generateDefaultValues = (premium: Premium | undefined) => {
  if (premium) {
    return {
      premium: {
        smallAndMediumSizedEnterprises: amountFormat(premium?.smallAndMediumSizedEnterprises),
        smallAndMediumSizedEnterprisesImage: premium?.smallAndMediumSizedEnterprisesImage,
        corporatePension: amountFormat(premium?.corporatePension),
        corporatePensionImage: premium?.corporatePensionImage,
        personalPension: amountFormat(premium?.personalPension),
        personalPensionImage: premium?.personalPensionImage,
        disabilityMutualAid: amountFormat(premium?.disabilityMutualAid),
        disabilityMutualAidImage: premium?.disabilityMutualAidImage
      }
    };
  } else {
    return { premium: undefined };
  }
};

export const premiumObj = {
  smallAndMediumSizedEnterprises: undefined,
  smallAndMediumSizedEnterprisesImage: undefined,
  corporatePension: undefined,
  corporatePensionImage: undefined,
  personalPension: undefined,
  personalPensionImage: undefined,
  disabilityMutualAid: undefined,
  disabilityMutualAidImage: undefined
};
