import { FC } from 'react';
import { AsyncTask } from './query';

// @ts-ignore
import { Link } from 'react-router-dom';

const failedMessage = (
  <p>
    情報の一括更新でエラーが発生しました。
    <br />
    <Link to="/" className="u-txt-link">
      「依頼一覧」ページ
    </Link>
    より確認してください。
  </p>
);

const messages = {
  in_progress: '情報の一括更新を行っているため一部操作ができません',
  waiting: '情報の一括更新を行っているため一部操作ができません',
  success: '情報の一括更新が完了しました',
  failed: failedMessage,
  partial_success: failedMessage
};

const sendRequestMessage = {
  in_progress: '年末調整の依頼を行っているため一部操作ができません',
  waiting: '年末調整の依頼を行っているため一部操作ができません',
  success: '年末調整の依頼が完了しました',
  failed: failedMessage,
  partial_success: ''
};

const sendWithholdingSlipForEmployeesMessage = {
  in_progress: '源泉徴収票交付の準備を行っているため一部操作ができません',
  waiting: '源泉徴収票交付の準備を行っているため一部操作ができません',
  success: '源泉徴収票交付の準備が完了しました',
  failed: '',
  partial_success: ''
};

const clientYearlyCalcStatutoryTotalReportMessage = {
  in_progress: '法定調書合計表の集計を行っているため一部操作ができません',
  waiting: '法定調書合計表の集計を行っているため一部操作ができません',
  success: '法定調書合計表の集計が完了しました',
  failed: '',
  partial_success: ''
};

const fixDataMessage = {
  in_progress: '年末調整の確定処理を行っているため一部操作ができません',
  waiting: '年末調整の確定処理を行っているため一部操作ができません',
  success: '年末調整の確定処理が完了しました',
  failed: failedMessage,
  partial_success: ''
};

export const convertMessage = (taskType?: AsyncTask['taskType'], status?: AsyncTask['status']) => {
  if (!status) return '';

  switch (taskType) {
    case 'send_request':
      return sendRequestMessage[status];
    case 'create_publish_withholding_slip_request':
    case 'create_deliver_withholding_slip_request':
      return sendWithholdingSlipForEmployeesMessage[status];
    case 'client_yearly_calc_statutory_total_report':
      return clientYearlyCalcStatutoryTotalReportMessage[status];
    case 'fix_data':
      return fixDataMessage[status];
    default:
      return messages[status];
  }
};

type FailedMessageProps = {
  year: number;
  taskType: AsyncTask['taskType'];
};

export const FailedMessage: FC<FailedMessageProps> = ({ year, taskType }) => {
  switch (taskType) {
    case 'bulk_update_difference_apply_methods':
    case 'apply_differences_to_payroll':
      return (
        <p>
          情報の一括更新でエラーが発生しました。
          <br />
          <Link to={`/${year}/result/difference_apply`} className="u-txt-link">
            「過不足額の精算」ページ
          </Link>
          より確認してください。
        </p>
      );
    case 'send_request':
      return (
        <p>
          年末調整依頼でエラーが発生しました。
          <br />
          <Link to="/" className="u-txt-link">
            「依頼一覧」ページ
          </Link>
          より確認してください。
        </p>
      );
    case 'create_publish_withholding_slip_request':
    case 'create_deliver_withholding_slip_request':
      return (
        <p>
          源泉徴収票交付の準備でエラーが発生しました。
          <br />
          <Link to={`/${year}/result/withholding_slip_deliver`} className="u-txt-link">
            「源泉徴収票交付」ページ
          </Link>
          より確認してください。
        </p>
      );
    case 'client_yearly_calc_statutory_total_report':
      return <p>法定調書合計表の集計でエラーが発生しました</p>;
    case 'fix_data':
      return (
        <p>
          年末調整の確定処理でエラーが発生しました。
          <br />
          <Link to={`/${year}/result`} className="u-txt-link">
            「年末調整結果」ページ
          </Link>
          より確認してください。
        </p>
      );
    default:
      return (
        <p>
          情報の一括更新でエラーが発生しました。
          <br />
          <Link to="/" className="u-txt-link">
            「依頼一覧」ページ
          </Link>
          より確認してください。
        </p>
      );
  }
};
