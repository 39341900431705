import React from 'react';
import Button from 'jbc-front/components/Button';
import { Panel, Headding, Item, Lead, Buttons } from '../components/PrimaryPanel';
import { DescriptionEdit } from '../components/DescriptionEdit';
import { CustomDescription } from '../components/QuestionDescription';
import { FormPlaceholder } from '../components/FormPlaceholder';
import { ToggleDivImage } from '../components/ToggleDivImage';
import image from '../../images/img-Premium_Deduction_Mortgage_Loan_Insurance.png';

const Question = () => {
  return (
    <Panel>
      <Headding icon={<DescriptionEdit />}>住宅ローン控除</Headding>
      <Item>
        <Lead>住宅ローン控除情報を記入してください。</Lead>
        <CustomDescription field="top" />
        <ToggleDivImage title={'※下図の書類に関する設問です。'}>
          <img src={image} alt="保険料控除_住宅ローン" />
        </ToggleDivImage>
        <FormPlaceholder />
        <Buttons>
          <Button primary disabled>
            次ヘ
          </Button>
        </Buttons>
      </Item>
    </Panel>
  );
};

export default Question;
