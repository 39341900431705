import { FC, useState } from 'react';
import { SubmitHandler, useForm, useFieldArray, FormProvider, useFormContext } from 'react-hook-form';
import { Edit, Strage, MinusCircle, Delete } from 'jbc-front/components/icons';
import ActionButton from 'jbc-front/components/ActionButton';

import { Section } from '@jbc-year-end-adj/2024/components/ui/Section';
import { Input } from '@jbc-year-end-adj/2024/components/form/Input';
import { useAdminMutation as useMutation } from '@jbc-year-end-adj/2024/hooks/graphql/useMutation';

import { useNotify } from '@jbc-year-end-adj/2024/hooks/useNotify';
import { UPDATE_CLIENT_CITY } from './mutation';
import { schema, Schema } from './schema';
import styles from './Form.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import { ClientCity } from '../query';

type Mode = 'show' | 'edit';

type FormProps = {
  clientCities: ClientCity[];
};

export const Form: FC<FormProps> = ({ clientCities }) => {
  const [mode, setMode] = useState<Mode>('show');
  const notify = useNotify();
  const [updateClientCity, { loading: submitting }] = useMutation(UPDATE_CLIENT_CITY, {
    refetchQueries: ['fetchAllClientCities']
  });

  const defaultValues = {
    clientCities: clientCities.map(clientCity => {
      return { clientCityId: clientCity.id, designationNumber: clientCity.designationNumber };
    })
  };

  const methods = useForm<Schema>({
    resolver: yupResolver(schema),
    defaultValues,
    mode: 'onChange'
  });

  const { control, handleSubmit, reset } = methods;
  const { fields, remove } = useFieldArray<Schema>({
    control,
    name: 'clientCities'
  });

  const onSubmit: SubmitHandler<Schema> = async data => {
    const clientCities = data.clientCities.map(clientCity => {
      return {
        id: clientCity.clientCityId,
        designationNumber: clientCity.designationNumber
      };
    });

    await updateClientCity({
      variables: {
        input: {
          attributes: {
            clientCities: clientCities
          }
        }
      }
    });

    setMode('show');
    notify('保存が完了しました', 'success');
  };

  const handleCancel = () => {
    reset(defaultValues);
    setMode('show');
  };

  const findClientCity = (id: string) => {
    return clientCities.find(clientCity => clientCity.id === id);
  };

  return (
    <Section className={styles.box}>
      <Section.Header>住民税納付先市区町村</Section.Header>
      <Section.Body>
        <div className="u-mb30">「特別徴収税額の決定・変更通知書」に記載されている「指定番号」を入力してください。</div>

        <FormProvider {...methods}>
          {clientCities && clientCities.length > 0 && (
            <>
              {mode === 'show' && (
                <div className={styles.action}>
                  <ActionButton onClick={() => setMode('edit')} icon={<Edit size={16} />}>
                    編集
                  </ActionButton>
                </div>
              )}
              {mode === 'edit' && (
                <div className={styles.action}>
                  <ActionButton disabled={submitting} onClick={handleSubmit(onSubmit)} icon={<Strage size={16} />}>
                    保存
                  </ActionButton>
                  <ActionButton onClick={() => handleCancel()} icon={<MinusCircle size={16} />}>
                    キャンセル
                  </ActionButton>
                </div>
              )}
            </>
          )}

          <div className="l-overflow-scroll">
            <table className={`${styles.table} m-table-list m-table-fixed`}>
              <thead>
                <tr>
                  <th style={{ width: '95px' }}>団体コード</th>
                  <th style={{ width: '170px' }}>市区町村名</th>
                  <th style={{ width: '213px' }}>指定番号</th>
                  <th style={{ width: '60px' }} />
                </tr>
              </thead>
              <tbody>
                {fields.length === 0 && (
                  <tr>
                    <td colSpan={4}>データがありません。</td>
                  </tr>
                )}

                {fields.map((field, index) => {
                  const clientCity = findClientCity(field.clientCityId);
                  if (!clientCity) return null;

                  if (mode === 'edit') {
                    return <EditRow key={field.id} clientCity={clientCity} index={index} onRemove={() => remove(index)} />;
                  }

                  return (
                    <tr key={field.id}>
                      <td>{clientCity.city.code}</td>
                      <td>{`${clientCity.city.prefecture.name} ${clientCity.city.name}`}</td>
                      <td>{clientCity.designationNumber}</td>
                      <td />
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </FormProvider>
      </Section.Body>
    </Section>
  );
};

type EditRowProps = {
  clientCity: ClientCity;
  index: number;
  onRemove: () => void;
};

const EditRow: FC<EditRowProps> = ({ clientCity, index, onRemove }) => {
  const { city } = clientCity;
  const {
    register,
    formState: { errors }
  } = useFormContext<Schema>();
  const disabled = clientCity.totalCount > 0;

  return (
    <tr>
      <td>{city.code}</td>
      <td>
        {city.prefecture.name} {city.name}
      </td>
      <td>
        <div className={styles.designationNumber}>
          <Input
            maxLength={15}
            {...register(`clientCities.${index}.designationNumber`)}
            isError={!!errors.clientCities?.[index]?.designationNumber?.message}
            error={errors.clientCities?.[index]?.designationNumber?.message}
          />
        </div>
      </td>
      <td>
        <ActionButton className={styles.default} onClick={() => onRemove()} disabled={disabled}>
          <div className={styles.noteContainer}>
            {disabled && (
              <div className={styles.note}>
                従業員情報に登録されている
                <br />
                市区町村は削除できません
              </div>
            )}
            <Delete size={20} />
          </div>
        </ActionButton>
      </td>
    </tr>
  );
};
