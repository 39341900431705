import { ComponentProps, forwardRef } from 'react';
import { FormField, FieldBaseProps } from '../FormField';
import { Label } from '../Label';
import { DateField as JbcDateField } from 'jbc-front/components/presenters/form/DateField';
import { Required } from 'jbc-front/components/presenters/form/Label';
import { ErrorMessage } from 'jbc-front/components/presenters/form/ErrorMessage';
import { Note } from 'jbc-front/components/presenters/ui/Note';
import { Hint } from 'jbc-front/components/presenters/ui/Hint';

type DateFieldProps = ComponentProps<typeof JbcDateField> &
  FieldBaseProps & {
    disabled?: boolean;
    placeholder?: string;
  };

export const DateField = forwardRef<HTMLInputElement, DateFieldProps>(
  ({ label, required = false, hint, note, error = undefined, ...dateFieldProps }, ref) => {
    const jbcDateFieldProps: ComponentProps<typeof JbcDateField> = {
      ...dateFieldProps,
      inputProps: {
        ...dateFieldProps.inputProps,
        disabled: dateFieldProps.disabled,
        placeholder: dateFieldProps.placeholder
      }
    };

    return (
      <FormField>
        {label && (
          <FormField.LabelContainer>
            <Label>
              {label}
              {required && <Required />}
              {hint && <Hint>{hint}</Hint>}
            </Label>
          </FormField.LabelContainer>
        )}

        <JbcDateField {...jbcDateFieldProps} ref={ref} />

        {note && (
          <FormField.NoteContainer>
            <Note>{note}</Note>
          </FormField.NoteContainer>
        )}

        {error && (
          <FormField.ErrorContainer>
            <ErrorMessage>{error}</ErrorMessage>
          </FormField.ErrorContainer>
        )}
      </FormField>
    );
  }
);
