import React from 'react'
import Icon from 'react-icon-base'

const Upload = props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <g fill='none' fillRule='evenodd' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.307'>
      <path d='M8 11.604V4.396M10.783 7.178L7.999 4.395 5.217 7.178' />
      <path d='M1.036 8a6.964 6.964 0 1 1 13.928 0A6.964 6.964 0 0 1 1.036 8z' />
    </g>
  </Icon>
)
Upload.defaultProps = {
  size: 16
}

export default Upload
