import { gql } from '@apollo/client';

export type QueryResult = {
  client: {
    id: string;
    clientYearly: {
      id: string;
      asyncTask: AsyncTask;
    };
  };
};

export type AsyncTask = {
  id: string;
  taskType: string;
  status: Status;
  error?: string;
  excutedAt?: string;
  endedAt?: string;
};

type Status = 'waiting' | 'in_progress' | 'success' | 'failed' | 'partial_success';

export const ASYNC_TASK_FRAGMENT = gql`
  fragment AsyncTaskFields on AsyncTask {
    id
    taskType
    status
    error
    excutedAt
    endedAt
  }
`;

export const FETCH_ASYNC_TASK = gql`
  query asyncTask($year: Int!) {
    client {
      id
      clientYearly(year: $year) {
        id
        asyncTask {
          ...AsyncTaskFields
        }
      }
    }
  }
  ${ASYNC_TASK_FRAGMENT}
`;
