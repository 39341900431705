import React from 'react'
import Icon from 'react-icon-base'

const Card = props => (
  <Icon viewBox="0 0 13 13" {...props}>
    <path d='M0 0h6v6H0zM0 7h6v6H0zM7 0h6v6H7zM7 7h6v6H7z' fill='currentColor' fillRule='evenodd' />
  </Icon>
)
Card.defaultProps = {
  size: 13
}

export default Card
