import { addNotification } from 'reapop';
import { useDispatch } from 'react-redux';

export const notify = (message, status = 'success') =>
  addNotification({
    message,
    position: 'tc',
    status,
    dismissAfter: 3000
  });

export const useNotify = () => {
  const dispatch = useDispatch();
  return (...args) => dispatch(notify(...args));
};
