import { FC } from 'react';
import { ResignInYear as ResignInYearTemplate } from 'features/questions/templates/ResignInYear';
import { ButtonContainer } from 'features/questions/components/ButtonContainer';
import Button from 'jbc-front/components/Button';
import { useWizard } from '../WizardProvider';

export const ResignInYear: FC = () => {
  const { question, request } = useWizard();

  return (
    <ResignInYearTemplate question={question}>
      <ButtonContainer>
        <Button huge primary={request.profile?.resignBeforeYearEnd} secondary={!request.profile?.resignBeforeYearEnd} disabled>
          退職予定です
        </Button>
        <Button huge primary={!request.profile?.resignBeforeYearEnd} secondary={request.profile?.resignBeforeYearEnd} disabled>
          退職予定はありません
        </Button>
      </ButtonContainer>
    </ResignInYearTemplate>
  );
};
