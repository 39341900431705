import { FC } from 'react';
import { Dl, Dt, Dd } from '../../../components/DefinitionList';
import { Grid } from '../../../components/Grid';
import { displayFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { useEmployeeInfo } from '../EmployeeInfoProvider';

const RESIDENTS_TAX_COLLECTION_METHODS = {
  from_salary: '特別徴収',
  normally_for_resign: '普通徴収（退職）',
  normally_others: '普通徴収（その他）'
};

export const Preview: FC = () => {
  const { employee } = useEmployeeInfo();

  return (
    <Grid>
      <Dl>
        <Dt>給与支払報告書提出先 市区町村コード</Dt>
        <Dd>{displayFormat(employee.profile.residentTaxCity?.code)}</Dd>
      </Dl>

      <Dl>
        <Dt>給与支払報告書提出先 市区町村</Dt>
        <Dd>{displayFormat(employee.profile.residentTaxCity?.name)}</Dd>
      </Dl>

      <Dl>
        <Dt>住民税徴収方法</Dt>
        <Dd>{RESIDENTS_TAX_COLLECTION_METHODS[employee.residentsTaxCollectionMethod]}</Dd>
      </Dl>
    </Grid>
  );
};
