import React from 'react';
import Sidebar from 'jbc-front/components/Sidebar';
import { Link } from 'react-router-dom';
import { Employee, Task, Setting, Close, Hamburger } from 'jbc-front/components/icons';
import styles from './Sidebar.scss';
import { onClickOutside } from '@jbc-year-end-adj/2024/components/onClickOutside';
import { withState } from 'recompose';
import { year } from '@jbc-year-end-adj/2024/consts';

const YeaSidebar = ({ setIsSidebarOpen, isSidebarOpen, offset }) => {
  return (
    <div>
      <Sidebar offset={offset} position="absolute" isOpen={isSidebarOpen}>
        <Sidebar.Item as={Link} to={`/${year || ''}`} icon={Employee} onClick={() => setIsSidebarOpen(false)}>
          依頼一覧
        </Sidebar.Item>
        <Sidebar.Item as={Link} to={`/${year ? year + '/' : ''}result`} icon={Task} onClick={() => setIsSidebarOpen(false)}>
          結果一覧
        </Sidebar.Item>
        <Sidebar.Item as={Link} to={`/${year ? year + '/' : ''}settings`} icon={Setting} onClick={() => setIsSidebarOpen(false)}>
          設定
        </Sidebar.Item>
      </Sidebar>
      <div
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        role="button"
        onKeyPress={() => setIsSidebarOpen(!isSidebarOpen)}
        tabIndex={0}
        className={styles.menuBtn}
      >
        {isSidebarOpen ? <Close size={20} /> : <Hamburger size={20} />}
      </div>
    </div>
  );
};

export default YeaSidebar
  |> onClickOutside(({ setIsSidebarOpen }) => setIsSidebarOpen(false))
  |> withState('isSidebarOpen', 'setIsSidebarOpen', false);
