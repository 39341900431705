import { gql } from '@apollo/client';
import { FileType, Gender, HandicapType, ResidenceStatus } from '@jbc-year-end-adj/types';
import { Schema, YearlyInfoSchema, IncomeSchema } from './schema';

export type QueryVariables = {
  input: {
    employeeId: string;
    attributes: {
      otherFamilies: OtherFamily[];
    };
  };
};

type OtherFamily = {
  id?: string;
  lastName: string;
  firstName: string;
  lastNameKana: string;
  firstNameKana: string;
  birthday: string;
  gender: Gender;
  relationOther: string;
  thisYear?: YearlyInfo;
  nextYear?: YearlyInfo;
  income?: Income;
  dependentFrom?: string;
  dependentReason?: string;
  diedOn?: string;
};

type YearlyInfo = {
  dependentTaxLaw: boolean;
  incomeAdjustmentDeduction?: boolean;
  residenceStatus?: ResidenceStatus;
  postcode0?: string;
  postcode1?: string;
  prefectureId?: string;
  city?: string;
  street?: string;
  building?: string;
  addressForeign?: string;
  isNonResident?: boolean;
  isStudyAbroad?: boolean;
  remittance?: string;
  relatedToRelativesDocument?: File | FileType | null;
  relatedToRemittanceDocument?: File | FileType | null;
  provingStudyAbroadDocument?: File | FileType | null;
  handicapType?: HandicapType;
  handicapDetail?: string;
  handicapImage?: File | FileType | null;
  earnings?: string;
};

type Income = {
  salaryIncome?: string;
  businessIncome?: string;
  businessExpense?: string;
  miscellaneousIncomeOfficialPension?: string;
  miscellaneousIncomeOther?: string;
  miscellaneousExpense?: string;
  dividendIncome?: string;
  dividendExpense?: string;
  realEstateIncome?: string;
  realEstateExpense?: string;
  retirementIncome?: string;
  lengthOfService?: string;
  retireForDisability?: boolean;
  isOfficerRetirementAllowance?: boolean;
  otherIncome?: string;
  otherExpense?: string;
  otherExpenseSpecialDeduction?: string;
  otherEarnings?: string;
};

export const UPDATE_REQUEST_OTHER_FAMILIES = gql`
  mutation UpdateRequestOtherFamilies($input: UpdateRequestOtherFamiliesInput!) {
    updateRequestOtherFamilies(input: $input) {
      employee {
        id
      }
    }
  }
`;

export const convertFormDataToQueryVariables = (data: Schema, employeeId: string) => {
  const otherFamilies = data.otherFamilies.map(otherFamily => {
    const thisYearDependentTaxLaw = otherFamily.thisYear?.dependentTaxLaw === 'true';
    const incomeAdjustmentDeduction = !thisYearDependentTaxLaw && otherFamily.thisYear?.incomeAdjustmentDeduction === 'true';
    const nextYearDependentTaxLaw = otherFamily.nextYear?.dependentTaxLaw === 'true';
    const dependentTaxLaw = thisYearDependentTaxLaw || nextYearDependentTaxLaw;

    const dateFormat = (date?: string) => {
      return date || undefined;
    };

    const otherFamilyAttributes: OtherFamily = {
      id: otherFamily.id,
      lastName: otherFamily.lastName,
      firstName: otherFamily.firstName,
      lastNameKana: otherFamily.lastNameKana,
      firstNameKana: otherFamily.firstNameKana,
      birthday: otherFamily.birthday,
      gender: otherFamily.gender,
      relationOther: otherFamily.relationOther,
      income: convertIncome(otherFamily.thisYear.yearlyInfo.income)
    };

    if (dependentTaxLaw) {
      otherFamilyAttributes.dependentFrom = dateFormat(otherFamily.dependentFrom);
      otherFamilyAttributes.dependentReason = otherFamily.dependentReason;
      otherFamilyAttributes.diedOn = dateFormat(otherFamily.diedOn);
    }

    if (thisYearDependentTaxLaw || incomeAdjustmentDeduction) {
      const hasHandicap = ['normal', 'special'].includes(otherFamily.thisYear.yearlyInfo.handicapType);

      otherFamilyAttributes.thisYear = {
        dependentTaxLaw: thisYearDependentTaxLaw,
        incomeAdjustmentDeduction,
        residenceStatus: otherFamily.thisYear.yearlyInfo.residenceStatus,
        ...convertYearlyInfoByResidenceStatus(otherFamily.thisYear.yearlyInfo),
        handicapType: otherFamily.thisYear.yearlyInfo.handicapType,
        handicapDetail: hasHandicap ? otherFamily.thisYear.yearlyInfo.handicapDetail : undefined,
        handicapImage: hasHandicap ? otherFamily.thisYear.yearlyInfo.handicapImage : undefined,
        earnings: otherFamily.thisYear.yearlyInfo.earnings || '0'
      };
    } else {
      otherFamilyAttributes.thisYear = {
        dependentTaxLaw: thisYearDependentTaxLaw,
        incomeAdjustmentDeduction
      };
    }

    if (nextYearDependentTaxLaw) {
      const hasHandicap = ['normal', 'special'].includes(otherFamily.nextYear.yearlyInfo.handicapType);

      otherFamilyAttributes.nextYear = {
        dependentTaxLaw: nextYearDependentTaxLaw,
        residenceStatus: otherFamily.nextYear.yearlyInfo.residenceStatus,
        ...convertYearlyInfoByResidenceStatus(otherFamily.nextYear.yearlyInfo),
        handicapType: otherFamily.nextYear.yearlyInfo.handicapType,
        handicapDetail: hasHandicap ? otherFamily.nextYear.yearlyInfo.handicapDetail : undefined,
        handicapImage: hasHandicap ? otherFamily.nextYear.yearlyInfo.handicapImage : undefined,
        earnings: otherFamily.nextYear.yearlyInfo.earnings || '0'
      };
    } else {
      otherFamilyAttributes.nextYear = {
        dependentTaxLaw: nextYearDependentTaxLaw
      };
    }

    return otherFamilyAttributes;
  });

  return {
    employeeId,
    attributes: {
      otherFamilies
    }
  };
};

const convertYearlyInfoByResidenceStatus = (yearlyInfo: YearlyInfoSchema['yearlyInfo']) => {
  switch (yearlyInfo.residenceStatus) {
    case 'same_address':
      return {
        relatedToRelativesDocument: undefined,
        relatedToRemittanceDocument: undefined,
        provingStudyAbroadDocument: undefined
      };
    case 'different_address':
      return {
        postcode0: yearlyInfo.postcode0,
        postcode1: yearlyInfo.postcode1,
        prefectureId: yearlyInfo.prefectureId,
        city: yearlyInfo.city,
        street: yearlyInfo.street,
        building: yearlyInfo.building,
        relatedToRelativesDocument: undefined,
        relatedToRemittanceDocument: undefined,
        provingStudyAbroadDocument: undefined
      };
    case 'different_and_foreign_address':
      return {
        addressForeign: yearlyInfo.addressForeign,
        isNonResident: yearlyInfo.isNonResident,
        remittance: yearlyInfo.remittance || '0',
        isStudyAbroad: yearlyInfo.isStudyAbroad,
        relatedToRelativesDocument: yearlyInfo.relatedToRelativesDocument,
        relatedToRemittanceDocument: yearlyInfo.relatedToRemittanceDocument,
        provingStudyAbroadDocument: yearlyInfo.isStudyAbroad ? yearlyInfo.provingStudyAbroadDocument : undefined
      };
  }
};

const convertIncome = (income?: IncomeSchema) => {
  if (!income) {
    return undefined;
  }

  return {
    salaryIncome: income.salaryIncome || '0',
    businessIncome: income.businessIncome || '0',
    businessExpense: income.businessExpense || '0',
    miscellaneousIncomeOfficialPension: income.miscellaneousIncomeOfficialPension || '0',
    miscellaneousIncomeOther: income.miscellaneousIncomeOther || '0',
    miscellaneousExpense: income.miscellaneousExpense || '0',
    dividendIncome: income.dividendIncome || '0',
    dividendExpense: income.dividendExpense || '0',
    realEstateIncome: income.realEstateIncome || '0',
    realEstateExpense: income.realEstateExpense || '0',
    retirementIncome: income.retirementIncome || '0',
    lengthOfService: income.lengthOfService || '0',
    retireForDisability: income.retireForDisability,
    isOfficerRetirementAllowance: income.isOfficerRetirementAllowance,
    otherIncome: income.otherIncome || '0',
    otherExpense: income.otherExpense || '0',
    otherExpenseSpecialDeduction: income.otherExpenseSpecialDeduction || '0',
    otherEarings: income.otherEarings || '0'
  };
};
