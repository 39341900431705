import React from 'react';
import { gql } from '@apollo/client';
import _ from 'lodash';
import { reduxForm } from '../components/FormName';
import FormErrors, { onSubmitFail } from 'jbc-front/components/FormErrors';
import { useQuery, useMutation, useYear } from '../components';
import { toFormValues } from '../utils/form';
import { parseBackUrl } from '../utils/url';
import Button from 'jbc-front/components/Button';
import LoadingPage from '../components/LoadingPage';
import styles from './WithholdingSlip.scss';
import { TextAreaField } from 'jbc-front/components/Form';
import PaymentDeduction from '../withholding_slip/paymentDeduction';
import { Section } from '../withholding_slip/parts';
import InsuranceDeduction, { INSURANCE_AMOUNT_SUM_FRAGMENT } from '../withholding_slip/insuranceDeduction';
import { amountFormat } from '@jbc-year-end-adj/common/utils/formatter';

const WITHHOLDING_SLIP_FRAGMENT = gql`
  fragment WithholdingSlip on WithholdingSlip {
    payPaymentAmount
    payDeductionAmount
    payIncomeTax
    bonusPaymentAmount
    bonusDeductionAmount
    bonusIncomeTax
    formerJobsPaymentAmount
    formerJobsDeductionAmount
    formerJobsTaxAmount
    adjustmentPaymentAmount
    adjustmentDeductionAmount
    adjustmentIncomeTax
    smallMutualAidPremium
    remark
    summary
  }
`;

const WITHHOLDING_SLIP = gql`
  query withholdingSlip($id: ID!, $year: Int!) {
    client {
      id
      clientYearly(year: $year) {
        id
        employee(id: $id) {
          id
          status
          insuranceAmountAutoSum {
            ...InsuranceAmountSum
          }
          insuranceAmountFinalSum {
            ...InsuranceAmountSum
          }
          withholdingSlip {
            id
            ...WithholdingSlip
          }
          profile {
            id
            formerJobsTaxAmount
            formerJobsPaymentAmount
            formerJobsDeductionAmount
            formerJobs {
              id
            }
          }
        }
      }
    }
  }
  ${WITHHOLDING_SLIP_FRAGMENT}
  ${INSURANCE_AMOUNT_SUM_FRAGMENT}
`;

const SAVE_WITHHOLDING_SLIP = gql`
  mutation saveWithholdingSlip($input: SaveWithholdingSlipInput!) {
    saveWithholdingSlip(input: $input) {
      employee {
        id
        insuranceAmountFinalSum {
          ...InsuranceAmountSum
        }
        withholdingSlip {
          id
          ...WithholdingSlip
        }
      }
    }
  }
  ${WITHHOLDING_SLIP_FRAGMENT}
  ${INSURANCE_AMOUNT_SUM_FRAGMENT}
`;

const initialValues = profile => {
  return {
    payPaymentAmount: '0',
    payDeductionAmount: '0',
    payIncomeTax: '0',
    bonusPaymentAmount: '0',
    bonusDeductionAmount: '0',
    bonusIncomeTax: '0',
    smallMutualAidPremium: '0',
    formerJobsPaymentAmount: profile.formerJobsPaymentAmount,
    formerJobsDeductionAmount: profile.formerJobsDeductionAmount,
    formerJobsTaxAmount: profile.formerJobsTaxAmount,
    adjustmentPaymentAmount: '0',
    adjustmentDeductionAmount: '0',
    adjustmentIncomeTax: '0'
  };
};

const makeInitialValues = (withholdingSlip, employee, profile) => {
  const { remark, summary, ...rest } = withholdingSlip || {};
  const { insuranceAmountFinalSum, insuranceAmountAutoSum } = employee;
  const insuranceAmountSum = _.mapValues(toFormValues(insuranceAmountFinalSum, true), amountFormat);
  const { housingLoanDeductionApplicableAmount } = insuranceAmountSum;
  const variables = Object.keys(rest).length === 0 ? initialValues(profile) : rest;
  return {
    paymentDeduction: _.mapValues(toFormValues(variables, true), amountFormat),
    insuranceDeduction: _.omit(insuranceAmountSum, 'housingLoanDeductionApplicableAmount'),
    housingLoanDeduction: { housingLoanDeductionApplicableAmount },
    remarks: { remark, summary },
    edited: _.fromPairs(_.map(insuranceAmountFinalSum, (v, k) => [k, insuranceAmountAutoSum[k] !== v])),
    totalAmount: {
      deductionAmount: '0',
      generalLifeInsurance: '0',
      nursingMedicalInsurance: '0',
      individualAnnuityInsurance: '0'
    }
  };
};

const Form =
  (({ submitting, handleSubmit, employee }) => {
    return (
      <div className={styles.container}>
        <form onSubmit={handleSubmit}>
          <div className="l-title-wrap">
            <h1 className="m-title-main">源泉徴収票</h1>
          </div>
          <div className={styles.formErrorsWrap}>
            <FormErrors />
          </div>
          <div className={styles.sectionWrap}>
            <PaymentDeduction />
            {employee.status !== 'not_need_adj' && <InsuranceDeduction />}
            <Section formName="remarks" title="源泉徴収票の摘要欄・備考欄" padding>
              <TextAreaField name="summary" label="摘要" />
              <TextAreaField name="remark" label="備考" />
            </Section>
          </div>
          <div className="u-ta-c u-mt30">
            <Button onClick={handleSubmit} primary disabled={submitting}>
              保存
            </Button>
          </div>
        </form>
      </div>
    );
  }) |> reduxForm({ form: 'withholdingSlip', onSubmitFail });

const WithholdingSlip = ({
  match: {
    params: { id }
  },
  history,
  location
}) => {
  const year = useYear();
  const { data, loading } = useQuery(WITHHOLDING_SLIP, {
    variables: { id, year },
    fetchPolicy: 'network-only'
  });
  const [saveWithholdingSlip] = useMutation(SAVE_WITHHOLDING_SLIP);
  if (loading) return <LoadingPage />;
  const {
    client: {
      clientYearly: {
        employee,
        employee: { withholdingSlip, profile }
      }
    }
  } = data;
  return (
    <Form
      initialValues={makeInitialValues(withholdingSlip, employee, profile)}
      onSubmit={async values => {
        const input = _.omit(values, ['edited', 'totalAmount']);
        await saveWithholdingSlip({ variables: { input: { employeeId: id, ...input } } });
        history.push(parseBackUrl(location, '/result'));
      }}
      employee={employee}
    />
  );
};

export default WithholdingSlip;
