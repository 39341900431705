import React from 'react';
import Button from 'jbc-front/components/Button';
import { Modal } from '../components';
import styles from '../pages/Result.scss';

export const FixDataModal = ({ showModal, hideModal, onSubmit, isExcessive, disabled, ...taskRunningProps }) => (
  <>
    <Button primary onClick={showModal} disabled={disabled} {...taskRunningProps}>
      年末調整を確定する
    </Button>
    <Modal.Modal>
      <Modal.Header>確定処理</Modal.Header>
      <Modal.Body>{isExcessive ? <FixDataAsyncModalBody /> : <FixDataModalBody />}</Modal.Body>
      <Modal.Footer>
        <Modal.Buttons>
          <Button primary as="button" onClick={onSubmit} disabled={disabled} {...taskRunningProps}>
            はい
          </Button>
          <Button onClick={hideModal}>いいえ</Button>
        </Modal.Buttons>
      </Modal.Footer>
    </Modal.Modal>
  </>
);

const FixDataModalCommonBody = () => (
  <>
    確定処理中は情報の閲覧・修正ができなくなります。
    <br />
    また、確定処理後は情報の修正ができなくなります。
    <br />
    確定処理を行いますか？
  </>
);

const FixDataModalBody = () => (
  <>
    <p className={styles.fixModalLabel}>年末調整の確定処理を行います。</p>
    <br />
    <FixDataModalCommonBody />
  </>
);

const FixDataAsyncModalBody = () => (
  <>
    <p className={styles.fixModalLabel}>
      年末調整の確定処理を行います。
      <br />
      処理が完了しましたらメールにてご連絡します。
    </p>
    <br />
    <FixDataModalCommonBody />
  </>
);
