import { yup } from '@jbc-year-end-adj/common/yup';
import { dateFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { Employee } from '../../../../query';

type IsDisasterSufferer = 'disasterSufferer' | 'notDisasterSufferer';
type isNonResident = 'resident' | 'nonResident';

export type Schema = {
  joinedOn: string;
  resignedOn: string;
  lastName: string;
  firstName: string;
  lastNameKana: string;
  firstNameKana: string;
  birthday: string;
  gender: 'male' | 'female';
  employmentType?: string;
  position?: string;
  isNonResident: isNonResident;
  postcode0?: string;
  postcode1?: string;
  prefectureId?: string;
  city?: string;
  street?: string;
  building?: string;
  householderRelationship: string;
  householderName: string;
  nationalType: 'japanese' | 'foreigner';
  isDisasterSufferer: IsDisasterSufferer;
  taxPostponementAmount: string;
};

const householderNameConditions = {
  is: (householderRelationship: string) => householderRelationship === '本人',
  then: () => yup.string().nullable(),
  otherwise: () => yup.string().label('世帯主の名前')
};

const taxPostponementAmountConditions = {
  is: (isDisasterSuffer: string) => isDisasterSuffer === 'disasterSufferer',
  then: () =>
    yup
      .string()
      .required()
      .label('徴収猶予税額'),
  otherwise: () => yup.string().nullable()
};

const addressConditions = (label: string) => {
  return {
    is: (isNonResident: isNonResident) => isNonResident === 'resident',
    then: () =>
      yup
        .string()
        .required()
        .label(label),
    otherwise: () => yup.string().nullable()
  };
};

export const schema = yup.object({
  joinedOn: yup
    .string()
    .required()
    .dateFormat()
    .label('入社日'),
  resignedOn: yup
    .string()
    .dateFormat()
    .nullable()
    .label('退職日'),
  lastName: yup
    .string()
    .required()
    .label('姓'),
  firstName: yup
    .string()
    .required()
    .label('名'),
  lastNameKana: yup
    .string()
    .required()
    .kana()
    .label('姓（カナ）'),
  firstNameKana: yup
    .string()
    .required()
    .kana()
    .label('名（カナ）'),
  birthday: yup
    .string()
    .required()
    .dateFormat()
    .label('生年月日'),
  gender: yup
    .mixed()
    .oneOf(['male', 'female'])
    .required()
    .label('性別'),
  employmentType: yup
    .string()
    .nullable()
    .label('雇用形態'),
  position: yup
    .string()
    .nullable()
    .label('役職'),
  isNonResident: yup
    .mixed()
    .oneOf(['resident', 'nonResident'])
    .required()
    .label('非居住者'),
  postcode0: yup.string().when('isNonResident', {
    is: (isNonResident: isNonResident) => isNonResident === 'resident',
    then: () =>
      yup
        .string()
        .numberFormat()
        .max(3, '郵便番号1は3桁で入力してください')
        .required()
        .label('郵便番号1'),
    otherwise: () => yup.string().nullable()
  }),
  postcode1: yup.string().when('isNonResident', {
    is: (isNonResident: isNonResident) => isNonResident === 'resident',
    then: () =>
      yup
        .string()
        .numberFormat()
        .max(4, '郵便番号2は4桁で入力してください')
        .required()
        .label('郵便番号2'),
    otherwise: () => yup.string().nullable()
  }),
  prefectureId: yup.string().when('isNonResident', addressConditions('都道府県')),
  city: yup.string().when('isNonResident', addressConditions('市区町村')),
  street: yup.string().label('丁目番地号'),
  building: yup.string().label('建物名'),
  householderRelationship: yup.string().label('世帯主の続柄'),
  householderName: yup.string().when('householderRelationship', householderNameConditions),
  nationalType: yup
    .mixed()
    .oneOf(['japanese', 'foreigner'])
    .required()
    .label('外国人区分'),
  isDisasterSufferer: yup
    .mixed()
    .oneOf(['disasterSufferer', 'notDisasterSufferer'])
    .required()
    .label('（源泉徴収票）災害者'),
  taxPostponementAmount: yup.string().when('isDisasterSufferer', taxPostponementAmountConditions)
});

export const generateDefaultValues = (employee: Employee | undefined): Schema => {
  const profile = employee?.profile;
  const isDisasterSuffer: IsDisasterSufferer = profile?.isDisasterSufferer ? 'disasterSufferer' : 'notDisasterSufferer';

  return {
    joinedOn: employee?.joinedOn ? dateFormat(employee?.joinedOn, 'L') : '',
    resignedOn: employee?.resignedOn ? dateFormat(employee?.resignedOn, 'L') : '',
    lastName: profile?.lastName ?? '',
    firstName: profile?.firstName ?? '',
    lastNameKana: profile?.lastNameKana ?? '',
    firstNameKana: profile?.firstNameKana ?? '',
    birthday: profile?.birthday ? dateFormat(profile?.birthday, 'L') : '',
    gender: profile?.gender ?? 'male',
    employmentType: employee?.employmentType ?? '',
    position: employee?.position ?? '',
    isNonResident: employee?.isNonResident ? 'nonResident' : 'resident',
    postcode0: profile?.postcode0 ?? '',
    postcode1: profile?.postcode1 ?? '',
    prefectureId: profile?.prefectureId ?? '',
    city: profile?.city ?? '',
    street: profile?.street ?? '',
    building: profile?.building || '',
    householderRelationship: profile?.householder?.relationship ?? '',
    householderName: profile?.householder?.name ?? '',
    nationalType: profile?.nationalType ?? 'japanese',
    isDisasterSufferer: isDisasterSuffer,
    taxPostponementAmount: profile?.taxPostponementAmount ?? ''
  };
};
