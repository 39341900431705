import React, { useState, useEffect, useRef } from 'react';
import styles from './Memo.scss';
import compose from 'lodash/fp/compose';
import { reduxForm } from './FormName';
import Modal, { withModal } from 'jbc-front/components/CommonModal';
import { TextAreaField } from 'jbc-front/components/Form';
import Button from 'jbc-front/components/Button';
import { useYear } from './useYear';
import { useQuery, useMutation } from './Graphql';
import { gql } from '@apollo/client';
import { toFormValues } from '../utils/form';
import { EditSquare, DeleteSquare, Close } from 'jbc-front/components/icons';
import moment from 'moment';
import _ from 'lodash';
import onClickOutside from './onClickOutside';
import { useNotify } from '../actions';
import LoadingPage from '../components/LoadingPage';
import { DateField, RadioField } from '../components/FieldWithDiff';
import ActionButton from 'jbc-front/components/ActionButton';
import { MEMOS_IN_ALERT_FRAGMENT } from './MemoAlertList';
import { Postit, PostitOn } from '../../jbc-front/components/icons';
import classnames from 'classnames';
import { Rnd } from 'react-rnd';
import { useMediaQuery } from '../hooks/useMediaQuery';

export const MEMO_FRAGMENT = gql`
  fragment Memo on Memo {
    id
    body
    alertAt
    color
    updatedAt
    lastUpdatedUser {
      id
      email
      name
    }
    createdUser {
      id
      name
    }
  }
`;

const CREATE_MEMO = gql`
  mutation createMemo($input: CreateMemoInput!, $year: Int!) {
    createMemo(input: $input) {
      employee {
        id
        memos {
          ...Memo
        }
      }
      client {
        id
        clientYearly(year: $year) {
          id
          memosInAlert {
            ...MemosInAlert
          }
        }
      }
    }
  }
  ${MEMO_FRAGMENT}
  ${MEMOS_IN_ALERT_FRAGMENT}
`;

const UPDATE_MEMO = gql`
  mutation updateMemo($input: UpdateMemoInput!, $year: Int!) {
    updateMemo(input: $input) {
      memo {
        id
        body
        alertAt
        color
        lastUpdatedUser {
          id
          name
        }
        createdUser {
          id
          name
        }
      }
      client {
        id
        clientYearly(year: $year) {
          id
          memosInAlert {
            ...MemosInAlert
          }
        }
      }
    }
  }
  ${MEMOS_IN_ALERT_FRAGMENT}
`;

const DELETE_MEMO = gql`
  mutation deleteMemo($input: DeleteMemoInput!, $year: Int!) {
    deleteMemo(input: $input) {
      employee {
        id
        memos {
          id
        }
      }
      client {
        id
        clientYearly(year: $year) {
          id
          memosInAlert {
            ...MemosInAlert
          }
        }
      }
    }
  }
  ${MEMOS_IN_ALERT_FRAGMENT}
`;

const MEMOS = gql`
  query memos($employeeId: ID!, $year: Int!) {
    client {
      id
      clientYearly(year: $year) {
        id
        employee(id: $employeeId) {
          id
          memos {
            ...Memo
          }
        }
      }
    }
  }
  ${MEMO_FRAGMENT}
`;

const ColorLabel = ({ color }) => <span className={styles[`memo_${color}`]}>●</span>;

export const MEMO_COLOR_OPTIONS = [
  { label: <ColorLabel color={'blue'} />, value: 'blue' },
  { label: <ColorLabel color={'yellow'} />, value: 'yellow' },
  { label: <ColorLabel color={'pink'} />, value: 'pink' },
  { label: <ColorLabel color={'green'} />, value: 'green' },
  { label: <ColorLabel color={'purple'} />, value: 'purple' },
  { label: <ColorLabel color={'orange'} />, value: 'orange' }
];

const initialState = { isOpen: false, currentMemo: null };

const setInitialValues = memo => (memo ? _.pick(toFormValues(memo), ['id', 'body', 'alertAt', 'color']) : { color: 'blue' });

export const MemoEdit = compose(
  Child => props => {
    const year = useYear();
    const [createMemo] = useMutation(CREATE_MEMO);
    const [updateMemo] = useMutation(UPDATE_MEMO);
    const notify = useNotify();
    return (
      <Child
        {...props}
        initialValues={setInitialValues(props.memo)}
        onSubmit={async values => {
          const { memo, employeeId, hideModal } = props;
          if (memo) {
            await updateMemo({ variables: { input: values, year } });
          } else {
            await createMemo({ variables: { input: { employeeId, ...values }, year } });
          }
          hideModal();
        }}
      />
    );
  },
  reduxForm({ form: 'memoEdit' })
)(({ handleSubmit, hideModal, memo, submitting }) => {
  return (
    <Modal isOpen portalClassName="ReactModalPortal">
      <Modal.Header hideModal={hideModal}>{memo ? 'メモを更新' : 'メモを新規作成'}</Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <RadioField name="color" options={MEMO_COLOR_OPTIONS} label="メモカラー" />
          <TextAreaField name="body" />
          <div className={styles.dateWrap}>
            <DateField name="alertAt" label="アラート設定" />
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Buttons>
          <Button onClick={hideModal}>キャンセル</Button>
          <Button primary onClick={handleSubmit} disabled={submitting}>
            保存する
          </Button>
        </Modal.Buttons>
      </Modal.Footer>
    </Modal>
  );
});

const DeleteModal = withModal(({ isModalOpen, hideModal, showModal, children, memo }) => {
  const [deleteMemo, { loading }] = useMutation(DELETE_MEMO);
  const year = useYear();

  return (
    <>
      {children({ showModal })}
      {isModalOpen && (
        <Modal isOpen portalClassName="ReactModalPortal ignore-react-onclickoutside">
          <Modal.Header hideModal={hideModal}>削除の確認</Modal.Header>
          <Modal.Body>メモを削除しますか</Modal.Body>
          <Modal.Footer>
            <Modal.Buttons>
              <Button onClick={hideModal}>いいえ</Button>
              <Button
                primary
                onClick={async () => {
                  await deleteMemo({ variables: { input: { id: memo.id }, year } });
                  hideModal();
                }}
                disabled={loading}
              >
                はい
              </Button>
            </Modal.Buttons>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
});

const MemoList = ({ edit, memos }) => (
  <div className={styles.list}>
    {memos.map(memo => (
      <div key={memo.id} className={classnames(styles.row, styles[`memo_border_BG_default_${memo.color}`])}>
        <div className={styles.memoHead}>
          <div className={styles.user}>{memo.createdUser?.name}</div>
          <div className={styles.action}>
            <div className={styles.tools}>
              <div role="button" className={styles.tool} onClick={edit(memo)} onKeyPress={edit(memo)} tabIndex={0}>
                <EditSquare />
              </div>
              <DeleteModal memo={memo}>
                {({ showModal }) => (
                  <div role="button" onClick={showModal} onKeyPress={showModal} className={styles.tool} tabIndex={0}>
                    <DeleteSquare />
                  </div>
                )}
              </DeleteModal>
            </div>
          </div>
        </div>
        <div className={styles.memoBody}>{memo.body}</div>
        <div className={styles.memoFooter}>
          <div className={styles.info}>アラート予定日: {memo.alertAt ? moment(memo.alertAt).format('YYYY/MM/DD') : '未設定'}</div>
          <div className={styles.info}>最終更新日時: {moment(memo.updatedAt).format('YYYY/MM/DD HH:mm')}</div>
          <div className={styles.info}>最終更新者: {memo.lastUpdatedUser ? memo.lastUpdatedUser.name : 'ー'}</div>
        </div>
      </div>
    ))}
  </div>
);

const MemoDetailContent = ({ employee, setIsOpen, setMemoEdit, className }) => {
  const [minHeight, setMinHeight] = useState(230);

  const contentRef = useRef(null);
  const rndRef = useRef(null);

  const edit = memo => () => {
    setMemoEdit({ currentMemo: memo, isOpen: true });
  };

  const handleResize = () => {
    const contentElement = contentRef.current;
    if (contentElement) {
      const computedStyle = window.getComputedStyle(contentElement);
      const padding = parseInt(computedStyle.paddingTop) + parseInt(computedStyle.paddingBottom);
      const newHeight = contentElement.scrollHeight + padding;
      setMinHeight(newHeight);
    }
  };

  const year = useYear();
  const { loading, data } = useQuery(MEMOS, { variables: { employeeId: employee.id, year } });
  const memos = _.get(data, 'client.clientYearly.employee.memos', []);
  const oneMemos = memos.length < 2;
  const mq = useMediaQuery();
  const maxWidth = mq.isMobile ? 350 : 1220;

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!loading && memos.length > 0 && rndRef.current) {
      let widthSet = '620px';
      if (memos.length === 1) {
        widthSet = '340px';
      }
      rndRef.current.updateSize({ width: widthSet });
    }
  }, [loading, memos]);

  const memoDetailContentClassNames = classnames(
    styles.memoRndContent,
    {
      [styles.oneMemos]: oneMemos,
      [styles.draggable]: true
    },
    className
  );

  const commonContent = (
    <div className={mq.isMobile ? styles.memoContentWrap : null}>
      <div className={styles.title}>
        <span>メモ</span>
        <Close className="u-cur-pointer cancel" onClick={() => setIsOpen(false)} />
      </div>
      <div ref={contentRef} className={classnames(styles.content, 'cancel')}>
        {loading ? (
          <LoadingPage />
        ) : (
          memos.length > 0 && (
            <>
              <MemoList edit={edit} memos={memos} />
              <div className={`l-flex ${styles.footer}`}>
                <ActionButton onClick={() => setMemoEdit({ isOpen: true, currentMemo: null })}>メモを追加</ActionButton>
              </div>
            </>
          )
        )}
      </div>
    </div>
  );

  return mq.isMobile ? (
    commonContent
  ) : (
    <Rnd
      ref={rndRef}
      default={{
        x: 40,
        y: 30,
        width: '340px',
        height: 'auto'
      }}
      className={memoDetailContentClassNames}
      minWidth={340}
      maxWidth={maxWidth}
      minHeight={minHeight}
      onResize={handleResize}
      bounds="#root"
      cancel=".cancel"
    >
      {commonContent}
    </Rnd>
  );
};

const MemoDetail = onClickOutside()(MemoDetailContent);

export const Memo = ({ employee, size, className, onClickOutside }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [memoEdit, setMemoEdit] = useState(initialState);
  const postit =
    employee.memos.length > 0 ? (
      <PostitOn className={styles[`memo_${employee.memos.slice(-1)[0].color}`]} size={size} />
    ) : (
      <Postit size={size} />
    );
  const open = e => {
    e.stopPropagation();
    if (employee.memos.length > 0) {
      setIsOpen(!isOpen);
    } else {
      setIsOpen(true);
      setMemoEdit({ isOpen: true, currentMemo: null, employeeId: employee.id });
    }
  };

  const handleClickOutside = () => {
    if (onClickOutside === false) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  return (
    <div className={styles.comments}>
      <div role="button" className={isOpen ? 'ignore-react-onclickoutside' : ''} onClick={open} onKeyPress={open} tabIndex={0}>
        <span className={styles.postit}>{postit}</span>
      </div>
      {isOpen &&
        employee.memos.length > 0 &&
        (memoEdit.isOpen ? (
          <MemoDetailContent {...{ employee, setIsOpen, setMemoEdit, className }} />
        ) : (
          <MemoDetail {...{ employee, setIsOpen, setMemoEdit, className, handleClickOutside }} />
        ))}
      {memoEdit.isOpen && (
        <MemoEdit
          employeeId={employee.id}
          memo={memoEdit.currentMemo}
          hideModal={() => {
            setMemoEdit(initialState);
          }}
        />
      )}
    </div>
  );
};

export default Memo;
