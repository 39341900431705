import React from 'react';
import Button from 'jbc-front/components/Button';
import { Panel, Headding, Item, Lead, Notes, Buttons } from '../components/PrimaryPanel';
import { DescriptionEdit } from '../components/DescriptionEdit';
import { CustomDescription } from '../components/QuestionDescription';

const Question = () => {
  return (
    <Panel>
      <Headding icon={<DescriptionEdit />}>年末調整開始</Headding>
      <Item>
        <Lead>年末調整を始める前に</Lead>
        <CustomDescription field="top" />
        <Buttons>
          <Button huge secondary disabled>
            年末調整を始める
          </Button>
        </Buttons>
      </Item>
    </Panel>
  );
};

export default Question;
