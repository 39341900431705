import { FC } from 'react';
import { Grid } from '../../../components/Grid';
import { useResult } from '../ResultProvider';
import { Diff, FileDiff } from '../../../components/Diff';
import { PreviewSubSection } from '../../../components/PreviewSubSection';
import { Dl, Dt, Dd, FileDd, FileDl } from '../../../components/DefinitionList';
import { Premium } from '../../../query';
import { amountFormat } from '@jbc-year-end-adj/common/utils/formatter';

type AddOrDeletePreviewProps = {
  premium: Premium;
};

const AddOrDeletePreview: FC<AddOrDeletePreviewProps> = ({ premium }) => {
  return (
    <Grid colGap col2>
      <PreviewSubSection>
        <PreviewSubSection.Header>独立行政法人中小企業基盤整備機構の共済契約</PreviewSubSection.Header>
        <PreviewSubSection.Body>
          <Dl>
            <Dt>掛金</Dt>
            <Dd>{amountFormat(premium.smallAndMediumSizedEnterprises)}</Dd>
          </Dl>

          <FileDl>
            <Dt>証明書画像</Dt>
            <FileDd file={premium.smallAndMediumSizedEnterprisesImage} />
          </FileDl>
        </PreviewSubSection.Body>
      </PreviewSubSection>

      <PreviewSubSection>
        <PreviewSubSection.Header>確定拠出年金法に規定する企業型年金加入者</PreviewSubSection.Header>
        <PreviewSubSection.Body>
          <Dl>
            <Dt>掛金</Dt>
            <Dd>{amountFormat(premium.corporatePension)}</Dd>
          </Dl>

          <FileDl>
            <Dt>証明書画像</Dt>
            <FileDd file={premium.corporatePensionImage} />
          </FileDl>
        </PreviewSubSection.Body>
      </PreviewSubSection>

      <PreviewSubSection>
        <PreviewSubSection.Header>確定拠出年金法に規定する個人型年金加入者</PreviewSubSection.Header>
        <PreviewSubSection.Body>
          <Dl>
            <Dt>掛金</Dt>
            <Dd>{amountFormat(premium.personalPension)}</Dd>
          </Dl>

          <FileDl>
            <Dt>証明書画像</Dt>
            <FileDd file={premium.personalPensionImage} />
          </FileDl>
        </PreviewSubSection.Body>
      </PreviewSubSection>

      <PreviewSubSection>
        <PreviewSubSection.Header>心身障害者扶養共済制度に関する契約</PreviewSubSection.Header>
        <PreviewSubSection.Body>
          <Dl>
            <Dt>掛金</Dt>
            <Dd>{amountFormat(premium.disabilityMutualAid)}</Dd>
          </Dl>

          <FileDl>
            <Dt>証明書画像</Dt>
            <FileDd file={premium.disabilityMutualAidImage} />
          </FileDl>
        </PreviewSubSection.Body>
      </PreviewSubSection>
    </Grid>
  );
};

export const Preview: FC = () => {
  const { employee } = useResult();
  const premium = employee.profile.premium;
  const requestPremium = employee.request?.profile?.premium;

  if (!!premium && !requestPremium) {
    return <AddOrDeletePreview premium={premium} />;
  }

  if (!premium && !!requestPremium) {
    return <AddOrDeletePreview premium={requestPremium} />;
  }

  return (
    <Grid colGap col2>
      <PreviewSubSection>
        <PreviewSubSection.Header>独立行政法人中小企業基盤整備機構の共済契約</PreviewSubSection.Header>
        <PreviewSubSection.Body>
          <Diff
            title="掛金"
            source={amountFormat(premium?.smallAndMediumSizedEnterprises)}
            input={amountFormat(requestPremium?.smallAndMediumSizedEnterprises)}
          />
          <FileDiff
            title="証明書画像"
            source={premium?.smallAndMediumSizedEnterprisesImage}
            input={requestPremium?.smallAndMediumSizedEnterprisesImage}
          />
        </PreviewSubSection.Body>
      </PreviewSubSection>

      <PreviewSubSection>
        <PreviewSubSection.Header>確定拠出年金法に規定する企業型年金加入者</PreviewSubSection.Header>
        <PreviewSubSection.Body>
          <Diff title="掛金" source={amountFormat(premium?.corporatePension)} input={amountFormat(requestPremium?.corporatePension)} />
          <FileDiff title="証明書画像" source={premium?.corporatePensionImage} input={requestPremium?.corporatePensionImage} />
        </PreviewSubSection.Body>
      </PreviewSubSection>

      <PreviewSubSection>
        <PreviewSubSection.Header>確定拠出年金法に規定する個人型年金加入者</PreviewSubSection.Header>
        <PreviewSubSection.Body>
          <Diff title="掛金" source={amountFormat(premium?.personalPension)} input={amountFormat(requestPremium?.personalPension)} />
          <FileDiff title="証明書画像" source={premium?.personalPensionImage} input={requestPremium?.personalPensionImage} />
        </PreviewSubSection.Body>
      </PreviewSubSection>

      <PreviewSubSection>
        <PreviewSubSection.Header>心身障害者扶養共済制度に関する契約</PreviewSubSection.Header>
        <PreviewSubSection.Body>
          <Diff
            title="掛金"
            source={amountFormat(premium?.disabilityMutualAid)}
            input={amountFormat(requestPremium?.disabilityMutualAid)}
          />
          <FileDiff title="証明書画像" source={premium?.disabilityMutualAidImage} input={requestPremium?.disabilityMutualAidImage} />
        </PreviewSubSection.Body>
      </PreviewSubSection>
    </Grid>
  );
};
