import React from 'react'
import Icon from 'react-icon-base'

const MapMarker = props => (
  <Icon viewBox="0 0 15 18" {...props}>
    <g fill='none' fillRule='evenodd' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.5'>
      <path d='M14 7.5c.028 4.692-6.5 9.5-6.5 9.5S1.034 12.527 1 7.5C.976 3.91 3.91 1 7.5 1s6.479 2.91 6.5 6.5z' />
      <path d='M9.667 6.928C9.667 7.993 8.697 9 7.5 9 6.304 9 5.333 7.993 5.333 6.928 5.333 5.863 6.303 5 7.5 5c1.196 0 2.167.863 2.167 1.928z' />
    </g>
  </Icon>
)
MapMarker.defaultProps = {
  size: 18
}

export default MapMarker
