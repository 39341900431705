import { isApolloError, ServerError } from '@apollo/client';

const isServerError = (error: Error): error is ServerError => error.name === 'ServerError';

export const isIgnorableNetworkError = (err: Error) =>
  isApolloError(err) && err.networkError && isServerError(err.networkError) && err.networkError.statusCode === 401;

/**
 * Rollbarに送信済みのエラー
 */
const sentToRollbarObjects = new WeakSet();

/**
 * 指定したオブジェクトをRollbarに送信済みのエラーとしてマークする。
 * 手動でRollbarに送信したエラーを再スローする前にこのメソッドを呼び出すことで、Rollbarのイベントを節約することができる。
 */
export const markAsSentToRollbar = (err: Error) => {
  sentToRollbarObjects.add(err);
};

/**
 * 指定したオブジェクトがRollbarに送信済みのエラーとしてマークされているかを判定する。結果が真の場合、Rollbarへの送信をスキップすべき。
 */
export const isSentToRollbar = (err: Error) => sentToRollbarObjects.has(err);
