import React from 'react';
import { gql } from '@apollo/client';
import { Section } from 'jbc-front/components/Form';
import { LabelMapper } from 'jbc-front/components/FormErrors';
import { FormSection } from '../components/FieldWithDiff';
import { Income, INCOME_FRAGMENT } from '../questions/Spouse';
import { useFormValue, useYear } from '../components';
import { toFormValues } from '../utils';
import { applicableForEmployee, applicableForSpouse, applicableForOtherFamily } from '@jbc-year-end-adj/ancient/utils/incomeAdjustment';

export const EMPLOYEE_INCOME_FRAGMENT = gql`
  fragment Incomes on Profile {
    income {
      ...Income
    }
  }
  ${INCOME_FRAGMENT}
`;

const useIncomeAdjustmentDeduction = () => {
  const year = useYear();
  const handicapClassification = useFormValue('taxClassifications.handicapClassification');
  const spouseHandicapType = useFormValue('spouseInformation.yearlyInfo.handicapType');
  const spouseTotalEarnings = useFormValue('spouseInformation.spouseIncome.totalEarnings');
  const otherFamilies = useFormValue('otherFamilies');

  return (
    applicableForEmployee(handicapClassification) ||
    applicableForSpouse(spouseHandicapType, spouseTotalEarnings) ||
    (otherFamilies || []).some(otherFamily => applicableForOtherFamily(otherFamily, year))
  );
};

const EmployeeIncome = () => {
  const year = useYear();
  const birthday = useFormValue('basicInformation.birthday');
  const formerJobs = useFormValue('formerJobs');
  const applyIncomeAdjustmentDeduction = useIncomeAdjustmentDeduction();
  return (
    <Section title="給与収入等">
      <FormSection name="employeeIncome">
        <LabelMapper name="employeeIncome" label="給与収入等の" />
        <Income
          field="employeeIncome"
          title="本人の"
          birthday={birthday}
          year={year}
          applyIncomeAdjustmentDeduction={applyIncomeAdjustmentDeduction}
          withSideJobIncome
          formerJobs={formerJobs}
        />
      </FormSection>
    </Section>
  );
};

export const makeInitialValues = profile => toFormValues(profile.income, true);

export default EmployeeIncome;
