import { FC } from 'react';
import { FileLink } from 'components/ui/FileLink';
import { displayFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { Dl, Dt, Dd } from '../../../components/DefinitionList';
import { Grid } from '../../../components/Grid';
import { useResult } from '../ResultProvider';
import styles from './Preview.scss';

export const Preview: FC = () => {
  const { employee } = useResult();
  const profile = employee.profile;
  const requestProfile = employee.request?.profile;

  return (
    <Grid>
      <Dl>
        <Dt>添付ファイル</Dt>
        <Dd>
          {profile.attachments.length > 0 ? (
            <div className={styles.fileList}>
              {profile.attachments.map(attachment => (
                <FileLink key={attachment.id} file={attachment.file} />
              ))}
            </div>
          ) : (
            displayFormat()
          )}
        </Dd>
        <Dd>
          {requestProfile && requestProfile?.attachments.length > 0 ? (
            <div className={styles.fileList}>
              {requestProfile?.attachments.map(attachment => (
                <FileLink key={attachment.id} file={attachment.file} />
              ))}
            </div>
          ) : (
            displayFormat()
          )}
        </Dd>
      </Dl>
    </Grid>
  );
};
