import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { Checkbox } from 'jbc-front/components/presenters/form/Checkbox';

import { InputWithText } from 'components/form/InputWithText';
import { Input } from 'components/form/Input';
import { useYear } from 'hooks/useYear';
import { Grid } from '../../../../../components/Grid';
import { amountFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { Schema } from '../schema';

import {
  salaryEarnings as calcSalaryEarnings,
  generalEarningsAllowMinus,
  generalEarnings,
  miscellaneousEarnings as calcMiscellaneousEarnings,
  retirementIncomeDeductionAmount as calcRetirementIncomeDeductionAmount,
  retirementEarnings as calcRetirementEarnings,
  totalEarnings as calcTotalEarnings
  // @ts-ignore
} from 'utils/income';
import { Section } from 'components/ui/Section';
import { FormSection } from 'components/form/FormSection';
import { AmountField } from 'components/react-hook-form/AmountField';

export const Income: FC = () => {
  const year = useYear();
  const {
    formState: { errors },
    register,
    watch,
    setValue
  } = useFormContext<Schema>();

  const dependentTaxLaw = watch('spouse.thisYear.dependentTaxLaw');
  const hasSpouseIncomeDetail = watch('hasSpouseIncomeDetail');
  const salaryIncome = watch('spouse.thisYear.yearlyInfo.income.salaryIncome');
  const businessIncome = watch('spouse.thisYear.yearlyInfo.income.businessIncome');
  const businessExpense = watch('spouse.thisYear.yearlyInfo.income.businessExpense');
  const dividendIncome = watch('spouse.thisYear.yearlyInfo.income.dividendIncome');
  const dividendExpense = watch('spouse.thisYear.yearlyInfo.income.dividendExpense');
  const realEstateIncome = watch('spouse.thisYear.yearlyInfo.income.realEstateIncome');
  const realEstateExpense = watch('spouse.thisYear.yearlyInfo.income.realEstateExpense');
  const retirementIncome = watch('spouse.thisYear.yearlyInfo.income.retirementIncome');
  const lengthOfService = watch('spouse.thisYear.yearlyInfo.income.lengthOfService');
  const retireForDisablity = watch('spouse.thisYear.yearlyInfo.income.retireForDisablity');
  const isOfficerRetirementAllowance = watch('spouse.thisYear.yearlyInfo.income.isOfficerRetirementAllowance');
  const miscellaneousIncomeOfficialPension = watch('spouse.thisYear.yearlyInfo.income.miscellaneousIncomeOfficialPension');
  const miscellaneousIncomeOther = watch('spouse.thisYear.yearlyInfo.income.miscellaneousIncomeOther');
  const miscellaneousExpense = watch('spouse.thisYear.yearlyInfo.income.miscellaneousExpense');
  const otherEarnings = watch('spouse.thisYear.yearlyInfo.income.otherEarings');
  const spouseBirthday = watch('spouse.birthday');
  const earnings = watch('spouse.thisYear.yearlyInfo.earnings');

  const salaryEarnings = calcSalaryEarnings(salaryIncome, year, false);
  const businessEarnings = generalEarningsAllowMinus(businessIncome, businessExpense);
  const dividendEarnings = generalEarnings(dividendIncome, dividendExpense);
  const realEstateEarnings = generalEarningsAllowMinus(realEstateIncome, realEstateExpense);
  const retirementIncomeDeductionAmount = calcRetirementIncomeDeductionAmount(lengthOfService, retireForDisablity, retirementIncome);
  const retirementEarnings = calcRetirementEarnings(
    retirementIncome,
    retirementIncomeDeductionAmount,
    isOfficerRetirementAllowance,
    lengthOfService,
    year
  );
  const miscellaneousEarnings = calcMiscellaneousEarnings(
    miscellaneousIncomeOfficialPension,
    miscellaneousIncomeOther,
    miscellaneousExpense,
    spouseBirthday,
    year,
    {
      salary: salaryEarnings,
      business: businessEarnings,
      dividend: dividendEarnings,
      realEstate: realEstateEarnings,
      retirement: retirementEarnings,
      other: otherEarnings
    }
  );
  const totalEarnings = calcTotalEarnings(
    salaryEarnings,
    businessEarnings,
    miscellaneousEarnings,
    dividendEarnings,
    realEstateEarnings,
    retirementEarnings,
    otherEarnings
  );

  useEffect(() => {
    if (dependentTaxLaw === 'true') {
      setValue('spouse.thisYear.yearlyInfo.earnings', totalEarnings);
    } else {
      const earningsStr = String(totalEarnings)
        .split(',')
        .join('');
      const retirementEarningsStr = String(retirementEarnings)
        .split(',')
        .join('');
      const earnings = Number(earningsStr) - Number(retirementEarningsStr);
      setValue('spouse.thisYear.yearlyInfo.earnings', amountFormat(String(earnings)));
    }
  }, [totalEarnings, dependentTaxLaw]);

  return (
    <>
      <h3>配偶者の給与収入</h3>

      <Grid>
        <Section>
          <Section.Header>給与所得</Section.Header>
          <Section.Body>
            <FormSection>
              <AmountField
                label="収入金額"
                noteMaxLength={19}
                note="2か所以上から給与支給がある場合は合算した金額を入力"
                required
                error={errors.spouse?.thisYear?.yearlyInfo?.income?.salaryIncome?.message}
                isError={!!errors.spouse?.thisYear?.yearlyInfo?.income?.salaryIncome?.message}
                {...register('spouse.thisYear.yearlyInfo.income.salaryIncome')}
              />
              <InputWithText label="所得金額" text="円" value={amountFormat(String(salaryEarnings))} disabled />
            </FormSection>
          </Section.Body>
        </Section>
      </Grid>

      <Checkbox {...register('hasSpouseIncomeDetail')}>給与収入以外の収入を入力する</Checkbox>

      {hasSpouseIncomeDetail && (
        <Grid colGap>
          <Section>
            <Section.Header>事業所得</Section.Header>
            <Section.Body>
              <FormSection>
                <AmountField
                  label="収入金額"
                  noteMaxLength={19}
                  note="農業、林業、水産養殖業、製造業、卸売業、小売業、金融業などのサービス業のほか対価を得て継続的に行う事業による所得"
                  error={errors.spouse?.thisYear?.yearlyInfo?.income?.businessIncome?.message}
                  isError={!!errors.spouse?.thisYear?.yearlyInfo?.income?.businessIncome?.message}
                  {...register('spouse.thisYear.yearlyInfo.income.businessIncome')}
                />
                <AmountField
                  label="必要経費"
                  noteMaxLength={19}
                  note="収入を得るために必要な売上原価、販売費・一般管理費その他の費用"
                  error={errors.spouse?.thisYear?.yearlyInfo?.income?.businessExpense?.message}
                  isError={!!errors.spouse?.thisYear?.yearlyInfo?.income?.businessExpense?.message}
                  {...register('spouse.thisYear.yearlyInfo.income.businessExpense')}
                />
                <InputWithText text="円" label="所得金額" value={amountFormat(businessEarnings)} disabled />
              </FormSection>
            </Section.Body>
          </Section>

          <Section>
            <Section.Header>雑所得</Section.Header>
            <Section.Body>
              <FormSection>
                <AmountField
                  label="収入金額（公的年金等に係る雑所得）"
                  noteMaxLength={19}
                  note="年金、厚生年金、共済年金などの公的年金等"
                  error={errors.spouse?.thisYear?.yearlyInfo?.income?.miscellaneousIncomeOfficialPension?.message}
                  isError={!!errors.spouse?.thisYear?.yearlyInfo?.income?.miscellaneousIncomeOfficialPension?.message}
                  {...register('spouse.thisYear.yearlyInfo.income.miscellaneousIncomeOfficialPension')}
                />
                <AmountField
                  label="収入金額（公的年金等以外の雑所得）"
                  noteMaxLength={19}
                  note="原稿料や印税、講演料、放送出演料、貸金の利子、生命保険契約等に基づく年金など他のいずれの所得にも該当しない所得や恩給（一時恩給を除く）等"
                  error={errors.spouse?.thisYear?.yearlyInfo?.income?.miscellaneousIncomeOther?.message}
                  isError={!!errors.spouse?.thisYear?.yearlyInfo?.income?.miscellaneousIncomeOther?.message}
                  {...register('spouse.thisYear.yearlyInfo.income.miscellaneousIncomeOther')}
                />
                <AmountField
                  label="必要経費"
                  error={errors.spouse?.thisYear?.yearlyInfo?.income?.miscellaneousExpense?.message}
                  isError={!!errors.spouse?.thisYear?.yearlyInfo?.income?.miscellaneousExpense?.message}
                  {...register('spouse.thisYear.yearlyInfo.income.miscellaneousExpense')}
                />
                <InputWithText text="円" label="所得金額" value={amountFormat(miscellaneousEarnings)} disabled />
              </FormSection>
            </Section.Body>
          </Section>

          <Section>
            <Section.Header>配当所得</Section.Header>
            <Section.Body>
              <FormSection>
                <AmountField
                  label="収入金額"
                  noteMaxLength={19}
                  note="株主や出資者が法人から受ける剰余金や、利益の配当、剰余金の分配、投資法人からの金銭の分配、投資信託（公社債投資信託及び公募公社債等運用投資信託以外のもの）及び特定受益証券発行信託の収益の分配などに係る所得"
                  error={errors.spouse?.thisYear?.yearlyInfo?.income?.dividendIncome?.message}
                  isError={!!errors.spouse?.thisYear?.yearlyInfo?.income?.dividendIncome?.message}
                  {...register('spouse.thisYear.yearlyInfo.income.dividendIncome')}
                />
                <AmountField
                  label="必要経費"
                  noteMaxLength={19}
                  note="収入金額からその元本を取得するために要した負債の利子（株式等の取得のために借り入れた負債の利子のうち、その株式等の譲渡所得等に係るものを除きます。）"
                  error={errors.spouse?.thisYear?.yearlyInfo?.income?.dividendExpense?.message}
                  isError={!!errors.spouse?.thisYear?.yearlyInfo?.income?.dividendExpense?.message}
                  {...register('spouse.thisYear.yearlyInfo.income.dividendExpense')}
                />
                <InputWithText text="円" label="所得金額" value={amountFormat(dividendEarnings)} disabled />
              </FormSection>
            </Section.Body>
          </Section>

          <Section>
            <Section.Header>不動産所得</Section.Header>
            <Section.Body>
              <FormSection>
                <AmountField
                  label="収入金額"
                  noteMaxLength={19}
                  note="不動産の貸付けに際して受け取る権利金や頭金、更新料、名義書換料も不動産所得になります"
                  error={errors.spouse?.thisYear?.yearlyInfo?.income?.realEstateIncome?.message}
                  isError={!!errors.spouse?.thisYear?.yearlyInfo?.income?.realEstateIncome?.message}
                  {...register('spouse.thisYear.yearlyInfo.income.realEstateIncome')}
                />
                <AmountField
                  label="必要経費"
                  noteMaxLength={19}
                  note="貸し付けた不動産についての修繕費、損害保険料、租税公課、減価償却費、借入金利子等"
                  error={errors.spouse?.thisYear?.yearlyInfo?.income?.realEstateExpense?.message}
                  isError={!!errors.spouse?.thisYear?.yearlyInfo?.income?.realEstateExpense?.message}
                  {...register('spouse.thisYear.yearlyInfo.income.realEstateExpense')}
                />
                <InputWithText text="円" label="所得金額" value={amountFormat(realEstateEarnings)} disabled />
              </FormSection>
            </Section.Body>
          </Section>

          <Section>
            <Section.Header>退職所得</Section.Header>
            <Section.Body>
              <FormSection>
                <AmountField
                  label="退職所得 収入金額"
                  noteMaxLength={19}
                  note="退職手当、一時恩給その他の退職により一時に受ける給与などの所得のほか、社会保険制度等に基づく一時金など"
                  error={errors.spouse?.thisYear?.yearlyInfo?.income?.retirementIncome?.message}
                  isError={!!errors.spouse?.thisYear?.yearlyInfo?.income?.retirementIncome?.message}
                  {...register('spouse.thisYear.yearlyInfo.income.retirementIncome')}
                />
                {retirementIncome && Number(retirementIncome.split(',').join('')) > 0 && (
                  <>
                    <Input
                      label="勤続年数"
                      error={errors.spouse?.thisYear?.yearlyInfo?.income?.lengthOfService?.message}
                      isError={!!errors.spouse?.thisYear?.yearlyInfo?.income?.lengthOfService?.message}
                      {...register('spouse.thisYear.yearlyInfo.income.lengthOfService')}
                    />
                    <Checkbox {...register('spouse.thisYear.yearlyInfo.income.retireForDisablity')}>
                      障害者になったことに直接基因して退職した
                    </Checkbox>
                    <Checkbox {...register('spouse.thisYear.yearlyInfo.income.isOfficerRetirementAllowance')}>
                      退職手当等が特定役員退職手当等に該当する
                    </Checkbox>
                  </>
                )}
                <InputWithText text="円" label="退職所得 控除額" value={amountFormat(retirementIncomeDeductionAmount)} disabled />
                <InputWithText text="円" label="退職所得 所得金額" value={amountFormat(retirementEarnings)} disabled />
              </FormSection>
            </Section.Body>
          </Section>

          <Section>
            <Section.Header>その他所得</Section.Header>
            <Section.Body>
              <FormSection>
                <AmountField
                  label="収入金額"
                  noteMaxLength={19}
                  note="⑴ 譲渡所得（土地、建物、機械、ゴルフ会員権、金地金、書画、骨とうなどの資産の譲渡による所得） ⑵ 山林所得（山林（所有期間５年超）の伐採又は譲渡による所得） ⑶ 一時所得（賞金や懸賞当せん金、競馬・競輪の払戻金（営利を目的とする継続的行為から生じたものを除きます。）、生命保険契約等に基づく一時金、損害保険契約等に基づく満期返戻金、遺失物拾得の報労金などによる所得） ⑷ 総合課税又は申告分離課税の対象となる利子所得 （注） 利子所得のうち預貯金の利子などの源泉分離課税の対象となるもの及び特定公社債の利子などの申告分離課税の対象となるもので確定申告しないことを選択したものは、収入金額に含まれません。 ⑸ 申告分離課税の適用を受けた一般株式等に係る譲渡所得等又は上場株式等に係る譲渡所得等（源泉徴収選択口座を通じて行った上場株式等の譲渡による所得等で、確定申告をしないことを選択した所得等は、収入金額に含まれません。） ⑹ 先物取引に係る雑所得等"
                  error={errors.spouse?.thisYear?.yearlyInfo?.income?.otherIncome?.message}
                  isError={!!errors.spouse?.thisYear?.yearlyInfo?.income?.otherIncome?.message}
                  {...register('spouse.thisYear.yearlyInfo.income.otherIncome')}
                />
                <AmountField
                  label="必要経費"
                  error={errors.spouse?.thisYear?.yearlyInfo?.income?.otherExpense?.message}
                  isError={!!errors.spouse?.thisYear?.yearlyInfo?.income?.otherExpense?.message}
                  {...register('spouse.thisYear.yearlyInfo.income.otherExpense')}
                />
                <AmountField
                  label="必要経費 うち特別控除額"
                  error={errors.spouse?.thisYear?.yearlyInfo?.income?.otherExpenseSpecialDeduction?.message}
                  isError={!!errors.spouse?.thisYear?.yearlyInfo?.income?.otherExpenseSpecialDeduction?.message}
                  {...register('spouse.thisYear.yearlyInfo.income.otherExpenseSpecialDeduction')}
                />
                <AmountField
                  label="所得金額"
                  error={errors.spouse?.thisYear?.yearlyInfo?.income?.otherEarings?.message}
                  isError={!!errors.spouse?.thisYear?.yearlyInfo?.income?.otherEarings?.message}
                  {...register('spouse.thisYear.yearlyInfo.income.otherEarings')}
                />
              </FormSection>
            </Section.Body>
          </Section>
        </Grid>
      )}

      <Grid>
        <InputWithText
          label="合計所得金額"
          text="円"
          disabled
          error={errors.spouse?.thisYear?.yearlyInfo?.earnings?.message}
          isError={!!errors.spouse?.thisYear?.yearlyInfo?.earnings?.message}
          value={amountFormat(earnings)}
          {...register('spouse.thisYear.yearlyInfo.earnings')}
        />
      </Grid>
    </>
  );
};
