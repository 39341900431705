import { QuestionGroup, Question } from '../../types';

const findQuestionIndex = (currentGroup: QuestionGroup, currentQuestion: Question) => {
  return currentGroup.questions.findIndex(question => question.id === currentQuestion?.id);
};

export const currentQuestionName = (questionGroups: QuestionGroup[], currentQuestion: Question) => {
  const group = questionGroups.find(group => {
    return group.questions.some(question => question.code === currentQuestion.code);
  });

  if (group) {
    return `STEP ${group.id} - ${findQuestionIndex(group, currentQuestion) + 1} ${currentQuestion.name}`;
  } else {
    return currentQuestion.name;
  }
};

export const progress = (answeredCount: number, total: number) => {
  return (answeredCount / total) * 100;
};

export const totalQuestionsCount = (questionGroups: QuestionGroup[]) => {
  return questionGroups.reduce((total, group) => total + group.questions.length, 0);
};
