import React, { useCallback } from 'react';
import { gql } from '@apollo/client';
import useReactRouter from 'use-react-router';
import _ from 'lodash';
import Button from 'jbc-front/components/Button';
import { useQuery, useYear } from '../components';
import { useAutofill, useFormValue } from '../components/FormName';
import { toFormValues } from '../utils/form';
import {
  calcEarthquakeInsuranceNewType,
  calcEarthquakeInsuranceOldType,
  calcGeneralLifeInsuranceNewType,
  calcGeneralLifeInsuranceOldType,
  calcIndividualAnnuityInsuranceNewType,
  calcIndividualAnnuityInsuranceOldType,
  calcNursingMedicalInsuranceNewType,
  sumWithLimit,
  limit
} from './calc';
import { ManualField, Note, Section, Table, Td, Th, Total, Tr } from './parts';

const SECTION_NAME = 'insuranceDeduction';

export const INSURANCE_AMOUNT_SUM_FRAGMENT = gql`
  fragment InsuranceAmountSum on InsuranceAmountSum {
    id
    earthquakeInsuranceNewType
    earthquakeInsuranceOldType
    generalLifeInsuranceNewType
    generalLifeInsuranceOldType
    individualAnnuityInsuranceNewType
    individualAnnuityInsuranceOldType
    nursingMedicalInsuranceNewType
    socialInsurance
    socialInsuranceNationalPensionEtc
    premium
    housingLoanDeductionApplicableAmount
  }
`;

const INSURANCE_AMOUNT_SUM = gql`
  query withholdingSlip($id: ID!, $year: Int!) {
    client {
      id
      clientYearly(year: $year) {
        id
        employee(id: $id) {
          id
          insuranceAmountAutoSum {
            ...InsuranceAmountSum
          }
          insuranceAmountFinalSum {
            ...InsuranceAmountSum
          }
          withholdingSlip {
            id
          }
        }
      }
    }
  }
  ${INSURANCE_AMOUNT_SUM_FRAGMENT}
`;

const GeneralLifeInsurances = ({ autoSum }) => {
  const formValues = [
    useFormValue(`${SECTION_NAME}.generalLifeInsuranceOldType`),
    useFormValue(`${SECTION_NAME}.generalLifeInsuranceNewType`)
  ];
  const handleCalc = useCallback(([oldType, newType]) => {
    const insurances = [calcGeneralLifeInsuranceOldType(oldType), calcGeneralLifeInsuranceNewType(newType)];
    return _.max([_.first(insurances), sumWithLimit(insurances, 40000)]);
  }, []);
  return (
    <>
      <Tr>
        <Th>旧契約の一般生命保険料額</Th>
        <Td>
          <ManualField
            name="generalLifeInsuranceOldType"
            label="旧契約の一般生命保険料額"
            autoSum={autoSum['generalLifeInsuranceOldType']}
          />
        </Td>
      </Tr>
      <Tr>
        <Th>新契約の一般生命保険料額</Th>
        <Td>
          <ManualField
            name="generalLifeInsuranceNewType"
            label="新契約の一般生命保険料額"
            autoSum={autoSum['generalLifeInsuranceNewType']}
          />
        </Td>
      </Tr>
      <Tr>
        <Th right>一般生命保険料控除額</Th>
        <Td>
          <Total name="generalLifeInsurance" {...{ formValues, handleCalc }} />
        </Td>
      </Tr>
    </>
  );
};

const NursingMedicalInsurance = ({ autoSum }) => {
  const formValues = [useFormValue(`${SECTION_NAME}.nursingMedicalInsuranceNewType`)];
  const handleCalc = useCallback(nums => {
    return calcNursingMedicalInsuranceNewType(_.head(nums));
  }, []);
  return (
    <>
      <Tr>
        <Th>介護医療保険料額</Th>
        <Td>
          <ManualField name="nursingMedicalInsuranceNewType" label="介護医療保険料額" autoSum={autoSum['nursingMedicalInsuranceNewType']} />
        </Td>
      </Tr>
      <Tr>
        <Th right>介護医療保険料控除額</Th>
        <Td>
          <Total name="nursingMedicalInsurance" {...{ formValues, handleCalc }} />
        </Td>
      </Tr>
    </>
  );
};

const IndividualAnnuityInsurance = ({ autoSum }) => {
  const formValues = [
    useFormValue(`${SECTION_NAME}.individualAnnuityInsuranceOldType`),
    useFormValue(`${SECTION_NAME}.individualAnnuityInsuranceNewType`)
  ];
  const handleCalc = useCallback(([oldType, newType]) => {
    const insurances = [calcIndividualAnnuityInsuranceOldType(oldType), calcIndividualAnnuityInsuranceNewType(newType)];
    return _.max([_.first(insurances), sumWithLimit(insurances, 40000)]);
  }, []);
  return (
    <>
      <Tr>
        <Th>旧契約の個人年金保険料額</Th>
        <Td>
          <ManualField
            name="individualAnnuityInsuranceOldType"
            label="旧契約の個人年金保険料額"
            autoSum={autoSum['individualAnnuityInsuranceOldType']}
          />
        </Td>
      </Tr>
      <Tr>
        <Th>新契約の個人年金保険料額</Th>
        <Td>
          <ManualField
            name="individualAnnuityInsuranceNewType"
            label="新契約の個人年金保険料額"
            autoSum={autoSum['individualAnnuityInsuranceNewType']}
          />
        </Td>
      </Tr>
      <Tr>
        <Th right>個人年金保険料の控除額</Th>
        <Td>
          <Total name="individualAnnuityInsurance" {...{ formValues, handleCalc }} />
        </Td>
      </Tr>
    </>
  );
};

const LifeInsurance = () => {
  const formValues = [
    useFormValue('totalAmount.generalLifeInsurance'),
    useFormValue('totalAmount.nursingMedicalInsurance'),
    useFormValue('totalAmount.individualAnnuityInsurance')
  ];
  const handleCalc = useCallback(nums => _.sum(nums) |> limit(120000), []);
  return (
    <Tr bold>
      <Th right>生命保険料控除額</Th>
      <Td>
        <Total {...{ formValues, handleCalc }} />
      </Td>
    </Tr>
  );
};

const EarthquakeInsurance = ({ autoSum }) => {
  const formValues = [
    useFormValue(`${SECTION_NAME}.earthquakeInsuranceOldType`),
    useFormValue(`${SECTION_NAME}.earthquakeInsuranceNewType`)
  ];
  const handleCalc = useCallback(([oldType, newType]) => {
    const insurances = [calcEarthquakeInsuranceOldType(oldType), calcEarthquakeInsuranceNewType(newType)];
    return sumWithLimit(insurances, 50000);
  }, []);
  return (
    <>
      <Tr>
        <Th>地震保険料額</Th>
        <Td>
          <ManualField name="earthquakeInsuranceNewType" label="地震保険料額" autoSum={autoSum['earthquakeInsuranceNewType']} />
        </Td>
      </Tr>
      <Tr>
        <Th>旧長期損害保険料額</Th>
        <Td>
          <ManualField name="earthquakeInsuranceOldType" label="旧長期損害保険料額" autoSum={autoSum['earthquakeInsuranceOldType']} />
        </Td>
      </Tr>
      <Tr bold>
        <Th right>地震保険料控除額</Th>
        <Td>
          <Total {...{ formValues, handleCalc }} />
        </Td>
      </Tr>
    </>
  );
};

const SocialInsurance = ({ autoSum }) => {
  const formValues = [
    useFormValue(`totalAmount.deductionAmount`),
    useFormValue(`${SECTION_NAME}.premium`),
    useFormValue(`${SECTION_NAME}.socialInsurance`)
  ];
  const handleCalc = useCallback(_.sum, []);
  return (
    <>
      <Tr>
        <Th>社会保険料額</Th>
        <Td>
          <ManualField name="socialInsurance" label="社会保険料額" autoSum={autoSum['socialInsurance']} />
        </Td>
      </Tr>
      <Tr>
        <Th>（うち国民年金保険料等の金額）</Th>
        <Td>
          <ManualField
            name="socialInsuranceNationalPensionEtc"
            label="（うち国民年金保険料等の金額）"
            autoSum={autoSum['socialInsuranceNationalPensionEtc']}
          />
        </Td>
      </Tr>
      <Tr>
        <Th>小規模企業共済等掛金</Th>
        <Td>
          <ManualField name="premium" label="小規模企業共済等掛金" autoSum={autoSum['premium']} />
        </Td>
      </Tr>
      <Tr bold>
        <Th right>社会保険料等の金額</Th>
        <Td>
          <Total {...{ formValues, handleCalc }} />
        </Td>
      </Tr>
    </>
  );
};

const InsuranceDeduction = () => {
  const {
    match: {
      params: { id }
    }
  } = useReactRouter();
  const year = useYear();
  const { autofill } = useAutofill();
  const { data, loading } = useQuery(INSURANCE_AMOUNT_SUM, {
    variables: { id, year }
  });
  if (loading) return null;
  const {
    client: {
      clientYearly: {
        employee: { insuranceAmountAutoSum }
      }
    }
  } = data;
  const handleRecalculate = () => {
    _.forEach(toFormValues(insuranceAmountAutoSum, true), (v, k) => {
      const sectionName = k === 'housingLoanDeductionApplicableAmount' ? 'housingLoanDeduction' : SECTION_NAME;
      autofill(`${sectionName}.${k}`, v);
      autofill(`edited.${k}`, false);
    });
  };
  return (
    <>
      <Section formName={SECTION_NAME} title="保険料控除" button={<Button onClick={handleRecalculate}>従業員情報から再計算する</Button>}>
        <Table>
          <tbody>
            <GeneralLifeInsurances autoSum={insuranceAmountAutoSum} />
            <NursingMedicalInsurance autoSum={insuranceAmountAutoSum} />
            <IndividualAnnuityInsurance autoSum={insuranceAmountAutoSum} />
            <LifeInsurance />
            <EarthquakeInsurance autoSum={insuranceAmountAutoSum} />
            <SocialInsurance autoSum={insuranceAmountAutoSum} />
          </tbody>
        </Table>
      </Section>
      <Section title="住宅ローン控除等" formName="housingLoanDeduction">
        <Table>
          <tbody>
            <Tr>
              <Th>住宅借入金等特別控除額</Th>
              <Td>
                <ManualField
                  name="housingLoanDeductionApplicableAmount"
                  label="住宅ローン控除等の住宅借入金等特別控除額"
                  autoSum={insuranceAmountAutoSum['housingLoanDeductionApplicableAmount']}
                />
              </Td>
            </Tr>
          </tbody>
        </Table>
      </Section>
      <Note>※適用数・住居開始年月日・特別控除区分・年末残高は「年末調整の依頼一覧」の「従業員情報入力」よりご入力ください。</Note>
    </>
  );
};

export default InsuranceDeduction;
