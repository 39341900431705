import React from 'react';
import styles from '../pages/EmployeeEdit.scss';
import Modal from '../components/Modal';
import Button from 'jbc-front/components/Button';

const CancelInformationRequest = ({ client, handleSubmit, submitting, widthWide = null, status }) => {
  const isOpenModal = ['not_requested', 'in_progress', 'rejected'].includes(status);

  const disabledReason = client =>
    client.clientYearly?.fixed && (
      <p className="u-ta-l">
        年末調整情報が確定していますので従業員情報の修正はできません。
        <br />
        修正が必要な場合、確定を解除してください。
      </p>
    );

  return isOpenModal ? (
    <>
      <Modal>
        {({ isOpen, showModal, hideModal }) => (
          <>
            <Button primary onClick={showModal} widthWide={widthWide} hideModal={hideModal} disabled={submitting}>
              完了
            </Button>
            <Modal.Modal isOpen={isOpen}>
              <Modal.Header>{status == 'not_requested' ? 'ステータスの確認' : '入力依頼の取消'}</Modal.Header>
              <Modal.Body>{status == 'not_requested' ? <NotRequestBody /> : <InRequestingBody />}</Modal.Body>
              <Modal.Footer>
                <Modal.Buttons>
                  <Button onClick={hideModal}>キャンセル</Button>
                  <Button
                    primary
                    onClick={async () => {
                      await handleSubmit('accept');
                      hideModal();
                    }}
                    disabled={submitting || client.clientYearly?.fixed}
                  >
                    完了
                  </Button>
                </Modal.Buttons>
              </Modal.Footer>
            </Modal.Modal>
          </>
        )}
      </Modal>
    </>
  ) : (
    <Button
      primary
      onClick={async () => await handleSubmit('accept')}
      widthWide={widthWide}
      disabled={submitting || client.clientYearly?.fixed}
      disabledReason={disabledReason(client)}
    >
      完了
    </Button>
  );
};
export default CancelInformationRequest;

const NotRequestBody = () => <>ステータスが「完了」になりますがよろしいですか？</>;
const InRequestingBody = () => (
  <>
    従業員に入力依頼をしている場合、
    <br />
    完了すると入力依頼が取り消されてステータスが「完了」になります。
    <br />
    <span className={styles.alertText}>本当に保存しますか？</span>
  </>
);
