import { FC } from 'react';
import { FileType } from '@jbc-year-end-adj/types';

import styles from './FileLink.scss';

type FileLinkProps = {
  file: FileType;
};

export const FileLink: FC<FileLinkProps> = ({ file }) => {
  return (
    <a href={file.url} download className={styles.downloadLink} target="_blank" rel="noopener noreferrer">
      {file.filename || file.file}
    </a>
  );
};
