import React from 'react'
import Icon from 'react-icon-base'

const Close = props => (
  <Icon viewBox="0 0 20 20" {...props}>
    <path d='M5.05 5.05l9.9 9.9M5.05 14.95l9.9-9.9' fill='none' fillRule='evenodd'
      stroke='currentColor' strokeWidth='2' />
  </Icon>
)
Close.defaultProps = {
  size: 20
}

export default Close
