import React from 'react'
import Icon from 'react-icon-base'

const Custom2 = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="icon/employees/Custom2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group" transform="translate(5.000000, 2.000000)" fill={props.color} fillRule="nonzero">
        <path d="M24.9646968,3 C25.196645,3 25.4208194,3.07701983 25.6037638,3.21716963 L25.7083151,3.30872398 L32.688983,10.2780999 L32.7653868,10.3634066 C32.9155586,10.5491979 32.989805,10.7633479 33,10.976569 L32.9990975,29.9387755 C32.9990975,31.5722036 31.7256525,32.9019192 30.1262729,32.9948154 L29.9472709,33 L0.0518266008,33 C-1.57039656,33 -2.9017781,31.7286212 -2.99480762,30.1189703 L-3,29.9387755 L-3,6.06122449 C-3,4.42779638 -1.726555,3.09808083 -0.1271754,3.0051846 L0.0518266008,3 L24.9646968,3 Z M23.9717333,5 L-0.0588235294,5 C-0.541586089,5 -0.935043874,5.357043 -0.992719258,5.81274112 L-1,5.92857143 L-1,30.0714286 C-1,30.5417748 -0.643677796,30.9349983 -0.177511016,30.9927111 L-0.0588235294,31 L30.0588235,31 C30.5415861,31 30.9350439,30.642957 30.9927193,30.1872589 L31,30.0714286 L31,12.0416667 L25.039457,12.0416667 L24.9148198,12.0343783 C24.4251325,11.9766132 24.0366017,11.5820116 23.9797253,11.0846729 L23.972549,10.9583333 L23.9717333,5 Z M25.9650039,6.41938966 L26,10 L29.5509654,10 L25.9650039,6.41938966 Z" id="Path" transform="translate(15.000000, 18.000000) rotate(90.000000) translate(-15.000000, -18.000000) "></path>
        <path d="M15.5425516,4 C16.4400143,4 17,4.67157288 17,5.5 L17,6.5 C17,7.32842712 16.4400143,8 15.5425516,8 L5.5,8 C4.60253728,8 4,7.32842712 4,6.5 L4,5.5 C4,4.67157288 4.60253728,4 5.5,4 L15.5425516,4 Z M15.2,5.5 L5.8,5.5 C5.63431458,5.5 5.5,5.63431458 5.5,5.8 L5.5,6.2 C5.5,6.36568542 5.63431458,6.5 5.8,6.5 L15.2,6.5 C15.3656854,6.5 15.5,6.36568542 15.5,6.2 L15.5,5.8 C15.5,5.63431458 15.3656854,5.5 15.2,5.5 Z" id="Rectangle"></path>
        <path d="M24.35,10 C25.2612698,10 26,10.6715729 26,11.5 L26,15.5 C26,16.3284271 25.2612698,17 24.35,17 L5.65,17 C4.73873016,17 4,16.3284271 4,15.5 L4,11.5 C4,10.6715729 4.73873016,10 5.65,10 L24.35,10 Z M24,11.5 L6,11.5 C5.72385763,11.5 5.5,11.7238576 5.5,12 L5.5,15 C5.5,15.2761424 5.72385763,15.5 6,15.5 L24,15.5 C24.2761424,15.5 24.5,15.2761424 24.5,15 L24.5,12 C24.5,11.7238576 24.2761424,11.5 24,11.5 Z" id="Rectangle-Copy"></path>
        <path d="M24.35,19 C25.2612698,19 26,19.6715729 26,20.5 L26,22.5 C26,23.3284271 25.2612698,24 24.35,24 L5.65,24 C4.73873016,24 4,23.3284271 4,22.5 L4,20.5 C4,19.6715729 4.73873016,19 5.65,19 L24.35,19 Z M24,20.5 L6,20.5 C5.72385763,20.5 5.5,20.7238576 5.5,21 L5.5,22 C5.5,22.2761424 5.72385763,22.5 6,22.5 L24,22.5 C24.2761424,22.5 24.5,22.2761424 24.5,22 L24.5,21 C24.5,20.7238576 24.2761424,20.5 24,20.5 Z" id="Rectangle-Copy-2"></path>
        <path d="M12.5285032,26 C13.5640371,26 14,26.6715729 14,27.5 L14,28.5 C14,29.3284271 13.5640371,30 12.5285032,30 L5.5,30 C4.46446609,30 4,29.3284271 4,28.5 L4,27.5 C4,26.6715729 4.46446609,26 5.5,26 L12.5285032,26 Z M12.2,27.5 L5.8,27.5 C5.63431458,27.5 5.5,27.6343146 5.5,27.8 L5.5,28.2 C5.5,28.3656854 5.63431458,28.5 5.8,28.5 L12.2,28.5 C12.3656854,28.5 12.5,28.3656854 12.5,28.2 L12.5,27.8 C12.5,27.6343146 12.3656854,27.5 12.2,27.5 Z" id="Rectangle-Copy-3"></path>
      </g>
    </g>
  </Icon>
)
Custom2.defaultProps = {
  size: 30,
  color: "#3498DB"
}

export default Custom2