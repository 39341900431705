import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { gql } from '@apollo/client';
import { Glass } from 'jbc-front/components/icons';
import Paginator from 'jbc-front/components/Paginator';
import Button from 'jbc-front/components/Button';
import { useQuery } from '../components/Graphql';
import { Modal, useAutofill } from '../components';
import styles from './CitySearchModal.scss';
import ActionButton from 'jbc-front/components/ActionButton';
import { Map } from 'jbc-front/components/icons';
import { Field, FormName } from 'redux-form';

const PER_PAGE = 9;
const FIRST = 1;

const SEARCH_CITIES = gql`
  query searchCities($page: Int, $per: Int, $query: String) {
    cities(page: $page, per: $per, query: $query) {
      totalCount
      list {
        id
        code
        name
      }
    }
  }
`;

const List = ({ query, input, hideModal }) => {
  const { autofill } = useAutofill();
  const [current, setCurrent] = useState(FIRST);
  const { loading, data } = useQuery(SEARCH_CITIES, {
    variables: { per: PER_PAGE, page: current, query }
  });
  useEffect(() => {
    setCurrent(FIRST);
  }, [query]);
  if (loading && !data?.cities) return null;
  const {
    cities: { totalCount }
  } = data;
  return (
    <>
      <div className="u-mt30">{totalCount} 件の検索結果</div>
      <div className="u-mt10">
        <ul className={styles.results}>
          <FormName>
            {({ sectionPrefix }) =>
              data.cities.list.map(city => (
                <li
                  role="presentation"
                  key={city.id}
                  onClick={() => {
                    input.onChange(city.code);
                    autofill(`${sectionPrefix}.name`, city.name);
                    hideModal();
                  }}
                >
                  {city.code}：{city.name}
                </li>
              ))
            }
          </FormName>
        </ul>
      </div>
      <div className={styles.pager}>
        <Paginator onClick={page => setCurrent(page)} {...{ totalResult: totalCount, current, rowsPerPage: PER_PAGE }} />
      </div>
    </>
  );
};

const Search = ({ queryInput, setQueryInput }) => {
  return (
    <input
      type="text"
      placeholder="市区町村コード・市区町村名で検索"
      value={queryInput}
      onChange={({ target }) => setQueryInput(target.value)}
    />
  );
};

const SearchList = ({ handleBackToAuto, hideModal, input }) => {
  const [queryInput, setQueryInput] = useState('');
  const [query, _setQuery] = useState('');
  const setQuery = useCallback(
    _.debounce(query => {
      _setQuery(query);
    }, 500),
    []
  );
  useEffect(() => {
    setQuery(queryInput);
  }, [queryInput, setQuery]);
  return (
    <Modal.Modal>
      <Modal.Header>市区町村選択</Modal.Header>
      <Modal.Body>
        <div className={styles.container}>
          <div className={styles.searchWrap}>
            <div className={styles.inputSearch}>
              <Glass size={16} />
              <Search {...{ queryInput, setQueryInput }} />
            </div>
            <Button
              type="button"
              as="button"
              widthAuto
              className={styles.backButton}
              onClick={() => {
                handleBackToAuto();
                hideModal();
              }}
            >
              自動判別に戻す
            </Button>
          </div>
        </div>
        <List query={query} input={input} hideModal={hideModal} />
      </Modal.Body>
    </Modal.Modal>
  );
};

export const CitySearchModal = ({ handleBackToAuto, disabled, input }) => (
  <Modal>
    {({ showModal, hideModal }) => (
      <>
        <ActionButton icon={<Map size={14} />} onClick={showModal} disabled={disabled}>
          市区町村を選択
        </ActionButton>
        <SearchList {...{ handleBackToAuto, hideModal, input }} />
      </>
    )}
  </Modal>
);

export const CitySearchField = props => <Field {...props} component={CitySearchModal} />;
