import React from 'react'
import Icon from 'react-icon-base'

const Birth = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g fill="none" stroke={props.color}>
      <path d="M24.206 13.402c0-3.21-2.61-5.82-5.82-5.82a5.827 5.827 0 0 0-5.819 5.82 5.811 5.811 0 0 0 2.65 4.883 3.555 3.555 0 0 1 3.17-1.95c1.381 0 2.582.793 3.168 1.948a5.858 5.858 0 0 0 2.651-4.881zm-4.427 6.484a1.394 1.394 0 0 0-2.786 0c0 .768.625 1.393 1.394 1.393.768 0 1.392-.625 1.392-1.393zm6.585-6.484a8.023 8.023 0 0 1-4.494 7.179 3.558 3.558 0 0 1-3.483 2.857c-1.72 0-3.16-1.23-3.483-2.857a7.964 7.964 0 0 1-4.495-7.18c0-4.398 3.579-7.977 7.978-7.977 4.399 0 7.977 3.579 7.977 7.978zm-7.977 23.952c-5.858 0-10.623-4.765-10.623-10.623V13.269c0-5.858 4.765-10.623 10.623-10.623 5.857 0 10.623 4.765 10.623 10.623v4.75a1.08 1.08 0 0 0 2.158 0v-4.75C31.168 6.22 25.434.488 18.387.488c-7.048 0-12.782 5.733-12.782 12.78v13.463c0 7.047 5.734 12.781 12.782 12.781 2.602 0 5.11-.785 7.254-2.272a1.08 1.08 0 0 0-1.23-1.774 10.527 10.527 0 0 1-6.024 1.888zm2.086-26.433a1.193 1.193 0 1 0 0 2.387 1.193 1.193 0 0 0 0-2.387zm-2.98 1.194a1.194 1.194 0 1 1-2.387 0 1.194 1.194 0 0 1 2.388 0z" fill="currentColor" strokeWidth=".3"/>
      <g strokeLinecap="round" strokeWidth="2.5">
        <path d="M25.63 28.378h8.78M30.02 32.768v-8.78"/>
      </g>
    </g>
  </Icon>
)
Birth.defaultProps = {
  size: 40
}

export default Birth

