import React, { useEffect, useMemo } from 'react';
import { gql } from '@apollo/client';
import { useQuery, useMutation, useYear } from '../components';
import { DownloadModal } from '../employees/DownloadModal';

const EMPLOYEE_CSV_EXPORT_REQUEST_RUNNING = gql`
  query employeeCsvExportRequestRunning($year: Int!) {
    client {
      id
      clientYearly(year: $year) {
        id
        fixed
        employeeCsvExportRequestRunning
      }
    }
  }
`;

const EMPLOYEES_CSV = gql`
  query employeeCsv($fileEncoding: FileEncoding, $search: EmployeeSearchInput, $year: Int!, $targetData: ExportTargetData) {
    client {
      id
      clientYearly(year: $year) {
        id
        exportEmployeeCsv(fileEncoding: $fileEncoding, search: $search, targetData: $targetData)
      }
    }
  }
`;

const CREATE_EMPLOYEE_CSV_EXPORT_REQUEST = gql`
  mutation createEmployeeCsvExportRequest($input: CreateEmployeeCsvExportRequestInput!) {
    createEmployeeCsvExportRequest(input: $input) {
      clientYearly {
        id
        employeeCsvExportRequestRunning
      }
    }
  }
`;

const DownloadEmployeesCsv = ({ search, hasConditions }) => {
  const year = useYear();
  const [createCsvRequest] = useMutation(CREATE_EMPLOYEE_CSV_EXPORT_REQUEST);
  const { data, startPolling, stopPolling } = useQuery(EMPLOYEE_CSV_EXPORT_REQUEST_RUNNING, {
    variables: { year },
    fetchPolicy: 'network-only'
  });
  const exportRequest = data?.client?.clientYearly || {};
  const polling = useMemo(() => {
    return exportRequest?.employeeCsvExportRequestRunning;
  }, [exportRequest]);
  const fixed = data?.client?.clientYearly?.fixed || false;

  useEffect(() => {
    if (polling) {
      startPolling(3000);
      return () => {
        stopPolling();
      };
    }
    return undefined;
  });

  return (
    <DownloadModal
      button="従業員データダウンロード"
      header="従業員データダウンロード"
      fileName="年末調整データ.csv"
      noteItem="年末調整データ"
      type="employee"
      query={EMPLOYEES_CSV}
      getFile={data => data.client.clientYearly.exportEmployeeCsv}
      createCsvRequest={createCsvRequest}
      search={search}
      hasConditions={hasConditions}
      disabled={polling}
      fixed={fixed}
    />
  );
};

export default DownloadEmployeesCsv;
