import { FC } from 'react';
import { SalaryMore as SalaryMoreTemplate } from 'features/questions/templates/SalaryMore';
import { ButtonContainer } from 'features/questions/components/ButtonContainer';
import Button from 'jbc-front/components/Button';
import { useWizard } from '../WizardProvider';

export const SalaryMore: FC = () => {
  const { question, request } = useWizard();

  return (
    <SalaryMoreTemplate question={question}>
      <ButtonContainer>
        <Button huge primary={request.profile?.salaryMoreThanOthers} secondary={!request.profile?.salaryMoreThanOthers} disabled>
          はい
        </Button>
        <Button huge primary={!request.profile?.salaryMoreThanOthers} secondary={request.profile?.salaryMoreThanOthers} disabled>
          いいえ
        </Button>
      </ButtonContainer>
    </SalaryMoreTemplate>
  );
};
