import { gql } from '@apollo/client';
import { Schema } from './schema';

export type QueryVariables = {
  input: {
    employeeId: string;
    attributes: {
      joinedOn: string;
      resignedOn?: string;
      lastName: string;
      firstName: string;
      lastNameKana: string;
      firstNameKana: string;
      birthday: string;
      employmentType?: string;
      position?: string;
      isNonResident: boolean;
      postcode0?: string;
      postcode1?: string;
      prefectureId?: string;
      city?: string;
      street?: string;
      building?: string;
      gender: 'male' | 'female';
      nationalType: 'japanese' | 'foreigner';
      householderRelationship: string;
      householderName?: string;
    };
  };
};

export const convertFormDataToQueryVariables = (employeeId: string, data: Schema): QueryVariables => {
  return {
    input: {
      employeeId,
      attributes: {
        joinedOn: data.joinedOn,
        resignedOn: data.resignedOn || undefined,
        lastName: data.lastName,
        firstName: data.firstName,
        lastNameKana: data.lastNameKana,
        firstNameKana: data.firstNameKana,
        birthday: data.birthday,
        gender: data.gender,
        employmentType: data.employmentType,
        position: data.position,
        isNonResident: data.isNonResident === 'nonResident',
        postcode0: data.postcode0,
        postcode1: data.postcode1,
        prefectureId: data.prefectureId,
        city: data.city,
        street: data.street,
        building: data.building,
        nationalType: data.nationalType,
        householderRelationship: data.householderRelationship,
        householderName: data.householderName
      }
    }
  };
};

export const UPDATE_BASIC_INFOS = gql`
  mutation UpdateBasicInfos($input: UpdateBasicInfosInput!) {
    updateBasicInfos(input: $input) {
      employee {
        id
      }
    }
  }
`;
