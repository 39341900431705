import { FC } from 'react';
import moment from 'moment';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { PlusSquare, DeleteSquare } from 'jbc-front/components/icons';
import { Required } from 'jbc-front/components/presenters/form/Label';
import { Checkbox } from 'jbc-front/components/presenters/form/Checkbox';
import { ErrorMessage } from 'jbc-front/components/presenters/form/ErrorMessage';
import { AnchorTarget } from 'jbc-front/components/ScrollSpy/v2';
import { Section } from '@jbc-year-end-adj/2024/components/ui/Section';
import { FormSection } from '@jbc-year-end-adj/2024/components/form/FormSection';
import { ToggleButton } from '@jbc-year-end-adj/2024/components/form/ToggleButton';
import { FormField } from '@jbc-year-end-adj/2024/components/form/FormField';
import { Label } from '@jbc-year-end-adj/2024/components/form/Label';
import { DateField } from '@jbc-year-end-adj/2024/components/react-hook-form/DateField';
import { SelectField } from '@jbc-year-end-adj/2024/components/react-hook-form/SelectField';
import { Schema } from './schema';
import styles from './Form.scss';

const submissionDeadlineHourOptions = [...Array(24)].map((_, i) => ({ label: `${i}時`, value: `${i}` }));
const reminderHourOptions = [...Array(24)].map((_, i) => ({ label: `${i}`, value: `${i}` }));
const reminderDayOptions = [...Array(31)].map((_, i) => ({ label: `${i}`, value: `${i}` }));

export const SubmissionDeadlineSettingsSection: FC = () => {
  const {
    register,
    watch,
    control,
    formState: { errors }
  } = useFormContext<Schema>();
  const { fields, append, remove } = useFieldArray<Schema>({
    control,
    name: 'reminders'
  });

  const hasSubmissionDeadline = watch('hasSubmissionDeadline');
  const useReminder = watch('useReminder');

  const handleAppend = () => {
    if (fields.length < 3) {
      append({ remindDaysBefore: '1', remindHour: '9' });
    }
  };
  const handleRemove = (index: number) => {
    if (fields.length > 1) {
      remove(index);
    }
  };

  return (
    <Section>
      <AnchorTarget name="submission_deadline_settings" />
      <Section.Header>提出期日設定</Section.Header>
      <Section.Body>
        <FormSection>
          <div>
            <p>年末調整の回答の提出期日を設定できます</p>
            <p>提出期日を設定した場合、提出期日時点で回答ができなくなります。提出期日を未来日で再設定すると再度入力が可能になります。</p>
          </div>

          <ToggleButton.Container>
            <ToggleButton {...register('hasSubmissionDeadline')} value="false">
              提出期日を設定しない
            </ToggleButton>
            <ToggleButton {...register('hasSubmissionDeadline')} value="true">
              提出期日を設定する
            </ToggleButton>
          </ToggleButton.Container>

          {hasSubmissionDeadline === 'true' && (
            <>
              <FormField>
                <FormField.LabelContainer>
                  <Label>
                    年末調整 提出期限
                    <Required />
                  </Label>
                </FormField.LabelContainer>
                <div className={styles.row}>
                  <DateField name="submissionDeadlineDate" error={errors.submissionDeadlineDate?.message} />
                  <SelectField
                    name="submissionDeadlineHour"
                    options={submissionDeadlineHourOptions}
                    placeholder=""
                    error={errors.submissionDeadlineHour?.message}
                  />
                </div>
              </FormField>
              <Checkbox {...register('useReminder')}>リマインドする</Checkbox>
              {errors.reminders?.message && <ErrorMessage>{errors.reminders?.message}</ErrorMessage>}
              {useReminder &&
                fields.map((field, index) => (
                  <ReminderFields key={field.id} index={index} append={handleAppend} remove={() => handleRemove(index)} />
                ))}
            </>
          )}
        </FormSection>
      </Section.Body>
    </Section>
  );
};

type ReminderFieldsProps = {
  index: number;
  append: () => void;
  remove: () => void;
};

const ReminderFields: FC<ReminderFieldsProps> = ({ index, append, remove }) => {
  const {
    watch,
    trigger,
    formState: { errors }
  } = useFormContext<Schema>();
  const remindDaysBefore = watch(`reminders.${index}.remindDaysBefore`);
  const submissionDeadlineDate = watch(`submissionDeadlineDate`);

  return (
    <div className={styles.reminderRow}>
      提出日時
      <div className={styles.reminderSelect}>
        <SelectField
          options={reminderDayOptions}
          name={`reminders.${index}.remindDaysBefore`}
          error={errors.reminders?.[index]?.remindDaysBefore?.message}
          onChange={_ => trigger('reminders')}
        />
      </div>
      日前
      {submissionDeadlineDate &&
        remindDaysBefore &&
        `(${moment(submissionDeadlineDate)
          .subtract(remindDaysBefore, 'days')
          .format('MM/DD')})`}
      <div className={styles.reminderSelect}>
        <SelectField
          options={reminderHourOptions}
          name={`reminders.${index}.remindHour`}
          error={errors.reminders?.[index]?.remindHour?.message}
          onChange={_ => trigger('reminders')}
        />
      </div>
      時に送信
      <div className={styles.reminderIcons}>
        <DeleteSquare
          size={20}
          role="button"
          tabIndex={0}
          onClick={remove}
          onKeyDown={event => {
            if (event.key === 'Enter' || event.key === ' ') {
              event.preventDefault();
              remove();
            }
          }}
        />
        {index === 0 && (
          <div
            role="button"
            tabIndex={0}
            onClick={append}
            onKeyDown={event => {
              if (event.key === 'Enter' || event.key === ' ') {
                event.preventDefault();
                append();
              }
            }}
          >
            <PlusSquare size={20} />
            追加
          </div>
        )}
      </div>
    </div>
  );
};
