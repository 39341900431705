import { yup } from '@jbc-year-end-adj/common/yup';
import { OtherFamily, Income } from '../../../../query';
import { dateFormFormat, amountFormat, fileSchemaFormat } from '@jbc-year-end-adj/common/utils/formatter';
import { Gender, ResidenceStatus, HandicapType, FileType } from '@jbc-year-end-adj/types';

export type Schema = {
  otherFamilies: OtherFamilySchema[];
};

type OtherFamilySchema = {
  id?: string;
  lastName: string;
  firstName: string;
  lastNameKana: string;
  firstNameKana: string;
  birthday: string;
  gender: Gender;
  relationOther: string;
  thisYear: YearlyInfoSchema;
  nextYear: YearlyInfoSchema;
  dependentFrom?: string;
  dependentReason?: string;
  diedOn?: string;
  hasOtherFamilyIncomeDetail: boolean;
};

export type YearlyInfoSchema = {
  dependentTaxLaw?: 'true' | 'false' | null;
  incomeAdjustmentDeduction?: 'true' | 'false';
  yearlyInfo: {
    residenceStatus: ResidenceStatus;
    postcode0?: string;
    postcode1?: string;
    prefectureId?: string;
    city?: string;
    street?: string;
    building?: string;
    addressForeign?: string;
    isNonResident?: boolean;
    isStudyAbroad?: boolean;
    remittance?: string;
    relatedToRelativesDocument: File | FileType | null;
    relatedToRemittanceDocument: File | FileType | null;
    provingStudyAbroadDocument: File | FileType | null;
    handicapType: HandicapType;
    handicapDetail?: string;
    handicapImage: File | FileType | null;
    income?: IncomeSchema;
    earnings?: string;
  };
};

export type IncomeSchema = {
  salaryIncome?: string;
  businessIncome?: string;
  businessExpense?: string;
  miscellaneousIncomeOfficialPension?: string;
  miscellaneousIncomeOther?: string;
  miscellaneousExpense?: string;
  dividendIncome?: string;
  dividendExpense?: string;
  realEstateIncome?: string;
  realEstateExpense?: string;
  retirementIncome?: string;
  lengthOfService?: string;
  retireForDisability?: boolean;
  isOfficerRetirementAllowance?: boolean;
  otherIncome?: string;
  otherExpense?: string;
  otherExpenseSpecialDeduction?: string;
  otherEarings?: string;
};

const isDifferentAddress = (residenceStatus: ResidenceStatus) => residenceStatus === 'different_address';
const hasYearlyInfo = (dependentTaxLaw: 'true' | 'false', incomeAdjustmentDeduction: 'true' | 'false') => {
  return dependentTaxLaw === 'true' || incomeAdjustmentDeduction === 'true';
};

const thisYearSchema = yup.object({
  dependentTaxLaw: yup.mixed<'true' | 'false'>().required(),
  incomeAdjustmentDeduction: yup.mixed<'true' | 'false'>().when(['dependentTaxLaw'], {
    is: (dependentTaxLaw: 'true' | 'false') => dependentTaxLaw === 'false',
    then: schema => schema.required(),
    otherwise: schema => schema.nullable()
  }),
  yearlyInfo: yup.object().when(['dependentTaxLaw', 'incomeAdjustmentDeduction'], {
    is: hasYearlyInfo,
    then: schema =>
      schema.shape({
        residenceStatus: yup
          .mixed<ResidenceStatus>()
          .required()
          .oneOf(['same_address', 'different_address', 'different_and_foreign_address'])
          .label('同居・別居'),
        postcode0: yup.string().when(['residenceStatus'], {
          is: isDifferentAddress,
          then: schema =>
            schema
              .required()
              .numberFormat()
              .max(3, '郵便番号1は3桁で入力してください')
              .label('郵便番号1'),
          otherwise: schema => schema.nullable()
        }),
        postcode1: yup.string().when(['residenceStatus'], {
          is: isDifferentAddress,
          then: schema =>
            schema
              .required()
              .numberFormat()
              .max(4, '郵便番号2は4桁で入力してください')
              .label('郵便番号2'),
          otherwise: schema => schema.nullable()
        }),
        prefectureId: yup.string().when(['residenceStatus'], {
          is: isDifferentAddress,
          then: schema => schema.required().label('都道府県'),
          otherwise: schema => schema.nullable()
        }),
        city: yup.string().when(['residenceStatus'], {
          is: isDifferentAddress,
          then: schema => schema.required().label('市区町村'),
          otherwise: schema => schema.nullable()
        }),
        street: yup.string().nullable(),
        building: yup.string().nullable(),
        addressForeign: yup.string().nullable(),
        isNonResident: yup.boolean().nullable(),
        isStudyAbroad: yup.boolean().nullable(),
        remittance: yup
          .string()
          .positiveAmountFormat()
          .maxAmount(999999999)
          .nullable()
          .label('国外居住親族への送金額'),
        relatedToRelativesDocument: yup.mixed<File>().nullable(),
        relatedToRemittanceDocument: yup.mixed<File>().nullable(),
        provingStudyAbroadDocument: yup.mixed<File>().nullable(),
        handicapType: yup
          .mixed<HandicapType>()
          .oneOf(['none', 'normal', 'special'])
          .label('障害者区分'),
        handicapDetail: yup.string().nullable(),
        handicapImage: yup.mixed<File>().nullable(),
        earnings: yup
          .string()
          .positiveAmountFormat()
          .maxAmount(480000)
          .nullable()
          .label('所得見積額'),
        income: yup.object({
          salaryIncome: yup
            .string()
            .positiveAmountFormat()
            .maxAmount(999999999)
            .required()
            .label('給与所得 収入金額'),
          businessIncome: yup
            .string()
            .positiveAmountFormat()
            .maxAmount(999999999)
            .nullable()
            .label('事業所得 収入金額'),
          businessExpense: yup
            .string()
            .positiveAmountFormat()
            .maxAmount(999999999)
            .nullable()
            .label('事業所得 必要経費'),
          miscellaneousIncomeOfficialPension: yup
            .string()
            .positiveAmountFormat()
            .maxAmount(999999999)
            .nullable()
            .label('雑所得 収入金額（公的年金等に係る雑所得）'),
          miscellaneousIncomeOther: yup
            .string()
            .positiveAmountFormat()
            .maxAmount(999999999)
            .nullable()
            .label('雑所得 収入金額（公的年金等以外の雑所得）'),
          miscellaneousExpense: yup
            .string()
            .positiveAmountFormat()
            .maxAmount(999999999)
            .nullable()
            .label('雑所得 必要経費'),
          dividendIncome: yup
            .string()
            .positiveAmountFormat()
            .maxAmount(999999999)
            .nullable()
            .label('配当所得 収入金額'),
          dividendExpense: yup
            .string()
            .positiveAmountFormat()
            .maxAmount(999999999)
            .nullable()
            .label('配当所得 必要経費'),
          realEstateIncome: yup
            .string()
            .positiveAmountFormat()
            .maxAmount(999999999)
            .nullable()
            .label('不動産所得 収入金額'),
          realEstateExpense: yup
            .string()
            .positiveAmountFormat()
            .maxAmount(999999999)
            .nullable()
            .label('不動産所得 必要経費'),
          retirementIncome: yup
            .string()
            .positiveAmountFormat()
            .maxAmount(999999999)
            .nullable()
            .label('退職所得 収入金額'),
          lengthOfService: yup
            .string()
            .numberFormat()
            .nullable()
            .label('勤続年数'),
          retireForDisablity: yup.boolean(),
          isOfficerRetirementAllowance: yup.boolean(),
          otherIncome: yup
            .string()
            .positiveAmountFormat()
            .maxAmount(999999999)
            .nullable()
            .label('その他所得 収入金額'),
          otherExpense: yup
            .string()
            .positiveAmountFormat()
            .maxAmount(999999999)
            .nullable()
            .label('その他所得 必要経費'),
          otherExpenseSpecialDeduction: yup
            .string()
            .maxAmount(999999999)
            .nullable()
            .label('その他所得 必要経費 うち特別控除額'),
          otherEarings: yup
            .string()
            .maxAmount(999999999)
            .nullable()
            .label('その他所得 所得金額')
        })
      }),
    otherwise: schema => schema.nullable()
  })
});

const nextYearSchema = yup.object({
  dependentTaxLaw: yup
    .mixed<'true' | 'false'>()
    .required()
    .label('来年の税の扶養対象'),
  yearlyInfo: yup.object().when(['dependentTaxLaw'], {
    is: (dependentTaxLaw: 'true' | 'false') => dependentTaxLaw === 'true',
    then: schema =>
      schema.shape({
        residenceStatus: yup
          .mixed<ResidenceStatus>()
          .required()
          .oneOf(['same_address', 'different_address', 'different_and_foreign_address'])
          .label('同居・別居'),
        postcode0: yup.string().when(['residenceStatus'], {
          is: isDifferentAddress,
          then: schema =>
            schema
              .required()
              .numberFormat()
              .max(3, '郵便番号1は3桁で入力してください')
              .label('郵便番号1'),
          otherwise: schema => schema.nullable()
        }),
        postcode1: yup.string().when(['residenceStatus'], {
          is: isDifferentAddress,
          then: schema =>
            schema
              .required()
              .numberFormat()
              .max(4, '郵便番号2は4桁で入力してください')
              .label('郵便番号2'),
          otherwise: schema => schema.nullable()
        }),
        prefectureId: yup.string().when(['residenceStatus'], {
          is: isDifferentAddress,
          then: schema => schema.required().label('都道府県'),
          otherwise: schema => schema.nullable()
        }),
        city: yup.string().when(['residenceStatus'], {
          is: isDifferentAddress,
          then: schema => schema.required().label('市区町村'),
          otherwise: schema => schema.nullable()
        }),
        street: yup.string().nullable(),
        building: yup.string().nullable(),
        addressForeign: yup.string().nullable(),
        isNonResident: yup.boolean().nullable(),
        isStudyAbroad: yup.boolean().nullable(),
        remittance: yup
          .string()
          .positiveAmountFormat()
          .maxAmount(999999999)
          .nullable()
          .label('国外居住親族への送金額'),
        relatedToRelativesDocument: yup.mixed<File>().nullable(),
        relatedToRemittanceDocument: yup.mixed<File>().nullable(),
        provingStudyAbroadDocument: yup.mixed<File>().nullable(),
        handicapType: yup
          .mixed<HandicapType>()
          .oneOf(['none', 'normal', 'special'])
          .label('障害者区分'),
        handicapDetail: yup.string().nullable(),
        handicapImage: yup.mixed<File>().nullable(),
        earnings: yup
          .string()
          .positiveAmountFormat()
          .maxAmount(480000)
          .nullable()
          .label('所得見積額')
      })
  })
});

const otherFamilySchema = yup.object({
  lastName: yup
    .string()
    .required()
    .label('姓'),
  firstName: yup
    .string()
    .required()
    .label('名'),
  lastNameKana: yup
    .string()
    .required()
    .kana()
    .label('姓（カナ）'),
  firstNameKana: yup
    .string()
    .required()
    .kana()
    .label('姓（カナ）'),
  birthday: yup
    .string()
    .required()
    .dateFormat()
    .label('生年月日'),
  gender: yup
    .mixed<Gender>()
    .required()
    .oneOf(['male', 'female'])
    .label('性別'),
  relationOther: yup
    .string()
    .required()
    .label('続柄'),
  thisYear: thisYearSchema,
  nextYear: nextYearSchema,
  dependentFrom: yup
    .string()
    .dateFormat()
    .nullable()
    .label('税法上の扶養家族になった日'),
  dependentReason: yup.string().nullable(),
  diedOn: yup
    .string()
    .dateFormat()
    .nullable()
    .label('死亡日'),
  hasOtherFamilyIncomeDetail: yup.boolean()
});

export const schema = yup.object({
  otherFamilies: yup.array().of(otherFamilySchema)
});

export const generateDefaultValues = (otherFamilies?: OtherFamily[]): Schema => {
  return {
    otherFamilies:
      otherFamilies?.map(otherFamily => ({
        id: otherFamily.id ? String(otherFamily.id) : undefined,
        lastName: otherFamily.lastName || '',
        firstName: otherFamily.firstName || '',
        lastNameKana: otherFamily.lastNameKana || '',
        firstNameKana: otherFamily.firstNameKana || '',
        birthday: dateFormFormat(otherFamily.birthday, 'L'),
        gender: otherFamily.gender || 'male',
        relationOther: otherFamily.relationOther || '',
        thisYear: {
          dependentTaxLaw: otherFamily.thisYear?.dependentTaxLaw ? 'true' : 'false',
          incomeAdjustmentDeduction: otherFamily.thisYear?.incomeAdjustmentDeduction ? 'true' : 'false',
          yearlyInfo: {
            residenceStatus: otherFamily.thisYear?.residenceStatus || 'same_address',
            postcode0: otherFamily.thisYear?.postcode0,
            postcode1: otherFamily.thisYear?.postcode1,
            prefectureId: otherFamily.thisYear?.prefectureId,
            city: otherFamily.thisYear?.city,
            street: otherFamily.thisYear?.street,
            building: otherFamily.thisYear?.building,
            addressForeign: otherFamily.thisYear?.addressForeign,
            isNonResident: otherFamily.thisYear?.isNonResident,
            isStudyAbroad: otherFamily.thisYear?.isStudyAbroad,
            remittance: amountFormat(otherFamily.thisYear?.remittance),
            relatedToRelativesDocument: fileSchemaFormat(otherFamily.thisYear?.relatedToRelativesDocument),
            relatedToRemittanceDocument: fileSchemaFormat(otherFamily.thisYear?.relatedToRemittanceDocument),
            provingStudyAbroadDocument: fileSchemaFormat(otherFamily.thisYear?.provingStudyAbroadDocument),
            handicapType: otherFamily.thisYear?.handicapType || 'none',
            handicapDetail: otherFamily.thisYear?.handicapDetail,
            handicapImage: fileSchemaFormat(otherFamily.thisYear?.handicapImage),
            earnings: amountFormat(otherFamily.thisYear?.earnings),
            income: {
              salaryIncome: amountFormat(otherFamily.income?.salaryIncome),
              businessIncome: amountFormat(otherFamily.income?.businessIncome),
              businessExpense: amountFormat(otherFamily.income?.businessExpense),
              miscellaneousIncomeOfficialPension: amountFormat(otherFamily.income?.miscellaneousIncomeOfficalPension),
              miscellaneousIncomeOther: amountFormat(otherFamily.income?.miscellaneousIncomeOther),
              miscellaneousExpense: amountFormat(otherFamily.income?.miscellaneousExpense),
              dividendIncome: amountFormat(otherFamily.income?.dividendIncome),
              dividendExpense: amountFormat(otherFamily.income?.dividendExpense),
              realEstateIncome: amountFormat(otherFamily.income?.realEstateIncome),
              realEstateExpense: amountFormat(otherFamily.income?.realEstateExpense),
              retirementIncome: amountFormat(otherFamily.income?.retirementIncome),
              lengthOfService: otherFamily.income?.lengthOfService,
              retireForDisablity: otherFamily.income?.retireForDisablity || false,
              isOfficerRetirementAllowance: otherFamily.income?.isOfficerRetirementAllowance || false,
              otherIncome: amountFormat(otherFamily.income?.otherIncome),
              otherExpense: amountFormat(otherFamily.income?.otherExpense),
              otherExpenseSpecialDeduction: amountFormat(otherFamily.income?.otherExpenseSpecialDeduction),
              otherEarings: amountFormat(otherFamily.income?.otherEarings)
            }
          }
        },
        nextYear: {
          dependentTaxLaw: dependentTaxLawValue(otherFamily.nextYear?.dependentTaxLaw),
          yearlyInfo: {
            residenceStatus: otherFamily.nextYear?.residenceStatus || 'same_address',
            postcode0: otherFamily.nextYear?.postcode0,
            postcode1: otherFamily.nextYear?.postcode1,
            prefectureId: otherFamily.nextYear?.prefectureId,
            city: otherFamily.nextYear?.city,
            street: otherFamily.nextYear?.street,
            building: otherFamily.nextYear?.building,
            addressForeign: otherFamily.nextYear?.addressForeign,
            isNonResident: otherFamily.nextYear?.isNonResident,
            isStudyAbroad: otherFamily.nextYear?.isStudyAbroad,
            remittance: amountFormat(otherFamily.nextYear?.remittance),
            relatedToRelativesDocument: fileSchemaFormat(otherFamily.nextYear?.relatedToRelativesDocument),
            relatedToRemittanceDocument: fileSchemaFormat(otherFamily.nextYear?.relatedToRemittanceDocument),
            provingStudyAbroadDocument: fileSchemaFormat(otherFamily.nextYear?.provingStudyAbroadDocument),
            handicapType: otherFamily.nextYear?.handicapType || 'none',
            handicapDetail: otherFamily.nextYear?.handicapDetail,
            handicapImage: fileSchemaFormat(otherFamily.nextYear?.handicapImage),
            earnings: amountFormat(otherFamily.nextYear?.earnings)
          }
        },
        dependentFrom: dateFormFormat(otherFamily.dependentFrom, 'L'),
        dependentReason: otherFamily.dependentReason,
        diedOn: dateFormFormat(otherFamily.diedOn, 'L'),
        hasOtherFamilyIncomeDetail: checkOtherFamilyIncomeDetail(otherFamily.income)
      })) || []
  };
};

const dependentTaxLawValue = (dependentTaxLaw?: boolean) => {
  if (dependentTaxLaw === undefined) return undefined;
  return dependentTaxLaw ? 'true' : 'false';
};

export const otherFamilyObj: OtherFamilySchema = {
  lastName: '',
  firstName: '',
  lastNameKana: '',
  firstNameKana: '',
  birthday: '',
  gender: 'male',
  relationOther: '',
  thisYear: {
    dependentTaxLaw: 'false',
    incomeAdjustmentDeduction: 'false',
    yearlyInfo: {
      residenceStatus: 'same_address',
      postcode0: '',
      postcode1: '',
      prefectureId: '',
      city: '',
      street: '',
      building: '',
      addressForeign: '',
      isNonResident: false,
      isStudyAbroad: false,
      remittance: '',
      relatedToRelativesDocument: null,
      relatedToRemittanceDocument: null,
      provingStudyAbroadDocument: null,
      handicapType: 'none',
      handicapDetail: '',
      handicapImage: null,
      earnings: '',
      income: {
        salaryIncome: '',
        businessIncome: '',
        businessExpense: '',
        miscellaneousIncomeOfficialPension: '',
        miscellaneousIncomeOther: '',
        miscellaneousExpense: '',
        dividendIncome: '',
        dividendExpense: '',
        realEstateIncome: '',
        realEstateExpense: '',
        retirementIncome: '',
        lengthOfService: '',
        retireForDisability: false,
        isOfficerRetirementAllowance: false,
        otherIncome: '',
        otherExpense: '',
        otherExpenseSpecialDeduction: '',
        otherEarings: ''
      }
    }
  },
  nextYear: {
    dependentTaxLaw: null,
    yearlyInfo: {
      residenceStatus: 'same_address',
      postcode0: '',
      postcode1: '',
      prefectureId: '',
      city: '',
      street: '',
      building: '',
      addressForeign: '',
      isNonResident: false,
      isStudyAbroad: false,
      remittance: '',
      relatedToRelativesDocument: null,
      relatedToRemittanceDocument: null,
      provingStudyAbroadDocument: null,
      handicapType: 'none',
      handicapDetail: '',
      handicapImage: null,
      earnings: ''
    }
  },
  dependentFrom: '',
  dependentReason: '',
  diedOn: '',
  hasOtherFamilyIncomeDetail: false
};

const checkOtherFamilyIncomeDetail = (income?: Income): boolean => {
  if (!income) return false;

  const {
    businessIncome,
    businessExpense,
    dividendIncome,
    dividendExpense,
    realEstateIncome,
    realEstateExpense,
    miscellaneousIncomeOfficalPension,
    miscellaneousIncomeOther,
    miscellaneousExpense,
    retirementIncome,
    otherIncome,
    otherExpenseSpecialDeduction,
    otherExpense,
    otherEarings
  } = income;

  return !!(
    (businessIncome && businessIncome !== '0') ||
    (businessExpense && businessExpense !== '0') ||
    (dividendIncome && dividendIncome !== '0') ||
    (dividendExpense && dividendExpense !== '0') ||
    (realEstateIncome && realEstateIncome !== '0') ||
    (realEstateExpense && realEstateExpense !== '0') ||
    (miscellaneousIncomeOfficalPension && miscellaneousIncomeOfficalPension !== '0') ||
    (miscellaneousIncomeOther && miscellaneousIncomeOther !== '0') ||
    (miscellaneousExpense && miscellaneousExpense !== '0') ||
    (retirementIncome && retirementIncome !== '0') ||
    (otherIncome && otherIncome !== '0') ||
    (otherExpenseSpecialDeduction && otherExpenseSpecialDeduction !== '0') ||
    (otherExpense && otherExpense !== '0') ||
    (otherEarings && otherEarings !== '0')
  );
};
