import { FC, ReactNode } from 'react';
import { Section } from '@jbc-year-end-adj/2024/components/ui/Section';

import { Question } from '../types';

import { Lead } from './parts/Lead';
import { Description } from './parts/Description';
import { Content as ContentContainer } from './parts/Content';
import { findDescriptions } from '../utils';

type SalaryMoreProps = {
  officeName?: string;
  question: Question;
  children: ReactNode;
};

export const SalaryMore: FC<SalaryMoreProps> = ({ officeName, question, children }) => {
  return (
    <Section>
      <Section.Header>{TITLE}</Section.Header>

      <Section.Body>
        <Content officeName={officeName} question={question}>
          {children}
        </Content>
      </Section.Body>
    </Section>
  );
};

export const TITLE = '今年の給与';

type ContentProps = {
  officeName?: string;
  question: Question;
  children: ReactNode;
};

export const Content: FC<ContentProps> = ({ officeName, question, children }) => {
  const questionDescriptions = findDescriptions(question, ['top']);

  return (
    <ContentContainer>
      <Lead>お給料は{officeName || 'こちらの会社'}の方が高いですか？</Lead>

      <Description>{questionDescriptions['top']?.description}</Description>

      {children}
    </ContentContainer>
  );
};
