import React from 'react';
import { gql } from '@apollo/client';
import moment from 'moment';
import Button from 'jbc-front/components/Button';
import { useNotify } from '../actions';
import { useMutation, useYear, Modal, convertSelectedIds, ASYNC_TASK_FRAGMENT } from '../components';
import { MIN_REQUEST_COUNT, MAX_REQUEST_COUNT } from '../pages/WithholdingSlipDeliver';
import styles from '../city_report/Report.scss';

const DELIVER_WITHHOLDING_SLIPS = gql`
  mutation deliverWithholdingSlips($input: DeliverWithholdingSlipsInput!) {
    deliverWithholdingSlips(input: $input) {
      withholdingSlips {
        id
        withholdingSlipDeliver {
          id
          status
          sentAt
        }
      }
    }
  }
`;

const DELIVER_WITHHOLDING_SLIPS_ASYNC = gql`
  mutation deliverWithholdingSlipsAsync($input: DeliverWithholdingSlipsAsyncInput!) {
    deliverWithholdingSlipsAsync(input: $input) {
      clientYearly {
        id
        sendWithholdingSlipForEmployeesRunning
        withholdingSlipDeliverRunning
        asyncTask {
          ...AsyncTaskFields
        }
      }
    }
  }
  ${ASYNC_TASK_FRAGMENT}
`;

const convertDeliverMutation = (selectedTotalCount, mutation, asyncMutation) => ({
  deliverMutation: selectedTotalCount > MAX_REQUEST_COUNT ? asyncMutation : mutation,
  message: selectedTotalCount > MIN_REQUEST_COUNT ? 'メール一括発送を開始しました' : 'メール発送を開始しました'
});

const makeDisabledReason = (isPreparing, isDelivering, loading) => {
  if (isPreparing) {
    return '源泉徴収票交付の準備を行っているため操作できません';
  }

  if (isDelivering) {
    return 'メール発送処理中のため操作できません';
  }

  if (loading) {
    return null;
  }

  return '従業員のメールアドレスが未入力のため発送できません';
};

const EmailModal = ({ children, selectedIds, allSelectMode = false, selectedTotalCount, isNotSelected, reset }) => {
  const year = useYear();
  const notify = useNotify();
  const [deliverWithholdingSlips, { loading: loadingDeliver }] = useMutation(DELIVER_WITHHOLDING_SLIPS);
  const [deliverWithholdingSlipsAsync, { loading: loadingDeliverAsync }] = useMutation(DELIVER_WITHHOLDING_SLIPS_ASYNC);
  const loading = loadingDeliver || loadingDeliverAsync;

  return (
    <Modal>
      {({ showModal, hideModal }) => (
        <>
          {children({ showModal, loading })}
          <Modal.Modal>
            <Modal.Header>源泉徴収票メールを送る</Modal.Header>
            <Modal.Body>
              選択された従業員に、源泉徴収票を送信します。
              <br />
              源泉徴収票は暗号化され、暗号は別途メールにて送信します。
              <br />
              <br />
              ※再送しますと画面に表示される送信日時が更新されますのでご注意ください。
              <br />
              ※選択した従業員のメールアドレスが未入力の場合は送信されません。
            </Modal.Body>
            <Modal.Footer>
              <Modal.Buttons>
                <Button onClick={hideModal} disabled={loading}>
                  キャンセル
                </Button>
                <Button
                  primary
                  onClick={async () => {
                    if (isNotSelected) {
                      return notify('従業員を選択してください', 'error');
                    }
                    const { deliverMutation, message } = convertDeliverMutation(
                      selectedTotalCount,
                      deliverWithholdingSlips,
                      deliverWithholdingSlipsAsync
                    );
                    const { employeeIds, exceptEmployeeIds } = convertSelectedIds(allSelectMode, selectedIds);
                    await deliverMutation({
                      variables: {
                        input: { year, allSelectMode, employeeIds, exceptEmployeeIds }
                      }
                    });
                    notify(message);
                    hideModal();
                    if (reset) {
                      reset();
                    }
                  }}
                  disabled={loading}
                >
                  送信
                </Button>
              </Modal.Buttons>
            </Modal.Footer>
          </Modal.Modal>
        </>
      )}
    </Modal>
  );
};

export const EmailButton = ({ withholdingSlipDeliver, employeeId, hasNoEmail, isPreparing, isDelivering, buttonStyle }) => (
  <>
    {withholdingSlipDeliver?.status === 'success' && (
      <>
        <div>発送済</div>
        <div className={styles.date}>{moment(withholdingSlipDeliver.sentAt).format('YYYY/MM/DD HH:mm')}</div>
      </>
    )}
    {!withholdingSlipDeliver && '未発送'}
    {withholdingSlipDeliver?.status === 'failed' && 'エラー'}
    {(withholdingSlipDeliver?.status === 'waiting' || withholdingSlipDeliver?.status === 'in_progress') && '発送中'}
    <EmailModal selectedIds={[employeeId]}>
      {({ showModal, loading }) => (
        <Button
          className={buttonStyle}
          widthAuto
          onClick={showModal}
          disabled={loading || isPreparing || isDelivering || hasNoEmail}
          disabledReason={makeDisabledReason(isPreparing, isDelivering, loading)}
        >
          {withholdingSlipDeliver?.status ? '再発送する' : '発送する'}
        </Button>
      )}
    </EmailModal>
  </>
);

export default EmailModal;
