import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { Section } from 'components/ui/Section';
import { FormSection } from 'components/form/FormSection';
import { Input } from 'components/form/Input';
import { DateField } from 'components/react-hook-form/DateField';
import { Radio } from 'components/form/Radio';
import { Schema } from '../schema';
import { Grid } from '../../../../../components/Grid';
import { FormSeparator } from '../../../../../components/FormSeparator';

export const Spouse: FC = () => {
  const {
    formState: { errors },
    register
  } = useFormContext<Schema>();

  return (
    <Section>
      <Section.Header>配偶者の基本情報</Section.Header>
      <Section.Body>
        <FormSection>
          <Grid colGap>
            <Input
              label="姓"
              required
              error={errors.spouse?.lastName?.message}
              isError={!!errors.spouse?.lastName?.message}
              {...register('spouse.lastName')}
            />
            <Input
              label="名"
              required
              error={errors.spouse?.firstName?.message}
              isError={!!errors.spouse?.firstName?.message}
              {...register('spouse.firstName')}
            />
          </Grid>
          <Grid colGap>
            <Input
              label="姓（カナ）"
              required
              error={errors.spouse?.lastNameKana?.message}
              isError={!!errors.spouse?.lastNameKana?.message}
              {...register('spouse.lastNameKana')}
            />
            <Input
              label="名（カナ）"
              required
              error={errors.spouse?.firstNameKana?.message}
              isError={!!errors.spouse?.firstNameKana?.message}
              {...register('spouse.firstNameKana')}
            />
          </Grid>

          <FormSeparator />

          <Grid colGap>
            <DateField
              label="生年月日"
              required
              note="例）1981/01/23、S56.1.23"
              name="spouse.birthday"
              error={errors.spouse?.birthday?.message}
            />

            <Radio.Container label="性別" required error={errors.spouse?.gender?.message}>
              <Radio value="male" {...register('spouse.gender')}>
                男性
              </Radio>
              <Radio value="female" {...register('spouse.gender')}>
                女性
              </Radio>
            </Radio.Container>
          </Grid>
        </FormSection>
      </Section.Body>
    </Section>
  );
};
