import { FC, ComponentProps } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { CreatableSelectField as UnControlledField } from 'components/form/SelectField';
import { Option, useCreatableOption } from 'jbc-front/components/presenters/form/Select';

type CreatableSelectFieldProps = ComponentProps<typeof UnControlledField> & {
  name: string;
};

export const CreatableSelectField: FC<CreatableSelectFieldProps> = ({ options: defaultOptions, name, ...creatableSelectProps }) => {
  const { control } = useFormContext();

  const { field } = useController({
    name,
    control
  });

  const handleChange = (option: unknown) => {
    const selectedOption = option as Option;
    field.onChange(selectedOption.value);
  };

  const handleBlur = () => {
    field.onBlur();
  };

  const handleCreateOption = (inputValue: string) => {
    onCreateOption(inputValue);
    field.onChange(inputValue);
  };

  const { options, onCreateOption } = useCreatableOption(defaultOptions);

  return (
    <UnControlledField
      {...creatableSelectProps}
      options={options}
      onChange={handleChange}
      onBlur={handleBlur}
      onCreateOption={handleCreateOption}
      value={options.find((option: Option) => option.value === field.value)}
    />
  );
};
