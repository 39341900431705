import { useEffect, useState } from 'react';
import { usePrefectures } from './usePrefectures';

export const usePrefectureOptions = () => {
  const { prefectures, loading } = usePrefectures();

  const [prefectureOptions, setPrefectureOptions] = useState<{ label: string; value: string }[]>([]);

  useEffect(() => {
    if (!loading && prefectures && prefectures.length > 0) {
      const options = prefectures.map(prefecture => {
        return { label: prefecture.name, value: String(prefecture.id) };
      });

      setPrefectureOptions(options);
    }
  }, [loading, prefectures]);

  return { prefectureOptions };
};
