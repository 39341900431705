import React from 'react'
import Icon from 'react-icon-base'

const TalentManagementLogo = props => (
  <Icon viewBox="0 0 371 50" {...props}>

    <g>
      <g>
        <path fill='#007CFF' d="M67.46,78.42c-19.88-2.41-44.87-4.4-62.62-4.97v-23c18.74,0.57,44.16,2.41,62.62,4.69V78.42z M69.02,39.23
				c-19.03-2.13-45.58-4.4-62.62-4.97V11.11c18.03,0.71,42.88,2.41,62.62,4.69V39.23z M7.68,104.69
				c56.94-1.28,84.06-19.17,88.75-73.84h26.27c-4.69,73.27-48.42,97.84-115.02,98.12V104.69z M87.62,26.31V7.14h14.62v19.17H87.62z
				 M106.51,26.31V7.14h14.77v19.17H106.51z"/>
        <path fill='#007CFF' d="M153.51,128.41v-21.87h71.99V87.79h-70.29V66.07h70.29V50.31h-71.85V28.3h96.56v100.25L153.51,128.41z"/>
        <path fill='#007CFF' d="M397.04,20.91c-1.85,72.99-45.16,104.51-98.83,108.77v-22.86c41.18-6.25,66.46-30.67,70.86-70.29h-85.62
				V12.96h92.3V1.88h14.77v11.08h3.98V1.88h14.63v19.03H397.04z"/>
        <path fill='#007CFF' d="M481.94,128.97v-23.15h19.88c9.23,0,12.64-3.55,12.64-12.5V51.44h-31.38c-3.27,44.3-29.25,70.15-58.5,78.81
				v-24.71c18.03-9.94,32.09-27.55,33.23-54.1h-33.23v-23h33.37V8.13h25.56v20.31h57.23v66.6c0,24-10.37,33.94-32.94,33.94H481.94z"
        />
        <path fill='#007CFF' d="M632.04,45.05c-16.33-3.27-43.74-6.82-60.92-7.67V12.68c18.6,0.99,42.88,3.98,60.92,7.1V45.05z
				 M573.25,104.41c50.69-3.55,81.37-17.47,85.48-71.85h26.7c-4.12,68.3-43.03,94.86-112.18,96.28V104.41z"/>
      </g>

      <g>
        <path fill='#333333' d="M748.42,129.99c-7.44-3.24-20.58-12.66-25.62-30.36c-2.28,11.7-9.3,22.68-25.98,30.54l-1.56-2.4
				c19.44-7.98,26.16-23.16,26.16-35.52V76.18h2.4v16.5c1.92,18.48,15.54,30.66,25.98,34.74L748.42,129.99z"/>
        <path fill='#333333' d="M774.34,130.17V91.36c-2.52,10.02-5.52,16.26-11.28,22.8l-1.56-1.86c5.88-6.18,9.3-14.64,12.3-22.8h-10.14
				v-2.04h10.68V76.18h2.16v11.28h10.38v2.04h-10.02c3.3,6.9,5.58,10.92,9.9,16.5l-1.26,1.92c-4.68-6.6-6.66-11.04-9-16.8v39.06
				H774.34z M804.94,89.62h-0.48c-3.24,11.94-8.16,22.26-18.78,31.02l-1.5-1.86c10.62-8.82,15.78-19.38,18.3-29.16h-13.44v-2.16
				h15.9V76.18h2.16v11.28h8.4v2.16h-8.4v33.06c0,6.18-1.86,7.38-11.94,7.38l-0.84-2.28c10.32,0,10.62-0.84,10.62-5.1V89.62z"/>
        <path fill='#333333' d="M847.96,88.96c0,0-1.56-3.66-3.72-7.02h-6.66c-3.72,4.86-7.56,7.68-7.56,7.68l-1.56-1.56
				c0,0,6.78-5.1,10.32-11.94l2.22,0.6c-0.6,1.08-1.2,2.1-1.86,3.06h16.5v2.16h-8.94c1.68,3.12,3.12,5.94,3.12,5.94L847.96,88.96z
				 M877,102.93V94.9h-45v8.04h-2.16v-10.2h23.52v-3.96h2.16v3.96h23.64v10.2H877z M872.32,130.17v-3.18h-33.54v3.18h-2.16v-30.6
				h35.22v10.62h-33.06v4.62h35.7v15.36H872.32z M869.68,101.73h-30.9v6.3h30.9V101.73z M872.32,116.97h-33.54v7.86h33.54V116.97z
				 M870.76,81.94c1.56,2.82,3.12,5.94,3.12,5.94l-1.8,1.08c0,0-1.8-3.84-3.78-7.02h-7.26c-3.24,4.14-6.18,6.18-6.18,6.18
				l-1.44-1.56c0,0,5.34-3.48,8.82-10.44l2.22,0.6c-0.6,1.08-1.2,2.1-1.86,3.06h17.94v2.16H870.76z"/>
        <path fill='#333333' d="M913.48,117.75c0,0-7.62,2.88-19.32,5.7l-0.66-2.28c3.72-0.78,6.96-1.62,9.72-2.34v-18.12H895v-2.16h8.22
				V82.6h-8.76v-2.16h19.32v2.16h-8.4v15.96H913v2.16h-7.62v17.52c5.22-1.62,8.1-2.82,8.1-2.82V117.75z M913.66,127.29v-2.16h16.26
				v-9.78h-14.16v-2.16h14.16v-8.34h-12.9V78.58h27.96v26.28h-12.9v8.34h13.62v2.16h-13.62v9.78h15.42v2.16H913.66z M929.92,80.74
				h-10.74v9.9h10.74V80.74z M929.92,92.79h-10.74v9.9h10.74V92.79z M942.82,80.74h-10.74v9.9h10.74V80.74z M942.82,92.79h-10.74
				v9.9h10.74V92.79z"/>
      </g>
    </g>
  </Icon>
)
TalentManagementLogo.defaultProps = {
  size: 160
}

export default TalentManagementLogo

