import { FC } from 'react';

import { DeletableSection } from 'components/ui/DeletableSection';
import { SelectField } from 'components/form/SelectField';
import { FormSection } from 'components/form/FormSection';
import { Row } from 'components/form/Row';
import { Input } from 'components/form/Input';
import { InputWithText } from 'components/form/InputWithText';
import { Radio } from 'components/form/Radio';
import { FileField } from 'components/form/FileField';
import { DateField } from 'components/form/DateField';
import { calcNewTypeDeduction, calcOldTypeDeduction } from 'utils/calcurators/lifeInsurance';
import { FilePreview } from 'components/feature/FilePreview';
import { LifeInsurance } from '../../../../query';
import { amountFormat, dateFormFormat } from '@jbc-year-end-adj/common/utils/formatter';

type IndividualAnnuityInsuranceType = {
  index: number;
  insurance: LifeInsurance;
  newTypeTotalAmount: number;
  oldTypeTotalAmount: number;
  year: number;
  imageRequired: 'none' | 'optional' | 'required';
};

export const IndividualAnnuityInsurance: FC<IndividualAnnuityInsuranceType> = ({
  index,
  insurance,
  newTypeTotalAmount,
  oldTypeTotalAmount,
  year,
  imageRequired
}) => {
  const image = insurance.image;
  const newOrOld = insurance.newOrOld;

  const deductionLimitAlert = () => {
    const newTypeDeduction = calcNewTypeDeduction(newTypeTotalAmount, year);
    const oldTypeDeduction = calcOldTypeDeduction(oldTypeTotalAmount, year);

    if (oldTypeDeduction >= 50000) {
      return '個人年金保険料の控除額の上限を超過しています。この金額を超えて個人年金保険料の申告をしても控除額に変更は生じません。';
    }

    const isOverOldTypeDeduction = oldTypeDeduction >= 40000;
    const isOverTotalDeduction = oldTypeDeduction + newTypeDeduction >= 40000;
    if ((isOverOldTypeDeduction || isOverTotalDeduction) && newOrOld === 'new_type') {
      return '個人年金保険料（新）の控除額の上限を超過しています。この金額を超えて個人年金保険料（新）の申告をしても控除額に変更は生じません。個人年金保険料（旧）は控除額の上限を超過していないため、申告いただいた場合控除額が増加する余地があります。';
    }

    return undefined;
  };

  return (
    <DeletableSection>
      <DeletableSection.Header>生命保険（{index + 1}）</DeletableSection.Header>
      <DeletableSection.Body>
        <FormSection>
          <SelectField
            label="保険の種類"
            required
            placeholder="選択して下さい"
            options={[{ label: '個人年金保険', value: 'IndividualAnnuityInsurance' }]}
            value={{ label: '個人年金保険', value: 'IndividualAnnuityInsurance' }}
            disabled
          />
          <Radio.Container label="新・旧の区分" required note="保険料控除証明書の表記をご確認ください">
            <Radio checked={insurance.newOrOld === 'new_type'} disabled>
              新
            </Radio>
            <Radio checked={insurance.newOrOld === 'old_type'} disabled>
              旧
            </Radio>
          </Radio.Container>
          <InputWithText
            label="保険料金額"
            text="円"
            required
            placeholder="例：100000"
            value={amountFormat(insurance.amount)}
            error={deductionLimitAlert()}
            disabled
          />
          <Row>
            <Input label="保険会社等の名称" required value={insurance.name} disabled />
            <Input
              label="保険等の種類"
              required
              note="保険料控除証明書に記載された保険種類を記入してください"
              value={insurance.insuranceType}
              disabled
            />
          </Row>
          <Row>
            <Input
              label="年金支払期間"
              required
              note="保険料控除証明書に記載された年金支払期間を記入してください"
              placeholder="例：終身、10年　等"
              value={insurance.insuranceDuration}
              disabled
            />
            <Input
              label="契約者氏名"
              required
              note="保険料控除証明書に記載された契約者の氏名を記入してください"
              placeholder="例：ジョブカン　太朗"
              value={insurance.nameOfContractor}
              disabled
            />
          </Row>
          <Row>
            <Input
              label="受取人・氏名"
              required
              note="保険料控除証明書に記載された受給人の氏名を記入してください"
              placeholder="例：ジョブカン　花子"
              value={insurance.nameOfRecipient}
              disabled
            />
            <Input
              label="受取人・続柄"
              required
              note="保険料控除証明書に記載された受給人と本人の関係を記入してください。受給人が本人の場合、「本人」を記入してください。"
              placeholder="例：本人、妻　等"
              value={insurance.relationshipOfRecipient}
              disabled
            />
          </Row>
          <DateField label="支払開始日" required value={dateFormFormat(insurance.paymentStartDate, 'L')} disabled />
          {imageRequired !== 'none' && (
            <FileField
              label="証明書画像"
              note="管理者が確認できるよう保険料控除証明書の画像（対応可能形式：jpg, jpeg, gif, png, pdf）をアップロードしてください。原本は必ず会社に提出してください。"
              required={imageRequired === 'required'}
              disabled
            >
              {image && (
                <FileField.FileContainer>
                  <FileField.File file={image} />
                  <FilePreview file={image} />
                </FileField.FileContainer>
              )}
            </FileField>
          )}
        </FormSection>
      </DeletableSection.Body>
    </DeletableSection>
  );
};
