import { useRef, useEffect } from 'react';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

type UseKeywordInput = (callback: (value: string) => void) => Subject<string>;

export const useKeywordInput: UseKeywordInput = callback => {
  const handleInputKeyword = (value: string) => {
    callback(value);
    return subscription.unsubscribe();
  };

  const keywordSubject = useRef(new Subject<string>());
  const subscription = keywordSubject.current.pipe(debounceTime(1000)).subscribe(handleInputKeyword);

  useEffect(() => {
    return subscription.unsubscribe();
  }, []);

  return keywordSubject.current;
};
