import React from 'react'
import Icon from 'react-icon-base'

const Alert = props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <g fill='none' fillRule='evenodd'>
      <circle cx='7' cy='7' r='7' fill='currentColor' />
      <g fill='#FFF' transform='translate(6.125 1.75)'>
        <circle cx='.875' cy='9.625' r='1' />
        <path d='M.475 7.455L0 .871C.073.296.365.006.875.001c.51-.007.802.284.875.87l-.394 6.584h-.88z' />
      </g>
    </g>
  </Icon>
)
Alert.defaultProps = {
  size: 16
}

export default Alert
