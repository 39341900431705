import { gql } from '@apollo/client';

import { RequestImageUploadSettings, ClientSettingEmployeeAddressClassification, Reminder, Schema } from './schema';

export const dataToQueryVariables = (data: Schema) => {
  const hasSubmissionDeadline = toBool(data.hasSubmissionDeadline);
  const submissionDeadlineDate = hasSubmissionDeadline ? data.submissionDeadlineDate : undefined;
  const submissionDeadlineHour = hasSubmissionDeadline ? data.submissionDeadlineHour : undefined;
  const useReminder = hasSubmissionDeadline ? data.useReminder : undefined;
  const reminders = hasSubmissionDeadline && useReminder ? data.reminders : undefined;

  return {
    input: {
      attributes: {
        requestPrint: toBool(data.requestPrint),
        requestImageUploadSettings: {
          earthquakeInsurance: data.requestImageUploadSettings.earthquakeInsurance,
          handicap: data.requestImageUploadSettings.handicap,
          housingLoan: data.requestImageUploadSettings.housingLoan,
          lifeInsurance: data.requestImageUploadSettings.lifeInsurance,
          nonResidentAndStudyAbroad: data.requestImageUploadSettings.nonResidentAndStudyAbroad,
          pensionInsurance: data.requestImageUploadSettings.pensionInsurance,
          premium: data.requestImageUploadSettings.premium,
          previousWithholdingSlip: data.requestImageUploadSettings.previousWithholdingSlip,
          socialInsurance: data.requestImageUploadSettings.socialInsurance,
          studentIdCard: data.requestImageUploadSettings.studentIdCard
        },
        employeeAddressClassification: data.employeeAddressClassification,
        sendAcceptedEmail: toBool(data.sendAcceptedEmail),
        hasSubmissionDeadline,
        submissionDeadlineDate,
        submissionDeadlineHour,
        useReminder,
        reminders
      }
    }
  };
};

const toBool = (value: 'true' | 'false') => {
  if (value === 'true') {
    return true;
  } else {
    return false;
  }
};

export type QueryVariables = {
  input: {
    attributes: {
      requestPrint: boolean;
      requestImageUploadSettings: RequestImageUploadSettings;
      employeeAddressClassification: ClientSettingEmployeeAddressClassification;
      sendAcceptedEmail: boolean;
      hasSubmissionDeadline: boolean;
      submissionDeadlineDate?: string;
      submissionDeadlineHour?: string;
      useReminder?: boolean;
      reminders?: Reminder[];
    };
  };
};

export const SAVE_CLIENT_SETTING = gql`
  mutation saveClientSetting($input: SaveClientSettingInput!) {
    saveClientSetting(input: $input) {
      clientYearly {
        id
        clientSetting {
          id
          requestPrint
          requestImageUploadSettings {
            lifeInsurance
            earthquakeInsurance
            pensionInsurance
            socialInsurance
            premium
            housingLoan
            studentIdCard
            handicap
            nonResidentAndStudyAbroad
            previousWithholdingSlip
          }
          employeeAddressClassification
          sendAcceptedEmail
          hasSubmissionDeadline
          submissionDeadline {
            id
            submissionDeadlineDate
            submissionDeadlineHour
            useReminder
            reminders {
              id
              remindDaysBefore
              remindHour
            }
          }
        }
      }
    }
  }
`;
