import React from 'react'
import Icon from 'react-icon-base'

const Project = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="icon/employees/Project" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group" transform="translate(3.000000, 2.000000)" fill={props.color} fillRule="nonzero">
        <path d="M25,0 C26.5976809,0 27.9036609,1.24891996 27.9949073,2.82372721 L28,3 L28,14 C28,14.5522847 27.5522847,15 27,15 C26.4871642,15 26.0644928,14.6139598 26.0067277,14.1166211 L26,14 L26,3 C26,2.48716416 25.6139598,2.06449284 25.1166211,2.00672773 L25,2 L3,2 C2.48716416,2 2.06449284,2.38604019 2.00672773,2.88337887 L2,3 L2,31 C2,31.5128358 2.38604019,31.9355072 2.88337887,31.9932723 L3,32 L14,32 C14.5522847,32 15,32.4477153 15,33 C15,33.5128358 14.6139598,33.9355072 14.1166211,33.9932723 L14,34 L3,34 C1.40231912,34 0.0963391206,32.75108 0.00509269341,31.1762728 L0,31 L0,3 C0,1.40231912 1.24891996,0.0963391206 2.82372721,0.00509269341 L3,0 L25,0 Z" id="Path"></path>
        <path d="M24,16 C29.5228475,16 34,20.4771525 34,26 C34,31.5228475 29.5228475,36 24,36 L24,36 L23,36 L23,27 L14,27 L14,26 C14,20.4771525 18.4771525,16 24,16 Z M24,18 C19.9985407,18 16.6832419,20.9378026 16.0933267,24.7741848 L16.0933267,24.7741848 L16.062,25 L25,25 L25,33.937 L25.2258152,33.9066733 C28.9822728,33.329048 31.8772015,30.1384139 31.9961932,26.2491793 L31.9961932,26.2491793 L32,26 C32,21.581722 28.418278,18 24,18 Z" id="Oval" transform="translate(24.000000, 26.000000) scale(1, -1) translate(-24.000000, -26.000000) "></path>
        <path d="M22,13 L22,24 L11,24 L11,23 C11,17.4771525 15.4771525,13 21,13 L21,13 L22,13 Z M20,15.062 L19.7741848,15.0933267 C16.3374257,15.6217924 13.6217924,18.3374257 13.0933267,21.7741848 L13.0933267,21.7741848 L13.062,22 L20,22 L20,15.062 Z" id="Oval"></path>
        <path d="M12.5,19.75 C12.9142136,19.75 13.25,20.0857864 13.25,20.5 C13.25,20.8796958 12.9678461,21.193491 12.6017706,21.2431534 L12.5,21.25 L4.5,21.25 C4.08578644,21.25 3.75,20.9142136 3.75,20.5 C3.75,20.1203042 4.03215388,19.806509 4.39822944,19.7568466 L4.5,19.75 L12.5,19.75 Z M13.5,16.75 C13.9142136,16.75 14.25,17.0857864 14.25,17.5 C14.25,17.8796958 13.9678461,18.193491 13.6017706,18.2431534 L13.5,18.25 L4.5,18.25 C4.08578644,18.25 3.75,17.9142136 3.75,17.5 C3.75,17.1203042 4.03215388,16.806509 4.39822944,16.7568466 L4.5,16.75 L13.5,16.75 Z M17.5,13.75 C17.9142136,13.75 18.25,14.0857864 18.25,14.5 C18.25,14.8796958 17.9678461,15.193491 17.6017706,15.2431534 L17.5,15.25 L4.5,15.25 C4.08578644,15.25 3.75,14.9142136 3.75,14.5 C3.75,14.1203042 4.03215388,13.806509 4.39822944,13.7568466 L4.5,13.75 L17.5,13.75 Z M16.5,10.75 C16.9142136,10.75 17.25,11.0857864 17.25,11.5 C17.25,11.8796958 16.9678461,12.193491 16.6017706,12.2431534 L16.5,12.25 L4.5,12.25 C4.08578644,12.25 3.75,11.9142136 3.75,11.5 C3.75,11.1203042 4.03215388,10.806509 4.39822944,10.7568466 L4.5,10.75 L16.5,10.75 Z M10.5,7.75 C10.9142136,7.75 11.25,8.08578644 11.25,8.5 C11.25,8.87969577 10.9678461,9.19349096 10.6017706,9.24315338 L10.5,9.25 L4.5,9.25 C4.08578644,9.25 3.75,8.91421356 3.75,8.5 C3.75,8.12030423 4.03215388,7.80650904 4.39822944,7.75684662 L4.5,7.75 L10.5,7.75 Z M10.5,4.75 C10.9142136,4.75 11.25,5.08578644 11.25,5.5 C11.25,5.87969577 10.9678461,6.19349096 10.6017706,6.24315338 L10.5,6.25 L4.5,6.25 C4.08578644,6.25 3.75,5.91421356 3.75,5.5 C3.75,5.12030423 4.03215388,4.80650904 4.39822944,4.75684662 L4.5,4.75 L10.5,4.75 Z" id="Combined-Shape"></path>
      </g>
    </g>
  </Icon>
)

Project.defaultProps = {
  size: 30,
  color: "#3498DB"
}

export default Project
