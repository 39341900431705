import React from 'react';
import { FieldArray } from 'redux-form';
import { DependentBasicInfo, relationOthers, residenceStatuses } from '../questions/Dependents';
import { Copy, DeleteSquare } from 'jbc-front/components/icons';
import { BoxDouble, Section } from 'jbc-front/components/Form';
import { useAutofill, useFormValue } from '../components/FormName';
import FormAdd from '../components/FormAdd';
import {
  FormSection,
  SelectField,
  CheckboxField,
  DateField,
  TextField,
  useDiff,
  RadioField,
  FileField,
  TextAreaField
} from '../components/FieldWithDiff';
import { LabelMapper } from 'jbc-front/components/FormErrors';
import { useQuery, useSession, useYear } from '../components';
import { amount, maxLength, maxLengthAmount, maxTenThousandYen } from '../validators';
import ActionButton from 'jbc-front/components/ActionButton';
import Address from '../components/Address';
import { Label } from '../components/PrimaryPanel';
import { handicapClassifications } from '../questions/Handicap';
import { salaryEarnings } from '../utils/income';
import { gql } from '@apollo/client';

export { makeInitialValues, OTHER_FAMILIES_FRAGMENT } from '../questions/Dependents';

const EARNINGS = gql`
  query earnings {
    yearMasters {
      id
      year
      earnings
      spouseEarnings
    }
  }
`;

export const DependentsYearlyInfo = ({ withCopy, field, hideIncome, target, isSpouse, thisYearField, year, isNextYear }) => {
  const { data, loading } = useQuery(EARNINGS);
  const residenceStatus = useFormValue(`${field}.residenceStatus`);
  const isNonResidentStatus = useFormValue(`${field}.isNonResident`);
  const isStudyAbroadStatus = useFormValue(`${field}.isStudyAbroad`);
  const handicapType = useFormValue(`${field}.handicapType`);
  const { autofill, withSelector } = useAutofill();
  const yearForAddressLookup = useYear();
  if (loading) return null;
  const { yearMasters } = data;
  const { earnings: maxEarnings, spouseEarnings: maxSpouseEarnings } = yearMasters.find(master => master.year === year);
  return (
    <>
      {withCopy && (
        <ActionButton
          icon={<Copy size={13} />}
          className="u-mb20"
          onClick={() => {
            withSelector(selector => {
              const thisYear = selector(thisYearField || field.replace(/nextYear/, 'thisYear'));
              [
                'residenceStatus',
                'prefectureId',
                'postcode0',
                'postcode1',
                'city',
                'street',
                'building',
                'addressForeign',
                'income',
                'earnings',
                'isNonResident',
                'remittance',
                'handicapType',
                'handicapDetail',
                'isStudyAbroad'
              ].forEach(name => autofill(`${field}.${name}`, thisYear[name]));
            });
          }}
        >
          今年の情報をコピーする
        </ActionButton>
      )}
      <RadioField name="residenceStatus" options={residenceStatuses} label="同居・別居" required />
      {residenceStatus === 'different_address' && <Address withKana={false} year={yearForAddressLookup} required />}
      {residenceStatus === 'different_and_foreign_address' && (
        <>
          <TextField name="addressForeign" label="Address" />
          <CheckboxField
            name="isNonResident"
            label="非居住者"
            description={`${target}が国内に住所を有せず、かつ、現在まで引き続き1年以上居所を有しない場合チェックしてください。`}
          />
        </>
      )}
      {residenceStatus === 'different_and_foreign_address' && isNonResidentStatus && (
        <>
          <CheckboxField name="isStudyAbroad" label="留学" description={`${target}が非居住者のうち留学の場合はチェックしてください。`} />
          <TextField
            name="remittance"
            label="国外居住親族への送金額"
            validate={amount}
            description={`${target}が非居住者である場合、本年中にその被扶養者に送金等をした金額の合計を記入してください。`}
          />
          <Label>添付ファイル</Label>
          <p className="u-mb20">
            国外居住親族に係る扶養控除等を受けようとする場合は関係書類（例：親族関係書類、送金関係書類、留学証明書類）を添付してください。
          </p>
          <FileField name="relatedToRelativesDocument" label="親族関係書類" />
          <FileField name="relatedToRemittanceDocument" label="送金関係書類" />
        </>
      )}
      {isNonResidentStatus && residenceStatus === 'different_and_foreign_address' && isStudyAbroadStatus && (
        <>
          <FileField name="provingStudyAbroadDocument" label="留学証明書類" />
        </>
      )}

      <RadioField name="handicapType" label="障害者区分" options={handicapClassifications} required />
      {handicapType && handicapType !== 'none' && (
        <>
          <TextAreaField name="handicapDetail" label="詳細" />
          <FileField name="handicapImage" label="障害者手帳画像" />
        </>
      )}
      {!hideIncome && (
        <BoxDouble>
          <TextField
            name="income"
            label="給与収入等"
            validate={[maxLengthAmount(7)]}
            description="給与収入が103万円を超える場合被扶養者に該当しません。（配偶者は150万円を超えてはいけません。また、従業員本人の給与収入が1,095万円を超える場合配偶者控除を受けることができません。）"
            onChange={(e, value) => {
              autofill(`${field}.earnings`, salaryEarnings(value, year));
            }}
          />
          <TextField
            name="earnings"
            label="所得見積額"
            validate={[amount, maxTenThousandYen(isSpouse ? maxSpouseEarnings : maxEarnings)]}
            description={`所得が${maxEarnings / 10_000}万円を超える場合被扶養者に該当しません。（配偶者は${maxSpouseEarnings /
              10_000}万円）`}
          />
        </BoxDouble>
      )}
    </>
  );
};

const Dependent = ({ field, diff = {} }) => {
  const { fixed } = useDiff();
  const {
    clientYearly: { year }
  } = useSession();
  const dependentThisYear = useFormValue(`${field}.thisYear.dependentTaxLaw`);
  const dependentNextYear = useFormValue(`${field}.nextYear.dependentTaxLaw`);
  const incomeAdjustmentDeduction = useFormValue(`${field}.thisYear.incomeAdjustmentDeduction`);
  return (
    <>
      <DependentBasicInfo diff={diff} />
      <SelectField
        options={relationOthers}
        name="relationOther"
        label="続柄詳細"
        creatable
        required
        note="リストにない続柄は直接入力して追加できます"
        changed={diff.relationOther}
      />
      <Section title="今年の税の扶養" style={{ maxWidth: '100%' }}>
        <FormSection name="thisYear">
          <LabelMapper name="thisYear" label="今年の情報の" />
          <CheckboxField
            name="dependentTaxLaw"
            label="今年の税の扶養対象"
            changed={diff.thisYear?.dependentTaxLaw}
            disabled={incomeAdjustmentDeduction}
          />
          <CheckboxField
            name="incomeAdjustmentDeduction"
            label="今年の税の扶養対象ではないが、所得金額調整控除の対象"
            note={
              <div>
                同一生計内の他の所得者に扶養される年齢23歳未満、もしくは特別障害者に該当する扶養親族は、本人の収入が850万円を超えている場合に所得金額調整控除の対象になります。詳しくは
                <a href="https://www.nta.go.jp/taxes/shiraberu/taxanswer/shotoku/1411.htm" target="_blank" rel="noopener noreferrer">
                  国税庁HP
                </a>
                をご覧ください。
              </div>
            }
            disabled={dependentThisYear}
          />
          {(dependentThisYear || incomeAdjustmentDeduction) && (
            <DependentsYearlyInfo field={`${field}.thisYear`} diff={diff.thisYear} target="被扶養者" year={year} />
          )}
        </FormSection>
      </Section>
      <Section title="来年の税の扶養" style={{ maxWidth: '100%' }}>
        <FormSection name="nextYear">
          <LabelMapper name="nextYear" label="来年の情報の" />
          <CheckboxField name="dependentTaxLaw" label="来年の税の扶養対象" changed={diff.nextYear?.dependentTaxLaw} />
          {dependentNextYear && (
            <DependentsYearlyInfo
              field={`${field}.nextYear`}
              withCopy={dependentThisYear && !fixed}
              diff={diff.nextYear}
              target="被扶養者"
              year={year + 1}
              isNextYear
            />
          )}
        </FormSection>
      </Section>
      {(dependentThisYear || dependentNextYear) && (
        <>
          <DateField name="dependentFrom" label="税法上の扶養家族になった日" />
          <TextField name="dependentReason" label="税法上の扶養に追加された理由" validate={maxLength(24)} />
          <LabelMapper name="otherFamilies.dependentReason" label="税法上の扶養に追加された理由" />
          <DateField name="diedOn" label="死亡日" description={`扶養親族が今年（${year}年）亡くなられた場合、死亡日をご記入ください。`} />
        </>
      )}
    </>
  );
};

export const genderOptions = [
  { value: 'male', label: '男性' },
  { value: 'female', label: '女性' }
];

const renderDependents = ({ fields, diff = [] }) => (
  <div>
    {fields.map((field, index) => (
      <Section
        key={index}
        title={`被扶養者 (${index + 1})`}
        icon={
          <DeleteSquare
            onClick={() => {
              fields.remove(index);
            }}
          />
        }
      >
        <FormSection name={field}>
          <Dependent field={field} diff={diff[index]} />
        </FormSection>
      </Section>
    ))}
    <div>{fields.length < 10 && <FormAdd onClick={() => fields.push({})} name="被扶養者" />}</div>
  </div>
);

const Dependents = ({ diff }) => (
  <Section title="被扶養者">
    <LabelMapper name="otherFamilies" label="被扶養者" />
    <FieldArray name="otherFamilies" component={renderDependents} diff={diff} />
  </Section>
);

export default Dependents;
