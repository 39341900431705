import React from 'react';
import { Fields, Field } from 'redux-form';
import _ from 'lodash';
import { dateFieldProps } from 'jbc-front/components/Form';
import { SearchTextBox } from 'jbc-front/components/SearchForm';
import styles from 'jbc-front/components/SearchForm.scss';

import { dateString } from '@jbc-year-end-adj/ancient/validators';

/* eslint-disable no-unused-vars */
export const renderError = ({ names, label, ...fields }) => {
  /* eslint-enable no-unused-vars */
  const field = _.find(fields, field => field.meta && field.meta.touched && field.meta.error);
  if (field) {
    return (
      <p>
        {label}
        {field.meta.error}
      </p>
    );
  }
  return null;
};

export const DateRow = ({ header, errorLabel, prefix }) => (
  <div className={styles.detailsRow}>
    <div className={styles.detailsHeader}>{header}</div>
    <div className={styles.detailsBody}>
      <div className={styles.detailsBodyItems}>
        <Field name={`${prefix}Start`} component={SearchTextBox} type="date" {...dateFieldProps} validate={dateString} />
        <span className={styles.detailsComplementText}>から</span>
        <Field name={`${prefix}End`} component={SearchTextBox} type="date" {...dateFieldProps} validate={dateString} />
        <span className={styles.detailsComplementText}>の間</span>
      </div>
      <div className={styles.detailsBodyErrors}>
        <Fields component={renderError} names={[`${prefix}Start`, `${prefix}End`]} label={errorLabel} />
      </div>
    </div>
  </div>
);
