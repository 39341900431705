import { ComponentProps, forwardRef } from 'react';
import { FormField, FieldBaseProps } from '../FormField';
import { Label } from '../Label';
import { Textarea as TextareaField } from 'jbc-front/components/presenters/form/Textarea';
import { Required } from 'jbc-front/components/presenters/form/Label';
import { ErrorMessage } from 'jbc-front/components/presenters/form/ErrorMessage';
import { Note } from 'jbc-front/components/presenters/ui/Note';
import { Hint } from 'jbc-front/components/presenters/ui/Hint';

type TextareaProps = ComponentProps<typeof TextareaField> & FieldBaseProps;

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ label, required = false, hint, note, error = undefined, ...textareaProps }, ref) => {
    return (
      <FormField>
        {label && (
          <FormField.LabelContainer>
            <Label>
              {label}
              {required && <Required />}
              {hint && <Hint>{hint}</Hint>}
            </Label>
          </FormField.LabelContainer>
        )}

        <TextareaField {...textareaProps} ref={ref} />

        {note && (
          <FormField.NoteContainer>
            <Note>{note}</Note>
          </FormField.NoteContainer>
        )}

        {error && (
          <FormField.ErrorContainer>
            <ErrorMessage>{error}</ErrorMessage>
          </FormField.ErrorContainer>
        )}
      </FormField>
    );
  }
);
