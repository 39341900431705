import React from 'react'
import Icon from 'react-icon-base'

const AddFile = props => (
  <Icon viewBox="0 0 28 30" {...props}>
    <g fill='none' fillRule='evenodd' stroke='currentColor'>
      <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M24 13.341V3.795C24 2.804 23.128 2 22.052 2H3.948c-.27 0-.525.05-.758.14-.7.273-1.19.912-1.19 1.655v22.41C2 27.196 2.872 28 3.948 28h6.215' />
      <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.7' d='M7 9h12M7 14h12' />
      <circle cx='21' cy='23' r='6' strokeWidth='1.7' />
      <path d='M18 23h6M21 26v-6' strokeWidth='1.4' />
    </g>
  </Icon>
)
AddFile.defaultProps = {
  size: 30
}

export default AddFile
