import React from 'react';
import { FileImportDescription } from '../components/FileImport';

const data = [
  { name: 'スタッフコード', description: '半角英数字50桁以内', example: 'A001', required: true },
  {
    name: '姓',
    description: '従業員の氏名',
    notice: '※ダウンロード時には出力されますが、更新時には無視されます。',
    example: '山田'
  },
  {
    name: '名',
    description: '従業員の氏名',
    notice: '※ダウンロード時には出力されますが、更新時には無視されます。',
    example: '太郎'
  },
  {
    name: '給与の課税支払額',
    description: (
      <>
        <p>半角数字10桁以内</p>
        <p>今年支払った給与の合計額</p>
      </>
    ),
    notice: '※非課税所得を除いて記入してください。',
    example: '3000000'
  },
  {
    name: '給与の社会保険控除額',
    description: (
      <>
        <p>半角数字10桁以内</p>
        <p>今年給与から天引きされた社会保険料等の合計額</p>
      </>
    ),
    example: '500000'
  },
  {
    name: '給与の源泉徴収税額',
    description: (
      <>
        <p>半角数字10桁以内</p>
        <p>今年給与から天引きされた所得税の合計額</p>
      </>
    ),
    example: '100000'
  },
  {
    name: '賞与の課税支払額',
    description: (
      <>
        <p>半角数字10桁以内</p>
        <p>今年支払った賞与の合計額</p>
      </>
    ),
    notice: '※非課税所得を除いて記入してください。',
    example: '500000'
  },
  {
    name: '賞与の社会保険控除額',
    description: (
      <>
        <p>半角数字10桁以内</p>
        <p>今年賞与から天引きされた社会保険料等の合計額</p>
      </>
    ),
    example: '100000'
  },
  {
    name: '賞与の源泉徴収税額',
    description: (
      <>
        <p>半角数字10桁以内</p>
        <p>今年賞与から天引きされた所得税の合計額</p>
      </>
    ),
    example: '50000'
  },
  {
    name: '前職の課税支払額',
    description: (
      <>
        <p>半角数字10桁以内</p>
        <p>前職で今年支払いを受けた課税支払額の合計額</p>
      </>
    ),
    example: '2000000'
  },
  {
    name: '前職の社会保険控除額',
    description: (
      <>
        <p>半角数字10桁以内</p>
        <p>前職で今年天引きされた社会保険料等の合計額</p>
      </>
    ),
    example: '400000'
  },
  {
    name: '前職の源泉徴収税額',
    description: (
      <>
        <p>半角数字10桁以内</p>
        <p>前職で今年天引きされた所得税の合計額</p>
      </>
    ),
    example: '100000'
  },
  {
    name: '課税支払額の調整額',
    description: (
      <>
        <p>半角数字10桁以内</p>
        <p>今年支払いを受けた課税支払額の調整額</p>
      </>
    ),
    example: '2000000'
  },
  {
    name: '社会保険控除額の調整額',
    description: (
      <>
        <p>半角数字10桁以内</p>
        <p>今年天引きされた社会保険料等の調整額</p>
      </>
    ),
    example: '400000'
  },
  {
    name: '源泉徴収税額の調整額',
    description: (
      <>
        <p>半角数字10桁以内</p>
        <p>今年天引きされた所得税の調整額</p>
      </>
    ),
    example: '100000'
  },
  {
    name: '天引きされた小規模共済掛金',
    description: (
      <>
        <p>半角数字10桁以内</p>
        <p>今年天引きされた小規模共済掛金の合計額以内</p>
      </>
    ),
    example: '100000'
  },
  {
    name: '旧契約の一般生命保険料額',
    description: (
      <>
        <p>半角数字10桁以内</p>
        <p>旧制度適用の一般生命保険料申告額の合計額</p>
      </>
    ),
    example: '100000'
  },
  {
    name: '新契約の一般生命保険料額',
    description: (
      <>
        <p>半角数字10桁以内</p>
        <p>新制度適用の一般生命保険料申告額の合計額</p>
      </>
    ),
    example: '100000'
  },
  {
    name: '介護医療保険料額',
    description: (
      <>
        <p>半角数字10桁以内</p>
        <p>介護医療保険料申告額の合計額</p>
      </>
    ),
    example: '100000'
  },
  {
    name: '旧契約の個人年金保険料額',
    description: (
      <>
        <p>半角数字10桁以内</p>
        <p>旧制度適用の個人年金保険料申告額の合計額</p>
      </>
    ),
    example: '100000'
  },
  {
    name: '新契約の個人年金保険料額',
    description: (
      <>
        <p>半角数字10桁以内</p>
        <p>新制度適用の個人年金保険料申告額の合計額</p>
      </>
    ),
    example: '100000'
  },
  {
    name: '地震保険料額',
    description: (
      <>
        <p>半角数字10桁以内</p>
        <p>地震保険料申告額の合計額</p>
      </>
    ),
    example: '200000'
  },
  {
    name: '旧長期損害保険料額',
    description: (
      <>
        <p>半角数字10桁以内</p>
        <p>地震保険料のうち、旧長期損害保険料申告額の合計額</p>
      </>
    ),
    example: '50000'
  },
  {
    name: '社会保険料額',
    description: (
      <>
        <p>半角数字10桁以内</p>
        <p>今年本人が支払った社会保険料額の合計額</p>
        <p>（例）国民年金保険、国民健康保険等</p>
      </>
    ),
    example: '100000'
  },
  {
    name: '国民年金保険料等の金額',
    description: (
      <>
        <p>半角数字10桁以内</p>
        <p>社会保険料額のうち、国民健康保険料の合計額</p>
      </>
    ),
    example: '50000'
  },
  {
    name: '小規模企業共済等掛金',
    description: (
      <>
        <p>半角数字10桁以内</p>
        <p>申告があった小規模共済掛金の合計額</p>
      </>
    ),
    example: '0'
  },
  {
    name: '住宅借入金等特別控除額',
    description: (
      <>
        <p>半角数字10桁以内</p>
        <p>住宅借入金等特別控除額（申告書⑭）の合計額</p>
      </>
    ),
    example: '800000'
  },
  {
    name: '摘要',
    description: (
      <>
        <p>追記事項がある場合、その追記する内容のみを入力</p>
        <p>（例）住宅ローンの適用数が3つ以上ある場合は3つ目以降の内訳内容</p>
      </>
    ),
    notice: '※受給者交付用、税務署提出用、給与支払報告書に記載されます。',
    example: '住宅ローン3回目：住宅開始年月日yyyy月mm年dd日、特別控除区分、年末残高10,000,000円'
  },
  {
    name: '備考',
    description: (
      <>
        <p>追記事項がある場合、その追記する内容のみを入力</p>
        <p>（例）５人目以降の控除対象扶養親族のマイナンバー等</p>
      </>
    ),
    notice: '※税務署提出用、給与支払報告書に記載されます。',
    example: '花子：123456789123'
  }
];

export default () => <FileImportDescription data={data} />;
