import React from 'react'
import Icon from 'react-icon-base'

const Custom7 = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g id="Custom7" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon/employees/Custom7-Copy-14" fill="#3498DB" fillRule="nonzero">
        <g id="Group-2" transform="translate(3.000000, 7.923475)">
          <path d="M14,15.0765246 C15.1045695,15.0765246 16,15.9719551 16,17.0765246 L16,22.0765246 C16,23.1810941 15.1045695,24.0765246 14,24.0765246 L4,24.0765246 C2.8954305,24.0765246 2,23.1810941 2,22.0765246 L2,17.0765246 C2,15.9719551 2.8954305,15.0765246 4,15.0765246 L14,15.0765246 Z M14,17.0765246 L4,17.0765246 L4,22.0765246 L14,22.0765246 L14,17.0765246 Z" id="Rectangle"></path>
          <path d="M7.2,16.1765246 C7.4209139,16.0108392 7.73431458,16.0556107 7.9,16.2765246 C8.04727593,16.4728925 8.02826489,16.7423364 7.8670671,16.9161547 L7.8,16.9765246 L3.8,19.9765246 C3.5790861,20.14221 3.26568542,20.0974385 3.1,19.8765246 C2.95272407,19.6801567 2.97173511,19.4107128 3.1329329,19.2368945 L3.2,19.1765246 L7.2,16.1765246 Z" id="Line"></path>
          <path d="M12.2202629,15.5443508 C12.4330798,15.3683861 12.7482495,15.3982607 12.9242143,15.6110775 C13.0806274,15.8002481 13.074402,16.0702901 12.9216195,16.2515496 L12.8574876,16.3150289 L4.81861235,22.9618636 C4.60579547,23.1378284 4.2906257,23.1079538 4.11466096,22.8951369 C3.95824786,22.7059664 3.96447322,22.4359244 4.11725575,22.2546649 L4.18138765,22.1911856 L12.2202629,15.5443508 Z" id="Line-Copy"></path>
          <path d="M30,15.0765246 C31.1045695,15.0765246 32,15.9719551 32,17.0765246 L32,22.0765246 C32,23.1810941 31.1045695,24.0765246 30,24.0765246 L20,24.0765246 C18.8954305,24.0765246 18,23.1810941 18,22.0765246 L18,17.0765246 C18,15.9719551 18.8954305,15.0765246 20,15.0765246 L30,15.0765246 Z M30,17.0765246 L20,17.0765246 L20,22.0765246 L30,22.0765246 L30,17.0765246 Z" id="Rectangle-Copy"></path>
          <path d="M19,16.0765246 C19.5522847,16.0765246 20,16.5242398 20,17.0765246 C20,17.5893604 19.6139598,18.0120318 19.1166211,18.0697969 L19,18.0765246 L15,18.0765246 C14.4477153,18.0765246 14,17.6288093 14,17.0765246 C14,16.5636888 14.3860402,16.1410174 14.8833789,16.0832523 L15,16.0765246 L19,16.0765246 Z" id="Line"></path>
          <g id="Group">
            <path d="M2.5,15.5765246 C3.05228475,15.5765246 3.5,16.0242398 3.5,16.5765246 C3.5,17.0893604 3.11395981,17.5120318 2.61662113,17.5697969 L2.5,17.5765246 L0.5,17.5765246 C-0.0522847498,17.5765246 -0.5,17.1288093 -0.5,16.5765246 C-0.5,16.0636888 -0.11395981,15.6410174 0.383378875,15.5832523 L0.5,15.5765246 L2.5,15.5765246 Z" id="Line-2"></path>
            <path d="M7.5,-1 C9.56709484,-1 10.6310788,0.39351847 10.4711664,2.65545318 C10.4322186,3.20636289 9.95404463,3.62139005 9.40313491,3.58244228 C8.8522252,3.54349451 8.43719804,3.06532054 8.47614581,2.51441082 C8.55989663,1.32976953 8.30810987,1 7.5,1 C6.65039061,1 5.79658786,1.95485994 5.0550616,4.07504656 L4.96296402,4.34615452 L1.46296402,16.8461545 C1.31405152,17.3779849 0.762200417,17.6884011 0.230370074,17.5394886 C-0.263472387,17.4012127 -0.566401674,16.9155051 -0.487930002,16.4210106 L-0.46296402,16.3068947 L3.05025655,3.76349523 C4.0660316,0.681593723 5.50980506,-1 7.5,-1 Z" id="Line-2"></path>
          </g>
          <g id="Group-Copy" transform="translate(29.000000, 9.000000) scale(-1, 1) translate(-29.000000, -9.000000) translate(24.000000, 0.000000)">
            <path d="M2.5,15.5765246 C3.05228475,15.5765246 3.5,16.0242398 3.5,16.5765246 C3.5,17.0893604 3.11395981,17.5120318 2.61662113,17.5697969 L2.5,17.5765246 L0.5,17.5765246 C-0.0522847498,17.5765246 -0.5,17.1288093 -0.5,16.5765246 C-0.5,16.0636888 -0.11395981,15.6410174 0.383378875,15.5832523 L0.5,15.5765246 L2.5,15.5765246 Z" id="Line-2"></path>
            <path d="M7.5,-1 C9.56709484,-1 10.6310788,0.39351847 10.4711664,2.65545318 C10.4322186,3.20636289 9.95404463,3.62139005 9.40313491,3.58244228 C8.8522252,3.54349451 8.43719804,3.06532054 8.47614581,2.51441082 C8.55989663,1.32976953 8.30810987,1 7.5,1 C6.65039061,1 5.79658786,1.95485994 5.0550616,4.07504656 L4.96296402,4.34615452 L1.46296402,16.8461545 C1.31405152,17.3779849 0.762200417,17.6884011 0.230370074,17.5394886 C-0.263472387,17.4012127 -0.566401674,16.9155051 -0.487930002,16.4210106 L-0.46296402,16.3068947 L3.05025655,3.76349523 C4.0660316,0.681593723 5.50980506,-1 7.5,-1 Z" id="Line-2"></path>
          </g>
        </g>
      </g>
    </g>
  </Icon>
)
Custom7.defaultProps = {
  size: 30
}

export default Custom7