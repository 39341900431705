import Rollbar from 'rollbar';
import { datadogRum } from '@datadog/browser-rum';
import { markAsSentToRollbar, isIgnorableNetworkError } from './rollbar/utils';

export const logError = (error: Error, context?: object) => {
  if (isIgnorableNetworkError(error)) {
    return;
  }

  Rollbar.error(error, context);
  datadogRum.addError(error, context);
  markAsSentToRollbar(error);
};

export interface TelemetryUser {
  id: string;
  customer_code?: string;
  application?: Application;
}

interface Application {
  id: string;
  name: string;
}

export const setTelemetryUser = (user: TelemetryUser) => {
  datadogRum.setUser({
    ...user
  });
};

export const clearTelemetryUser = () => {
  datadogRum.clearUser();
};
