import React from 'react';
import Button from 'jbc-front/components/Button';
import { Panel, Headding, Item, Lead, Buttons } from '../components/PrimaryPanel';
import { DescriptionEdit } from '../components/DescriptionEdit';
import { CustomDescription } from '../components/QuestionDescription';

const Question = () => {
  return (
    <Panel>
      <Headding icon={<DescriptionEdit />}>給与収入等</Headding>
      <Item>
        <Lead>収入は給与収入のみですか？</Lead>
        <CustomDescription field="top" />
        <Buttons>
          <Button huge secondary disabled>
            はい
          </Button>
          <Button huge secondary disabled>
            いいえ
          </Button>
        </Buttons>
      </Item>
    </Panel>
  );
};

export default Question;
