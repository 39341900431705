import { FC, ComponentProps } from 'react';
import { FormField, FieldBaseProps } from '../FormField';
import { Label } from '../Label';
import { CreatableSelect } from 'jbc-front/components/presenters/form/Select';
import { Required } from 'jbc-front/components/presenters/form/Label';
import { ErrorMessage } from 'jbc-front/components/presenters/form/ErrorMessage';
import { Note } from 'jbc-front/components/presenters/ui/Note';
import { ReadMoreReadLess } from 'jbc-front/components/presenters/ui/ReadMoreReadLess';
import { Hint } from 'jbc-front/components/presenters/ui/Hint';

type CreatableSelectFieldProps = ComponentProps<typeof CreatableSelect> &
  FieldBaseProps & {
    noteMaxLength?: number;
  };

export const CreatableSelectField: FC<CreatableSelectFieldProps> = ({
  label,
  required = false,
  hint,
  note,
  noteMaxLength,
  error = undefined,
  ...creatableSelectProps
}) => {
  return (
    <FormField>
      {label && (
        <FormField.LabelContainer>
          <Label>
            {label}
            {required && <Required />}
            {hint && <Hint>{hint}</Hint>}
          </Label>
        </FormField.LabelContainer>
      )}

      <CreatableSelect {...creatableSelectProps} />

      {note && (
        <FormField.NoteContainer>
          <Note>{noteMaxLength === undefined ? note : <ReadMoreReadLess maxLength={noteMaxLength}>{note}</ReadMoreReadLess>}</Note>
        </FormField.NoteContainer>
      )}

      {error && (
        <FormField.ErrorContainer>
          <ErrorMessage>{error}</ErrorMessage>
        </FormField.ErrorContainer>
      )}
    </FormField>
  );
};
