import { useState, useEffect } from 'react';
import { gql, useLazyQuery, useQuery } from '@apollo/client';

export type Address = {
  id: number;
  postcode: number;
  prefecture: string;
  prefectureKana: string;
  city: string;
  cityKana: string;
  street: string;
  streetKana: string;
  cityRcd: {
    id: number;
    code: string;
    name: string;
  };
};

type QueryResult = {
  postcode: Address;
};

type QueryVariables = {
  postcode: number;
  year: number;
};

type EmployeeQueryVariables = {
  postcode: number;
};

const FETCH_POSTCODE = gql`
  query postcode($postcode: Int!, $year: Int!) {
    postcode(postcode: $postcode, year: $year) {
      id
      city
      prefecture
      street
      streetKana
      cityKana
      postcode
      cityRcd {
        id
        code
        name
      }
    }
  }
`;

const FETCH_POSTCODE_EMPLOYEE = gql`
  query postcode($postcode: Int!) {
    postcode(postcode: $postcode) {
      id
      city
      prefecture
      street
      streetKana
      cityKana
      postcode
      cityRcd {
        id
        code
        name
      }
    }
  }
`;

export const useEmployeeAddress = () => {
  const [address, setAddress] = useState<Address>();
  const [fetch, { data, loading }] = useLazyQuery<QueryResult, EmployeeQueryVariables>(FETCH_POSTCODE_EMPLOYEE, {
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (!loading && data) {
      setAddress(data.postcode);
    }
  }, [loading]);

  return { fetchAddress: fetch, address };
};

export const useAddress = () => {
  const [address, setAddress] = useState<Address>();
  const [fetch, { data, loading }] = useLazyQuery<QueryResult, QueryVariables>(FETCH_POSTCODE, {
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (!loading && data) {
      setAddress(data.postcode);
    }
  }, [loading]);

  return { fetchAddress: fetch, address };
};

export const useAddressImmediate = (variables: QueryVariables) => {
  const { data, loading } = useQuery<QueryResult, QueryVariables>(FETCH_POSTCODE, { variables });
  return { loading, address: data?.postcode };
};
