import { FC } from 'react';
import { useQuery } from '@apollo/client';
import { Form } from './Form';
import { Preview } from './Preview';
import { ToggleSection, useToggleSection, ViewType } from '../../../components/ToggleSection';
import { FETCH_EMPLOYEE_OPTIONS, QueryResult } from './query';
import { useYear } from 'hooks/useYear';

type BasicInformationProps = {
  setMode: (viewType: ViewType) => void;
};

export const BasicInformation: FC<BasicInformationProps> = ({ setMode }) => {
  const { viewType, setViewType } = useToggleSection('preview');

  const handleSetViewType = (viewType: ViewType) => {
    setMode(viewType);
    setViewType(viewType);
  };

  const handleClose = () => {
    handleSetViewType('preview');
    window.location.hash = '';
    window.location.hash = '#basic-info';
  };

  const year = useYear();
  const { data } = useQuery<QueryResult>(FETCH_EMPLOYEE_OPTIONS, { variables: { year } });
  const clientYearly = data?.client.clientYearly;

  const employmentTypeOptions = clientYearly?.employmentTypes
    .filter(employmentType => !!employmentType)
    .map(employmentType => ({ value: employmentType, label: employmentType }));
  employmentTypeOptions?.unshift({ value: '', label: '' });

  const positionOptions = clientYearly?.positions.filter(position => !!position).map(position => ({ value: position, label: position }));
  positionOptions?.unshift({ value: '', label: '' });

  return (
    <ToggleSection viewType={viewType} setViewType={handleSetViewType}>
      <ToggleSection.Header>基本情報</ToggleSection.Header>
      <ToggleSection.Body>
        {viewType === 'preview' && <Preview />}
        {viewType === 'form' && (
          <Form employmentTypeOptions={employmentTypeOptions || []} positionOptions={positionOptions || []} onCancel={handleClose} />
        )}
      </ToggleSection.Body>
    </ToggleSection>
  );
};
