import { FC, ReactNode } from 'react';
import { Section } from 'components/ui/Section';

import { Question } from '../type';

import { ToggleImageContainer } from './parts/ToggleImageContainer';
import { Lead } from './parts/Lead';
import { Description } from './parts/Description';
import { Content } from './parts/Content';

// @ts-ignore
import image from '../../../../images/img-Foundation_Exclusion_Form.png';

type AllIncomeProps = {
  question: Question;
  children: ReactNode;
  initiallyOpen?: boolean;
};

export const AllIncome: FC<AllIncomeProps> = ({ question, children, initiallyOpen = false }) => {
  const description = question.questionDescriptions.find(description => description.field == 'top')?.description;

  return (
    <Section>
      <Section.Header>給与収入等（本人の収入合計金額）</Section.Header>

      <Section.Body>
        <Content>
          <Lead>各項目の収入の合計金額を入力してください。</Lead>

          <Description>{description}</Description>

          <ToggleImageContainer initiallyOpen={initiallyOpen}>
            <ToggleImageContainer.Header>「基礎控除申告書（赤枠）」に反映されます。</ToggleImageContainer.Header>
            <ToggleImageContainer.Body>
              <img src={image} alt="基配所_基礎控除申告書" />
            </ToggleImageContainer.Body>
          </ToggleImageContainer>

          {children}
        </Content>
      </Section.Body>
    </Section>
  );
};
