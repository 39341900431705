import React, { useMemo, useCallback } from 'react';

import { BoxDouble } from 'jbc-front/components/Form';
import { TextField, SelectField, NumberFields } from './FieldWithDiff';

import * as validators from '../validators';
import _ from 'lodash';
import { withPrefix } from './Name';
import { gql, useApolloClient } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { autofill, FormName, formValueSelector } from 'redux-form';
import { getState } from '../utils/redux';

export const addressFields = ['postcode0', 'postcode1', 'prefectureId', 'city', 'street', 'building'];

export const prefectures = [
  '北海道',
  '青森県',
  '岩手県',
  '宮城県',
  '秋田県',
  '山形県',
  '福島県',
  '茨城県',
  '栃木県',
  '群馬県',
  '埼玉県',
  '千葉県',
  '東京都',
  '神奈川県',
  '新潟県',
  '富山県',
  '石川県',
  '福井県',
  '山梨県',
  '長野県',
  '岐阜県',
  '静岡県',
  '愛知県',
  '三重県',
  '滋賀県',
  '京都府',
  '大阪府',
  '兵庫県',
  '奈良県',
  '和歌山県',
  '鳥取県',
  '島根県',
  '岡山県',
  '広島県',
  '山口県',
  '徳島県',
  '香川県',
  '愛媛県',
  '高知県',
  '福岡県',
  '佐賀県',
  '長崎県',
  '熊本県',
  '大分県',
  '宮崎県',
  '鹿児島県',
  '沖縄県'
];
export const prefectureOptions = prefectures.map((val, index) => ({ value: `${index + 1}`, label: val }));

const POSTCODE_FRAGMENT = gql`
  fragment Postcode on Postcode {
    id
    city
    prefecture
    street
    streetKana
    cityKana
    postcode
    cityRcd {
      id
      code
      name
    }
  }
`;

const POSTCODE = gql`
  query postcode($postcode: Int!) {
    postcode(postcode: $postcode) {
      ...Postcode
    }
  }
  ${POSTCODE_FRAGMENT}
`;

const POSTCODE_WITH_YEAR = gql`
  query postcode($postcode: Int!, $year: Int!) {
    postcode(postcode: $postcode, year: $year) {
      ...Postcode
    }
  }
  ${POSTCODE_FRAGMENT}
`;

const Address = ({
  required,
  kanaRequired,
  withKana,
  noHankaku,
  diff,
  disabled,
  postcodeDescription,
  form,
  sectionPrefix,
  client,
  autofillCityCode,
  year
}) => {
  const dispatch = useDispatch();
  const selector = useMemo(() => formValueSelector(form), [form]);
  const handleChange = useCallback(
    async (event, value, previousValue, name) => {
      const state = await getState(dispatch);
      const postcode0 = name.match(/.*postcode0$/i) ? value : selector(state, withPrefix(sectionPrefix, 'postcode0'));
      const postcode1 = name.match(/.*postcode1$/i) ? value : selector(state, withPrefix(sectionPrefix, 'postcode1'));
      if (postcode0?.length === 3 && postcode1?.length === 4) {
        const postcode = `${postcode0}${postcode1}`;
        if (postcode.match(/\d{7}/)) {
          try {
            const queryOptions = year
              ? { query: POSTCODE_WITH_YEAR, variables: { postcode: +postcode, year } }
              : { query: POSTCODE, variables: { postcode: +postcode } };
            const {
              data: { postcode: address }
            } = await client.query(queryOptions);
            if (address && +postcode === address.postcode) {
              const prefecture = prefectureOptions.find(prefectureOption => prefectureOption.label === address.prefecture);
              if (prefecture) {
                dispatch(autofill(form, withPrefix(sectionPrefix, 'prefectureId'), prefecture.value));
              }
              dispatch(autofill(form, withPrefix(sectionPrefix, 'city'), address.city));
              dispatch(autofill(form, withPrefix(sectionPrefix, 'street'), address.street));
              if (withKana) {
                dispatch(
                  autofill(
                    form,
                    withPrefix(sectionPrefix, 'addressKana'),
                    address.prefecture_kana + address.city_kana + address.street_kana
                  )
                );
              }
              if (autofillCityCode) {
                autofillCityCode(address.cityRcd);
              }
            }
          } catch (err) {
            // ignore error
            if (process.env.NODE_ENV !== 'production' && console.error) {
              console.error(err);
            }
          }
        }
      }
    },
    [client, dispatch, form, sectionPrefix, selector, withKana, autofillCityCode, year]
  );

  return (
    <div>
      <NumberFields
        texts={['〒 ', ' - ']}
        lengths={[3, 4]}
        required={required}
        label="郵便番号"
        prefix="postcode"
        exactLength={true}
        onChange={handleChange}
        changed={_.range(2).some(i => diff[`postcode${i}`])}
        disabled={disabled}
        seperateChar=""
        description={postcodeDescription}
      />
      <BoxDouble>
        <SelectField
          name={'prefectureId'}
          label="都道府県"
          options={prefectureOptions}
          required={required}
          changed={diff['prefectureId']}
          disabled={disabled}
        />
        <TextField
          name="city"
          label="市区町村"
          required={required}
          validate={noHankaku && validators.noHankaku}
          changed={diff['city']}
          disabled={disabled}
        />
        <TextField
          name="street"
          label="丁目番地号"
          validate={noHankaku && validators.noHankaku}
          changed={diff['street']}
          disabled={disabled}
        />
        <TextField
          name="building"
          label="建物名"
          validate={noHankaku && validators.noHankaku}
          changed={diff['building']}
          disabled={disabled}
        />
      </BoxDouble>
      {withKana && (
        <TextField
          name="addressKana"
          label="住所カナ"
          required={kanaRequired}
          validate={noHankaku ? [validators.zenkakuKatakanaLoose, validators.noHankaku] : validators.zenkakuKatakanaLoose}
          changed={diff['addressKana']}
          disabled={disabled}
        />
      )}
    </div>
  );
};

Address.defaultProps = {
  withKana: true,
  diff: {}
};
const AddressWrap = props => {
  const client = useApolloClient();
  return <FormName>{({ form, sectionPrefix }) => <Address {...{ form, sectionPrefix, client, ...props }} />}</FormName>;
};

export default AddressWrap;
